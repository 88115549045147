<template>
  <div class="addRules">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="goBack">规则</span>
        <span> / </span>
        <span>{{ router.currentRoute.value.query.name ? router.currentRoute.value.query.name : '新建' }}</span>
      </p>
    </div>
    <!--内容-->
    <div class="content">
      <el-form ref="userForm" :inline="true" :model="formData" label-width="auto">
        <div class="topContent">
          <div class="leftCont">
            <div class="leftTopBox">
              <el-form-item label="Rule ID" prop="id" required>
                <el-input v-model="formData.id" :disabled="!!router.currentRoute.value.query.name"></el-input>
              </el-form-item>
              <el-form-item label="名称" prop="name">
                <el-input v-model="formData.name" placeholder="请输入名称"></el-input>
              </el-form-item>
              <el-form-item label="立即运行" prop="triggered">
                <el-switch v-model="formData.triggered" style="--el-switch-on-color: #ccefe3;"/>
              </el-form-item>
            </div>
            <div class="leftBottomBox">
              <el-form-item>
                <div slot="label" class="labelBox" style="margin-top: 20px">
                  <i style="color: red;">*</i>
                  <p style="margin-left: 5px">SQL 编辑器</p>
                </div>
                <p class="example" @click="dialogFormVisible=true">SQL示例</p>
                <div class="sqlMonaco"></div>
              </el-form-item>
            </div>
          </div>
          <div class="rightCont">
            <el-tabs v-model="tabIdx" class="demo-tabs">
              <el-tab-pane class="movement" label="动作" name="movement">
                <p class="addMoveBtn"
                   @click="addMove">添加动作</p>
                <div class="moveList">
                  <div v-for="(item,index) in formData.actions" :key="index" class="moveItem">
                    <div class="moveL">
                      <img v-if="Object.keys(item)[0]==='file'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/File.svg">
                      <img v-if="Object.keys(item)[0]==='influx'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/influx.svg">
                      <img v-if="Object.keys(item)[0]==='influx2'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/influx.svg">
                      <img v-if="Object.keys(item)[0]==='kafka'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/kafka.svg">
                      <img v-if="Object.keys(item)[0]==='log'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/log.svg">
                      <img v-if="Object.keys(item)[0]==='memory'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/memory.svg">
                      <img v-if="Object.keys(item)[0]==='mqtt'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/mqtt.svg">
                      <img v-if="Object.keys(item)[0]==='neuron'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/neuron.svg">
                      <img v-if="Object.keys(item)[0]==='nop'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/nop.svg">
                      <img v-if="Object.keys(item)[0]==='rest'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/rest.svg">
                      <img v-if="Object.keys(item)[0]==='sql'" alt=""
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/sql.svg">
                      <p>{{ Object.keys(item)[0] }}</p>
                    </div>
                    <div class="moveR">
                      <el-icon @click="editMove(item,index)">
                        <EditPen/>
                      </el-icon>
                      <el-icon @click="delMove(index)">
                        <Delete/>
                      </el-icon>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane class="dataSource" label="数据源示例" name="dataSource">
                <p class="der">已创建的数据源，以及用法示例</p>
                <el-collapse accordion @change="handleChange">
                  <el-collapse-item v-for="(item,index) in dataSourceList" :key="index" :name="index"
                                    :title="item.name">
                    <div class="dataSourceType">数据源类型: {{ item.type }}</div>
                    <div class="dataSourceCont">
                      <div class="dataSourceHead">
                        <p>SQL 语句</p>
                        <el-button @click="useSQL(item)">使用 SQL</el-button>
                      </div>
                      <div :class="'dataSourceValMonaco'+index" class="dataSourceValMonaco"></div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="bottomContent">
          <el-tabs v-model="tabIdx2" class="demo-tabs">
            <el-tab-pane class="debug" label="调试规则" name="debug">
              <div class="head">
                <div class="headLeft">
                  <el-button type="primary" @click="openDataSourceFun">模拟数据源</el-button>
                  <el-button type="primary" @click="openDataTemplateFun">数据模版</el-button>
                </div>
                <div class="headRight">
                  <el-button :icon="Delete" @click="delTestFun"></el-button>
                  <el-button :disabled="!isRunning" :icon="VideoPause" @click="stopTestFun"></el-button>
                  <el-button :icon="isRunning?null:VideoPlay" :loading="isRunning" @click="startTestFun"></el-button>
                </div>
              </div>
              <div class="debugRuleMonaco"></div>
            </el-tab-pane>
            <el-tab-pane class="select" label="规则选项" name="select">
              <el-collapse>
                <el-collapse-item name="1" title="通用">
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">线程数</p>
                      <el-tooltip
                          content="一条规则运行时会根据 sql 语句分解成多个 plan 运行。该参数设置每个 plan 运行的线程数。该参数值大于1时，消息处理顺序可能无法保证。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.concurrency"/>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">缓存大小</p>
                      <el-tooltip
                          content="指定每个 plan 可缓存消息数。若缓存消息数超过此限制，plan 将阻塞消息接收，直到缓存消息被消费使得缓存消息数目小于限制为止。此选项值越大，则消息吞吐能力越强，但是内存占用也会越多。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.bufferLength"/>
                    </div>
                  </el-form-item>
                  <br>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">是否开启 DEBUG 日志</p>
                      <el-tooltip
                          content="指定该条规则是否开启 Debug Level 的日志水平，缺省情况下会继承全局配置中的 Debug 配置参数。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--单选框-->
                      <el-radio-group v-model="formData.options.debug">
                        <el-radio :label="true" border>True</el-radio>
                        <el-radio :label="false" border>False</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">是否发送元数据</p>
                      <el-tooltip
                          content="指定是否将事件的元数据发送到目标。 如果为 true，则目标可以获取元数据信息。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--单选框-->
                      <el-radio-group v-model="formData.options.sendMetaToSink">
                        <el-radio :label="true" border>True</el-radio>
                        <el-radio :label="false" border>False</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <br>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">流的 QoS </p>
                      <el-tooltip
                          content="指定流的 qos。 值为0对应最多一次； 1对应至少一次，2对应恰好一次。 如果 qos 大于0，将激活检查点机制以定期保存状态，以便可以从错误中恢复规则。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--输入框-->
                      <el-select v-model="formData.options.qos" clearable>
                        <el-option :label="0" :value="0"></el-option>
                        <el-option :label="1" :value="1"></el-option>
                        <el-option :label="2" :value="2"></el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">检查点间隔</p>
                      <el-tooltip
                          content="指定触发检查点的时间间隔（单位为 ms）。 仅当 qos 大于0时才有效。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.checkpointInterval"/>
                    </div>
                  </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="2" title="事件时间">
                  <el-form-item prop="options.lateTolerance">
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">时间时间延迟</p>
                      <el-tooltip
                          content="在使用事件时间窗口时，可能会出现元素延迟到达的情况。 LateTolerance 可以指定在删除元素之前可以延迟多少时间（单位为毫秒）。 默认情况下，该值为0，表示后期元素将被删除。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.lateTolerance"/>
                    </div>
                  </el-form-item>
                  <el-form-item prop="options.isEventTime">
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">是否使用事件时间</p>
                      <el-tooltip
                          content="使用事件时间还是将时间用作事件的时间戳。 如果使用事件时间，则将从有效负载中提取时间戳。 必须通过 stream 定义指定时间戳记。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--单选框-->
                      <el-radio-group v-model="formData.options.isEventTime">
                        <el-radio :label="true" border>True</el-radio>
                        <el-radio :label="false" border>False</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="3" title="规则重试">
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">最大重试次数</p>
                      <el-tooltip
                          content="最大重试次数。如果设置为0，该规则将立即失败，不会进行重试"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.restartStrategy.attempts"/>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">重试间隔时间</p>
                      <el-tooltip
                          content="默认的重试间隔时间，以毫秒为单位。如果没有设置multiplier，重试的时间间隔将固定为这个值。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.restartStrategy.delay"/>
                    </div>
                  </el-form-item>
                  <br>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">重试最大间隔时间</p>
                      <el-tooltip
                          content="重试最大间隔时间，单位是毫秒。只有当multiplier有设置时，从而使得每次重试的延迟都会增加时才会生效。默认的重试间隔时间，以毫秒为单位。如果没有设置multiplier，重试的时间间隔将固定为这个值。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.restartStrategy.maxDelay"/>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">重试间隔时间乘数</p>
                      <el-tooltip
                          content="默认的重试间隔时间，以毫秒为单位。如果没有设置multiplier，重试的时间间隔将固定为这个值。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.restartStrategy.multiplier"/>
                    </div>
                  </el-form-item>
                  <br>
                  <el-form-item>
                    <div slot="label"
                         class="labelBox"
                         style="margin-top: 20px">
                      <p style="margin-left: 5px">随机值系数</p>
                      <el-tooltip
                          content="添加或减去延迟的随机值系数，防止在同一时间重新启动多个规则。"
                          effect="dark"
                          placement="top"
                          popper-class="el_tooltip_item"
                      >
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="widthBox">
                      <!--数字输入框-->
                      <el-input-number v-model="formData.options.restartStrategy.jitterFactor" :step="0.1"/>
                    </div>
                  </el-form-item>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form>
      <div class="footer">
        <el-button type="primary" @click="saveFun">保存规则</el-button>
        <el-button @click="router.push('/admin/rulesPage')">取消</el-button>
      </div>
    </div>


    <!--模拟数据源弹窗-->
    <el-dialog v-model="dialogFormVisible2" :close-on-click-modal="false" class="dialogFormVisible2"
               title="模拟数据源" width="60%">
      <el-tabs
          v-model="tabsIdx"
          class="demo-tabs"
          editable
          type="card"
          @edit="handleTabsEdit"
      >
        <el-tab-pane
            v-for="(item,index) in editableTabs"
            :key="item.name"
            :label="item.title"
            :name="item.name"
        >
          <el-form :model="dataSourceFormData[index]">
            <el-form-item label="选择SQL中模拟数据源" prop="dataSourceSQL" required style="margin-bottom: 20px">
              <el-select v-model="dataSourceFormData[index].dataSourceSQL"
                         clearable placeholder="请选择SQL中模拟数据源">
                <el-option v-for="(item,index) in dataSourceSelectList" :key="index"
                           :disabled="dataSourceFormData.some(obj => obj.dataSourceSQL === item)"
                           :label="item"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发送间隔" prop="sendTime" required style="margin-bottom: 20px">
              <el-input v-model="dataSourceFormData[index].sendTime" placeholder="请输入发送间隔"></el-input>
            </el-form-item>
            <el-form-item label="循环发送" prop="loop" style="margin-bottom: 20px">
              <el-switch v-model="dataSourceFormData[index].loop" style="--el-switch-on-color: #ccefe3;"/>
            </el-form-item>
            <el-form-item class="width" prop="payload" style="margin-bottom: 20px">
              <div slot="label"
                   class="labelBox"
                   style="margin-top: 20px">
                <i style="color: red;">*</i>
                <p style="margin-left: 5px">payload</p>
                <el-tooltip
                    content="payload 格式: 支持“多行一条 json”、“单行一条 json”，“多行，每行一条 json”"
                    effect="dark"
                    placement="top"
                    popper-class="el_tooltip_item"
                >
                  <el-icon>
                    <QuestionFilled/>
                  </el-icon>
                </el-tooltip>
              </div>
              <div :class="'payloadMonaco'+(item.name-1)" class="payloadMonaco"></div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible2=false">取消</el-button>
        <el-button class="cancle resetButton"
                   @click="dataSourceFlag=!dataSourceFlag">{{ dataSourceFlag ? '关闭' : '开启' }}模拟数据源</el-button>
        <el-button class="confrim" type="primary" @click="dialogFormVisible2=false">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--数据模版弹窗-->
    <el-dialog v-model="dialogFormVisible3" :close-on-click-modal="false" class="dialogFormVisible3"
               title="数据模版" width="60%">
      <div>
        <p class="name">输入数据模版</p>
        <p class="der">可选项，模拟 Sink 中的 Data Template，对 SQL 语句处理过后的输出，进行数据格式修改</p>
        <div class="templateMonaco"></div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible3=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="dialogFormVisible3=false">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--SQL示例-->
    <el-drawer
        v-model="dialogFormVisible"
        class="dialogFormVisible"
        title="SQL 示例"
    >
      <el-collapse accordion @change="handleChange2">
        <el-collapse-item name="1" title="选择数采模块数据源 neuronStream 的所有数据">
          <div class="dataSourceCont">
            <div class="dataSourceHead dataSourceHead2">
              <p class="tit">SQL 语句</p>
              <el-button @click="useSQL2('A1')">使用 SQL</el-button>
            </div>
            <div class="statementMonaco statementMonacoA1"></div>
            <div class="dataSourceHead">
              <p class="tit">应用场景</p>
              <p class="der">将数采模块数据源 neuronStream 的所有数据，不做任何处理，然后转发出去。</p>
              <p class="tit">输入消息示例</p>
            </div>
            <div class="statementMonaco statementMonacoA2"></div>
            <div class="dataSourceHead">
              <p class="tit">处理结果</p>
            </div>
            <div class="statementMonaco statementMonacoA3"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="2" title="数据筛选及简单数学计算">
          <div class="dataSourceCont">
            <div class="dataSourceHead dataSourceHead2">
              <p class="tit">SQL 语句</p>
              <el-button @click="useSQL2('B1')">使用 SQL</el-button>
            </div>
            <div class="statementMonaco statementMonacoB1"></div>
            <div class="dataSourceHead">
              <p class="tit">应用场景</p>
              <p class="der">筛选数据源的部分字段，进行简单数学计算，并对数据标签名称重命名</p>
              <p class="tit">输入消息示例</p>
            </div>
            <div class="statementMonaco statementMonacoB2"></div>
            <div class="dataSourceHead">
              <p class="tit">处理结果</p>
            </div>
            <div class="statementMonaco statementMonacoB3"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="3" title="数据条件筛选">
          <div class="dataSourceCont">
            <div class="dataSourceHead dataSourceHead2">
              <p class="tit">SQL 语句</p>
              <el-button @click="useSQL2('C1')">使用 SQL</el-button>
            </div>
            <div class="statementMonaco statementMonacoC1"></div>
            <div class="dataSourceHead">
              <p class="tit">应用场景</p>
              <p class="der">只有当数据源 node_name 字段的值为 modbus1 时，才计算并输出。数据源 node_name 字段的值不为 modbus1 的消息将被丢弃。</p>
              <p class="tit">输入消息示例</p>
            </div>
            <div class="statementMonaco statementMonacoC2"></div>
            <div class="dataSourceHead">
              <p class="tit">处理结果</p>
            </div>
            <div class="statementMonaco statementMonacoC3"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="4" title="时间窗口计算输出">
          <div class="dataSourceCont">
            <div class="dataSourceHead dataSourceHead2">
              <p class="tit">SQL 语句</p>
              <el-button @click="useSQL2('D1')">使用 SQL</el-button>
            </div>
            <div class="statementMonaco statementMonacoD1"></div>
            <div class="dataSourceHead">
              <p class="tit">应用场景</p>
              <p class="der">按照 group_name 分组，并计算每个分组内 tag1 的 10 秒钟平均值</p>
              <p class="tit">输入消息示例</p>
            </div>
            <div class="statementMonaco statementMonacoD2"></div>
            <div class="dataSourceHead">
              <p class="tit">处理结果</p>
            </div>
            <div class="statementMonaco statementMonacoD3"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="5" title="分析函数示例 1">
          <div class="dataSourceCont">
            <div class="dataSourceHead dataSourceHead2">
              <p class="tit">SQL 语句</p>
              <el-button @click="useSQL2('E1')">使用 SQL</el-button>
            </div>
            <div class="statementMonaco statementMonacoE1"></div>
            <div class="dataSourceHead">
              <p class="tit">应用场景</p>
              <p class="der">当数据点 tag1 变化时，上报筛选过后的数据</p>
              <p class="tit">输入消息示例</p>
            </div>
            <div class="statementMonaco statementMonacoE2"></div>
            <div class="dataSourceHead">
              <p class="tit">处理结果</p>
            </div>
            <div class="statementMonaco statementMonacoE3"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="6" title="使用内置函数">
          <div class="dataSourceCont">
            <div class="dataSourceHead dataSourceHead2">
              <p class="tit">SQL 语句</p>
              <el-button @click="useSQL2('F1')">使用 SQL</el-button>
            </div>
            <div class="statementMonaco statementMonacoF1"></div>
            <div class="dataSourceHead">
              <p class="tit">应用场景</p>
              <p class="der">对采集到的数据，补充采集时间</p>
              <p class="tit">输入消息示例</p>
            </div>
            <div class="statementMonaco statementMonacoF2"></div>
            <div class="dataSourceHead">
              <p class="tit">处理结果</p>
            </div>
            <div class="statementMonaco statementMonacoF3"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="7" title="调用外部服务示例一">
          <div class="dataSourceCont">
            <div class="dataSourceHead dataSourceHead2">
              <p class="tit">SQL 语句</p>
              <el-button @click="useSQL2('G1')">使用 SQL</el-button>
            </div>
            <div class="statementMonaco statementMonacoG1"></div>
            <div class="dataSourceHead">
              <p class="tit">应用场景</p>
              <p class="der">从 simuStream 数据源将完整 JSON 数据 {"a":10,"b":20}， 通过 HTTP POST 发往外部 HTTP
                服务（http://[ip]:[port]/api/test1），经过加法运算后返回结果，外部 HTTP 服务接收 2 个参数，参数名称即为 a 和 b。</p>
              <p class="tit">输入消息示例</p>
            </div>
            <div class="statementMonaco statementMonacoG2"></div>
            <div class="dataSourceHead">
              <p class="tit">处理结果</p>
            </div>
            <div class="statementMonaco statementMonacoG3"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="8" title="调用外部服务示例二">
          <div class="dataSourceCont">
            <div class="dataSourceHead dataSourceHead2">
              <p class="tit">SQL 语句</p>
              <el-button @click="useSQL2('H1')">使用 SQL</el-button>
            </div>
            <div class="statementMonaco statementMonacoH1"></div>
            <div class="dataSourceHead">
              <p class="tit">应用场景</p>
              <p class="der">从 neuronStream 数据源选择 tag1 和 tag2 两个数据点，通过 HTTP POST 发往外部 HTTP
                服务（http://[ip]:[port]/api/test1），经过加法运算后返回结果，外部 HTTP 服务接收 2 个参数，参数名称即为 a 和 b。</p>
              <p class="tit">输入消息示例</p>
            </div>
            <div class="statementMonaco statementMonacoH2"></div>
            <div class="dataSourceHead">
              <p class="tit">处理结果</p>
            </div>
            <div class="statementMonaco statementMonacoH3"></div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-drawer>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref} from "vue"
import {useRouter} from "vue-router/dist/vue-router";
import './addRules.less'
import * as monaco from "monaco-editor";
import {EditPen, Delete, VideoPlay, VideoPause, QuestionFilled} from '@element-plus/icons-vue'
import {
  ruleDetailApi,
  getDataSourceApi,
  editRuleDetailApi,
  addRuleDetailApi,
  getDataSourceSelectApi, getDataSourceDetailApi, ruletestStopApi, ruletestApi, ruletestStartApi, getListApi
} from '@/api/modules/rulesPage'
import {ElMessage} from "element-plus";

let router = useRouter()

const goBack = function () {
  router.push('/admin/rulesPage')
}
// tab栏**************************************************************************
// 上面tab栏
let tabIdx = ref('movement')
// 下面tab栏
let tabIdx2 = ref('debug')
// 模拟数据源tab栏
let tabIndex = 1
const tabsIdx = ref(1)
let editableTabs = ref([
  {
    title: '模拟数据源1',
    name: 1,
  },
])
const handleTabsEdit = (targetName, action) => {
  if (action === 'add') {
    const newTabName = `${++tabIndex}`
    editableTabs.value.push({
      title: `模拟数据源${tabIndex}`,
      name: newTabName
    })
    tabsIdx.value = newTabName

    dataSourceFormData.value.push({
      dataSourceSQL: '',
      sendTime: 1000,
      loop: true,
      payload: ''
    })
    nextTick(() => {
      setTimeout(() => {
        payloadArr.push(
            monaco.editor.create(document.querySelector(`.payloadMonaco${tabIndex - 1}`), {
              value: '', // 编辑器的值
              ...monacoOption.value
            })
        )
      }, 100)
    })
  } else if (action === 'remove') {
    const tabs = editableTabs.value
    let activeName = tabsIdx.value
    if (activeName === targetName) {
      tabs.forEach((tab, index) => {
        if (tab.name === targetName) {
          const nextTab = tabs[index + 1] || tabs[index - 1]
          if (nextTab) {
            activeName = nextTab.name
          }
        }
      })
    }
    tabsIdx.value = activeName
    editableTabs.value = tabs.filter((tab) => tab.name !== targetName)


    dataSourceFormData.value.splice(Number(targetName) - 1, 1)
    payloadArr.splice(Number(targetName) - 1, 1)
  }

}
let dataSourceFlag = ref(true)


// 上面折叠板展开事件**************************************************************************
const handleChange = function (e) {
  if (e !== '') {
    setTimeout(() => {
      const container2 = document.querySelector(`.dataSourceValMonaco${e}`)
      monacoEditor2 = monaco.editor.create(container2, {
        value: "SELECT\n" + "  *\n" + "FROM\n" + '  ' + dataSourceList.value[e].name, // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  }
}
// 点击使用SQL
const useSQL = function (item) {
  monacoEditor.setValue("SELECT\n" + "  *\n" + "FROM\n" + '  ' + item)
}


// 模拟数据源弹窗**************************************************************************
let dialogFormVisible2 = ref(false)
let dataSourceSelectList = ref([])
let payloadArr = []
// 模拟数据源弹窗表单数据
let dataSourceFormData = ref([{
  dataSourceSQL: '',
  sendTime: 1000,
  loop: true,
  payload: ''
}])
const openDataSourceFun = async function () {
  await getDataSourceSelectApi({
    id: formData.value.id,
    sql: formData.value.sql,
    actions: [
      {
        "log": {}
      }
    ]
  }).then(res => {
    if (res.status === 200) {
      dataSourceSelectList.value = res.data.sources
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
  dialogFormVisible2.value = true
  await nextTick(() => {
    if (!payloadArr[0]) {
      payloadArr.push(
          monaco.editor.create(document.querySelector('.payloadMonaco0'), {
            value: '', // 编辑器的值
            ...monacoOption.value
          })
      )
    }

  })
}


// 数据模版弹窗**************************************************************************
let dialogFormVisible3 = ref(false)
let monacoEditor4
let dataTemplateVal = ref('')
const openDataTemplateFun = function () {
  dialogFormVisible3.value = true
  nextTick(() => {
    setTimeout(() => {
      if (monacoEditor4 === undefined) {
        monacoEditor4 = monaco.editor.create(document.querySelector('.templateMonaco'), {
          value: dataTemplateVal.value, // 编辑器的值
          ...monacoOption.value
        })
      }
    }, 100)
  })
}


// SQL示例弹窗
let dialogFormVisible = ref(false)
const handleChange2 = function (e) {
  if (e === '1') {
    setTimeout(() => {
      monaco.editor.create(document.querySelector('.statementMonacoA1'), {
        value: "SELECT\n" + "  *\n" + "FROM\n" + '  neuronStream', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoA2'), {
        value: '{\n' + '  "timestamp": 1701134996987,\n' + '  "node_name": "modbus1",\n' + '  "group_name": "group1",\n' + '  "values": {\n' + '    "tag1": 300\n' + '  },\n' + '  "errors": {},\n' + '  "metas": {}\n' + '}',
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoA3'), {
        value: '{\n' + '  "timestamp": 1701134996987,\n' + '  "node_name": "modbus1",\n' + '  "group_name": "group1",\n' + '  "values": {\n' + '    "tag1": 300\n' + '  },\n' + '  "errors": {},\n' + '  "metas": {}\n' + '}', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  } else if (e === '2') {
    setTimeout(() => {
      monaco.editor.create(document.querySelector('.statementMonacoB1'), {
        value: "SELECT\n" + "  ABS( values.tag1 * values.tag2 ) AS calc_data1,\n" + "  timestamp\n" + "FROM\n" + '  neuronStream', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoB2'), {
        value: '{\n' + '  "timestamp": 1701134996987,\n' + '  "node_name": "modbus1",\n' + '  "group_name": "group1",\n' + '  "values": {\n' + '    "tag1": 100\n' + '    "tag2": 5\n' + '  },\n' + '  "errors": {},\n' + '  "metas": {}\n' + '}',
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoB3'), {
        value: '{\n' + '  "calc_data1": 500,\n' + '  "timestamp": 1701134996987\n' + '}', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  } else if (e === '3') {
    setTimeout(() => {
      monaco.editor.create(document.querySelector('.statementMonacoC1'), {
        value: "SELECT\n" + "  values.tag1 *2/10 AS new_tag1\n" + "FROM\n" + '  neuronStream\n' + "WHERE\n" + '  node_name = "modbus1"', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoC2'), {
        value: '{\n' + '  "timestamp": 1701134996987,\n' + '  "node_name": "modbus1",\n' + '  "group_name": "group1",\n' + '  "values": {\n' + '    "tag1": 100\n' + '    "tag2": 5\n' + '  },\n' + '  "errors": {},\n' + '  "metas": {}\n' + '}',
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoC3'), {
        value: '{\n' + '  "new_tag1": 20\n' + '}', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  } else if (e === '4') {
    setTimeout(() => {
      monaco.editor.create(document.querySelector('.statementMonacoD1'), {
        value: "SELECT\n" + "  avg(values.tag1) AS tag1_avg_10 ,\n" + "  group_name\n" + "FROM\n" + '  neuronStream\n' + "GROUP BY\n" + '  group_name,\n' + '  TUMBLINGWINDOW(ss, 10);', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoD2'), {
        value: '{\n' + '  "timestamp": 1701134996987,\n' + '  "node_name": "modbus1",\n' + '  "group_name": "group1",\n' + '  "values": {\n' + '    "tag1": 100\n' + '    "tag2": 5\n' + '  },\n' + '  "errors": {},\n' + '  "metas": {}\n' + '}',
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoD3'), {
        value: '{\n' + '  "group_name": "group1",\n' + '  "tag1_avg_10": 100\n' + '}', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  } else if (e === '5') {
    setTimeout(() => {
      monaco.editor.create(document.querySelector('.statementMonacoE1'), {
        value: "SELECT\n" + "  timestamp,\n" + "  node_name,\n" + "  group_name,\n" + "  values.tag1,\n" + "  values.tag2,\n" + "FROM\n" + '  neuronStream\n' + "WHERE\n" + ' HAD_CHANGED(true,values.tag1) = true', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoE2'), {
        value: '{\n' + '  "timestamp": 1701134996987,\n' + '  "node_name": "modbus1",\n' + '  "group_name": "group1",\n' + '  "values": {\n' + '    "tag1": 100\n' + '    "tag2": 5\n' + '  },\n' + '  "errors": {},\n' + '  "metas": {}\n' + '}',
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoE3'), {
        value: '{\n' + '  "timestamp": 1701134996987,\n' + '  "node_name": "modbus1",\n' + '  "group_name": "group1",\n' + '  "tag1": 101,\n' + '  "tag2": 5\n' + '}', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  } else if (e === '6') {
    setTimeout(() => {
      monaco.editor.create(document.querySelector('.statementMonacoF1'), {
        value: "SELECT\n" + "  now() AS time,\n" + "  tag1,\n" + "  value1,\n" + "  value2\n" + "FROM\n" + '  demoStream\n', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoF2'), {
        value: '{\n' + '  "tag1": "sensor1",\n' + '  "value1": 100,\n' + '  "value2": 200\n' + '}',
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoF3'), {
        value: '{\n' + '  "time": "2023-11-28 07:37:26",\n' + '  "tag1": "sensor1",\n' + '  "value1": 100,\n' + '  "value2": 200\n' + '}', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  } else if (e === '7') {
    setTimeout(() => {
      monaco.editor.create(document.querySelector('.statementMonacoG1'), {
        value: "SELECT\n" + '  ex_service("post","/api/test1", *)\n' + "FROM\n" + '  SimuStream', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoG2'), {
        value: '{\n' + '  "a": 10,\n' + '  "b": 20\n' + '}',
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoG3'), {
        value: '{\n' + '  "ex_service": {\n' + '    "result": 30\n' + '  }\n' + '}', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  } else if (e === '8') {
    setTimeout(() => {
      monaco.editor.create(document.querySelector('.statementMonacoH1'), {
        value: "SELECT\n" + '  ex_service("post","/api/test1", object_construct("a",values.tag1 ,"b", values.tag2))\n' + "FROM\n" + '  neuronStream', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoH2'), {
        value: '{\n' + '  "timestamp": 1701134996987,\n' + '  "node_name": "modbus1",\n' + '  "group_name": "group1",\n' + '  "values": {\n' + '    "tag1": 1\n' + '    "tag2": 2\n' + '    "tag3": 3\n' + '    "tag4": 4\n' + '  },\n' + '  "errors": {},\n' + '  "metas": {}\n' + '}',
        ...monacoOption.value,
        readOnly: true,
      })
      monaco.editor.create(document.querySelector('.statementMonacoH3'), {
        value: '{\n' + '  "ex_service": {\n' + '    "result": 3\n' + '  }\n' + '}', // 编辑器的值
        ...monacoOption.value,
        readOnly: true,
      })
    }, 100)
  }
}
// 点击使用SQL
const useSQL2 = function (item) {
  if (item === 'A1') {
    monacoEditor.setValue("SELECT\n" + "  *\n" + "FROM\n" + '  neuronStream')
  } else if (item === 'B1') {
    monacoEditor.setValue("SELECT\n" + "  ABS( values.tag1 * values.tag2 ) AS calc_data1,\n" + "  timestamp\n" + "FROM\n" + '  neuronStream')
  } else if (item === 'C1') {
    monacoEditor.setValue("SELECT\n" + "  values.tag1 *2/10 AS new_tag1\n" + "FROM\n" + '  neuronStream\n' + "WHERE\n" + '  node_name = "modbus1"')
  } else if (item === 'D1') {
    monacoEditor.setValue("SELECT\n" + "  avg(values.tag1) AS tag1_avg_10 ,\n" + "  group_name\n" + "FROM\n" + '  neuronStream\n' + "GROUP BY\n" + '  group_name,\n' + '  TUMBLINGWINDOW(ss, 10);')
  } else if (item === 'E1') {
    monacoEditor.setValue("SELECT\n" + "  timestamp,\n" + "  node_name,\n" + "  group_name,\n" + "  values.tag1,\n" + "  values.tag2,\n" + "FROM\n" + '  neuronStream\n' + "WHERE\n" + ' HAD_CHANGED(true,values.tag1) = true')
  } else if (item === 'F1') {
    monacoEditor.setValue("SELECT\n" + "  now() AS time,\n" + "  tag1,\n" + "  value1,\n" + "  value2\n" + "FROM\n" + '  demoStream\n')
  } else if (item === 'G1') {
    monacoEditor.setValue("SELECT\n" + '  ex_service("post","/api/test1", *)\n' + "FROM\n" + '  SimuStream')
  } else if (item === 'H1') {
    monacoEditor.setValue("SELECT\n" + '  ex_service("post","/api/test1", object_construct("a",values.tag1 ,"b", values.tag2))\n' + "FROM\n" + '  neuronStream')
  }
}


// 规则**************************************************************************
// 规则表单
let formData = ref({
  triggered: true,
  id: router.currentRoute.value.query.name ? router.currentRoute.value.query.name : '',
  name: "",
  sql: "SELECT\n" + "  *\n" + "FROM\n" + "  neuronStream",
  actions: [],
  options: {
    debug: false,
    logFilename: "",
    isEventTime: false,
    lateTolerance: 0,
    concurrency: 1,
    bufferLength: 1024,
    sendMetaToSink: false,
    sendError: true,
    qos: 0,
    checkpointInterval: 300000,
    restartStrategy: {
      attempts: 0,
      delay: 1000,
      multiplier: 2,
      maxDelay: 30000,
      jitterFactor: 0.1
    },
    cron: "",
    duration: "",
    cronDatetimeRange: null
  }
})
// 获取规则详情
const ruleDetailFun = async function (id) {
  await ruleDetailApi(id).then(res => {
    if (res.status === 200) {
      formData.value = res.data
      monacoEditor.setValue(res.data.sql)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
// 保存规则
const saveFun = function () {
  if (router.currentRoute.value.query.name) {
    editRuleDetailApi(router.currentRoute.value.query.name, formData.value).then(res => {
      if (res.status === 200) {
        router.push('/admin/rulesPage')
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
      ElMessage({
        type: 'error',
        message: err.response.data.message,
      })
    })
  } else {
    addRuleDetailApi(formData.value).then(res => {
      if (res.status === 200 || res.status === 201) {
        router.push('/admin/rulesPage')
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
      ElMessage({
        type: 'error',
        message: err.response.data.message,
      })
    })
  }
}
const delMove = function (index) {
  formData.value.actions.splice(index, 1)
}
const addMove = function () {
  router.push({
    name: 'AddAction',
    query: {
      name: router.currentRoute.value.query.name
    },
    params: {
      actionObj: JSON.stringify({})
    }
  })
}
const editMove = function (item, index) {
  router.push({
    name: 'AddAction',
    query: {
      name: router.currentRoute.value.query.name,
      index: String(index)
    },
    params: {
      actionObj: JSON.stringify(item)
    }
  })
}


// 数据源示例
// 数据源示例列表
let dataSourceList = ref([])
// 获取数据源示例列表
const getDataSourceFun = async function () {
  await getDataSourceApi().then(async res => {
    if (res.status === 200) {
      for (let i = 0; i < res.data.length; i++) {
        dataSourceList.value.push({
          name: res.data[i]
        })
        await getDataSourceDetailApi(res.data[i]).then(res => {
          if (res.status === 200) {
            dataSourceList.value[i].type = res.data.Options.type
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
          ElMessage({
            type: 'error',
            message: err.response.data.message,
          })
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}


// 运行测试**************************************************************************
// 是否正在运行
let isRunning = ref(false)
// 清除运行测试
const delTestFun = function () {
  monacoEditor3.setValue('')
}
// 停止运行测试
const stopTestFun = function () {
  ruletestStopApi(formData.value.id).then(res => {
    if (res.status === 200) {
      isRunning.value = false
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
// 开始运行测试
const startTestFun = function () {
  let data = {
    id: formData.value.id,
    sql: formData.value.sql,
    mockSource: {},
    sinkProps: {
      dataTemplate: monacoEditor4 !== undefined ? monacoEditor4.getValue() : '',
      sendSingle: true
    }
  }
  if (dataSourceFlag) {
    for (let i = 0; i < dataSourceFormData.value.length; i++) {
      if (data.mockSource[dataSourceFormData.value[i].dataSourceSQL] !== undefined) {
        data.mockSource[dataSourceFormData.value[i].dataSourceSQL] = {
          loop: dataSourceFormData.value[i].loop,
          interval: dataSourceFormData.value[i].sendTime,
          data: [JSON.parse(payloadArr[i].getValue())]
        }
      }
    }
  } else {
    data.mockSource = {}
  }


  console.log(data)

  ruletestApi(data).then(res => {
    if (res.status === 200) {
      ruletestStartApi(formData.value.id).then(res => {
        if (res.status === 200) {
          isRunning.value = true
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message,
          })
        }
      }).catch(err => {
        console.log(err);
        ElMessage({
          type: 'error',
          message: err.response.data.message,
        })
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}


let monacoEditor = null
let monacoEditor2 = null
let monacoEditor3 = null
// 编辑器默认配置
let monacoOption = ref({
  language: 'mql', //语言
  theme: 'vs', // 编辑器主题：vs, hc-black, or vs-dark
  autoIndent: true, // 自动缩进
  readOnly: false, // 是否只读
  minimap: {   //是否显示缩略图
    enabled: false
  },
  scrollbar: { // 滚动条设置
    verticalScrollbarSize: 2, // 竖滚动条
    horizontalScrollbarSize: 2, // 横滚动条
  }
})

// 四位数随机字符串
function generateRandomString() {
  let chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  let stringLength = 4;
  let randomString = '';

  for (let i = 0; i < stringLength; i++) {
    let randomIndex = Math.floor(Math.random() * chars.length);
    randomString += chars.charAt(randomIndex);
  }

  return randomString;
}

onMounted(async () => {
  const container = document.querySelector('.sqlMonaco')
  monacoEditor = monaco.editor.create(container, {
    value: formData.value.sql, // 编辑器的值
    ...monacoOption.value
  })

  const container3 = document.querySelector('.debugRuleMonaco')
  monacoEditor3 = monaco.editor.create(container3, {
    value: '', // 编辑器的值
    ...monacoOption.value,
    readOnly: true,
  })


  // 获取数据源示例
  await getDataSourceFun()
  // 判断如果是编辑就获取详情
  if (router.currentRoute.value.query.name) {
    await ruleDetailFun(router.currentRoute.value.query.name)
  } else {
    // 如果不是编辑就随机生成id
    function getId() {
      formData.value.id = 'rule_' + generateRandomString()
      getListApi().then(res => {
        if (res.status === 200) {
          res.data
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].id === formData.value.id) {
              getId()
            }
          }
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
        ElMessage({
          type: 'error',
          message: err.response.data.message,
        })
      })
    }

    getId()
  }


  let obj = {}
  if (router.currentRoute.value.query.index && router.currentRoute.value.params.actionObj && router.currentRoute.value.params.actionObj !== '{}') {
    obj[JSON.parse(router.currentRoute.value.params.actionObj).type] = JSON.parse(router.currentRoute.value.params.actionObj)
    formData.value.actions.splice(Number(router.currentRoute.value.query.index), 1, obj)
  } else if (router.currentRoute.value.params.actionObj && router.currentRoute.value.params.actionObj !== '{}') {
    obj[JSON.parse(router.currentRoute.value.params.actionObj).type] = JSON.parse(router.currentRoute.value.params.actionObj)
    formData.value.actions.push(obj)
  }

  // 禁止折叠面板按钮点击跳转
  let ele = document.querySelectorAll('.el-collapse-item button')
  for (let i = 0; i < ele.length; i++) {
    ele[i].addEventListener('click', function (event) {
      event.preventDefault();
    });
  }
})


</script>

<style lang="less" scoped>
</style>
