<template>
  <div class="editConfig">
    <div class="title">
      <p>
        <span @click="goBack">地区电价配置</span>
        <span> / </span>
        <span>{{ strategyForm.id ? '编辑' : '新增' }}电价配置</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        {{ strategyForm.id ? '编辑' : '新增' }}电价配置
      </p>
    </div>
    <div class="content">
      <!--数据列表内容-->
      <div class="contain">
        <el-form
            ref="strategyFormRef"
            :model="strategyForm"
            :rules="strategyRules"
            class="strategyForm"
            status-icon
        >
          <!--选择省市-->
          <div class="topBox">
            <el-form-item class="topFormItem" label="区域" prop="provinceName">
              <el-select v-model="strategyForm.provinceName" placeholder="请选择区域">
                <el-option v-for="(item,index) in categoryList" :key="index" :label="item"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="topFormItem" label="名称" prop="categoryName">
              <el-input v-model="strategyForm.categoryName" placeholder="请输入名称"/>
            </el-form-item>
          </div>
          <!--分时策略标题-->
          <div class="title">分时策略
            <el-tooltip
                class="box-item"
                content="00-24.00,全天24小时，不可重复、交叉"
                effect="dark"
                placement="top"
            >
              <el-icon>
                <QuestionFilled/>
              </el-icon>
            </el-tooltip>
          </div>
          <!--分时策略数据-->
          <div class="strategy">
            <div class="timeBox">
              <div class="tit">分时策略：</div>
              <div v-for="(item,index) in strategyForm.sharpTimeListJsonStrCopy" :key="index"
                   class="sharp">
                <el-form-item :label="'尖时段'+Number(index+1)+':'">
                  <el-select v-model="item.startTime" placeholder="开始时间">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.endTime" placeholder="结束时间" style="margin-left: 20px">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                </el-form-item>
                <el-icon v-if="strategyForm.sharpTimeListJsonStrCopy.length>1" class="delIcon"
                         @click="delTime(1)">
                  <Delete/>
                </el-icon>
                <el-icon v-if="strategyForm.sharpTimeListJsonStrCopy.length===index+1" class="addIcon"
                         @click="addTime(1,index)">
                  <Plus/>
                </el-icon>
              </div>
              <div v-for="(item,index) in strategyForm.peakTimeListJsonStrCopy" :key="index" class="peak">
                <el-form-item :label="'峰时段'+Number(index+1)+':'">
                  <el-select v-model="item.startTime" placeholder="开始时间">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.endTime" placeholder="结束时间" style="margin-left: 20px">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                </el-form-item>
                <el-icon v-if="strategyForm.peakTimeListJsonStrCopy.length>1" class="delIcon" @click="delTime(2,index)">
                  <Delete/>
                </el-icon>
                <el-icon v-if="strategyForm.peakTimeListJsonStrCopy.length===index+1" class="addIcon"
                         @click="addTime(2)">
                  <Plus/>
                </el-icon>
              </div>
              <div v-for="(item,index) in strategyForm.flatTimeListJsonStrCopy" :key="index" class="flat">
                <el-form-item :label="'平时段'+Number(index+1)+':'">
                  <el-select v-model="item.startTime" placeholder="开始时间">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.endTime" placeholder="结束时间" style="margin-left: 20px">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                </el-form-item>
                <el-icon v-if=" strategyForm.flatTimeListJsonStrCopy.length>1" class="delIcon"
                         @click="delTime(3,index)">
                  <Delete/>
                </el-icon>
                <el-icon v-if=" strategyForm.flatTimeListJsonStrCopy.length===index+1" class="addIcon"
                         @click="addTime(3)">
                  <Plus/>
                </el-icon>
              </div>
              <div v-for="(item,index) in strategyForm.valleyTimeListJsonStrCopy" :key="index" class="valley">
                <el-form-item :label="'谷时段'+Number(index+1)+':'">
                  <el-select v-model="item.startTime" placeholder="开始时间">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.endTime" placeholder="结束时间" style="margin-left: 20px">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                </el-form-item>
                <el-icon v-if="strategyForm.valleyTimeListJsonStrCopy.length>1" class="delIcon"
                         @click="delTime(4,index)">
                  <Delete/>
                </el-icon>
                <el-icon v-if="strategyForm.valleyTimeListJsonStrCopy.length===index+1" class="addIcon"
                         @click="addTime(4)">
                  <Plus/>
                </el-icon>
              </div>
            </div>
            <div class="priceBox">
              <div class="priceBox1">
                <div class="tit">电网电价
                  <el-tooltip
                      class="box-item"
                      content="从电网购电的电价"
                      effect="dark"
                      placement="top"
                  >
                    <el-icon>
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="sharp">
                  <el-form-item label="尖时刻电价" prop="strategyForm.sharpFee">
                    <el-input v-model="strategyForm.sharpFee" type="number">
                      <template #append>元/kWh</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="peak">
                  <el-form-item label="峰时刻电价" prop="strategyForm.peakFee">
                    <el-input v-model="strategyForm.peakFee" type="number">
                      <template #append>元/kWh</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="flat">
                  <el-form-item label="平时刻电价" prop="strategyForm.flatFee">
                    <el-input v-model="strategyForm.flatFee" type="number">
                      <template #append>元/kWh</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="valley">
                  <el-form-item label="谷时刻电价" prop="strategyForm.valleyFee">
                    <el-input v-model="strategyForm.valleyFee" type="number">
                      <template #append>元/kWh</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!--取消保存操作-->
      <div class="footer">
        <el-button class="resetButton" @click="goBack">取消</el-button>
        <el-button type="primary" @click="submitClick">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './editConfig.less'
import {Back, Delete, Plus, QuestionFilled, Timer} from '@element-plus/icons-vue'
import {ElMessage} from 'element-plus'
import {useRouter} from 'vue-router';
import {saveConfigApi, getDetailConfigApi, getConfigProvinceApi} from '@/api/modules/electrovalence'
import qs from "qs";

let router = useRouter()
// 返回-------------------------------------------------------------------
const goBack = function () {
  router.push('/admin/rateAllocation')
}


// 时间列表
let timeList = ref(['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59'])


// 电价表单数据-------------------------------------------------------------------
let strategyForm = ref({
  id: 0,
  provinceName: '',
  categoryName: '',
  sharpFee: '',
  sharpTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: ''
    }
  ],
  peakFee: '',
  peakTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: ''
    }
  ],
  flatFee: '',
  flatTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: ''
    }
  ],
  valleyFee: '',
  valleyTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: ''
    }
  ],
})
// 版本表单验证规则
let strategyRules = ref({
  provinceName: [
    {required: true, message: '请选择区域', trigger: 'blur'},
  ],
  categoryName: [
    {required: true, message: '请输入名称', trigger: 'blur'},
  ]
})

// 选择省市-------------------------------------------------------------------
let categoryList = ref([])
const getConfigProvinceFun = function () {
  getConfigProvinceApi().then(res => {
    if (res.data.code === 0) {
      categoryList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 新增删除操作-------------------------------------------------------------------
// 新增时段
const addTime = function (idx) {
  if (idx === 1) {
    strategyForm.value.sharpTimeListJsonStrCopy.push({
      startTime: '',
      endTime: ''
    })
  } else if (idx === 2) {
    strategyForm.value.peakTimeListJsonStrCopy.push({
      startTime: '',
      endTime: ''
    })
  } else if (idx === 3) {
    strategyForm.value.flatTimeListJsonStrCopy.push({
      startTime: '',
      endTime: ''
    })
  } else if (idx === 4) {
    strategyForm.value.valleyTimeListJsonStrCopy.push({
      startTime: '',
      endTime: ''
    })
  }
}
// 删除时段
const delTime = function (idx, index) {
  if (idx === 1) {
    strategyForm.value.sharpTimeListJsonStrCopy.splice(index, 1)
  } else if (idx === 2) {
    strategyForm.value.peakTimeListJsonStrCopy.splice(index, 1)
  } else if (idx === 3) {
    strategyForm.value.flatTimeListJsonStrCopy.splice(index, 1)
  } else if (idx === 4) {
    strategyForm.value.valleyTimeListJsonStrCopy.splice(index, 1)
  }
}


// 提价表单----------------------------------------------------------------------
// 表单元素
let strategyFormRef = ref()
// 提交表单
const submitClick = () => {
  // 提交表单
  if (strategyFormRef) {
    strategyFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        strategyForm.value.sharpTimeListJsonStr = JSON.stringify(strategyForm.value.sharpTimeListJsonStrCopy)
        strategyForm.value.peakTimeListJsonStr = JSON.stringify(strategyForm.value.peakTimeListJsonStrCopy)
        strategyForm.value.flatTimeListJsonStr = JSON.stringify(strategyForm.value.flatTimeListJsonStrCopy)
        strategyForm.value.valleyTimeListJsonStr = JSON.stringify(strategyForm.value.valleyTimeListJsonStrCopy)
        saveConfigApi(qs.stringify(strategyForm.value)).then(res => {
          if (res.data.code === 0) {
            router.push('/admin/rateAllocation')
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
};


onMounted(() => {
  if (router.currentRoute.value.query.id) {
    getDetailConfigApi(qs.stringify({id: router.currentRoute.value.query.id})).then(res => {
      if (res.data.code === 0) {
        let data = JSON.parse(JSON.stringify(res.data.result))
        strategyForm.value.id = data.id
        strategyForm.value.provinceName = data.provinceName
        strategyForm.value.categoryName = data.categoryName
        strategyForm.value.sharpFee = data.sharpRate.fee
        strategyForm.value.peakFee = data.peakRate.fee
        strategyForm.value.flatFee = data.flatRate.fee
        strategyForm.value.valleyFee = data.valleyRate.fee
        strategyForm.value.sharpTimeListJsonStrCopy = data.sharpRate.timeList
        strategyForm.value.peakTimeListJsonStrCopy = data.peakRate.timeList
        strategyForm.value.flatTimeListJsonStrCopy = data.flatRate.timeList
        strategyForm.value.valleyTimeListJsonStrCopy = data.valleyRate.timeList
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
  getConfigProvinceFun()
})


</script>

<style lang="less" scoped>
</style>
