import {api} from "@/api";


// 获取查询方案列表
export const getQueryListApi = (type) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/query/list/${type}`,
        method: 'GET',
    })
}


// 获取查询方案详情
export const getQueryDetailApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/query/detail/${id}`,
        method: 'GET',
    })
}

// 新增查询方案列表
export const saveQueryListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/query/save',
        method: 'POST',
        data: data
    })
}

// 编辑查询方案列表
export const editQueryListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/query/update',
        method: 'POST',
        data: data
    })
}

// 删除查询方案列表
export const delQueryListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/query/remove/${id}`,
        method: 'POST',
    })
}


// 图表查询
export const chatSearchApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/data/getCompositeData',
        method: 'POST',
        data: data
    })
}


// 导出
export const exportComDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        responseType: "blob",
        url: `/zouk/message/rest/data/exportExcelForCompositeData`,
        method: 'POST',
        data: data
    })
}