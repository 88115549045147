import {api} from "@/api";

// 获取电价列表
export const getListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRate/page',
        method: 'POST',
        data: data
    })
}

// 获取电价详情
export const getListDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRate/getDetail',
        method: 'POST',
        data: data
    })
}

// 编辑电价
export const editListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRate/save',
        method: 'POST',
        data: data
    })
}

// 删除电价
export const delListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRate/delete',
        method: 'POST',
        data: data
    })
}


// 地区费率配置
// 获取省选项
export const getConfigProvinceApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRateConfig/listProvince',
        method: 'POST',
        data: data
    })
}
// 获取配置项
export const getConfigSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRateConfig/listSelect',
        method: 'POST',
        data: data
    })
}
// 获取地区配置列表
export const getConfigListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRateConfig/page',
        method: 'POST',
        data: data
    })
}
// 获取地区配置详情
export const getDetailConfigApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRateConfig/getDetail',
        method: 'POST',
        data: data
    })
}

// 保存地区配置
export const saveConfigApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRateConfig/save',
        method: 'POST',
        data: data
    })
}

// 删除地区配置
export const delConfigApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRateConfig/delete',
        method: 'POST',
        data: data
    })
}
