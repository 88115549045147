import {api3} from "@/api";

// 获取插件类型
export const getSelectListApi = (data) => {
    return api3({
        url: '/api/neuron/plugin',
        method: 'GET',
        params: data
    })
}

// 根据插件类型获取表单数据
export const getFormDataApi = (data) => {
    return api3({
        url: '/api/neuron/schema',
        method: 'GET',
        params: data
    })
}

// 获取北向应用列表
export const getListApi = (data) => {
    return api3({
        url: '/api/neuron/node',
        method: 'GET',
        params: data
    })
}

// 获取北向应用列表中的数据
export const getListDataApi = (data) => {
    return api3({
        url: '/api/neuron/node/state',
        method: 'GET',
        params: data
    })
}

// 北向应用停止或开始运行
export const controlRunApi = (data) => {
    return api3({
        url: '/api/neuron/node/ctl',
        method: 'POST',
        data: data
    })
}

// 新增北向应用
export const addDataApi = (data) => {
    return api3({
        url: '/api/neuron/node',
        method: 'POST',
        data: data
    })
}

// 编辑北向应用
export const editDataApi = (data) => {
    return api3({
        url: '/api/neuron/node',
        method: 'PUT',
        data: data
    })
}

// 删除北向应用
export const delDataApi = (data) => {
    return api3({
        url: '/api/neuron/node',
        method: 'DELETE',
        data: data
    })
}


// 北向应用--控制开启或关闭DEBUG日志
export const controlLogApi = (data) => {
    return api3({
        url: '/api/neuron/log/level',
        method: 'PUT',
        data: data
    })
}

// 北向应用--下载驱动日志  data为应用名称
export const downloadLogApi = (name) => {
    return api3({
        responseType: "blob",
        url: `/api/log/neuron/${name}`,
        method: 'GET',
    })
}


// 获取应用组列表***************************************************************************
export const getGroupListApi = (data) => {
    return api3({
        url: '/api/neuron/subscribe',
        method: 'GET',
        params: data
    })
}

// 获取树级结构第一层    type=1
export const getTreeListApi = (data) => {
    return api3({
        url: '/api/neuron/node',
        method: 'GET',
        params: data
    })
}
// 获取树级结构   node=battery
export const getTreeDataApi = (data) => {
    return api3({
        url: '/api/neuron/group',
        method: 'GET',
        params: data
    })
}


// 新增应用组列表
export const addGroupListApi = (data) => {
    return api3({
        url: '/api/neuron/subscribes',
        method: 'POST',
        data: data
    })
}

// 取消应用组列表
export const delGroupListApi = (data) => {
    return api3({
        url: '/api/neuron/subscribe',
        method: 'DELETE',
        data: data
    })
}


// 应用详情***************************************************************************

//获取数据统计     category=app&node=DataProcessing
export const getMetricsApi = (data) => {
    return api3({
        url: '/api/neuron/metrics',
        method: 'GET',
        params: data
    })
}


// 获取应用配置    node=DataProcessing
export const getSettingApi = (data) => {
    return api3({
        url: '/api/neuron/node/setting',
        method: 'GET',
        params: data
    })
}
// 保存应用配置    node=DataProcessing
export const saveSettingApi = (data) => {
    return api3({
        url: '/api/neuron/node/setting',
        method: 'POST',
        data: data
    })
}