<template>
  <div class="messagePage">
    <!--标题-->
    <div class="title">
      <el-icon @click="goBack">
        <Back/>
      </el-icon>
      <span>消息详情</span>
    </div>
    <!--搜索-->
    <div class="searchBox">
      <div class="searchItem">
        <span>消息类型：</span>
        <el-select v-model="type">
          <el-option :value="0" label="全部"/>
          <el-option :value="1" label="告警通知"/>
          <el-option :value="2" label="消息通知"/>
        </el-select>
      </div>
      <div class="searchItem">
        <span>消息来源：</span>
        <el-select v-model="from">
          <el-option :value="0" label="全部"/>
          <el-option v-for="(item,index) in menuList" :key="index" :label="item.name" :value="item.id"/>
        </el-select>
      </div>
      <div class="searchItem">
        <span>消息状态：</span>
        <el-select v-model="status">
          <el-option :value="0" label="全部"/>
          <el-option :value="1" label="已读"/>
          <el-option :value="2" label="未读"/>
        </el-select>
      </div>
    </div>
    <!--表格-->
    <div class="tabBox">
      <el-table v-loading="loding" :data="tableData" style="width: 100%">
        <el-table-column label="消息内容" prop="content" width="280">
          <template #default="scope">
            <p :title="scope.row.content" class="contVal">{{ scope.row.content }}</p>
          </template>
        </el-table-column>
        <el-table-column label="消息状态" prop="status"/>
        <el-table-column label="消息类型" prop="type"/>
        <el-table-column label="消息来源" prop="from">
          <template #default="scope">
            <a style="color: #409eff">{{ scope.row.from }}</a>
          </template>
        </el-table-column>
        <el-table-column label="通知时间" prop="time" width="180"/>
        <el-table-column label="操作" width="150">
          <template #default="scope">
            <p class="btn">标为已读</p>
            <p class="btn" style="color:rgb(255, 77, 79);">删除</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pag">
      <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                     layout="total,prev, pager, next,sizes" @size-change="handleSizeChange"
                     @current-change="pageChange"/>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {Back} from '@element-plus/icons-vue'
import {useRouter} from 'vue-router';
import {ElMessage, ElMessageBox} from "element-plus";
import qs from "qs";
import {getMenuListApi} from "@/api/modules/menu";

let router = useRouter()
// 返回
const goBack = function () {
  router.go(-1)
}


// 表格数据
let tableData = [
  {
    content: '17:41:34 | Grid fault (6c39373f5adae5d86fjr4c) 触发告警',
    status: '未读',
    type: '告警通知',
    from: '告警管理',
    time: '2016-05-03 17:14',
  },
  {
    content: '17:41:34 | Grid fault (6c39373f5adae5d86fjr4c) 触发告警',
    status: '未读',
    type: '告警通知',
    from: '告警管理',
    time: '2016-05-03 17:14',
  },
  {
    content: '17:41:34 | Grid fault (6c39373f5adae5d86fjr4c) 触发告警',
    status: '未读',
    type: '告警通知',
    from: '告警管理',
    time: '2016-05-03 17:14',
  },
  {
    content: '17:41:34 | Grid fault (6c39373f5adae5d86fjr4c) 触发告警',
    status: '未读',
    type: '告警通知',
    from: '告警管理',
    time: '2016-05-03 17:14',
  },
]


// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref(10)
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}


// 搜索
let type = ref(0)
let from = ref(0)
let status = ref(2)


// 获取列表
let loding = ref(false)
const getListFun = function () {
  loding.value = true
  // getWorkOrderListApi(qs.stringify({
  //   pageNumber: pageNumber.value,
  //   pageSize: pageSize.value,
  // })).then(res => {
  // loding.value=false
  //   if (res.data.code === 0) {
  //     tableData.value = res.data.result.list
  //     total.value = Number(res.data.result.total)
  //   } else {
  //     ElMessage({
  //       message: res.data.message,
  //       type: 'error'
  //     })
  //   }
  // }).catch(err => {
  //   console.log(err);
  // })
}
// 查询
const searchFun = function () {
  getListFun()
}


let menuList = ref([])
onMounted(() => {
  getMenuListApi(qs.stringify({type: 1})).then(res => {
    if (res.data.code === 0) {
      menuList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  })
})
</script>

<style lang="less" scoped>
.messagePage {
  margin: 32px auto;
  padding: 24px 32px;
  width: 946px;
  background-color: #fff;
  height: calc(100% - 112px);
  overflow: auto;
  //标题
  .title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    font-weight: 600;

    .el-icon {
      font-size: 20px;
      font-weight: bold;
      margin-right: 8px;
      transform: translateY(4px);
    }
  }

  //搜索
  .searchBox {
    display: flex;
    margin: 20px 0;

    .searchItem {
      margin-right: 20px;

      .el-select {
        width: 100px;
      }
    }
  }


  //表格
  .el-table {
    color: rgba(3, 10, 26, 0.85);

    .el-table__body-header, .el-table__header {
      th {
        background-color: rgb(250, 250, 250);
        font-weight: 500;
      }

      th:nth-child(2)::before, th:nth-child(3)::before, th:nth-child(4)::before, th:nth-child(5)::before, th:nth-child(6)::before, th:nth-child(7)::before {
        content: '';
        width: 1px;
        height: 1.6em;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    th, td {
      padding: 0;
    }

    .cell {
      padding: 14px 16px;

      .contVal {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: default;
      }

      .btn {
        display: inline-block;
        color: rgb(24, 144, 255);
        margin-right: 14px;
        cursor: pointer;
      }


    }
  }

  //分页
  .pag {
    width: 100%;
    height: 32px;
    padding: 16px 0;

    .el-pagination {
      float: right;
    }
  }
}
</style>
