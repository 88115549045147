<template>
  <div class="dataMonitoring">
    <div class="content">
      <div class="titleBox">
        <div>最后数据的时间 {{ lastTime }}</div>
        <div>产品型号：{{ productModel }}</div>
      </div>
      <div class="echartsTabBox">
        <div v-for="(item,index) in monitoringList" class="parentClass">
          <div class="echartsBox" style="position:relative;">
            <div class="leftDetail">
              <!--{{String(item.title).replace(/\s/g,"&ensp;")}}-->
              <div class="bigClass">{{ String(item.titleName).replace(/\s/g, "&ensp;") }}</div>
              <div class="signClass">
                <span class="numberValueClass">{{ item.value }}</span>
                <br/>
                <span style="position: absolute;bottom: 5px;right: 10px">{{ item.unitName }}</span>
              </div>
            </div>
            <div class="rightDetail">
              <div :id="forId(index)" class="echrtsClass"></div>
            </div>
            <div class="largeDetail">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/%E6%89%A9%E5%A4%A7.svg"
                   @click="dataSearch(item.id)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="数据查询" width="70%">
      <div class="searchHead">
        <span>日期： </span>
        <el-select v-model="timeFlag" clearable @change="timeFlagSelect">
          <el-option label="自定义时间段" value="-1"></el-option>
          <el-option label="近1小时" value="1H"></el-option>
          <el-option label="近12小时" value="12H"></el-option>
          <el-option label="近一天" value="1D"></el-option>
          <el-option label="近3天" value="3D"></el-option>
          <el-option label="近1周" value="1W"></el-option>
          <el-option label="本周" value="THIS_WEEK"></el-option>
          <el-option label="本月" value="1M"></el-option>
          <el-option label="本季" value="1Q"></el-option>
        </el-select>
        <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style" range-separator="→"
                        start-placeholder="开始时间" style="transform: translateY(3px);margin-left: 15px"
                        type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss">
        </el-date-picker>
        <el-input v-if="timeFlag!='1H'" v-model="frequency" :disabled="true" placeholder="请输入采样频率"
                  style="width: 40%;margin-left: 16px;" type="number">
          <template #append>
            <el-select v-model="timeUnit" style="width: 90px;">
              <el-option label="分钟" value="m"/>
              <el-option label="小时" value="h"/>
              <el-option label="天" value="d"/>
              <el-option label="月" value="n"/>
              <el-option label="年" value="y"/>
            </el-select>
          </template>
        </el-input>
        <el-button class="searchButton" style="text-align: right;" type="primary" @click="searchFun">查询</el-button>
      </div>
      <div class="chart"></div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="dialogVisible = false">关闭</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, onUnmounted, onDeactivated, nextTick} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus, Minus} from '@element-plus/icons-vue'
import {getList} from '@/api/modules/dataMonitoring'
import {
  getEquipmentListApi,
  getDataListApi,
  getEchartsDataApi,
  getTableDataApi,
  getSearchListApi,
  getSearchDetailApi,
  testApi,
  exportDataApi
} from '@/api/modules/dataSearch'
import moment from 'moment'
import './dataMonitoring.less'
import qs from 'qs';
import * as echarts from 'echarts';
// 定义传值类型
const props = defineProps({
  value: String //这里就接收到父组件传递的value
})
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

let productModel = ref()
let lastTime = ref()
let monitoringList = ref([])
//柱状图id(②数据监测)
const forId = function (index) {
  return 'geo_' + index;
}
const getListFun = function () {
  openFullScreen()
  getList(qs.stringify({
    id: props.value
  })).then(res => {
    loadingAdd.value.close();
    //console.log(res);
    if (res.data.ok) {
      lastTime.value = res.data.result.lastTime
      productModel.value = res.data.result.productModel
      monitoringList.value = res.data.result.list
      monitoringList.value.forEach(item => {
        item.valueList2 = []
      })
      monitoringList.value.forEach(item => {
        var arr = []
        if (item.valueList.length > 0) {
          item.valueList.forEach(items => {
            arr.push(item.maxValue * 0.02)
            item.valueList2 = arr
          })
        }
      })
      setTimeout(() => {
        drawLine()
      }, 100)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const drawLine = function () {
  var echarts = require('echarts');
  var roseCharts = document.getElementsByClassName('echrtsClass');
  for (var i = 0; i < roseCharts.length; i++) {
    var myChart = echarts.init(roseCharts[i]); //
    myChart.clear(); //切换折线图前要先清除
    myChart.setOption({
      tooltip: {
        trigger: 'axis', // axis   item   none三个值
        show: true,
        /*formatter: function(params) {
    return(
     params[0].name +
     "<br/>" +
     "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(24,144,255)'></span>" +
     params[0].seriesName + "<span style='display:inline-block;margin-right:5px'></span>" + params[0].data);
   }*/
        formatter: function (params) {
          return (params[0].data);
        }
      },
      /*图表位置*/
      grid: {
        x: 0, //左留白
        y: 10, //上留白
        x2: 10, //右留白
        y2: 0 //下留白
      },
      title: {},
      legend: {
        data: [],
        //data: ['销量', 'xxm'],
      },
      //x轴
      xAxis: {
        data: ["10-01", "10-02", "10-03", "10-04", "10-05", "10-06", "10-01", "10-02", "10-03", "10-04", "10-05", "10-06",],
        axisLine: {
          lineStyle: {
            color: '#ccc',
            width: 0 //x轴线
          }
        },
        //x轴线中的小竖的分割线
        axisTick: {
          show: false
        },
        show: false
      },
      //y轴
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#999',
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      series: [{
        name: '',
        type: 'bar',
        barGap: '-100%',
        stack: 'xxm',
        data: monitoringList.value[i].valueList,
        barWidth: '35%', //柱子的宽度
        itemStyle: {
          normal: {
            color: function (params) {
              if (params.dataIndex != 11) {
                return new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: "#E1E0E8" // 0% 处的颜色
                }, {
                  offset: 1,
                  color: "#1890FF" // 60% 处的颜色
                }], false)
              } else {
                return new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: "#BCB9D8" // 0% 处的颜色
                }, {
                  offset: 1,
                  color: "#1890FF" // 60% 处的颜色
                }], false)
              }
            }
          }
        }
      },
        {
          name: '',
          type: 'bar',
          stack: 'xxm',
          data: monitoringList.value[i].valueList2,
          barWidth: '35%', //柱子的宽度
          itemStyle: {
            normal: {
              color: function (params) {
                return '#000'
              },
              label: {
                position: 'top',
                color: '#000',
              },
            },
          },
        },
      ]
    });
  }
}
// const intervalId = setInterval(getListFun, 5000)
watch(() => props.value, (newValue, oldValue) => {
  setTimeout(() => {
    getListFun()
  }, 100)
}, {
  immediate: true
})
onUnmounted(() => {
  // clearInterval(intervalId)
})
onDeactivated(() => {
  // clearInterval(intervalId)
})
/*数据查询*/
let dialogVisible = ref(false)
const dataSearch = function (id) {
  equipmentArr.value[0].dictIds = []
  dialogVisible.value = true
  equipmentArr.value[0].dictIds.push(id)
  plan.value = null
  timeFlag.value = '1H'
  dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  frequency.value = 1
  timeUnit.value = 'm'
  yom.value = []
  echartsData.value = []
  echartsDataX.value = []
  tableData.value = []
  getTableDataFun()
}
// 搜索
let plan = ref() //选择的搜索方案
let timeFlag = ref('1H') //日期快捷选择
let dateTime = ref([moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]) //搜索日期
let frequency = ref(1) //频率
let timeUnit = ref('m') //频率单位
let yom = ref([]) //同环比
let equipmentArr = ref([{
  deviceId: props.value,
  dictIds: []
}]) //搜索的设备
// 日期快捷选择事件
const timeFlagSelect = function () {
  if (timeFlag.value === '-1') {
    dateTime.value = []
  } else if (timeFlag.value === '1H') {
    dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 1
    timeUnit.value = 'm'
  } else if (timeFlag.value === '12H') {
    dateTime.value = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 5
    timeUnit.value = 'm'
  } else if (timeFlag.value === '1D') {
    dateTime.value = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 10
    timeUnit.value = 'm'
  } else if (timeFlag.value === '3D') {
    dateTime.value = [moment().subtract(3, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 30
    timeUnit.value = 'm'
  } else if (timeFlag.value === '1W') {
    dateTime.value = [moment().subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 1
    timeUnit.value = 'h'
  } else if (timeFlag.value === 'THIS_WEEK') {
    dateTime.value = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')]
    frequency.value = 1
    timeUnit.value = 'h'
  } else if (timeFlag.value === '1M') {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
    frequency.value = 2
    timeUnit.value = 'h'
  } else if (timeFlag.value === '1Q') {
    dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format('YYYY-MM-DD HH:mm:ss')]
    frequency.value = 6
    timeUnit.value = 'h'
  }
}

// 设备列表
let equipmentList = ref([])
// 指标列表
let dataList = ref([])
// 获取设备列表
const getEquipmentListFun = async function () {
  await getEquipmentListApi().then(res => {
    if (res.data.code === 200) {
      equipmentList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取指标列表
const getDataListFun = async function (index, id) {
  if (id) {
    await getDataListApi(id).then(res => {
      if (res.data.code === 200) {
        dataList.value[index] = res.data.data
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 选择设备事件
let treeRef1 = ref()
const equipmentChange = function (index) {
  equipmentArr.value[index].dictIds = []
  // getDataListFun(index)
  getDataListFun(index, treeRef1.value[index].getCurrentNode().id)
  equipmentArr.value[index].deviceId = treeRef1.value[index].getCurrentNode().id
}
// 选择指标事件
const dataChange = function (index) {
  if (equipmentArr.value[index].dictIds.length > 5) {
    ElMessage({
      type: 'info',
      message: '最多选择5个指标'
    })
    equipmentArr.value[index].dictIds.splice(-1)
  }
}
// 点击新增设备
const addClick = function () {
  equipmentArr.value.push({
    deviceId: '',
    dictIds: []
  })
}
// 点击删除设备
const delClick = function (index) {
  equipmentArr.value.splice(index, 1)
  dataList.value.splice(index, 1)
}

// 获取图表数据
let titleName = ref()
let echartsData = ref([])
let echartsDataX = ref([])
const getEchartsDataFun = async function () {
  echartsDataX.value = []
  echartsData.value = []
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    params: equipmentArr.value,
    yom: yom.value[0]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.params.length; i++) {
    if (!data.params[i].deviceId || (data.params[i].dictIds.length === 0)) {
      flag = true
    }
  }
  if (data.startTime && data.endTime && data.frequency && data.timeUnit) {
    openFullScreen()
    await getEchartsDataApi(data).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 200) {
        echartsDataX.value = res.data.data.xAxis
        echartsData.value = res.data.data.yAxis
        for (let i = 0; i < echartsData.value.length; i++) {
          echartsData.value[i].name = echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName
          echartsData.value[i].type = 'line'
          echartsData.value[i].lineStyle = {
            width: 1
          }
          echartsData.value[i].symbol = 'circle'
          // echartsData.value[i].symbol = 'none'
          echartsData.value[i].sampling = 'lttb'
          for (let j = 0; j < echartsData.value[i].data.length; j++) {
            echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
          }
        }
        console.log(echartsData.value, echartsDataX.value)
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}
// 获取表格数据
let tableData = ref([])
let pageData = ref([])
const getTableDataFun = async function () {
  pageData.value = []
  tableData.value = []
  for (let i = 0; i < equipmentArr.value.length; i++) {
    pageData.value.push({
      pageNum: 1,
      pageSize: 10
    })
    let data = {
      startTime: dateTime.value ? dateTime.value[0] : '',
      endTime: dateTime.value ? dateTime.value[1] : '',
      pageNum: pageData.value[i].pageNum,
      pageSize: 1000,
      frequency: frequency.value,
      timeUnit: timeUnit.value,
      timeUnit: 'm',
      params: [equipmentArr.value[i]]
    }
    // 计算同环比时间
    if (yom.value && yom.value[0] == 1) {
      data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      console.log(data.lastStartTime, data.lastEndTime)
    } else if (yom.value && yom.value[0] == 2) {
      if (timeFlag.value === '1M') {
        let lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
        let lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      } else if (timeFlag.value === '1Q') {
        data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      } else {
        let timeLength = moment(data.endTime).diff(data.startTime, 'second')
        data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      }
    }
    let flag = false
    for (let i = 0; i < data.params.length; i++) {
      if (!data.params[i].deviceId || (data.params[i].dictIds.length === 0)) {
        flag = true
      }
    }
    if (data.startTime && data.endTime && data.frequency) {
      openFullScreen()
      await getTableDataApi(data).then(res => {
        loadingAdd.value.close()
        if (res.data.code === 200) {
          echartsDataX.value = []
          echartsData.value = [{
            data: []
          }]
          if (res.data.data.list.length > 0) {
            var arr = JSON.parse(JSON.stringify(res.data.data.list[0].dataMetricsVO.reverse()))
            for (var i = 0; i < arr.length; i++) {
              echartsDataX.value.push(arr[i].ts)
              echartsData.value[0].data.push(arr[i].metricDataList[0].val)
              for (let i = 0; i < echartsData.value.length; i++) {
                echartsData.value[i].name = arr[i].metricDataList[0].titleName
                echartsData.value[i].type = 'line'
                echartsData.value[i].lineStyle = {
                  width: 1
                }
                echartsData.value[i].symbol = 'circle'
                echartsData.value[i].sampling = 'lttb'
                for (let j = 0; j < echartsData.value[i].data.length; j++) {
                  echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
                }
              }
            }
          }
          nextTick(() => {
            chartLoad()
          })
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage({
        type: 'info',
        message: '请选择筛选条件'
      })
    }
  }
}

const moreClick = async function (index) {
  openFullScreen()
  pageData.value[index].pageNum = pageData.value[index].pageNum + 1
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    pageNum: pageData.value[index].pageNum,
    pageSize: 10,
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    params: [equipmentArr.value[index]]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    console.log(data.lastStartTime, data.lastEndTime)
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      let lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      let lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  await getTableDataApi(data).then(res => {
    loadingAdd.value.close()
    if (res.data.code === 200) {
      let arr = res.data.data.list
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].labelArr = []
          for (let j = 0; j < arr[i].dataMetricsVO.length; j++) {
            arr[i].dataMetricsVO[j].ts = arr[i].dataMetricsVO[j].ts.slice(0, 10) + ' ' + arr[i].dataMetricsVO[j].ts.slice(11, 19)
            for (let k = 0; k < arr[i].dataMetricsVO[j].metricDataList.length; k++) {
              arr[i].labelArr.push(arr[i].dataMetricsVO[j].metricDataList[k].titleName)
              arr[i].labelArr = Array.from(new Set(arr[i].labelArr))
              let name = arr[i].dataMetricsVO[j].metricDataList[k].titleName
              arr[i].dataMetricsVO[j][name] = Number(arr[i].dataMetricsVO[j].metricDataList[k].val).toFixed(2)
            }
          }
        }
        tableData.value[index].dataMetricsVO.push(...arr[0].dataMetricsVO)
        tableData.value[index].labelArr.push(...arr[0].labelArr)
        tableData.value[index].labelArr = Array.from(new Set(tableData.value[index].labelArr))
        console.log(tableData.value)
      } else {
        ElMessage({
          type: 'info',
          message: '没有更多数据了！'
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 查询
const searchFun = async function () {
  if (timeFlag.value == '1H') {
    getTableDataFun()
  } else {
    getEchartsDataFun()
  }
}
// 重置
const resetFun = function () {
  plan.value = ''
  dateTime.value = ''
  frequency.value = 1
  timeUnit.value = ''
  timeFlag.value = ''
  yom.value = []
  equipmentArr.value = [{
    deviceId: '',
    dictIds: []
  }]

  echartsData.value = []
  echartsDataX.value = []
  tableData.value = []
  nextTick(() => {
    chartLoad()
  })
}

// 控制展示图表还是表格
let showFlag = ref(true)
const showClick = async function (flag) {
  // showFlag.value = flag === 1;
  if (flag === 1) {
    showFlag.value = true
    await getEchartsDataFun()
    if (tableData.value.length > 0) {
      await nextTick(() => {
        chartLoad()
      })
    }
  } else {
    await getTableDataFun()
    showFlag.value = false
  }
}

// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    dataZoom: [{
      type: 'inside',
      start: 0,
      end: 100,
      disabled: false
    },
      {
        start: 0,
        end: 100
      }
    ],
    series: echartsData.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}

// 点击导出
const exportFun = function () {
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    params: equipmentArr.value,
    yom: yom.value[0]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.params.length; i++) {
    if (!data.params[i].deviceId || (data.params[i].dictIds.length === 0)) {
      flag = true
    }
  }

  if (data.startTime && data.endTime && data.frequency && data.timeUnit && !flag) {
    const downloadZip = (data, fileName = '数据查询') => {
      var blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      if ('download' in document.createElement('a')) {
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    }
    openFullScreen()
    exportDataApi(data).then(res => {
      loadingAdd.value.close()
      downloadZip(res.data)
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}

// 获取查询方案列表
let planList = ref([])
const getSearchListFun = async function () {
  await getSearchListApi(0).then(res => {
    if (res.data.code === 200) {
      planList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询方案change事件
const planChange = async function () {
  await getSearchDetailApi(plan.value).then(res => {
    if (res.data.code === 200) {
      frequency.value = res.data.data.frequency
      timeUnit.value = res.data.data.timeUnit
      timeFlag.value = res.data.data.timeFlag
      yom.value = res.data.data.yom ? [res.data.data.yom] : []
      timeFlagSelect()
      if (timeFlag.value === '-1') {
        dateTime.value = [res.data.data.startTime, res.data.data.endTime]
      }
      equipmentArr.value = res.data.data.deviceConditionList
      for (let i = 0; i < res.data.data.deviceConditionList.length; i++) {
        getDataListFun(i, res.data.data.deviceConditionList[i].deviceId)
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 树形结构数据
let allTree = ref()
onMounted(async () => {
  // getEquipmentListFun()
  //		await getSearchListFun()
  //			await planChange()
  //			await getEchartsDataFun()

})
</script>

<style>

</style>
