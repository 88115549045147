import {api3} from "@/api";
//获取数据监控一级节点
export const dataMonitoring = () => {
    return api3({
        url: '/api/neuron/node',
        method: 'GET',
        params: {type: 1}
    })
}
//获取数据监控二级节点
export const dataMonitoring2 = (data) => {
    return api3({
        url: '/api/neuron/group',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        params: data
    })
}
//获取数据监控表数据
export const dataTableData = (data) => {
    return api3({
        url: '/api/neuron/tags',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        params: data
    })
}
//获取数据监控表数据
export const dataTableData2 = (data) => {
    return api3({
        url: '/api/neuron/read',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}
//数据监控写入
export const dataWrite = (data) => {
    return api3({
        url: '/api/neuron/write',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}