<template>
	<div class="energyLargeScreen" v-show="router.currentRoute.value.query.id">
		<div class="title">
			<p>
				<span>能耗大屏</span>
			</p>
		</div>
		<div class="content">
			<!--上面-->
			<!--<div class="topContain">
				<div class="itemBox">
					<p>上月数据</p>
					<div class="itemCont itemCont1">
						<p>CO2排放：<span style="color: #00DC34;">123</span>吨</p>
						<el-progress :show-text="false" :stroke-width="36" color="#00DC34" :percentage="80" />
						<p style="display:inline-block;margin-top: 16px;">标准煤：<span style="color: #00AAE8;">123</span>吨</p>
						<el-progress :show-text="false" :stroke-width="36" color="#00AAE8" :percentage="80" />
					</div>
				</div>
				<div class="itemBox">
					<p style="color: transparent;">耗电量</p>
					<div class="itemCont">
						<div class="kChart2 kChart"></div>
					</div>
				</div>
				<div class="itemBox">
					<p>本月数据</p>
					<div class="itemCont">
						<span>上月电费：<span style="color: #00DC34;">123</span>元</span>
						<el-progress :show-text="false" :stroke-width="20" color="#00DC34" :percentage="80" />
						<span style="display:inline-block;margin-top: 20px;">本月电费：<span style="color: #00AAE8;">123</span>元</span>
						<el-progress :show-text="false" :stroke-width="20" color="#00AAE8" :percentage="80" />
						<span style="display:inline-block;margin-top: 20px;">(截至到昨日零点)</span>
					</div>
				</div>
				<div class="itemBox">
					<p style="color: transparent;">最大需量</p>
					<div class="itemCont">
						<div class="kChart4 kChart"></div>
					</div>
				</div>
			</div>-->
			<div class="topContain">
				<div class="itemBox itemBox5" v-show="electricTotalPowerData.type">
					<!--<div class="itemBox">-->
					<p :class="[isFullScreen?'pWhite':'pBlack']">当日数据</p>
					<div class="itemCont">
						<img src="../../assets/img/allscreen.svg" alt="" v-show="!isFullScreen" @click="fullScreenHandle('.itemBox5')" />
						<img src="../../assets/img/caclescreen.svg" alt="" @click="fullScreenHandle('.itemBox5')" v-show="isFullScreen" />
						<div class="kChart5 kChart"></div>
					</div>
				</div>
				<div class="itemBox itemBox6" v-show="dailyElectricityData.type">
					<!--<div class="itemBox">-->
					<p :class="[isFullScreen?'pWhite':'pBlack']">近7天数据</p>
					<div class="itemCont">
						<img src="../../assets/img/allscreen.svg" alt="" v-show="!isFullScreen" @click="fullScreenHandle('.itemBox6')" />
						<img src="../../assets/img/caclescreen.svg" alt="" @click="fullScreenHandle('.itemBox6')" v-show="isFullScreen" />
						<div class="kChart6 kChart"></div>
					</div>
				</div>
				<div class="itemBox itemBox7" v-show="monthElectricityData.type">
					<!--<div class="itemBox">-->
					<p :class="[isFullScreen?'pWhite':'pBlack']">近30天数据</p>
					<div class="itemCont">
						<img src="../../assets/img/allscreen.svg" alt="" v-show="!isFullScreen" @click="fullScreenHandle('.itemBox7')" />
						<img src="../../assets/img/caclescreen.svg" alt="" @click="fullScreenHandle('.itemBox7')" v-show="isFullScreen" />
						<div class="kChart7 kChart"></div>
					</div>
				</div>
				<!--<div class="itemBox">
					<p style="color: transparent;">最大负荷</p>
					<div class="itemCont">
						<div class="kChart8 kChart"></div>
					</div>
				</div>-->
			</div>
		</div>
	</div>
</template>

<script setup>
	//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
	//例如:import 《组件名称》 from '《组件路径》';
	import { nextTick, onMounted, reactive, ref } from "vue"
	import './energyLargeScreen.less'
	import * as echarts from 'echarts';
	import { useRouter } from 'vue-router';
	import qs from "qs";
	import { Delete, ZoomIn, Plus, Back, CircleClose, CirclePlus } from '@element-plus/icons-vue'
	import {
		getData
	} from '@/api/modules/energyLargeScreen.js'
	import { ElMessage } from "element-plus";
	let router = useRouter()
	let electricTotalPowerData = ref({})
	let dailyElectricityData = ref({})
	let monthElectricityData = ref({})
	const getDataFun = function() {
		getData(qs.stringify({
			id: router.currentRoute.value.query.id
		})).then(res => {
			//console.log(res)
			if(res.data.code === 0) {
				res.data.result.forEach(item => {
					for(var i = 0; i < item.data.yAxis[0].data.length; i++) {
						item.data.yAxis[0].data[i] = Number(item.data.yAxis[0].data[i]).toFixed(2)
					}
					if(String(item.type).indexOf('1') != -1) { //总有功功率
						electricTotalPowerData.value = item
						nextTick(() => {
							echarts5()
						})
					}
					if(String(item.type).indexOf('2') != -1) { //日用电量
						dailyElectricityData.value = item
						nextTick(() => {
							echarts6()
						})
					}
					if(String(item.type).indexOf('3') != -1) { //月用电量
						monthElectricityData.value = item
						nextTick(() => {
							echarts7()
						})
					}
				})
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error',
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	const echarts2 = function() {
		var chartDom = document.querySelector('.kChart2')
		var myChart = echarts.init(chartDom);
		var option;
		option = {
			title: {
				text: '耗电量20MWh',
				textStyle: {
					fontSize: 12,
					color: '#666',
				},
				left: 'center'
			},
			tooltip: {
				trigger: 'item'
			},
			color: ['#02E1FC', '#00AAE8', '#00DC34', '#ef6567', '#f9c956', '#75bedc'],
			series: [{
				name: '',
				type: 'pie',
				radius: ['40%', '70%'],
				avoidLabelOverlap: false,
				label: {
					show: true,
				},
				labelLine: {
					show: true,
					color: '#000'
				},
				data: [{
						value: 1048,
						name: '尖',
					},
					{
						value: 735,
						name: '峰'
					},
					{
						value: 580,
						name: '平'
					},
					{
						value: 484,
						name: '谷'
					},
				]
			}],
		};
		myChart.clear()
		option && myChart.setOption(option);
		myChart.resize();
		window.onresize = () => {
			myChart.resize();
		}
	}
	const echarts4 = function() {
		var chartDom = document.querySelector('.kChart4')
		var myChart = echarts.init(chartDom);
		var option;
		option = {
			title: {
				text: '最大需量',
				textStyle: {
					fontSize: 14,
					color: '#666',
				},
				left: 'center',
			},
			tooltip: {
				trigger: 'axis', // axis   item   none三个值
				show: true
			},
			legend: {
				data: ['Email', 'Direct'],
				orient: 'vertical',
				bottom: '0',
				left: '0',
				itemHeight: 10,
				itemWidth: 20,
				textStyle: {
					fontSize: 10,
					color: "#333",
				},
			},
			grid: {
				x: 30, //左留白
				y: 30, //上留白
				x2: 30, //右留白
				y2: 60 //下留白
			},
			xAxis: {
				type: 'category',
				data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
				show: true
			},
			yAxis: {
				name: 'kW',
				type: 'value',
				show: true,
				axisLine: { //坐标轴刻度标签
					show: true
				},
				axisTick: {
					show: true //坐标轴刻度线
				},
			},
			color: ['#00AAE8', '#00DC34', '#ef6567', '#f9c956', '#75bedc'],
			series: [{
					name: 'Email',
					data: [150, 230, 224, 218, 135, 147, 260],
					type: 'line',
					smooth: true,
					showSymbol: false,
				},
				{
					name: 'Direct',
					data: [100, 20, 24, 108, 15, 247, 220],
					type: 'line',
					smooth: true,
					showSymbol: false,
				}
			]
		};
		myChart.clear()
		option && myChart.setOption(option);

		myChart.resize();
		window.onresize = () => {
			myChart.resize();
		}
	}
	const echarts5 = function() {
		var chartDom = document.querySelector('.kChart5')
		chartDom.removeAttribute("_echarts_instance_");
		var myChart = echarts.init(chartDom);
		var option;
		option = {
			title: {
				text: electricTotalPowerData.value.name,
				textStyle: {
					fontSize: 14,
					color: '#666',
				},
				left: 'center',
			},
			color: ['#00DC34', '#ef6567', '#f9c956', '#75bedc'],
			tooltip: {
				trigger: 'axis', // axis   item   none三个值
				show: true
			},
			grid: {
				x: 30, //左留白
				y: 30, //上留白
				x2: 30, //右留白
				y2: 30 //下留白
			},
			xAxis: {
				type: 'category',
				data: electricTotalPowerData.value.data.xAxis,
				show: true
			},
			yAxis: {
				name: 'kW',
				type: 'value',
				show: true,
				axisLine: { //坐标轴刻度标签
					show: true
				},
				axisTick: {
					show: true //坐标轴刻度线
				},
			},
			series: [{
				name: electricTotalPowerData.value.name,
				data: electricTotalPowerData.value.data.yAxis[0].data,
				type: 'line',
				smooth: true,
				showSymbol: false,
			}]
		};
		myChart.clear()
		option && myChart.setOption(option);

		myChart.resize();
		window.onresize = () => {
			myChart.resize();
		}
	}
	const echarts6 = function() {
		var chartDom = document.querySelector('.kChart6')
		chartDom.removeAttribute("_echarts_instance_");
		var myChart = echarts.init(chartDom);
		var option;
		option = {
			title: {
				text: dailyElectricityData.value.name,
				textStyle: {
					fontSize: 14,
					color: '#666',
				},
				left: 'center',
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				}
			},
			color: ['#02E1FC', '#00AAE8', '#00DC34', '#ef6567', '#f9c956', '#75bedc'],
			grid: {
				x: 30, //左留白
				y: 30, //上留白
				x2: 30, //右留白
				y2: 30 //下留白
			},
			xAxis: [{
				type: 'category',
				data: dailyElectricityData.value.data.xAxis
			}],
			yAxis: {
				name: 'kWh',
				type: 'value',
				show: true,
				axisLine: { //坐标轴刻度标签
					show: true
				},
				axisTick: {
					show: true //坐标轴刻度线
				},
			},
			series: [{
				name: dailyElectricityData.value.name,
				type: 'bar',
				stack: '渠道1',
				data: dailyElectricityData.value.data.yAxis[0].data
			}]
			/*series: [{
					name: '意向',
					type: 'bar',
					stack: '渠道1',
					data: [12, 12, 12, 12, 1, 2],
				},
				{
					name: '进行中',
					type: 'bar',
					stack: '渠道1',
					data: [24, 12, 12, 0, 1, 2],
				},
				{
					name: '已完成',
					type: 'bar',
					stack: '渠道1',
					data: [12, 0, 0, 24, 21],
				},
			],*/
		};
		myChart.clear()
		option && myChart.setOption(option);

		myChart.resize();
		window.onresize = () => {
			myChart.resize();
		}
	}
	const echarts7 = function() {
		var chartDom = document.querySelector('.kChart7')
		chartDom.removeAttribute("_echarts_instance_");
		var myChart = echarts.init(chartDom);
		var option;
		option = {
			title: {
				text: monthElectricityData.value.name,
				textStyle: {
					fontSize: 14,
					color: '#666',
				},
				left: 'center',
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					label: {
						backgroundColor: '#6a7985'
					}
				}
			},
			legend: {
				//data: ['拥有'],
				right: '0',
				top: '22',
				itemHeight: 10,
				itemWidth: 20,
				textStyle: {
					fontSize: 10,
					color: "#333",
				},
			},
			color: ['#00AAE8', '#02E1FC', '#00DC34', '#ef6567', '#f9c956', '#75bedc'],
			grid: {
				x: 30, //左留白
				y: 50, //上留白
				x2: 30, //右留白
				y2: 30 //下留白
			},
			xAxis: [{
				type: 'category',
				boundaryGap: false,
				data: monthElectricityData.value.data.xAxis
			}],
			yAxis: {
				name: 'kWh',
				type: 'value',
				show: true,
				axisLine: { //坐标轴刻度标签
					show: true
				},
				axisTick: {
					show: true //坐标轴刻度线
				},
			},
			series: [{
				name: dailyElectricityData.value.name,
				type: 'line',
				stack: 'Total',
				areaStyle: {},
				emphasis: {
					focus: 'series'
				},
				data: dailyElectricityData.value.data.yAxis[0].data,
				showSymbol: false,
				smooth: true,
			}, ]
			/*series: [{
					name: 'Ads',
					type: 'line',
					stack: 'Total',
					areaStyle: {},
					emphasis: {
						focus: 'series'
					},
					data: [220, 182, 191, 234, 190, 330, 310],
					showSymbol: false,
					smooth: true,
				},
				{
					name: 'Union',
					type: 'line',
					stack: 'Total',
					areaStyle: {},
					emphasis: {
						focus: 'series'
					},
					data: [220, 182, 191, 234, 290, 330, 310],
					showSymbol: false,
					smooth: true,
				}
			]*/
		};
		myChart.clear()
		option && myChart.setOption(option);

		myChart.resize();
		window.onresize = () => {
			myChart.resize();
		}
	}
	const echarts8 = function() {
		var chartDom = document.querySelector('.kChart8')
		chartDom.removeAttribute("_echarts_instance_");
		var myChart = echarts.init(chartDom);
		var option;
		option = {
			grid: {
				x: 30, //左留白
				y: 30, //上留白
				x2: 30, //右留白
				y2: 30 //下留白
			},
			series: [{
				type: 'gauge',
				axisLine: {
					lineStyle: {
						width: 30,
						color: [
							[0.3, '#67e0e3'],
							[0.7, '#37a2da'],
							[1, '#fd666d']
						]
					}
				},
				radius: '100%', //设置速度仪表盘的大小
				pointer: {
					itemStyle: {
						color: 'auto'
					}
				},
				axisTick: {
					distance: -30,
					length: 8,
					lineStyle: {
						color: '#fff',
						width: 2
					}
				},
				splitLine: {
					distance: -30,
					length: 30,
					lineStyle: {
						color: '#fff',
						width: 1
					}
				},
				axisLabel: {
					color: 'inherit',
					distance: 32,
					fontSize: 7
				},
				detail: {
					valueAnimation: true,
					formatter: '{value} km/h',
					color: 'inherit',
					fontSize: 10
				},
				data: [{
					value: 70,
					name: '最大负荷'
				}]
			}]
		};
		myChart.clear()
		option && myChart.setOption(option);

		myChart.resize();
		window.onresize = () => {
			myChart.resize();
		}
	}
	/*
	 * 图表全屏功能
	 * ***************************************/
	const isFullScreen = ref(false); // 是否是全屏状态
	const fullScreenHandle = (value) => {
		isFullScreen.value = !isFullScreen.value;
		if(isFullScreen.value) {
			enterFullScreen(value); // 进入全屏
		} else {
			exitFullscreen(value); // 退出全屏
		}
	};
	// 进入全屏
	const enterFullScreen = (value) => {
		// 获取layout的dom元素
		const parentNode = document.querySelector(value); // 获取需要全屏的元素;
		// 进入全屏
		if(parentNode.requestFullscreen) {
			parentNode.requestFullscreen();
		} else if(parentNode.webkitRequestFullScreen) {
			parentNode.webkitRequestFullScreen();
		} else if(parentNode.mozRequestFullScreen) {
			parentNode.mozRequestFullScreen();
		} else if(parentNode.msRequestFullscreen) {
			// IE11
			parentNode.msRequestFullscreen();
		}
		if(value == '.itemBox5') {
			echarts5()
		} else if(value == '.itemBox6') {
			echarts6()
		} else if(value == '.itemBox7') {
			echarts7()
		}
	};
	// 退出全屏
	const exitFullscreen = (value) => {
		if(document.exitFullscreen) {
			document.exitFullscreen();
		} else if((document).webkitCancelFullScreen) {
			(document).webkitCancelFullScreen();
		} else if((document).mozCancelFullScreen) {
			(document).mozCancelFullScreen();
		} else if((document).msExitFullscreen) {
			(document).msExitFullscreen();
		}
	};

	onMounted(() => {
		//监听是否全屏
		window.addEventListener('resize', () => {
			if(document.fullscreenElement) {
				isFullScreen.value = true
			} else {
				isFullScreen.value = false
			}
		})
		//		echarts2()
		//		echarts4()
		//		echarts8()
		if(router.currentRoute.value.query.id) {
			getDataFun()
		}
	})
</script>

<style lang="less" scoped>

</style>