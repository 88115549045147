<template>
  <!--字典分类页面-->
  <div class="dictionaryClassify">
    <div class="title">字典分类</div>
    <div class="content">
      <!--<div class="searchBox">
        <div class="liBox">
          <el-input v-model="deviceName" autocomplete="off" type="text" prefix-icon="el-icon-search" placeholder='请输入设备名名称' clearable></el-input>
        </div>
        <div class="liBox">
          <el-select v-model="deviceStatus" placeholder="请选择设备状态" style="width:100%" clearable>
            <el-option v-for="item in deviceList" :key="item.value" :label="item.label" :value="item.value" style="width:100%">
            </el-option>
          </el-select>
        </div>
        <div class="liBox">
          <el-input v-model="controlName" autocomplete="off" type="text" prefix-icon="el-icon-search" placeholder='请输入控制名称' clearable></el-input>
        </div>
        <div class="liBox">
          <el-button type="primary" class="searchButton" @click="getListFun">查询</el-button>
        </div>
      </div>-->
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">新建一级字典分类</el-button>
        </div>
        <el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData"
                  style="width: 100%" @selection-change="handleSelectionChange">
          <!--<el-table-column label="序号" property="" min-width="1%">
            <template #default="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>-->
          <template #empty>
            <el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
							<p>您可以在此页面新建字典分类信息</p>
						</el-empty>
          </template>
          <el-table-column label="分类名称" min-width="1%" property="name">
          </el-table-column>
          <el-table-column label="下级分类" min-width="3%" property="subs">
          </el-table-column>
          <el-table-column label="操作" min-width="1%">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="editClick(scope.row,scope.row.id)">编辑</li>
                <li class="editClass" @click="addClick(scope.row.id)">新建子分类</li>
                <li class="deleteClass" @click="deleteClick(scope.row.id)">删除
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <!--<div class="footerBox">
          <el-pagination small :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small" :disabled="disabled" background layout="total, prev, pager, next, sizes" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>-->
      </div>
    </div>
    <!--新增字典分类弹窗-->
    <el-dialog v-model="addDictDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <el-form ref="addDictFormRef" :model="addDictForm" :rules="addDictRules" class="demo-ruleForm" status-icon label-width="auto">
        <el-form-item label="名称" prop="name">
          <el-input v-model="addDictForm.name" placeholder="请输入名称"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="addDictDialogVisible = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="addDictSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--编辑字典分类弹窗-->
    <el-dialog v-model="editDictDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div v-for="item in rowList" style="position: relative;">
        <div>
          名称：
          <el-input v-model="item.name" class="groupClass"></el-input>
        </div>
        <div v-for="vs in item.subList" class="groupClassBox">
          <el-input v-model="vs.name" class="groupClass2"></el-input>
          <el-icon style="color: #666;font-size: 18px;vertical-align: middle;" @click="deleteClick(vs.id)">
            <CircleCloseFilled/>
          </el-icon>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="editDictDialogVisible = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="editDictSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {CircleCloseFilled, Plus} from '@element-plus/icons-vue'
import {getList, saveList, deleteList} from '../../api/modules/dictionaryClassify'
import './dictionaryClassify.less'
import qs from 'qs';

let router = useRouter()
/*生命周期*/
onMounted(() => {
  getListFun()
})

let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
//获取字典列表
const getListFun = function () {
  loadingVal.value = true
  let params = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
  }
  getList(qs.stringify(params)).then(res => {
    //console.log(res);
    loadingVal.value = false
    if (res.data.ok) {
      tableData.value = res.data.result
      //total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let title = ref() //弹窗标题
let dialogWidth = ref('40%') //新增字典分类弹窗宽度
let addDictDialogVisible = ref(false) //新增一级字典分类弹窗开关
let editDictDialogVisible = ref(false) //新增二级字典分类弹窗开关
let rowList = ref([]) //编辑列表
//新增字典分类的点击
const addClick = function (val) {
  editId.value = val
  addDictForm.value.name = ''
  if (val) {
    title.value = '新增字典二级分类'
  } else {
    title.value = '新增字典一级分类'
  }
  addDictDialogVisible.value = true
}
//字典编辑的点击
const editClick = function (list, id) {
  rowList.value = []
  rowList.value.push(JSON.parse(JSON.stringify(list)))
  editId.value = id
  editDictDialogVisible.value = true
  title.value = '编辑字典分类'
}
//字典分类的点击
const classfiyClick = function (controlId, deviceControlId, deviceId, controlName) {
  router.push({
    path: '/admin/dictionaryClassify'
  })
}
let addDictFormRef = ref() //字典分类
let editId = ref() //编辑id
//字典分类表单
let addDictForm = ref({
  name: ''
})
// 字典分类表单验证规则
let addDictRules = ref({
  name: [{
    required: true,
    message: '请输入名称',
    trigger: 'blur'
  }]
})
//新增字典分类的保存
const addDictSave = function () {
  let params = {
    id: 0,
    name: addDictForm.value.name
  }
  if (addDictFormRef) {
    addDictFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (title.value == '新增字典一级分类') {
          params['parentId'] = 0
        } else if (title.value == '新增字典二级分类') {
          params['parentId'] = editId.value
        }
        saveList(
            qs.stringify(params)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            addDictDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
//编辑的保存
const editDictSave = async function () {
  for (var i = 0; i < rowList.value.length; i++) {
    if (rowList.value[i].name) {
      await saveList(
          qs.stringify({
            parentId: 0,
            id: rowList.value[i].id,
            name: rowList.value[i].name
          })
      ).then(async res => {
        //console.log(res);
        if (res.data.ok) {
          if (rowList.value.length - 1 == i) {
            getListFun()
            editDictDialogVisible.value = false
          }
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage({
        message: '请输入必填项',
        type: 'info'
      })
    }
    if (rowList.value[i].subList && rowList.value[i].subList.length > 0) {
      for (var j = 0; j < rowList.value[i].subList.length; j++) {
        if (rowList.value[i].name) {
          await saveList(
              qs.stringify({
                parentId: rowList.value[i].id,
                id: rowList.value[i].subList[j].id,
                name: rowList.value[i].subList[j].name
              })
          ).then(async res => {
            //console.log(res);
            if (res.data.ok) {
              if (rowList.value[i].subList.length - 1 == j) {
                getListFun()
                editDictDialogVisible.value = false
              }
            } else {
              ElMessage({
                message: res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            message: '请输入必填项',
            type: 'info'
          })
        }
      }
    } else {
      getListFun()
      editDictDialogVisible.value = false
    }
  }
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除字典分类',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此字典分类，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun();
        rowList.value.forEach(item => {
          item.subList.forEach((v, i) => {
            if (v.id == id) {
              item.subList.splice(i, 1);
            }
          })
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
</script>