<template>
  <div class="dataStatistics">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="goBack">规则</span>
        <span> / </span>
        <span>规则状态</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        {{ router.currentRoute.value.query.id }}
      </p>
    </div>
    <div class="content">
      <div :style="{justifyContent:tableData.status==='running'?'space-around':'start'}" class="headBox">
        <div class="item">
          <p class="name">status: &nbsp; </p>
          <p class="val">{{ tableData.status }}</p>
        </div>
        <div v-if="tableData.status==='running'" class="item">
          <p class="name">lastStartTimestamp: &nbsp; </p>
          <p class="val">{{ tableData.lastStartTimestamp }}</p>
        </div>
        <div v-if="tableData.status==='running'" class="item">
          <p class="name">lastStopTimestamp: &nbsp; </p>
          <p class="val">{{ tableData.lastStopTimestamp }}</p>
        </div>
        <div v-if="tableData.status==='running'" class="item">
          <p class="name">nextStopTimestamp: &nbsp; </p>
          <p class="val">{{ tableData.nextStopTimestamp }}</p>
        </div>
        <div v-if="tableData.status!=='running'" class="item">
          <p class="name">message: &nbsp; </p>
          <p class="val">{{ tableData.message }}</p>
        </div>
      </div>
      <div class="searchBox">
        <el-input
            v-model="searchName"
            class="input-with-select"
            placeholder="请输入源名称"
            style="width: 400px;margin-bottom: 16px"
        >
          <template #prepend>
            <el-select v-model="searchType" placeholder="Select" style="width: 115px">
              <el-option label="All" value="all"/>
              <el-option label="Source" value="source"/>
              <el-option label="Op" value="op"/>
              <el-option label="Sink" value="sink"/>
            </el-select>
          </template>
        </el-input>
      </div>
      <div class="contain">
        <div v-for="(item,index) in tableData.dataArr"
             v-show="((item.title.substring(0,item.title.indexOf('_'))===searchType)&&searchName==='')||(searchType==='all'&&searchName==='')||(item.title.includes(searchName)&&(searchType==='all'||(item.title.substring(0,item.title.indexOf('_'))===searchType)))"
             :key="index" class="containItem">
          <p v-if="item.title.substring(0,item.title.indexOf('_'))==='source'" class="tit"
             style="border-color: #189bfe">{{ item.title }}</p>
          <p v-if="item.title.substring(0,item.title.indexOf('_'))==='op'" class="tit" style="border-color: #13cda0">
            {{ item.title }}</p>
          <p v-if="item.title.substring(0,item.title.indexOf('_'))==='sink'" class="tit"
             style="border-color: #5e4eff">
            {{ item.title }}</p>
          <div class="item">
            <p class="name">records_in_total:</p>
            <p class="val">{{ item.records_in_total }}</p>
          </div>
          <div class="item">
            <p class="name">records_out_total:</p>
            <p class="val">{{ item.records_out_total }}</p>
          </div>
          <div class="item">
            <p class="name">process_latency_us:</p>
            <p class="val">{{ item.process_latency_us }}</p>
          </div>
          <div class="item">
            <p class="name">buffer_length:</p>
            <p class="val">{{ item.buffer_length }}</p>
          </div>
          <div class="item">
            <p class="name">last_invocation:</p>
            <p class="val">{{ item.last_invocation }}</p>
          </div>
          <div class="item">
            <p class="name">exceptions_total:</p>
            <p class="val">{{ item.exceptions_total }}</p>
          </div>
          <div class="item">
            <p class="name">last_exception:</p>
            <el-tooltip
                v-if="item.last_exception!==''"
                :content="item.last_exception"
                effect="dark"
                placement="top"
                popper-class="el_tooltip_item"
            >
              <p class="val more">{{ item.last_exception }}</p>
            </el-tooltip>
          </div>
          <div class="item">
            <p class="name">last_exception_time:</p>
            <p class="val">{{ item.last_exception_time }}</p>
          </div>
          <div class="item">
            <p class="name">messages_processed_total:</p>
            <p class="val">{{ item.messages_processed_total }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, onUnmounted, reactive, ref} from "vue"
import './dataStatistics.less'
import {useRouter} from "vue-router/dist/vue-router";
import {getListDataApi} from "@/api/modules/rulesPage";
import {ElMessage} from "element-plus";
import {Back} from '@element-plus/icons-vue'


let router = useRouter()

const goBack = function () {
  router.push('/admin/rulesPage')
}


// 获取数据
let tableData = ref({})
const getListDataFun = function () {
  getListDataApi(router.currentRoute.value.query.id).then(res => {
    if (res.status === 200) {
      tableData.value = res.data
      let arr = []
      for (const resKey in tableData.value) {
        if (resKey.includes('_')) {
          let title = resKey.substring(0, resKey.lastIndexOf('0') + 1)
          if (arr.some(item => item.title === title)) {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].title === title) {
                arr[i][resKey.substring(resKey.lastIndexOf('0') + 2)] = tableData.value[resKey]
              }
            }
          } else {
            arr.push({
              title: title
            })
          }
        }
      }
      tableData.value.dataArr = arr
      console.log(arr)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}

// 搜索
let searchName = ref('')
let searchType = ref('all')


onMounted(() => {
  getListDataFun()
})

const timer = setInterval(getListDataFun, 5000);
onUnmounted(() => {
  clearInterval(timer)
})


</script>

<style lang="less" scoped>
</style>
