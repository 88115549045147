import {api} from "@/api";

// 获取角色列表
export const getRoleListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/role/listAll',
        method: 'POST',
        data: data
    })
}

// 获取角色菜单列表
export const getRoleMenuApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/role/getDetail',
        method: 'POST',
        data: data
    })
}

// 保存角色
export const saveRoleApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/role/save',
        method: 'POST',
        data: data
    })
}

// 更新角色菜单
export const saveRoleMenuApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/role/updateMenu',
        method: 'POST',
        data: data
    })
}

// 删除角色
export const delRoleApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/role/delete',
        method: 'POST',
        data: data
    })
}