<template>
	<div class="nop">
		<div>
			<el-form ref="actionData" :inline="true" :model="formData">
				<el-collapse v-model="activeNames2" @change="handleChange">
					<el-collapse-item title="Base" name="1">
						<el-form-item label="" style="margin-bottom: 20px">
							<span slot="label">
												打印日志
												<el-tooltip content="是否将结果打印到日志。缺省为 false，这种情况下将不会打印到日志文件" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
							<el-radio-group v-model="formData.log" placeholder="请选择">
								<el-radio :label="true" border>True</el-radio>
								<el-radio :label="false" border>False</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-collapse-item>
				</el-collapse>
			</el-form>
		</div>

	</div>
</template>

<script setup>
	import { onMounted, onUnmounted, reactive, ref, watch, defineEmits } from "vue"
	import './nop.less'
	import { useRouter } from "vue-router/dist/vue-router";
	import { Back, QuestionFilled, Document, Plus, Delete } from '@element-plus/icons-vue'
	let router = useRouter()

	const emit = defineEmits(["returnResults"])
	// 定义传值类型
	const props = defineProps({
		actionObj: String //这里就接收到父组件传递的value
	})
	/*生命周期*/
	onMounted(() => {
		if(JSON.parse(props.actionObj).nop && JSON.parse(props.actionObj).nop.log != '') {
			formData.value = JSON.parse(props.actionObj).nop
			formData.value.type = "nop"
		}
		// 禁止折叠面板按钮点击跳转
		let ele = document.querySelectorAll('.el-collapse-item button')
		for(let i = 0; i < ele.length; i++) {
			ele[i].addEventListener('click', function(event) {
				event.preventDefault();
			});
		}
	});
	const activeNames2 = ref(['1']) //折叠面板默认值
	const handleChange = (val) => {
		//console.log(val)
	}
	let active = ref(1);
	//表单
	let formData = ref({
		//Base
		'log': false,
		'type': "nop"
	})
	let actionData = ref() // 提交表单
	//测试连接的点击
	const testConnectRules = function() {
		emit("returnResults", formData.value)
	}
	//提交的点击
	const submitFun = function() {
		emit("submitResults", formData.value)
	}
	// 暴露方法
	defineExpose({
		testConnectRules,
		submitFun
	});
</script>

<style>

</style>