<template>
  <!--字典设置页面-->
  <div class="dictionarySet">
    <div class="title">字典设置</div>
    <div class="content">
      <el-form>
        <div class="searchBox">
          <div class="liBox">
            <el-form-item label="字典名称">
              <el-input v-model="dictKey" autocomplete="off" clearable placeholder='请输入字典名称' type="text"></el-input>
            </el-form-item>
          </div>
          <div class="liBox">
            <el-form-item label="字典key">
              <el-input v-model="nameKey" autocomplete="off" clearable placeholder='请输入字典key' type="text"></el-input>
            </el-form-item>
          </div>
          <div class="liBox">
            <el-form-item label="一级字典分类">
              <el-select v-model="firstId" clearable placeholder="请选择一级字典分类" style="width:100%" @change="firstChange">
                <el-option v-for="item in categoryAllList" :key="item.id" :label="item.name" :value="item.id"
                           style="width:100%">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="liBox">
            <el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
            <el-button class="searchButton resetButton" @click="resetClick()">重置</el-button>
          </div>
          <div class="liBox">
            <el-form-item label="二级字典分类">
              <el-select v-model="secondId" clearable placeholder="请选择二级字典分类" style="width:100%">
                <el-option v-for="item in secondCategoryList" :key="item.id" :label="item.name" :value="item.id"
                           style="width:100%">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

        </div>
      </el-form>
      <div class="tableBox">
        <div class="operationBox">
          <!--<el-button type="primary" @click="exportClick()">导出字典</el-button>-->
          <!--<el-button type="primary" @click="importClick()">导入字典</el-button>-->
          <el-button :icon="Plus" type="primary" @click="addClick()">新建字典</el-button>
        </div>
        <el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" style="width: 100%"
                  @selection-change="handleSelectionChange">
          <!--<el-table-column label="序号" property="deviceModel" min-width="1%">
            <template #default="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>-->
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建字典信息</p>
            </el-empty>
          </template>
          <el-table-column label="key" min-width="1%" property="dictKey">
          </el-table-column>
          <el-table-column label="名称" min-width="1%" property="name">
          </el-table-column>
          <el-table-column label="所属一级分类" min-width="3%" property="firstCategoryStr">
          </el-table-column>
          <el-table-column label="所属二级分类" min-width="3%" property="secondCategoryStr">
          </el-table-column>
          <el-table-column label="操作" min-width="1%">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="editClick(scope.row.id,scope.row)">编辑</li>
                <li class="deleteClass" @click="deleteClick(scope.row.id)">删除
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small"
                         :total="total" background layout="total, sizes, prev, pager, next" small
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!--新增、编辑、导入字典弹窗-->
    <el-dialog v-model="dictDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div>
        <el-form ref="dictFormRef" :model="dictForm" :rules="dictRules" class="demo-ruleForm" label-width="auto"
                 style="width:98%">
          <el-form-item label="key" prop="dictKey">
            <el-input v-model="dictForm.dictKey" placeholder="请输入名称"/>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="dictForm.name" placeholder="请输入名称"/>
          </el-form-item>
        </el-form>
        <!--<div class="uploadClass">
          <el-upload class="upload-demo" ref="upload" :action="http+'/zkep2/upload/upload'" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" :on-change="onChange" :auto-upload="true" accept=".xls,.xlsx" :on-success="onSuccess" :before-upload="beforeUpload">
            <button class="selectFile" slot="trigger" size="small" type="primary" id="fileName">选择文件</button>
            <span class="weiUpload" v-if="fileList.length==0">未选择任何文件</span>
          </el-upload>
        </div>-->
        <div class="titleBox">
          <div class="leftBox"></div>
          <div style="display: inline-block;" @click="collapseClick()">
            <i v-if="!upShow" class="iconfont icon-xiangshangshouqi-yuankuang iconClass"></i>
            <i v-if="upShow" class="iconfont icon-xiangxiazhankai-yuankuang iconClass"></i>
          </div>
        </div>
        <div class="tabBox">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item,index) in title_categoryData" :key='index' :name="index" title="电表">
              <template #title>
                <el-checkbox v-model="item.checked" @click.stop>{{ item.name }}</el-checkbox>
                <i :class="judgeActive('1')!==-1?'iconfont icon-xiangshangshouqi-yuankuang iconClass':'iconfont icon-xiangxiazhankai-yuankuang iconClass'"></i>
              </template>
              <div v-for="items in item.subList" class="tab_li">
                <el-checkbox v-model="items.checked" @change="checkedChange(index)">{{ items.name }}</el-checkbox>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="dictDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="dictSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus} from '@element-plus/icons-vue'
import {getList, getDetail, getAllCategory, saveList, deleteList} from '../../api/modules/dictionarySet'
import './dictionarySet.less'
import qs from 'qs';

let router = useRouter()
/*生命周期*/
onMounted(() => {
  getListFun()
  getAllCategoryFun()
})

let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//重置
const resetClick = function () {
  dictKey.value = ''
  nameKey.value = ''
  firstId.value = ''
  secondId.value = ''
}
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
let dictKey = ref() //搜索字典名称
let nameKey = ref() //搜索字典名称
let firstId = ref() //搜索一级字典分类
let secondId = ref() //搜索二级字典分类
//获取字典列表
const getListFun = function () {
  loadingVal.value = true
  let params = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    name: dictKey.value,
    dictKey: nameKey.value,
    firstId: firstId.value,
    secondId: secondId.value
  }
  getList(qs.stringify(params)).then(res => {
    //console.log(res);
    loadingVal.value = false
    if (res.data.ok) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let categoryAllList = ref([]) //所有分类列表
let secondCategoryList = ref([]) //二级字典分类列表
//获取所有字典分类
const getAllCategoryFun = function () {
  getAllCategory(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      categoryAllList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//一级字典分类的改变
const firstChange = function () {
  secondCategoryList.value = []
  secondId.value = ''
  categoryAllList.value.forEach(item => {
    if (item.id == firstId.value) {
      secondCategoryList.value = item.subList
    }
  })
}
let title = ref() //弹窗标题
let dialogWidth = ref('40%') //新增字典弹窗宽度
let dictDialogVisible = ref(false) //新增字典弹窗开关

//新增字典的点击
const addClick = function () {
  editId.value = ''
  dictForm.value = {}
  dictDialogVisible.value = true
  title.value = '新增字典'
  getAllCategory(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      title_categoryData.value = res.data.result
      title_categoryData.value.forEach(item => {
        item.checked = false
        item.subList.forEach(items => {
          items.checked = false
        })
      })
      //默认展开
      upShow.value = true
      collapseClick()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//字典编辑的点击
const editClick = function (id, row) {
  editId.value = id
  dictDialogVisible.value = true
  title.value = '编辑字典'
  getDetail(qs.stringify({
    id: id
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      dictForm.value.dictKey = row.dictKey
      dictForm.value.name = row.name
      title_categoryData.value = res.data.result.categoryList
      title_categoryData.value.forEach(item => {
        if (item.checkFlag == 0) {
          item.checked = false
        } else {
          item.checked = true
        }
        item.subList.forEach(items => {
          if (items.checkFlag == 0) {
            items.checked = false
          } else {
            items.checked = true
          }
        })
      })
      //默认折叠
      upShow.value = false
      collapseClick()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let dictFormRef = ref() //字典分类
let editId = ref() //编辑id
//字典分类表单
let dictForm = ref({
  name: '',
  dictKey: ''
})
// 字典分类表单验证规则
let dictRules = ref({
  name: [{
    required: true,
    message: '请输入字典名称',
    trigger: 'blur'
  },],
  dictKey: [{
    required: true,
    message: '请输入字典key',
    trigger: 'blur'
  },]
})
//字典的保存
const dictSave = function () {
  let categoryIdList = []
  title_categoryData.value.forEach(item => {
    if (item.checked) {
      categoryIdList.push(item.id)
    }
    item.subList.forEach(items => {
      if (items.checked) {
        categoryIdList.push(items.id)
      }
    })
  })
  let params = {
    dictKey: dictForm.value.dictKey,
    name: dictForm.value.name,
    categoryIdList: categoryIdList.toString()
  }
  if (dictFormRef) {
    dictFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (title.value == '新增字典') {
          params['id'] = 0
        } else if (title.value == '编辑字典') {
          params['id'] = editId.value
        }
        saveList(
            qs.stringify(params)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            dictDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除字典',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此字典，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
/*导入*/
//导入字典的点击
const importClick = function () {
  dictDialogVisible.value = true
  title.value = '导入字典'
}
let fileList = ref([])
const handleRemove = function () {
  console.log('aaa')
}
const handlePreview = function () {
  console.log('aaa')
}
const onChange = function () {
  console.log('aaa')
}
let title_categoryData = ref([])
let upShow = ref(true)
/*弹窗折叠*/
const collapseClick = function () {
  upShow.value = !upShow.value
  if (upShow.value) {
    activeNames.value = []
  } else {
    activeNames.value = []
    title_categoryData.value.forEach((item, index) => {
      activeNames.value.push(index)
    })
  }
}
let activeNames = ref([])
const judgeActive = function (data) {
  return activeNames.value.indexOf(data)
}
const handleChange = function (val) {
  if (activeNames.value.length == 0) {
    upShow.value = true
  } else {
    upShow.value = false
  }
}
//复选框改变
const checkedChange = function (index) {
  let falg = false
  title_categoryData.value.forEach((item, i) => {
    if (i == index) {
      item.subList.forEach(items => {
        if (items.checked) {
          falg = true
        }
      })
      if (falg) {
        item.checked = true
      } else {
        item.checked = false
      }
    }
  })
}
</script>