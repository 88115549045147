<template>
  <div class="dataSearch">
    <div class="title">
      <p>
        <span>本地数据查询</span>
      </p>
      <el-button type="primary" @click="searchPlan">查询管理</el-button>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <span>查询方案： </span>
          <el-select v-model="plan" @change="planChange">
            <el-option v-for="(item,index) in planList" :key="index" :label="item.queryName"
                       :value="item.id"></el-option>
          </el-select>
          <span style="margin-left: 50px">采样频率： </span>
          <el-input v-model="frequency" :disabled="true" placeholder="请输入采样频率" type="number">
            <!--            <template #suffix>-->
            <!--              分钟-->
            <!--            </template>-->
            <template #append>
              <el-select v-model="timeUnit" style="width: 90px;">
                <el-option label="分钟" value="m"/>
                <el-option label="小时" value="h"/>
                <el-option label="天" value="d"/>
                <el-option label="月" value="n"/>
                <el-option label="年" value="y"/>
              </el-select>
            </template>
          </el-input>
        </div>
        <div class="liBox">
          <span>日期： </span>
          <el-select v-model="timeFlag" clearable @change="timeFlagSelect">
            <el-option label="自定义时间段" value="-1"></el-option>
            <el-option label="近1小时" value="1H"></el-option>
            <el-option label="近12小时" value="12H"></el-option>
            <el-option label="近一天" value="1D"></el-option>
            <el-option label="近3天" value="3D"></el-option>
            <el-option label="近1周" value="1W"></el-option>
            <el-option label="本周" value="THIS_WEEK"></el-option>
            <el-option label="本月" value="1M"></el-option>
            <el-option label="本季" value="1Q"></el-option>
          </el-select>
          <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→"
                          start-placeholder="开始时间" style="transform: translateY(3px);margin-left: 15px"
                          type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
          <el-checkbox-group v-model="yom" :max="1" :min="0" style="display: inline-block;margin-left: 20px">
            <el-checkbox :label="1">同比</el-checkbox>
            <el-checkbox :label="2">环比</el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-for="(item,index) in equipmentArr" :key="index" class="liBox liCopy">
          <span>设备： </span>
          <!--          <el-select v-model="item.deviceId" clearable placeholder="请选择设备" @change="equipmentChange(index)">-->
          <!--            <el-option v-for="(item_d,index_d) in equipmentList" :key="index_d" :label="item_d.name"-->
          <!--                       :value="item_d.id"></el-option>-->
          <!--          </el-select>-->
          <el-tree-select
              ref="treeRef1"
              v-model="item.deviceId"
              :data="allTree"
              :props="props"
              :render-after-expand="false"
              filterable
              node-key="id"
              style="height: auto!important;"
              @change="equipmentChange(index)"
          />
          <el-select v-model="item.dictKeys" class="item" clearable multiple placeholder="请选择指标"
                     @change="dataChange(index)">
            <el-option v-for="(item_d,index_d) in dataList[index]" :key="index_d" :label="item_d.value"
                       :value="item_d.key"></el-option>
          </el-select>
          <el-icon v-if="equipmentArr.length>1" class="delIcon" @click="delClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===equipmentArr.length-1" class="addIcon" @click="addClick">
            <CirclePlus/>
          </el-icon>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button type="success" @click="exportFun">导出</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--搜索的内容-->
      <div class="contain">
        <div class="head">
          <div class="tab">
            <p :class="showFlag?'active':''" @click="showClick(1)">图表</p>
            <!--            <p :class="!showFlag?'active':''" @click="showClick(2)">表格</p>-->
          </div>
        </div>
        <el-empty v-if="tableData.length===0&&echartsDataX.length===0"
                  class="emptyClass" description="暂无数据信息"
                  image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
        <!--图表-->
        <div v-show="showFlag&&echartsDataX.length!==0" class="chart"></div>
        <!--表格-->
        <div v-show="!showFlag&&tableData.length!==0" class="tableCont">
          <div v-for="(item,index) in tableData" :key="index" class="tableItem">
            <div class="tit">{{ item.deviceName }}</div>
            <el-table :data="item.dataMetricsVO" style="width: calc(100% - 48px);" table-layout="auto">
              <el-table-column label="上报时间" prop="ts"></el-table-column>
              <el-table-column v-for="(item_a,index_a) in item.labelArr" :key="index_a" :label="item_a"
                               :prop="item_a"></el-table-column>
            </el-table>
            <el-button v-if="item.dataMetricsVO.length>0" @click="moreClick(index)">加载更多</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref} from "vue"
import './dataSearch.less'
import {CloseBold, ZoomIn, Plus, Delete, CirclePlus, CircleClose} from '@element-plus/icons-vue'
import {CascaderProps, ElLoading, ElMessage, ElMessageBox} from 'element-plus'
import * as echarts from 'echarts';
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  getEquipmentListApi,
  getDataListApi,
  getEchartsDataApi,
  getTableDataApi,
  getSearchListApi, getSearchDetailApi, testApi, exportDataApi, getAllTreeApi
} from '@/api/modules/dataSearchLocal'
import moment from 'moment'

let router = useRouter()


// 点击跳转查询管理
const searchPlan = function () {
  router.push('/admin/searchPlanLocal')
}

// 搜索---------------------------------------------------------------------------------------
let plan = ref()   //选择的搜索方案
let timeFlag = ref()   //日期快捷选择
let dateTime = ref()   //搜索日期
let frequency = ref(1)   //频率
let timeUnit = ref('m')   //频率单位
let yom = ref([])  //同环比
let equipmentArr = ref([
  {
    deviceId: '',
    dictKeys: []
  }
])    //搜索的设备
// 日期快捷选择事件
const timeFlagSelect = function () {
  if (timeFlag.value === '-1') {
    dateTime.value = []
  } else if (timeFlag.value === '1H') {
    dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '12H') {
    dateTime.value = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1D') {
    dateTime.value = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '3D') {
    dateTime.value = [moment().subtract(3, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1W') {
    dateTime.value = [moment().subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === 'THIS_WEEK') {
    dateTime.value = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1M') {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1Q') {
    dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}


// 设备列表
let equipmentList = ref([])
// 指标列表
let dataList = ref([])
// 获取设备列表
const getEquipmentListFun = async function () {
  await getEquipmentListApi().then(res => {
    if (res.data.code === 200) {
      equipmentList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取指标列表
const getDataListFun = async function (index, id) {
  if (id) {
    await getDataListApi(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.code === 0) {
        dataList.value[index] = res.data.result
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 选择设备事件
let treeRef1 = ref()
const equipmentChange = function (index) {
  equipmentArr.value[index].dictKeys = []
  // getDataListFun(index)
  getDataListFun(index, treeRef1.value[index].getCurrentNode().id)
  equipmentArr.value[index].deviceId = treeRef1.value[index].getCurrentNode().id
}
// 选择指标事件
const dataChange = function (index) {
  if (equipmentArr.value[index].dictKeys.length > 5) {
    ElMessage({
      type: 'info',
      message: '最多选择5个指标'
    })
    equipmentArr.value[index].dictKeys.splice(-1)
  }
}
// 点击新增设备
const addClick = function () {
  equipmentArr.value.push({
    deviceId: '',
    dictKeys: []
  })
}
// 点击删除设备
const delClick = function (index) {
  equipmentArr.value.splice(index, 1)
  dataList.value.splice(index, 1)
}

// 获取图表数据
let echartsData = ref([])
let echartsDataX = ref([])
const getEchartsDataFun = async function () {
  echartsDataX.value = []
  echartsData.value = []
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    params: equipmentArr.value,
    yom: yom.value[0]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.params.length; i++) {
    if (!data.params[i].deviceId || (data.params[i].dictKeys.length === 0)) {
      flag = true
    }
    delete data.params[i].dictList
    delete data.params[i].dictIds
  }
  if (data.startTime && data.endTime && data.frequency && data.timeUnit && !flag) {
    openFullScreen()
    await getEchartsDataApi(data).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        echartsDataX.value = res.data.data.xAxis
        echartsData.value = res.data.data.yAxis
        for (let i = 0; i < echartsData.value.length; i++) {
          echartsData.value[i].name = echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName
          echartsData.value[i].type = 'line'
          echartsData.value[i].lineStyle = {
            width: 1
          }
          echartsData.value[i].symbol = 'circle'
          // echartsData.value[i].symbol = 'none'
          echartsData.value[i].sampling = 'lttb'
          for (let j = 0; j < echartsData.value[i].data.length; j++) {
            echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
          }
        }
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}
// 获取表格数据
let tableData = ref([])
let pageData = ref([])
const getTableDataFun = async function () {
  pageData.value = []
  tableData.value = []
  for (let i = 0; i < equipmentArr.value.length; i++) {
    pageData.value.push({
      pageNum: 1,
      pageSize: 10
    })
    let data = {
      startTime: dateTime.value ? dateTime.value[0] : '',
      endTime: dateTime.value ? dateTime.value[1] : '',
      pageNum: pageData.value[i].pageNum,
      pageSize: 10,
      frequency: frequency.value,
      timeUnit: timeUnit.value,
      params: [equipmentArr.value[i]],
    }
    // 计算同环比时间
    if (yom.value && yom.value[0] == 1) {
      data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      console.log(data.lastStartTime, data.lastEndTime)
    } else if (yom.value && yom.value[0] == 2) {
      if (timeFlag.value === '1M') {
        let lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
        let lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      } else if (timeFlag.value === '1Q') {
        data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      } else {
        let timeLength = moment(data.endTime).diff(data.startTime, 'second')
        data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      }
    }
    let flag = false
    for (let i = 0; i < data.params.length; i++) {
      if (!data.params[i].deviceId || (data.params[i].dictKeys.length === 0)) {
        flag = true
      }
      delete data.params[i].dictList
      delete data.params[i].dictIds
    }
    if (data.startTime && data.endTime && data.frequency && data.timeUnit && !flag) {
      openFullScreen()
      await getTableDataApi(data).then(res => {
        loadingAdd.value.close()
        if (res.data.code === 200) {
          let arr = res.data.data.list
          for (let i = 0; i < arr.length; i++) {
            arr[i].labelArr = []
            for (let j = 0; j < arr[i].dataMetricsVO.length; j++) {
              arr[i].dataMetricsVO[j].ts = arr[i].dataMetricsVO[j].ts.slice(0, 10) + ' ' + arr[i].dataMetricsVO[j].ts.slice(11, 19)
              for (let k = 0; k < arr[i].dataMetricsVO[j].metricDataList.length; k++) {
                arr[i].labelArr.push(arr[i].dataMetricsVO[j].metricDataList[k].titleName)
                arr[i].labelArr = Array.from(new Set(arr[i].labelArr))
                let name = arr[i].dataMetricsVO[j].metricDataList[k].titleName
                arr[i].dataMetricsVO[j][name] = Number(arr[i].dataMetricsVO[j].metricDataList[k].val).toFixed(2)
              }
            }
          }
          tableData.value.push(...arr)
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage({
        type: 'info',
        message: '请选择筛选条件'
      })
    }
  }
}

const moreClick = async function (index) {
  openFullScreen()
  pageData.value[index].pageNum = pageData.value[index].pageNum + 1
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    pageNum: pageData.value[index].pageNum,
    pageSize: 10,
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    params: [equipmentArr.value[index]]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    console.log(data.lastStartTime, data.lastEndTime)
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      let lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      let lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  await getTableDataApi(data).then(res => {
    loadingAdd.value.close()
    if (res.data.code === 200) {
      let arr = res.data.data.list
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].labelArr = []
          for (let j = 0; j < arr[i].dataMetricsVO.length; j++) {
            arr[i].dataMetricsVO[j].ts = arr[i].dataMetricsVO[j].ts.slice(0, 10) + ' ' + arr[i].dataMetricsVO[j].ts.slice(11, 19)
            for (let k = 0; k < arr[i].dataMetricsVO[j].metricDataList.length; k++) {
              arr[i].labelArr.push(arr[i].dataMetricsVO[j].metricDataList[k].titleName)
              arr[i].labelArr = Array.from(new Set(arr[i].labelArr))
              let name = arr[i].dataMetricsVO[j].metricDataList[k].titleName
              arr[i].dataMetricsVO[j][name] = Number(arr[i].dataMetricsVO[j].metricDataList[k].val).toFixed(2)
            }
          }
        }
        tableData.value[index].dataMetricsVO.push(...arr[0].dataMetricsVO)
        tableData.value[index].labelArr.push(...arr[0].labelArr)
        tableData.value[index].labelArr = Array.from(new Set(tableData.value[index].labelArr))
      } else {
        ElMessage({
          type: 'info',
          message: '没有更多数据了！'
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 查询
const searchFun = async function () {
  if (showFlag.value) {
    await getEchartsDataFun()
  } else {
    await getTableDataFun()
  }
}
// 重置
const resetFun = function () {
  plan.value = ''
  dateTime.value = ''
  frequency.value = 1
  timeUnit.value = ''
  timeFlag.value = ''
  yom.value = []
  equipmentArr.value = [
    {
      deviceId: '',
      dictKeys: []
    }
  ]


  echartsData.value = []
  echartsDataX.value = []
  tableData.value = []
  nextTick(() => {
    chartLoad()
  })
}


// 控制展示图表还是表格
let showFlag = ref(true)
const showClick = async function (flag) {
  // showFlag.value = flag === 1;
  if (flag === 1) {
    showFlag.value = true
    await getEchartsDataFun()
    if (tableData.value.length > 0) {
      await nextTick(() => {
        chartLoad()
      })
    }
  } else {
    await getTableDataFun()
    showFlag.value = false
  }
}


// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
        disabled: false
      },
      {
        start: 0,
        end: 100
      }
    ],
    series: echartsData.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 点击导出
const exportFun = function () {
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    params: equipmentArr.value,
    yom: yom.value[0]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.params.length; i++) {
    if (!data.params[i].deviceId || (data.params[i].dictKeys.length === 0)) {
      flag = true
    }
    delete data.params[i].dictList
    delete data.params[i].dictIds
  }

  if (data.startTime && data.endTime && data.frequency && data.timeUnit && !flag) {
    const downloadZip = (data, fileName = '数据查询') => {
      var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
      if ('download' in document.createElement('a')) {
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    }
    openFullScreen()
    exportDataApi(data).then(res => {
      loadingAdd.value.close()
      downloadZip(res.data)
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}


// 获取查询方案列表
let planList = ref([])
const getSearchListFun = async function () {
  await getSearchListApi(2).then(res => {
    if (res.data.code === 200) {
      planList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询方案change事件
const planChange = async function () {
  await getSearchDetailApi(plan.value).then(res => {
    if (res.data.code === 200) {
      frequency.value = res.data.data.frequency
      timeUnit.value = res.data.data.timeUnit
      timeFlag.value = res.data.data.timeFlag
      yom.value = res.data.data.yom ? [res.data.data.yom] : []
      timeFlagSelect()
      if (timeFlag.value === '-1') {
        dateTime.value = [res.data.data.startTime, res.data.data.endTime]
      }
      equipmentArr.value = res.data.data.deviceConditionList
      for (let i = 0; i < res.data.data.deviceConditionList.length; i++) {
        getDataListFun(i, res.data.data.deviceConditionList[i].deviceId)
      }
      // 处理指标
      for (let i = 0; i < equipmentArr.value.length; i++) {
        equipmentArr.value[i].dictKeys = []
        for (let j = 0; j < equipmentArr.value[i].dictList.length; j++) {
          equipmentArr.value[i].dictKeys[j] = equipmentArr.value[i].dictList[j].key
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 树形结构数据
let allTree = ref()
onMounted(async () => {
  // getEquipmentListFun()
  await getSearchListFun()
  if (router.currentRoute.value.query.id) {
    plan.value = router.currentRoute.value.query.id
    await planChange()
    await getEchartsDataFun()
  }

  // 获取所有设备树
  getAllTreeApi().then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      allTree.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})

</script>

<style lang="less" scoped>
</style>
