<template>
	<div class="log"></div>
</template>

<script setup>
	import { onMounted, onUnmounted, reactive, ref, watch, defineEmits } from "vue"
	import { useRouter } from "vue-router/dist/vue-router";
	import { Back, QuestionFilled, Document, Plus, Delete } from '@element-plus/icons-vue'
	let router = useRouter()

	const emit = defineEmits(["returnResults"])
	// 定义传值类型
	const props = defineProps({
		actionObj: String //这里就接收到父组件传递的value
	})
	/*生命周期*/
	onMounted(() => {
		if(JSON.parse(props.actionObj).log && JSON.parse(props.actionObj).log) {
			formData.value = JSON.parse(props.actionObj).log
			formData.value.type = "log"
		}
		// 禁止折叠面板按钮点击跳转
		let ele = document.querySelectorAll('.el-collapse-item button')
		for(let i = 0; i < ele.length; i++) {
			ele[i].addEventListener('click', function(event) {
				event.preventDefault();
			});
		}
	});
	let formData = ref({
		'type': "log"
	})
	let actionData = ref() // 提交表单
	//测试连接的点击
	const testConnectRules = function() {
		emit("returnResults", formData.value)
	}
	//提交的点击
	const submitFun = function() {
		emit("submitResults", formData.value)
	}
	// 暴露方法
	defineExpose({
		testConnectRules,
		submitFun
	});
</script>

<style>

</style>