import {api} from "@/api";

// 获取字典分类列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dictCategory/listAll',
        method: 'POST',
        data: data
    })
}
// 字典分类的新增/编辑
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dictCategory/save',
        method: 'POST',
        data: data
    })
}
//字典分类的删除
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dictCategory/delete',
        method: 'POST',
        data: data
    })
}


