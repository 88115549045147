import {api} from "@/api";

// 获取字典列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dict/page',
        method: 'POST',
        data: data
    })
}
//获取所有字典分类选项
export const getAllCategory = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dictCategory/listSelect',
        method: 'POST',
        data: data
    })
}
// 字典的新增/编辑
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dict/save',
        method: 'POST',
        data: data
    })
}
//获取字典详情
export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dict/getDetail',
        method: 'POST',
        data: data
    })
}
//字典的删除
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dict/delete',
        method: 'POST',
        data: data
    })
}



