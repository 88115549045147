import {api3} from "@/api";
//获取流类型
export const getSinks = (data) => {
    return api3({
        url: '/api/ekuiper/metadata/sinks',
        method: 'GET',
           params: data
    })
}
//file的测试保存
export const fileTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/file',
        method: 'POST',
        data: data
   })
}
//influx的测试保存
export const influxTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/influx',
        method: 'POST',
        data: data
   })
}
//influx2的测试保存
export const influx2Test = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/influx2',
        method: 'POST',
        data: data
   })
}
//kafka的测试保存
export const kafkaTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/kafka',
        method: 'POST',
        data: data
   })
}
//log的测试保存
export const logTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/log',
        method: 'POST',
        data: data
   })
}
//memory的测试保存
export const memoryTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/memory',
        method: 'POST',
        data: data
   })
}
//mqtt的测试保存
export const mqttTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/mqtt',
        method: 'POST',
        data: data
   })
}
//neuron的测试保存
export const neuronTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/neuron',
        method: 'POST',
        data: data
   })
}
//nop的测试保存
export const nopTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/nop',
        method: 'POST',
        data: data
   })
}
//rest的测试保存
export const restTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/rest',
        method: 'POST',
        data: data
   })
}
//sql的测试保存
export const sqlTest = (data) => {
	 return api3({
        url: '/api/ekuiper/metadata/sinks/connection/sql',
        method: 'POST',
        data: data
   })
}

//获取流配置
export const streamConfig = (data) => {
    return api3({
        url: `api/ekuiper/metadata/sources/yaml/${data}`,
        method: 'GET',
    })
}