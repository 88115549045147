<template>
	<!--微断设备管理页面-->
	<div class="device">
		<div class="title">微断设备</div>
		<div class="content">
			<div class="searchBox">
				<el-form>
					<div class="liBox">
						<el-form-item label="产品型号">
							<el-input v-model="productModel" autocomplete="off" clearable placeholder='请输入产品型号' type="text"></el-input>
						</el-form-item>
					</div>
					<div class="liBox">
						<el-form-item label="设备名称">
							<el-input v-model="name" autocomplete="off" clearable placeholder='请输入设备名称' type="text" @change="nameChange"></el-input>
						</el-form-item>
					</div>
					<div class="liBox">
						<el-form-item label="通讯地址">
							<el-input v-model="address" autocomplete="off" clearable placeholder='请输入通讯地址' type="text"></el-input>
						</el-form-item>
					</div>
					<div class="liBox">
						<el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
						<el-button class="searchButton resetButton" @click="resetClick()">重置</el-button>
					</div>
					<div class="liBox">
						<el-form-item label="设备状态">
							<el-select v-model="useStatus" clearable placeholder="请选择" style="width:100%">
								<el-option label="全部" value=""></el-option>
								<el-option label="在线" value="1"></el-option>
								<el-option label="离线" value="0"></el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div class="tableBox">
				<div class="operationBox">
					<el-button :icon="Plus" type="primary" @click="addClick()">
						新建设备
					</el-button>
					<div class="tabBox">
						<div :class="{ active: isActive=='列表' }" @click="tabClick('列表')">列表</div>
						<div :class="{ active: isActive=='卡片' }" @click="tabClick('卡片')">卡片</div>
					</div>
				</div>
				<!--卡片-->
				<div class="cardBox" v-show="isActive=='卡片'">
					<div class="cardContent" v-for="item in tableData">
						<el-tooltip class="box-item" effect="dark" content="单击进入设备管理界面" placement="top">
							<div class="titleBox" @click="managementClick(item.id)"></div>
						</el-tooltip>
						<div class="topBox">
							<div class="leftBox">
								<div>{{item.name}}</div>
								<div>{{item.createTime}}</div>
							</div>
							<div class="rightBox">
								<div class='statusOnClass' v-show="item.useStatus==1">
									<div class="dotOnClass"></div>{{item.useStatusStr}}</div>
								<div class='statusOutClass' v-show="item.useStatus==0">
									<div class="dotOutClass"></div>{{item.useStatusStr}}</div>
							</div>
						</div>
						<div class="centerBox">
							<div class="leftBox">
								<div>产品型号</div>
								<div>{{item.productModel}}</div>
							</div>
							<div class="rigthBox">
								<div>协议类型</div>
								<div>{{ item.protocolTypeStr ? item.protocolTypeStr : '698' }}</div>
							</div>
						</div>
						<div class="centerBox">
							<div class="leftBox">
								<div>通讯地址</div>
								<div>{{item.address}}</div>
							</div>
							<div class="rigthBox">
								<div>创建人</div>
								<div>{{item.creatorName}}</div>
							</div>
						</div>
						<div class="bottomBox">
								<div @click="managerClick(item.gatewayId)">网关</div>
								<div @click="transmissionFun(item.id,item.protocolType)">透传</div>
								<div @click="checkTimeClick(item.id,item.protocolType)">校时</div>
							<div @click="managementClick(item.id)">管理</div>
							<div @click="addClick(item.id)">编辑</div>
							<div @click="deleteClick(item.id)">删除</div>
						</div>
					</div>
				</div>
				<!--列表-->
				<el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" style="width: 100%;margin-top: 20px;" @selection-change="handleSelectionChange" :cell-style="cellStyle" v-show="isActive=='列表'">
					<template #empty>
						<el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
							<p>您可以在此页面新建设备信息</p>
						</el-empty>
					</template>
					<el-table-column label="设备名称" min-width="2%" property="name">
						<template #default="scope">
							<span @click="managementClick(scope.row.id)" style="cursor: pointer;">{{ scope.row.name}}</span>
						</template>
					</el-table-column>
					<el-table-column label="通讯地址" width="150" property="address">
						<template #default="scope">
							<span>{{ scope.row.address}}</span>
							<img class="adressClass" src="../../assets/img/编组@2x.png" alt="" />
						</template>
					</el-table-column>
					<el-table-column label="产品型号" min-width="2%" property="productModel">
					</el-table-column>
					<el-table-column label="状态" min-width="1%" property="useStatusStr">
						<template #default="scope">
							<div class='statusOnClass' v-show="scope.row.useStatus==1">
								<div class="dotOnClass"></div>{{ scope.row.useStatusStr }}</div>
							<div class='statusOutClass' v-show="scope.row.useStatus==0">
								<div class="dotOutClass"></div>{{ scope.row.useStatusStr }}</div>
						</template>
					</el-table-column>
					<el-table-column label="协议类型" min-width="1%" property="protocolTypeStr">
						<template #default="scope">
							<p>{{ scope.row.protocolTypeStr ? scope.row.protocolTypeStr : '698' }}</p>
						</template>
					</el-table-column>
					<el-table-column label="创建人" min-width="1%" property="creatorName">
						<template #default="scope">
							<img class="userClass" src="../../assets/img/user.png" alt="" />
							<sapn>{{ scope.row.creatorName}}</sapn>
						</template>
					</el-table-column>
					<el-table-column label="是否启用" min-width="1%" show-overflow-tooltip property="enableFlagStr">
					</el-table-column>
					<el-table-column label="创建时间" min-width="2%" property="createTime" sortable>
					</el-table-column>
					<el-table-column label="备注" min-width="2%" show-overflow-tooltip property="remark">
					</el-table-column>
					<el-table-column label="操作" min-width="3%" fixed="right">
						<template #default="scope">
							<ul class="operationUl">
								<li class="editClass" @click="managerClick(scope.row.gatewayId)">网关</li>
								<li class="editClass" @click="transmissionFun(scope.row.id,scope.row.protocolType)">透传</li>
								<li class="editClass" @click="checkTimeClick(scope.row.id,scope.row.protocolType)">校时</li>
								<li class="editClass" @click="managementClick(scope.row.id)">管理</li>
								<li class="editClass" @click="addClick(scope.row.id)">编辑
								</li>
								<li class="deleteClass" @click="deleteClick(scope.row.id)">删除
								</li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
				<div class="footerBox">
					<el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :current-page="pageNumber" :small="small" :total="total" background layout="total, sizes,prev, pager, next " small @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</div>
		</div>
		<!--新增设备弹窗-->
		<el-dialog v-model="deviceDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
			<div>
				<el-form ref="deviceFormRef" :model="deviceForm" :rules="deviceRules" class="demo-ruleForm" style="width:98%" label-width="auto">
					<div style="display: flex;">
						<div style="flex:1;margin-right: 30px;">
							<el-form-item label="产品类型" prop="categoryId">
								<el-select v-model="deviceForm.categoryId" clearable placeholder="请选择产品类型" style="width:100%" @change="categoryChange">
									<el-option v-for="item in allCategoryList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="设备名称">
								<el-input v-model="deviceForm.name" placeholder="请输入设备名称" />
							</el-form-item>
							<el-form-item label="通讯地址" prop="address">
								<el-input v-model="deviceForm.address" placeholder="请输入通讯地址" @change="addressChange" />
							</el-form-item>
							<el-form-item label="用户类型" prop="userType">
								<el-input v-model="deviceForm.userType" placeholder="请输入用户类型" />
							</el-form-item>
							<el-form-item label="端口" prop="port">
								<el-select v-model="deviceForm.port" clearable placeholder="请选择端口" style="width:100%">
									<el-option v-for="item in portList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="电流变比" prop="rateA">
								<el-input v-model="deviceForm.rateA" placeholder="请输入电流变比" type="number" />
							</el-form-item>
							
							<el-form-item label="是否启用" prop="enableFlag">
								<el-select v-model="deviceForm.enableFlag" clearable filterable placeholder="请选择是否启用" style="width:100%">
									<el-option v-for="item in enableList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div style="flex:1;">
							<el-form-item label="产品型号" prop="productId">
								<el-select v-model="deviceForm.productId" clearable placeholder="请选择产品型号" style="width:100%">
									<el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="网关" prop="gatewayId">
								<el-select v-model="deviceForm.gatewayId" clearable filterable placeholder="请选择网关" style="width:100%">
									<el-option v-for="item in gatewayList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="规约类型" prop="rulerType">
								<el-select v-model="deviceForm.rulerType" clearable placeholder="请选择规约类型" style="width:100%">
									<el-option v-for="item in rulerTypeList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="波特率" prop="rateType">
								<el-select v-model="deviceForm.rateType" clearable placeholder="请选择波特率" style="width:100%">
									<el-option v-for="item in rateTypeList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="电压变比" prop="rateV">
								<el-input v-model="deviceForm.rateV" placeholder="请输入电压变比" type="number" />
							</el-form-item>
							<el-form-item label="备注">
								<el-input v-model="deviceForm.remark" placeholder="请输入备注" />
							</el-form-item>
						</div>
					</div>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button @click="deviceDialogVisible = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">保存</el-button>
      			</span>
			</template>
		</el-dialog>
		<!--换表弹窗-->
		<el-dialog v-model="switchDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
			<div>
				<el-form ref="switchFormRef" :model="switchForm" :rules="switchRules" class="demo-ruleForm" style="width:98%">
					<el-form-item label="产品类型" prop="categoryId">
						<el-select v-model="switchForm.categoryId" clearable placeholder="请选择产品类型" style="width:100%" @change="categoryChange">
							<el-option v-for="item in allCategoryList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="产品型号" prop="productId">
						<el-select v-model="switchForm.productId" clearable placeholder="请选择产品型号" style="width:100%">
							<el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="通讯地址" prop="address">
						<el-input v-model="switchForm.address" placeholder="请输入通讯地址" @change="addressChange" />
					</el-form-item>
					<el-form-item label="更换设备的起始读数(正向有功电能)" label-width="50%">
					</el-form-item>
					<el-form-item label="尖" prop="address">
						<el-input v-model="switchForm.address" placeholder="请输入" @change="addressChange" />
					</el-form-item>
					<el-form-item label="峰" prop="address">
						<el-input v-model="switchForm.address" placeholder="请输入" @change="addressChange" />
					</el-form-item>
					<el-form-item label="平" prop="address">
						<el-input v-model="switchForm.address" placeholder="请输入" @change="addressChange" />
					</el-form-item>
					<el-form-item label="谷" prop="address">
						<el-input v-model="switchForm.address" placeholder="请输入" @change="addressChange" />
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button @click="deviceDialogVisible = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">保存</el-button>
      			</span>
			</template>
		</el-dialog>
		
		<!--透传网关弹窗-->
		<el-dialog v-model="gatewayTrans" :close-on-click-modal="false" class="gatewayTrans" title="透传" width="50%">
			<div class="textarea1">
				<el-table v-if="readData instanceof Array&&readData.length>0" v-loading="readLoading" :data="readData" border style="width: 100%">
					<el-table-column label="上报时间" min-width="1%" prop="applyTime">
					</el-table-column>
					<el-table-column label="上报内容" min-width="2%" prop="apply">
					</el-table-column>
				</el-table>
				<p v-if="typeof readData == 'string'">{{ readData }}</p>
			</div>
			<div slot="footer" class="dialog-footer">
				<div style="display: flex;justify-content: space-between;position: relative;">
					<el-input v-model="msg" autosize class="textarea2" type="textarea">
					</el-input>
					<el-button class="confrim1" style=";position: absolute;bottom:0;right:0" type="primary" @click="sendSave()">
						发送
					</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script setup>
	import { reactive, onMounted, computed, watch, ref, defineComponent, h } from 'vue';
	import { useRouter } from 'vue-router';
	import { ElTable, ElMessage, ElMessageBox } from 'element-plus'
	import { ArrowUpBold, ArrowDownBold, Plus } from '@element-plus/icons-vue'
	import {
		getList,
		getAllCategory,
		getProductList,
		getSelectList,
		getGatewayList,
		getDetail,
		saveList,
		deleteList
	} from '../../api/modules/device'
	import {
		checkTimeFun,
		sendCommandList,
		readResultList,
		// 376.1
		tSendCommandListApi,
		tCheckTimeApi
	} from '../../api/modules/gateway'
	import './device.less'
	import qs from 'qs';

	let router = useRouter()
	/*生命周期*/
	onMounted(() => {
		if(router.currentRoute.value.query.pn){
			pageNumber.value=Number(router.currentRoute.value.query.pn)
		}
		name.value=window.localStorage.getItem('name')
		getListFun()
		getAllCategoryFun()
		getSelectListFun()
		getGatewayListFun()
	})
	//设备名称的改变
	const nameChange=function(){
		window.localStorage.setItem('name', name.value)
	}
	let pageNumber = ref(1) //页码
	let pageSize = ref(20) //页数
	let total = ref(0) //总页数
	let tableData = ref([]) //列表数据
	//重置
	const resetClick = function() {
		productModel.value = ''
		name.value = ''
		address.value = ''
		useStatus.value = ''
	}
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageNumber.value = val;
		getListFun();
	}
	let loadingVal = ref(false) //表格加载
	let productModel = ref() //产品线型号
	let name = ref() //设备名称
	let address = ref() //通讯地址
	let useStatus = ref('') //状态
	//获取设备列表
	const getListFun = function() {
		loadingVal.value = true
		let params = {
			pageNumber: pageNumber.value,
			pageSize: pageSize.value,
			name: name.value,
			address: address.value,
			productModel: productModel.value,
			useStatus: useStatus.value,
			equipmentType:2
		}
		getList(qs.stringify(params)).then(res => {
			loadingVal.value = false
			//console.log(res);
			if(res.data.ok) {
				tableData.value = res.data.result.list
				total.value = Number(res.data.result.total)
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let allCategoryList = ref([]) //所有分类列表
	//所有分类列表
	const getAllCategoryFun = function() {
		getAllCategory(null).then(res => {
			//console.log(res);
			if(res.data.ok) {
				allCategoryList.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let productList = ref([]) //产品列表
	//产品列表
	const getProductListFun = function() {
		getProductList(qs.stringify({
			categoryId: deviceForm.value.categoryId
		})).then(res => {
			//console.log(res);
			if(res.data.ok) {
				productList.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let portList = ref([])
	let rateTypeList = ref([])
	let rulerTypeList = ref([])
	let enableList=ref([{id:1,name:'是'},{id:0,name:'否'}])
	const getSelectListFun = function() {
		getSelectList(null).then(res => {
			//console.log(res);
			if(res.data.ok) {
				portList.value = res.data.result.portList
				rateTypeList.value = res.data.result.rateTypeList
				rulerTypeList.value = res.data.result.rulerTypeList
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let gatewayList = ref([]) //网关列表
	const getGatewayListFun = function() {
		getGatewayList(null).then(res => {
			//console.log(res);
			if(res.data.ok) {
				gatewayList.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}

	//分类改变
	const categoryChange = function() {
		if(deviceForm.value.categoryId) {
			getProductListFun()
		}
		deviceForm.value.productId = ''
	}

	let dialogWidth = ref('60%') //新增设备弹窗宽度
	let deviceDialogVisible = ref(false) //新增设备弹窗开关
	let title = ref() //弹窗标题
	let editId = ref() //编辑id
	//新增设备的点击
	const addClick = function(id) {
		if(id) {
			editId.value = id
			title.value = '编辑设备'
			getDetail(qs.stringify({
				id: id
			})).then(res => {
				//console.log(res);
				if(res.data.ok) {
					deviceForm.value.categoryId = res.data.result.categoryId
					deviceForm.value.productId = res.data.result.productId
					deviceForm.value.gatewayId = res.data.result.gatewayId
					deviceForm.value.name = res.data.result.name
					deviceForm.value.address = res.data.result.address
					deviceForm.value.enableFlag = res.data.result.enableFlag
					deviceForm.value.rulerType = String(res.data.result.rulerType)
					deviceForm.value.userType = res.data.result.userType
					deviceForm.value.rateType = String(res.data.result.rateType)
					deviceForm.value.port = String(res.data.result.port)
					deviceForm.value.rateV = res.data.result.rateV
					deviceForm.value.rateA = res.data.result.rateA
					deviceForm.value.remark = res.data.result.remark
					getProductListFun()
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			editId.value = ''
			title.value = '新建设备'
			deviceForm.value = {}
		}
		deviceDialogVisible.value = true
	}
	let deviceFormRef = ref() //设备
	//设备表单
	let deviceForm = ref({
		categoryId: '',
		productId: '',
		gatewayId: '',
		name: '',
		address: '',
		enableFlag:'',
		rulerType: '',
		userType: '',
		rateType: '',
		port: '',
		rateV: '',
		rateA: '',
		remark: ''
	})
	// 设备表单验证规则
	let deviceRules = ref({
		categoryId: [{
			required: true,
			message: '请选择产品类型',
			trigger: 'blur'
		}],
		productId: [{
			required: true,
			message: '请选择产品型号',
			trigger: 'blur'
		}],
		gatewayId: [{
			required: true,
			message: '请选择网关',
			trigger: 'blur'
		}],
		name: [{
			required: true,
			message: '请输入设备名称',
			trigger: 'blur'
		}],
		address: [{
			required: true,
			message: '请输入通讯地址',
			trigger: 'blur'
		}],
		port: [{
			required: true,
			message: '请选择端口',
			trigger: 'blur'
		}],
		rateV: [{
			required: true,
			message: '请输入电压变比',
			trigger: 'blur'
		}],
		rateA: [{
			required: true,
			message: '请输入电流变比',
			trigger: 'blur'
		}],
	})
	//设备的保存
	const deviceSave = function() {
		let params = {
			productId: deviceForm.value.productId,
			gatewayId: deviceForm.value.gatewayId,
			name: deviceForm.value.name,
			address: deviceForm.value.address,
			enableFlag: deviceForm.value.enableFlag,
			rulerType: deviceForm.value.rulerType,
			userType: deviceForm.value.userType,
			rateType: deviceForm.value.rateType,
			port: deviceForm.value.port,
			rateV: deviceForm.value.rateV,
			rateA: deviceForm.value.rateA,
			remark: deviceForm.value.remark,
			type:2
		}
		if(title.value == '新建设备') {
			params['id'] = 0
		} else if(title.value == '编辑设备') {
			params['id'] = editId.value
		}
		if(deviceFormRef) {
			deviceFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					saveList(
						qs.stringify(params)
					).then(async res => {
						//console.log(res);
						if(res.data.ok) {
							deviceDialogVisible.value = false
							getListFun()
						} else {
							ElMessage({
								message: res.data.message,
								type: 'error'
							})
						}
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}
	}
	//删除
	const deleteClick = function(id) {

		ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			title: '删除设备',
			message: h('p', null, [
				h('img', {
					src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
					style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
				}, ''),
				h('span', {
					style: 'color:#595959;'
				}, '请确认是否要删除此设备，删除后数据不可恢复'),
			])
		}).then(() => {
			deleteList(
				qs.stringify({
					id: id
				})
			).then(res => {
				//console.log(res);
				if(res.data.ok) {
					getListFun()
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消删除',
				type: 'info',
			})
		});
	}
	//通讯地址改变
	const addressChange = function() {
		deviceForm.value.address = deviceForm.value.address.padStart(12, '0')
	}
	//管理
	const managementClick = function(id) {
		router.push({
			path: '/admin/deviceManagement',
			query: {
				id: id,
				pn:pageNumber.value,
				ty:2
			}
		})
	}
	//换表*************************************************
	let switchDialogVisible = ref(false) //换表弹窗开关
	let switchFormRef = ref() //换表
	//换表表单
	let switchForm = ref({
		categoryId: '',
		productId: '',
		address: '',
	})
	// 换表表单验证规则
	let switchRules = ref({
		categoryId: [{
			required: true,
			message: '请选择产品类型',
			trigger: 'blur'
		}],
		productId: [{
			required: true,
			message: '请选择产品型号',
			trigger: 'blur'
		}]
	})
	const switchClick = function() {
		switchDialogVisible.value = true
		title.value = '换表'
	}
	const cellStyle = ({
		row,
		column,
		rowIndex,
		columnIndex
	}) => {
		if(columnIndex === 0) {
			return {
				color: "#165DFF"
			};
		}
	}
	/*切换卡片*/
	let isActive = ref('列表')
	const tabClick = function(val) {
		isActive.value = val
	}
	
	//微断网关管理
	const managerClick = function(id) {
		router.push({
			path: '/admin/gatewayDetail',
			query: {
				id: id,
				pn:pageNumber.value,
				flag:true
			}
		})
	}
	// 透传相关事件********************************************************
	let gatewayTrans = ref(false)
	let gateWayId = ref()
	let gateType = ref()

	const transmissionFun = function(id, type) {
		gateWayId.value = id
		gateType.value = type
		gatewayTrans.value = true
		// readData.value = []
		msg.value = ''
	}
	let readLoading = ref(false)
	let readData = ref()
	let msg = ref()
	// 透传提交
	const sendSave = function() {
		if(msg.value) {
			//msg.value = msg.value.replace(/\s*/g, ""); //去除空格
			openFullScreen()
			gateType.value == 1 ? tSendCommandListApi({
				// sendCommandList(qs.stringify({
				gatewayId: gateWayId.value,
				msg: msg.value
			}).then(async res => {
				if(res.data.ok) {
					if(res.data.result) {
						await readResultListFun(res.data.result.commandLogId);
					}
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
					loadingVal.value.close()
				}
			}).catch(err => {
				console.log(err);
			}) : sendCommandList(qs.stringify({
				gatewayId: gateWayId.value,
				msg: msg.value.replace(/\s*/g, "") //去除空格
			})).then(async res => {
				if(res.data.ok) {
					if(res.data.result) {
						await readResultListFun(res.data.result);
					}
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
					loadingVal.value.close()
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			ElMessage({
				type: 'info',
				message: '请先输入报文!'
			});
		}
	}
	//透传结果
	const readResultListFun = function(id) {
		let num = 0
		let timer = setInterval(() => {
			num++
			readResultList(qs.stringify({
				commandLogId: id
			})).then(res => {
				//console.log(num)
				//console.log(res);
				if(res.data.ok) {
					if(res.data.result.length > 0) {
						loadingVal.value.close()
						readData.value = res.data.result
						ElMessage({
							type: 'success',
							message: '透传发送成功!'
						});
					}
					if(num > 9 || res.data.result.length > 0) {
						clearInterval(timer)
					}
					if(num > 9) {
						loadingVal.value.close()
						ElMessage({
							type: 'error',
							message: '透传发送失败!'
						});
					}
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}, 1000)
	}
	//透传读取列表
	const command_applyListFun = function(id) {
		var params = {
			pageSize: -1,
			pageNum: 1,
			commandLogId: id
		}
		command_applyList(params).then(res => {
			//readLoading.value = false
			loadingVal.value.close()
			readData.value = []
			if(res.data.ok) {
				readData.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//校时
	const checkTimeClick = function(id, type) {
		ElMessageBox.confirm("确认校时吗？", '确认要校时吗？', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(async() => {
			openFullScreen()
			type == 1 ? tCheckTimeApi(qs.stringify({
				gatewayId: id
			})).then(res => {
				getListFun()
				loadingVal.value.close()
				//console.log(res);
				if(res.data.ok) {
					ElMessage({
						message: '校时成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			}) : checkTimeFun(qs.stringify({
				gatewayId: id
			})).then(res => {
				getListFun()
				loadingVal.value.close()
				//console.log(res);
				if(res.data.ok) {
					ElMessage({
						message: '校时成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消校时！',
			})
		})
	}
</script>