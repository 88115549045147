let letterList = function () {
  return [
    {
      name: 'A',
      idx: 1
    },
    {
      name: 'B',
      idx: 2
    },
    {
      name: 'C',
      idx: 3
    },
    {
      name: 'D',
      idx: 4
    },
    {
      name: 'E',
      idx: 5
    },
    {
      name: 'F',
      idx: 6
    },
    {
      name: 'G',
      idx: 7
    },
    {
      name: 'H',
      idx: 8
    },
    {
      name: 'I',
      idx: 9
    },
    {
      name: 'J',
      idx: 10
    },
    {
      name: 'K',
      idx: 11
    },
    {
      name: 'L',
      idx: 12
    },
    {
      name: 'M',
      idx: 13
    },
    {
      name: 'N',
      idx: 14
    },
    {
      name: 'O',
      idx: 15
    },
    {
      name: 'P',
      idx: 16
    },
    {
      name: 'Q',
      idx: 17
    },
    {
      name: 'R',
      idx: 18
    },
    {
      name: 'S',
      idx: 19
    },
    {
      name: 'T',
      idx: 20
    },
    {
      name: 'U',
      idx: 21
    },
    {
      name: 'V',
      idx: 22
    },
    {
      name: 'W',
      idx: 23
    },
    {
      name: 'X',
      idx: 24
    },
    {
      name: 'Y',
      idx: 25
    },
    {
      name: 'Z',
      idx: 26
    }
  ]
}

export default letterList

