import {api} from "@/api";

// 获取网关任务表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/page',
        method: 'POST',
        data: data
    })
}
//获取所有方案
export const getPlanList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/listPlanSelect',
        method: 'POST',
        data: data
    })
}
//获取所有select
export const getSelectList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/getSelect',
        method: 'POST',
        data: data
    })
}

//新增、编辑的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/save',
        method: 'POST',
        data: data
    })
}

//获取网关任务详情
export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/getDetail',
        method: 'POST',
        data: data
    })
}


//删除网关
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/delete',
        method: 'POST',
        data: data
    })
}
//下发任务
export const downList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/down',
        method: 'POST',
        data: data
    })
}
//清空任务
export const cleanList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/clean',
        method: 'POST',
        data: data
    })
}
//读取任务
export const readList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/read',
        method: 'POST',
        data: data
    })
}
//读取档案结果
export const readResultList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/getReadResult',
        method: 'POST',
        data: data
    })
}
//读取解析
export const readParseList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayTask/readParse',
        method: 'POST',
        data: data
    })
}

// 设置任务
export const setTaskApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/protocol3761/command/setTask',
        method: 'POST',
        data: data
    })
}