import {ref, onMounted, onUnmounted} from 'vue';

export function useCurrentTime(updateIntervalMs = 1000) {
    let timeVal = ref()
    let dateVal = ref()
    let weekVal = ref()

    function updateTime() {
        // 当前时间
        const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
        const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
        const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
        const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
        const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
        const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
        timeVal.value = hours + ':' + minutes + ':' + seconds
        dateVal.value = year + '.' + month + '.' + day
        weekVal.value = new Intl.DateTimeFormat('zh-CN', {
            weekday: 'long'
        }).format(new Date());
    }

    // 在组件挂载时启动定时器更新当前时间
    onMounted(() => {
        const timerId = setInterval(updateTime, updateIntervalMs);
        // 返回清除定时器的函数，便于在组件卸载时清理资源
        return () => clearInterval(timerId);
    });

    return {timeVal, dateVal, weekVal};
}