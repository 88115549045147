import {api} from "@/api";

// 获取网关方案表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/page',
        method: 'POST',
        data: data
    })
}

//获取所有select
export const getSelectList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/getSelect',
        method: 'POST',
        data: data
    })
}

//获取所有抄读select
export const listParserSelect = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/listParserSelect',
        method: 'POST',
        data: data
    })
}
//获取数据项select
export const listParserItemSelect = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/listSelect',
        method: 'POST',
        data: data
    })
}
//新增、编辑的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/save',
        method: 'POST',
        data: data
    })
}

//获取网关任务详情
export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/getDetail',
        method: 'POST',
        data: data
    })
}


//删除网关
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/delete',
        method: 'POST',
        data: data
    })
}
//下发方案//todo报500
export const downList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/down',
        method: 'POST',
        data: data
    })
}
//清空方案
export const cleanList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/clean',
        method: 'POST',
        data: data
    })
}
//读取方案
export const readList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/read',
        method: 'POST',
        data: data
    })
}
//读取档案结果
export const readResultList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/getReadResult',
        method: 'POST',
        data: data
    })
}
//读取解析
export const readParseList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gatewayPlan/readParse',
        method: 'POST',
        data: data
    })
}