import {api} from "@/api";

// 获取关联设备
export const getRelationListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/listRelation',
        method: 'POST',
        data: data
    })
}


// 获取控制日志列表
export const getControlLogListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/controlLog/page',
        method: 'POST',
        data: data
    })
}