<template>
  <div class="fxlwwScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="mainCont">
          <div class="head"></div>
          <div class="timeBox">
            <p class="time">{{ timeVal }}</p>
            <p class="date">{{ dateVal }}</p>
            <!--          <p class="week">{{ weekVal }}</p>-->
          </div>
          <div class="weatherBox">
            <span>{{ currentWeatherObj.city }}</span>
            <span> {{
                allWeatherData[0] ? allWeatherData[0].nighttemp : ''
              }}~{{ allWeatherData[0] ? allWeatherData[0].daytemp : '' }}C°</span>
            <span>{{ currentWeatherObj.weather }}</span>
          </div>
          <div class="leftBox">
            <div class="leftBoxItem">
              <div class="dataBox">
                <div class="dataItem">
                  <p class="name">今日</p>
                  <count-up ref="countUp1" :end-val="data.storeEnergyToday" :start-val="countStart1"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">昨日</p>
                  <count-up ref="countUp2" :end-val="data.storeEnergyYesterday" :start-val="countStart2"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">当月累计</p>
                  <count-up ref="countUp3" :end-val="data.storeEnergyTotal" :start-val="countStart3"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">储能状态</p>
                  <div class="val val2">
                    <count-up ref="countUp4" :end-val="data.rechargeRate" :start-val="countStart4"
                              style="color: #7BFFAD"></count-up>
                    <span style="color: #7BFFAD">%</span>
                    <!--                    <div class="iconBox">-->
                    <!--                      &lt;!&ndash;<img alt="" src="../../../assets/img/cd.svg" >&ndash;&gt;-->
                    <!--                      <p v-if="data.status==0">停机</p>-->
                    <!--                      <p v-if="data.status==1">待机</p>-->
                    <!--                      <p v-if="data.status==2">放电</p>-->
                    <!--                      <p v-if="data.status==3">充电</p>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <div class="chartBox">
                <!--              <p class="chartTitle">近七日发电趋势</p>-->
                <div class="legend">
                  <p>近7日放电趋势</p>
                  <p class="rect"></p>
                </div>
                <div class="chart1_5 chart"></div>
              </div>
            </div>
            <div class="leftBoxItem">
              <div class="dataBox">
                <div class="dataItem">
                  <p class="name">今日</p>
                  <count-up ref="countUp5" :end-val="data.photovoltaicGenerateElectricityToday" :start-val="countStart5"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">昨日</p>
                  <count-up ref="countUp6" :end-val="data.photovoltaicGenerateElectricityYesterday"
                            :start-val="countStart6" class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">当月累计</p>
                  <count-up ref="countUp7" :end-val="data.photovoltaicGenerateElectricityMonth" :start-val="countStart7"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">历史累计</p>
                  <count-up ref="countUp8" :end-val="data.photovoltaicGenerateElectricityTotal" :start-val="countStart8"
                            class="val"></count-up>
                </div>
              </div>
              <div class="chartBox">
                <!--              <p class="chartTitle">近七日充电量趋势</p>-->
                <div class="legend">
                  <p>近7日上网电量趋势</p>
                  <p class="rect"></p>
                </div>
                <div class="chart2_5 chart"></div>
              </div>
            </div>
            <div class="leftBoxItem">
              <div class="headBox">
                <p>时间</p>
                <p>设备名称</p>
                <p>控制名称</p>
                <p>控制参数</p>
              </div>
              <el-carousel :autoplay="true" direction="vertical" height="200px">
                <el-carousel-item v-for="item in data.logList" :key="item">
                  <div v-for="item_child in item" class="contItem">
                    <p>{{ item_child.executeTime }}</p>
                    <p>{{ item_child.deviceName }}</p>
                    <p>{{ item_child.controlName }}</p>
                    <p :title="item_child.controlParamList[0]">{{ item_child.controlParamList[0] }}</p>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <img alt="" class="line1" src="../../../assets/img/fxlLine.png">
            <img alt="" class="line2" src="../../../assets/img/fxlLine.png">
          </div>
          <div class="rightBox">
            <div class="rightBoxItem ">
              <div class="dataBox">
                <div class="dataItem">
                  <p class="name">今日</p>
                  <count-up ref="countUp9" :end-val="data.totalUseToday" :start-val="countStart9"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">昨日</p>
                  <count-up ref="countUp10" :end-val="data.totalUseYesterday" :start-val="countStart10"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">当月累计</p>
                  <count-up ref="countUp11" :end-val="data.totalUseMonth" :start-val="countStart11"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">历史累计</p>
                  <count-up ref="countUp12" :end-val="data.totalUseTotal" :start-val="countStart12"
                            class="val"></count-up>
                </div>
              </div>
              <div class="chartBox">
                <!--              <p class="chartTitle">近七日充电量趋势</p>-->
                <div class="legend">
                  <p>用电量趋势</p>
                  <p class="rect"></p>
                </div>
                <div class="circleChart_5 circleChart chart"></div>
              </div>
            </div>
            <div class="rightBoxItem">
              <div class="dataBox">
                <div class="dataItem">
                  <p class="name">今日</p>
                  <count-up ref="countUp13" :end-val="data.photovoltaicUpToday" :start-val="countStart13"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">昨日</p>
                  <count-up ref="countUp14" :end-val="data.photovoltaicUpYesterday" :start-val="countStart14"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">当月累计</p>
                  <count-up ref="countUp15" :end-val="data.photovoltaicUpMonth" :start-val="countStart15"
                            class="val"></count-up>
                </div>
                <div class="dataItem">
                  <p class="name">历史累计</p>
                  <count-up ref="countUp16" :end-val="data.photovoltaicUpTotal" :start-val="countStart16"
                            class="val"></count-up>
                </div>
              </div>
              <div class="chartBox">
                <!--              <p class="chartTitle">当日储能发电功率</p>-->
                <div class="legend">
                  <p>用电量趋势</p>
                  <p class="rect"></p>
                </div>
                <div class="chart3_5 chart"></div>
              </div>
            </div>
            <div class="rightBoxItem">
              <div class="chartBox">
                <div class="legendBox">
                  <div class="legend">
                    <p>负载</p>
                    <p class="rect"></p>
                  </div>
                  <div class="legend">
                    <p>储能</p>
                    <p class="rect"></p>
                  </div>
                </div>
                <div class="chart chart5_5"></div>
              </div>
            </div>
            <img alt="" class="line1" src="../../../assets/img/fxlLine.png">
            <img alt="" class="line2" src="../../../assets/img/fxlLine.png">
          </div>
          <div class="topBox">
            <div class="topBoxItem">
              <!--            <img alt="" src="../../../assets/img/icon.svg">-->
              <div class="valCont">
                <p class="val">
                  <count-up ref="countUp17" :decimalPlaces="2" :end-val="data.totalRate" :start-val="countStart17"
                            class="val"
                            style="display: inline-block"></count-up>
                </p>
                <p class="name">总表功率(kw)</p>
              </div>
            </div>
            <div class="topBoxItem">
              <!--            <img alt="" src="../../../assets/img/icon2.svg">-->
              <div class="valCont">
                <p class="val">
                  <count-up ref="countUp18" :decimalPlaces="2" :end-val="data.thatDayIncome" :start-val="countStart18"
                            class="val"
                            style="display: inline-block"></count-up>
                </p>
                <p class="name">负载功率(kw)</p>
              </div>
            </div>
            <div class="topBoxItem">
              <!--            <img alt="" src="../../../assets/img/icon3.svg">-->
              <div class="valCont">
                <p class="val">
                  <count-up ref="countUp19" :decimalPlaces="2" :end-val="data.storeEnergyRate" :start-val="countStart19"
                            class="val"
                            style="display: inline-block"></count-up>
                </p>
                <p class="name">储能功率(kw)</p>
              </div>
            </div>
            <div class="topBoxItem">
              <!--            <img alt="" src="../../../assets/img/icon4.svg">-->
              <div class="valCont">
                <p class="val">
                  <count-up ref="countUp20" :decimalPlaces="2" :end-val="data.storeEnergyThatDayIncome"
                            :start-val="countStart20"
                            class="val"
                            style="display: inline-block"></count-up>
                </p>
                <p class="name">储能当天收益(元)</p>
              </div>
            </div>
          </div>
          <div class="mainCircuit">
            <!--          <change-circuit :type="transitionName" :data="circuitValue"/>-->
            <transition appear mode="out-in" name="el-fade-in-linear">
              <circuit :List="List" :asyncData="circuitValue"/>
            </transition>
          </div>
        </div>
      </div>
    </v-scale-screen>
  </div>
</template>
<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import VScaleScreen from 'v-scale-screen'
import './fxlwwScreen.less'
import * as echarts from "echarts";
import {fxlcnScreenApi, getWeatherApi, getFxlDeviceListApi, fxlwwScreenApi} from '@/api/modules/bigScreen'
import {ElMessage} from "element-plus";
import Circuit from "@/components/fxlcircuit.vue";
import CountUp from 'vue-countup-v3'
// import ChangeCircuit from "@/components/changeCircuit.vue";
import useCircuit from "@/hooks/useCircuit";


/*天气************************************************/
let currentWeatherObj = ref({}) //实时天气
//实时天气
const currentWeatherFun = function () {
  getWeatherApi('base').then(res => {
    if (res.data.status === '1') {
      currentWeatherObj.value = res.data.lives[0]
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let allWeatherData = ref([]) //所有天气
//所有天气
const allWeatherFun = function () {
  getWeatherApi('all').then(res => {
    if (res.data.status === '1') {
      allWeatherData.value = res.data.forecasts[0].casts
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.hngfScreen'); // 获取需要全屏的元素;
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};
const check = function () {
  enterFullScreen()
}
// 当前时间
let timeVal = ref()
let dateVal = ref()
let weekVal = ref()
const updateTime = function () {
  const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
  const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
  const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
  const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
  const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
  const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
  timeVal.value = hours + ':' + minutes + ':' + seconds
  dateVal.value = year + '.' + month + '.' + day
  weekVal.value = new Intl.DateTimeFormat('zh-CN', {
    weekday: 'long'
  }).format(new Date());
}

// 加载左边第一个
const chartLoad1 = function () {
  var chartDom = document.querySelector('.chart1_5');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 28,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.storeEnergyListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#FFFFFF',
        }
      },
    },
    series: [{
      data: data.value.storeEnergyList,
      type: 'line',
      itemStyle: {
        color: '#55B5FF',
        opacity: 0
      },
      lineStyle: {
        width: 2.5
      },
      markPoint: {
        data: [{
          type: 'max',
          name: 'Max',
          symbolSize: 40,
        }]
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(54, 161, 255, 0.60)'
        },
          {
            offset: 1,
            color: 'rgba(25, 104, 255, 0)'
          },
        ])
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载左边第二个
const chartLoad2 = function () {
  var chartDom = document.querySelector('.chart2_5');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 28,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.photovoltaicGenerateElectricityListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      {
        data: JSON.parse(JSON.stringify(data.value.photovoltaicGenerateElectricityList)),
        type: 'bar',
        barWidth: 18,
        itemStyle: {
          color: 'transparent',
          borderWidth: 1,
          borderColor: 'rgba(255,255,255,.5)'
        },
        tooltip: {
          show: false
        },
      },
      {
        type: "pictorialBar", //设置类型为象形柱状图
        border: 12,
        symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#B0FFEB",
            shadowColor: '#0078FF',
            shadowBlur: 12,
            shadowOffsetX: 1,
          }
        },
        symbolRepeat: "true",
        symbolSize: [14, 5],
        z: 1,
        data: data.value.photovoltaicGenerateElectricityList,
      },
      {
        type: "pictorialBar", //设置类型为象形柱状图
        symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#094E66",
            barBorderColor: "#FFFFFF", // 边框色
            barBorderWidth: 1, // 边框宽度
          }
        },
        tooltip: {
          show: false
        },
        label: {
          show: false,
          position: 'top', // 位置
          color: '#f0f0f0',
          fontSize: 14,
        },
        symbolRepeat: "fixed",
        symbolSize: [14, 5],
        z: 0,
        data: data.value.photovoltaicGenerateElectricityList,
      }]
  };

  myChart.clear()
  option && myChart.setOption(option);
  nextTick(() => {
    let max = myChart.getModel().getComponent('yAxis', 0).axis.scale.getExtent()[1]
    for (let i = 0; i < option.series[0].data.length; i++) {
      option.series[0].data[i] = max
    }
    myChart.setOption(option);
  })


  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第一个
const circleChartLoad = function () {
  var chartDom = document.querySelector('.circleChart_5');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 28,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.totalUseListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [{
      data: data.value.totalUseList,
      type: 'line',
      itemStyle: {
        color: '#55B5FF',
        opacity: 0
      },
      lineStyle: {
        width: 2.5
      },
      markPoint: {
        data: [{
          type: 'max',
          name: 'Max',
          symbolSize: 40,
        }]
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(54, 161, 255, 0.60)'
        },
          {
            offset: 1,
            color: 'rgba(25, 104, 255, 0)'
          },
        ])
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第二个
const chartLoad3 = function () {
  var chartDom = document.querySelector('.chart3_5');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 28,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.photovoltaicUpListX,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [{
      type: 'line',
      data: data.value.photovoltaicUpList,
      itemStyle: {
        color: '#51FFDF',
        opacity: 0
      },
      lineStyle: {
        width: 2.5
      },
      markPoint: {
        data: [{
          type: 'max',
          name: 'Max',
          symbolSize: 40
        }]
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(30, 239, 196, 0.65)'
        },
          {
            offset: 1,
            color: 'rgba(6, 169, 137, 0)'
          },
        ])
      }
    }],
  };
  myChart.clear()
  option && myChart.setOption(option);
  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载下边第二个
const chartLoad5 = function () {
  var chartDom = document.querySelector('.chart5_5');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.rateTimeList
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      {
        data: data.value.rateValueListY1,
        type: 'line',
        itemStyle: {
          color: '#54B8FF',
          opacity: 0
        },
        lineStyle: {
          width: 2.5
        },
        markPoint: {
          data: [{
            type: 'max',
            name: 'Max',
            symbolSize: 40
          }]
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(54, 161, 255, 0.60)'
          },
            {
              offset: 1,
              color: 'rgba(0, 61, 178, 0)'
            },
          ])
        }
      },
      {
        data: data.value.rateValueListY2,
        type: 'line',
        itemStyle: {
          color: '#51FFDF',
          opacity: 0
        },
        lineStyle: {
          width: 2.5
        },
        markPoint: {
          data: [{
            type: 'max',
            name: 'Max',
            symbolSize: 40
          }]
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(30, 239, 196, 0.65)'
          },
            {
              offset: 1,
              color: 'rgba(0, 61, 178, 0)'
            },
          ])
        }
      }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

let data = ref({
  "generateElectricityMonth": "",
  "generateElectricityMonthList": [],
  "generateElectricityToday": "",
  "generateElectricityTotal": "",
  "generateElectricityYesterday": "",
  "generateRateList": [],
  "generateRateMaxTime": "",
  "generateRateMaxValue": "",
  "generateRateMinTime": "",
  "generateRateMinValue": "",
  "generateRateNowTime": "",
  "generateRateNowValue": "",
  "inTimeRate": "",
  "monthGenerateElectricityList": [],
  "photovoltaicEquipmentList": [],
  "standardCoal": "",
  "storeEquipment": {
    "key": "",
    "value": ""
  },
  "thatDayIncome": "",
  "todayGenerateElectricityList": [],
  "totalIncome": "",
  "upMonth": "",
  "upMonthList": [],
  "upToday": "",
  "upTotal": "",
  "upYesterday": "",
  logList: []
})

// 数字轮播数据
let countUp1 = ref()
let countUp2 = ref()
let countUp3 = ref()
let countUp4 = ref()
let countUp5 = ref()
let countUp6 = ref()
let countUp7 = ref()
let countUp8 = ref()
let countUp9 = ref()
let countUp10 = ref()
let countUp11 = ref()
let countUp12 = ref()
let countUp13 = ref()
let countUp14 = ref()
let countUp15 = ref()
//数字轮播初始值
let countStart1 = ref(0)
let countStart2 = ref(0)
let countStart3 = ref(0)
let countStart4 = ref(0)
let countStart5 = ref(0)
let countStart6 = ref(0)
let countStart7 = ref(0)
let countStart8 = ref(0)
let countStart9 = ref(0)
let countStart10 = ref(0)
let countStart11 = ref(0)
let countStart12 = ref(0)
let countStart13 = ref(0)
let countStart14 = ref(0)
let countStart15 = ref(0)
let countStart16 = ref(0)
let countStart17 = ref(0)
let countStart18 = ref(0)
let countStart19 = ref(0)
let countStart20 = ref(0)

// 获取大屏数据
const getDataFun = async function () {
  await fxlwwScreenApi().then(res => {
    if (res.data.code === 0) {
      if (data.value.storeEnergyToday != res.data.result.storeEnergyToday) {
        countStart1.value = data.value.storeEnergyToday
      }
      if (data.value.storeEnergyYesterday != res.data.result.storeEnergyYesterday) {
        countStart2.value = data.value.storeEnergyYesterday
      }
      if (data.value.storeEnergyTotal != res.data.result.storeEnergyTotal) {
        countStart3.value = data.value.storeEnergyTotal
      }
      if (data.value.rechargeRate != res.data.result.rechargeRate) {
        countStart4.value = data.value.rechargeRate
      }
      if (data.value.photovoltaicGenerateElectricityToday != res.data.result.photovoltaicGenerateElectricityToday) {
        countStart5.value = data.value.photovoltaicGenerateElectricityToday
      }
      if (data.value.photovoltaicGenerateElectricityYesterday != res.data.result.photovoltaicGenerateElectricityYesterday) {
        countStart6.value = data.value.photovoltaicGenerateElectricityYesterday
      }
      if (data.value.photovoltaicGenerateElectricityMonth != res.data.result.photovoltaicGenerateElectricityMonth) {
        countStart7.value = data.value.photovoltaicGenerateElectricityMonth
      }
      if (data.value.photovoltaicGenerateElectricityTotal != res.data.result.photovoltaicGenerateElectricityTotal) {
        countStart8.value = data.value.photovoltaicGenerateElectricityTotal
      }
      if (data.value.totalUseToday != res.data.result.totalUseToday) {
        countStart9.value = data.value.totalUseToday
      }
      if (data.value.totalUseYesterday != res.data.result.totalUseYesterday) {
        countStart10.value = data.value.totalUseYesterday
      }
      if (data.value.totalUseMonth != res.data.result.totalUseMonth) {
        countStart11.value = data.value.totalUseMonth
      }
      if (data.value.totalUseTotal != res.data.result.totalUseTotal) {
        countStart12.value = data.value.totalUseTotal
      }
      if (data.value.photovoltaicUpToday != res.data.result.photovoltaicUpToday) {
        countStart13.value = data.value.photovoltaicUpToday
      }
      if (data.value.photovoltaicUpYesterday != res.data.result.photovoltaicUpYesterday) {
        countStart14.value = data.value.photovoltaicUpYesterday
      }
      if (data.value.photovoltaicUpMonth != res.data.result.photovoltaicUpMonth) {
        countStart15.value = data.value.photovoltaicUpMonth
      }
      if (data.value.photovoltaicUpTotal != res.data.result.photovoltaicUpTotal) {
        countStart16.value = data.value.photovoltaicUpTotal
      }
      if (data.value.totalRate != res.data.result.totalRate) {
        countStart17.value = data.value.totalRate
      }
      if (data.value.loadRate != res.data.result.loadRate) {
        countStart18.value = data.value.loadRate
      }
      if (data.value.storeEnergyRate != res.data.result.storeEnergyRate) {
        countStart19.value = data.value.storeEnergyRate
      }
      if (data.value.storeEnergyThatDayIncome != res.data.result.storeEnergyThatDayIncome) {
        countStart20.value = data.value.loadRate
      }
      if (data.value.storeEnergyList && data.value.storeEnergyList.length > 0) {
        if (JSON.stringify(data.value.storeEnergyList) != JSON.stringify(res.data.result.storeEnergyList)) {
          chartLoad1()
        }
      }
      if (data.value.photovoltaicGenerateElectricityList && data.value.photovoltaicGenerateElectricityList.length > 0) {
        if (JSON.stringify(data.value.photovoltaicGenerateElectricityList) != JSON.stringify(res.data.result.photovoltaicGenerateElectricityList)) {
          chartLoad2()
        }
      }
      if (data.value.totalUseList && data.value.totalUseList.length > 0) {
        if (JSON.stringify(data.value.totalUseList) != JSON.stringify(res.data.result.totalUseList)) {
          circleChartLoad()
        }
      }
      if (data.value.photovoltaicUpList && data.value.photovoltaicUpList.length > 0) {
        if (JSON.stringify(data.value.photovoltaicUpList) != JSON.stringify(res.data.result.photovoltaicUpList)) {
          chartLoad3()
        }
      }
      if (data.value.rateValueList && data.value.rateValueList.length > 0) {
        if (JSON.stringify(data.value.rateValueList) != JSON.stringify(res.data.result.rateValueList)) {
          chartLoad5()
        }
      }

      data.value = res.data.result
      //储能
      data.value.storeEnergyListX = []
      data.value.storeEnergyListY = []
      for (let i = 0; i < data.value.storeEnergyList.length; i++) {
        data.value.storeEnergyListX.push(data.value.storeEnergyList[i].key)
        data.value.storeEnergyListY.push(Number(data.value.storeEnergyList[i].value))
      }
      // 光伏发电量
      data.value.photovoltaicGenerateElectricityListX = []
      data.value.photovoltaicGenerateElectricityListY = []
      for (let i = 0; i < data.value.photovoltaicGenerateElectricityList.length; i++) {
        data.value.photovoltaicGenerateElectricityListX.push(data.value.photovoltaicGenerateElectricityList[i].key)
        data.value.photovoltaicGenerateElectricityListY.push(Number(data.value.photovoltaicGenerateElectricityList[i].value))
      }
      //总表
      data.value.totalUseListX = []
      data.value.totalUseListY = []
      for (let i = 0; i < data.value.totalUseList.length; i++) {
        data.value.totalUseListX.push(data.value.totalUseList[i].key)
        data.value.totalUseListY.push(Number(data.value.totalUseList[i].value))
      }
      // 光伏上网电量
      data.value.photovoltaicUpListX = []
      data.value.photovoltaicUpListY = []
      for (let i = 0; i < data.value.photovoltaicUpList.length; i++) {
        data.value.photovoltaicUpListX.push(data.value.photovoltaicUpList[i].key)
        data.value.photovoltaicUpListY.push(Number(data.value.photovoltaicUpList[i].value))
      }
      //近一小时功率曲线
      data.value.rateValueListY1 = []
      data.value.rateValueListY2 = []
      data.value.rateValueList = JSON.parse(JSON.stringify(res.data.result.rateValueList))
      for (let i = 0; i < data.value.rateValueList.length; i++) {
        if (data.value.rateValueList[i].key === '负载') {
          data.value.rateValueListY1 = data.value.rateValueList[i].value
        } else if (data.value.rateValueList[i].key === '储能') {
          data.value.rateValueListY2 = data.value.rateValueList[i].value
        }
      }
      let newLogList = []
      for (let i = 0; i < data.value.logList.length; i += 4) {
        newLogList.push(data.value.logList.slice(i, i + 4));
      }
      data.value.logList = newLogList
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
onMounted(async () => {
  await getDataFun()
  await equipmentList();
  updateTime()
  //天气
  currentWeatherFun()
  allWeatherFun()


  nextTick(() => {
    chartLoad1()
    chartLoad2()
    chartLoad3()
    chartLoad5()
    circleChartLoad()
  })


  // window.onresize = () => {
  //   let timer = setTimeout(() => {
  //     if (timer) {
  //       clearTimeout(timer)
  //     }
  //     let dom = document.querySelector('.hngfScreen')
  //     console.log(dom.clientWidth / 1920, dom.clientHeight / 1080)
  //     let dom2 = document.querySelector('.screen-wrapper')
  //     dom2.style.transform = `scale(${dom.clientWidth / 1920},${dom.clientHeight / 1080})`
  //     console.log(dom2.style)
  //   }, 500)
  // }
})
//大屏设备数据
const {arrow, circuitValue, List, equipmentList} = useCircuit(getFxlDeviceListApi)

// 定时器
const intervalId2 = setInterval(getDataFun, 10000)
const intervalId3 = setInterval(equipmentList, 60000)
const intervalId = setInterval(updateTime, 1000)
const intervalId4 = setInterval(currentWeatherFun, 1800000)
const intervalId5 = setInterval(allWeatherFun, 1800000)
onUnmounted(() => {
  clearInterval(intervalId)
  clearInterval(intervalId2)
  clearInterval(intervalId3)
  clearInterval(intervalId4)
  clearInterval(intervalId5)
})
</script>

<style lang="less" scoped>

</style>