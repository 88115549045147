import { api } from '@/api'

// 新增计费管理-固定计费项管理
export function addFixed(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/fixed/save',
    method: 'post',
    data: data,
  })
}

// 查询计费管理-固定计费项管理列表
export function getFixedList(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/fixed/list',
    method: 'get',
    params: query,
  })
}
