// useCircuit.js 提取复用函数
import {ref} from 'vue';

export default function useCircuit(getDeviceListApi) {
    //jtopo数据存储
    const asyncData = ref([]);
    const circuitValue = ref([]);
    //预览设备数据:
    const equipmentLists = ref({
        "photovoltaicEquipmentList": [{
            "key": "光伏设备1",
        },
            {
                "key": "光伏设备2",
            },
            {
                "key": "光伏设备3",
            },
            {
                "key": "光伏设备4",
            },
            {
                "key": "光伏设备5",
            }
        ],
        "storeEquipment": {
            "key": "储能设备",
        },
    })

    // 提取构建ports数组的逻辑到一个单独的函数
    function buildPorts(EquipmentList, storeEquipment, loadRate, totalRate) {
        let ports = [{
            name: '负载',
            img: 'fz2.svg',
            value: loadRate
        }];
        EquipmentList.forEach(item => {
            ports.push({
                name: item.key,
                value: item.value,
                img: item.key.includes('储能') ? 'core.png' : 'gf2.svg'//是否包含储能，包含储能则使用储能图片
            });
        });
        ports.splice(2, 0, {
            name: storeEquipment.key,
            value: storeEquipment.value,
            img: 'core.png'
        });
        ports.push({
            name: '电网',
            img: 'dw2.svg',
            value: totalRate
        });
        ports.forEach(item => {
            circuitValue.value.push({
                item
            });
        })
        return ports;
    }

    const List = ref({
        cn: [],
        gf: [],
        totalStoreRate: ''
    })
    const equipmentList = async () => {
        await getDeviceListApi().then(res => {
            if (res.data.code === 0) {
                // if (res.data.result.photovoltaicEquipmentList.length === 0) {
                //丰轩乐大屏
                let {
                    loadRate,
                    totalRate,
                    storeEquipmentList,
                    totalStoreRate
                } = res.data.result;
                //储能设备列表及数据
                List.value.cn = res.data.result.storeEquipmentList
                List.value.totalStoreRate = totalStoreRate
                List.value.gf = res.data.result.photovoltaicEquipmentList
                const fxlEquipmentLis = []
                storeEquipmentList.forEach((item, index) => {
                    if (index > 0) {
                        fxlEquipmentLis.push({
                            "key": storeEquipmentList[index].key,
                            "value": storeEquipmentList[index].value,
                        })
                    }
                })
                // 从第二项开始，将光伏替换为储能
                /* if (fxlEquipmentLis.length !== 0) {
                     fxlEquipmentLis.forEach((item, index) => {
                         equipmentLists.value.photovoltaicEquipmentList[index] = item
                     })
                 }*/
                storeEquipmentList = {
                    "key": storeEquipmentList[0].key,
                    "value": storeEquipmentList[0].value,
                }
                //清空数据显示数组
                circuitValue.value = []
                asyncData.value = buildPorts(equipmentLists.value.photovoltaicEquipmentList, storeEquipmentList, loadRate, totalRate);
            } else {
                // 处理API返回的错误情况
                console.error('Failed to fetch device list:', res.data.message);//默认是光伏背景颜色
            }
        })
    }
    // 返回需要暴露给组件的数据和方法
    return {
        circuitValue,
        List,
        // 如果需要的话，也可以暴露fetchAndProcessData方法供外部调用
        equipmentList
    };
}

