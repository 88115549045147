<template>
  <div class="gatewayDetail">
    <div class="title">
      <p>
        <span @click="goBack">{{router.currentRoute.value.query.flag=='true'?'微断网关':'网关管理'}}</span>
        <span> / </span>
        <span>网关管理</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        网关管理
      </p>
    </div>
    <div class="content">
      <div class="searchBox" style="height: 90px;">
        <div class="tableBox" style="padding: 0;">
          <el-table v-loading="DeviceLoading" :data="gatewayData" style="width: 100%">
            <template #empty>
              <el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
						</el-empty>
            </template>
            <el-table-column label="通讯地址" min-width="2%" property="address">
            </el-table-column>
            <el-table-column label="状态" min-width="2%" property="useStatusStr">
            </el-table-column>
            <el-table-column label="hei" min-width="2%" property="hei">
            </el-table-column>
            <el-table-column label="iccid" min-width="2%" property="iccid">
            </el-table-column>
            <el-table-column label="imsi" min-width="2%" property="imsi">
            </el-table-column>
            <el-table-column label="备注" min-width="2%" property="remark">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="searchBox" style="height: calc(100% - 194px );">
        <el-tabs v-model="tabId" class="demo-tabs" type="card" @tab-change="changeTab">
          <el-tab-pane v-for="item in tabList" :key="item.id" :label="item.name" :name="item.id">
          </el-tab-pane>
        </el-tabs>
        <div style="height: calc(100% - 56px );overflow: auto;">
          <GatewayDevice v-if="tabId===1" :type="gatewayType" :value="router.currentRoute.value.query.id"/>
          <Plan v-if="tabId===2" :type="gatewayType" :value="router.currentRoute.value.query.id"/>
          <Task v-if="tabId===3" :type="gatewayType" :value="router.currentRoute.value.query.id"/>
          <History v-if="tabId===4" :type="gatewayType" :value="router.currentRoute.value.query.id"/>
          <Offline v-if="tabId===5" :type="gatewayType" :value="router.currentRoute.value.query.id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus, Back} from '@element-plus/icons-vue'
import {getDetail} from '../../api/modules/gatewayDetail'
import './gatewayDetail.less'
import qs from 'qs';
import GatewayDevice from "@/pages/gateway/navCont/gatewayDevice";
import Plan from "@/pages/gateway/navCont/plan";
import Task from "@/pages/gateway/navCont/task";
import History from "@/pages/gateway/navCont/history";
import Offline from "@/components/comOffline";

let router = useRouter()
let tabId = ref(1)
let tabList = ref([{
  name: '设备',
  id: 1
}, {
  name: '方案',
  id: 2
},
  {
    name: '任务',
    id: 3
  },
  {
    name: '上报历史',
    id: 4
  },
  {
    name: '网关离线',
    id: 5
  }
])
const managementData = ref()
let DeviceLoading = ref(false)
let gatewayData = ref([])
let gatewayType = ref()
const getDetailFun = function () {
  DeviceLoading.value = true
  getDetail(qs.stringify({
    gatewayId: router.currentRoute.value.query.id
  })).then(res => {
    DeviceLoading.value = false
    //console.log(res);
    if (res.data.ok) {
      gatewayData.value = []
      gatewayData.value.push(res.data.result)
      gatewayType.value = res.data.result.protocolType
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 返回
const goBack = function () {
	if(router.currentRoute.value.query.flag=='true'){
		router.push({
			path: '/admin/microInterDevice',
			query: {
				pn: router.currentRoute.value.query.pn
			}
	})
	}else{
		router.push({
			path: '/admin/gateway',
			query: {
				pn: router.currentRoute.value.query.pn
			}
	})
	}
}
/*生命周期*/
onMounted(() => {
  getDetailFun()
})
</script>

<style>

</style>