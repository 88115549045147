import { api } from '@/api'

// 查询租户列表
export function listTenant(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/tenant/page',
    method: 'get',
    params: query,
  })
}

// 查询租户详细
export function getTenant(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/tenant/detail/' + id,
    method: 'get',
  })
}

// 新增租户
export function addTenant(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/tenant/save',
    method: 'post',
    data: data,
  })
}

// 修改租户
export function updateTenant(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/tenant/update',
    method: 'post',
    data: data,
  })
}

// 删除租户
export function delTenant(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/tenant/remove/' + id,
    method: 'post',
  })
}

// 获取项目设备列表
export function ListTenantEquipment(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/tenant/equipment',
    method: 'get',
    params: query,
  })
}

// 绑定设备
export function bindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/tenant/bind',
    method: 'post',
    data: data,
  })
}

// 解绑设备
export function unbindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/tenant/unbind',
    method: 'post',
    data: data,
  })
}

// 获取租户列表
export function getTenantList(landlordId) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: `/zouk/billing/tenant/list/${landlordId}`,
    method: 'get',
  })
}

// 获取租户设备列表
export function getTenantEquipmentList(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: `/zouk/billing/tenant/equipment`,
    method: 'get',
    params: query,
  })
}

// 转移租户电表
export function transferTenantMeter(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: `/zouk/billing/tenant/transferTenantMeter`,
    method: 'post',
    data: data,
  })
}

// 设置租户
export function setTenant(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: `/zouk/billing/tenant/set`,
    method: 'post',
    data: data,
  })
}
