<template>
	<!--大屏权限页面-->
	<div class="bigScreenAuthority">
		<div class="headerBox">
			大屏权限
		</div>
		<div class="contentBox">
			<!--左侧内容-->
			<div class="leftBox">
				<div class="searchBox">
					<el-input v-model="searchVal" clearable placeholder="请输入关键字" @change="searchClick(customerList,searchVal)">
						<template #prefix>
							<el-icon class="el-input__icon">
								<search/>
							</el-icon>
						</template>
					</el-input>
				</div>
				<div class="treeBox">
					<el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList" :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false" :props="defaultProps" node-key="id" @node-click="handleNodeClick">
						<template #default="{ node, data }">
							<span>{{ node.label }}</span>
						</template>
					</el-tree>
				</div>
				<!--				<div class="addBox">-->
				<!--					<el-button :icon="Plus" @click="organizationClick()">新建权限</el-button>-->
				<!--				</div>-->
			</div>
			<!--右侧内容-->
			<div class="rightBox">
				<div class="headerDiv">
					<span style="font-weight: bold;">{{ custerObj.name }}</span>
					<div class="userBox">
						<img alt="" src="../../../assets/img/133@2x.png" />
						<!--<el-icon :size="20">
              <User/>
            </el-icon>-->
						<label>{{ total }}</label>
					</div>
					<!--<div class="userBox">
            <el-icon :size="20">
              <OfficeBuilding />
            </el-icon>
            <label>136</label>
          </div>-->
					<div class="viewBox">
						<el-icon v-if="viewOpen" :size="16" @click="viewOpenClick">
							<View/>
						</el-icon>
						<el-icon v-if="!viewOpen" :size="16" @click="viewOpenClick">
							<Hide/>
						</el-icon>
					</div>
					<!--<el-button :disabled="custerObj.parentId==''?true:false" class="eidtButton" type="primary"
                     @click="organizationClick(custerObj)">编辑权限信息
          </el-button>-->
				</div>
				<div class="baseBox">
					<div class="imgBox">
						<el-avatar v-if="custerObj.logo" :size="60" :src="custerObj.logo" shape="square" />
					</div>
					<div class="baseDetail">

						<div class="detailSecond">
							<!--<div class="itemDetail">
                <p class="detailText">负责人</p>
                <span class="detailText" v-if="viewOpen" style="margin-right: 16px;">张三</span>
                <span v-if="viewOpen" style="color: #1890FF;cursor: pointer;" @click="editHead">编辑</span>
                <p class="detailText" v-if="!viewOpen">***********</p>
              </div>-->
							<div class="itemDetail">
								<p class="detailText">
									<img alt="" src="../../../assets/img/141@2x.png" />
									<span style="vertical-align: middle;">地址：</span>
									<span v-if="viewOpen" class='adressClass'>{{ custerObj.address ? custerObj.address : '-' }}</span>
									<span v-if="!viewOpen" style="vertical-align: middle;">***********</span>
								</p>
							</div>
							<div class="itemDetail">
								<p class="detailText">
									<img alt="" src="../../../assets/img/145@2x.png" />
									<span style="vertical-align: middle;">电话：</span>
									<span v-if="viewOpen">{{ custerObj.mobile ? custerObj.mobile : '-' }}</span>
									<span v-if="!viewOpen" class="detailText" style="vertical-align: middle;">***********</span>
								</p>
							</div>
							<!--<div class="itemDetail">
                <p class="detailText">权限类型</p>
                <p class="detailText" v-if="viewOpen">1111</p>
                <p class="detailText" v-if="!viewOpen">***********</p>
             </div>-->
						</div>
						<p class="detailText">
							<img alt="" src="../../../assets/img/201@2x.png" />
							<sapn style="vertical-align: middle;">创建时间：</sapn>
							<span v-if="viewOpen">{{ custerObj.createTime ? custerObj.createTime : '-' }}</span>
							<span v-if="!viewOpen" class="detailText" style="vertical-align: middle;">***********</span>
						</p>
					</div>

				</div>
				<div class="tableHeader">
					<!--<el-button style="width: 110px;height: 32px;" :icon="Plus" type="primary" @click="memberClick()">新建成员
          </el-button>-->
					<!--<el-button disabled>批量移除</el-button>-->
					<div class="seachBox">
						<el-input v-model="memberSearchValue" class="input-with-select" clearable placeholder="">
							<template #prepend>
								<el-select v-model="selectValue" placeholder="" style="width: 100px">
									<el-option :value="1" label="账号" />
									<el-option :value="2" label="名称" />
								</el-select>
							</template>
							<template #append>
								<el-button :icon="Search" @click="getMemberListFun" />
							</template>
						</el-input>
					</div>
				</div>
				<div class="tableBox">
					<el-table ref="multipleTableRef" v-loading='memberLoading' :data="memberList" style="width: 100%" @selection-change="handleSelectionChange">
						<template #empty>
							<el-empty class="emptyClass" description="暂无数据信息" image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
								<p>您可以在此页面新建成员信息</p>
							</el-empty>
						</template>
						<!--<el-table-column type="selection" width="40" />-->
						<el-table-column fixed label="名称" min-width="1%">
							<template #default="scope">{{ scope.row.name }}</template>
						</el-table-column>
						<el-table-column label="账号" min-width="1%" prop="account"></el-table-column>
						<el-table-column label="手机号" min-width="1%" prop="mobile"></el-table-column>
						<el-table-column label="角色" min-width="2%" prop="roleStr">
							<template #default="scope">
								<el-tooltip :content="scope.row.roleStr" placement="top">
									<span>{{ scope.row.roleStr }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<!--<el-table-column label="负责电站" min-width="2%">
              <template #default="scope">
                <el-popover placement="top-start" title="" trigger="hover">
                  <div class="liClass">
                    <p v-for="item in stationList">{{item}}</p>
                  </div>
                  <template #reference>
                    <span style="color: #1890FF;">{{ scope.row.name }}</span>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column property="name" label="电站查看权限" min-width="2%"></el-table-column>
            <el-table-column property="name" label="成员查看权限" min-width="2%"></el-table-column>-->
						<el-table-column fixed="right" label="操作" min-width="1%">
							<template #default="scope">
								<ul class="operationUl">
									<li class="editClass" @click="bindClick(scope.row)">
										绑定大屏
									</li>
								</ul>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="footerBox" v-show="memberList.length>0">
					<el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :total="total" background layout="total,sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</div>
		</div>
		<el-dialog v-model="dialogVisibleS" :close-on-click-modal="false" :width="dialogWidth" title="绑定大屏">
			<el-form ref="dataScreenFormRef" :model="dataScreenForm" :rules="dataScreenRules" class="demo-ruleForm" status-icon>
				<el-form-item label="大屏" prop="dataScreen">
					<el-select v-model="dataScreenForm.dataScreen" :max-collapse-tags="3" collapse-tags filterable multiple placeholder="请选择大屏" style="width: 100%">
						<el-option v-for="item in screenList" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button @click="dialogVisibleS = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="bindConfirm()">确认</el-button>
      			</span>
			</template>
		</el-dialog>
		<!--新建、编辑权限弹窗-->
		<el-drawer v-model="drawerOrganization" :show-close="false" direction="rtl" size="30%">
			<template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerOrganization=false">
						<CloseBold/>
					</el-icon>
				</span>
				<span class="tltleClass">{{ title }}</span>
				<div class="operBox">
					<el-button @click="drawerOrganization=false">取消</el-button>
					<el-button type="primary" @click="organizationSave">确认</el-button>
				</div>
			</template>
			<template #default>
				<div style="padding: 20px;">
					<el-form ref="organizationFormRef" :model="organizationForm" :rules="organizationRules" class="demo-ruleForm" status-icon label-width="auto">
						<el-form-item v-if="title=='新建权限'" label="父级权限" prop="parentOrganization">
							<el-input v-model="organizationForm.parentOrganization" disabled placeholder="请输入父级权限" />
						</el-form-item>
						<el-form-item label="权限名称" prop="organizationName">
							<el-input v-model="organizationForm.organizationName" maxlength="20" placeholder="请输入权限名称" show-word-limit/>
						</el-form-item>
						<!--            <p>20个字符内</p>-->
						<el-form-item label="权限地址" prop="organizationAdress">
							<el-input v-model="organizationForm.organizationAdress" maxlength="50" placeholder="请输入权限地址" show-word-limit/>
						</el-form-item>
						<!--            <p>50个字符内</p>-->
						<el-form-item label="电话号码" prop="organizationTel">
							<el-input v-model="organizationForm.organizationTel" class="input-with-select" type="text">
								<!--                <template #prepend>-->
								<!--                  <el-select v-model="mobileValue" placeholder="" style="width: 140px">-->
								<!--                    <el-option :value="1" label="中国(+86)"/>-->
								<!--                  </el-select>-->
								<!--                </template>-->
							</el-input>
						</el-form-item>
						<!--<el-form-item label="权限类型" prop="organizationType">
              <el-input v-model="organizationForm.organizationType" placeholder="请输入权限类型" />
              <p>不超过20个字符,不填写则为默认企业</p>
            </el-form-item>-->
						<el-form-item label="产品型号" prop="productIdList">
							<el-select v-model="organizationForm.productIdList" filterable multiple placeholder="请选择产品型号" style="width: 100%">
								<el-option-group v-for="group in productList" :key="group.id" :label="group.name">
									<el-option v-for="item in group.subList" :key="item.id" :label="item.name" :value="item.id" />
								</el-option-group>
							</el-select>
						</el-form-item>
						<el-form-item label="权限图片" prop="organizationImg">
							<el-upload :auto-upload="false" :class="{hideUpload:hideUploadEdit}" :file-list='organizationForm.organizationImg' :limit="1" accept=".png,.jpg" action="#" list-type="picture-card" @change="uploadFile">
								<el-icon>
									<Plus/>
								</el-icon>
								<p>上传图片</p>
								<template #file="{ file }">
									<div>
										<img :src="file.url" alt="" class="el-upload-list__item-thumbnail" />
										<span class="el-upload-list__item-actions">
										  <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                      >
										    <el-icon><ZoomIn/></el-icon>
										  </span>
										<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            							  <el-icon><Delete/></el-icon>
          								</span>
										</span>
									</div>
								</template>
							</el-upload>
						</el-form-item>
						<p style="margin-bottom: 20px;">支持jpg、png格式</p>
						<!--            <el-form-item label="权限项目" prop="projectIdList">-->
						<!--              <el-tree ref="treeRef2"-->
						<!--                       :data="customerList2" :default-expand-all="true"-->
						<!--                       :expand-on-click-node="false"-->
						<!--                       :props="{label: 'name',children: 'subList',}" node-key="id"-->
						<!--                       show-checkbox-->
						<!--                       @check-change="handleCheckChange">-->
						<!--              </el-tree>-->
						<!--            </el-form-item>-->
					</el-form>
				</div>
			</template>
		</el-drawer>
		<el-dialog v-model="dialogVisible" title="查看大图">
			<img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
		</el-dialog>
		<!--新建、编辑成员弹窗-->
		<el-drawer v-model="drawerMember" :show-close="false" direction="rtl" size="30%">
			<template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerMember=false">
						<CloseBold/>
					</el-icon>
				</span>
				<span class="tltleClass">{{ title }}</span>
				<div class="operBox">
					<el-button @click="drawerMember=false">取消</el-button>
					<el-button type="primary" @click="memberSave">确认</el-button>
				</div>
			</template>
			<template #default>
				<div style="padding: 20px;">
					<el-form ref="memberFormRef" :model="memberForm" :rules="memberRules" class="demo-ruleForm" status-icon>
						<el-form-item label="父级权限" prop="parentOrganization">
							<el-input v-model="memberForm.parentOrganization" disabled placeholder="请输入父级权限" />
						</el-form-item>
						<el-form-item v-if="title!=='修改密码'" label="成员名称" prop="memberName">
							<el-input v-model="memberForm.memberName" placeholder="请输入成员名称" />
						</el-form-item>
						<el-form-item v-if="title!=='修改密码'" label="所属角色" prop="memberRole">
							<el-select v-model="memberForm.memberRole" :max-collapse-tags="3" collapse-tags filterable multiple placeholder="请选择所属角色" style="width: 100%">
								<el-option v-for="item in rolelist" :key="item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
						<!--<el-form-item label="电站查看权限" prop="powerStationPower">
              <el-radio-group v-model="memberForm.powerStationPower" class="ml-4">
                <el-radio label="1" size="large">本人负责的电站</el-radio>
                <el-radio label="2" size="large">所在权限及本人负责</el-radio>
                <el-radio label="3" size="large">所在权限及下级权限和本人负责的所有电站</el-radio>
                <el-radio label="4" size="large">跨区下的所有电站</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="成员查看权限" prop="memberPower">
              <el-radio-group v-model="memberForm.memberPower" class="ml-4">
                <el-radio label="1" size="large">所在权限中的所有成员</el-radio>
                <el-radio label="2" size="large">所在权限及下级权限中的所有成员</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="账号类型" prop="accountType">
              <el-radio-group v-model="memberForm.accountType">
                <el-radio-button label="1">电话号码</el-radio-button>
                <el-radio-button label="2">邮箱</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="电话号码" prop="memberTel" v-if="memberForm.accountType==1">
              <el-input v-model="memberForm.memberTel" placeholder="请输入手机号码" />
            </el-form-item>
            <el-form-item label="邮箱" prop="email" v-if="memberForm.accountType==2">
              <el-input v-model="memberForm.email" placeholder="请输入邮箱" />
            </el-form-item>-->
						<el-form-item v-if="title!=='修改密码'" label="账号" prop="account">
							<el-input v-model="memberForm.account" placeholder="请输入账号" />
						</el-form-item>
						<el-form-item v-if="title!=='修改密码'" label="手机号" prop="memberTel">
							<el-input v-model="memberForm.memberTel" placeholder="请输入手机号码" />
						</el-form-item>

						<el-form-item v-if="title!='编辑用户'" label="登入密码" prop="password">
							<el-input v-model="memberForm.password" placeholder="请输入登入密码" show-password type="password" />
						</el-form-item>
						<p v-if="title!='编辑用户'" style="color: rgba(0, 0, 0, 0.45);padding-top: 10px;margin-bottom: 20px;">密码长度 8-20 位，至少包含以下四种字符类型（数字、大写字母、小写字母、英文符号）
						</p>
						<el-form-item v-if="title!='编辑用户'" label="确认密码" prop="confirmPassword">
							<el-input v-model="memberForm.confirmPassword" placeholder="请输入确认密码" show-password type="password" />
						</el-form-item>
						<el-form-item label="用户项目" prop="projectIdList">
							<el-tree ref="treeRef2" :data="customerList2" :default-expand-all="true" :expand-on-click-node="false" :props="{label: 'name',children: 'subList',}" node-key="id" show-checkbox @check-change="handleCheckChange">
							</el-tree>
						</el-form-item>
					</el-form>
				</div>
			</template>
		</el-drawer>
		<!--暂时不用---------------------------------------------->
		<!--编辑负责人弹窗-->
		<el-drawer v-model="drawerHead" :show-close="false" direction="rtl" size="46%">
			<template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerHead=false">
						<CloseBold/>
					</el-icon>
				</span>
				<span class="tltleClass">编辑负责人</span>
				<div class="operBox">
					<el-button @click="drawerHead=false">取消</el-button>
					<el-button type="primary" @click="drawerHead=false">确认</el-button>
				</div>
			</template>
			<template #default>
				<div class="searchDrawerBox">
					<el-input v-model="input3" class="input-with-select" placeholder="">
						<template #prepend>
							<el-select v-model="selectValue" placeholder="" style="width: 100px">
								<el-option :value="1" label="账号" />
								<el-option :value="2" label="名称" />
							</el-select>
						</template>
						<template #append>
							<el-button :icon="Search" />
						</template>
					</el-input>
				</div>
				<div class="tableBox">
					<el-table ref="multipleTableRef" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="40" />
						<el-table-column label="名称" min-width="1%">
							<template #default="scope">{{ scope.row.name }}</template>
						</el-table-column>
						<el-table-column label="账号" min-width="1%" property="name"></el-table-column>
						<el-table-column label="角色" min-width="1%" property="name">
							<template #default="scope">
								<el-tooltip :content="scope.row.name" placement="top">
									<span>{{ scope.row.name }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column label="负责电站" min-width="2%">
							<template #default="scope">
								<el-popover placement="top-start" title="" trigger="hover">
									<div class="liClass">
										<p v-for="item in stationList">{{ item }}</p>
									</div>
									<template #reference>
										<span style="color: #1890FF;">{{ scope.row.name }}</span>
									</template>
								</el-popover>
							</template>
						</el-table-column>
						<el-table-column label="电站查看权限" min-width="2%" property="name"></el-table-column>
						<el-table-column label="成员查看权限" min-width="2%" property="name"></el-table-column>
					</el-table>
				</div>
				<div class="footerBox">
					<el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4" :disabled="disabled" :page-sizes="[100, 200, 300, 400]" :total="400" background layout="total, prev, pager, next, sizes" small @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</template>
		</el-drawer>
		<!--修改密码弹窗-->
		<el-drawer v-model="drawerPassword" :show-close="false" direction="rtl" size="30%">
			<template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerPassword=false">
						<CloseBold/>
					</el-icon>
				</span>
				<span class="tltleClass">修改密码</span>
				<div class="operBox">
					<el-button @click="drawerPassword=false">取消</el-button>
					<el-button type="primary" @click="passwordSave">确认</el-button>
				</div>
			</template>
			<template #default>
				<div style="padding: 20px;">
					<el-form ref="passwordFormRef" :model="passwordForm" :rules="passwordRules" class="demo-ruleForm" status-icon>
						<el-form-item label="父级权限" prop="parentorganization">
							<el-input v-model="passwordForm.parentorganization" placeholder="请输入父级权限" />
						</el-form-item>
						<el-form-item label="账号类型" prop="account">
							<el-radio-group v-model="passwordForm.account">
								<el-radio-button label="1">电话号码</el-radio-button>
								<el-radio-button label="2">邮箱</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item v-if="passwordForm.account==1" label="电话号码" prop="tel">
							<el-input v-model="passwordForm.tel" placeholder="请输入电话号码" />
						</el-form-item>
						<el-form-item v-if="passwordForm.account==2" label="邮箱" prop="email">
							<el-input v-model="passwordForm.email" placeholder="请输入邮箱" />
						</el-form-item>
						<el-form-item label="登入密码" prop="password">
							<el-input v-model="passwordForm.password" placeholder="请输入登入密码" show-password type="password" />
						</el-form-item>
						<p style="color: rgba(0, 0, 0, 0.45);padding-top: 10px;">密码长度 8-20 位，至少包含以下四种字符类型（数字、大写字母、小写字母、英文符号）
						</p>
					</el-form>
				</div>
			</template>
		</el-drawer>
	</div>
</template>

<script setup>
	import { reactive, onMounted, computed, watch, ref, nextTick, h } from 'vue';
	import { useRouter, useRoute } from 'vue-router'
	import { ElTable, ElMessage, ElMessageBox } from 'element-plus'
	import {
		Search,
		Delete,
		Edit,
		CirclePlus,
		Plus,
		User,
		OfficeBuilding,
		Hide,
		View,
		CloseBold,
		ZoomIn
	} from '@element-plus/icons-vue'
	import {
		getList,
		saveList,
		deleteList,
		uploadImg,
		roleList,
		addMemberList,
		getMemberList,
		editMemberList,
		memberDeleteList,
		updatePassword,
		getAllCategory,
		getProductList,
		getProductApi,
		getTreeDetailApi,
		getScreenList,
		updateDataScreen
	} from '../../../api/modules/tissue'
	import './bigScreenAuthority.less'
	import qs from 'qs';
	import { getProjectListApi } from "@/api/modules/project";

	/*路由*/
	const router = useRouter()
	const route = useRoute() //需要将useRoute函数执行放在顶部
	let userId = route.query.userId; /*获取query''*/
	/*生命周期*/
	onMounted(() => {
		getListFun()
		getRoleListFun()
		getProductListFun() //获取产品列表
		getProjectListFun2() //获取项目树
		getScreenListFun() //数据大屏列表
	})
	//默认树级属性
	let defaultProps = {
		label: 'name',
		children: 'subList',
	}
	let customerList = ref([]) //权限树级列表
	let searchVal = ref() //树级列表字段
	//树级列表搜索
	const searchClick = function(data, name) {
		defaultExpandKeys.value = []
		let res = tree(data, name)
		if(res) {
			//currentNodeKey.value = res.id
			treeRef.value.setCurrentKey(res.id)
			defaultExpandKeys.value.push(res.parentId)
			custerObj.value = res
			getMemberListFun()
			if(res.logo) {
				organizationForm.value.organizationImg.push({
					name: 'aaa',
					url: res.logo
				})
			} else {
				organizationForm.value.organizationImg = []
			}
		}
	}
	const tree = function(data, name) {
		for(let i = 0; i < data.length; i++) {
			let item = data[i];
			if(item.name === name) {
				return item;
			} else {
				if(item.subList && item.subList.length > 0) {
					let res = tree(item.subList, name);
					if(res) return res;
				}
			}
		}
	}
	let custerObj = ref({}) //当前权限信息
	let currentNodeKey = ref() //默认选中树级列表第一级
	let treeRef = ref(null)
	//获取权限树级列表
	const getListFun = async function() {
		await getList(null).then(res => {
			//console.log(res);
			if(res.data.ok) {
				customerList.value = []
				organizationForm.value.organizationImg = []
				customerList.value.push(res.data.result)
				defaultExpandKeys.value = [customerList.value[0].id]
				if(currentNodeKey.value === undefined) { //第一次加默认选中
					currentNodeKey.value = customerList.value[0].id
					custerObj.value = customerList.value[0]
					organizationForm.value.parentOrganization = customerList.value[0].name
					memberForm.value.parentOrganization = customerList.value[0].name
					if(customerList.value.length > 0 && customerList.value[0].logo) {
						organizationForm.value.organizationImg.push({
							name: 'aaa',
							url: customerList.value[0].logo
						})
					}
				}
				getMemberListFun()
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let mobileValue = ref(1)
	//树级列表鼠标经过
	const mouseenter = function(data) {
		data.del = true
	}
	//树级列表鼠标离开
	const mouseleave = function(data) {
		data.del = false
	}
	let defaultExpandKeys = ref([]) //树的默认展开
	//树级的点击
	const handleNodeClick = function(e) {
		custerObj.value = e
		defaultExpandKeys.value = []
		organizationForm.value.organizationImg = []
		defaultExpandKeys.value.push(e.parentId)
		organizationForm.value.parentOrganization = e.name
		memberForm.value.parentOrganization = e.name
		if(title.value == '编辑权限' && e.logo) {
			organizationForm.value.organizationImg.push({
				name: 'aaa',
				url: e.logo
			})
		}
		getMemberListFun()
	}
	/*权限*********************************************/
	let drawerOrganization = ref(false) //权限弹窗开关
	let title = ref('')
	// 权限表单元素
	let organizationFormRef = ref()
	//新增/编辑权限的点击
	let organizationClick = function(val) {
		organizationForm.value = {}
		if(val) {
			organizationForm.value.organizationImg = []
			title.value = '编辑权限'
			organizationForm.value.organizationName = val.name
			organizationForm.value.organizationTel = val.mobile
			organizationForm.value.organizationAdress = val.address

			if(val.logo) {
				hideUploadEdit.value = true
				organizationForm.value.organizationImg.push({
					name: 'aaa',
					url: val.logo
				})
			} else {
				hideUploadEdit.value = false
			}
			getProductApi(qs.stringify({
				id: val.id
			})).then(res => {
				if(res.data.code === 0) {
					nextTick(() => {
						organizationForm.value.productIdList = res.data.result.productIdList
					})
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			hideUploadEdit.value = false
			title.value = '新建权限'
			organizationForm.value.parentOrganization = custerObj.value.name
			organizationForm.value.organizationImg = []
			organizationForm.value.productIdList = []
			// nextTick(() => {
			//   treeRef2.value.setCheckedKeys([])
			// })
		}
		drawerOrganization.value = true
	}
	//权限表单
	let organizationForm = ref({
		parentOrganization: '',
		organizationName: '',
		organizationAdress: '',
		organizationTel: '',
		organizationType: '',
		productIdList: [],
		projectIdList: [],
		organizationImg: []
	})
	// 正确的手机号正则
	let telVal = /^[1][3,4,5,7,8][0-9]{9}$/;
	let telValidator = (rule, value, callback) => {
		if(value === '') {
			callback(new Error('请输入手机号码'))
		} else if(!telVal.test(value)) {
			callback(new Error('请输入有效的电话号码'))
		} else {
			callback()
		}
	}
	// 权限表单验证规则
	let organizationRules = ref({
		parentOrganization: [{
			required: true,
			message: '请输入父级权限',
			trigger: 'blur'
		}, ],
		organizationName: [{
			required: true,
			message: '请输入权限名称',
			trigger: 'blur'
		}, ],
		organizationAdress: [{
			required: true,
			message: '请输入权限地址',
			trigger: 'blur'
		}, ],
		organizationTel: [{
			required: true,
			trigger: 'blur',
			validator: telValidator,
		}]
	})
	/*
	 * 上传图片
	 * */
	let dialogVisible = ref(false) //大图弹窗显示开关
	let dialogImageUrl = ref() //大图列表
	let hideUploadEdit = ref(false) //图片上传显示开关
	//上传文件
	const uploadFile = function(file) {
		if((file.size / 1024 / 1024) > 5) {
			ElMessage({
				message: '图片大小不能超过5M!',
				type: 'info'
			})
			organizationForm.value.organizationImg = []
		} else {
			let fd = new FormData();
			//注意是文件流file.raw
			fd.append('file', file.raw); //传文件
			uploadImg(
				fd
			).then(res => {
				//console.log(res);
				if(res.data.ok) {
					hideUploadEdit.value = true
					organizationForm.value.organizationImg = []
					organizationForm.value.organizationImg.push({
						name: 'aaa',
						url: res.data.result.fullPath
					})
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}
	}
	//删除图片
	const handleRemove = (file) => {
		organizationForm.value.organizationImg = []
		hideUploadEdit.value = false
	}
	//查看大图
	const handlePictureCardPreview = (file) => {
		dialogImageUrl.value = file.url
		dialogVisible.value = true
	}
	// 权限表单提交
	const organizationSave = function() {
		let logo;
		if(organizationForm.value.organizationImg && organizationForm.value.organizationImg.length > 0) {
			logo = organizationForm.value.organizationImg[0].url
		} else {
			logo = ''
		}
		var params = {
			name: organizationForm.value.organizationName,
			address: organizationForm.value.organizationAdress,
			mobile: organizationForm.value.organizationTel ? organizationForm.value.organizationTel : '',
			logo: logo,
			productIdList: organizationForm.value.productIdList.join(),
			// projectIdList: treeRef2.value.getCheckedKeys().join()
		}
		if(organizationFormRef) {
			organizationFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					if(title.value == '新建权限') {
						params['id'] = 0
						params['parentId'] = custerObj.value.id
					} else if(title.value == '编辑权限') {
						params['id'] = custerObj.value.id
						params['parentId'] = custerObj.value.parentId
					}
					saveList(
						qs.stringify(params)
					).then(async res => {
						//console.log(res);
						if(res.data.ok) {
							await getListFun() //有赋值顺序问题需要异步
							custerObj.value.name = res.data.result.name
							custerObj.value.address = res.data.result.address
							custerObj.value.id = res.data.result.id
							custerObj.value.logo = res.data.result.logo
							custerObj.value.mobile = res.data.result.mobile
							custerObj.value.parentId = res.data.result.parentId
							treeRef.value.setCurrentKey(res.data.result.id)
							//currentNodeKey.value = res.data.result.id
							drawerOrganization.value = false
						} else {
							ElMessage({
								message: res.data.message,
								type: 'error'
							})
						}
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}
	}
	//权限的删除
	const organizationDelete = function(data) {
		var params = {
			id: Number(data.id)
		}
		ElMessageBox.confirm("权限删除之后不可恢复，确认要删除吗", '操作确认', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			// type: 'warning',
			title: '删除权限',
			message: h('p', null, [
				h('img', {
					src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
					style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
				}, ''),
				h('span', {
					style: 'color:#595959;'
				}, '权限删除之后不可恢复，确认要删除吗'),
			])
		}).then(() => {
			deleteList(
				qs.stringify(params)
			).then(async res => {
				//console.log(res);
				if(res.data.ok) {
					currentNodeKey.value = undefined
					await getListFun()
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消删除',
				type: 'info',
			})
		});
	}
	//可视开关
	let viewOpen = ref(false)
	//可视点击
	const viewOpenClick = function() {
		viewOpen.value = !viewOpen.value
	} /*成员*********************************************/
	let drawerMember = ref(false) //成员弹窗开关
	// 成员表单元素
	let memberFormRef = ref()
	//成员表单
	let memberForm = ref({
		parentOrganization: '',
		memberName: '',
		memberRole: '',
		powerStationPower: '1',
		memberPower: '1',
		accountType: 1,
		memberTel: '',
		email: '',
		account: '',
		password: '',
		confirmPassword: ''
	})
	let rolelist = ref([]) //角色列表
	//获取角色列表
	const getRoleListFun = function() {
		roleList(null).then(res => {
			//console.log(res);
			if(res.data.ok) {
				rolelist.value = res.data.result
				rolelist.value.forEach(item => {
					item.id = Number(item.id)
				})
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//密码验证规则
	// 密码校验 长度不能小于8位且不能大于20位字符,必须包含大写字母、小写字母、数字和特殊符号
	let ISPWD = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,\._\+(){}])[0-9a-zA-Z!@#$%^&*,\\._\+(){}]{8,20}$/;
	let passwordValidator = (rule, value, callback) => {
		if(value === '') {
			callback(new Error('请输入登入密码'))
		} else if(!ISPWD.test(value)) {
			callback(new Error('密码长度 8-20 位，至少包含以下四种字符类型（数字、大写字母、小写字母、英文符号）'))
		} else {
			callback()
		}
	}
	// 正确的手机号正则
	let telVal2 = /^[1][3,4,5,7,8][0-9]{9}$/;
	let telValidator2 = (rule, value, callback) => {
		if(value === '') {
			callback(new Error('请输入手机号码'))
		} else if(!telVal2.test(value)) {
			callback(new Error('请输入有效的电话号码'))
		} else {
			callback()
		}
	}
	// 确认密码表单验证规则
	let confirmPasswordValidator = (rule, value, callback) => {
		if(value === '') {
			callback(new Error('请再次输入密码'))
		} else if(value !== memberForm.value.password) {
			callback(new Error('两次输入密码不一致!'))
		} else {
			callback()
		}
	}
	// 成员表单验证规则
	let memberRules = ref({
		memberName: [{
			required: true,
			message: '请输入成员名称',
			trigger: 'blur'
		}, ],
		memberRole: [{
			required: true,
			message: '请选择所属角色',
			trigger: 'blur'
		}, ],
		powerStationPower: [{
			required: true,
			message: '请选择电站查看权限',
			trigger: 'blur'
		}, ],
		memberPower: [{
			required: true,
			message: '请选择电站查看权限',
			trigger: 'blur'
		}, ],
		accountType: [{
			required: true,
			message: '请选择账户类型',
			trigger: 'blur'
		}, ],
		memberTel: [{
			required: true,
			trigger: 'blur',
			validator: telValidator2
		}, ],
		email: [{
			required: true,
			message: '请输入邮箱',
			trigger: 'blur'
		}, ],
		account: [{
			required: true,
			message: '请输入账号',
			trigger: 'blur'
		}, ],
		password: [{
			required: true,
			trigger: 'blur',
			validator: passwordValidator
		}, ],
		confirmPassword: [{
			required: true,
			trigger: 'blur',
			validator: confirmPasswordValidator
		}, ]
	})
	//成员列表
	let memberList = ref([]) //成员列表
	let pageNumber = ref(1) //页码
	let pageSize = ref(20) //页数
	let total = ref(0) //总页数
	let memberSearchValue = ref() //账号/名称的搜索
	let selectValue = ref(1) //选中字段
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getMemberListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageNumber.value = val;
		getMemberListFun();
	}
	let memberLoading = ref(false) //用户表格加载
	//获取成员列表
	const getMemberListFun = function() {
		memberLoading.value = true
		let params = {
			pageNumber: pageNumber.value,
			pageSize: pageSize.value,
			customerId: custerObj.value.id,
			account: selectValue.value == 1 ? memberSearchValue.value : null,
			name: selectValue.value == 2 ? memberSearchValue.value : null
		}
		getMemberList(qs.stringify(params)).then(res => {
			//console.log(res);
			memberLoading.value = false
			if(res.data.ok) {
				memberList.value = res.data.result.list
				total.value = Number(res.data.result.total)
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let memberEditId = ref() //成员id
	//新增/编辑成员的点击
	let memberClick = function(val, val2) {
		memberForm.value.memberName = ''
		memberForm.value.account = ''
		memberForm.value.memberTel = ''
		memberForm.value.memberRole = []
		memberForm.value.password = ''
		if(val) {
			memberForm.value.memberName = val.name
			memberForm.value.account = val.account
			memberForm.value.memberTel = val.mobile
			memberEditId.value = val.id
			memberForm.value.memberRole = []
			if(val.roleList && val.roleList.length > 0) {
				val.roleList.forEach(item => {
					memberForm.value.memberRole.push(Number(item.id))
				})
			}
			if(val2) {
				title.value = '修改密码'
			} else {
				title.value = '编辑用户'
			}
			getTreeDetailApi(qs.stringify({
				id: val.id
			})).then(res => {
				if(res.data.code === 0) {
					nextTick(() => {
						treeRef2.value.setCheckedKeys(res.data.result.projectIdList)
					})
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			memberEditId.value = ''
			title.value = '新建成员'
			nextTick(() => {
				treeRef2.value.setCheckedKeys([])
			})
		}
		drawerMember.value = true
	}
	// 成员表单提交
	const memberSave = function() {
		let allProjectIdList = []
		for(let i = 0; i < treeRef2.value.getCheckedNodes(false, true).length; i++) {
			allProjectIdList.push(treeRef2.value.getCheckedNodes(false, true)[i].id)
		}
		let params = {
			name: memberForm.value.memberName,
			roleJsonStr: JSON.stringify(memberForm.value.memberRole),
			account: memberForm.value.account,
			mobile: memberForm.value.memberTel,
			projectIdList: treeRef2.value.getCheckedKeys().join(),
			allProjectIdList: allProjectIdList.join()
		}
		if(memberFormRef) {
			memberFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					params['customerId'] = custerObj.value.id
					params['password'] = memberForm.value.password
					if(title.value == '新建成员') { //新增
						addMemberList(
							qs.stringify(params)
						).then(res => {
							//console.log(res);
							if(res.data.ok) {
								getMemberListFun()
								drawerMember.value = false
							} else {
								ElMessage({
									message: res.data.message,
									type: 'error'
								})
							}
						}).catch(err => {
							console.log(err);
						})
					} else if(title.value == '编辑用户') { //编辑
						params['id'] = memberEditId.value
						editMemberList(
							qs.stringify(params)
						).then(res => {
							//console.log(res);
							if(res.data.ok) {
								getMemberListFun()
								drawerMember.value = false
							} else {
								ElMessage({
									message: res.data.message,
									type: 'error'
								})
							}
						}).catch(err => {
							console.log(err);
						})
					} else if(title.value == '修改密码') {
						params['password'] = memberForm.value.password
						params['id'] = memberEditId.value
						updatePassword(
							qs.stringify(params)
						).then(res => {
							//console.log(res);
							if(res.data.ok) {
								getMemberListFun()
								drawerMember.value = false
							} else {
								ElMessage({
									message: res.data.message,
									type: 'error'
								})
							}
						}).catch(err => {
							console.log(err);
						})
					}
				}
			})
		}
	}
	//移除
	const deleteClick = function(id) {
		ElMessageBox.confirm("确认移除当前用户吗，删除后不可恢复，请谨慎操作", '', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			title: '删除成员',
			message: h('p', null, [
				h('img', {
					src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
					style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
				}, ''),
				h('span', {
					style: 'color:#595959;'
				}, '确认移除当前用户吗，删除后不可恢复，请谨慎操作'),
			])
		}).then(() => {
			memberDeleteList(
				qs.stringify({
					id: id
				})
			).then(res => {
				//console.log(res);
				if(res.data.ok) {
					getMemberListFun()
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消移除',
				type: 'info',
			})
		});
	}
	/*暂时不用********************************************/
	//表格列表
	const tableData = [{
			date: '2016-05-03',
			name: 'Tom,werwet',
			address: 'No. 189, Grove St, Los Angeles',
		},
		{
			date: '2016-05-02',
			name: 'Tom',
			address: 'No. 189, Grove St, Los Angeles',
		},
		{
			date: '2016-05-04',
			name: 'Tom',
			address: 'No. 189, Grove St, Los Angeles',
		},
		{
			date: '2016-05-01',
			name: 'Tom',
			address: 'No. 189, Grove St, Los Angeles',
		},
		{
			date: '2016-05-08',
			name: 'Tom',
			address: 'No. 189, Grove St, Los Angeles',
		},
		{
			date: '2016-05-06',
			name: 'Tom',
			address: 'No. 189, Grove St, Los Angeles',
		},
		{
			date: '2016-05-07',
			name: 'Tom',
			address: 'No. 189, Grove St, Los Angeles',
		},
	]
	//负责电站列表
	let stationList = ref([
		'1111',
		'2222355555eer55555',
		'333',
		'444',
		'1111',
		'1111',
		'1111',
		'1111',
		'1111',
		'222',
		'333',
		'444',
		'1111',
		'1111',
		'1111',
		'1111',
	])
	/*负责人*********************************************/
	let drawerHead = ref(false) //编辑负责人弹窗开关
	//编辑负责人的点击
	let editHead = function() {
		drawerHead.value = true
	}
	/*密码*********************************************/
	let drawerPassword = ref(false) //修改密码弹窗开关
	// 密码表单元素
	let passwordFormRef = ref()
	//修改密码
	let editPasswordClick = function() {
		drawerPassword.value = true
	}
	//修改密码表单
	let passwordForm = ref({
		parentorganization: '',
		account: 1,
		tel: '',
		email: '',
		password: ''
	})
	// 修改密码单验证规则
	let passwordRules = ref({
		account: [{
			required: true,
			message: '请输入账户类型',
			trigger: 'blur'
		}, ],
		tel: [{
			required: true,
			message: '请输入电话号码',
			trigger: 'blur'
		}, ],
		email: [{
			required: true,
			message: '请输入邮箱',
			trigger: 'blur'
		}, ],
		password: [{
			required: true,
			trigger: 'blur',
			validator: passwordValidator
		}, ],
	})
	// 找回密码表单提交
	const passwordSave = function() {
		if(passwordFormRef) {
			passwordFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					drawerPassword.value = false
				}
			})
		}
	}

	// 产品******************************************************************************************
	let productList = ref([]) //产品列表
	//产品列表
	const getProductListFun = function() {
		getProductList().then(res => {
			if(res.data.code === 0) {
				productList.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}

	// 项目树******************************************************************************************
	let customerList2 = ref([]) //权限树级列表
	let treeRef2 = ref(null)
	//获取项目树级列表
	const getProjectListFun2 = async function() {
		await getProjectListApi().then(res => {
			if(res.data.ok) {
				customerList2.value = []
				customerList2.value.push(res.data.result)
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	/*绑定大屏*/
	// 绑定大屏表单元素
	let dataScreenFormRef = ref()
	//绑定大屏表单
	let dataScreenForm = ref({
		dataScreen: []
	})
	// 绑定大屏表单验证规则
	let dataScreenRules = ref({
		dataScreen: [{
			required: true,
			message: '请选择大屏',
			trigger: 'blur'
		}, ]
	})
	//大屏列表
	const getScreenListFun = function() {
		getScreenList(null).then(res => {
			//console.log(res);
			if(res.data.ok) {
				screenList.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let dialogVisibleS = ref(false) //绑定大屏弹窗
	let screenList = ref([]) //大屏列表
	let memberId = ref('')
	//绑定的点击
	const bindClick = function(val) {
		dialogVisibleS.value = true
		dataScreenForm.value.dataScreen = []
		memberId.value = val.id
		if(val.dataScreenIdList && val.dataScreenIdList.length > 0) {
			val.dataScreenIdList.forEach(item => {
				dataScreenForm.value.dataScreen.push(item)
			})
		}
	}
	//绑定的确认
	const bindConfirm = function() {
		let params = {
			dataScreenIdList: dataScreenForm.value.dataScreen.join(),
			memberId: memberId.value
		}
		dataScreenFormRef.value.validate((valid) => {
			//提交成功
			if(valid) {
				dialogVisibleS.value = false
				updateDataScreen(
					qs.stringify(params)
				).then(res => {
					//console.log(res);
					if(res.data.ok) {
						getMemberListFun()
						dialogVisibleS.value = false
					} else {
						ElMessage({
							message: res.data.message,
							type: 'error'
						})
					}
				}).catch(err => {
					console.log(err);
				})
			}
		})
	}
</script>
<style lang="less" scoped>
	/*el-tooltip的样式不能下载scoped*/
	
	.el-popper.is-customized {
		padding: 4px !important;
		background: rgba(0, 0, 0, .8);
		color: #fff !important;
	}
	
	.el-popper.is-customized .el-popper__arrow::before {
		background: rgba(0, 0, 0, .8);
	}
	
	//分页列表
	/*:deep(.is-first) {
  margin-right: 19px !important;
}*/
</style>