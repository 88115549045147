<template>
  <div class="deviceManagement">
    <div class="title">
      <p>
        <span v-if="router.currentRoute.value.query.projectId" @click="goBack">项目管理 </span>
        <span v-if="router.currentRoute.value.query.ty==1" @click="goBack">设备管理</span>
        <span v-if="router.currentRoute.value.query.ty==2" @click="goBack">微断设备</span>
        <span> / </span>
        <span>管理</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        管理
      </p>
    </div>
    <div class="content">
      <!--上部分信息-->
      <div class="topBox">
        <div class="itemBox">
          <el-carousel v-if="infoData.productImageList2&&infoData.productImageList2.length>0" height="240px"
                       type="none">
            <el-carousel-item v-for="(item,index) in infoData.productImageList2" :key="index">
              <img :src="item.url" alt="" @click="handlePictureCardPreview(item)">
            </el-carousel-item>
          </el-carousel>
          <div v-else class="demo-image__error">
            <el-image>
              <template #error>
                <div class="image-slot">
                  暂无图片
                </div>
              </template>
            </el-image>
          </div>
        </div>
        <div class="itemBox" style="display: flex;">
          <div style="flex:4">
            <img v-if="!status" alt="" src="../../assets/img/no_wifi.svg"/>
            <img v-if="status" alt="" src="../../assets/img/wifi.svg"/>
            <!--<el-switch v-model="status" disabled/>-->
            <p class="codeClass">{{ infoData.name }}</p>
            <p>{{ infoData.projectName }}/{{ infoData.name }}</p>
            <p>产品类型：<span>{{ infoData.categoryName }}</span></p>
            <p>产品型号：<span>{{ infoData.productModel }}</span></p>
            <p>
              网&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;关：<span>{{
                infoData.gatewayAddress
              }}</span>
            </p>
            <p>通讯地址：<span>{{ infoData.address }}</span></p>
          </div>
          <div style="flex:3">
            <p>规约类型：<span>{{ infoData.rulerTypeStr }}</span></p>
            <p>用户类型：<span>{{ infoData.userTypeStr }}</span></p>
            <p>波&nbsp;特&nbsp;率&thinsp;&thinsp;：<span>{{ infoData.rateTypeStr }}</span></p>
            <p>端&nbsp;&nbsp;&nbsp;&nbsp;&thinsp;&thinsp;&thinsp;&thinsp;口：<span>{{ infoData.portStr }}</span></p>
            <p>电压变比：<span>{{ infoData.rateV }}</span></p>
            <p>电流变比：<span>{{ infoData.rateA }}</span></p>
            <p>备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&thinsp;注：<span>{{ infoData.remark }}</span></p>
          </div>
        </div>
        <!--<div class="itemBox">

        </div>-->
        <!--        <div class="itemBox">-->
        <!--          <div class="leftTopContent">-->
        <!--            <div class="modelClass">-->
        <!--              控制-->
        <!--            </div>-->
        <!--            <div v-if="protocolType!=1" style="overflow: auto;height: calc(100% - 32px);">-->
        <!--              <el-button v-for="item in controlList" class="conorlButton" size="small" type="primary"-->
        <!--                         @click="productControlFun(item.id)">-->
        <!--                {{ item.name }}-->
        <!--              </el-button>-->
        <!--            </div>-->
        <!--            <div v-if="protocolType==1" style="overflow: auto;height: calc(100% - 32px);">-->
        <!--              <el-button class="conorlButton" size="small" type="primary" @click="productControlFun2(1)">拉闸-->
        <!--              </el-button>-->
        <!--              <el-button class="conorlButton" size="small" type="primary" @click="productControlFun2(2)">合闸-->
        <!--              </el-button>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="leftbottomContent">-->
        <!--            <div class="modelClass">-->
        <!--              控制策略-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <!--<div class="topBox2">
        <div class="leftTopBox2">
          <div class="leftTopContent" style="width:59%">
            <div class="modelClass">
              产品型号：{{ infoData.productModel }}
            </div>
            <div class="namelClass2">
              <span class="codeClass">{{ infoData.name }}</span>
            </div>
          </div>
          <div class="rightTopContent">
            <span style="font-size: 12px;">{{infoData.projectName}}/{{infoData.name}}</span>
          </div>
        </div>
        <div class="leftTopBox2">
          <div class="leftTopContent2">
            <div class="modelClass">
              设备状态
            </div>
            <div class="namelClass">
              <span>{{ infoData.statusStr }}</span>
            </div>
          </div>
        </div>
        <div class="relatedItemsBox">
          <div class="leftTopContent2">
            <div class="modelClass">
              控制
            </div>
            <div style="overflow: auto;height: calc(100% - 32px);">
              <el-button v-for="item in controlList" type="primary" size="small" @click="productControlFun(item.id)" class="conorlButton">
                {{ item.name }}
              </el-button>
            </div>
          </div>
        </div>
      </div>-->
      <div class="searchBox">
        <el-tabs v-model="tabId" class="demo-tabs" type="card" @tab-change="changeTab">
          <el-tab-pane v-for="item in tabList" :key="item.id" :label="item.name" :name="item.id">
          </el-tab-pane>
        </el-tabs>
        <div style="height: calc(100% - 56px );overflow: auto;">
          <DataMonitoring v-if="tabId===1" :value="router.currentRoute.value.query.id"/>
          <!--<ControlLog v-if="tabId===2" :value="router.currentRoute.value.query.id" />
          <WarnInfo v-if="tabId===3" :value="router.currentRoute.value.query.id" />
          <History v-if="tabId===4" :value="router.currentRoute.value.query.id" />-->
          <!--          <Alarm v-if="tabId===5" :value="router.currentRoute.value.query.id"/>-->
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="查看大图">
      <img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus, Back} from '@element-plus/icons-vue'
import './deviceManagement.less'
import {
  getHead,
  getControlList,
  productControl,
  tProductControl,
  getDeviceDetail,

} from "@/api/modules/deviceManagementLocal";
import qs from 'qs';
import DataMonitoring from "@/pages/deviceLocal/navCont/dataMonitoring";
import ControlLog from "@/pages/device/navCont/controlLog";
import WarnInfo from "@/pages/device/navCont/warnInfo";
import History from "@/pages/device/navCont/history";
import Alarm from "@/components/comAlarm";

let router = useRouter()
let tabId = ref(1)
let tabList = ref([{
  name: '数据监测',
  id: 1
},
  //		{
  //			name: '控制日志',
  //			id: 2
  //		},
  //		{
  //			name: '报警信息',
  //			id: 3
  //		},
  //		{
  //			name: '历史数据',
  //			id: 4
  //		},
  // {
  //   name: '设备报警',
  //   id: 5
  // }
])
let infoData = ref({}) // 头部信息
let status = ref(false)
let controlList = ref([]) //控制列表
let gatewayId = ref()
let protocolType = ref()
//获取设备详情
const getDeviceDetailFun = function () {
  getDeviceDetail(qs.stringify({
    id: router.currentRoute.value.query.id,
  })).then(res => {
    //console.log(res)
    if (res.data.ok) {
      gatewayId.value = res.data.result.gatewayId
      protocolType.value = res.data.result.protocolType


      infoData.value = res.data.result
      infoData.value.productImageList2 = []
      for (let i = 0; i < infoData.value.productImageList.length; i++) {
        //console.log(infoData.value.productImageList)
        infoData.value.productImageList2.push({
          url: infoData.value.productImageList[i],
          name: i
        })
      }
      if (infoData.value.status == 1) {
        status.value = true
      } else {
        status.value = false
      }
      controlList.value = res.data.result.productControlList

    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
const getHeadFun = function () {
  getHead(qs.stringify({
    equipmentId: router.currentRoute.value.query.id,
  })).then(res => {
    //console.log(res)
    if (res.data.ok) {
      infoData.value = res.data.result
      infoData.value.productImageList2 = []
      for (let i = 0; i < infoData.value.productImageList.length; i++) {
        //console.log(infoData.value.productImageList)
        infoData.value.productImageList2.push({
          url: infoData.value.productImageList[i],
          name: i
        })
      }
      if (infoData.value.status == 1) {
        status.value = true
      } else {
        status.value = false
      }
      controlList.value = res.data.result.productControlList
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
let dialogVisible = ref(false) //大图弹窗显示开关
let dialogImageUrl = ref() //大图列表
//查看大图
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}
//控制列表
const getControlListFun = function () {
  getControlList(qs.stringify({
    id: router.currentRoute.value.query.id,
  })).then(res => {
    //console.log(res)
    if (res.data.ok) {
      controlList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
//控制的点击
const productControlFun = function (controlId) {
  productControl(qs.stringify({
    equipmentId: router.currentRoute.value.query.id,
    controlId: controlId
  })).then(res => {
    if (res.data.ok) {
      ElMessage({
        type: 'success',
        message: '设置控制操作成功！'
      });
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message + '!'
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
//控制的点击2
const productControlFun2 = function (num) {
  openFullScreen()
  tProductControl({
    onOrOff: num !== 1,
    deviceId: router.currentRoute.value.query.id
  }).then(res => {
    loadingVal.value.close()
    if (res.data.ok) {
      ElMessage({
        type: 'success',
        message: '设置控制操作成功！'
      });
    } else {
      loadingVal.value.close()
      ElMessage({
        type: 'error',
        message: res.data.message + '!'
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
/*加载loading*/
const loadingVal = ref()
const openFullScreen = () => {
  loadingVal.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}
// 返回
const goBack = function () {
  if (router.currentRoute.value.query.projectId) {
    router.push('/admin/projectLocal')
  } else {
    if (router.currentRoute.value.query.ty == 1) {
      router.push({
        path: '/admin/deviceLocal',
        query: {
          pn: router.currentRoute.value.query.pn
        }
      })
    } else {
      router.push({
        path: '/admin/microInterDevice',
        query: {
          pn: router.currentRoute.value.query.pn
        }
      })
    }
  }

}
onMounted(() => {
  // getHeadFun()
  getDeviceDetailFun()
  //getControlListFun()
})
</script>

<style>

</style>
