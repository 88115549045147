<template>
  <div class="file">
    <el-form label-position="top" ref="userForm" :rules="rules" :inline="true" :model="deployGroup"
             label-width="auto">
      <el-form-item v-if="temp.name" label="名称" prop="name">
        <el-input v-model="deployGroup.name" :disabled="store.state.sourceData['prohibit']"></el-input>
      </el-form-item>
      <el-form-item prop="fileType">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">文件类型</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">文件格式类型</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup.fileType"
            placeholder="请选择"
            clearable
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in fileType"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="path">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">文件路径</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">指定文件的目录，可以是软件根目录的相对路径，或绝对路径。请勿包含文件名，文件名应该在数据源中定义</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.path"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">间隔时间（ms）</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">读取文件的间隔时间，单位为毫秒。如果只需读取一次，设置为 0</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input-number v-model="deployGroup.interval"/>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">发送间隔（ms）</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">读取后，两条数据发送的间隔时间，单位为毫秒</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <template #default>
          <div>
            <el-input-number v-model="deployGroup.sendInterval"/>
          </div>
        </template>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">读取后动作</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">读取后的操作。0 表示保留文件；1 表示删除文件；2 表示将文件移动到属性 '移动位置' 中定义的路径</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup.actionAfterRead"
            placeholder="请选择"
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in actionAfterReads"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">移动位置</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">指定读取后将文件移至的路径, 仅用于 actionAfterRead 为 2 的情况</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.moveTo"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">是否包含表头行</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">指定文件是否有表头行，多用于 csv。若为 true，则第一行解析为文件头</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-radio v-model="deployGroup.hasHeader" :label="true" border>True</el-radio>
        <el-radio v-model="deployGroup.hasHeader" :label="false" border>False</el-radio>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">字段列表</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">定义列名，特别适用于 CSV 文件。例如，columns: [id, name]。如果定义了文件头，该选项将被覆盖</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup.columns"
            multiple
            filterable
            allow-create
            default-first-option
            :reserve-keyword="false"
            no-match-text="无匹配数据"
            placeholder="输入新条目后,按下回车键即可新建条目"
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in columns.length>0?columns:[]"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">文件开头忽略的行数</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                              <span
                                  style="color: #ffffff;margin-right: 8px">指定文件开始处要忽略的行数。空行将被忽略且不计算在内</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input-number v-model="deployGroup.ignoreStartLines"/>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">文件结尾忽略的行数</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                              <span
                                  style="color: #ffffff;margin-right: 8px">指定文件末尾要忽略的行数。空行将被忽略且不计算在内</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input-number v-model="deployGroup.ignoreEndLines"/>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import {defineExpose, defineProps, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";

const store = useStore();
const temp = defineProps({
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }, name: {
    type: Boolean,
    default: () => {
      return true
    }
  }
})
//文件类型
let fileType = ref(['json', 'csv', 'lines'])
//读取后动作
let actionAfterReads = ref([0, 1, 2])
let userForm = ref(null)
//字段列表
let columns = ref([])
//form数据
let deployGroup = ref({
  "actionAfterRead": 0,
  "columns": [],
  "fileType": "json",
  "hasHeader": false,
  "ignoreEndLines": 0,
  "ignoreStartLines": 0,
  "interval": 0,
  "moveTo": "",
  "parallel": false,
  "path": "",
  "name": "",
  "sendInterval": 0,
  "numericField": "",
})
const rules = reactive({
  name: [{required: true, message: '请填写名称', trigger: 'blur'}],
  fileType: [{required: true, trigger: 'blur', message: ''}],
  path: [{required: true, trigger: 'blur', message: ''}],
})
const getFormData = async () => {
  const isValid = await userForm.value.validate();
  if (isValid) {
    return deployGroup.value
  }
}
defineExpose({getFormData});
onMounted(() => {
  deployGroup.value = {...deployGroup.value, ...temp.data};
  // console.log(temp.name)
})
</script>


<style scoped lang="less">
//提示文字最大宽度
.max-tooltip {
  max-width: 280px !important;
}

.label {
  position: relative;
  margin-left: 10px;

  &:before {
    position: absolute;
    content: '*';
    color: var(--el-color-danger);
    top: 0;
    left: -10px;
  }
}

.file {
  @import "public";

  :deep(.el-form) {
    .el-form-item__content {
      width: 500px !important;
      height: 32px;
      border-radius: 8px;
      box-sizing: border-box;

      .el-input-number {
        width: 180px !important;;
      }
    }
  }
}
</style>