<template>
	<div class="plan">
		<div class="content">
			<div class="tableBox">
				<div class="operationBox">
					<el-button :icon="Plus" type="primary" @click="organizationScheme()">新增方案</el-button>
					<el-button type="primary" @click="readSchemeFun">读取方案</el-button>
					<el-button type="primary" @click="emptySchemeFun()">清空方案</el-button>
					<el-button type="primary" @click="issueSchemeFun()">下发方案</el-button>
				</div>
				<el-table v-loading="schemeLoading" :data="schemeData" style="width: 100%">
					<template #empty>
						<el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
							<p>您可以在此页面新建方案信息</p>
						</el-empty>
					</template>
					<el-table-column label="方案编号" min-width="1%" prop="">
						<template #default="scope">{{ scope.row.caseNo }}</template>
					</el-table-column>
					<el-table-column label="方案类型" min-width="1%" prop="case_type">
						<template #default="scope">{{ scope.row.caseTypeStr }}</template>
					</el-table-column>
					<el-table-column label="采集类型" min-width="1%" prop="collect_type">
						<template #default="scope">{{ scope.row.collectTypeStr }}</template>
					</el-table-column>
					<el-table-column label="用户类型" min-width="2%" prop="">
						<template #default="scope">{{ scope.row.msUser }}</template>
					</el-table-column>
					<el-table-column label="数据项" min-width="3%" prop="dataItem">
					</el-table-column>
					<el-table-column label="操作" min-width="1%" prop="">
						<template #default="scope">
							<ul class="operationUl">
								<li class="editClass" @click="organizationScheme(scope.row.id)"><img alt="" src="../../../assets/img/161@2x.png" /></li>
								<li class="editClass" @click="delSchemeFun(scope.row.id)"><img alt="" src="../../../assets/img/163@2x.png" />
								</li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
				<div class="footerBox">
					<el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small" :total="total" background layout="total, sizes, prev, pager, next" small @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</div>
		</div>
		<!--新增、编辑方案弹窗-->
		<el-dialog v-model="organizationSchemeAlert" :close-on-click-modal="false" :title="title" :width="dialogWidth" class="organizationSchemeAlert">
			<el-form ref="organizationSchemeFormRef" :model="organizationSchemeForm" :rules="organizationSchemeRules" label-width="auto">
				<el-form-item label="采集方案" prop="caseType">
					<el-select v-model="organizationSchemeForm.caseType" placeholder="请选择" style="width:100%">
						<el-option v-for="item in caseTypeList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="方案编号" prop="caseNo">
					<el-input v-model="organizationSchemeForm.caseNo" autocomplete="off" placeholder="单行输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="存储深度" prop="storeDepth">
					<el-input v-model="organizationSchemeForm.storeDepth" autocomplete="off" placeholder="单行输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="采集方式" prop="collectType">
					<el-select v-model="organizationSchemeForm.collectType" placeholder="请选择" style="width:100%">
						<el-option v-for="item in collectTypeList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if='organizationSchemeForm.collectType==1||organizationSchemeForm.collectType==5' label="采集内容" prop="collectContent">
					<el-input v-model="organizationSchemeForm.collectContent" autocomplete="off" placeholder="单行输入" type="text"></el-input>
				</el-form-item>
				<el-form-item label="ms数据类型分类" prop="ms">
					<el-select v-model="organizationSchemeForm.ms" placeholder="请选择" style="width:100%">
						<el-option v-for="item in msList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<div v-for="(item,index) in msUserList" v-if='organizationSchemeForm.ms==2' class="el-form-item">
					<el-form-item label="一组用户类型对应值" style="width: 100%">
						<el-input v-model="item.oneVal" autocomplete="off" placeholder="单行输入" style="width: calc(100% - 32px);" type="number"></el-input>
						<el-button v-if="index==0" :icon="Plus" circle type="info" @click="addClick" />
						<el-button v-if="index!=0" :icon="Minus" circle type="info" @click="minusClick(index)" />
					</el-form-item>
				</div>
				<el-form-item label="储存时标选择" prop="timeMarker">
					<el-select v-model="organizationSchemeForm.timeMarker" placeholder="请选择" style="width:100%">
						<el-option v-for="item in timerMarkerList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="产品类型" prop="parserId">
					<el-select v-model="organizationSchemeForm.parserId" clearable placeholder="请选择" style="width:100%" @change="parserChange">
						<el-option v-for="item in parserList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="记录列选择">
					<el-button :disabled="!organizationSchemeForm.parserId" plain style="margin-left:4px;padding:8px;font-size: 12px;" type="primary" @click="selectHandle()">记录列筛选
					</el-button>
					<div class="specilCheckBox">
						<div v-for="item in parserItemIdList" v-show="item.checked==true" class="parserClass">
							<el-checkbox v-model="item.checked" :disabled="!organizationSchemeForm.parserId">{{ item.name }}
							</el-checkbox>
							<div class="nameClass2">{{ item.dataCode }}</div>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button @click="organizationSchemeAlert = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="organizationSchemeFun">保存</el-button>
      			</span>
			</template>
		</el-dialog>
		<!--记录列弹窗-->
		<el-dialog v-model="dialogFormVisible10" :close-on-click-modal="false" :width="dialogWidth2" title="记录列筛选">
			<div>
				<div class="checkAllBox">
					<el-checkbox v-model="checkedAll" @change="checkedAllChange()">全选</el-checkbox>
				</div>
				<div v-for="item in parserItemIdList" :class="[item.checkFlag?'parserClass2':'parserClass3']">
					<el-checkbox v-model="item.checked" @change="checkedRadioChange()">{{ item.name }}</el-checkbox>
					<div v-if="item.checkFlag==true" :class="[item.checkFlag?'nameClass3':'nameClass2']">{{ item.dataCode }}</div>
					<div v-if="item.checkFlag==false" :class="[item.checked?'nameClass3':'nameClass2']">{{ item.dataCode }}</div>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button @click="dialogFormVisible10 = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="dialogFormVisible10 = false">确认</el-button>
      			</span>
			</template>
		</el-dialog>
		<!--读取方案弹窗-->
		<el-dialog v-model="readSchemeAlert" :close-on-click-modal="false" class="readSchemeAlert" title="读取方案" width="80%">
			<div class="tableBox" style="padding: 0;">
				<el-table v-loading='readLoading' :data="readData" style="width: 100%" table-layout="auto">
					<template #empty>
						<el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
						</el-empty>
					</template>
					<el-table-column label="上报时间" min-width="1%" prop="createTime">
					</el-table-column>
					<el-table-column label="上报内容" min-width="3%" prop="content">
					</el-table-column>
					<el-table-column align="center" fixed="right" label="操作" min-width="1%" width="100px">
						<template #default="scope">
							<ul>
								<li class="editClass" @click="analysisClick(scope.row.id)">解析</li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button class="confrim" type="primary" @click="readSchemeAlert=false">确认</el-button>
      			</span>
			</template>
		</el-dialog>
		<!--解析弹窗-->
		<el-dialog v-model="analysisAlert" :close-on-click-modal="false" class="analysisAlert" title="方案解析" width="80%">
			<div class="tableBox" style="padding: 0;">
				<!--方案解析-->
				<el-table v-loading="analysisLoading" :data="analysisList" style="width: 100%" table-layout="auto">
					<template #empty>
						<el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
						</el-empty>
					</template>
					<el-table-column label="采集方案" min-width="1%" prop="caseTypeStr">
					</el-table-column>
					<el-table-column label="方案编号" min-width="1%" prop="caseNo">
					</el-table-column>
					<el-table-column label="存储深度" min-width="1%" prop="deep">
					</el-table-column>
					<el-table-column label="采集方式" min-width="1%" prop="collectTypeStr">
					</el-table-column>
					<el-table-column label="采集内容" min-width="1%" prop="collectContent">
					</el-table-column>
					<el-table-column label="表计集合" min-width="1%" prop="ms">
					</el-table-column>
					<el-table-column label="表计内容" min-width="1%" prop="msList">
					</el-table-column>
					<el-table-column label="储存时标选择" min-width="1%" prop="timeMarker">
					</el-table-column>
					<el-table-column label="记录选择" min-width="1%" prop="columns">
					</el-table-column>
				</el-table>
			</div>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button class="confrim" type="primary" @click="analysisAlert=false">确认</el-button>
      			</span>
			</template>
		</el-dialog>
		<div v-if="progressShow" class="progressPop">
			<Progress></Progress>
		</div>
	</div>
</template>

<script setup>
	import { reactive, onMounted, computed, watch, ref, defineComponent, h } from 'vue';
	import { useRouter } from 'vue-router';
	import { ElTable, ElMessage, ElMessageBox, ElLoading } from 'element-plus'
	import { ArrowUpBold, ArrowDownBold, Plus, Minus } from '@element-plus/icons-vue'
	import {
		getList,
		getSelectList,
		listParserSelect,
		listParserItemSelect,
		saveList,
		getDetail,
		deleteList,
		downList,
		cleanList,
		readList,
		readResultList,
		readParseList
	} from '../../../api/modules/plan'
	import './plan.less'
	import Progress from '../../../components/progress.vue'
	import qs from 'qs';
	// 定义传值类型
	const props = defineProps({
		value: String //这里就接收到父组件传递的value
	})
	let pageNumber = ref(1) // 当前页数
	let pageSize = ref(20) // 每页条数
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageNumber.value = val;
		getListFun();
	}
	const total = ref(0) //总条数
	// 获取列表
	let schemeLoading = ref(false)
	let schemeData = ref([])
	const getListFun = function() {
		schemeLoading.value = true
		getList(qs.stringify({
			pageSize: pageSize.value,
			pageNumber: pageNumber.value,
			gatewayId: props.value
		})).then(res => {
			//console.log(res);
			schemeLoading.value = false
			if(res.data.ok) {
				schemeData.value = res.data.result.list
				total.value = Number(res.data.result.total)
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//获取所有select
	const getSelectListFun = function() {
		getSelectList(null).then(res => {
			//console.log(res);
			if(res.data.ok) {
				caseTypeList.value = res.data.result.caseTypeList
				msList.value = res.data.result.msList
				collectTypeList.value = res.data.result.collectTypeList
				timerMarkerList.value = res.data.result.timerMarkerList
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//获取所有抄读select
	const listParserSelectFun = function() {
		listParserSelect(qs.stringify({
			gatewayId: props.value
		})).then(res => {
			//console.log(res);
			if(res.data.ok) {
				parserList.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//抄读项的改变
	const parserChange = function() {
		parserItemIdList.value = []
	}
	let dialogFormVisible10 = ref(false) //纪录列弹窗显示
	let dialogWidth2 = ref('60%') //弹窗宽度
	/*纪录列筛选*/
	//纪录列筛选的点击
	const selectHandle = function() {
		listParserItemSelectFun()
		dialogFormVisible10.value = true
	}
	//获取数据项select
	const listParserItemSelectFun = function() {
		if(!editId.value) {
			listParserItemSelect(qs.stringify({
				parserId: organizationSchemeForm.value.parserId
			})).then(res => {
				//console.log(res);
				if(res.data.ok) {
					parserItemIdList.value = res.data.result
					parserItemIdList.value.forEach(item => {
						if(item.checkFlag) {
							item.checked = true
						} else {
							item.checked = false
						}
					})
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			listParserItemSelect(qs.stringify({
				parserId: organizationSchemeForm.value.parserId
			})).then(res => {
				//console.log(res);
				if(res.data.ok) {
					res.data.result.forEach(item => {
						parserItemIdList.value.forEach(item_s => {
							if(item_s.id == item.id) {
								item.checkFlag = item_s.checkFlag
							}
						})
					})
					parserItemIdList.value = res.data.result
					parserItemIdList.value.forEach(item => {
						if(item.checkFlag) {
							item.checked = true
						} else {
							item.checked = false
						}
					})
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}
	}
	//全选、全不选
	const checkedAllChange = function() {
		if(checkedAll.value == true) {
			parserItemIdList.value.forEach(item => {
				item.checked = true
			})
		} else {
			parserItemIdList.value.forEach(item => {
				item.checked = false
			})
		}
	}
	//单选
	const checkedRadioChange = function(val, signVal) {
		var checkedArr = []
		parserItemIdList.value.forEach(item => {
			if(item.checked == true) {
				checkedArr.push(item)
			}
		})
		if(checkedArr.length == parserItemIdList.value.length) {
			checkedAll.value = true
		} else {
			checkedAll.value = false
		}
	}
	let checkedAll = ref(false) //全选

	// 组织方案相关事件及数据***********************************************************
	let title = ref() //弹窗名称
	let dialogWidth = ref('40%') //弹窗宽度
	let parserList = ref([]) //抄读列表
	let parserItemIdList = ref([]) //数据项列表
	let msUserList = ref([]) //用户类型列表
	let organizationSchemeAlert = ref(false) // 组织方案弹窗
	let organizationSchemeData = ref([]) // 组织方案表格数据
	let editId = ref() //编辑的id
	let caseTypeList = ref([]) // 采集方案列表
	let msList = ref([]) // 组织方案ms数据类型分类
	let collectTypeList = ref([]) // 组织方案采集方式
	let timerMarkerList = ref([]) // 组织方案存储时标选择

	// 新增、编辑组织方案
	const organizationScheme = function(id) {
		organizationSchemeForm.value = {
			caseType: '',
			caseNo: '',
			storeDepth: '255',
			collectType: '',
			collectContent: '',
			ms: '',
			timeMarker: '',
			parserId: ''
		}
		parserItemIdList.value = []
		msUserList.value = []
		msUserList.value.push(new Sell());
		organizationSchemeAlert.value = true
		if(id) {
			title.value = "编辑方案"
			editId.value = id
			getDetail(qs.stringify({
				id: id
			})).then(res => {
				//console.log(res);
				if(res.data.ok) {
					organizationSchemeForm.value.caseType = String(res.data.result.caseType)
					organizationSchemeForm.value.caseNo = res.data.result.caseNo
					organizationSchemeForm.value.storeDepth = res.data.result.storeDepth
					organizationSchemeForm.value.collectType = String(res.data.result.collectType)
					organizationSchemeForm.value.collectContent = res.data.result.collectContent
					organizationSchemeForm.value.ms = String(res.data.result.ms)
					organizationSchemeForm.value.timeMarker = String(res.data.result.timeMarker)
					organizationSchemeForm.value.parserId = res.data.result.parserId
					msUserList.value = []
					if(res.data.result.msUserList.length > 0) {
						res.data.result.msUserList.forEach(item => {
							msUserList.value.push({
								oneVal: item
							})
						})
					} else {
						msUserList.value = []
						msUserList.value.push(new Sell());
					}
					parserItemIdList.value = res.data.result.checkParserItemList
					parserItemIdList.value.forEach(item => {
						if(item.checkFlag == 1) {
							item.checked = true
						} else {
							item.checked = false
						}
					})
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			title.value = "新增方案"
			editId.value = ''
		}
	}
	/*用户类型*/
	let oneVal1 = ref()

	function Sell(oneVal) {
		oneVal1.value = oneVal;
	}

	Sell.prototype.setVar1 = function(value) {
		oneVal1.value = value;
	};
	msUserList.value.push(new Sell());
	//新增用户类型的点击
	const addClick = function() {
		msUserList.value.push(new Sell());
	}
	//删除
	const minusClick = function(index) {
		msUserList.value.splice(index, 1);
	}

	let organizationSchemeFormRef = ref() //网关方案
	//网关方案表单
	let organizationSchemeForm = ref({
		caseType: '',
		caseNo: '',
		storeDepth: '255',
		collectType: '',
		collectContent: '',
		ms: '',
		timeMarker: '',
		parserId: ''
	})
	// 网关方案表单验证规则
	let organizationSchemeRules = ref({
		caseType: [{
			required: true,
			message: '请输入采集方案',
			trigger: 'blur'
		}],
		caseNo: [{
			required: true,
			message: '请输入方案编号',
			trigger: 'blur'
		}],
		storeDepth: [{
			required: true,
			message: '请输入储存深度',
			trigger: 'blur'
		}],
		collectType: [{
			required: true,
			message: '请输入采集方式',
			trigger: 'blur'
		}],
		ms: [{
			required: true,
			message: '请输入ms数据类型分类',
			trigger: 'blur'
		}],
		timeMarker: [{
			required: true,
			message: '请输入储存时标',
			trigger: 'blur'
		}],
		parserId: [{
			required: true,
			message: '请选择产品类型',
			trigger: 'blur'
		}],
	})

	// 组织方案的保存
	const organizationSchemeFun = function() {
		var parserItemIdList1 = ''
		var arr = []
		parserItemIdList.value.forEach(item => {
			if(item.checked) {
				arr.push(item.id)
			}
		})
		parserItemIdList1 = arr.toString()
		var msUserList1 = '';
		msUserList.value.forEach((item, index) => {
			if(item.oneVal) {
				if(index == msUserList.value.length - 1) {
					msUserList1 += item.oneVal
				} else {
					msUserList1 += item.oneVal + ','
				}
			}
		})
		if(organizationSchemeForm.value.ms == 1) {
			msUserList1 = ''
		}
		if(organizationSchemeForm.value.ms == 2) {
			organizationSchemeForm.value.msUser = msUserList1
		}
		let params = {
			gatewayId: props.value,
			caseType: organizationSchemeForm.value.caseType,
			caseNo: organizationSchemeForm.value.caseNo,
			storeDepth: organizationSchemeForm.value.storeDepth,
			collectType: organizationSchemeForm.value.collectType,
			collectContent: organizationSchemeForm.value.collectContent,
			ms: organizationSchemeForm.value.ms,
			msUserList: msUserList1,
			timeMarker: organizationSchemeForm.value.timeMarker,
			parserId: organizationSchemeForm.value.parserId,
			parserItemIdList: parserItemIdList1
		}
		if(organizationSchemeFormRef) {
			organizationSchemeFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					if(!editId.value) {
						params['id'] = 0
					} else if(editId.value) {
						params['id'] = editId.value
					}
					//console.log(params)
					saveList(
						qs.stringify(params)
					).then(async res => {
						//console.log(res);
						if(res.data.ok) {
							organizationSchemeAlert.value = false
							getListFun()
						} else {
							ElMessage({
								message: res.data.message,
								type: 'error'
							})
						}
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}

	}
	// 删除
	const delSchemeFun = function(id) {
		ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			title: '删除方案',
			message: h('p', null, [
				h('img', {
					src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
					style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
				}, ''),
				h('span', {
					style: 'color:#595959;'
				}, '请确认是否要删除此方案，删除后数据不可恢复'),
			])
		}).then(() => {
			deleteList(
				qs.stringify({
					id: id
				})
			).then(res => {
				//console.log(res);
				if(res.data.ok) {
					getListFun()
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消删除',
				type: 'info',
			})
		});
	}
	// 读取方案***********************************************************
	// 读取方案弹窗----------------------------------------------
	let readSchemeAlert = ref(false)
	let readLoading = ref(false)
	// 读取方案列表
	// 点击读取方案
	const readSchemeFun = function() {
		analysisList.value = []
		readData.value = []
		progressShow.value = true
		readSchemeAlert.value = true
		readList(qs.stringify({
			gatewayId: props.value
		})).then(async res => {
			//console.log(res);
			if(res.data.ok) {
				if(res.data.result) {
					await readResultListFun(res.data.result);
				}
			} else {
				ElMessage({
					type: 'error',
					message: res.data.message + '!'
				});
				progressShow.value = false
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//方案读取结果
	const readResultListFun = function(id) {
		let num = 0
		let timer = setInterval(() => {
			num++
			readResultList(qs.stringify({
				commandLogId: id
			})).then(res => {
				//console.log(num)
				//console.log(res);
				if(res.data.ok) {
					if(res.data.result.length > 0) {
						progressShow.value = false
						readData.value = res.data.result
						ElMessage({
							type: 'success',
							message: '读取方案成功!'
						});
					}
					if(num > 9 || res.data.result.length > 0) {
						clearInterval(timer)
					}
					if(num > 9) {
						progressShow.value = false
						ElMessage({
							type: 'error',
							message: '读取方案失败!'
						});
					}
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}, 1000)
	}
	let readData = ref([])
	const readParseListFun = function(id) {
		analysisLoading.value = true
		readParseList(
			qs.stringify({
				commandLogId: id
			})).then(res => {
			//console.log(res);
			analysisLoading.value = false
			if(res.data.ok) {
				analysisList.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	/*加载loading*/
	const loadingVal = ref()
	const progressShow = ref(false)
	const openFullScreen = () => {
		loadingVal.value = ElLoading.service({
			lock: true,
			text: '',
			background: 'rgba(0, 0, 0, 0.7)',
		})
	}
	// 解析弹窗----------------------------------------------
	let analysisAlert = ref(false)
	// 方案解析列表
	let analysisList = ref([])
	let analysisLoading = ref(false)
	// 点击查看解析
	const analysisClick = function(id) {
		analysisAlert.value = true
		readParseListFun(id)
	}

	// 清空方案***********************************************************
	const emptySchemeFun = function() {
		ElMessageBox.confirm("确认清空此网关的方案吗？", '清空方案', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			cleanList(qs.stringify({
				gatewayId: props.value
			})).then(res => {
				//console.log(res);
				//loadingVal.value.close()
				progressShow.value = false
				if(res.data.ok) {
					ElMessage({
						message: '方案清空成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消清空方案!'
			});
		});
	}

	// 下发方案***********************************************************
	const issueSchemeFun = function() {
		ElMessageBox.confirm("确认下发此网关的方案吗？", '下发方案', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success'
		}).then(() => {
			//openFullScreen()
			progressShow.value = true
			downList(qs.stringify({
				gatewayId: props.value
			})).then(res => {
				//console.log(res);
				//loadingVal.value.close()
				progressShow.value = false
				if(res.data.ok) {
					ElMessage({
						message: '方案下发成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消下装方案!'
			});
		});
	}

	watch(() => props.value, (newValue, oldValue) => {
		getListFun()
		getSelectListFun()
		listParserSelectFun()
	}, {
		immediate: true
	})
</script>

<style>

</style>