import {api} from "@/api";

// 获取订阅列表
export const getListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/subscribe/list',
        method: 'GET',
        params: data
    })
}


// 新增订阅列表
export const saveListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/subscribe/create',
        method: 'POST',
        data: data
    })
}


// 编辑订阅列表
export const editListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/subscribe/update',
        method: 'POST',
        data: data
    })
}
// 查询列表详情
export const getDetailApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/subscribe/detail/${id}`,
        method: 'GET',
    })
}


// 删除订阅列表
export const delListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/subscribe/remove/${id}`,
        method: 'POST'
    })
}


// 查询设备列表
export const getEquipmentListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/device/list',
        method: 'GET',
        params: data
    })
}

// 查询指标列表
export const getDataListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/dict/list/${id}`,
        method: 'GET',
    })
}