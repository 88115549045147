<template>
  <div class="southDriver">
    <div class="title">
      <p>
        <span>南向设备</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px;">插件类型</label>
          <el-select v-model="plugin" clearable placeholder="请选择插件类型" @change="">
            <el-option v-for="item in pluginData" :key="item.name" :label="item.name" :value="item.name"
                       style="width:100%">
            </el-option>
          </el-select>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px;">名称</label>
          <el-input v-model="node" :prefix-icon="Search" clearable placeholder="请输入关键字搜索"></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="getNodeFun">查询</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--内容-->
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">
            添加设备
          </el-button>
          <el-button type="primary" @click="exportClick()">
            导出
          </el-button>
          <el-upload v-model:file-list="nodeData" :auto-upload="false" :show-file-list="false" action="#"
                     class="upload-demo"
                     limit="1" @change="uploadFile2">
            <el-button style="margin-left: 12px;" type="primary" @click="">
              导入
            </el-button>
          </el-upload>
        </div>
        <!--列表-->
        <el-table v-loading="loadingVal" :data="tableData" :row-key="getRowKeys" style="width: 100%"
                  @selection-change="handleSelectionChange">
          <el-table-column :reserve-selection="true" label="选取" style="height:20px" type="selection" width="55">
          </el-table-column>
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面添加设备</p>
            </el-empty>
          </template>
          <el-table-column label="名称" property="name">
            <template #default="scope">
              <span style="cursor: pointer; color:rgb(22, 93, 255);"
                    @click="groupClick(scope.row.name)">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="工作状态" property="running">
            <template #default="scope">
              <span v-if="scope.row.running==4" class="stop" @click="groupClick(scope.row.name)">停止</span>
              <span v-if="scope.row.running==3" class="start" @click="groupClick(scope.row.name)">运行中</span>
            </template>
          </el-table-column>
          <el-table-column label="连接状态" property="link">
            <template #default="scope">
              <p v-if="scope.row.link==1" style="color:rgba(0, 0, 0, 0.85)" @click="groupClick(scope.row.name)">连接</p>
              <p v-if="scope.row.link==0" style="color: #bac1cd;" @click="groupClick(scope.row.name)">断开</p>
            </template>
          </el-table-column>
          <el-table-column label="延时(毫秒)" property="rtt">
            <template #default="scope">
              <p @click="groupClick(scope.row.name)">
                {{ scope.row.rtt }}</p>
            </template>
          </el-table-column>
          <el-table-column label="插件" property="plugin">
            <template #default="scope">
              <p @click="groupClick(scope.row.name)">
                {{ scope.row.plugin }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <ul class="operationUl">
                <li>
                  <el-tooltip :content="scope.row.switch?'停止':'启动'" class="box-item" effect="dark">
                    <el-switch v-model="scope.row.switch" style="--el-switch-on-color: #ccefe3;"
                               @change="isOpenChange($event,scope.row)"/>
                  </el-tooltip>
                </li>
                <li>
                  <el-tooltip class="box-item" content="组列表" effect="dark">
                    <img alt="" class="groupClass" src="../../../assets/img/group.svg"
                         @click="groupClick(scope.row.name)"/>
                  </el-tooltip>
                </li>
                <li>
                  <el-popover popper-class="moreOpera" trigger="click">
                    <div class="listPox">
                      <p @click="editClick(scope.row)">
                        <el-icon>
                          <EditPen/>
                        </el-icon>
                        <span>编辑设备</span>
                      </p>
                      <p @click='deviceDetailClick(1,scope.row.name,scope.row.plugin2)'>
                        <el-icon>
                          <DataAnalysis/>
                        </el-icon>
                        <span>数据统计</span>
                      </p>
                      <p @click='deviceDetailClick(2,scope.row.name,scope.row.plugin2)'>
                        <el-icon>
                          <Setting/>
                        </el-icon>
                        <span>设备配置</span>
                      </p>
                      <p @click="debugClick(scope.row.log_level,scope.row.name)">
                        <el-icon>
                          <Memo/>
                        </el-icon>
                        <span>{{ scope.row.log_level == 'notice' ? '开启' : '关闭' }}DEBUG日志</span>
                      </p>
                      <p @click="copyClick(scope.row.name)">
                        <img alt="" class="copyClass" src="../../../assets/img/copy.svg"/>
                        <span>复制</span>
                      </p>
                      <p @click="downloadLogFun(scope.row)">
                        <el-icon>
                          <Download/>
                        </el-icon>
                        <span>下载驱动日志</span>
                      </p>
                      <p @click="delClick(scope.row.name)">
                        <el-icon>
                          <Delete/>
                        </el-icon>
                        <span>删除</span>
                      </p>
                    </div>
                    <template #reference>
                      <p class="more">
                        <el-tooltip class="box-item" content="更多" effect="dark">
                          <el-icon class="more">
                            <MoreFilled/>
                          </el-icon>
                        </el-tooltip>
                      </p>
                    </template>
                  </el-popover>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--编辑弹窗-->
    <el-dialog v-model="editDialogFormVisible" :close-on-click-modal="false" :title="title" width="40%">
      <el-form ref="userForm2" :inline="true" :model="formData2" :rules="formRules2" label-width="auto">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formData2.name" placeholder="请输入设备名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="editDialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="editSave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--添加弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="title" width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="名称" prop="name" style="margin-bottom: 20px">
          <el-input v-model="formData.name" placeholder="请输入应用名称"></el-input>
        </el-form-item>
        <el-form-item label="插件" prop="plugin">
          <el-select v-model="formData.plugin" clearable filterable placeholder="请选择插件" @change="selectChange">
            <el-option v-for="(item,index) in pluginData" :key="index" :label="item.name==='eKuiper'?'ZKData':item.name"
                       :value="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="formData.params" label-width="auto">
        <!--接口请求循环出来的表单数据-->
        <el-form-item v-for="(value, key, index) in formObj" :key="index" :prop="key" :rules="[{
                        required: value.attribute==='required'&&(!value.condition||formData.params[value.condition.field]===value.condition.value),
                        message: `${value.type==='string'||value.type==='int'?'请输入':'请选择'}${value.name_zh}`,
                        trigger: 'blur',
                      }]" class="formItem">
          <div v-if="(!value.condition||formData.params[value.condition.field]===value.condition.value)&&value.name_zh"
               slot="label"
               class="labelBox" style="margin-top: 20px">
            <i v-if="value.attribute==='required'" style="color: red;">*</i>
            <p style="margin-left: 5px">{{ value.name_zh }}</p>
            <el-tooltip :content="value.description_zh" effect="dark" placement="top" popper-class="el_tooltip_item">
              <el-icon>
                <QuestionFilled/>
              </el-icon>
            </el-tooltip>
          </div>
          <div class="widthBox">
            <!--输入框-->
            <el-input
                v-if="value.type==='string'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" :maxlength="value.valid&&value.valid.length"></el-input>
            <!--下拉框-->
            <el-select
                v-if="value.type==='map'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" clearable>
              <el-option v-for="(item,index) in value.valid.map" :key="index" :label="item.key"
                         :value="item.value"></el-option>
            </el-select>
            <!--单选框-->
            <el-radio-group
                v-if="value.type==='bool'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]">
              <el-radio :label="true" border>True</el-radio>
              <el-radio :label="false" border>False</el-radio>
            </el-radio-group>
            <!--数字输入框-->
            <el-input-number
                v-if="value.type==='int'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" :controls="false" :max="value.valid.max" :min="value.valid.min"/>
            <!--上传文件-->
            <el-upload
                v-if="value.type==='file'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model:file-list="formData.params[key+'-fileList']" :auto-upload="false" action="#" class="upload-demo"
                limit="1" @change="uploadFile($event,key)">
              <div class="uploadBox">
                <div :class="formData.params[key+'-fileList']&&formData.params[key+'-fileList'].length>0?'none':''"
                     class="upBtnBox">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <span>点击上传</span>
                </div>
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--复制弹窗-->
    <el-dialog v-model="copyeditDialogFormVisible" :close-on-click-modal="false" title="驱动复制" width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="名称" prop="copyName">
          <el-input v-model="formData.copyName" placeholder="请输入驱动名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="copyeditDialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="copySave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h, onUnmounted} from 'vue';
import './southDriver.less'
import {
  Plus,
  Search,
  CollectionTag,
  EditPen,
  DataAnalysis,
  Setting,
  Memo,
  Download,
  MoreFilled,
  Delete
} from '@element-plus/icons-vue'
import {
  getNode,
  getPlugin,
  getState,
  ctl,
  driversList,
  driversControl,
  nodeDelete,
  levelControl,
  nodeEditSave,
  template,
  templateImport
} from "@/api/modules/southDriver";
import {
  downloadLogApi,
  addDataApi,
  getFormDataApi
} from "@/api/modules/northDriver";
import {addWaterApi, delWaterListApi, editWaterApi, getWaterListApi} from "@/api/modules/waterMeter";
import {
  ElMessage,
  ElMessageBox,
  ElLoading
} from "element-plus";
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()
/*生命周期*/
onMounted(async () => {
  if (router.currentRoute.value.query.ty) {
    formData.value = {
      name: '',
      plugin: '',
      params: {}
    }
    dialogFormVisible.value = true
    title.value = "添加设备"
  }
  await getNodeFun()
  await getPluginFun()
})
let loadingVal = ref(false) //加载
let tableData = ref([]) //设备列表
//获取设备列表
const getNodeFun = async function () {
  loadingVal.value = true
  let params = {
    type: 1,
    node: node.value,
    plugin: plugin.value
  }
  await getNode(params).then(res => {
    loadingVal.value = false
    if (res.status == 200) {
      tableData.value = res.data.nodes
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].plugin === 'eKuiper') {
          tableData.value[i].plugin = 'ZKData'
        }
      }
      getStateFun()
    } else {
      tableData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//获取插件列表
let pluginData = ref([])
const getPluginFun = function () {
  getPlugin(null).then(res => {
    if (res.status == 200) {
      pluginData.value = res.data.plugins
      tableData.value.forEach(item => {
        pluginData.value.forEach(items => {
          if (item.plugin == items.name) {
            item.plugin2 = items.schema
          }
        })
      })
    } else {
      pluginData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//详情
const getStateFun = function () {
  getState(null).then(res => {
    if (res.status == 200) {
      tableData.value.forEach(item => {
        res.data.states.forEach(items => {
          if (item.name == items.node) {
            item.link = items.link
            item.running = items.running
            item.rtt = items.rtt
            if (items.running == 3) {
              item.switch = true
            } else {
              item.switch = false
            }
            item.log_level = items.log_level
          }
        })
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//搜索
let plugin = ref() //插件
let node = ref() //名称
// 重置
const resetFun = function () {
  plugin.value = ''
  node.value = ''
  getNodeFun()
}
// 定时器
const intervalId = setInterval(getStateFun, 10000)
onUnmounted(() => {
  clearInterval(intervalId)
})

// 设备连接断开
// 应用连接断开
let loading = ref(false)
const isOpenChange = function (value, scope) {
  ctl({
    cmd: scope.running === 3 ? 1 : 0,
    node: scope.name,
  }).then(res => {
    loading.value = false
    if (res.status === 200) {
      getNodeFun()
      ElMessage({
        message: scope.running === 3 ? '停止成功' : '运行成功',
        type: 'success'
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
  })
}

let driversData = ref([])
//复制弹窗的点击
let copyeditDialogFormVisible = ref(false)
const copyClick = function (name) {
  formData.value.copyName = name + '_copy'
  copyeditDialogFormVisible.value = true
  driversList({
    name: name
  }).then(res => {
    if (res.status == 200) {
      driversData.value = res.data
      driversData.value.nodes[0].name = formData.value.copyName
    } else {
      driversData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//复制弹窗的保存
const copySave = function () {
  driversControl(driversData.value).then(res => {
    if (res.status == 200) {
      getNodeFun()
      copyeditDialogFormVisible.value = false
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//关闭或开启debug日志
const debugClick = function (log_level, name) {
  var level;
  if (log_level == 'debug') {
    level = 'notice'
  } else {
    level = 'debug'
  }
  var params = {
    core: false,
    level: level,
    node: name
  }
  levelControl(params).then(res => {
    if (res.status == 200) {
      getNodeFun()
      copyeditDialogFormVisible.value = false
      if (log_level == 'debug') {
        ElMessage({
          message: '已关闭DEBUG日志！',
          type: 'success'
        })
      } else if (log_level == 'notice') {
        ElMessage({
          message: '已开启DEBUG日志！',
          type: 'success'
        })
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

let dialogFormVisible = ref(false) // 添加表单弹窗
let title = ref('') //弹窗title
// 新增设备点击
const addClick = function () {
  formData.value = {
    name: '',
    plugin: '',
    params: {}
  }
  dialogFormVisible.value = true
  title.value = "添加设备"
}
// 添加设备表单数据
let formData = ref({
  name: '',
  plugin: '',
  params: {}
})
// 添加设备表单验证
let formRules = ref({
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'change',
  }],
  plugin: [{
    required: true,
    message: '请选择插件',
    trigger: 'blur'
  }],
  copyName: [{
    required: true,
    message: '请输入驱动名称',
    trigger: 'change',
  }],
})
let userForm = ref() // 提交添加设备表单
// 添加设备的保存
const save = function () {
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      addDataApi(formData.value).then(res => {
        if (res.status === 200) {
          getNodeFun()
          dialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}
// 插件选择事件
let formObj = ref({})
const selectChange = function () {
  var schema_name;
  pluginData.value.forEach(items => {
    if (formData.value.plugin == items.name) {
      schema_name = items.schema
    }
  })
  getFormDataApi({
    schema_name: schema_name
  }).then(res => {
    if (res.status === 200) {
      formData.value = {
        name: formData.value.name,
        plugin: formData.value.plugin,
        params: {}
      }
      formObj.value = res.data
      console.log(formObj.value)
      let num = 0
      for (const key in formObj.value) {
        formData.value.params[key] = formObj.value[key].default
        if (formObj.value[key].type === 'file') {
          let fileList = key + '-fileList'
          formData.value.params[fileList] = []
          num++
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 文件上传
const uploadFile = function (file, key) {
  const reader = new FileReader();
  reader.readAsDataURL(file.raw);
  reader.onload = (e) => {
    formData.value.params[key] = reader.result.split(",")[1]
  };
}

let editDialogFormVisible = ref(false) // 编辑表单弹窗
let oldName = ref()
// 编辑设备点击
const editClick = function (row) {
  editDialogFormVisible.value = true
  title.value = "编辑设备"
  formData2.value.name = row.name
  oldName = row.name
}
// 编辑设备表单数据
let formData2 = ref({
  name: '',
})
// 编辑设备表单验证
let formRules2 = ref({
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'change',
  }],
})
let userForm2 = ref() // 提交编辑设备表单
//编辑的保存
const editSave = function () {
  var params = {
    name: oldName,
    new_name: formData2.value.name
  }
  userForm2.value.validate((valid) => {
    //提交成功
    if (valid) {
      nodeEditSave(params).then(res => {
        if (res.status == 200) {
          getNodeFun()
          editDialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}
// 删除设备
const delClick = function (name) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除组',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此组，删除后数据不可恢复'),
    ])
  }).then(() => {
    nodeDelete({
      name: name
    }).then(res => {
      if (res.status == 200) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getNodeFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}
const groupClick = function (node) {
  router.push({
    path: '/admin/group',
    query: {
      node: node
    }
  })
}
//复选框触发
let multipleSelection = ref([])
const handleSelectionChange = function (val) {
  multipleSelection.value = val;
}
//保留分页记忆
const getRowKeys = function (row) {
  return row.id
}

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
let nodeData = ref()
// 文件上传
const uploadFile2 = function (file) {
  const reader = new FileReader();
  reader.readAsText(file.raw);
  reader.onload = (e) => {
    if (e.target.result) {
      const jsonContent = JSON.parse(JSON.stringify(e.target.result));
      importClick(jsonContent)
    } else {
      ElMessage({
        type: 'warning',
        message: '文件解析错误，请上传有效的文件！',
      })
    }
  }
}
//导入
const importClick = function (nodeData) {
  templateImport({
    nodeData: nodeData,
  }).then(res => {
    if (res.status == 200) {
      ElMessage({
        type: 'success',
        message: '导入成功',
      })
      getNodeFun()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//导出
const exportClick = function () {
  let idList = []
  multipleSelection.value.forEach(item => {
    idList.push(item.name)
  })
  if (multipleSelection.value.length > 0) {
    var params = {
      category: 2,
      nodes: idList.join(','),
    }
    template(params).then(res => {
      if (res.status == 200) {
        //json数据下载为json文件
        // 将对象转为json字符串
        const jsonStr = JSON.stringify(res.data.nodes, null, 2)
        // 创建一个文本文件Blob对象
        const blob = new Blob([jsonStr], {
          type: 'application/json'
        })
        // 构造下载链接
        const url = URL.createObjectURL(blob)
        // 创建下载链接的a标签
        const a = document.createElement('a')
        a.href = url
        a.download = "_tmp_south_devices_" + new Date().getTime() + ".json"
        // 模拟点击下载链接
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        // 释放Blob占用的资源
        URL.revokeObjectURL(url)
        ElMessage({
          type: 'success',
          message: '导出成功',
        })
        getNodeFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条导出的数据!'
    })
  }
}
// 下载驱动日志
const downloadLogFun = function (scope) {
  const downloadZip = (data, fileName = '驱动日志') => {
    var blob = new Blob([data], {
      type: 'application/x-tar'
    })
    if ('download' in document.createElement('a')) {
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) //创建下载的链接
      downloadElement.href = href
      downloadElement.download = fileName //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    }
  }
  openFullScreen()
  downloadLogApi(scope.name).then(res => {
    loadingAdd.value.close()
    if (res.status === 200) {
      downloadZip(res.data)
      ElMessage({
        type: 'success',
        message: '下载成功',
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const deviceDetailClick = function (type, node, plugin) {
  router.push({
    path: '/admin/deviceDetail',
    query: {
      type: type,
      node: node,
      plugin: plugin
    }
  })
}
</script>

<style lang="less">
.moreOpera {
  width: 180px !important;
}

//.moreOpera .listPox {
//  position: fixed;
//  width: 148px;
//  z-index: 99999;
//  padding: 8px 16px;
//  background-color: #fff;
//  border-radius: 4px;
//  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
//
//  > p {
//    padding: 8px;
//    cursor: pointer;
//  }
//
//  .el-icon {
//    margin-right: 8px;
//  }
//
//}
//
//.moreOpera .copyClass {
//  width: 14px !important;
//  height: 14px !important;
//  margin-right: 10px;
//  margin-left: 1px !important;
//}
</style>