<template>
  <div class="sql">
    <el-form label-position="top" ref="userForm" :rules="rules" :inline="true" :model="deployGroup"
             label-width="auto">
      <el-form-item v-if="type.name" label="名称" prop="name">
        <el-input v-model="deployGroup.name" :disabled="store.state.sourceData['prohibit']"></el-input>
      </el-form-item>
      <el-form-item prop="url">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">数据库地址</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">数据库服务器的 URL，需要特别注意的是 URL 中的密码为 url 编码后的字符，并且编辑时需要重新输入密码。例如，mysql 的数据库地址为 mysql://username:password@127.0.0.1:3306/testdb?parseTime=true，若原始密码为 123:#?，则这里的 password 应填入 123%3A%23%3F</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.url"></el-input>
      </el-form-item>
      <div v-if="!type.name||!reveal">
        <el-form-item prop="interval">
          <template #label>
            <div style="display: flex;align-items: center;">
              <label for="">时间间隔（ms）</label>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
              >
                <img style="width: 16px;height: 16px;margin-left:5px"
                     src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                <template #content>
                  <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">发出消息的间隔，单位为 ms</span>
                  </div>
                </template>
              </el-tooltip>
            </div>
          </template>
          <div class="intervalNumber">
            <el-input-number v-model="deployGroup.interval"/>
          </div>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div style="display: flex;align-items: center;">
              <label for="">查询模板</label>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
              >
                <img style="width: 16px;height: 16px;margin-left:5px"
                     src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                <template #content>
                  <div class="max-tooltip">
                    <span style="color: #ffffff;margin-right: 8px">查询模版</span>
                  </div>
                </template>
              </el-tooltip>
            </div>
          </template>
          <div class="httpHeader">
            <el-table :data="tableData" style="width: 500px;min-height: 100px;">
              <el-table-column label="键" min-width="169">
                <template #default="scope">
                  <label :class="[scope.row.required?'label':'']" for="">{{ scope.row.name }}</label>
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      placement="top"
                  >
                    <img style="width: 16px;height: 16px;margin-left:5px"
                         src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                    <template #content>
                      <div class="max-tooltip">
                        <span style="color: #ffffff;margin-right: 8px">{{ scope.row.tips }}</span>
                      </div>
                    </template>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="值" min-width="169">
                <template #default="scope">
                  <el-form-item
                      :prop=" scope.$index===0?'TemplateSql':''"
                      :rules="{ required: true, message: '', trigger: 'blur' }"
                      v-if="scope.$index === 0 && scope.row.type!=='null'&&scope.row.type!=='select'"
                  >
                    <el-input
                        :type="scope.row.type"
                        :rows="3"
                        v-model="deployGroup.templateSqlQueryCfg[scope.row.name]"
                    ></el-input>
                  </el-form-item>
                  <el-input
                      v-else-if="scope.row.type!=='null'&&scope.row.type!=='select'"
                      :type="scope.row.type"
                      v-model="deployGroup.templateSqlQueryCfg[scope.row.name]"
                  ></el-input>
                  <el-select v-if="scope.row.type==='select'"
                             v-model="deployGroup.templateSqlQueryCfg[scope.row.name]"
                             placeholder="请选择"
                             clearable
                             size="large"
                             style="width: 100%!important;"
                  >
                    <el-option
                        v-for="item in fileType"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </div>
      <div v-if="!type.name||reveal">
        <el-form-item>
          <template #label>
            <div style="display: flex;align-items: center;">
              <label for="">查询表配置</label>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top"
              >
                <img style="width: 16px;height: 16px;margin-left:5px"
                     src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                <template #content>
                  <div class="max-tooltip">
                    <span style="color: #ffffff;margin-right: 8px">查询表配置，仅在作为查询表使用时生效</span>
                  </div>
                </template>
              </el-tooltip>
            </div>
          </template>
          <div class="httpHeader">
            <el-table :data="tableDeploy" style="width: 500px;min-height: 100px;">
              <el-table-column prop="name" label="键" min-width="169">
                <template #default="scope">
                  <label :class="[scope.row.required?'label':'']" for="">{{ scope.row.name }}</label>
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      placement="top"
                  >
                    <img style="width: 16px;height: 16px;margin-left:5px"
                         src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                    <template #content>
                      <div class="max-tooltip">
                        <span style="color: #ffffff;margin-right: 8px">{{ scope.row.tips }}</span>
                      </div>
                    </template>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="值" min-width="169">
                <template #default="scope">
                  <div class="inputNumber" v-if="scope.row.type!=='switch'">
                    <el-input-number v-model="deployGroup.lookup[scope.row.name]" controls-position="right"/>
                  </div>
                  <div class="switch" v-else>
                    <el-switch v-model="deployGroup.lookup[scope.row.name]" style="--el-switch-on-color: #ccefe3;"/>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {computed, defineExpose, defineProps, onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";

const userForm = ref(null);
const type = defineProps({
  componentType: {
    type: Object,
    default: () => {
      return {}
    }
  },
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }, name: {
    type: Boolean,
    default: () => {
      return true
    }
  }
})
const store = useStore();
let deployGroup = ref({
  "name": '',
  "url": '',
})
/*{"TemplateSql":"0","interval":1000,"url":"mysql://username:******@127.0.0.1:3306/testdb?parseTime=true","templateSqlQueryCfg":{"TemplateSql":"1","indexField":"2","indexFieldType":"DATETIME","indexValue":"232","dateTimeFormat":"123"}}*/
const tableData = ref([
  {
    name: 'TemplateSql',
    type: 'textarea',
    value: '',
    tips: 'SQL 语句模版',
    required: true,
  },
  {
    name: 'indexField',
    type: 'text',
    value: '',
    tips: '表的哪一列作为索引来记录偏移量'
  },
  {
    name: 'indexFieldType',
    type: 'select',
    value: '',
    tips: '索引字段的列类型'
  },
  {
    name: 'indexValue',
    type: 'null',
    value: '',
    tips: '初始索引值'
  },
  {
    name: 'dateTimeFormat',
    type: 'text',
    value: '',
    tips: '索引字段的时间格式'
  }
])
const rules = reactive({
  name: [{required: true, message: '请填写名称', trigger: 'blur'}],
  url: [{required: true, trigger: 'blur', message: ''}],
  interval: [{required: true, trigger: 'blur', message: ''}],
  TemplateSql: [{required: true, trigger: 'blur', message: ''}],
})
const tableDeploy = ref([
  {
    name: 'cache',
    type: 'switch',
    value: '',
    tips: '是否启用缓存',
  },
  {
    name: 'cacheTtl',
    type: 'inputNumber',
    value: '',
    tips: '缓存的生存时间，单位为 s',
  },
  {
    name: 'cacheMissingKey',
    type: 'switch',
    value: '',
    tips: '是否对空值进行缓存',
  },
])
const fileType = ['DATETIME', 'INT']
const reveal = computed(() => {
  return type.componentType === 'lookupTable'
})
//监听indexFieldType
watch(deployGroup, (newVal) => {
  if (newVal.templateSqlQueryCfg) {
    if (newVal.templateSqlQueryCfg.indexFieldType) {
      tableData.value[3].type = 'text'
    } else {
      tableData.value[3].type = 'null'
    }
  }
}, {deep: true});
// 表单校验
const getFormData = async () => {
  const isValid = await userForm.value.validate();
  if (isValid) {
    if (type.componentType !== 'lookupTable') {
      delete deployGroup.value.lookup
    }
    return deployGroup.value
  }
}
defineExpose({getFormData});
onMounted(() => {
  if (!type.name) {
    deployGroup.value = {
      ...deployGroup.value,
      "interval": 1000,
      "content": '',
      "templateSqlQueryCfg": {
        "TemplateSql": "",
        "indexField": "",
        "indexFieldType": "",
        "indexValue": "",
        "dateTimeFormat": ""
      },
      "lookup": {
        "cache": true,
        "cacheMissingKey": true,
        "cacheTtl": 600
      }
    }
  }
  if (type.componentType !== 'lookupTable') {
    deployGroup.value = {
      ...deployGroup.value,
      "interval": 1000,
      "content": '',
      "templateSqlQueryCfg": {
        "TemplateSql": "",
        "indexField": "",
        "indexFieldType": "",
        "indexValue": "",
        "dateTimeFormat": ""
      }
    }
  } else {
    deployGroup.value = {
      ...deployGroup.value,
      "lookup": {
        "cache": true,
        "cacheMissingKey": true,
        "cacheTtl": 600
      }
    }
  }
  deployGroup.value = {...deployGroup.value, ...type.data}
  // console.log(deployGroup.value)
})
</script>


<style scoped lang="less">
.max-tooltip {
  max-width: 280px !important;
}

.label {
  position: relative;
  margin-left: 10px;

  &:before {
    position: absolute;
    content: '*';
    color: var(--el-color-danger);
    top: 0;
    left: -10px;
  }
}

.intervalNumber {
  width: 180px;
}

.inputNumber {

  :deep(.el-input-number) {
    text-align: left !important;
    box-sizing: border-box;

    .el-input-number__increase {
      border-radius: 0 8px 0 0 !important;
      margin-right: 1px !important;
    }

    .el-input-number__decrease {
      margin-right: 1px !important;
      border-radius: 0 0 8px 0 !important;
    }
  }
}

.sql {
  @import "public";

  .httpHeader {
    width: 500px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #e2e7ea;

    :deep(.el-form-item) {
      width: auto !important;
      min-width: auto !important;
      padding: 10px 0;
      box-sizing: border-box;

      .el-form-item, .el-form-item__content {
        margin-right: 0 !important;
      }

      .el-textarea__inner {
        width: 100% !important;
        min-width: auto !important;
        box-sizing: border-box;
      }
    }
  }

  .switch {
    :deep(.is-checked) {
      .el-switch__core {
        border: 1px solid #00b173 !important;
      }

      .el-switch__action {
        background-color: #00b173 !important;
      }
    }
  }
}
</style>