<template>
  <!--设备管理页面-->
  <div class="devicePage">
    <div class="title">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleTabClick">
        <el-tab-pane :name="1" label="网关设备"></el-tab-pane>
        <el-tab-pane :name="3" label="直连设备"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="content">
      <div class="searchBox">
        <el-form>
          <div class="searchPox">
            <div v-if="activeName===1" class="liBox">
              <el-form-item label="产品型号">
                <el-input v-model="productModel" autocomplete="off" clearable placeholder='请输入产品型号'
                          type="text"></el-input>
              </el-form-item>
            </div>
            <div class="liBox">
              <el-form-item label="设备名称">
                <el-input v-model="name" autocomplete="off" clearable placeholder='请输入设备名称' type="text"
                          @change="nameChange"></el-input>
              </el-form-item>
            </div>
            <div v-if="activeName===1" class="liBox">
              <el-form-item label="通讯地址">
                <el-input v-model="address" autocomplete="off" clearable placeholder='请输入通讯地址' type="text"></el-input>
              </el-form-item>
            </div>
            <div v-if="activeName===1" class="liBox">
              <el-form-item label="设备状态">
                <el-select v-model="useStatus" clearable placeholder="请选择" style="width:100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="在线" value="1"></el-option>
                  <el-option label="离线" value="0"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="liBox searchBtn">
            <el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
            <el-button class="searchButton resetButton" @click="resetClick()">重置</el-button>
          </div>
        </el-form>
      </div>
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">
            新建设备
          </el-button>
          <div class="tabBox">
            <div :class="{ active: isActive=='列表' }" @click="tabClick('列表')">列表</div>
            <div :class="{ active: isActive=='卡片' }" @click="tabClick('卡片')">卡片</div>
          </div>
        </div>
        <!--卡片-->
        <div v-show="isActive=='卡片'&&activeName===1" class="cardBox">
          <div v-for="item in tableData" class="cardContent">
            <el-tooltip class="box-item" content="单击进入设备管理界面" effect="dark" placement="top">
              <div class="titleBox" @click="managementClick(item.id)"></div>
            </el-tooltip>
            <div class="topBox">
              <div class="leftBox">
                <div>{{ item.name }}</div>
                <div>{{ item.createTime }}</div>
              </div>
              <div class="rightBox">
                <div v-show="item.useStatus==1" class='statusOnClass'>
                  <div class="dotOnClass"></div>
                  {{ item.useStatusStr }}
                </div>
                <div v-show="item.useStatus==0" class='statusOutClass'>
                  <div class="dotOutClass"></div>
                  {{ item.useStatusStr }}
                </div>
              </div>
            </div>
            <div class="centerBox">
              <div class="leftBox">
                <div>产品型号</div>
                <div v-if="activeName===1">{{ item.productModel }}</div>
              </div>
              <div class="rigthBox">
                <div>协议类型</div>
                <div v-if="activeName===1">{{ item.protocolTypeStr ? item.protocolTypeStr : '698' }}</div>
                <div v-if="activeName===3">{{ item.productModel }}</div>
              </div>
            </div>
            <div class="centerBox">
              <div class="leftBox">
                <div>通讯地址</div>
                <div>{{ item.address }}</div>
              </div>
              <div class="rigthBox">
                <div>创建人</div>
                <div>{{ item.creatorName }}</div>
              </div>
            </div>
            <div class="bottomBox">
              <div @click="managementClick(item.id)">管理</div>
              <div @click="addClick(item.id)">编辑</div>
              <div @click="deleteClick(item.id)">删除</div>
            </div>
          </div>
        </div>
        <!--卡片-->
        <div v-show="isActive=='卡片'&&activeName===3" class="cardBox cardBox2">
          <div v-for="item in tableData" class="cardContent">
            <el-tooltip class="box-item" content="单击进入设备管理界面" effect="dark" placement="top">
              <div class="titleBox" @click="managementClick(item.id)"></div>
            </el-tooltip>
            <div class="topBox">
              <div class="leftBox">
                <div>{{ item.name }}</div>
                <div>{{ item.createTime }}</div>
              </div>
              <div class="rightBox">
                <div v-show="item.useStatus==1" class='statusOnClass'>
                  <div class="dotOnClass"></div>
                  {{ item.useStatusStr }}
                </div>
                <div v-show="item.useStatus==0" class='statusOutClass'>
                  <div class="dotOutClass"></div>
                  {{ item.useStatusStr }}
                </div>
              </div>
            </div>
            <div class="centerBox">
              <div class="leftBox">
                <div>连接状态</div>
                <div>{{ item.link === 1 ? '连接' : '断开' }}</div>
              </div>
              <div class="leftBox">
                <div>延时</div>
                <div>{{ item.rtt }}</div>
              </div>
              <div class="leftBox">
                <div>协议类型</div>
                <div>{{ item.productModel }}</div>
              </div>
              <div class="leftBox">
                <div>创建人</div>
                <div>{{ item.creatorName }}</div>
              </div>
            </div>
          </div>
        </div>
        <!--列表-->
        <el-table v-show="isActive=='列表'" ref="multipleTableRef" v-loading="loadingVal" :cell-style="cellStyle"
                  :data="tableData" style="width: 100%;margin-top: 20px;" @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建设备信息</p>
            </el-empty>
          </template>
          <el-table-column label="设备名称" min-width="150" property="name">
            <template #default="scope">
              <span v-if="scope.row.type===1" style="cursor: pointer;"
                    @click="managementClick(scope.row.id)">{{ scope.row.name }}</span>
              <span v-if="scope.row.type===3" style="cursor: pointer;"
                    @click="groupClick(scope.row.name)">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="activeName===1" label="通讯地址" min-width="160" property="address">
            <template #default="scope">
              <span>{{ scope.row.address }}</span>
              <img v-show="scope.row.address" alt="" class="adressClass" src="../../assets/img/编组@2x.png"/>
            </template>
          </el-table-column>
          <el-table-column label="设备类型" min-width="100" property="typeStr">
          </el-table-column>
          <el-table-column v-if="activeName===1" label="产品型号" min-width="160" property="productModel">
          </el-table-column>
          <el-table-column label="状态" min-width="100" property="useStatusStr">
            <template #default="scope">
              <div v-show="scope.row.useStatus==1" class='statusOnClass'>
                <div class="dotOnClass"></div>
                {{ scope.row.useStatusStr }}
              </div>
              <div v-show="scope.row.useStatus==0" class='statusOutClass'>
                <div class="dotOutClass"></div>
                {{ scope.row.useStatusStr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="协议类型" min-width="120" property="protocolTypeStr">
            <template #default="scope">
              <p v-if="scope.row.type === 1">{{ scope.row.protocolTypeStr ? scope.row.protocolTypeStr : '698' }}</p>
              <p v-if="scope.row.type === 3">{{ scope.row.productModel }}</p>
            </template>
          </el-table-column>
          <el-table-column label="创建人" min-width="100" property="creatorName">
            <template #default="scope">
              <img alt="" class="userClass" src="../../assets/img/user.png"/>
              <sapn>{{ scope.row.creatorName }}</sapn>
            </template>
          </el-table-column>
          <el-table-column label="是否启用" min-width="80" property="enableFlagStr" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="创建时间" min-width="170" property="createTime" sortable>
          </el-table-column>
          <el-table-column label="备注" min-width="150" property="remark" show-overflow-tooltip>
          </el-table-column>
          <el-table-column :width="activeName===1?150:210" fixed="right" label="操作">
            <template #default="scope">
              <ul v-if="scope.row.type===3" class="operationUl">
                <li>
                  <el-tooltip :content="scope.row.switch?'停止':'启动'" class="box-item" effect="dark">
                    <el-switch :value="scope.row.switch" style="--el-switch-on-color: #ccefe3;"
                               @change="isOpenChange($event,scope.row)"/>
                  </el-tooltip>
                </li>
                <!--                <li>-->
                <!--                  <el-tooltip class="box-item" content="组列表" effect="dark">-->
                <!--                    <img alt="" class="groupClass" src="../../assets/img/group.svg"-->
                <!--                         @click="groupClick(scope.row.name)"/>-->
                <!--                  </el-tooltip>-->
                <!--                </li>-->
                <li class="editClass" @click="editClick(scope.row)">编辑</li>
                <li class="deleteClass" @click="delClick(scope.row.name,scope.row.id)">删除</li>
                <li>
                  <el-popover popper-class="moreOpera" trigger="click">
                    <div class="listPox">
                      <!--                      <p @click="editClick(scope.row)">-->
                      <!--                        <el-icon>-->
                      <!--                          <EditPen/>-->
                      <!--                        </el-icon>-->
                      <!--                        <span>编辑设备</span>-->
                      <!--                      </p>-->
                      <!--                      <p @click='deviceDetailClick(1,scope.row.name,scope.row.plugin2,scope.row.id)'>-->
                      <!--                        <el-icon>-->
                      <!--                          <DataAnalysis/>-->
                      <!--                        </el-icon>-->
                      <!--                        <span>数据统计</span>-->
                      <!--                      </p>-->
                      <p @click='deviceDetailClick(2,scope.row.name,scope.row.plugin2,scope.row.id)'>
                        <el-icon>
                          <Setting/>
                        </el-icon>
                        <span>设备配置</span>
                      </p>
                      <!--                      <p @click="debugClick(scope.row.log_level,scope.row.name)">-->
                      <!--                        <el-icon>-->
                      <!--                          <Memo/>-->
                      <!--                        </el-icon>-->
                      <!--                        <span>{{ scope.row.log_level == 'notice' ? '开启' : '关闭' }}DEBUG日志</span>-->
                      <!--                      </p>-->
                      <!--                      <p @click="copyClick(scope.row.name)">-->
                      <!--                        <img alt="" class="copyClass" src="../../assets/img/copy.svg"/>-->
                      <!--                        <span>复制</span>-->
                      <!--                      </p>-->
                      <!--                      <p @click="downloadLogFun(scope.row)">-->
                      <!--                        <el-icon>-->
                      <!--                          <Download/>-->
                      <!--                        </el-icon>-->
                      <!--                        <span>下载驱动日志</span>-->
                      <!--                      </p>-->
                      <!--                      <p @click="delClick(scope.row.name)">-->
                      <!--                        <el-icon>-->
                      <!--                          <Delete/>-->
                      <!--                        </el-icon>-->
                      <!--                        <span>删除</span>-->
                      <!--                      </p>-->
                    </div>
                    <template #reference>
                      <p class="more">
                        <el-tooltip class="box-item" content="更多" effect="dark">
                          <el-icon class="more">
                            <MoreFilled/>
                          </el-icon>
                        </el-tooltip>
                      </p>
                    </template>
                  </el-popover>
                </li>
              </ul>
              <!--              <li @click="switchClick">换表</li>-->
              <ul v-if="scope.row.type===1" class="operationUl">
                <li class="editClass" @click="managementClick(scope.row.id)">管理</li>
                <li class="editClass" @click="addClick(scope.row.id)">编辑
                </li>
                <li class="deleteClass" @click="deleteClick(scope.row.id)">删除
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :current-page="pageNumber" :disabled="disabled" :page-size="pageSize"
                         :page-sizes="[20,100, 200, 300, 400]" :small="small" :total="total" background
                         layout="total, sizes,prev, pager, next " small @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!--设备类型弹窗-->
    <el-dialog v-model="typeDialogVisible" :close-on-click-modal="false" :title="title" class="typeDialogVisible"
               width="544px">
      <div class="selectPox">
        <!--        <el-radio-group v-model="deviceType">-->
        <!--          <el-radio-button label="网关设备" value="网关设备"/>-->
        <!--          <el-radio-button label="直连设备" value="直连设备"/>-->
        <!--        </el-radio-group>-->
        <div :class="deviceType==='网关设备'?'activePox pox':'pox'" @click="deviceType='网关设备'">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/deviceSelect1.png">
          <p>网关设备</p>
        </div>
        <div :class="deviceType==='直连设备'?'activePox pox':'pox'" @click="deviceType='直连设备'">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/deviceSelect2.png">
          <p>直连设备</p>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="typeDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="deviceTypeFun">确认</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--新增设备弹窗-->
    <el-dialog v-model="deviceDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div>
        <el-form ref="deviceFormRef" :model="deviceForm" :rules="deviceRules" class="demo-ruleForm" label-width="auto"
                 style="width:98%">
          <div style="display: flex;">
            <div style="flex:1;margin-right: 30px;">
              <el-form-item label="产品类型" prop="categoryId">
                <el-select v-model="deviceForm.categoryId" clearable placeholder="请选择产品类型" style="width:100%"
                           @change="categoryChange(deviceForm.categoryId)">
                  <el-option v-for="item in allCategoryList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="categoryName=='电表'" label="电网费率" prop="powerGridRateId">
                <el-select v-model="deviceForm.powerGridRateId" clearable placeholder="请选择电网费率" style="width:100%">
                  <el-option v-for="item in powerGridRateData" :key="item.id" :label="item.versionNumber"
                             :value="item.id" style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="设备名称">
                <el-input v-model="deviceForm.name" placeholder="请输入设备名称"/>
              </el-form-item>
              <el-form-item label="通讯地址" prop="address">
                <el-input v-model="deviceForm.address" placeholder="请输入通讯地址" @change="addressChange"/>
              </el-form-item>
              <el-form-item label="用户类型" prop="userType">
                <el-input v-model="deviceForm.userType" placeholder="请输入用户类型"/>
              </el-form-item>
              <el-form-item label="端口" prop="port">
                <el-select v-model="deviceForm.port" clearable placeholder="请选择端口" style="width:100%">
                  <el-option v-for="item in portList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="电流变比" prop="rateA">
                <el-input v-model="deviceForm.rateA" placeholder="请输入电流变比" type="number"/>
              </el-form-item>

              <el-form-item label="是否启用" prop="enableFlag">
                <el-select v-model="deviceForm.enableFlag" clearable filterable placeholder="请选择是否启用"
                           style="width:100%">
                  <el-option v-for="item in enableList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="flex:1;">
              <el-form-item label="产品型号" prop="productId">
                <el-select v-model="deviceForm.productId" clearable placeholder="请选择产品型号" style="width:100%">
                  <el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="网关" prop="gatewayId">
                <el-select v-model="deviceForm.gatewayId" clearable filterable placeholder="请选择网关" style="width:100%">
                  <el-option v-for="item in gatewayList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="规约类型" prop="rulerType">
                <el-select v-model="deviceForm.rulerType" clearable placeholder="请选择规约类型" style="width:100%">
                  <el-option v-for="item in rulerTypeList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="波特率" prop="rateType">
                <el-select v-model="deviceForm.rateType" clearable placeholder="请选择波特率" style="width:100%">
                  <el-option v-for="item in rateTypeList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="电压变比" prop="rateV">
                <el-input v-model="deviceForm.rateV" placeholder="请输入电压变比" type="number"/>
              </el-form-item>
              <el-form-item label="备注">
                <el-input v-model="deviceForm.remark" placeholder="请输入备注"/>
              </el-form-item>
              <!--<el-form-item label="关联设备名称">
                <el-input v-model="deviceForm.relationName" placeholder="请输入关联设备名称" />
              </el-form-item>-->
            </div>
          </div>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="deviceDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--换表弹窗-->
    <el-dialog v-model="switchDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div>
        <el-form ref="switchFormRef" :model="switchForm" :rules="switchRules" class="demo-ruleForm" style="width:98%">
          <el-form-item label="产品类型" prop="categoryId">
            <el-select v-model="switchForm.categoryId" clearable placeholder="请选择产品类型" style="width:100%"
                       @change="categoryChange">
              <el-option v-for="item in allCategoryList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品型号" prop="productId">
            <el-select v-model="switchForm.productId" clearable placeholder="请选择产品型号" style="width:100%">
              <el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通讯地址" prop="address">
            <el-input v-model="switchForm.address" placeholder="请输入通讯地址" @change="addressChange"/>
          </el-form-item>
          <el-form-item label="更换设备的起始读数" prop="">
          </el-form-item>
          <el-form-item label="尖" prop="address">
            <el-input v-model="switchForm.address" placeholder="请输入" @change="addressChange"/>
          </el-form-item>
          <el-form-item label="峰" prop="address">
            <el-input v-model="switchForm.address" placeholder="请输入" @change="addressChange"/>
          </el-form-item>
          <el-form-item label="平" prop="address">
            <el-input v-model="switchForm.address" placeholder="请输入" @change="addressChange"/>
          </el-form-item>
          <el-form-item label="谷" prop="address">
            <el-input v-model="switchForm.address" placeholder="请输入" @change="addressChange"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="deviceDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>


    <!--直连设备操作弹窗-->
    <!--添加弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="title" class="dialogFormVisible"
               width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="名称" prop="name" style="margin-bottom: 20px">
          <el-input v-model="formData.name" placeholder="请输入应用名称"></el-input>
        </el-form-item>
        <el-form-item label="插件" prop="plugin">
          <el-select v-model="formData.plugin" clearable filterable placeholder="请选择插件" @change="selectChange">
            <el-option v-for="(item,index) in pluginData" :key="index" :label="item.name==='eKuiper'?'ZKData':item.name"
                       :value="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="formData.params" label-width="auto">
        <!--接口请求循环出来的表单数据-->
        <el-form-item v-for="(value, key, index) in formObj" :key="index" :prop="key"
                      :rules="[{
                        required: value.attribute==='required'&&(!value.condition||formData.params[value.condition.field]===value.condition.value),
                        message: `${value.type==='string'||value.type==='int'?'请输入':'请选择'}${value.name_zh}`,
                        trigger: 'blur',
                      }]" class="formItem">
          <div v-if="(!value.condition||formData.params[value.condition.field]===value.condition.value)&&value.name_zh"
               slot="label"
               class="labelBox" style="margin-top: 20px">
            <i v-if="value.attribute==='required'" style="color: red;">*</i>
            <p style="margin-left: 5px">{{ value.name_zh }}</p>
            <el-tooltip :content="value.description_zh" effect="dark" placement="top" popper-class="el_tooltip_item">
              <el-icon>
                <QuestionFilled/>
              </el-icon>
            </el-tooltip>
          </div>
          <div class="widthBox">
            <!--输入框-->
            <el-input
                v-if="value.type==='string'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" :maxlength="value.valid&&value.valid.length"></el-input>
            <!--下拉框-->
            <el-select
                v-if="value.type==='map'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" clearable>
              <el-option v-for="(item,index) in value.valid.map" :key="index" :label="item.key"
                         :value="item.value"></el-option>
            </el-select>
            <!--单选框-->
            <el-radio-group
                v-if="value.type==='bool'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]">
              <el-radio :label="true" border>True</el-radio>
              <el-radio :label="false" border>False</el-radio>
            </el-radio-group>
            <!--数字输入框-->
            <el-input-number
                v-if="value.type==='int'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" :controls="false" :max="value.valid.max" :min="value.valid.min"/>
            <!--上传文件-->
            <el-upload
                v-if="value.type==='file'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model:file-list="formData.params[key+'-fileList']" :auto-upload="false" action="#" class="upload-demo"
                limit="1" @change="uploadFile($event,key)">
              <div class="uploadBox">
                <div :class="formData.params[key+'-fileList']&&formData.params[key+'-fileList'].length>0?'none':''"
                     class="upBtnBox">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <span>点击上传</span>
                </div>
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--编辑弹窗-->
    <el-dialog v-model="editDialogFormVisible" :close-on-click-modal="false" :title="title" width="40%">
      <el-form ref="userForm2" :inline="true" :model="formData2" :rules="formRules2" label-width="auto">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formData2.name" placeholder="请输入设备名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="editDialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="editSave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--复制弹窗-->
    <el-dialog v-model="copyeditDialogFormVisible" :close-on-click-modal="false" title="驱动复制" width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="名称" prop="copyName">
          <el-input v-model="formData.copyName" placeholder="请输入驱动名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="copyeditDialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="copySave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {
  ArrowUpBold,
  ArrowDownBold,
  Plus,
  Search,
  CollectionTag,
  EditPen,
  DataAnalysis,
  Setting,
  Memo,
  Download,
  MoreFilled,
  Delete
} from '@element-plus/icons-vue'
import {
  getList,
  getAllCategory,
  getProductList,
  getSelectList,
  getGatewayList,
  getDetail,
  saveList,
  deleteList,
  powerGridRateList
} from '../../api/modules/device'
import {
  getNode,
  getPlugin,
  getState,
  ctl,
  driversList,
  driversControl,
  nodeDelete,
  levelControl,
  nodeEditSave,
  template,
  templateImport,
  saveSouthEquipmentApi
} from "@/api/modules/southDriver";
import {
  downloadLogApi,
  addDataApi,
  getFormDataApi,
} from "@/api/modules/northDriver";
import './device.less'
import qs from 'qs';

let router = useRouter()
let route = useRouter()
/*生命周期*/
onMounted(async () => {
  if (router.currentRoute.value.query.pn) {
    pageNumber.value = Number(router.currentRoute.value.query.pn)
  }
  name.value = window.localStorage.getItem('name')
  await getListFun()
  getAllCategoryFun()
  getSelectListFun()
  getGatewayListFun()
  powerGridRateListFun()
  await getPluginFun()

  if (router.currentRoute.value.query.type == 3) {
    activeName.value = 3
  }
  const query = {...route.query};
  // 使用新的查询参数替换当前路由
  router.replace({query});
})


// 选择网关设备或直连设备
let activeName = ref(1)
const handleTabClick = async function (tab, event) {
  pageNumber.value = 1
  pageSize.value = 20
  productModel.value = ''
  name.value = ''
  address.value = ''
  useStatus.value = ''
  await getListFun()
  await getPluginFun()
}


//设备名称的改变
const nameChange = function () {
  window.localStorage.setItem('name', name.value)
}
let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//重置
const resetClick = function () {
  pageNumber.value = 1
  pageSize.value = 20
  productModel.value = ''
  name.value = ''
  address.value = ''
  useStatus.value = ''
  getListFun()
}
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
let productModel = ref() //产品线型号
let name = ref() //设备名称
let address = ref() //通讯地址
let useStatus = ref('') //状态
//获取设备列表
const getListFun = async function () {
  loadingVal.value = true
  let params = (activeName.value === 1) ? {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    name: name.value,
    address: address.value,
    productModel: productModel.value,
    useStatus: useStatus.value,
    equipmentType: activeName.value,
  } : {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    name: name.value,
    equipmentType: activeName.value,
  }

  await getList(qs.stringify(params)).then(res => {
    loadingVal.value = false
    //console.log(res);
    if (res.data.ok) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
      getStateFun()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let allCategoryList = ref([]) //所有分类列表
//所有分类列表
const getAllCategoryFun = function () {
  getAllCategory(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      allCategoryList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let productList = ref([]) //产品列表
//产品列表
const getProductListFun = function () {
  getProductList(qs.stringify({
    categoryId: deviceForm.value.categoryId
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      productList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let powerGridRateData = ref() //费率配置列表
const powerGridRateListFun = function () {
  powerGridRateList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      powerGridRateData.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let portList = ref([])
let rateTypeList = ref([])
let rulerTypeList = ref([])
let enableList = ref([{
  id: 1,
  name: '是'
}, {
  id: 0,
  name: '否'
}])
const getSelectListFun = function () {
  getSelectList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      portList.value = res.data.result.portList
      rateTypeList.value = res.data.result.rateTypeList
      rulerTypeList.value = res.data.result.rulerTypeList
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let gatewayList = ref([]) //网关列表
const getGatewayListFun = function () {
  getGatewayList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      gatewayList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let categoryName = ref()
//分类改变
const categoryChange = function (id) {
  if (deviceForm.value.categoryId) {
    getProductListFun()
  }
  allCategoryList.value.forEach(item => {
    if (item.id == id) {
      categoryName.value = item.name
      if (item.name != '电表') {
        deviceForm.value.powerGridRateId = ''
      }
    }
  })
  deviceForm.value.productId = ''
}

let dialogWidth = ref('60%') //新增设备弹窗宽度
let deviceDialogVisible = ref(false) //新增设备弹窗开关
let title = ref() //弹窗标题
let editId = ref() //编辑id
//新增设备的点击
const addClick = function (id) {
  if (id) {
    editId.value = id
    title.value = '编辑设备'
    getDetail(qs.stringify({
      id: id
    })).then(res => {
      //console.log(res);
      if (res.data.ok) {
        deviceForm.value.categoryId = res.data.result.categoryId
        categoryChange(res.data.result.categoryId)
        deviceForm.value.powerGridRateId = res.data.result.powerGridRateId
        deviceForm.value.productId = res.data.result.productId
        deviceForm.value.gatewayId = res.data.result.gatewayId
        deviceForm.value.name = res.data.result.name
        deviceForm.value.address = res.data.result.address
        deviceForm.value.enableFlag = res.data.result.enableFlag
        deviceForm.value.rulerType = String(res.data.result.rulerType)
        deviceForm.value.userType = res.data.result.userType
        deviceForm.value.rateType = String(res.data.result.rateType)
        deviceForm.value.port = String(res.data.result.port)
        deviceForm.value.rateV = res.data.result.rateV
        deviceForm.value.rateA = res.data.result.rateA
        deviceForm.value.remark = res.data.result.remark
        deviceForm.value.relationName = res.data.result.relationName
        getProductListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
    deviceDialogVisible.value = true
  } else {
    typeDialogVisible.value = true
    title.value = '设备类型'
  }
}
const deviceTypeFun = function () {
  if (deviceType.value == '网关设备') {
    editId.value = ''
    deviceForm.value = {
      categoryId: '',
      powerGridRateId: '',
      productId: '',
      gatewayId: '',
      name: '',
      address: '',
      enableFlag: 1,
      rulerType: '',
      userType: '1',
      rateType: '',
      port: '',
      rateV: 1,
      rateA: 1,
      remark: '',
      relationName: ''
    }
    deviceDialogVisible.value = true
    typeDialogVisible.value = false
  } else {
    // 判断是直连设备
    formData.value = {
      name: '',
      plugin: '',
      params: {}
    }
    dialogFormVisible.value = true
    typeDialogVisible.value = false


    // router.push({
    //   path: '/admin/southDriver',
    //   query: {
    //     ty: 1
    //   }
    // })
  }
}
let deviceFormRef = ref() //设备
//设备表单
let deviceForm = ref({
  categoryId: '',
  powerGridRateId: '',
  productId: '',
  gatewayId: '',
  name: '',
  address: '',
  enableFlag: 1,
  rulerType: '',
  userType: '1',
  rateType: '',
  port: '',
  rateV: 1,
  rateA: 1,
  remark: '',
  relationName: ''
})
// 设备表单验证规则
let deviceRules = ref({
  categoryId: [{
    required: true,
    message: '请选择产品类型',
    trigger: 'blur'
  }],
  powerGridRateId: [{
    required: true,
    message: '请选择电网费率',
    trigger: 'blur'
  }],
  productId: [{
    required: true,
    message: '请选择产品型号',
    trigger: 'blur'
  }],
  gatewayId: [{
    required: true,
    message: '请选择网关',
    trigger: 'blur'
  }],
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'blur'
  }],
  address: [{
    required: true,
    message: '请输入通讯地址',
    trigger: 'blur'
  }],
  port: [{
    required: true,
    message: '请选择端口',
    trigger: 'blur'
  }],
  rateV: [{
    required: true,
    message: '请输入电压变比',
    trigger: 'blur'
  }],
  rateA: [{
    required: true,
    message: '请输入电流变比',
    trigger: 'blur'
  }],
})
//设备的保存
const deviceSave = function () {
  let params = {
    productId: deviceForm.value.productId,
    gatewayId: deviceForm.value.gatewayId,
    powerGridRateId: categoryName.value == '电表' ? deviceForm.value.powerGridRateId : null,
    name: deviceForm.value.name,
    address: deviceForm.value.address,
    enableFlag: deviceForm.value.enableFlag,
    rulerType: deviceForm.value.rulerType,
    userType: deviceForm.value.userType,
    rateType: deviceForm.value.rateType,
    port: deviceForm.value.port,
    rateV: deviceForm.value.rateV,
    rateA: deviceForm.value.rateA,
    remark: deviceForm.value.remark,
    relationName: deviceForm.value.relationName,
    type: 1
  }
  if (title.value == '新建设备') {
    params['id'] = 0
  } else if (title.value == '编辑设备') {
    params['id'] = editId.value
  }
  if (deviceFormRef) {
    deviceFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (deviceForm.value.name) {
          saveList(
              qs.stringify(params)
          ).then(async res => {
            //console.log(res);
            if (res.data.ok) {
              deviceDialogVisible.value = false
              getListFun()
            } else {
              ElMessage({
                message: res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            message: '请输入设备名称',
            type: 'info'
          })
        }
      }
    })
  }
}
//删除
const deleteClick = function (id) {

  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除设备',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
//通讯地址改变
const addressChange = function () {
  deviceForm.value.address = deviceForm.value.address.padStart(12, '0')
}
//管理
const managementClick = function (id) {
  router.push({
    path: '/admin/deviceManagement',
    query: {
      id: id,
      pn: pageNumber.value,
      ty: 1
    }
  })
}
//换表*************************************************
let switchDialogVisible = ref(false) //换表弹窗开关
let switchFormRef = ref() //换表
//换表表单
let switchForm = ref({
  categoryId: '',
  productId: '',
  address: '',
})
// 换表表单验证规则
let switchRules = ref({
  categoryId: [{
    required: true,
    message: '请选择产品类型',
    trigger: 'blur'
  }],
  productId: [{
    required: true,
    message: '请选择产品型号',
    trigger: 'blur'
  }]
})
const switchClick = function () {
  switchDialogVisible.value = true
  title.value = '换表'
}
const cellStyle = ({
                     row,
                     column,
                     rowIndex,
                     columnIndex
                   }) => {
  if (columnIndex === 0) {
    return {
      color: "#165DFF"
    };
  }
}
/*切换卡片*/
let isActive = ref('列表')
const tabClick = function (val) {
  isActive.value = val
}


/*直连设备****************************************************************************/
// 新建直连设备弹窗
let dialogFormVisible = ref(false)
//获取插件列表
let pluginData = ref([])
const getPluginFun = function () {
  getPlugin(null).then(res => {
    if (res.status == 200) {
      pluginData.value = res.data.plugins
      tableData.value.forEach(item => {
        pluginData.value.forEach(items => {
          if (item.productModel == items.name) {
            item.plugin2 = items.schema
          }
        })
      })
    } else {
      pluginData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 添加设备表单数据----------------------------------------------
let formData = ref({
  name: '',
  plugin: '',
  params: {}
})
// 添加设备表单验证
let formRules = ref({
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'change',
  }],
  plugin: [{
    required: true,
    message: '请选择插件',
    trigger: 'blur'
  }],
  copyName: [{
    required: true,
    message: '请输入驱动名称',
    trigger: 'change',
  }],
})
let userForm = ref() // 提交添加设备表单
// 添加设备的保存
const save = function () {
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      saveSouthEquipmentApi(qs.stringify({
        id: 0,
        name: formData.value.name,
        plugin: formData.value.plugin,
        params: JSON.stringify(formData.value.params),
        authorization: 'Bearer ' + window.localStorage.getItem('token')
      })).then(res => {
        if (res.data.code === 0) {
          dialogFormVisible.value = false
          typeDialogVisible.value = false
          getListFun()
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}
// 直连设备的列表详情
//获取设备列表
const getStateFun = function () {
  getState(null).then(res => {
    if (res.status == 200) {
      tableData.value.forEach(item => {
        res.data.states.forEach(items => {
          if (item.name == items.node) {
            item.link = items.link
            item.running = items.running
            item.rtt = items.rtt
            if (items.running == 3) {
              item.switch = true
            } else {
              item.switch = false
            }
            item.log_level = items.log_level
          }
        })
      })
      console.log(tableData.value)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 插件选择事件
let formObj = ref({})
const selectChange = function () {
  var schema_name;
  pluginData.value.forEach(items => {
    if (formData.value.plugin == items.name) {
      schema_name = items.schema
    }
  })
  getFormDataApi({
    schema_name: schema_name
  }).then(res => {
    if (res.status === 200) {
      formData.value = {
        name: formData.value.name,
        plugin: formData.value.plugin,
        params: {}
      }
      formObj.value = res.data
      console.log(formObj.value)
      let num = 0
      for (const key in formObj.value) {
        formData.value.params[key] = formObj.value[key].default
        if (formObj.value[key].type === 'file') {
          let fileList = key + '-fileList'
          formData.value.params[fileList] = []
          num++
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 设备连接断开----------------------------------------------
// 应用连接断开
let loading = ref(false)
const isOpenChange = function (value, scope) {
  ctl({
    cmd: scope.running === 3 ? 1 : 0,
    node: scope.name,
  }).then(res => {
    loading.value = false
    if (res.status === 200) {
      getListFun()
      ElMessage({
        message: scope.running === 3 ? '停止成功' : '运行成功',
        type: 'success'
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
  })
}

//关闭或开启debug日志----------------------------------------------
const debugClick = function (log_level, name) {
  var level;
  if (log_level == 'debug') {
    level = 'notice'
  } else {
    level = 'debug'
  }
  var params = {
    core: false,
    level: level,
    node: name
  }
  levelControl(params).then(res => {
    if (res.status == 200) {
      getListFun()
      copyeditDialogFormVisible.value = false
      if (log_level == 'debug') {
        ElMessage({
          message: '已关闭DEBUG日志！',
          type: 'success'
        })
      } else if (log_level == 'notice') {
        ElMessage({
          message: '已开启DEBUG日志！',
          type: 'success'
        })
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//组的点击
const groupClick = function (node) {
  router.push({
    path: '/admin/group',
    query: {
      node: node
    }
  })
}
let typeDialogVisible = ref(false)
let deviceType = ref("网关设备")
let editDialogFormVisible = ref(false) // 编辑表单弹窗
let oldName = ref()
// 编辑设备点击----------------------------------------------
const editClick = function (row) {
  editDialogFormVisible.value = true
  title.value = "编辑设备"
  formData2.value = {
    id: row.id,
    name: row.name,
    authorization: 'Bearer ' + window.localStorage.getItem('token')
  }
  oldName = row.name
}
// 编辑设备表单数据
let formData2 = ref({
  id: 0,
  name: '',
  authorization: 'Bearer ' + window.localStorage.getItem('token')
})
// 编辑设备表单验证
let formRules2 = ref({
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'change',
  }],
})
let userForm2 = ref() // 提交编辑设备表单
//编辑的保存
const editSave = function () {
  var params = {
    name: oldName,
    new_name: formData2.value.name
  }
  userForm2.value.validate((valid) => {
    //提交成功
    if (valid) {
      saveSouthEquipmentApi(qs.stringify(formData2.value)).then(res => {
        if (res.data.code == 0) {
          getListFun()
          editDialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}
//数据统计/数据配置----------------------------------------------
const deviceDetailClick = function (type, node, plugin, id) {
  console.log(plugin)
  router.push({
    path: '/admin/deviceDetail',
    query: {
      type: type,
      node: node,
      plugin: plugin,
      id: id,
    }
  })
}
let driversData = ref([])
// let formData = ref({})
//复制弹窗的点击----------------------------------------------
let copyeditDialogFormVisible = ref(false)
const copyClick = function (name) {
  formData.value.copyName = name + '_copy'
  copyeditDialogFormVisible.value = true
  driversList({
    name: name
  }).then(res => {
    if (res.status == 200) {
      driversData.value = res.data
      driversData.value.nodes[0].name = formData.value.copyName
    } else {
      driversData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//复制弹窗的保存
const copySave = function () {
  driversControl(driversData.value).then(res => {
    if (res.status == 200) {
      getListFun()
      copyeditDialogFormVisible.value = false
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 下载驱动日志
const downloadLogFun = function (scope) {
  const downloadZip = (data, fileName = '驱动日志') => {
    var blob = new Blob([data], {
      type: 'application/x-tar'
    })
    if ('download' in document.createElement('a')) {
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) //创建下载的链接
      downloadElement.href = href
      downloadElement.download = fileName //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    }
  }
  openFullScreen()
  downloadLogApi(scope.name).then(res => {
    loadingAdd.value.close()
    if (res.status === 200) {
      downloadZip(res.data)
      ElMessage({
        type: 'success',
        message: '下载成功',
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 删除设备
const delClick = function (name, id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除组',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此组，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
        nodeDelete({
          name: name
        }).then(res => {
          if (res.status == 200) {
            ElMessage({
              type: 'success',
              message: '删除成功',
            })
            getListFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}
</script>