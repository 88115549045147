<template>
  <div class="customized">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="goBack">项目管理</span>
        <span> / </span>
        <span>定制项目仪表盘</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        定制项目仪表盘
      </p>
    </div>
    <div class="content">
      <div class="addHead">
        <el-button type="primary" @click="addChat(1)">橱窗组件</el-button>
        <el-button type="primary" @click="addChat(2)">图表组件</el-button>
        <el-button type="primary" @click="addChat(3)">复合查询组件</el-button>
      </div>
      <div class="cont">
        <el-empty
            v-if="(detailData.queryList&&detailData.queryList.length===0)&&(detailData.paramList&&detailData.paramList.length===0)"
            class="emptyClass" description="暂无数据信息"
            image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
        <div v-show="detailData.paramList&&detailData.paramList.length!==0" class="boxCont">
          <div v-for="(item,index) in detailData.paramList" :key="index" class="itemBox">
            <p class="tit">{{ item.name }}</p>
            <p class="val">{{ item.value }}</p>
            <el-icon class="close" @click="delCustom(item.id)">
              <CloseBold/>
            </el-icon>
            <img alt="" src="../../../assets/img/401.svg">
          </div>
        </div>
        <div v-show="detailData.queryList&&detailData.queryList.length!==0" class="chatCont">
          <div class="projectName">数字能源中心</div>
          <div v-for="(item,index) in detailData.queryList" :key="index" class="itemCont">
            <div class="close">
              <el-icon class="close" style="cursor: pointer" @click="delChat(item.queryId)">
                <CloseBold/>
              </el-icon>
            </div>
            <el-empty v-if="item.chartData.xAxis.length===0"
                      class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
            <!--图表-->
            <p v-show="item.chartData.xAxis.length!==0" style="text-align: center;font-size: 16px;margin:10px auto;">
              {{ item.queryName }}</p>
            <div v-show="item.chartData.xAxis.length!==0" :class="'chat'+index"
                 style="width: 100%;height: 400px;"></div>
          </div>
        </div>
      </div>
    </div>

    <!--定制组件弹窗-->
    <el-dialog
        v-model="chatDialog"
        :title="titleName"
        width="522"
    >
      <el-form
          ref="roleFormRef"
          :model="chatForm"
          class="roleFormRef"
          status-icon
      >
        <el-form-item v-show="showFlag===1" label="参数名称" prop="name">
          <el-select v-model="chatForm.name" placeholder="请输入参数名称">
            <el-option v-for="(item,index) in paramsList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item v-show="showFlag===2" label="查询方案" prop="name">
          <el-select v-model="chatForm.plan" placeholder="请输入查询方案">
            <el-option v-for="(item,index) in planList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item v-show="showFlag===3" label="复合查询方案" prop="name">
          <el-select v-model="chatForm.plan" placeholder="请输入复合查询方案">
            <el-option v-for="(item,index) in planList2" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="resetButton" @click="chatDialog = false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref} from "vue"
import './customized.less'
import {Back, CirclePlus, CloseBold} from '@element-plus/icons-vue'
import {useRouter} from 'vue-router';
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {
  getCustomDetailApi,
  saveCustomDetailApi,
  getCustomSelectApi,
  getChatSelectApi
} from "@/api/modules/project";
import qs from "qs";
import * as echarts from "echarts";

let router = useRouter()


// 返回
const goBack = function () {
  router.push('/admin/projectLocal')
}

// 获取下拉及详情------------------------------------------------------------------------
// 获取参数列表
let paramsList = ref([])
const getParamsFun = function () {
  getCustomSelectApi(qs.stringify({
    projectId: router.currentRoute.value.query.projectId
  })).then(res => {
    if (res.data.code === 0) {
      paramsList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取方案列表
let planList = ref([])
let planList2 = ref([])
const getPlanFun = async function () {
  await getChatSelectApi(qs.stringify({
    projectId: router.currentRoute.value.query.projectId,
    queryType: 0
  })).then(res => {
    if (res.data.code === 0) {
      planList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  await getChatSelectApi(qs.stringify({
    projectId: router.currentRoute.value.query.projectId,
    queryType: 1
  })).then(res => {
    if (res.data.code === 0) {
      planList2.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取仪表盘详情
let detailData = ref({})
const getDetailFun = function () {
  openFullScreen()
  getCustomDetailApi(qs.stringify({
    projectId: router.currentRoute.value.query.projectId
  })).then(res => {
    loadingAdd.value.close();
    if (res.data.code === 0) {
      detailData.value = res.data.result
      dataArr.value = {
        queryIdList: [],
        paramIdList: []
      }
      for (let i = 0; i < res.data.result.queryList.length; i++) {
        dataArr.value.queryIdList.push(res.data.result.queryList[i].queryId)
      }
      for (let i = 0; i < res.data.result.paramList.length; i++) {
        dataArr.value.paramIdList.push(res.data.result.paramList[i].id)
      }
      // 处理图表数据
      for (let i = 0; i < res.data.result.queryList.length; i++) {
        let echartsDataX = []
        let echartsData = []
        echartsDataX = res.data.result.queryList[i].chartData.xAxis
        echartsData = res.data.result.queryList[i].chartData.yAxis
        for (let j = 0; j < echartsData.length; j++) {
          echartsData[j].name = echartsData[j].titleName
          // echartsData[j].type = 'line'
          // echartsData[j].symbol = 'none'
          echartsData[j].sampling = 'lttb'


          // 处理浮点数
          for (let k = 0; k < echartsData[j].data.length; k++) {
            echartsData[j].data[k] = Number(echartsData[j].data[k]).toFixed(2)
          }
          // 处理最大值最小值
          echartsData[j].markPoint = {
            data: []
          }
          if (echartsData[j].valueType !== '') {
            echartsData[j].valueType = echartsData[j].valueType.split(',')
            echartsData[j].valueType = echartsData[j].valueType.map(Number)
            if (echartsData[j].valueType && echartsData[j].valueType.length > 0) {
              for (let k = 0; k < echartsData[j].valueType.length; k++) {
                if (echartsData[j].valueType[k] === 0) {
                  echartsData[j].markPoint.data.push({
                    type: 'max', name: 'Max'
                  })
                }
                if (echartsData[j].valueType[k] === 1) {
                  echartsData[j].markPoint.data.push({
                    type: 'min', name: 'Min'
                  })
                }
              }
            }
          }
          // console.log(echartsData[j].valueType, echartsData[j].markPoint)

          // 处理图表类型
          if (echartsData[j].chatType !== '') {
            if (echartsData[j].chatType === 0) {
              echartsData[j].type = 'bar'
              echartsData[j].symbol = 'none'
              echartsData[j].barGap = '0%'
            } else if (echartsData[j].chatType === 1) {
              echartsData[j].type = 'line'
              echartsData[j].lineStyle = {
                width: 1
              }
              echartsData[j].symbol = 'circle'
              // echartsData[j].symbol = 'none'
            } else if (echartsData[j].chatType === 3) {
              echartsData[j].type = 'scatter'
              echartsData[j].symbolSize = 15
              //for (let j = 0; j < echartsData[i].data.length; j++) {
              // echartsData[i].data.splice(j, 1, [echartsDataX.value[j], Number(echartsData[i].data[j])])
              //}
            } else if (echartsData[j].chatType === 2) {
              echartsData[j].type = 'line'
              echartsData[j].symbol = 'none'
              echartsData[j].areaStyle = {
                opacity: 0.3
              }
            }
          } else {
            echartsData[j].type = 'line'
            echartsData[j].lineStyle = {
              width: 1
            }
            echartsData[j].symbol = 'circle'
            // echartsData[j].symbol = 'none'
          }
        }
        nextTick(() => {
          chartLoad(i, echartsDataX, echartsData)
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

// 根据详情展示图表---------------------------------------------------------------------
// 加载图表
const chartLoad = function (index, echartsDataX, echartsData) {
  var chartDom = document.querySelector(`.chat${index}`);
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: 80,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: echartsDataX
    },
    yAxis: {
      type: 'value',
    },
    dataZoom: [
      {
        // type: 'inside',
        start: 0,
        end: 100,
        disabled: false
      },
      {
        start: 0,
        end: 100
      }
    ],
    series: echartsData
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}


// 仪表盘数据id
let dataArr = ref({
  paramIdList: [],
  queryIdList: []
})
// 新增组件弹窗
let chatDialog = ref(false)
// 新增表单
let chatForm = ref({
  name: '',
  plan: ''
})
// 判断展示橱窗还是图表
let showFlag = ref()
let titleName = ref()
// 添加仪表盘
const addChat = function (flag) {
  chatDialog.value = true
  showFlag.value = flag
  if (flag === 1) {
    titleName.value = '新增橱窗组件'
  } else if (flag === 2) {
    titleName.value = '新增图表组件'
  } else if (flag === 3) {
    titleName.value = '新增复合查询组件'
  }
  getParamsFun()
  getPlanFun()
  chatForm.value = {
    name: '',
    plan: ''
  }
}
// 表单提交
const submit = function () {
  if (chatForm.value.name !== '') {
    dataArr.value.paramIdList.push(chatForm.value.name)
  }
  if (chatForm.value.plan !== '') {
    dataArr.value.queryIdList.push(chatForm.value.plan)
  }
  saveCustomDetailApi(qs.stringify({
    projectId: router.currentRoute.value.query.projectId,
    paramIdList: dataArr.value.paramIdList.join(),
    queryIdList: dataArr.value.queryIdList.join()
  })).then(res => {
    if (res.data.code === 0) {
      chatDialog.value = false
      getDetailFun()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 点击删除橱窗数据
const delCustom = function (id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除橱窗',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此橱窗，删除后数据不可恢复'),
    ])
  }).then(() => {
    dataArr.value.paramIdList = dataArr.value.paramIdList.filter(item => item != id)
    saveCustomDetailApi(qs.stringify({
      projectId: router.currentRoute.value.query.projectId,
      paramIdList: dataArr.value.paramIdList.join(),
      queryIdList: dataArr.value.paramIdList.join()
    })).then(res => {
      if (res.data.code === 0) {
        chatDialog.value = false
        getDetailFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}

// 点击删除图表数据
const delChat = function (id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除图表',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此图表，删除后数据不可恢复'),
    ])
  }).then(() => {
    dataArr.value.queryIdList = dataArr.value.queryIdList.filter(item => item != id)
    saveCustomDetailApi(qs.stringify({
      projectId: router.currentRoute.value.query.projectId,
      paramIdList: dataArr.value.paramIdList.join(),
      queryIdList: dataArr.value.queryIdList.join()
    })).then(res => {
      if (res.data.code === 0) {
        chatDialog.value = false
        getDetailFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}


onMounted(() => {
  getParamsFun()
  getPlanFun()
  getDetailFun()
})
</script>

<style lang="less" scoped>
</style>
