<template>
  <div class="versionDetail">
    <div class="title">
      <p>
        <span @click="goBack">电价配置</span>
        <span> / </span>
        <span>详情</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        详情
      </p>
    </div>
    <div class="content">
      <!--数据列表内容-->
      <div class="contain">
        <!--头部-->
        <div class="head">
          <div class="left">
            <p>版本名称</p>
            <p>{{ strategyForm.versionNumber }}</p>
          </div>
          <div class="right">
            <p>生效时间</p>
            <p>{{ strategyForm.startTime }} - {{ strategyForm.endTime }}</p>
          </div>
        </div>
        <!--内容-->
        <div class="listCont">
          <div class="cont">
            <div class="timeBox">
              <p class="tit">分时策略：</p>
              <div>
                <p>尖时段</p>
                <p v-for="(item,index) in strategyForm.sharpTimeListJsonStrCopy" :key="index">{{
                    item.startTime
                  }}～{{ item.endTime }}</p>
              </div>
              <div>
                <p>峰时段</p>
                <p v-for="(item,index) in strategyForm.peakTimeListJsonStrCopy" :key="index">{{
                    item.startTime
                  }}～{{ item.endTime }}</p>
              </div>
              <div>
                <p>平时段</p>
                <p v-for="(item,index) in strategyForm.flatTimeListJsonStrCopy" :key="index">{{
                    item.startTime
                  }}～{{ item.endTime }}</p>
              </div>
              <div>
                <p>谷时段</p>
                <p v-for="(item,index) in strategyForm.valleyTimeListJsonStrCopy" :key="index">{{
                    item.startTime
                  }}～{{ item.endTime }}</p>
              </div>
            </div>
            <div class="priceBox1">
              <div class="tit">电网电价
                <el-tooltip
                    class="box-item"
                    content="从电网购电的电价"
                    effect="dark"
                    placement="top"
                >
                  <el-icon>
                    <QuestionFilled/>
                  </el-icon>
                </el-tooltip>
              </div>
              <div>
                <p>尖时段电价</p>
                <p>{{ strategyForm.sharpFee }}元/kwh</p>
              </div>
              <div>
                <p>峰时段电价</p>
                <p>{{ strategyForm.peakFee }}元/kwh</p>
              </div>
              <div>
                <p>平时段电价</p>
                <p>{{ strategyForm.flatFee }}元/kwh</p>
              </div>
              <div>
                <p>谷时段电价</p>
                <p>{{ strategyForm.valleyFee }}元/kwh</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './versionDetail.less'
import {Back, Plus, QuestionFilled} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {getListDetailApi} from "@/api/modules/electrovalence";
import qs from "qs";

let router = useRouter()
// 返回
const goBack = function () {
  router.push('/admin/electrovalence')
}

// 电价表单数据-------------------------------------------------------------------
let strategyForm = ref({
  id: 0,
  versionNumber: '',
  startTime: '',
  endTime: '',
  sharpFee: '',
  sharpTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: ''
    }
  ],
  peakFee: '',
  peakTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: ''
    }
  ],
  flatFee: '',
  flatTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: ''
    }
  ],
  valleyFee: '',
  valleyTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: ''
    }
  ],
})

onMounted(() => {
  if (router.currentRoute.value.query.id) {
    getListDetailApi(qs.stringify({id: router.currentRoute.value.query.id})).then(res => {
      if (res.data.code === 0) {
        let data = JSON.parse(JSON.stringify(res.data.result))
        strategyForm.value.id = data.id
        strategyForm.value.startTime = data.startTime
        strategyForm.value.endTime = data.endTime
        strategyForm.value.versionNumber = data.versionNumber
        strategyForm.value.sharpFee = data.sharpRate.fee
        strategyForm.value.peakFee = data.peakRate.fee
        strategyForm.value.flatFee = data.flatRate.fee
        strategyForm.value.valleyFee = data.valleyRate.fee
        strategyForm.value.sharpTimeListJsonStrCopy = data.sharpRate.timeList
        strategyForm.value.peakTimeListJsonStrCopy = data.peakRate.timeList
        strategyForm.value.flatTimeListJsonStrCopy = data.flatRate.timeList
        strategyForm.value.valleyTimeListJsonStrCopy = data.valleyRate.timeList
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
})
</script>

<style lang="less" scoped>
</style>
