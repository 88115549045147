<template>
  <!--上网电价页面-->
  <div class="upPrise">
    <div class="title">上网电价</div>
    <div class="content">
      <!--<el-form>
        <div class="searchBox">
          <div class="liBox">
            <el-form-item label="上网电价名称">
              <el-input v-model="name" autocomplete="off" clearable placeholder='请输入上网电价名称' type="text"></el-input>
            </el-form-item>
          </div>
          <div class="liBox">
            <el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
            <el-button class="searchButton resetButton" @click="resetClick()">重置</el-button>
          </div>
        </div>
      </el-form>-->
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">
            新建上网电价
          </el-button>
          <el-tooltip
              class="box-item"
              content="新能源发电上网电价"
              effect="dark"
              placement="top"
          >
            <el-icon style="margin-left: 8px;margin-top: 7px; font-size: 18px;">
              <QuestionFilled/>
            </el-icon>
          </el-tooltip>
        </div>
        <el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建上网电价信息</p>
            </el-empty>
          </template>
          <el-table-column label="电价" min-width="2%" property="price">
          </el-table-column>
          <el-table-column label="开始时间" min-width="2%" property="startDate" sortable>
          </el-table-column>
          <el-table-column label="结束时间" min-width="2%" property="endDate" sortable>
          </el-table-column>
          <el-table-column label="操作" min-width="1%">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="addClick(scope.row)">编辑</li>
                <li class="deleteClass" @click="deleteClick(scope.row.id)">删除
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small"
                         :total="total" background layout="total, sizes, prev, pager, next" small
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!--新增/编辑上网电价弹窗-->
    <el-dialog v-model="upPriseDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <el-form ref="upPriseFormRef" :model="upPriseForm" :rules="upPriseRules" class="demo-ruleForm" label-width="auto"
               style="width:98%">
        <el-form-item label="电价" prop="price">
          <el-input v-model="upPriseForm.price" placeholder="请输入电价"/>
        </el-form-item>
        <el-form-item label="开始时间:" prop="startDate">
          <el-date-picker v-model="upPriseForm.startDate" placeholder="请选择开始时间" style="width:100%"
                          type="datetime" value-format="YYYY-MM-DD HH:mm:ss"/>
        </el-form-item>
        <el-form-item label="结束时间:" prop="endDate">
          <el-date-picker v-model="upPriseForm.endDate" placeholder="请选择结束时间" style="width:100%"
                          type="datetime" value-format="YYYY-MM-DD HH:mm:ss"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="upPriseDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="upPriseSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {
  Delete,
  QuestionFilled,
  Plus
} from '@element-plus/icons-vue'
import {
  getList,
  saveList,
  deleteList
} from '../../../api/modules/upPrise'
import './upPrise.less'
import qs from 'qs';

let router = useRouter()
/*生命周期*/
onMounted(() => {
  getListFun()
})

let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//重置
const resetClick = function () {
  name.value = ''
}
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
let name = ref() //上网电价名称
//获取上网电价列表
const getListFun = function () {
  loadingVal.value = true
  let params = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    name: name.value
  }
  getList(qs.stringify(params)).then(res => {
    //console.log(res);
    loadingVal.value = false
    if (res.data.ok) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let dialogWidth = ref('40%') //新增上网电价弹窗宽度
let upPriseDialogVisible = ref(false) //新增上网电价弹窗开关
let title = ref('') //弹窗标题
let editId = ref()
//新增/编辑上网电价的点击
let addClick = function (val) {
  upPriseForm.value = {}
  if (val) {
    title.value = '编辑上网电价'
    upPriseForm.value.price = val.price
    upPriseForm.value.startDate = val.startDate
    upPriseForm.value.endDate = val.endDate
    editId.value = val.id
  } else {
    title.value = '新建上网电价'
    upPriseForm.value.price = ''
    upPriseForm.value.startDate = ''
    upPriseForm.value.endDate = ''
  }
  upPriseDialogVisible.value = true
}
let upPriseFormRef = ref() //上网电价
//上网电价表单
let upPriseForm = ref({
  price: '',
  startDate: '',
  endDate: ''
})
// 上网电价表单验证规则
let upPriseRules = ref({
  price: [{
    required: true,
    message: '请输入电价',
    trigger: 'blur'
  }],
  startDate: [{
    required: true,
    message: '请选择开始时间',
    trigger: 'blur'
  }],
  endDate: [{
    required: true,
    message: '请选择结束时间',
    trigger: 'blur'
  }]
})
//上网电价的保存
const upPriseSave = function () {
  var params = {
    price: upPriseForm.value.price,
    startDate: upPriseForm.value.startDate,
    endDate: upPriseForm.value.endDate
  }
  if (upPriseFormRef) {
    upPriseFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (title.value == '新建上网电价') {
          params['id'] = 0
        } else if (title.value == '编辑上网电价') {
          params['id'] = editId.value
        }
        saveList(
            qs.stringify(params)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            upPriseDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除上网电价',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此上网电价，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
</script>