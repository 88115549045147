<template>
	<!--实时数据页面-->
	<div class="nowData">
		<div class="title">
			实时数据
		</div>
		<div class="contentBox">
			<!--左侧内容-->
			<div class="leftBox">
				<div class="searchBox2">
					<el-select v-model="address" clearable placeholder="请选择区域" style="width:80%">
						<el-option v-for="item in addressList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
						</el-option>
					</el-select>
					<el-switch v-model="group" inactive-text="分级"></el-switch>
				</div>
				<div class="searchBox">
					<el-input v-model="searchVal" clearable placeholder="请输入关键字" @change="searchClick(customerList,searchVal)">
						<template #prefix>
							<el-icon class="el-input__icon">
								<search/>
							</el-icon>
						</template>
					</el-input>
				</div>
				<div class="treeBox">
					<el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList" :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false" :props="defaultProps" node-key="id" @node-click="handleNodeClick">
						<template #default="{ node, data }">
							<span class="custom-tree-node" style="width:100%;">
	              <span>{{ node.label }}</span>
							</span>
						</template>
					</el-tree>
				</div>
			</div>
			<!--右侧内容-->
			<div class="rightBox">
				<!--<div class="headerBox">
					<el-select v-model="address" clearable placeholder="请选择类型" style="margin-right: 20px;">
						<el-option v-for="item in addressList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
						</el-option>
					</el-select>
					<div class="tabBox" style="margin-right: 20px;">
						<p :class="activeIdx===1?'active':''" @click="activeClick(1)">总能耗</p>
						<p :class="activeIdx===2?'active':''" @click="activeClick(2)">分时能耗</p>
					</div>
					<el-select v-model="address" clearable placeholder="请选择" style="margin-right: 20px;">
						<el-option v-for="item in addressList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
						</el-option>
					</el-select>
					<div>
						<el-date-picker v-model="dateTime" end-placeholder="结束时间"
                              range-separator="→"
                              start-placeholder="开始时间"
                              type="datetimerange"
                              value-format="YYYY-MM-DD HH:mm:ss">
              </el-date-picker>
					</div>
				</div>-->
				<div class="centerBox"></div>
				<div class="bottomBox">
					<div class="leftBottomBox">1</div>
					<div class="rightBottomBox">2</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { reactive, onMounted, computed, watch, ref, nextTick, h } from 'vue';
	import { useRouter, useRoute } from 'vue-router'
	import { ElTable, ElMessage, ElMessageBox } from 'element-plus'
	import {
		Search
	} from '@element-plus/icons-vue'
	import {
		getList,
	} from '../../api/modules/tissue'
	import './nowData.less'
	import qs from 'qs';

	/*路由*/
	const router = useRouter()
	const route = useRoute() //需要将useRoute函数执行放在顶部
	let userId = route.query.userId; /*获取query''*/
	/*生命周期*/
	onMounted(() => {
		getListFun()
	})
	let addressList = ref([{
			id: 1,
			name: '中国'
		},
		{
			id: 2,
			name: '杭州'
		},
	])
	let group = ref(false)
	let dateTime=ref()
	// 日月年切换
	let activeIdx = ref(1)
	const activeClick = function(idx) {
		activeIdx.value = idx
	}
	//默认树级属性
	let defaultProps = {
		label: 'name',
		children: 'subList',
	}
	let customerList = ref([]) //组织树级列表
	let searchVal = ref() //树级列表字段
	//树级列表搜索
	const searchClick = function(data, name) {
		defaultExpandKeys.value = []
		let res = tree(data, name)
		if(res) {
			//currentNodeKey.value = res.id
			treeRef.value.setCurrentKey(res.id)
			defaultExpandKeys.value.push(res.parentId)
		}
	}
	const tree = function(data, name) {
		for(let i = 0; i < data.length; i++) {
			let item = data[i];
			if(item.name === name) {
				return item;
			} else {
				if(item.subList && item.subList.length > 0) {
					let res = tree(item.subList, name);
					if(res) return res;
				}
			}
		}
	}
	let custerObj = ref({}) //当前组织信息
	let currentNodeKey = ref() //默认选中树级列表第一级
	let treeRef = ref(null)
	//获取组织树级列表
	const getListFun = async function() {
		await getList(null).then(res => {
			//console.log(res);
			if(res.data.ok) {
				customerList.value = []
				customerList.value.push(res.data.result)
				defaultExpandKeys.value = [customerList.value[0].id]
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}

	let defaultExpandKeys = ref([]) //树的默认展开
	//树级的点击
	const handleNodeClick = function(e) {
		defaultExpandKeys.value = []
	}
</script>
<style lang="less" scoped>
	/*el-tooltip的样式不能下载scoped*/
	
	.el-popper.is-customized {
		padding: 4px !important;
		background: rgba(0, 0, 0, .8);
		color: #fff !important;
	}
	
	.el-popper.is-customized .el-popper__arrow::before {
		background: rgba(0, 0, 0, .8);
	}
</style>