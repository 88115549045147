<template>
	<div class="addAction">
		<div class="title">
			<p>
				<span @click="goBack1">规则</span>
				<span> / </span>
				<span @click="goBack2">编辑规则</span>
				<span> / </span>
				<span>{{router.currentRoute.value.query.index?'编辑动作':'添加动作'}}</span>
			</p>
			<p class="tit">
				<el-icon @click="goBack2">
					<Back/>
				</el-icon>
				<span>{{router.currentRoute.value.query.index?'编辑动作':'添加动作'}}</span>
			</p>
		</div>
		<div class="stream-details">
			<div class="stream-steps">
				<div class="steps-container">
					<div style="display: flex;align-items: center;">
						<img alt="" src="http://192.168.3.253:8085/web/common/img/done-blue.fca2daac.svg">
						<span class="active-text">1.选择插件</span>
					</div>
					<div class="line"></div>
					<div style="display: flex;align-items: center;">
						<img :src="active === 2?'http://192.168.3.253:8085/web/common/img/done-blue.fca2daac.svg':'http://192.168.3.253:8085/web/common/img/done-gray.33058c88.svg'" alt="">
						<span :class="active === 2 ? 'active active-text' :''">2.插件配置</span>
					</div>
				</div>
			</div>
			<div v-if="active === 1">
				<div class="steps-title">选择文件类型</div>
				<div class="stream-content">

					<div v-for="(item, index) in arrayList" :key="index" class="stream-details-dome" @click="typeClick(item.name)">
						<div :class="['template-card',activeCell===item.name?'active-cell':'']" @click="activeCell=item.name">
							<div class="left-content">
								<img :src="item.url" alt="">
							</div>
							<div class="card-content">
								<div class="template-label">{{ item.name }}</div>
								<div class="template-content">
									{{ item.about.description.zh }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="editor">
				<file v-if="activeCell=='File'" ref="fileChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></file>
				<influx v-if="activeCell=='InfluxDB V1'" ref="influxChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></influx>
				<influx2 v-if="activeCell=='InfluxDB V2'" ref="influx2Child" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></influx2>
				<kafka v-if="activeCell=='Kafka'" ref="kafkaChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></kafka>
				<log v-if="activeCell=='Log'" ref="logChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></log>
				<memory v-if="activeCell=='Memory'" ref="memoryChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></memory>
				<mqtt v-if="activeCell=='MQTT'" ref="mqttChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></mqtt>
				<neuron v-if="activeCell=='Neuron'" ref="neuronChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></neuron>
				<nop v-if="activeCell=='Nop'" ref="nopChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></nop>
				<sql v-if="activeCell=='SQL'" ref="sqlChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></sql>
				<rest v-if="activeCell=='REST'" ref="restChild" @returnResults="getResults" @submitResults="getSubmitResults" :actionObj="router.currentRoute.value.params.actionObj"></rest>
			</div>
		</div>
		<!--最底部按钮-->
		<div class="footer-fix-container">
			<div v-if="active === 1">
				<el-button type="primary" @click="next(2)">下一步</el-button>
				<el-button @click="cancleClick">取消</el-button>
			</div>
			<div v-else>
				<el-button type="primary" @click="submitClick">提交</el-button>
				<el-button @click="next(1)">上一步</el-button>
				<el-button type="primary" @click="testConnect">测试连接</el-button>
				<el-button @click="cancleClick">取消</el-button>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, onUnmounted, reactive, ref, watch } from "vue"
	import './addAction.less'
	import { useRouter } from "vue-router/dist/vue-router";
	import {
		ElMessage
	} from "element-plus";
	import { Back, QuestionFilled, Document, Plus } from '@element-plus/icons-vue'
	import { getSinks, fileTest, influxTest, influx2Test, kafkaTest, logTest, memoryTest, mqttTest, neuronTest, nopTest, sqlTest, restTest } from "@/api/modules/addAction";
	import file from "./modules/file.vue";
	import influx from "./modules/influx.vue";
	import influx2 from "./modules/influx2.vue";
	import kafka from "./modules/kafka.vue";
	import log from "./modules/log.vue";
	import memory from "./modules/memory.vue";
	import mqtt from "./modules/mqtt.vue";
	import neuron from "./modules/neuron.vue";
	import nop from "./modules/nop.vue";
	import sql from "./modules/sql.vue";
	import rest from "./modules/rest.vue";
	let router = useRouter()
	/*生命周期*/
	onMounted(() => {
		//console.log(router.currentRoute.value.query)
		//console.log(JSON.parse(router.currentRoute.value.params.actionObj))
		//console.log(Object.keys(JSON.parse(router.currentRoute.value.params.actionObj))[0])
		for(let key in JSON.parse(router.currentRoute.value.params.actionObj)) {
			if(key == 'file') {
				activeCell.value = 'File'
			} else if(key == 'influx') {
				activeCell.value = 'InfluxDB V1'
			} else if(key == 'influx2') {
				activeCell.value = 'InfluxDB V2'
			} else if(key == 'kafka') {
				activeCell.value = 'Kafka'
			} else if(key == 'log') {
				activeCell.value = 'Log'
			} else if(key == 'memory') {
				activeCell.value = 'Memory'
			} else if(key == 'mqtt') {
				activeCell.value = 'MQTT'
			} else if(key == 'neuron') {
				activeCell.value = 'Neuron'
			} else if(key == 'nop') {
				activeCell.value = 'Nop'
			} else if(key == 'rest') {
				activeCell.value = 'REST'
			} else if(key == 'sql') {
				activeCell.value = 'SQL'
			}
		}
		getSinks().then(res => {
			if(res.statusText === 'OK') {
				arrayList.value = res.data
				let nameArr = ['File', 'InfluxDB V1', 'InfluxDB V2', 'Kafka', 'Log', 'Memory', 'MQTT', 'Neuron', 'Nop', 'REST', 'SQL']
				arrayList.value.forEach((item, index) => {
					if(item.name == 'file') {
						item.name = 'File'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/File.svg'
					} else if(item.name == 'influx') {
						item.name = 'InfluxDB V1'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/influx.svg'
					} else if(item.name == 'influx2') {
						item.name = 'InfluxDB V2'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/influx.svg'
					} else if(item.name == 'kafka') {
						item.name = 'Kafka'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/kafka.svg'
					} else if(item.name == 'log') {
						item.name = 'Log'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/log.svg'
					} else if(item.name == 'memory') {
						item.name = 'Memory'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/memory.svg'
					} else if(item.name == 'mqtt') {
						item.name = 'MQTT'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/mqtt.svg'
					} else if(item.name == 'neuron') {
						item.name = 'Neuron'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/neuron.svg'
					} else if(item.name == 'nop') {
						item.name = 'Nop'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/nop.svg'
					} else if(item.name == 'rest') {
						item.name = 'REST'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/rest.svg'
					} else if(item.name == 'sql') {
						item.name = 'SQL'
						item.url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/sql.svg'
					} else {
						item.name = '无'
						arrayList.value = arrayList.value.filter(item => item.name !== '无');
					}
				})
			}
		})
	});
	//返回
	const goBack1 = function() {
		router.push('/admin/rulesPage')
	}
	const goBack2 = function() {
		router.push({
			name: 'AddRules',
			query: {
				name: router.currentRoute.value.query.name,
				index: router.currentRoute.value.query.index
			},
			params: {
				actionObj: '{}'
			}
		})
	}
	let active = ref(1); //步骤值
	let activeCell = ref('MQTT')
	let arrayList = ref([]) //文件类型列表
	//上一步/下一步的点击
	const next = (n) => {
		active.value = n;
	};
	let nameArr = ['File', 'InfluxDB V1', 'InfluxDB V2', 'Kafka', 'Log', 'Memory', 'MQTT', 'Neuron', 'Nop', 'REST', 'SQL']
	//类型的点击
	const typeClick = function(type) {
		activeCell.value = type
	}
	//定义子组件
	let fileChild = ref(null);
	let influxChild = ref(null);
	let influx2Child = ref(null);
	let kafkaChild = ref(null);
	let logChild = ref(null);
	let memoryChild = ref(null);
	let mqttChild = ref(null);
	let neuronChild = ref(null);
	let nopChild = ref(null);
	let restChild = ref(null);
	let sqlChild = ref(null);

	//提交的点击
	const submitClick = function() {
		if(activeCell.value == 'File') {
			fileChild.value.submitFun();
		} else if(activeCell.value == 'InfluxDB V1') {
			influxChild.value.submitFun();
		} else if(activeCell.value == 'InfluxDB V2') {
			influx2Child.value.submitFun();
		} else if(activeCell.value == 'Kafka') {
			kafkaChild.value.submitFun();
		} else if(activeCell.value == 'Log') {
			logChild.value.submitFun();
		} else if(activeCell.value == 'Memory') {
			memoryChild.value.submitFun();
		} else if(activeCell.value == 'MQTT') {
			mqttChild.value.submitFun();
		} else if(activeCell.value == 'Neuron') {
			neuronChild.value.submitFun();
		} else if(activeCell.value == 'Nop') {
			nopChild.value.submitFun();
		} else if(activeCell.value == 'REST') {
			restChild.value.submitFun();
		} else if(activeCell.value == 'SQL') {
			sqlChild.value.submitFun();
		}
	}
	//获取提交的值
	const getSubmitResults = function(e) {
		//console.log(e)
		router.push({
			name: 'AddRules',
			query: {
				name: router.currentRoute.value.query.name,
				index: router.currentRoute.value.query.index
			},
			params: {
				actionObj: JSON.stringify(e)
			}
		})
	}
	//测试连接的点击
	const testConnect = function() {
		if(activeCell.value == 'File') {
			fileChild.value.testConnectRules();
		} else if(activeCell.value == 'InfluxDB V1') {
			influxChild.value.testConnectRules();
		} else if(activeCell.value == 'InfluxDB V2') {
			influx2Child.value.testConnectRules();
		} else if(activeCell.value == 'Kafka') {
			kafkaChild.value.testConnectRules();
		} else if(activeCell.value == 'Log') {
			logChild.value.testConnectRules();
		} else if(activeCell.value == 'Memory') {
			memoryChild.value.testConnectRules();
		} else if(activeCell.value == 'MQTT') {
			mqttChild.value.testConnectRules();
		} else if(activeCell.value == 'Neuron') {
			neuronChild.value.testConnectRules();
		} else if(activeCell.value == 'Nop') {
			nopChild.value.testConnectRules();
		} else if(activeCell.value == 'REST') {
			restChild.value.testConnectRules();
		} else if(activeCell.value == 'SQL') {
			sqlChild.value.testConnectRules();
		}
	}
	//获取测试的值
	const getResults = function(e) {
		//console.log(e)
		if(e.type == 'file') {
			console.log(e)
			fileTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'influx') {
			console.log(e)
			influxTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'influx2') {
			console.log(e)
			influx2Test(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'kafka') {
			console.log(e)
			kafkaTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'log') {
			console.log(e)
			logTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'memory') {
			console.log(e)
			memoryTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'mqtt') {
			console.log(e)
			mqttTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'neuron') {
			console.log(e)
			neuronTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'nop') {
			console.log(e)
			nopTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'rest') {
			console.log(e)
			restTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		} else if(e.type == 'sql') {
			console.log(e)
			sqlTest(e).then(res => {
				if(res.status == 200) {
					//console.log(res)
					ElMessage({
						type: 'success',
						message: '测试成功！'
					});
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message
					});
				}
			}).catch(err => {
				console.log(err)
				ElMessage({
					type: 'error',
					message: err.response.data.message
				});
			})
		}
	}
	//取消
	const cancleClick = function() {
		router.push({
			name: 'AddRules',
			query: {
				name: router.currentRoute.value.query.name,
				index: router.currentRoute.value.query.index
			},
			params: {
				actionObj: '{}'
			}
		})
	}
</script>

<style>

</style>