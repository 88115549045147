import {api} from "@/api";

// 策略列表-----------------------
// 获取策略列表
export const getStrategyListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/list',
        method: 'POST',
        data: data
    })
}
// 获取设备大类列表
export const getEquipmentTypeListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/category/listSelect',
        method: 'POST',
        data: data
    })
}
// 获取产品型号列表
export const getEquipmentModelListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/listProductModel',
        method: 'POST',
        data: data
    })
}
// 获取控制列表
export const getControlListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/listControl',
        method: 'POST',
        data: data
    })
}
// 删除策略
export const delStrategyListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/del',
        method: 'POST',
        data: data
    })
}
// 启用策略
export const enableStrategyApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/enable',
        method: 'POST',
        data: data
    })
}
// 禁用策略
export const disableStrategyApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/disable',
        method: 'POST',
        data: data
    })
}


// 保存策略--------------------------
// 获取头部信息
export const getHeadInfoApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/equipmentControl/getHead',
        method: 'POST',
        data: data
    })
}
// 拉合闸点击
export const operateDeviceApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zkep2/device/operateDevice',
        method: 'POST',
        data: data
    })
}
// 获取详情
export const getStrategyDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/detail',
        method: 'GET',
        params: data
    })
}
// 获取参数列表
export const getStrategyConfigListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControlParam/page',
        method: 'POST',
        data: data
    })
}
// 获取数据项列表
export const getDataItemApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControlRelated/list',
        method: 'POST',
        data: data
    })
}
// 获取参数列表
export const getParamListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/paramConfigV2/page',
        method: 'POST',
        data: data
    })
}
// 保存策略
export const saveStrategyApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/edit',
        method: 'POST',
        data: data
    })
}

// 策略日志--------------------------
export const getStrategyLogApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/strategyLog/page',
        method: 'POST',
        data: data
    })
}


// 运行结果------------------------------------
// 运行结果列表
export const getRunResultListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/strategyLog/listRun',
        method: 'POST',
        data: data
    })
}
// 获取设备选项
export const getEquipmentSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/strategyLog/listDeviceSelect',
        method: 'POST',
        data: data
    })
}
// 获取产品控制选项
export const getProductControlApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/strategyLog/listControlSelect',
        method: 'POST',
        data: data
    })
}


// 方案配置--------------------------------------------------------
// 获取方案配置列表
export const getProgrammeListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/slg/page',
        method: 'POST',
        data: data
    })
}
// 获取方案配置详情
export const getProgrammeDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/slg/getDetail',
        method: 'POST',
        data: data
    })
}
// 保存方案配置
export const saveProgrammeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/slg/save',
        method: 'POST',
        data: data
    })
}
// 删除方案配置
export const delProgrammeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/slg/delete',
        method: 'POST',
        data: data
    })
}
// 启用禁用方案配置
export const changeProgrammeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/slg/change',
        method: 'POST',
        data: data
    })
}
// 获取电价版本列表
// 获取配置项
export const getVersionSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRate/listSelect',
        method: 'POST',
        data: data
    })
}

// 获取所有设备树
export const getAllTreeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/equipment/getAllTree',
        method: 'POST',
        data: data
    })
}