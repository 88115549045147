import {api} from "@/api";

// 获取水表列表
export const getWaterListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/waterMeter/list',
        method: 'GET',
        params: data
    })
}

// 新增水表
export const addWaterApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/waterMeter/save',
        method: 'POST',
        data: data
    })
}

// 编辑水表
export const editWaterApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/waterMeter/update',
        method: 'POST',
        data: data
    })
}

// 水表详情
export const getWaterDetailApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message3761/rest/waterMeter/detail/${id}`,
        method: 'GET',
    })
}

// 删除水表
export const delWaterListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message3761/rest/waterMeter/remove/${id}`,
        method: 'POST',
    })
}

// 读表
export const readWaterApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/protocol3761/waterMeter/readData',
        method: 'GET',
        params: data
    })
}
// 水表拉合闸
export const closeWaterApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/protocol3761/waterMeter/enableSwitch',
        method: 'POST',
        data: data
    })
}
//获取网关select
export const getGatewayList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/listSelect',
        method: 'POST',
        data: data
    })
}