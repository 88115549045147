<template>
  <div class="waterMeter">
    <div class="title">
      <p>
        <span>水表管理</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px;">设备id</label>
          <el-input v-model="deviceId" :prefix-icon="Search" clearable placeholder="请输入设备id"></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px;">通讯地址</label>
          <el-input v-model="address" :prefix-icon="Search" clearable placeholder="请输入通讯地址"></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--新增水表-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增水表
        </el-button>
        <!--水表列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增水表</p>
            </el-empty>
          </template>
          <!--          addressId-->
          <!--          :-->
          <!--          "00001806919547"-->
          <!--          currentAmount-->
          <!--          :-->
          <!--          "0"-->
          <!--          dailyCumulativeFlow-->
          <!--          :-->
          <!--          "0"-->
          <!--          gatewayAddress-->
          <!--          :-->
          <!--          "19000039"-->
          <!--          portId-->
          <!--          :-->
          <!--          2-->
          <!--          readStatus-->
          <!--          :-->
          <!--          ""-->
          <!--          realTime-->
          <!--          :-->
          <!--          ""-->
          <!--          switchStatus-->
          <!--          :-->
          <!--          ""-->
          <!--          waterMeterId-->
          <!--          :-->
          <!--          "05fe2bbd94f5b6c809281ba5436002dd"-->
          <!--          waterMeterName-->
          <!--          :-->
          <!--          "德胜园理发店"-->
          <el-table-column label="设备id" prop="waterMeterId"></el-table-column>
          <el-table-column label="设备名称" prop="waterMeterName"></el-table-column>
          <el-table-column label="网关" prop="gatewayAddress"></el-table-column>
          <el-table-column label="通讯地址" prop="addressId"></el-table-column>
          <el-table-column label="阀门状态" prop="switchStatus">
            <template #default="scope">
              <p v-if="scope.row.switchStatus==0">开</p>
              <p v-else-if="scope.row.switchStatus==1">关</p>
              <p v-else-if="scope.row.switchStatus==3">故障</p>
              <p v-else>未知</p>
            </template>
          </el-table-column>
          <el-table-column label="读数故障" prop="readStatus">
            <template #default="scope">
              <p v-if="scope.row.readStatus==0">正常</p>
              <p v-else-if="scope.row.readStatus==1">故障</p>
              <p v-else>未知</p>
            </template>
          </el-table-column>
          <el-table-column label="当前累积量" prop="currentAmount"></el-table-column>
          <el-table-column label="日累积量" prop="dailyCumulativeFlow"></el-table-column>
          <el-table-column label="实时时间" prop="realTime"></el-table-column>
          <el-table-column label="操作" width="230px">
            <template #default="scope">
              <div class="opacity">
                <!--                <img alt="" src="../../assets/img/161@2x.png" @click.prevent="editClick(scope.row.id,scope.row)">-->
                <p class="btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</p>
                <p class="btn" @click="readWaterFun(scope.row.waterMeterId)">读表</p>
                <p class="btn" @click="closeWaterFun(false,scope.row.waterMeterId)">拉闸</p>
                <p class="btn" @click="closeWaterFun(true,scope.row.waterMeterId)">合闸</p>
                <p class="btn delClass" @click.prevent="delClick(scope.row.waterMeterId)">删除</p>
                <!--                <img alt="" src="../../assets/img/163@2x.png" style="cursor:pointer"-->
                <!--                     @click.prevent="delClick(scope.row.waterMeterId)">-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑水表':'新增水表'"
               width="40%">
      <el-form ref="waterForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="网关" prop="gatewayAddress">
          <el-select v-model="formData.gatewayAddress" clearable filterable placeholder="请选择网关">
            <el-option v-for="(item,index) in gatewayList" :key="index" :label="item.name"
                       :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通讯地址" prop="addressId">
          <el-input v-model="formData.addressId" placeholder="请输入通讯地址"></el-input>
        </el-form-item>

        <el-form-item label="通信端口号" prop="portId">
          <el-input v-model="formData.portId" placeholder="请输入1～30的通信端口号" type="number"></el-input>
        </el-form-item>
        <el-form-item label="开关名称" prop="waterMeterName">
          <el-input v-model="formData.waterMeterName" placeholder="请输入开关名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './waterMeter.less'
import {CirclePlus, CircleClose, Plus, Search, Delete} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox, ElSwitch} from 'element-plus'
import {useRouter} from 'vue-router';

import qs from "qs";
import {
  getWaterListApi,
  addWaterApi,
  editWaterApi,
  getWaterDetailApi,
  delWaterListApi,
  readWaterApi,
  closeWaterApi,
  getGatewayList
} from "@/api/modules/waterMeter";


let router = useRouter()

// 搜索
let deviceId = ref()  // 设备id
let address = ref()  // 通讯地址

// 水表配置列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 水表表格数据
let tableData = ref([])
// 获取水表列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getWaterListApi({
    pageNum: pageNumber.value,
    pageSize: pageSize.value,
    waterMeterId: deviceId.value,
    address: address.value,
  }).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 查询
const searchFun = function () {
  pageNumber.value = 1
  getListFun()
}
// 重置
const resetFun = function () {
  deviceId.value = ''
  address.value = ''
  pageNumber.value = 1
  getListFun()
}


// 获取网关列表
let gatewayList = ref([])

// 新增水表表单-----------------------------------------------------------------
// 水表表单数据
let formData = ref({
  gatewayAddress: '',
  addressId: '',
  portId: '',
  waterMeterName: '',
})
let formRules = ref({
  gatewayAddress: [{
    required: true,
    message: '请选择网关',
    trigger: 'change',
  }],
  addressId: [{
    required: true,
    message: '请输入通讯地址',
    trigger: 'blur'
  }],
  portId: [{
    required: true,
    message: '请输入通讯端口号',
    trigger: 'blur'
  }, {
    pattern: /^([1-9]|[1-2]\d|3[01])$/,
    message: '数值范围为1～31',
    trigger: 'blur'
  }],
  waterMeterName: [{
    required: true,
    message: '请输入开关名称',
    trigger: 'blur'
  }],
})
// 新增编辑表单弹窗
let dialogFormVisible = ref(false)

// 新增水表点击
const addClick = function () {
  formData.value = {
    gatewayAddress: '',
    addressId: '',
    portId: '',
    waterMeterName: '',
  }
  dialogFormVisible.value = true
}
// 编辑点击
const editClick = function (id, row) {
  dialogFormVisible.value = true
  formData.value = JSON.parse(JSON.stringify(row))
}
// 提交水表表单
let waterForm = ref()
const save = function () {
  waterForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      if (formData.value.waterMeterId) {
        editWaterApi(formData.value).then(res => {
          if (res.data.code == 0) {
            getListFun()
            dialogFormVisible.value = false
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            });
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        addWaterApi(formData.value).then(res => {
          if (res.data.code == 0) {
            getListFun()
            dialogFormVisible.value = false
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            });
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  })
}

// 删除水表
const delClick = function (id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除水表',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此水表，删除后数据不可恢复'),
    ])
  }).then(() => {
    delWaterListApi(id).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}


// 读表
const readWaterFun = function (id) {
  ElMessageBox.confirm('请确认是否要读表', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: '读表'
  }).then(() => {
    readWaterApi({deviceId: id}).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '读表成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消读表',
    })
  })
}
// 拉合闸
const closeWaterFun = function (onOrOff, id) {
  ElMessageBox.confirm(onOrOff ? '请确认是否要合闸' : '请确认是否要拉闸', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: onOrOff ? '合闸' : '拉闸'
  }).then(() => {
    closeWaterApi({
      deviceId: id,
      onOrOff: onOrOff
    }).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: onOrOff ? '合闸成功' : '拉闸成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: onOrOff ? '取消合闸' : '取消拉闸',
    })
  })
}


onMounted(() => {
  // 获取水表列表
  getListFun()

  // 获取网关列表
  getGatewayList().then(res => {
    if (res.data.code === 0) {
      gatewayList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
})


</script>

<style lang="less" scoped>
</style>
