<template>
  <div class="payment">
    <div class="title">
      <p>
        <span>用户交费记录</span>
      </p>
    </div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">租户</label>
          <el-input
            v-model="queryParams.tenantName"
            prefix-icon="Search"
            clearable
            placeholder="请输入租户"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建用户交费记录
        </el-button>
        <el-table v-loading="loading" :data="paymentList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建租户交费记录</p>
            </el-empty>
          </template>
          <el-table-column label="租户姓名" align="center" prop="tenantName" />
          <el-table-column
            label="房东姓名"
            align="center"
            prop="landlordName"
          />
          <el-table-column
            label="交费金额"
            align="center"
            prop="balanceAmount"
          />
          <el-table-column label="交费方式" align="center" prop="paymentType">
            <template #default="{ row }">
              <span>{{
                payment_type.find((item) => item.value === row.paymentType)
                  ?.label
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="交费时间"
            align="center"
            prop="paymentTime"
            width="180"
          />
          <!-- <el-table-column label="操作" align="center" width="230px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form ref="paymentRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item v-if="isShow" label="项目" prop="projectId">
          <el-select
            v-model="form.projectId"
            placeholder="请选择项目"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in formLists.projectList"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="房东" prop="landlordId">
          <el-select
            v-model="form.landlordId"
            placeholder="请选择房东"
            clearable
            filterable
            style="width: 100%"
            @change="handleLandlordChange"
          >
            <el-option
              v-for="item in formLists.landlordList"
              :key="item.landlordId"
              :label="item.landlordName"
              :value="item.landlordId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="租户" prop="tenantId">
          <el-select
            v-model="form.tenantId"
            placeholder="请选择租户"
            clearable
            filterable
            style="width: 100%"
            @change="handleTenantChange"
          >
            <el-option
              v-for="item in formLists.tenantList"
              :key="item.tenantId"
              :label="item.tenantName"
              :value="item.tenantId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="交费金额" prop="balanceAmount">
          <el-input
            v-model="form.balanceAmount"
            placeholder="请输入交费金额"
            clearable
          />
        </el-form-item>
        <el-form-item label="交费方式" prop="paymentType">
          <el-select
            v-model="form.paymentType"
            placeholder="请选择交费方式"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="dict in payment_type"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="交费时间" prop="paymentTime">
          <el-date-picker
            clearable
            v-model="form.paymentTime"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="请选择交费时间"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './payment.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listPayment,
  getPayment,
  delPayment,
  addPayment,
  updatePayment,
} from '@/api/modules/payment'
import { getLandlordList, listLandlordProject } from '@/api/modules/landlord'
import { getTenantList } from '@/api/modules/tenant'

const paymentList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const paymentRef = ref(null)
const payment_type = ref([
  { label: '现金', value: 1 },
  { label: '微信', value: 2 },
  { label: '支付宝', value: 3 },
  { label: '银行卡', value: 4 },
])
const isShow = ref(true)

const formLists = reactive({
  projectList: [],
  landlordList: [],
  tenantList: [],
})

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    tenantName: null,
  },
  rules: {
    projectId: [{ required: true, message: '请选择项目', trigger: 'change' }],
    landlordId: [{ required: true, message: '请选择房东', trigger: 'change' }],
    tenantId: [{ required: true, message: '请选择租户', trigger: 'change' }],
    balanceAmount: [
      { required: true, message: '请输入交费金额', trigger: 'blur' },
    ],
    paymentType: [
      { required: true, message: '请选择交费方式', trigger: 'change' },
    ],
    paymentTime: [
      { required: true, message: '请选择交费时间', trigger: 'change' },
    ],
  },
})

const { queryParams, form, rules } = toRefs(data)

/** 查询用户交费记录列表 */
function getList() {
  loading.value = true
  listPayment(queryParams.value).then((response) => {
    paymentList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    projectId: null,
    landlordId: null,
    landlordName: null,
    tenantId: null,
    tenantName: null,
    balanceAmount: null,
    paymentType: null,
    paymentTime: null,
  }
  paymentRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    tenantName: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  listLandlordProject().then((response) => {
    formLists.projectList = response.data.result
    getLandlordList().then((response) => {
      formLists.landlordList = response.data.result
      if (formLists.projectList.length === 1) {
        form.value.projectId = formLists.projectList[0].projectId
        isShow.value = false
      } else {
        isShow.value = true
      }
      open.value = true
      title.value = '新建用户交费记录'
    })
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  listLandlordProject().then((response) => {
    formLists.projectList = response.data.result
    getLandlordList().then((response) => {
      formLists.landlordList = response.data.result
      getTenantList(row.landlordId).then((response) => {
        formLists.tenantList = response.data.result
        getPayment(row.id).then((response) => {
          form.value = response.data
          if (
            formLists.projectList.length === 1 &&
            form.value.projectId === formLists.projectList[0].projectId
          ) {
            isShow.value = false
          } else {
            isShow.value = true
          }
          open.value = true
          title.value = '修改租户交费记录'
        })
      })
    })
  })
}

/** 提交按钮 */
function submitForm() {
  paymentRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updatePayment(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addPayment(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除用户交费记录',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此用户交费记录，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delPayment(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumberberber = e
  getList()
}

function handleLandlordChange(e) {
  if (e) {
    getTenantList(e).then((response) => {
      formLists.tenantList = response.data.result
    })
    form.value.landlordName = formLists.landlordList.find(
      (item) => item.landlordId === e
    ).landlordName
  } else {
    formLists.tenantList = []
    form.value.landlordName = null
  }
}

function handleTenantChange(e) {
  if (e) {
    form.value.tenantName = formLists.tenantList.find(
      (item) => item.tenantId === e
    ).tenantName
  } else {
    form.value.tenantName = null
  }
}
</script>
