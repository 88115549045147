import {api3} from "@/api";
//获取流管理列表
export const streamManagement = () => {
    return api3({
        url: '/api/ekuiper/streams',
        method: 'GET',
    })
}
//获取流管理单个数据
export const getStream = (streamName) => {
    return api3({
        url: `api/ekuiper/streams/${streamName}`,
        method: 'GET',
    })
}
//获取表数据/查询 列表
export const getScanTable = (kind) => {
    return api3({
        url: 'api/ekuiper/tables',
        method: 'GET',
        params: {
            kind: kind
        }
    })
}
//获取表管理单个数据
export const getTables = (streamName) => {
    return api3({
        url: `api/ekuiper/tables/${streamName}`,
        method: 'GET',
    })
}
//获取查询表数据
export const getLookupTable = (streamName) => {
    return api3({
        url: `api/ekuiper/streams/${streamName}`,
        method: 'GET',
    })
}
//添加配置组
export const addConfigGroup = (type, name, data) => {
    return api3({
        url: `api/ekuiper/metadata/sources/${type}/confKeys/${name}`,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}
//删除流管理单个数据
export const deleteStream = (fileName) => {
    return api3({
        url: `api/ekuiper/streams/${fileName}`,
        method: 'DELETE',
    })
}
//删除表管单个数据
export const deleteTables = (fileName) => {
    return api3({
        url: `api/ekuiper/tables/${fileName}`,
        method: 'DELETE',
    })
}