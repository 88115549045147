<template>
  <!--抄读管理页面-->
  <div class="parser">
    <div class="title">抄读管理</div>
    <div class="content">
      <div class="searchBox">
        <el-form>
          <div class="liBox">
            <el-form-item label="抄读名称">
              <el-input v-model="parserName" autocomplete="off" clearable placeholder='请输入抄读名称' type="text"></el-input>
            </el-form-item>
          </div>
          <div class="liBox">
            <el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
            <el-button class="searchButton resetButton" @click="resetClick()">重置</el-button>
          </div>
        </el-form>
      </div>
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">新建抄读方式</el-button>
          <el-button type="primary" @click="exportClick()">导出抄读</el-button>
          <el-button type="success" @click="importClick">导入</el-button>
          <div style="display: none;">
            <el-upload v-model:file-list="fileList" :auto-upload="false" :data="personData" :show-file-list="false"
                       accept=".xls,.xlsx" action="#" class="upload-demo" @change="uploadFile">
              <button id="fileName" slot="trigger" class="selectFile" size="small" type="primary">选择文件</button>
            </el-upload>
          </div>
        </div>
        <el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" :row-key="getRowKeys"
                  style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column :reserve-selection="true" label="选取" style="height:20px" type="selection" width="55">
          </el-table-column>
          <!--<el-table-column label="序号" property="deviceModel" min-width="1%">
            <template #default="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>-->
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建抄读信息</p>
            </el-empty>
          </template>
          <el-table-column label="抄读名称" min-width="2%" property="name">
          </el-table-column>
          <el-table-column label="备注" min-width="2%" property="remark">
          </el-table-column>
          <el-table-column label="操作" min-width="1%">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="detailClick(scope.row.id)">查看</li>
                <li class="editClass" @click="addClick(scope.row.id,scope.row)">
                  编辑
                </li>
                <li class="deleteClass" @click="deleteClick(scope.row.id)">
                  删除
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small"
                         :total="total" background layout="total, sizes, prev, pager, next" small
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!--新增、编辑抄读方式弹窗-->
    <el-dialog v-model="parserDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div>
        <el-form ref="parserFormRef" :model="parserForm" :rules="parserRules" class="demo-ruleForm" label-width="auto"
                 style="width:98%">
          <el-form-item label="名称" prop="name">
            <el-input v-model="parserForm.name" placeholder="请输入名称"/>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="parserForm.remark" placeholder="请输入备注"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="parserDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="parserSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus} from '@element-plus/icons-vue'
import {getList, exportList, importList, saveList, deleteList} from '../../api/modules/parser'
import './parser.less'
import qs from 'qs';
import {http} from '@/api';

let router = useRouter()
/*生命周期*/
onMounted(() => {
  getListFun()
})

let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//重置
const resetClick = function () {
  parserName.value = ''
}
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
let parserName = ref() //抄读名称
//获取抄读列表
const getListFun = function () {
  loadingVal.value = true
  let params = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    name: parserName.value
  }
  getList(qs.stringify(params)).then(res => {
    //console.log(res);
    loadingVal.value = false
    if (res.data.ok) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let title = ref() //弹窗标题
let dialogWidth = ref('40%') //新增抄读弹窗宽度
let parserDialogVisible = ref(false) //新增抄读弹窗开关
let editId = ref() //编辑id
//新增、编辑抄读方式的点击
const addClick = function (id, row) {
  parserForm.value.name = ''
  parserForm.value.remark = ''
  editId.value = id
  if (id) {
    title.value = '编辑抄读方式'
    parserForm.value.name = row.name
    parserForm.value.remark = row.remark
  } else {
    title.value = '新增抄读方式'
  }
  parserDialogVisible.value = true
}
let parserFormRef = ref() //抄读方式分类
//抄读方式分类表单
let parserForm = ref({
  name: '',
  remark: ''
})
// 抄读方式分类表单验证规则
let parserRules = ref({
  name: [{
    required: true,
    message: '请输入名称',
    trigger: 'blur'
  }]
})
//抄读方式的保存
const parserSave = function () {
  let params = {
    name: parserForm.value.name,
    remark: parserForm.value.remark
  }
  if (parserFormRef) {
    parserFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (title.value == '新增抄读方式') {
          params['id'] = 0
        } else if (title.value == '编辑抄读方式') {
          params['id'] = editId.value
        }
        saveList(
            qs.stringify(params)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            parserDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除抄读',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此抄读，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
//复选框触发
let multipleSelection = ref([])
const handleSelectionChange = function (val) {
  multipleSelection.value = val;
}
//保留分页记忆
const getRowKeys = function (row) {
  return row.id
}
const loadingAdd = ref()
//加载的动画
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0)'
  });
}
//导出抄读
const exportClick = function () {
  let idList = []
  if (multipleSelection.value.length > 0) {
    multipleSelection.value.forEach(item => {
      idList.push(item.id)
    })
    /*var consturl = http + 'zouk/rest/parser/export?parserIdList=' + idList.join(',')
    var xhr = new XMLHttpRequest();
    xhr.open("get", consturl, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("memberId", window.localStorage.getItem('memberId')); //加请求头
    xhr.setRequestHeader("Authorization", window.localStorage.getItem('Authorization')); //加请求头
    xhr.onload = function() {
      if(xhr.status == 200) {
        let blob = new Blob([this.response], {
          type: 'application'
        });
        var a = document.createElement("a")
        var url = window.URL.createObjectURL(blob)
        a.href = url
        var farmname = consturl.substring(consturl.length - 21)
        a.download = farmname // 文件名
      }
      a.click()
      window.URL.revokeObjectURL(url)
    }
    xhr.send()*/
    openFullScreen();
    setTimeout(() => {
      loadingAdd.value.close();
      ElMessage({
        type: 'success',
        message: '导出成功'
      });
    }, 2000);
    window.location.href = http + 'zouk/rest/parser/export?parserIdList=' + idList.join(',')
    /*exportList({parserIdList:idList.join(',')}).then(res => {
      //console.log(res);
      if(res) {
        console.log(res)
        ElMessage({
          message: '导出抄读成功！',
          type: 'success'
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })*/
  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条导出的数据!'
    })
  }
}
// 导入抄读
let personData = ref({}) //自定义上传参数
let fileList = ref([]) //上传文件
const importClick = function () {
  document.getElementById("fileName").click();
}
// 导入抄读的改变
const uploadFile = function (file) {
  let fd = new FormData();
  fd.append('file', file.raw);
  importList(fd).then(res => {
    //console.log(res);
    if (res.data.ok) {
      //console.log(res)
      getListFun()
      ElMessage({
        type: 'success',
        message: '导入成功！'
      });
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//查看
const detailClick = function (id) {
  router.push({
    path: '/admin/parserItem',
    query: {
      id: id
    }
  })
}
</script>