<template>
  <div class="homePage">
    <div class="content">
      <!--上面-->
      <div class="topBox">
        <div class="topTitle">
          <img alt="" src="../../assets/img/haunying@2x.png"/> 欢迎登录，数字能源中心
        </div>
        <div class="topContain">
          <div class="itemCont" @click="router.push('/admin/device')">
            <p class="tit">总设备数</p>
            <div class="center">
              <div class="leftBox">
                <div class="dataCont">
                  <p>
                    <span class="val">{{ indexDataObj.equipmentNumberTotal }}</span>
                    <span class="unit">台</span>
                  </p>
                </div>
                <div class="onlineClass">
                  目前在线 <span>{{ indexDataObj.equipmentNumberOnline }}</span> 台
                </div>
              </div>
              <div class="rightBox">
                <el-progress :indeterminate="true"
                             :percentage="(indexDataObj.equipmentNumberOnline/indexDataObj.equipmentNumberTotal)*100"
                             :show-text="false" :stroke-width="14" color="#165DFF" type="circle"/>
              </div>
            </div>
          </div>
          <div class="itemCont" @click="router.push('/admin/gateway')">
            <p class="tit">总网关数</p>
            <div class="center">
              <div class="leftBox">
                <div class="dataCont">
                  <p>
                    <span class="val">{{ indexDataObj.gatewayNumberTotal }}</span>
                    <span class="unit">台</span>
                  </p>
                </div>
                <div class="onlineClass">
                  目前在线 <span>{{ indexDataObj.gatewayNumberOnline }}</span> 台
                </div>
              </div>
              <div class="rightBox">
                <el-progress :indeterminate="true"
                             :percentage="(indexDataObj.gatewayNumberOnline/indexDataObj.gatewayNumberTotal)*100"
                             :show-text="false" :stroke-width="14" color="#8D4EDA" type="circle"/>
              </div>
            </div>
          </div>
          <div class="itemCont" @click="router.push('/admin/workOrder')">
            <div class="echartsBox">
              <div class="kChart2"></div>
            </div>
            <p class="tit">运维工单</p>
            <div class="center">
              <div class="leftBox">
                <div class="dataCont">
                  <p>
                    <span class="val">{{ indexDataObj.ticketNumberTotal }}</span>
                    <span class="unit">单</span>
                  </p>
                </div>
                <!--<div class="weekClass">
                  <label>本周 </label> <span class="weekTitle">{{ indexDataObj.ticketNumberWeek}}</span>
                  <span><img src="../../assets/img/up.png" alt="" /></span>
                  <label> 本月 </label><span class="monthTitle">{{ indexDataObj.ticketNumberMonth}}</span>
                  <span><img src="../../assets/img/down.png" alt="" /></span>
                </div>-->
              </div>
            </div>
          </div>
          <!--        <div class="itemCont" @click="router.push('/admin/alarm')">-->
          <!--          <p class="tit">当前设备告警</p>-->
          <!--          <div class="center">-->
          <!--            <div class="dataCont">-->
          <!--              <img alt="" src="../../assets/img/591.svg">-->
          <!--              <p>-->
          <!--                <span class="val">1041</span>-->
          <!--              </p>-->
          <!--            </div>-->
          <!--            <p class="name">佐小二智慧平台</p>-->
          <!--          </div>-->
          <!--          <div class="line">-->
          <!--            <div class="rowChart">-->
          <!--              <p style="width: 60%;background-color: #FF4A4A;"></p>-->
          <!--              <p style="width: 30%;background-color: #FEA553;"></p>-->
          <!--              <p style="width: 10%;background-color: #D4DCDE;"></p>-->
          <!--            </div>-->
          <!--            <div class="rowChartName">-->
          <!--              <div>-->
          <!--                <p style="background-color: #FF4A4A;"></p>-->
          <!--                <span>重要</span>-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <p style="background-color: #FEA553;"></p>-->
          <!--                <span>次要</span>-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <p style="background-color: #D4DCDE;"></p>-->
          <!--                <span>提示</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
          <div class="itemCont">
            <div class="tit">总记录数
            </div>
            <div class="center">
              <div class="leftBox">
                <div class="dataCont">
                  <p>
                    <span class="val">{{
                        Math.round(Number(String(indexDataObj.storeNumber).slice(0, -1))) ? Math.round(Number(String(indexDataObj.storeNumber).slice(0, -1))) : ''
                      }}</span>
                    <!--<span class="val">111</span>-->
                    <span class="unit">万</span>
                  </p>
                </div>
                <!--<div class="weekClass">
                  <label>较昨天 </label> <span class="weekTitle"></span>
                  <span><img src="../../assets/img/up.png" alt="" /></span>
                </div>-->
              </div>
              <div class="rightBox">
                <div class="kChart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--中间-->
      <!--<div class="centerContain">
        <div v-for="item in 6" :key="item" class="centerContainItem">
          <p>文案</p>
        </div>
      </div>-->
      <!--下面-->
      <!--<div class="footerContain">
        <p>杭州佐客科技有限公司</p>
      </div>-->
      <!--<div class="title2">
        <p>
          <span>我的微应用</span>
        </p>
      </div>
      <div class="secondContain">
        <div v-for="item in microApplicationData" class="itemCont" @click="largeScreenClick(item.id)">
          <p class="">{{ item.typeStr }}</p>
          <el-icon class="el-icon-delete editFilter" style="color: #4AB8FF;font-size: 16px;cursor: pointer;" @click.stop="editClick(item.id)">
            <Edit/>
          </el-icon>
          <el-icon class="el-icon-delete delFilter" style="color: #FE4761;font-size: 16px;cursor: pointer;" @click.stop="deleteClick(item.id)">
            <Delete/>
          </el-icon>
          <div v-for="items in item.subTypeStrList">
            <p class="tit">{{ items }}</p>
          </div>
        </div>
        <div class="itemCont itemPlus" @click="addClick()">
          <el-icon>
            <Plus/>
          </el-icon>
        </div>
      </div>-->
      <div class="title3">
        <p>
          <span>数据大屏控制</span>
        </p>
      </div>
      <div class="largeScreenBox">
        <el-tooltip v-for="item in indexDataObj.dataScreenList" class="box-item" content="单击查看大屏详情" effect="dark"
                    placement="top">
          <div class="largeScreenUl" @click="detailClick(item.requestUrl)">
            <div class="largeScreenTitle">
              <img alt="" src="../../assets/img/largeScreenTitle.png"/>
              <span>
							{{ item.name }}
						</span>
            </div>
            <div class="mask"></div>
            <div class="largeScreenContent">
              <el-image :src="item.imageUrl" fit="scale-down"/>
            </div>
            <el-button class="detailButton" style="width:100%" type="primary" @click="detailClick(item.requestUrl)">
              查看详情
            </el-button>
          </div>
        </el-tooltip>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑':'新建'" width="60%">
      <el-form>
        <el-select v-model="typeVal" clearable style="width:160px">
          <el-option label="能源大屏" value="1"></el-option>
        </el-select>
        <div style="margin-left: 30px">
          <span>是否公开标识</span>
          <el-switch v-model="openFlag" style="margin-left: 36px;"></el-switch>
        </div>
      </el-form>
      <el-form v-show="typeVal==1" ref="fromRef" :inline="true" :model="formData">
        <div v-for="(item,index) in formData.energyScreenConfigJson" :key="index" class="rowItem">
          <div v-if="formData.energyScreenConfigJson.length!==1" style="display: flex;align-items: center">
            <el-icon class="el-icon-delete" style="color: #FE4761;font-size: 16px;cursor: pointer;"
                     @click="delCompound(index)">
              <Delete/>
            </el-icon>
          </div>
          <div>
            <div>
              <el-select v-model="item.type" clearable @change="typeSelect(item.type,index)">
                <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"
                           style="width:100%">
                </el-option>
                <!--<el-option label="电总功率" value="1"></el-option>-->
              </el-select>
            </div>
            <p>=</p>
          </div>
          <div v-for="(item_child,index_child) in item.dataList" :key="index_child"
               :style="{alignItems:item_child.type===''?'center':'start'}" class="contItemBox">
            <div v-if="index_child!==0" class="symbol">
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='+'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'+')">+</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='-'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'-')">-</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='×'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'×')">×</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='÷'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'÷')">÷</p>
            </div>
            <div class="contItem">
              <el-select v-model="item_child.type" clearable placeholder="请选择参数类型">
                <el-option :value="1" label="指标"/>
                <el-option :value="0" label="数值"/>
              </el-select>
              <div v-if="item_child.type!==''" class="contBox">
                <el-icon v-if="item.dataList.length>1" class="delIcon" @click="delControl(index,index_child)">
                  <CircleClose/>
                </el-icon>
                <el-select v-show="item_child.type==1" ref="elSelectTable" v-model="item_child.typeName"
                           :filter-method="filterMethod" :popper-append-to-body="false"
                           class="select-unitName select-option-father" clearable filterable
                           popper-class="select_report_and_type">
                  <el-option :value="dataItemList">
                    <el-tree ref="treeRef" :expand-on-click-node=true :filter-node-method="filterNode" :load="treeList"
                             :props="props" highlight-current lazy
                             @node-click="clickUnitTreeNodeChild($event,index,index_child)"/>
                  </el-option>
                </el-select>
                <div class="center">
                  <el-input v-if="item_child.type==0" v-model="item_child.val" class="val" placeholder="请输入"
                            type="number"/>
                  <p v-if="item_child.type==1">{{ item_child.deviceName }}</p>
                  <p v-if="item_child.type==1">{{ item_child.typeName }}</p>
                </div>
                <p v-if="item_child.type==1" class="unit">{{ item_child.unit }}</p>
              </div>
              <div v-if="item_child.type==1" class="contTime">
                <el-checkbox v-model="item_child.difference" label="差值" size="large"/>
              </div>
            </div>
          </div>
          <div>
            <el-icon class="addIcon" @click="addControl(index)">
              <CirclePlus/>
            </el-icon>
          </div>
        </div>
        <div style="text-align: center;width:100%">
          <el-button :icon="Plus" style="margin-bottom: 0;width: 50%;" type="primary" @click="addCompound">
          </el-button>
        </div>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
          <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref} from "vue"
import './homePage.less'
import * as echarts from 'echarts';
import {useRouter, useRoute} from 'vue-router';
import qs from "qs";
import {CascaderProps, ElMessage, ElMessageBox} from "element-plus";
import {Delete, ZoomIn, Plus, Back, CircleClose, CirclePlus, Edit} from '@element-plus/icons-vue'
import {
  indexData,
  microApplicationList,
  saveMicroApplication,
  detailMicroApplication,
  deleteMicroApplication
} from '@/api/modules/homePage.js'
import moment from "moment";
import {getTreeListApi} from "@/api/modules/control";
import letterList from "@/assets/js/letterList";

let router = useRouter()
const route = useRoute();
let indexDataObj = ref({}) //首页数据
//首页数据
const getIndexData = function () {
  indexData().then(res => {
    //console.log(res)
    if (res.data.code === 0) {
      indexDataObj.value = res.data.result
      console.log(indexDataObj.value.dataScreenList)
      chatLoad()
      chatLoad2()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//查看大屏
const detailClick = function (val) {
  window.open(`#${val}`, '_blank');
}
const chatLoad2 = function () {
  var chartDom = document.querySelector('.kChart2')
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 0,
      top: 300,
      right: 0,
      bottom: 0,
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center',
      icon: 'circle',
      itemWidth: 6,
      itemHeight: 6,
      textStyle: {
        fontSize: 12,
        color: '#4E5969'
      }
    },
    series: [{
      name: '',
      type: 'pie',
      center: ['50%', '60%'],
      radius: ['50%', '65%'],
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      data: [{
        value: indexDataObj.ticketNumberComplete,
        name: '已完结',
        itemStyle: {
          color: '#165DFF'
        }
      },
        {
          value: indexDataObj.ticketNumberTotal - indexDataObj.ticketNumberComplete,
          name: '待处理',
          itemStyle: {
            color: '#90ACFF'
          }
        }
      ]
    }]
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
const chatLoad = function () {
  var chartDom = document.querySelector('.kChart')
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    grid: {
      x: 0, //左留白
      y: 0, //上留白
      x2: 0, //右留白
      y2: 0 //下留白
    },
    xAxis: {
      type: 'category',
      data: indexDataObj.value.storeWeekTimeList,
      axisLabel: { //坐标轴刻度标签
        show: false
      },
      axisLine: { //坐标轴轴线
        show: false
      },
      axisTick: { //坐标轴刻度
        show: false
      }
    },
    yAxis: {
      splitLine: { //坐标轴在grid区域中的分割线
        show: false
      },
      axisLabel: { //坐标轴刻度标签
        show: false
      },
      min: function (value) {
        return value.min;
      }
      //logBase: 10, // 底数
    },
    series: [{
      data: indexDataObj.value.storeWeekDataList,
      type: 'bar',
      itemStyle: {
        color: function (params) {
          // 定义换色数组
          var colorList = ['#2CAB40', '#86DF6C']
          if (params.dataIndex % 2 == 0) {
            return colorList[0]
          } else {
            return colorList[1]
          }
        },
        borderRadius: [4, 4, 4, 4]

      },
      //barMinHeight:30
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
//微应用列表
let microApplicationData = ref([])
const microApplicationListFun = function () {
  microApplicationList(null).then(res => {
    if (res.data.code === 0) {
      microApplicationData.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 新增编辑-----------------------------------------------------------------
// 新增编辑弹窗
let dialogFormVisible = ref(false)

let typeVal = ref()
let typeList = ref([{
  name: '电总功率',
  id: '1'
},
  {
    name: '用电量',
    id: '2'
  }
])
let openFlag = ref(false)
// 新增编辑表单数据
let formData = ref({
  energyScreenConfigJson: [{
    formula: 'A',
    type: '',
    dataList: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      difference: '',
      deviceId: '',
      deviceName: ''
    }]
  },]
})
// 类型选择事件
const typeSelect = function (type, index) {
  var num = 0;
  formData.value.energyScreenConfigJson.forEach(item => {
    if (type == item.type) {
      num++
      if (num > 1) {
        formData.value.energyScreenConfigJson[index].type = ''
        ElMessage({
          message: '字典重复！',
          type: 'info',
        })
      }
    }
  })
}
// 点击新增
const addClick = function () {
  dialogFormVisible.value = true
  formData.value.id = ''
  typeVal.value = ''
  openFlag.value = false
  formData.value = {
    energyScreenConfigJson: [{
      formula: 'A',
      type: '',
      dataList: [{
        val: "",
        type: '',
        unit: "",
        typeId: '',
        typeName: "",
        replaceStr: "A",
        difference: '',
        deviceId: '',
        deviceName: ''
      }]
    },]
  }
}
// 点击编辑
const editClick = function (id, row) {
  dialogFormVisible.value = true
  detailMicroApplication(qs.stringify({
    id: id
  })).then(res => {
    if (res.data.ok) {
      formData.value = {
        id: id,
        energyScreenConfigJson: res.data.result.energyScreenConfigList
      }
      typeVal.value = String(res.data.result.type)
      openFlag.value = res.data.result.openFlag == 1 ? true : false
      for (let i = 0; i < formData.value.energyScreenConfigJson.length; i++) {
        formData.value.energyScreenConfigJson[i].type = String(formData.value.energyScreenConfigJson[i].type)
        for (let j = 0; j < formData.value.energyScreenConfigJson[i].dataList.length; j++) {
          formData.value.energyScreenConfigJson[i].dataList[j].difference = formData.value.energyScreenConfigJson[i].dataList[j].difference === 1 ? true : false
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 提交
let fromRef = ref()
const save = function () {
  // 提交表单
  if (fromRef) {
    fromRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        // 处理差值
        for (let i = 0; i < formData.value.energyScreenConfigJson.length; i++) {
          for (let j = 0; j < formData.value.energyScreenConfigJson[i].dataList.length; j++) {
            formData.value.energyScreenConfigJson[i].dataList[j].difference = formData.value.energyScreenConfigJson[i].dataList[j].difference ? 1 : 0
          }
        }
        // 处理符号和字母
        for (let i = 0; i < formData.value.energyScreenConfigJson.length; i++) {
          for (let j = 0; j < formData.value.energyScreenConfigJson[i].dataList.length; j++) {
            formData.value.energyScreenConfigJson[i].dataList[j].replaceStr = letterList()[j].name
          }
          formData.value.energyScreenConfigJson[i].formula = letterList()[0].name + formData.value.energyScreenConfigJson[i].formula.substr(1);
          for (let j = 0; j < formData.value.energyScreenConfigJson[i].formula.length; j++) {
            if (j % 2 == 0 && j !== 0) {
              formData.value.energyScreenConfigJson[i].formula = formData.value.energyScreenConfigJson[i].formula.substr(0, j) + letterList()[j / 2].name + formData.value.energyScreenConfigJson[i].formula.substr(j + 1);
            }
          }
        }
        delete formData.value.date
        let params = {
          id: formData.value.id ? formData.value.id : '0',
          type: typeVal.value,
          openFlag: openFlag.value ? '1' : '0',
          energyScreenConfigJson: JSON.stringify(formData.value.energyScreenConfigJson)
        }
        //console.log(params)
        saveMicroApplication(qs.stringify(params)).then(res => {
          if (res.data.ok) {
            microApplicationListFun()
            dialogFormVisible.value = false
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}

// 点击删除
const deleteClick = function (id, row) {
  ElMessageBox.confirm('请确认是否要删除此应用，删除后数据不可恢复', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    deleteMicroApplication(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.ok) {
        microApplicationListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}

// 弹窗的参数操作------------------------------------
// 符号点击
const symbolClick = function (index, index_child, e) {
  let str = formData.value.energyScreenConfigJson[index].formula
  formData.value.energyScreenConfigJson[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
}
// 添加行
const addCompound = function () {
  formData.value.energyScreenConfigJson.push({
    formula: 'A',
    name: '',
    type: '',
    dataList: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      difference: '',
      deviceId: '',
      deviceName: ''
    }]
  })
}
// 删除行
const delCompound = function (index) {
  ElMessageBox.confirm('请确认是否要删除，删除后数据不可恢复', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    formData.value.energyScreenConfigJson.splice(index, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 添加参数值
const addControl = function (index) {
  formData.value.energyScreenConfigJson[index].formula = formData.value.energyScreenConfigJson[index].formula.concat('+D')
  formData.value.energyScreenConfigJson[index].dataList.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    difference: '',
    deviceId: '',
    deviceName: ''
  })
}
// 删除参数值
const delControl = function (index, index_child) {
  ElMessageBox.confirm('请确认是否要删除此应用，删除后数据不可恢复', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    let str = formData.value.energyScreenConfigJson[index].formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    formData.value.energyScreenConfigJson[index].formula = strA.concat(strB)
    formData.value.energyScreenConfigJson[index].dataList.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data, index, index_child) {
  if (data.type == 3) {
    formData.value.energyScreenConfigJson[index].dataList[index_child].typeName = data.name
    formData.value.energyScreenConfigJson[index].dataList[index_child].typeId = data.id
    formData.value.energyScreenConfigJson[index].dataList[index_child].deviceName = data.equipmentName
    formData.value.energyScreenConfigJson[index].dataList[index_child].unit = data.unitName
    formData.value.energyScreenConfigJson[index].dataList[index_child].deviceId = data.equipmentId
    formData.value.energyScreenConfigJson[index].dataList[index_child].deviceName = data.equipmentName
    elSelectTable.value[index_child].blur();
  } else {
    // ElMessage({
    // 	type: 'info',
    // 	message: '当前选择不是指标选项'
    // });
  }
}

// 下拉搜索
const filterMethod = function (val) {
  treeRef.value[0].filter(val)
}
const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}
const largeScreenClick = function (id) {
  router.push({
    path: '/admin/energyLargeScreen',
    query: {
      id: id
    }
  })
}

onMounted(() => {
  getIndexData()
  microApplicationListFun()
})
</script>

<style lang="less" scoped>

</style>