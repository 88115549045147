<template>
  <div class="admin">
    <div class="common-layout">
      <el-container class="el-containerAll">
        <!--头部-->
        <el-header>
          <!--logo和名称-->
          <div class="left">
            <img alt="" src="../../assets/img/logo.png" style="height: 26px">
            <!--得力logo-->
            <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/deliLogo.png"-->
            <!--                 style="height: 24px">-->
            <!--惠生logo-->
            <!--            <img alt="" src="../../assets/img/hsLogo.png" style="height: 24px">-->
            <p>数字能源中心</p>
            <!--导航是否展开-->
            <div class="flagBox">
              <el-icon v-if="!isCollapse" class="iconLeft flagIcon" @click="isCollapse=true">
                <Fold/>
              </el-icon>
              <el-icon v-if="isCollapse" class="iconRight flagIcon" @click="isCollapse=false">
                <Expand/>
              </el-icon>
            </div>
          </div>
          <div class="right">
            <!--            <el-popover :width="400" placement="bottom-end" title="消息通知" trigger="hover">-->
            <!--              <div class="info">-->
            <!--                <el-empty v-if="messageInfo.length===0" :image-size="80" description="暂无数据"/>-->
            <!--                <div v-for="(item,index) in messageInfo" :key="index" class="infoBox">-->
            <!--                  <p>【告警通知】{{ item.date }}</p>-->
            <!--                  <p>{{ item.time }}|{{ item.info }}</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="lookAll" @click="lookAllClick">查看全部消息</div>-->
            <!--              <template #reference>-->
            <!--                <i class="iconfont icon-xiaoxi"></i>-->
            <!--              </template>-->
            <!--            </el-popover>-->

            <el-popover :width="300" placement="bottom" trigger="hover">
              <div class="userInfo">
                <div class="nameBox">
                  <p>{{ userInfo.name }}</p>
                  <p @click="setClick">设置</p>
                </div>
                <p v-for='(item,index) in userInfo.roleNameList' :key="index" class="role">{{ item }}</p>
              </div>
              <div class="loginOut" @click="loginOutClick">
                <i class="iconfont icon-exit"></i>
                <span>退出登陆</span>
              </div>
              <template #reference>
                <div class="userInfoDom">
                  <!--                  <i class="iconfont icon-topnav_btn_user_nor"></i>-->
                  <img alt="" src="../../assets/img/user.png">
                  <p>您好，{{ userInfo.name }}！</p>
                </div>

              </template>
            </el-popover>
          </div>
        </el-header>
        <!--内容-->
        <el-container>
          <!--侧边-->
          <el-aside v-if="asideVisible">
            <!--导航-->
            <el-menu :collapse="isCollapse" :default-active="$route.path" class="el-menu-vertical-demo" router
                     unique-opened>
              <router-link v-for="(item,index) in menuList" :key="index" :to="item.menuKey">
                <el-menu-item v-if="!item.subMenuFlag" :index="item.menuKey" @click="menuKeyClick(item.menuKey)">
                  <img :src="item.logo" alt="">
                  <template #title>{{ item.name }}</template>
                </el-menu-item>
                <el-sub-menu v-if="item.subMenuFlag" :index="item.menuKey">
                  <template #title>
                    <img :src="item.logo" alt="">
                    <span v-if="!isCollapse">{{ item.name }}</span>
                  </template>
                  <el-menu-item-group>
                    <router-link v-for="(item_child,index_child) in item.subList" :key="index_child"
                                 :to="item_child.menuKey">
                      <el-menu-item :index="item_child.menuKey">{{ item_child.name }}</el-menu-item>
                    </router-link>
                  </el-menu-item-group>
                </el-sub-menu>
              </router-link>
            </el-menu>
          </el-aside>
          <!--主体-->
          <el-main>
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue"
import {useRouter, onBeforeRouteUpdate} from 'vue-router';
import {DataLine, Postcard, User, Fold, Expand, UserFilled} from '@element-plus/icons-vue'
import {getLoginDetailApi} from '../../api/modules/login'
import {ElMessage} from 'element-plus'

// 导航是否展开
let isCollapse = ref(false)
// 监听导航是否展开来判断侧边栏宽度
watch(isCollapse, (newVal, oldVal) => {
  if (!newVal) {
    document.querySelector('.el-aside').style.width = '208px'
  } else {
    document.querySelector('.el-aside').style.width = 'auto'
  }
})

// 消息信息
let messageInfo = ref([{
  date: '2023-10-9 22:15',
  time: '22:15:29',
  info: 'ncejrnvhr nciuevvn nviuenwi(cencjevnh)'
},
  {
    date: '2023-10-9 22:15',
    time: '22:15:29',
    info: 'ncejrnvhr nciuevvn nviuenwi(cencjevnh)'
  }
])
// 点击查看全部消息
const lookAllClick = function () {
  router.push('/admin/messagePage')
}
const menuKeyClick = function (val) {
  if (val == '/admin/device') {
    window.localStorage.setItem('name', '')
  }
}
// 用户
let role = ref('全功能')

// 用户设置
const setClick = function () {
  router.push('/admin/userSetting')
}
// 退出登陆
const loginOutClick = function () {
  router.push('/login')
  window.localStorage.clear()
}

let router = useRouter()
const path = router.currentRoute.value.path;
let asideVisible = ref(true) //侧边栏是否展示
if (path === '/admin/userSetting' || path === '/admin/messagePage') {
  asideVisible.value = false
} else {
  asideVisible.value = true
}

watch(() => router.currentRoute.value.path, (newVal, oldVal) => {
  if (newVal === '/admin/userSetting' || newVal === '/admin/messagePage') {
    asideVisible.value = false
  } else {
    asideVisible.value = true
  }

  if (newVal === '/admin/homePage') {
    document.querySelector('.el-containerAll').style.backgroundImage = "url('https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/homeBackImg.png')"
  } else {
    document.querySelector('.el-containerAll').style.backgroundImage = "url('https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/backImg.png')"
  }
})

let menuList = ref([])
let userInfo = ref({})
onMounted(() => {
  menuList.value = JSON.parse(window.localStorage.getItem('menuList'))
  getLoginDetailApi().then(res => {
    if (res.data.code === 0) {
      userInfo.value = res.data.result
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
</script>

<style lang="less">
.admin {
  //background-color: #F7F8FAFF;
}

.el-container:has(.hngfScreen) {
  min-width: 0 !important;
}

.el-container:has(.hncnScreen) {
  min-width: 0 !important;
}

.el-container {
  width: 100vw;
  height: 100vh;
  min-width: 1200px !important;
  background: #F7F8FAFF url("https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/backImg.png") no-repeat 0 0;
  background-size: 100% 100%;

  .el-container {
    background: none;
    height: calc(100vh - 56px);
  }

  .el-header {
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: space-between;
    align-items: center;
    //background-color: rgba(255, 255, 255, 0.04);
    background-color: #E5ECF9;

    .left {
      display: flex;
      align-items: center;

      > p {
        margin-left: 16px;
        padding-left: 16px;
        border-left: 1px solid rgba(0, 0, 0, 0.06);
        font-size: 16px;
        font-weight: 500;
      }

      .flagBox {
        transform: translate(20px, 4px);

        .flagIcon {
          font-size: 16px;
          color: #303134;
        }
      }
    }

    .right {
      display: flex;

      .userInfoDom {
        margin-left: 20px;
        display: flex;

        img {
          width: 32px;
          height: 32px;
        }

        > p {
          font-size: 14px;
          line-height: 36px;
          margin: 0 10px;
          color: rgba(0, 0, 0, 0.65);
        }

      }

      .iconfont {
        font-size: 22px;
      }

      .iconfont:hover {
        color: #409eff;
      }
    }
  }

  .el-aside {
    //width: auto;
    height: 100%;
    max-width: 240px;
    position: relative;
    overflow: visible;
    //background-color: #EDF0F6;
    background-color: transparent;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    //导航

    .el-menu::-webkit-scrollbar {
      width: 3px; /* 设置滚动条的宽度 */
    }

    .el-menu::-webkit-scrollbar-thumb {
      background: #999; /* 设置滑块的背景颜色 */
      border-radius: 3px;
    }

    .el-menu {
      border-right: 0;
      height: 100%;
      overflow: auto;
      background-color: #F0F3F9;
      //background-color: transparent;

      .el-menu--inline {
        overflow: hidden;
      }

      .el-menu-item.is-active {
        background-color: #fff !important;
        //background-color: rgba(0, 0, 0, 0.05) !important;
        //color: #ffffff !important;
        color: rgba(22, 93, 255, 1) !important;
        margin: 0 8px;
        padding: 0 12px;
        border-radius: 4px;
      }

      ul li ul {
        .el-menu-item.is-active {
          padding: 0 32px;
        }
      }

      .is-active .el-sub-menu__title, .el-menu-item.is-active, .el-menu-item:hover, .el-sub-menu__title:hover {
        color: rgba(22, 93, 255, 1);
        background-color: transparent;
      }

      .el-menu-item,
      .el-sub-menu__title {
        height: 40px;
        margin: 4px 0 8px;

        img {
          width: 22px;
          height: 22px;
          padding: 0 4px;
          margin-right: 5px;
        }
      }

      .el-menu-item-group__title {
        padding: 0;
      }
    }

  }

  .el-main {
    //background-color: rgb(245, 245, 246);
    //background-color: #EDF0F6;
    background-color: transparent;
    padding: 0;
    height: calc(100vh - 56px);
  }
}

//提示弹窗
.el-popper.is-light.el-popover {
  padding: 0 !important;
  font-size: 14px;
  color: rgba(0, 0, 0, .8) !important;

  .el-popover__title {
    padding: 12px;
    margin: 0;
  }

  .info {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    padding: 0 12px;

    .infoBox {
      padding: 12px 5px;
      border-bottom: 1px solid #efefef;
      line-height: 20px;

      p:first-child {
        color: rgba(0, 0, 0, .45);
        font-size: 12px;
      }
    }

    .infoBox:last-child {
      border-bottom: 0;
    }
  }

  .lookAll {
    color: #409eff;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }

  .userInfo {
    padding: 12px;

    .nameBox {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      p:last-child {
        color: #409eff;
        cursor: pointer;
      }
    }

    .role {
      display: inline-block;
      margin-right: 6px;
      width: auto;
      height: 22px;
      padding: 3px 8px;
      border: 1px solid #d9d9d9;
      background-color: #fafafa;
      text-align: center;
      line-height: 22px;
      font-size: 12px;
      margin-top: 15px;
    }
  }

  .loginOut {
    padding: 12px;
    border-top: 1px solid #efefef;
    cursor: pointer;

    span {
      margin-left: 5px;
    }
  }

  .loginOut:hover {
    color: #409eff;
  }
}
</style>
