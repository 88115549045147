import {api} from "@/api";

// 分页获取仪表盘
export const getListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/panelParam/page',
        method: 'POST',
        data: data
    })
}

// 删除仪表盘
export const delListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/panelParam/delete',
        method: 'POST',
        data: data
    })
}

// 保存仪表盘
export const saveListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/panelParam/save',
        method: 'POST',
        data: data
    })
}