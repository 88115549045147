<template>
  <div class="sourcePageEditor">
    <div @click="comeBack" style="margin-bottom: 8px;cursor: pointer"> {{
        '< 返回'
      }}
    </div>
    <div class="stream-details" v-loading="showLoading">
      <div class="stream-steps">
        <div class="title">{{ `${route.query.oper === 'add' ? '添加' : '编辑'}源配置组` }}</div>
        <div v-if="route.query.oper === 'add'" class="steps-container">
          <div style="display: flex;align-items: center;">
            <img src="http://192.168.3.253:8085/web/common/img/done-blue.fca2daac.svg" alt="">
            <span class="active-text">{{ `选择源类型` }}</span>
          </div>
          <div class="line"></div>
          <div style="display: flex;align-items: center;">
            <img
                :src="active === 2?'http://192.168.3.253:8085/web/common/img/done-blue.fca2daac.svg':'http://192.168.3.253:8085/web/common/img/done-gray.33058c88.svg'"
                alt="">
            <span :class="active === 2 ? 'active active-text' :''">{{ `源配置` }}</span>
          </div>
        </div>
        <div v-if="active === 1" class="steps-name">
          <el-form
              ref="ruleFormRef"
              style="max-width: 600px"
              :model="addFormDate"
              :rules="rules"
              label-width="auto"
              class="demo-ruleForm"
              status-icon
              label-position="top"
          >
            <el-form-item label="名称" prop="name">
              <el-input v-model="addFormDate.name"/>
            </el-form-item>
            <el-form-item label="源类型">
              <el-radio v-model="addFormDate.type" label="流" border>流</el-radio>
              <el-radio v-model="addFormDate.type" label="扫描表" border>扫描表</el-radio>
              <el-radio v-model="addFormDate.type" label="查询表" border>查询表</el-radio>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="margin-bottom: 100px;" v-if="active === 1">
        <div class="stream-content" ref="myContainer">
          <div class="flex-container" v-if="loading">
            <div v-for="(item, index) in cellType" :key=" index"
                 class="stream-details-dome">
              <div
                  @click="selectCell(item.name)"
                  :class="['template-card',activeCell===item.name?'active-cell':'']">
                <div class="left-content">
                  <img :src="item.url" alt="">
                </div>
                <div class="card-content">
                  <div class="template-label">{{ item.initial }}</div>
                  <div class="template-content">
                    {{ item.about ? item.about.description.zh : '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--配置页-->
      <div v-else :class="['container']" ref="myContainer">
        <div style="max-width: 550px;">
          <currentComponent :data="configGroup" :key="deployKey" ref="formChild"></currentComponent>
<!--          <currentComponent :name="false" :data="configGroup" :key="deployKey" ref="formChild"></currentComponent>-->
        </div>
      </div>
      <div v-loading="showLoading" :style="{width:width+'px'}" class="deploy-footer">
        <!--        <div>
                  <el-button type="primary" @click="configuration">提交</el-button>
                  <el-button @click="comeBack">取消</el-button>
                  <el-button @click="testConnection">测试连接</el-button>
                </div>-->
        <div v-if="active === 1">
          <el-button type="primary" @click="next(2)">下一步</el-button>
          <el-button @click="comeBack">取消</el-button>
        </div>
        <div v-else>
          <el-button type="primary" @click="configuration">提交</el-button>
          <el-button v-if="route.query.oper === 'add'" @click="next(1)">上一步</el-button>
          <el-button @click="testConnection">测试连接</el-button>
          <el-button @click="comeBack">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {computed, onMounted, onUnmounted, onUpdated, reactive, ref, watch} from "vue"
import {useRoute} from "vue-router";
import {streamConfig, streamType, testConnections} from "@/api/modules/sourcePageEditor";
import userSourcePageEditorData from "@/pages/dataProcessing/sourcePage/utils/data";
import mqtt from "@/pages/dataProcessing/sourcePage/formDialog/mqtt.vue";
import file from "@/pages/dataProcessing/sourcePage/formDialog/file.vue";
import httppull from "@/pages/dataProcessing/sourcePage/formDialog/httppull.vue";
import httppush from "@/pages/dataProcessing/sourcePage/formDialog/httppush.vue";
import neuron from "@/pages/dataProcessing/sourcePage/formDialog/neuron.vue";
import simulator from "@/pages/dataProcessing/sourcePage/formDialog/simulator.vue";
import sql from "@/pages/dataProcessing/sourcePage/formDialog/sql.vue";
import video from "@/pages/dataProcessing/sourcePage/formDialog/video.vue";
import redis from "@/pages/dataProcessing/sourcePage/formDialog/redis.vue";
import router from "@/router";
import {addConfigGroup} from "@/api/modules/sourcePage";
import {ElMessage} from 'element-plus'
import {useStore} from "vuex";

let active = ref(1);
const {
  formData,
  activeCell,
  arrayList,
} = userSourcePageEditorData()
//修改
const formChild = ref(null);
const ruleFormRef = ref(null);
const configGroup = ref({})
const deployKey = ref(0)
let deployGroup = ref(null)
let loading = ref(false)
const store = useStore();
//弹窗类型
const componentType = ref('mqtt');
const rules = reactive({
  name: [{required: true, message: '请填名称', trigger: 'blur'}],
})
let route = useRoute();
const next = async (n) => {
  try {
    if (n === 2) {
      const data = await ruleFormRef.value.validate();
      if (data) {
        active.value = n;
      }
    } else {
      active.value = n;
    }
  } catch (error) {
    ElMessage({
      message: "请填写内容",
      type: 'error',
    })
    // console.log(error)
  }
};
const width = ref(0)
const myContainer = ref(null);
const inquiry = ref(null);
const addFormDate = ref({
  name: '',
  type: '流'
})
/*--------方法区域-----------*/
//返回
const comeBack = () => {
  router.push('/admin/dataConfigurationPage')
}
// 处理窗口大小变化的函数
const handleResize = () => {
  if (myContainer.value) {
    width.value = myContainer.value.clientWidth;
  }
};
//测试连接
const testConnection = async () => {
  try {
    const formData = await formChild.value.getFormData();
    const data = {...formData}
    delete data.name
    for (let key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        // 检查属性值是否为空字符串
        if (data[key] === '') {
          // 删除空字符串属性
          delete data[key];
        }
      }
    }
    let path = route.query.type;
    if (route.query.oper === 'add') {
      path = activeCell.value;
    }
    if (formData) {
      testConnections(path, formData).then(res => {
        if (res.status === 200) {
          ElMessage({
            message: "连接成功",
            type: 'success',
          })
        }
        // console.log(res)
      }).catch(error => {
        // console.log(error)
        ElMessage({
          message: error.message,
          type: 'error',
        })
      })
    }
  } catch (error) {
    // console.log(error)
  }
}
// 提交弹窗
const configuration = async () => {
  try {
    const formData = await formChild.value.getFormData();
    const data = {...formData}
    delete data.name
    for (let key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        // 检查属性值是否为空字符串
        if (data[key] === '') {
          // 删除空字符串属性
          delete data[key];
        }
      }
    }
    // 添加配置组 path , name ,data
    let name = route.query.confKeyName;
    let path = route.query.type;
    if (route.query.oper === 'add') {
      path = activeCell.value;
      name = addFormDate.value.name
    }
    console.log(path, name, data)
    addConfigGroup(path, name, data).then(res => {
      // console.log(res.message)
      if (res.status === 200) {
      	if(route.query.oper === 'add'){
      		ElMessage({
          message: "添加成功",
          type: 'success',
        })
      	}else{
      		ElMessage({
          message: "编辑成功",
          type: 'success',
        })
      	}
        
      }
      comeBack()
    }).catch(error => {
      ElMessage({
        message: error.message,
        type: 'error',
      })
    })
  } catch (error) {
    // console.log("error", error)
  }
}
//选择卡片
const selectCell = (item) => {
  activeCell.value = item;
  formData.value.configurationGroup = '';
  formData.value.dataSource = activeCell.value.replace(/\s/g, '').toLowerCase()
}
//添加配置文件时的默认值
const addToConfigGroup = () => {
  switch (componentType.value) {
    case 'mqtt':
      return {"server": "tcp://127.0.0.1:1883", "protocolVersion": "3.1.1", "insecureSkipVerify": false, "qos": 1};
    case 'file':
      return {
        "actionAfterRead": 0,
        "columns": [],
        "fileType": "json",
        "hasHeader": false,
        "ignoreEndLines": 0,
        "ignoreStartLines": 0,
        "parallel": false,
        "sendInterval": 0
      };
    case 'httppull':
      return {
        "url": "http://127.0.0.1:5536",
        "method": "get",
        "interval": 1000,
        "timeout": 5000,
        "incremental": false,
        "bodyType": "json",
        "insecureSkipVerify": false,
        "responseType": "code",
        "oauth": {"access": {}, "refresh": {}}
      };
    case 'httppush':
      return {"method": "POST"};
    case 'memory':
      return {};
    case 'neuron':
      return {"url": "tcp://127.0.0.1:7081"};
    case 'simulator':
      return {"interval": 1000,};
    case 'sql':
      return {};
    case 'video':
      return {"url": "127.0.0.1:5536", "interval": 1000};
    case 'redis':
      return {"addr": "10.122.48.17:6379"};
    default:
      return {};
  }
}
//获取卡片图片及文字
const getCellType = () => {
  const temp = arrayList.value
  streamType().then(res => {
    const cellData = res.data
    temp.forEach(itemB => {
      // 查找arrayA中name与itemB.name相同的对象
      const matchedItemInA = cellData.find(itemA => itemA.name === itemB.name);
      // 如果找到匹配项，则更新其initial和url
      if (matchedItemInA) {
        itemB.about = matchedItemInA.about
        itemB.about.description.zh = itemB.description.zh
      }
    });
    arrayList.value = arrayList.value.filter(itemA =>
        cellData.some(itemB => itemB.name === itemA.name)
    );
    loading.value = true
  })
  streamType({kind: 'lookup'}).then(res => {
    const cellData = res.data
    temp.forEach(itemB => {
      // 查找arrayA中name与itemB.name相同的对象
      const matchedItemInA = cellData.find(itemA => itemA.name === itemB.name);
      // 如果找到匹配项，则更新其initial和url
      if (matchedItemInA) {
        itemB.about = matchedItemInA.about
        itemB.about.description.zh = itemB.description.zh
      }
    });
    // console.log(arrayList.value)
    inquiry.value = temp.filter(itemA =>
        cellData.some(itemB => itemB.name === itemA.name)
    );
    // console.log(inquiry.value)
    loading.value = true
  })
}
/*--------------计算属性---------------------*/
//动态加载组件
const currentComponent = computed(() => {
  switch (componentType.value) {
    case 'mqtt':
      return mqtt;
    case 'file':
      return file;
    case 'httppull':
      return httppull;
    case 'httppush':
      return httppush;
    case 'memory':
      return;
    case 'neuron':
      return neuron;
    case 'simulator':
      return simulator;
    case 'sql':
      return sql;
    case 'video':
      return video;
    case 'redis':
      return redis;
    default:
      return mqtt;
  }
});
//不同表的卡片数组
const cellType = computed(() => {
  const data = arrayList.value
  if (addFormDate.value.type === '查询表') {
    const allowedNames = ['httppull', 'redis', 'sql'];
    activeCell.value = 'redis';
    return inquiry.value.filter(item => allowedNames.includes(item.name));
  } else if (addFormDate.value.type === '流') {
    activeCell.value = 'mqtt';
  } else {
    activeCell.value = 'file';
  }

  return data.filter(item => item.name !== 'memory' && item.name !== 'redis');
});
// 加载动画
const showLoading = computed(() => {
  if (route.query.oper === 'add') {
    return !loading.value
  }
  return false
})
/*---------------监听------------------------*/
// 选择的卡片
watch(activeCell, (newValue) => {
  componentType.value = newValue.replace(/\s/g, '').toLowerCase()
}, {deep: true})
// 监听步骤
watch(active, (newValue) => {
  if (newValue === 2) {
    if (route.query.oper === 'add') {
      configGroup.value = addToConfigGroup();
      configGroup.value = {...configGroup.value, name: addFormDate.value.name}
      store.state.sourceData['prohibit'] = true
      deployKey.value++
    }
  }
});
// 在组件挂载后获取元素并开始监听宽度变化
onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

// 当组件更新时，也可以检查宽度变化（根据需要使用）
onUpdated(() => {
  handleResize();
});

// 解绑事件监听器，防止内存泄漏
onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
onMounted(() => {
  // console.log(route.query)
  if (route.query.oper !== 'add') {
    active.value = 2;
    let temp = route.query.type
    componentType.value = temp
    streamConfig(temp.replace(/\s/g, '').toLowerCase()).then(res => {
      deployGroup.value = res.data
      configGroup.value = res.data[route.query.confKeyName];
      configGroup.value = {...configGroup.value, name: route.query.confKeyName}
      store.state.sourceData['prohibit'] = true
      deployKey.value++
    })
  } else {
    try {
      getCellType()
    } catch (error) {
      // console.log(error)
    }
  }
});
</script>


<style scoped lang="less">
@import "../sourcePage/sourcePageEditor";

.resourcesPage {
  width: 100%;
  height: auto;
  padding: 28px;
  box-sizing: border-box;
}

.container {
  width: 100%;
  padding: 24px;
  height: auto;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  box-sizing: border-box;
  background: #ffffff;
  margin: 24px 0 100px;
}

.hide-name {
  :deep(.el-form) {
    .el-form-item:first-child {
      display: none !important;
    }
  }
}

.add-module {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  box-sizing: border-box;
  background: #ffffff;
  width: auto;
  height: auto;
}

.steps-name {
  margin-top: 20px;

  :deep(.el-form-item) {
    box-sizing: border-box;

    .el-input__wrapper, .el-input {
      width: 410px !important;
      height: 32px !important;
      border-radius: 8px;
      box-sizing: border-box;
    }
  }
}

.deploy-footer {
  position: fixed;
  bottom: 0;
  height: 94px;
  padding: 0 28px;
  box-sizing: border-box;
  background: #ffffff;
  z-index: 10;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
}

</style>