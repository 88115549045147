<template>
  <div class="strategyLog">
    <div class="title">
      <p>
        <span @click="goBack">策略管理</span>
        <span> / </span>
        <span>日志管理</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        日志管理
      </p>
    </div>
    <div class="content">
      <div class="contain">
        <el-table :data="controlList" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="设备型号" prop="deviceModel" width="110">
          </el-table-column>
          <el-table-column label="设备名称" prop="deviceName" width="110">
          </el-table-column>
          <el-table-column label="状态" prop="deviceStatus" width="90">
            <template #default="scope">
              {{ scope.row.deviceStatus ? '在线' : '离线' }}
            </template>
          </el-table-column>
          <el-table-column label="控制" prop="controlName" width="90">
          </el-table-column>
          <el-table-column label="操作参数" min-width="10%" prop="name">
            <template #default="scope">
              <div v-for='item in scope.row.controlParameters' class="confBox confBoxCopy">
                <p>{{ item.paramName }} =</p>
                <div v-for='(items,i) in item.data' class="contBox">
                  <p class="repaceClass">{{ items.repaceVal }}</p>
                  <div class="contItem">
                    <p v-if="items.type!=3" :title="items.deviceName" class="name">{{ items.deviceName }}
                    </p>
                    <el-tooltip :content="items.typeName" class="item" effect="dark" placement="bottom-start">
                      <p v-if="items.type!=3" class="name">{{ items.typeName }}
                      </p>
                    </el-tooltip>
                    <p v-if="items.type==2" class="time">时效{{ items.expirationTime }}s</p>
                    <p v-if="items.type==3" class="val">{{ items.val }}</p>
                    <p v-if="items.type==1" class="valUnit">{{ items.unit }}</p>
                  </div>
                </div>
                <p>{{ item.paramUnit }}(单位)</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="contain">
        <el-table :data="controlStrategy" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="执行时间" prop="" width="130">
            <template #default="scope">
              {{ scope.row.durationBegin }} - <br> {{ scope.row.durationEnd }}
            </template>
          </el-table-column>
          <el-table-column label="执行频率" prop="frequency" width="110">
          </el-table-column>
          <el-table-column label="触发条件" prop="name">
            <template #default="scope">
              <div v-for='item in scope.row.executionCondition' class="confBox confBoxCopy">
                <div v-for='(items,i) in item.data' class="contBox">
                  <p class="repaceClass">{{ items.repaceVal }}</p>
                  <div class="contItem">
                    <p v-if="items.type!=3" :title="items.deviceName" class="name">{{ items.deviceName }}
                    </p>
                    <el-tooltip :content="items.typeName" class="item" effect="dark" placement="bottom-start">
                      <p v-if="items.type!=3" class="name">{{ items.typeName }}
                      </p>
                    </el-tooltip>
                    <p v-if="items.type==2" class="time">时效{{ items.expirationTime }}s</p>
                    <p v-if="items.type==3" class="val">{{ items.val }}</p>
                    <p v-if="items.type==1" class="valUnit">{{ items.unit }}</p>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="memo" width="110">
          </el-table-column>
        </el-table>
      </div>
      <!--      <div class="searchBox">-->
      <!--        <div class="liBox">-->

      <!--        </div>-->
      <!--      </div>-->
      <div class="contain">
        <div class="liPox" style="margin-bottom: 20px;">
          <div class="liBox">
            <el-select v-model="executionStatus" clearable placeholder="请选择执行状态" style="width:100%">
              <el-option v-for="item in executionList" :key="item.id" :label="item.value" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </div>
          <div class="liBox" style="width:24%">
            <el-date-picker v-model="timeList" end-placeholder="结束时间" popper-class="date-style" range-separator="→"
                            start-placeholder="开始时间" style="width:100%" type="datetimerange"
                            value-format="YYYY-MM-DD HH:mm:ss" @change="getList">
            </el-date-picker>
          </div>
          <div class="liBox" style="margin-left: 20px;">
            <el-button class="searchButton" type="primary" @click="getList">查询</el-button>
            <el-button class="searchButton" @click="resetFun">重置</el-button>
          </div>
        </div>
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="执行时间" prop="executionTime" width="110">
            <template #default="scope">
              <span>{{ scope.row.executionTime.slice(0, 10) }}</span>
              <br/>
              <span>{{ scope.row.executionTime.slice(11, 20) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="执行状态" prop="executionStatus" width="110">
            <template #default="scope">
              <p v-for="(item,index) in executionList" :key="index">
                <span v-if="item.id==scope.row.executionStatus"
                      :style="{color:scope.row.executionStatus==6?'green':'#FE4761'}"> {{ item.value }}</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column label="条件" min-width="10%" prop="name">
            <template #default="scope">
              <div v-for='item in scope.row.conditionInfo' class="confBox confBoxCopy">
                <div v-for='(items,i) in item.data' class="contBox">
                  <p class="repaceClass">{{ items.repaceVal }}</p>
                  <div class="contItem" style="height: 80px;">
                    <p v-if="items.type!=3" :title="items.deviceName" class="name">{{ items.deviceName }}
                    </p>
                    <el-tooltip :content="items.typeName" class="item" effect="dark" placement="bottom-start">
                      <p v-if="items.type!=3" class="name">{{ items.typeName }}
                      </p>
                    </el-tooltip>
                    <!--todo-->
                    <p v-if="items.type!=3" class="name">{{ items.val }}</p>
                    <p v-if="items.type==2" class="time">时效{{ items.expirationTime }}s</p>
                    <p v-if="items.type==3" class="val">{{ items.val }}</p>
                    <p v-if="items.type==1" class="valUnit">{{ items.unit }}</p>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!--          <el-table-column label="执行返回值" prop="returnCode" width="130">-->
          <!--            <template #default="scope">-->
          <!--              <span :style="{color:scope.row.returnCode==0?'green':'#FE4761'}">{{ scope.row.returnCode }}</span>-->
          <!--              <span v-if="scope.row.returnCode==0" style="color: green">设备返回成功</span>-->
          <!--              <span v-if="scope.row.returnCode==1" style="color: #FE4761">设备返回失败</span>-->
          <!--              <span v-if="scope.row.returnCode==2" style="color:#FE4761">空</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize2" :page-sizes="[20,100, 200, 300, 400]"
                         :total="total"
                         background layout="total, prev, pager, next, sizes"
                         small @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue"
import {Back} from '@element-plus/icons-vue'
import './strategyLog.less'
import {useRouter} from 'vue-router';
import {getStrategyListApi, getStrategyLogApi} from "@/api/modules/strategy";
import qs from "qs";
import {ElMessage} from "element-plus";

let router = useRouter()

const goBack = function () {
  router.push('/admin/strategy')
}


let loading = ref(false) //加载状态
let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let pageNumber2 = ref(1) //页码
let pageSize2 = ref(20) //页数
let total = ref(0) //总页数
let tableData2 = ref([]) //列表数据
let deviceName = ref('')
let deviceStatus = ref('')
let executionLogName = ref('')
let timeList = ref([])
let baseObj = ref({})
let executionLogItemList = ref([])
let controlId = ref() //控制id
let controlName = ref()
let deviceList = ref([{
  value: 0,
  label: '离线'
}, {
  value: 1,
  label: '在线'
}])
let executionStatus = ref()
let executionList = ref([
  {
    id: 0,
    value: '条件失败',
  },
  {
    id: 1,
    value: '条件无数据',
  },
  {
    id: 2,
    value: '条件数据不符合时效要求',
  },
  {
    id: 3,
    value: '控制参数计算出错',
  },
  {
    id: 4,
    value: '控制参数无数据',
  },
  {
    id: 5,
    value: '控制参数数据不符合时效要求',
  },
  {
    id: 6,
    value: '发送成功',
  },
  {
    id: 7,
    value: '发送失败',
  }
])
//每页多少
const handleSizeChange = function (val) {
  pageSize2.value = val;
  getList();
}
//更改页数
const handleCurrentChange = function (val) {
  console.log(val)
  pageNumber2.value = val;
  getList();
}
let controlList = ref([]) //控制列表
let controlStrategy = ref([]) //策略控制列表
let tableData = ref([])

//控制策略列表
const getStrategyList = function () {
  getStrategyListApi({
    Page: {
      pageSize: pageSize.value,
      pageNo: pageNumber.value
    },
    controlStrategyId: router.currentRoute.value.query.controlStrategyId
  }).then(res => {
    if (res.data.code === 0) {
      controlList.value = []
      controlStrategy.value = []
      let arr = res.data.result.data
      arr.forEach(item => {
        item.controlParameters = []
        item.strategy.forEach(items => {
          items.controlParameters = JSON.parse(items.controlParameters)
          items.controlParameters.forEach(item_s => {
            var index;
            item_s.data.forEach(itemc => {
              index = item_s.formula.indexOf(itemc.replaceStr)
              itemc.repaceVal = item_s.formula.slice(index - 1, index)
            })
          })
          items.executionCondition = JSON.parse(items.executionCondition)
          items.executionCondition.forEach(item_s => {
            var index;
            item_s.data.forEach(itemc => {
              index = item_s.formula.indexOf(itemc.replaceStr)
              itemc.repaceVal = item_s.formula.slice(index - 1, index)
            })
          })
          controlStrategy.value.push(items)
          item.controlParameters.push(...items.controlParameters)
        })
        controlList.value.push(item)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err)
  })
}
//获取列表
const getList = function () {
  loading.value = true
  var start_date;
  var end_date;
  if (timeList.value && timeList.value.length) {
    start_date = timeList.value[0]
    end_date = timeList.value[1]
  }
  getStrategyLogApi(qs.stringify({
    pageSize: pageSize2.value,
    pageNum: pageNumber2.value,
    deviceName: deviceName.value,
    controlName: controlName.value,
    executionStatus: executionStatus.value,
    executionStart: start_date,
    executionEnd: end_date,
    controlStrategyId: router.currentRoute.value.query.controlStrategyId
  })).then(res => {
    //console.log(res)
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      tableData.value.forEach(item => {
        item.conditionInfo = JSON.parse(item.conditionInfo)
        item.conditionInfo.forEach(items => {
          var index;
          items.data.forEach(itemc => {
            index = items.formula.indexOf(itemc.replaceStr)
            itemc.repaceVal = items.formula.slice(index - 1, index)
          })
        })
      })
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err)
  })
}


// 重置
const resetFun = function () {
  pageSize2.value = 20
  pageNumber2.value = 1
  deviceName.value = null
  controlName.value = null
  executionStatus.value = null
  timeList.value = []

  getList()
}
onMounted(() => {
  getList(); //执行日志列表
  getStrategyList(); //获取策略列表
})


</script>

<style lang="less" scoped>

</style>