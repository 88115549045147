import {api} from "@/api";

// 查询设备列表
export const getEquipmentListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/device/list',
        method: 'GET',
        params: data
    })
}

// 查询指标列表
export const getDataListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/rest/equipmentLocal/listCollectDict`,
        method: 'POST',
        data: data
    })
}
// 获取图表数据
export const getEchartsDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataMetricsV2/getLocalChartData',
        method: 'POST',
        data: data
    })
}
// 获取表格数据
export const getTableDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataMetricsV2/getLocalTableData',
        method: 'POST',
        data: data
    })
}


// 查询条件设置---------------------------------------
// 查询列表
export const getSearchListApi = (type) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/query/list/${type}`,
        method: 'GET'
    })
}
// 保存查询列表
export const saveSearchListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/query/save',
        method: 'POST',
        data: data
    })
}
// 编辑查询列表
export const editSearchListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/query/update',
        method: 'POST',
        data: data
    })
}
// 查询列表详情
export const getSearchDetailApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/query/detail/${id}`,
        method: 'GET',
    })
}
// 删除查询列表
export const delSearchListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/message/rest/query/remove/${id}`,
        method: 'POST',
    })
}


// 导出
export const exportDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        responseType: "blob",
        url: `/zouk/rest/dataMetricsV2/exportLocalExcel`,
        method: 'POST',
        data: data
    })
}


// 获取所有设备树
export const getAllTreeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentLocal/getAllTree',
        method: 'POST',
        data: data
    })
}

// /zouk/net/equipment/getAllTree 替换 /zouk/rest/equipmentLocal/getAllTree
// /zouk/message/rest/dict/list/ 替换 /zouk/rest/equipmentLocal/listCollectDict
// zouk/message/rest/data/getChartData 替换 /zouk/rest/dataMetricsV2/getLocalChartData
