<template>
  <div class="editVersion">
    <div class="title">
      <p>
        <span @click="goBack">电价配置</span>
        <span> / </span>
        <span>{{ strategyForm.id ? '编辑' : '新增' }}电价</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        {{ strategyForm.id ? '编辑' : '新增' }}电价
      </p>
    </div>
    <div class="content">
      <!--数据列表内容-->
      <div class="contain">
        <el-form
            ref="strategyFormRef"
            :model="strategyForm"
            :rules="strategyRules"
            class="strategyForm"
            status-icon
        >
          <!--选择省市-->
          <div class="topBox">
            <el-form-item class="topFormItem" label="区域">
              <el-select v-model="category" placeholder="请选择区域" @change="addressChange($event)">
                <el-option v-for="(item,index) in categoryList" :key="index" :label="item.provinceName"
                           :value="item.provinceName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="topFormItem" label="名称">
              <el-select v-model="categorySub" placeholder="请选择名称" @change="addressSubChange($event)">
                <el-option v-for="(item,index) in categorySubList" :key="index" :label="item.categoryName"
                           :value="item.categoryName"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <!--版本名称和生效时间-->
          <div class="topBox">
            <el-form-item class="topFormItem" label="版本名称" prop="versionNumber">
              <el-input v-model="strategyForm.versionNumber" placeholder="请输入版本名称"/>
            </el-form-item>
            <el-form-item class="topFormItem" label="生效时间" prop="ableTime">
              <el-date-picker
                  v-model="strategyForm.ableTime"
                  end-placeholder="结束时间"
                  placeholder="请选择"
                  start-placeholder="开始时间"
                  type="datetimerange"
                  value-format="YYYY-MM-DD HH:mm:ss"
              />
            </el-form-item>
          </div>
          <!--分时策略标题-->
          <div class="title">分时策略
            <el-tooltip
                class="box-item"
                content="00-24.00,全天24小时，不可重复、交叉"
                effect="dark"
                placement="top"
            >
              <el-icon>
                <QuestionFilled/>
              </el-icon>
            </el-tooltip>
          </div>
          <!--分时策略数据-->
          <div class="strategy">
            <div class="timeBox">
              <div class="tit">分时策略：</div>
              <div v-for="(item,index) in strategyForm.sharpTimeListJsonStrCopy" :key="index"
                   class="sharp">
                <el-form-item :label="'尖时段'+Number(index+1)+':'">
                  <el-select v-model="item.startTime" placeholder="开始时间">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.endTime" placeholder="结束时间" style="margin-left: 20px">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.type" style="margin-left: 20px">
                    <el-option :value="1" label="不操作"/>
                    <el-option :value="2" label="充电"/>
                    <el-option :value="3" label="放电"/>
                  </el-select>
                </el-form-item>
                <el-icon v-if="strategyForm.sharpTimeListJsonStrCopy.length>1" class="delIcon"
                         @click="delTime(1)">
                  <Delete/>
                </el-icon>
                <el-icon v-if="strategyForm.sharpTimeListJsonStrCopy.length===index+1" class="addIcon"
                         @click="addTime(1,index)">
                  <Plus/>
                </el-icon>
              </div>
              <div v-for="(item,index) in strategyForm.peakTimeListJsonStrCopy" :key="index" class="peak">
                <el-form-item :label="'峰时段'+Number(index+1)+':'">
                  <el-select v-model="item.startTime" placeholder="开始时间">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.endTime" placeholder="结束时间" style="margin-left: 20px">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.type" style="margin-left: 20px">
                    <el-option :value="1" label="不操作"/>
                    <el-option :value="2" label="充电"/>
                    <el-option :value="3" label="放电"/>
                  </el-select>
                </el-form-item>
                <el-icon v-if="strategyForm.peakTimeListJsonStrCopy.length>1" class="delIcon" @click="delTime(2,index)">
                  <Delete/>
                </el-icon>
                <el-icon v-if="strategyForm.peakTimeListJsonStrCopy.length===index+1" class="addIcon"
                         @click="addTime(2)">
                  <Plus/>
                </el-icon>
              </div>
              <div v-for="(item,index) in strategyForm.flatTimeListJsonStrCopy" :key="index" class="flat">
                <el-form-item :label="'平时段'+Number(index+1)+':'">
                  <el-select v-model="item.startTime" placeholder="开始时间">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.endTime" placeholder="结束时间" style="margin-left: 20px">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.type" style="margin-left: 20px">
                    <el-option :value="1" label="不操作"/>
                    <el-option :value="2" label="充电"/>
                    <el-option :value="3" label="放电"/>
                  </el-select>
                </el-form-item>
                <el-icon v-if=" strategyForm.flatTimeListJsonStrCopy.length>1" class="delIcon"
                         @click="delTime(3,index)">
                  <Delete/>
                </el-icon>
                <el-icon v-if=" strategyForm.flatTimeListJsonStrCopy.length===index+1" class="addIcon"
                         @click="addTime(3)">
                  <Plus/>
                </el-icon>
              </div>
              <div v-for="(item,index) in strategyForm.valleyTimeListJsonStrCopy" :key="index" class="valley">
                <el-form-item :label="'谷时段'+Number(index+1)+':'">
                  <el-select v-model="item.startTime" placeholder="开始时间">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.endTime" placeholder="结束时间" style="margin-left: 20px">
                    <template #prefix>
                      <el-icon>
                        <Timer/>
                      </el-icon>
                    </template>
                    <el-option v-for="(item_t,index_t) in timeList" :key="index_t" :label="item_t"
                               :value="item_t"></el-option>
                  </el-select>
                  <el-select v-model="item.type" style="margin-left: 20px">
                    <el-option :value="1" label="不操作"/>
                    <el-option :value="2" label="充电"/>
                    <el-option :value="3" label="放电"/>
                  </el-select>
                </el-form-item>
                <el-icon v-if="strategyForm.valleyTimeListJsonStrCopy.length>1" class="delIcon"
                         @click="delTime(4,index)">
                  <Delete/>
                </el-icon>
                <el-icon v-if="strategyForm.valleyTimeListJsonStrCopy.length===index+1" class="addIcon"
                         @click="addTime(4)">
                  <Plus/>
                </el-icon>
              </div>
            </div>
            <div class="priceBox">
              <div class="priceBox1">
                <div class="tit">电网电价
                  <el-tooltip
                      class="box-item"
                      content="从电网购电的电价"
                      effect="dark"
                      placement="top"
                  >
                    <el-icon>
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="sharp">
                  <el-form-item label="尖时刻电价" prop="strategyForm.sharpFee">
                    <el-input v-model="strategyForm.sharpFee" type="number">
                      <template #append>元/kWh</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="peak">
                  <el-form-item label="峰时刻电价" prop="strategyForm.peakFee">
                    <el-input v-model="strategyForm.peakFee" type="number">
                      <template #append>元/kWh</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="flat">
                  <el-form-item label="平时刻电价" prop="strategyForm.flatFee">
                    <el-input v-model="strategyForm.flatFee" type="number">
                      <template #append>元/kWh</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="valley">
                  <el-form-item label="谷时刻电价" prop="strategyForm.valleyFee">
                    <el-input v-model="strategyForm.valleyFee" type="number">
                      <template #append>元/kWh</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!--取消保存操作-->
      <div class="footer">
        <el-button class="resetButton" @click="goBack">取消</el-button>
        <el-button type="primary" @click="submitClick">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {reactive, ref, getCurrentInstance, onMounted} from "vue"
import './editVersion.less'
import {Back, Delete, Plus, QuestionFilled, Timer} from '@element-plus/icons-vue'
import {ElMessage} from 'element-plus'
import {useRouter} from 'vue-router';
import {editListApi, getListDetailApi, getConfigSelectApi} from '@/api/modules/electrovalence'
import qs from "qs";

let router = useRouter()
// 返回-------------------------------------------------------------------
const goBack = function () {
  router.push('/admin/electrovalence')
}


// 时间列表
let timeList = ref(['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59'])


// 电价表单数据-------------------------------------------------------------------
let strategyForm = ref({
  id: 0,
  versionNumber: '',
  ableTime: [],
  sharpFee: '',
  sharpTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: '',
      type: 1
    }
  ],
  peakFee: '',
  peakTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: '',
      type: 1
    }
  ],
  flatFee: '',
  flatTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: '',
      type: 1
    }
  ],
  valleyFee: '',
  valleyTimeListJsonStrCopy: [
    {
      startTime: '',
      endTime: '',
      type: 1
    }
  ],
})
// 版本表单验证规则
let strategyRules = ref({
  versionNumber: [
    {required: true, message: '请输入版本名称', trigger: 'blur'},
  ],
  ableTime: [
    {required: true, message: '请选择生效时间', trigger: 'blur'},
  ]
})

// 选择省市-------------------------------------------------------------------
let category = ref('')
let categorySub = ref('')
let categoryList = ref([])
let categorySubList = ref([])
// 获取配置选项
const getPowerSelectFun = function () {
  getConfigSelectApi().then(res => {
    if (res.data.code === 0) {
      categoryList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const addressChange = function (e) {
  categorySub.value = ''
  for (let i = 0; i < categoryList.value.length; i++) {
    if (categoryList.value[i].provinceName === e) {
      categorySubList.value = categoryList.value[i].categoryList
    }
  }
}
const addressSubChange = function (e) {
  for (let i = 0; i < categorySubList.value.length; i++) {
    if (categorySubList.value[i].categoryName === e) {
      let data = JSON.parse(JSON.stringify(categorySubList.value[i]))
      strategyForm.value.sharpFee = data.sharpRate.fee
      strategyForm.value.peakFee = data.peakRate.fee
      strategyForm.value.flatFee = data.flatRate.fee
      strategyForm.value.valleyFee = data.valleyRate.fee
      strategyForm.value.sharpTimeListJsonStrCopy = data.sharpRate.timeList
      strategyForm.value.peakTimeListJsonStrCopy = data.peakRate.timeList
      strategyForm.value.flatTimeListJsonStrCopy = data.flatRate.timeList
      strategyForm.value.valleyTimeListJsonStrCopy = data.valleyRate.timeList
      for (let j = 0; j < strategyForm.value.sharpTimeListJsonStrCopy.length; j++) {
        strategyForm.value.sharpTimeListJsonStrCopy[i].type = 1
      }
      for (let j = 0; j < strategyForm.value.peakTimeListJsonStrCopy.length; j++) {
        strategyForm.value.peakTimeListJsonStrCopy[i].type = 1
      }
      for (let j = 0; j < strategyForm.value.flatTimeListJsonStrCopy.length; j++) {
        strategyForm.value.flatTimeListJsonStrCopy[i].type = 1
      }
      for (let j = 0; j < strategyForm.value.valleyTimeListJsonStrCopy.length; j++) {
        strategyForm.value.valleyTimeListJsonStrCopy[i].type = 1
      }
    }
  }
}

// 新增删除操作-------------------------------------------------------------------
// 新增时段
const addTime = function (idx) {
  if (idx === 1) {
    strategyForm.value.sharpTimeListJsonStrCopy.push({
      startTime: '',
      endTime: '',
      type: 1
    })
  } else if (idx === 2) {
    strategyForm.value.peakTimeListJsonStrCopy.push({
      startTime: '',
      endTime: '',
      type: 1
    })
  } else if (idx === 3) {
    strategyForm.value.flatTimeListJsonStrCopy.push({
      startTime: '',
      endTime: '',
      type: 1
    })
  } else if (idx === 4) {
    strategyForm.value.valleyTimeListJsonStrCopy.push({
      startTime: '',
      endTime: '',
      type: 1
    })
  }
}
// 删除时段
const delTime = function (idx, index) {
  if (idx === 1) {
    strategyForm.value.sharpTimeListJsonStrCopy.splice(index, 1)
  } else if (idx === 2) {
    strategyForm.value.peakTimeListJsonStrCopy.splice(index, 1)
  } else if (idx === 3) {
    strategyForm.value.flatTimeListJsonStrCopy.splice(index, 1)
  } else if (idx === 4) {
    strategyForm.value.valleyTimeListJsonStrCopy.splice(index, 1)
  }
}


// 提价表单----------------------------------------------------------------------
// 表单元素
let strategyFormRef = ref()
// 提交表单
const submitClick = () => {
  // 提交表单
  if (strategyFormRef) {
    strategyFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        strategyForm.value.startTime = strategyForm.value.ableTime[0]
        strategyForm.value.endTime = strategyForm.value.ableTime[1]
        strategyForm.value.sharpTimeListJsonStr = JSON.stringify(strategyForm.value.sharpTimeListJsonStrCopy)
        strategyForm.value.peakTimeListJsonStr = JSON.stringify(strategyForm.value.peakTimeListJsonStrCopy)
        strategyForm.value.flatTimeListJsonStr = JSON.stringify(strategyForm.value.flatTimeListJsonStrCopy)
        strategyForm.value.valleyTimeListJsonStr = JSON.stringify(strategyForm.value.valleyTimeListJsonStrCopy)
        editListApi(qs.stringify(strategyForm.value)).then(res => {
          if (res.data.code === 0) {
            router.push('/admin/electrovalence')
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
};


onMounted(() => {
  if (router.currentRoute.value.query.id) {
    getListDetailApi(qs.stringify({id: router.currentRoute.value.query.id})).then(res => {
      if (res.data.code === 0) {
        let data = JSON.parse(JSON.stringify(res.data.result))
        strategyForm.value.id = data.id
        strategyForm.value.ableTime = [data.startTime, data.endTime]
        strategyForm.value.versionNumber = data.versionNumber
        strategyForm.value.sharpFee = data.sharpRate.fee
        strategyForm.value.peakFee = data.peakRate.fee
        strategyForm.value.flatFee = data.flatRate.fee
        strategyForm.value.valleyFee = data.valleyRate.fee
        strategyForm.value.sharpTimeListJsonStrCopy = data.sharpRate.timeList
        strategyForm.value.peakTimeListJsonStrCopy = data.peakRate.timeList
        strategyForm.value.flatTimeListJsonStrCopy = data.flatRate.timeList
        strategyForm.value.valleyTimeListJsonStrCopy = data.valleyRate.timeList
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
  getPowerSelectFun()
})


</script>

<style lang="less" scoped>
</style>
