import { api } from '@/api'

// 查询计费管理-自定义计费项列表
export function listCustom(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/page',
    method: 'get',
    params: query,
  })
}

// 查询计费管理-自定义计费项详细
export function getCustom(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/detail/' + id,
    method: 'get',
  })
}

// 新增计费管理-自定义计费项
export function addCustom(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/save',
    method: 'post',
    data: data,
  })
}

// 修改计费管理-自定义计费项
export function updateCustom(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/update',
    method: 'post',
    data: data,
  })
}

// 删除计费管理-自定义计费项
export function delCustom(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/remove/' + id,
    method: 'post',
  })
}

// 查询计费管理-自定义计费项项目列表
export function listCustomProject(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/project',
    method: 'get',
    params: query,
  })
}

// 查询计费管理-自定义计费项设备列表
export function listCustomEquipment(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/equipment',
    method: 'get',
    params: query,
  })
}

// 绑定设备
export function bindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/bind',
    method: 'post',
    data: data,
  })
}

// 解绑设备
export function unbindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/unbind',
    method: 'post',
    data: data,
  })
}

// 查询计费管理-自定义计费项列表
export function getCustomList(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/custom/list',
    method: 'get',
    params: query,
  })
}
