import {api} from "@/api";

// 获取产品列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/page',
        method: 'POST',
        data: data
    })
}
// 获取产品分类列表
export const getAllCategoryList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/category/listAll',
        method: 'POST',
        data: data
    })
}
//获取所有抄读方式
export const getAllParserList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/v3/listSelect',
        method: 'POST',
        data: data
    })
}
//获取详情列表
export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/getDetail',
        method: 'POST',
        data: data
    })
}
//获取所有抄读项
export const getParserItemList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/listSelect',
        method: 'POST',
        data: data
    })
}
//新增的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/save',
        method: 'POST',
        data: data
    })
}

//删除产品
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/delete',
        method: 'POST',
        data: data
    })
}
