import {api3} from "@/api";
//获取流类型
export const streamType = (data) => {
    return api3({
        url: '/api/ekuiper/metadata/sources',
        method: 'GET',
        params: data ? data : {}
    })
}
//获取流配置
export const streamConfig = (data) => {
    return api3({
        url: `api/ekuiper/metadata/sources/yaml/${data}`,
        method: 'GET',
    })
}
//新增/修改流管理表
export const addStream = (fileName, data) => {
    return api3({
        url: `api/ekuiper/streams/${fileName}`,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}
//新增/修改表管理表
export const addTable = (fileName, data) => {
    return api3({
        url: `api/ekuiper/tables`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            kind: fileName
        },
        data: data
    })
}
export const modifyTable = (fileName,path,data) => {
    return api3({
        url: `api/ekuiper/tables/${path}`,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            kind: fileName
        },
        data: data
    })
}
//测试连接
export const testConnections = (name, data) => {
    return api3({
        url: `api/ekuiper/metadata/sources/connection/${name}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}