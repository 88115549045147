import { api } from '@/api'

// 查询用户交费记录列表
export function listPayment(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/payment/page',
    method: 'get',
    params: query,
  })
}

// 查询用户交费记录详细
export function getPayment(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/payment/detail/' + id,
    method: 'get',
  })
}

// 新增用户交费记录
export function addPayment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/payment/save',
    method: 'post',
    data: data,
  })
}

// 修改用户交费记录
export function updatePayment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/payment/update',
    method: 'post',
    data: data,
  })
}

// 删除用户交费记录
export function delPayment(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/payment/remove' + id,
    method: 'post',
  })
}

// 获取项目设备列表
export function ListPaymentEquipment(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/payment/equipment',
    method: 'get',
    params: query,
  })
}
