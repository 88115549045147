import {api} from "@/api";

// 获取告警模版列表
export const getAlarmTemplateListAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnRuleTemplate/page',
        method: 'POST',
        data: data
    })
}
// 新增编辑告警模版
export const saveAlarmTemplateAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnRuleTemplate/save',
        method: 'POST',
        data: data
    })
}
// 删除编辑告警模版
export const delAlarmTemplateAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnRuleTemplate/delete',
        method: 'POST',
        data: data
    })
}

// 获取指标选项
export const getIndexSelectAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dict/listSelectByName',
        method: 'POST',
        data: data
    })
}


// 获取告警规则列表
export const getAlarmRuleListAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnRule/page',
        method: 'POST',
        data: data
    })
}
// 新增编辑告警规则
export const saveAlarmRuleAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnRule/save',
        method: 'POST',
        data: data
    })
}
// 获取告警规则详情
export const getAlarmRuleDetailAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnRule/getDetail',
        method: 'POST',
        data: data
    })
}

// 删除编辑告警规则
export const delAlarmRuleAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnRule/delete',
        method: 'POST',
        data: data
    })
}


// 告警模版选项列表
export const getAlarmSelectListAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnRuleTemplate/listSelect',
        method: 'POST',
        data: data
    })
}

// 组织用户选项列表
export const getUserSelectListAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/listSelectByCurrentMember',
        method: 'POST',
        data: data
    })
}


// 获取告警列表
export const getAlarmListAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnLog/page',
        method: 'POST',
        data: data
    })
}

// 结束告警规则
export const endAlarmAPi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warnLog/over',
        method: 'POST',
        data: data
    })
}