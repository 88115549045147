<template>
  <div class="httppull">
    <el-form label-position="top" ref="userForm" :rules="rules" :inline="true" :model="deployGroup"
             label-width="auto">
      <el-form-item v-if="temp.name" label="名称" prop="name">
        <el-input v-model="deployGroup.name" :disabled="store.state.sourceData['prohibit']"></el-input>
      </el-form-item>
      <el-form-item prop="url">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">路径</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">获取结果的 URL</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.url"></el-input>
      </el-form-item>
      <el-form-item prop="method">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">HTTP 方法</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">HTTP 方法，它可以是 post、get、put 和 delete</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup['method']"
            placeholder="请选择"
            clearable
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in fileType"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">间隔时间（ms）</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">请求之间的间隔时间，单位为 ms</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input-number v-model="deployGroup.interval"/>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">超时时间（ms）</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">HTTP 请求的超时时间，单位为 ms</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <template #default>
          <div>
            <el-input-number v-model="deployGroup.timeout"/>
          </div>
        </template>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">递增</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">如果将其设置为 true，则将与最后的结果进行比较； 如果两个请求的响应相同，则将跳过发送结果</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-radio v-model="deployGroup.incremental" :label="true" border>True</el-radio>
        <el-radio v-model="deployGroup.incremental" :label="false" border>False</el-radio>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">正文</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">请求的正文</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input
            style="width: 500px"
            :rows="3"
            v-model="deployGroup.body"
            type="textarea"
        />
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">正文类型</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                              <span
                                  style="color: #ffffff;margin-right: 8px">正文类型，可以是 none|text|json|html|xml|javascript 格式</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.bodyType"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">客户端证书
            </label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">客户端证书文件</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-upload
            ref="uploadRef"
            class="upload-demo"
            :auto-upload="false"
            :show-file-list="false"
            @change="handleFile($event, 'certificationRaw')"
        >
          <template #trigger>
            <div class="upload-container">
              <div class="el-upload el-upload--text">
                <div class="btn-text">
                  <div :class="['upload-btn']">
                    <div v-if="!deployGroup.certificationRaw">
                      <img
                          src="http://192.168.3.253:8085/web/common/img/upload-file.28dfa07c.svg" alt=""
                          class="image">
                      <span class="text">点击上传</span>
                    </div>
                    <div v-else class="file-md5">
                      <label
                          for="">MD5: {{ encryptText(deployGroup.certificationRaw) }}</label>
                      <div class="text reupload-btn">重新上传</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">客户端私钥</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">客户端私钥文件</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-upload
            ref="uploadRef"
            class="upload-demo"
            :auto-upload="false"
            :show-file-list="false"
            @change="handleFile($event, 'privateKeyRaw')"
        >
          <template #trigger>
            <div class="upload-container">
              <div class="el-upload el-upload--text">
                <div class="btn-text">
                  <div :class="['upload-btn']">
                    <div v-if="!deployGroup.privateKeyRaw">
                      <img
                          src="http://192.168.3.253:8085/web/common/img/upload-file.28dfa07c.svg" alt=""
                          class="image">
                      <span class="text">点击上传</span>
                    </div>
                    <div v-else class="file-md5">
                      <label
                          for="">MD5: {{ encryptText(deployGroup.privateKeyRaw) }}</label>
                      <div class="text reupload-btn">重新上传</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">CA 证书</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">CA 证书文件</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-upload
            ref="uploadRef"
            class="upload-demo"
            :auto-upload="false"
            :show-file-list="false"
            @change="handleFile($event, 'rootCARaw')"
        >
          <template #trigger>
            <div class="upload-container">
              <div class="el-upload el-upload--text">
                <div class="btn-text">
                  <div :class="['upload-btn']">
                    <div v-if="!deployGroup.rootCARaw">
                      <img
                          src="http://192.168.3.253:8085/web/common/img/upload-file.28dfa07c.svg" alt=""
                          class="image">
                      <span class="text">点击上传</span>
                    </div>
                    <div v-else class="file-md5">
                      <label
                          for="">MD5: {{ encryptText(deployGroup.rootCARaw) }}</label>
                      <div class="text reupload-btn">重新上传</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">跳过证书验证</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">如果设置为 true，TLS 接受服务器提供的任何证书以及该证书中的任何主机名。在这种模式下，TLS 容易受到中间人攻击。默认值为 false，配置项只能用于 TLS 连接</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-radio v-model="deployGroup.insecureSkipVerify" :label="true" border>True</el-radio>
        <el-radio v-model="deployGroup.insecureSkipVerify" :label="false" border>False</el-radio>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">HTTP 标头</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">需要与 HTTP 请求一起发送的 HTTP 请求标头</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <div class="httpHeader">
          <el-table :data="tableData" style="width: 500px;min-height: 100px;">
            <el-table-column prop="date" label="键" min-width="169">
              <template #default="scope">
                <el-input v-model="scope.row.key"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="值" min-width="169">
              <template #default="scope">
                <el-input v-model="scope.row.value"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="100">
              <template #default="scope">
                <div class="del-button">
                  <el-button :icon="Delete" circle @click="deleteTable(tableData,scope.row)"/>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="add-button">
            <el-button :icon="Plus" @click="addTable('http')">添加</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">响应类型</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">响应类型,可以是 code 或者 body，如果是 code，那么会检查 HTTP 响应码来判断响应状态。如果是 body，那么会检查 HTTP 响应正文，要求其为 JSON 格式，并且检查 code 字段的值</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup.responseType"
            placeholder="请选择"
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in responseType"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">OAuth</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">配置 OAuth 验证流程</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <section class="kv-tree-table-form">
          <el-table :data="tableData2" style="width: 100%">
            <el-table-column type="expand">
              <template #default="props">
                <div class="oAuth-child">
                  <el-table :span-method="arraySpanMethod" :show-header="false" :data="props.row.family">
                    <el-table-column prop="name">
                      <template #default="scope">
                        <label v-if="scope.row.type!==null" for="">{{ scope.row.name }}</label>
                        <div v-if="scope.row.type===null&&scope.row.name===null" style="width: 100%;"
                             class="httpHeader">
                          <el-table :data="header" style="width: 500px;min-height: 100px;">
                            <el-table-column prop="date" label="键" min-width="169">
                              <template #default="scope">
                                <el-input v-model="scope.row.key"></el-input>
                              </template>
                            </el-table-column>
                            <el-table-column prop="name" label="值" min-width="169">
                              <template #default="scope">
                                <el-input v-model="scope.row.value"></el-input>
                              </template>
                            </el-table-column>
                            <el-table-column label="操作" min-width="100">
                              <template #default="scope">
                                <div class="del-button">
                                  <el-button :icon="Delete" circle @click="deleteTable(header,scope.row)"/>
                                </div>
                              </template>
                            </el-table-column>
                          </el-table>
                          <div class="add-button">
                            <el-button :icon="Plus" @click="addTable('oauth')">添加</el-button>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="type">
                      <template #default="scope">
                        <div v-if="deployGroup.oauth[props.row.name]">
                          <el-input v-model="deployGroup.oauth[props.row.name][scope.row.name]"
                                    v-if="scope.row.type!==null" :rows="1"
                                    :type="scope.row.type"></el-input>
                        </div>
                        <!--                        <span>{{ props.row.name }}</span>-->
                        <!--                        <div v-else>
                                                  <el-input v-model="deployGroup.refresh[props.row.name][scope.row.name]"
                                                            v-if="scope.row.type!==null" :rows="1"
                                                            :type="scope.row.type"></el-input>
                                                </div>-->
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="键" prop="name"></el-table-column>
            <el-table-column label="值" prop="value"/>
          </el-table>
        </section>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import {defineExpose, defineProps, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import {Delete, Plus} from "@element-plus/icons-vue";
import {md5} from "js-md5";

const store = useStore();
const userForm = ref(null);
const temp = defineProps({
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }, name: {
    type: Boolean,
    default: () => {
      return true
    }
  }
})
const tableData2 = [
  {
    name: 'access',
    family: [
      {
        name: 'url',
        type: 'input'
      },
      {
        name: 'body',
        type: 'textarea'
      },
      {
        name: 'expire',
        type: 'input'
      },
    ],
  },
  {
    name: 'refresh',
    family: [
      {
        name: 'url',
        type: 'input'
      },
      {
        name: 'header',
        type: null
      },
      {
        name: null,
        type: null
      },
      {
        name: 'body',
        type: 'textarea'
      },
    ],
  }]
//文件类型
let fileType = ref(["get", "post", "put", "delete"])
let responseType = ref(['code', 'body'])
let tableData = ref([])
//form数据
let deployGroup = ref({
  "bodyType": "",
  "headers": {},
  "incremental": false,
  "insecureSkipVerify": true,
  "method": "",
  "oauth": {
    "access": {
      "body": "",
      "expire": "",
      "url": ""
    },
    "refresh": {
      "body": "",
      "expire": "",
      "url": "",
      "header": {}
    }
  },
  "responseType": "",
  "timeout": 0,
  "url": "",
  "interval": 0,
  "body": "",
  "certificationRaw": "",//证书证书文件 客户端
  "privateKeyRaw": "",//私钥文件 客户端客户端私钥
  "rootCARaw": "",//ca证书 客户端
})
// "header": {"accept": "application/json", "authorization": "Bearer {{.token}}"},
let header = ref([])
tableData.value = Object.entries(deployGroup.value.headers).map(([key, value]) => ({key, value}));
header.value = Object.entries(deployGroup.value.oauth.refresh.header).map(([key, value]) => ({key, value}));

// deployGroup.value = temp.data['name'] === void 0 ? {...temp.data} : deployGroup.value
const rules = reactive({
  name: [{required: true, message: '请填写名称', trigger: 'blur'}],
  method: [{required: true, trigger: 'blur', message: ''}],
  url: [{required: true, trigger: 'blur', message: ''}],
})
//上传文件
const handleFile = (rawFile, name) => {
  const reader = new FileReader();
  reader.readAsDataURL(rawFile.raw);
  reader.onload = (event) => {
    const base64String = event.target.result; // 这里就是获取到的Base64字符串
    // 移除前缀,并赋值
    deployGroup.value[name] = base64String.replace(/^data:image\/svg\+xml;base64,/, '')
  };
}
//新增表格，头部
const addTable = (name) => {
  if (name === 'http') {
    tableData.value.push({key: '', value: ''})
  } else {
    header.value.push({key: '', value: ''})
  }
}
const deleteTable = (array, e) => {
  array.splice(tableData.value.indexOf(e), 1)
}
const arraySpanMethod = ({row, column, rowIndex, columnIndex,}) => {
  if (row.name === null && row.type === null) {
    if (rowIndex % 2 === 0) {
      if (columnIndex === 0) {
        return [1, 2]
      }
    }
  }
}
//数组转对象
const arrayToObject = (arr) => {
  let obj = {};
  arr.forEach(item => {
    obj[item.key] = item.value;
  });
  return obj;
}
const getFormData = async () => {
  const isValid = await userForm.value.validate();
  if (isValid) {
    if (tableData.value.length > 0) {
      deployGroup.value.headers = arrayToObject(tableData.value);
    }
    if (header.value.length > 0) {
      deployGroup.value.oauth.refresh.header = arrayToObject(header.value);
    }
    let temp = deployGroup.value
    removeEmptyValues(temp);
    removeEmptyValues(temp);
    removeEmptyValues(temp);
    // console.log(temp);
    return temp
  }
}
defineExpose({getFormData});
//md5加密url;
const encryptText = (url) => {
  // 使用md5进行加密并转换为32位小写
  return md5(url).toLowerCase();
}
//深度合并对象
const deepMerge = (target, source) => {
  for (const key in source) {
    if (source[key] && typeof source[key] === 'object') {
      // 如果属性是对象，递归调用合并函数
      if (!target[key]) {
        target[key] = Array.isArray(source[key]) ? [] : {};
      }
      deepMerge(target[key], source[key]);
    } else {
      // 如果属性不是对象，直接覆盖
      target[key] = source[key];
    }
  }
}
//移除对象的空值属性
const removeEmptyValues = (obj) => {
  for (let key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // 如果值是对象或数组，递归处理
      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            delete obj[key];
          } else {
            value.forEach((item, index) => {
              if (typeof item === 'object' && item !== null) {
                removeEmptyValues(item);
              }
            });
          }
        } else {
          if (Object.keys(value).length === 0) {
            delete obj[key];
          } else {
            removeEmptyValues(value);
          }
        }
      }

      // 如果值是null、undefined或空字符串，删除属性
      else if (value === null || value === undefined || value === '') {
        delete obj[key];
      }
    }
  }
}
onMounted(() => {
  deepMerge(deployGroup.value, temp.data);
  // console.log(deployGroup.value)

  if (deployGroup.value.headers) {
    tableData.value = Object.entries(deployGroup.value.headers).map(([key, value]) => ({key, value}));
  }
  if (deployGroup.value.oauth.refresh.header) {
    header.value = Object.entries(deployGroup.value.oauth.refresh.header).map(([key, value]) => ({key, value}));
  }
})
</script>


<style scoped lang="less">
//提示文字最大宽度
.max-tooltip {
  max-width: 280px !important;
}

.label {
  position: relative;
  margin-left: 10px;

  &:before {
    position: absolute;
    content: '*';
    color: var(--el-color-danger);
    top: 0;
    left: -10px;
  }
}

.upload-container {
  width: 500px !important;
  height: 32px;
  border: 1px dashed #189bfe;
  border-radius: 8px;
  background-color: rgba(24, 155, 254, .05);
  cursor: pointer;

  .btn-text {
    width: 500px;
    box-sizing: border-box;
    padding: 0 16px;
    color: #189bfe;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .upload-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-sizing: border-box;

      .image {
        width: 18px;
        height: 22px;
        vertical-align: text-bottom;
        margin-right: 10px;
      }

      .file-md5 {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        display: flex;
        justify-content: space-between;

        label {
          color: #96999c;
          margin-right: 10px;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .text {
        color: #189bfe;
      }

      .reupload-btn {
        width: 56px;
        white-space: nowrap;
      }
    }
  }
}

.httppull {
  @import "public";

  .httpHeader {
    //padding: 5px;
    width: 500px;
    box-sizing: border-box;
    //width: 440px;
    border-radius: 8px;
    border: 1px solid #e2e7ea;

    .del-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-button {
        width: 50%;
      }
    }

    .add-button {
      .el-button {
        width: 436px;
        margin-left: 1px;
        border: none;
        color: #189bfe;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  .kv-tree-table-form {
    width: 500px;
    box-sizing: border-box;
    //padding: 16px;
    border-radius: 8px;
    border: 1px solid #e2e7ea;
  }

  .oAuth-child {
    :deep(.el-table__row:last-child) {
      .el-table_7_column_18, .el-table__cell {
        border: none !important;;
      }
    }
  }
}
</style>