<template>
  <div class="logQuery">
    <div class="title">
      <p>
        <span>日志查询</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <el-select v-model="relationName" clearable placeholder="请选择设备" style="width:100%">
            <el-option v-for="item in equipmentList" :key="item.relationName" :label="item.name"
                       :value="item.relationName"
                       style="width:100%">
            </el-option>
          </el-select>
        </div>
        <div class="liBox">
          <el-date-picker v-model="time" placeholder="请选择时间" popper-class="date-style" type="date"
                          value-format="YYYY-MM-DD">
          </el-date-picker>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--参数列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="节点名称" prop="nodeName">
          </el-table-column>
          <el-table-column label="控制时间" prop="time">
          </el-table-column>
          <el-table-column label="类型" prop="type">
          </el-table-column>
          <el-table-column label="控制值" prop="value">
          </el-table-column>
          <el-table-column label="内容" min-width="200px" prop="content">
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,prev, pager, next,sizes" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './logQuery.less'

import {CirclePlus, CircleClose, Plus, Search} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {
  getEquipmentSelectApi, getProductControlApi,
  getRunResultListApi,
} from "@/api/modules/strategy";
import {getRelationListApi, getControlLogListApi} from '@/api/modules/logQuery'
import qs from "qs";

let router = useRouter()
// 运行结果列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 运行结果表格数据
let tableData = ref([])
// 获取运行结果列表
let loading = ref(false)
const getListFun = function () {
  if (relationName.value !== '' && time.value !== '' && time.value) {
    loading.value = true
    getControlLogListApi(qs.stringify({
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
      relationName: relationName.value,
      time: time.value,
    })).then(res => {
      loading.value = false
      if (res.data.code === 0) {
        tableData.value = res.data.result.list
        total.value = Number(res.data.result.total)
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      loading.value = false
      console.log(err);
    })
  } else {
    ElMessage({
      message: '请选择查询条件',
      type: 'warning'
    })
  }
}


// 搜索
let equipmentList = ref([]) // 设备选项列表
let relationName = ref('')
let time = ref('')
const searchFun = function () {
  getListFun()
}
const resetFun = function () {
  pageSize.value = 20
  pageNumber.value = 1
  relationName.value = ''
  time.value = ''
  total.value = 0
  tableData.value = []
}


// 获取设备选项
const getEquipmentList = function () {
  getRelationListApi().then(res => {
    if (res.data.ok) {
      equipmentList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err)
  })
}


onMounted(() => {
  // getListFun()
  getEquipmentList()
})
</script>

<style lang="less" scoped>
</style>
