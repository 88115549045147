<template>
  <div class="northGroup">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="goBack">北向应用</span>
        <span> / </span>
        <span>组列表</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        {{ router.currentRoute.value.query.name }} 应用组列表
      </p>
    </div>
    <!--内容-->
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px;">名称</label>
          <el-input v-model="name" :prefix-icon="Search" clearable placeholder="请输入关键字搜索"></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
        </div>
      </div>

      <!--内容-->
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick">
            添加订阅
          </el-button>
          <el-button @click="multipleDelClick()">
            批量删除
          </el-button>
        </div>
        <!--列表-->
        <el-table v-loading='loading' :data="tableData" row-key="group"
                  style="width: 100%"
                  table-layout="auto" @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面添加订阅</p>
            </el-empty>
          </template>
          <el-table-column type="selection" width="55"/>
          <el-table-column label="设备名称" prop="driver"/>
          <el-table-column label="组名称" prop="group"/>
          <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <ul class="operationUl">
                <li @click="delClick(scope.row)">删除</li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title='新增指标' width='40%'>
      <div class="borderClass">
        <el-form-item label="订阅南向驱动数据" required>
          <div class="operation">
            <el-radio-group v-model="expand" aria-label="size control" @change="expandFun">
              <el-radio-button :label="true">全部展开</el-radio-button>
              <el-radio-button :label="false">全部折叠</el-radio-button>
            </el-radio-group>
          </div>
          <div style="padding: 10px 0;">
            <el-tree
                ref="treeRef"
                :data="treeList"
                :default-expand-all="expand"
                :default-expanded-keys="expandArr"
                :props="props"
                node-key="name"
                show-checkbox
            />
          </div>
        </el-form-item>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="importSave">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './northGroup.less'
import {Back, Plus} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {getDeviceListApi} from "@/api/modules/project";
import qs from "qs";
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {
  getTreeListApi,
  getTreeDataApi,
  getGroupListApi,
  delGroupListApi,
  addGroupListApi
} from '@/api/modules/northDriver'

let router = useRouter()

// 返回
const goBack = function () {
  router.push('/admin/northDriver')
}

// 获取列表数据*****************************************************************************
// 搜索
let name = ref('')


// 表格数据
let tableData = ref([])

// 获取列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getGroupListApi({
    app: router.currentRoute.value.query.name,
    driver: name.value,
  }).then(res => {
    loading.value = false
    if (res.status === 200) {
      tableData.value = res.data.groups
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}


// 查询
const searchFun = function () {
  getListFun()
}
// 重置
const resetFun = function () {
  name.value = ''
  getListFun()
}


// 表格多选框选择事件
let checkIdArr = ref([])
const handleSelectionChange = function (e) {
  checkIdArr.value = e
}

// 新增订阅*****************************************************************************
// 订阅南向驱动数据
let dialogFormVisible = ref(false)
// 新增订阅点击
const addClick = function () {
  dialogFormVisible.value = true
}
// 数据树元素
let treeRef = ref()
//获取树
const getTreeFun = async function () {
  await getTreeListApi({
    type: 1,
  }).then(res => {
    if (res.status === 200) {
      treeList.value = res.data.nodes
      for (let i = 0; i < treeList.value.length; i++) {
        getTreeDataApi({
          node: treeList.value[i].name
        }).then(res => {
          if (res.status === 200) {
            for (let j = 0; j < res.data.groups.length; j++) {
              res.data.groups[j].parentName = treeList.value[i].name
            }
            treeList.value[i].children = res.data.groups
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            })
          }
        }).catch(err => {
          console.log(err);
          ElMessage({
            type: 'error',
            message: err.response.data.message,
          })
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
// 树配置
let props = {
  label: 'name',
}
const treeList = ref([])
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0)'
  })
}
// 新增订阅
let importSave = function () {
  let arr = treeRef.value.getCheckedNodes(true)
  for (let i = 0; i < arr.length; i++) {
    arr[i].driver = arr[i].parentName
    arr[i].group = arr[i].name
  }
  if (arr.length > 0) {
    addGroupListApi({
      app: router.currentRoute.value.query.name,
      groups: arr
    }).then(res => {
      if (res.status === 200) {
        dialogFormVisible.value = false
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err)
    })
  } else {
    ElMessage({
      type: 'warning',
      message: '请先选择指标！'
    })
  }
}

// 全部展开折叠选择
let expand = ref(false)
let expandArr = ref([])
const expandFun = function (e) {
  const nodes = treeRef.value.store._getAllNodes();
  if (e) {
    nodes.forEach(item => {
      item.expanded = true;
    });
  } else {
    nodes.forEach(item => {
      item.expanded = false;
    });
  }
}


// 删除订阅*****************************************************************************
const delClick = function (scope) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '取消订阅',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要取消订阅，删除后数据不可恢复'),
    ])
  }).then(() => {
    if (Array.isArray(scope)) {
      for (let i = 0; i < scope.length; i++) {
        delGroupListApi({
          driver: scope[i].driver,
          group: scope[i].group,
          app: router.currentRoute.value.query.name
        }).then(res => {
          if (res.status === 200) {
            ElMessage({
              type: 'success',
              message: '取消成功',
            })
            getListFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
          ElMessage({
            type: 'error',
            message: err.response.data.message,
          })
        })
      }
    } else {
      delGroupListApi({
        driver: scope.driver,
        group: scope.group,
        app: router.currentRoute.value.query.name
      }).then(res => {
        if (res.status === 200) {
          ElMessage({
            type: 'success',
            message: '取消成功',
          })
          getListFun()
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message,
          })
        }
      }).catch(err => {
        console.log(err);
        ElMessage({
          type: 'error',
          message: err.response.data.message,
        })
      })
    }
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}
// 多选删除
const multipleDelClick = function () {
  delClick(checkIdArr.value)
}

onMounted(() => {
  getListFun()
  getTreeFun()
})

</script>

<style lang="less" scoped>
</style>
