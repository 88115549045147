<template>
  <div class="hsydScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="head"></div>
        <div class="timeBox">
          <p class="time">{{ timeVal }}</p>
          <p class="date">{{ dateVal }}</p>
          <!--          <p class="week">{{ weekVal }}</p>-->
        </div>
        <div class="logo">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/fsScreen/hsHomeLogo.png">
        </div>
        <div class="leftBox">
          <div class="leftBoxMainItem leftBoxItem">
            <div class="marquee tit" style="transform: translateY(8px)">
              <div class="marquee-wrap ">
                <p class="marquee-content">
                  {{ data.first.name }}
                </p>
              </div>
            </div>
            <div class="circleBoxItem">
              <div class="circleBox">
                <div class="chart  hsydCircleChart1"  @click="projectClick(data.first.projectId,data.first.project)"></div>
                <p class="name">实时功率</p>
              </div>
            </div>
            <div class="dataBox1">
              <div class="dataBoxItem">
                <count-up ref="countUp1" :end-val="data.first.totalNumber"
                          :start-val="countStart1"
                          class="value"
                          style="display: inline-block"></count-up>
                <p class="name">总设备数(台)</p>
              </div>
              <div class="dataBoxItem">
                <count-up ref="countUp2" :end-val="data.first.onlineNumber"
                          :start-val="countStart2"
                          class="value"
                          style="display: inline-block"></count-up>
                <p class="name">在线设备数(台)</p>
              </div>
              <div class="dataBoxItem">
                <count-up ref="countUp3" :end-val="data.first.offlineNumber"
                          :start-val="countStart3"
                          class="value"
                          style="display: inline-block"></count-up>
                <p class="name">离线设备数(台)</p>
              </div>
            </div>
          </div>
          <div class="leftBoxCont">
            <div class="topCont">
              <div v-for="(item,index) in data.firstList" :key="index"
                   :style="{backgroundImage:item.useStatus==1?'url(https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/hsScreen/hsydItemBlue.png)':'url(https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/hsScreen/hsydItemRed.png)'}"
                   class="leftBoxItem" @click="projectClick(item.projectId,item.project)">
                <div class="marquee tit">
                  <div class="marquee-wrap ">
                    <p class="marquee-content">
                      {{ item.name }}
                    </p>
                  </div>
                </div>
                <div class="circleBoxItem">
                  <div class="circleBox">
                    <div :class="'hsydCircleChart2_'+index" class="chart"></div>
                    <p class="name">实时功率</p>
                  </div>
                </div>
              </div>
              <div class="stationFirst stationBox">
                <div class="leftBoxMainItem leftBoxItem">
                  <div class="marquee tit">
                    <div class="marquee-wrap ">
                      <p class="marquee-content">
                        {{ data.stationFirst ? data.stationFirst.name : '' }}
                      </p>
                    </div>
                  </div>
                  <div class="circleBoxItem">
                    <div class="circleBox">
                      <div class="chart  hsydCircleChart3_1"></div>
                      <p class="name">实时功率</p>
                    </div>
                  </div>
                </div>
                <div v-for="(item,index) in data.stationFirst.subList" v-if="data.stationFirst" :key="index"
                     :style="{backgroundImage:item.useStatus==1?'url(https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/hsScreen/hsydItemBlue.png)':'url(https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/hsScreen/hsydItemRed.png)'}"
                     class="leftBoxItem" @click="projectClick(item.projectId,item.project)">
                  <div class="marquee tit">
                    <div class="marquee-wrap ">
                      <p class="marquee-content">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>

                  <div class="circleBoxItem">
                    <div class="circleBox">
                      <div :class="'hsydCircleChart4_'+index" class="chart"></div>
                      <p class="name">实时功率</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="stationSecond stationBox">
                <div class="leftBoxMainItem leftBoxItem">
                  <div class="marquee tit">
                    <div class="marquee-wrap ">
                      <p class="marquee-content">
                        {{ data.stationSecond ? data.stationSecond.name : '' }}
                      </p>
                    </div>
                  </div>
                  <div class="circleBoxItem">
                    <div class="circleBox">
                      <div class="chart  hsydCircleChart3_2"></div>
                      <p class="name">实时功率</p>
                    </div>
                  </div>
                </div>
                <div v-for="(item,index) in data.stationSecond.subList" v-if="data.stationSecond" :key="index"
                     :style="{backgroundImage:item.useStatus==1?'url(https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/hsScreen/hsydItemBlue.png)':'url(https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/hsScreen/hsydItemRed.png)'}"
                     class="leftBoxItem" @click="projectClick(item.projectId,item.project)">
                  <div class="marquee tit">
                    <div class="marquee-wrap ">
                      <p class="marquee-content">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>
                  <div class="circleBoxItem">
                    <div class="circleBox">
                      <div :class="'hsydCircleChart5_'+index" class="chart"></div>
                      <p class="name">实时功率</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-scale-screen>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import './hsydScreen.less'

import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import VScaleScreen from 'v-scale-screen'
import {useRouter} from 'vue-router';
import * as echarts from "echarts";
import {getHsydScreenApi} from '@/api/modules/bigScreen'
import {ElMessage} from "element-plus";
import CountUp from 'vue-countup-v3'

let router = useRouter()

// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.hngfScreen'); // 获取需要全屏的元素;
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};
const check = function () {
  enterFullScreen()
}

// 当前时间
let timeVal = ref()
let dateVal = ref()
let weekVal = ref()
const updateTime = function () {
  const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
  const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
  const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
  const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
  const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
  const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
  timeVal.value = hours + ':' + minutes + ':' + seconds
  dateVal.value = year + '.' + month + '.' + day
  weekVal.value = new Intl.DateTimeFormat('zh-CN', {
    weekday: 'long'
  }).format(new Date());
}

const projectClick = function (id, list) {
  router.push({
    path: '/admin/project',
    query: {
      id: id
    }
  })
  window.localStorage.setItem('project', JSON.stringify(list))
}

// 加载头部图表
const circleChartLoad = function () {
  var chartDom = document.querySelector('.hsydCircleChart1');
  if (chartDom) {
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      series: [{
        type: 'gauge',
        startAngle: 245,
        endAngle: -62,
        pointer: {
          show: true,
          offsetCenter: ['0%', '-50%'],
          length: '50%',
          icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
          itemStyle: {
            borderCap: 'round',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#66FFFF'
              },
              {
                offset: 0.7,
                color: 'rgba(102,255,255,.6)'
              },
              {
                offset: 1,
                color: 'rgba(102,255,255,.1)'
              },
            ]),
          }
        },
        progress: {
          show: true,
          width: 10,
          // overlap: false,
          // roundCap: true,
          // clip: false,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#59F8AC'
              },
              {
                offset: 1,
                color: '#50C0FF'
              },
            ]),
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            // color: '#01071D',
            color: [
              [0.1, '#2c4c64'], // 0~10% 红轴
              [1, '#2c4c64'], // 10~20% 绿轴
            ],
            opacity: 0.4, //See here
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: [{
          value: data.value.first.rate,
          // name: data.value.statusStr,
          name: 'kw',
          title: {
            offsetCenter: ['0%', '36%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0'],
            textShadowBlur: 12,
            textShadowColor: '#06B8FB',
          }
        }],
        title: {
          fontSize: 10,
          color: '#fff'
        },
        detail: {
          fontSize: 16,
          color: '#fff',
          // formatter: '{value}',
          // formatter: `{value}{a|%}`,
          // rich: {
          //   a: {
          //     fontSize: 12,
          //     color: '#fff',
          //     verticalAlign: 'bottom',
          //     width: 20,
          //     align: 'center',
          //     display: 'block'
          //   }
          // }
        },
      }]
    };

    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize({
      animation: {
        duration: 2800,
        easing: 'cubicInOut',
        // delay: 500,
      },
    });
    window.onresize = () => {
      myChart.resize();
    }
  }
}
// 加载列表图表
const circleChartLoad1 = function (i, val) {
  var chartDom = document.querySelector(`.hsydCircleChart2_${i}`);
  if (chartDom) {
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      series: [{
        type: 'gauge',
        startAngle: 245,
        endAngle: -62,
        pointer: {
          show: true,
          offsetCenter: ['0%', '-50%'],
          length: '50%',
          icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
          itemStyle: {
            borderCap: 'round',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#66FFFF'
              },
              {
                offset: 0.7,
                color: 'rgba(102,255,255,.6)'
              },
              {
                offset: 1,
                color: 'rgba(102,255,255,.1)'
              },
            ]),
          }
        },
        progress: {
          show: true,
          width: 10,
          // overlap: false,
          // roundCap: true,
          // clip: false,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#59F8AC'
              },
              {
                offset: 1,
                color: '#50C0FF'
              },
            ]),
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            // color: '#01071D',
            color: [
              [0.1, '#2c4c64'], // 0~10% 红轴
              [1, '#2c4c64'], // 10~20% 绿轴
            ],
            opacity: 0.4, //See here
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: [{
          value: val,
          // name: data.value.statusStr,
          name: 'kw',
          title: {
            offsetCenter: ['0%', '36%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0'],
            textShadowBlur: 12,
            textShadowColor: '#06B8FB',
          }
        }],
        title: {
          fontSize: 10,
          color: '#fff'
        },
        detail: {
          fontSize: 16,
          color: '#fff',
          // formatter: '{value}',
          // formatter: `{value}{a|%}`,
          // rich: {
          //   a: {
          //     fontSize: 12,
          //     color: '#fff',
          //     verticalAlign: 'bottom',
          //     width: 20,
          //     align: 'center',
          //     display: 'block'
          //   }
          // }
        },
      }]
    };

    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize({
      animation: {
        duration: 2800,
        easing: 'cubicInOut',
        // delay: 500,
      },
    });
    window.onresize = () => {
      myChart.resize();
    }
  }
}

// 加载列表双层头部图表
const circleChartLoad2 = function () {
  var chartDom = document.querySelector('.hsydCircleChart3_1');
  if (chartDom) {
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      series: [{
        type: 'gauge',
        startAngle: 245,
        endAngle: -62,
        pointer: {
          show: true,
          offsetCenter: ['0%', '-50%'],
          length: '50%',
          icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
          itemStyle: {
            borderCap: 'round',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#66FFFF'
              },
              {
                offset: 0.7,
                color: 'rgba(102,255,255,.6)'
              },
              {
                offset: 1,
                color: 'rgba(102,255,255,.1)'
              },
            ]),
          }
        },
        progress: {
          show: true,
          width: 10,
          // overlap: false,
          // roundCap: true,
          // clip: false,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#59F8AC'
              },
              {
                offset: 1,
                color: '#50C0FF'
              },
            ]),
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            // color: '#01071D',
            color: [
              [0.1, '#2c4c64'], // 0~10% 红轴
              [1, '#2c4c64'], // 10~20% 绿轴
            ],
            opacity: 0.4, //See here
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: [{
          value: data.value.stationFirst ? data.value.stationFirst.rate : '',
          // name: data.value.statusStr,
          name: 'kw',
          title: {
            offsetCenter: ['0%', '36%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0'],
            textShadowBlur: 12,
            textShadowColor: '#06B8FB',
          }
        }],
        title: {
          fontSize: 10,
          color: '#fff'
        },
        detail: {
          fontSize: 16,
          color: '#fff',
          // formatter: '{value}',
          // formatter: `{value}{a|%}`,
          // rich: {
          //   a: {
          //     fontSize: 12,
          //     color: '#fff',
          //     verticalAlign: 'bottom',
          //     width: 20,
          //     align: 'center',
          //     display: 'block'
          //   }
          // }
        },
      }]
    };

    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize({
      animation: {
        duration: 2800,
        easing: 'cubicInOut',
        // delay: 500,
      },
    });
    window.onresize = () => {
      myChart.resize();
    }
  }
}
// 加载列表双层头部图表
const circleChartLoad3 = function () {
  var chartDom = document.querySelector('.hsydCircleChart3_2');
  if (chartDom) {
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      series: [{
        type: 'gauge',
        startAngle: 245,
        endAngle: -62,
        pointer: {
          show: true,
          offsetCenter: ['0%', '-50%'],
          length: '50%',
          icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
          itemStyle: {
            borderCap: 'round',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#66FFFF'
              },
              {
                offset: 0.7,
                color: 'rgba(102,255,255,.6)'
              },
              {
                offset: 1,
                color: 'rgba(102,255,255,.1)'
              },
            ]),
          }
        },
        progress: {
          show: true,
          width: 10,
          // overlap: false,
          // roundCap: true,
          // clip: false,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#59F8AC'
              },
              {
                offset: 1,
                color: '#50C0FF'
              },
            ]),
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            // color: '#01071D',
            color: [
              [0.1, '#2c4c64'], // 0~10% 红轴
              [1, '#2c4c64'], // 10~20% 绿轴
            ],
            opacity: 0.4, //See here
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: [{
          value: data.value.stationSecond ? data.value.stationSecond.rate : '',
          // name: data.value.statusStr,
          name: 'kw',
          title: {
            offsetCenter: ['0%', '36%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0'],
            textShadowBlur: 12,
            textShadowColor: '#06B8FB',
          }
        }],
        title: {
          fontSize: 10,
          color: '#fff'
        },
        detail: {
          fontSize: 16,
          color: '#fff',
          // formatter: '{value}',
          // formatter: `{value}{a|%}`,
          // rich: {
          //   a: {
          //     fontSize: 12,
          //     color: '#fff',
          //     verticalAlign: 'bottom',
          //     width: 20,
          //     align: 'center',
          //     display: 'block'
          //   }
          // }
        },
      }]
    };

    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize({
      animation: {
        duration: 2800,
        easing: 'cubicInOut',
        // delay: 500,
      },
    });
    window.onresize = () => {
      myChart.resize();
    }
  }
}
// 加载列表双层列表
const circleChartLoad4 = function (i, val) {
  var chartDom = document.querySelector(`.hsydCircleChart4_${i}`);
  if (chartDom) {
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      series: [{
        type: 'gauge',
        startAngle: 245,
        endAngle: -62,
        pointer: {
          show: true,
          offsetCenter: ['0%', '-50%'],
          length: '50%',
          icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
          itemStyle: {
            borderCap: 'round',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#66FFFF'
              },
              {
                offset: 0.7,
                color: 'rgba(102,255,255,.6)'
              },
              {
                offset: 1,
                color: 'rgba(102,255,255,.1)'
              },
            ]),
          }
        },
        progress: {
          show: true,
          width: 10,
          // overlap: false,
          // roundCap: true,
          // clip: false,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#59F8AC'
              },
              {
                offset: 1,
                color: '#50C0FF'
              },
            ]),
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            // color: '#01071D',
            color: [
              [0.1, '#2c4c64'], // 0~10% 红轴
              [1, '#2c4c64'], // 10~20% 绿轴
            ],
            opacity: 0.4, //See here
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: [{
          value: val,
          // name: data.value.statusStr,
          name: 'kw',
          title: {
            offsetCenter: ['0%', '36%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0'],
            textShadowBlur: 12,
            textShadowColor: '#06B8FB',
          }
        }],
        title: {
          fontSize: 10,
          color: '#fff'
        },
        detail: {
          fontSize: 16,
          color: '#fff',
          // formatter: '{value}',
          // formatter: `{value}{a|%}`,
          // rich: {
          //   a: {
          //     fontSize: 12,
          //     color: '#fff',
          //     verticalAlign: 'bottom',
          //     width: 20,
          //     align: 'center',
          //     display: 'block'
          //   }
          // }
        },
      }]
    };

    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize({
      animation: {
        duration: 2800,
        easing: 'cubicInOut',
        // delay: 500,
      },
    });
    window.onresize = () => {
      myChart.resize();
    }
  }
}
// 加载列表双层列表
const circleChartLoad5 = function (i, val) {
  var chartDom = document.querySelector(`.hsydCircleChart5_${i}`);
  if (chartDom) {
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      series: [{
        type: 'gauge',
        startAngle: 245,
        endAngle: -62,
        pointer: {
          show: true,
          offsetCenter: ['0%', '-50%'],
          length: '50%',
          icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
          itemStyle: {
            borderCap: 'round',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#66FFFF'
              },
              {
                offset: 0.7,
                color: 'rgba(102,255,255,.6)'
              },
              {
                offset: 1,
                color: 'rgba(102,255,255,.1)'
              },
            ]),
          }
        },
        progress: {
          show: true,
          width: 10,
          // overlap: false,
          // roundCap: true,
          // clip: false,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#59F8AC'
              },
              {
                offset: 1,
                color: '#50C0FF'
              },
            ]),
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            // color: '#01071D',
            color: [
              [0.1, '#2c4c64'], // 0~10% 红轴
              [1, '#2c4c64'], // 10~20% 绿轴
            ],
            opacity: 0.4, //See here
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: [{
          value: val,
          // name: data.value.statusStr,
          name: 'kw',
          title: {
            offsetCenter: ['0%', '36%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0'],
            textShadowBlur: 12,
            textShadowColor: '#06B8FB',
          }
        }],
        title: {
          fontSize: 10,
          color: '#fff'
        },
        detail: {
          fontSize: 16,
          color: '#fff',
          // formatter: '{value}',
          // formatter: `{value}{a|%}`,
          // rich: {
          //   a: {
          //     fontSize: 12,
          //     color: '#fff',
          //     verticalAlign: 'bottom',
          //     width: 20,
          //     align: 'center',
          //     display: 'block'
          //   }
          // }
        },
      }]
    };

    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize({
      animation: {
        duration: 2800,
        easing: 'cubicInOut',
        // delay: 500,
      },
    });
    window.onresize = () => {
      myChart.resize();
    }
  }
}


let data = ref({
  "first": {
    "name": "",
    "offlineNumber": 0,
    "onlineNumber": 0,
    "rate": 0,
    "totalNumber": 0
  },
  "firstList": [],
  stationFirst: {
    subList: []
  },
  stationSecond: {
    subList: []
  }
})

// 数字轮播数据
let countUp1 = ref()
let countUp2 = ref()
let countUp3 = ref()
let countUp4 = ref()
let countUp5 = ref()
let countUp6 = ref()
//数字轮播初始值
let countStart1 = ref(0)
let countStart2 = ref(0)
let countStart3 = ref(0)
let countStart4 = ref(0)
let countStart5 = ref(0)
let countStart6 = ref(0)

// 获取大屏数据
const getDataFun = async function () {
  await getHsydScreenApi().then(res => {
    if (res.data.code === 0) {
      if (data.value.first.totalNumber != res.data.result.first.totalNumber) {
        countStart1.value = res.data.result.first.totalNumber
      }
      if (data.value.first.onlineNumber != res.data.result.first.onlineNumber) {
        countStart2.value = res.data.result.first.onlineNumber
      }
      if (data.value.first.offlineNumber != res.data.result.first.offlineNumber) {
        countStart3.value = res.data.result.first.offlineNumber
      }

      // 图表更新
      if (data.value.first.rate != res.data.result.first.rate) {
        nextTick(() => {
          circleChartLoad()
        })
      }
      if (data.value.stationFirst.rate != res.data.result.stationFirst.rate) {
        nextTick(() => {
          circleChartLoad2()
        })
      }
      if (data.value.stationSecond.rate != res.data.result.stationSecond.rate) {
        nextTick(() => {
          circleChartLoad3()
        })
      }


      for (let i = 0; i < data.value.firstList.length; i++) {
        if (data.value.firstList[i].rate !== res.data.result.firstList[i].rate) {
          nextTick(() => {
            circleChartLoad1(i, data.value.firstList[i].rate)
          })
        }
      }
      for (let i = 0; i < data.value.stationFirst.subList.length; i++) {
        if (data.value.stationFirst.subList[i].rate !== res.data.result.stationFirst.subList[i].rate) {
          nextTick(() => {
            circleChartLoad4(i, data.value.stationFirst.subList[i].rate)
          })
        }
      }
      for (let i = 0; i < data.value.stationSecond.subList.length; i++) {
        if (data.value.stationSecond.subList[i].rate !== res.data.result.stationSecond.subList[i].rate) {
          nextTick(() => {
            circleChartLoad5(i, data.value.stationSecond.subList[i].rate)
          })
        }
      }

      data.value = res.data.result


    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


onMounted(async () => {
  document.title = '惠生用电监控大屏'
  await getDataFun()
  updateTime()
  nextTick(() => {
    circleChartLoad()
    circleChartLoad2()
    circleChartLoad3()
    for (let i = 0; i < data.value.firstList.length; i++) {
      circleChartLoad1(i, data.value.firstList[i].rate)
    }
    for (let i = 0; i < data.value.stationFirst.subList.length; i++) {
      circleChartLoad4(i, data.value.stationFirst.subList[i].rate)
    }
    for (let i = 0; i < data.value.stationSecond.subList.length; i++) {
      circleChartLoad5(i, data.value.stationSecond.subList[i].rate)
    }
  })


})

// 定时器
const intervalId2 = setInterval(getDataFun, 10000)
const intervalId = setInterval(updateTime, 1000)
onUnmounted(() => {
  clearInterval(intervalId)
  clearInterval(intervalId2)
})

</script>

<style lang="less" scoped>
</style>
