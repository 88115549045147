import {api} from "@/api";


//能耗大屏数据

export const getData = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/microApplication/getData',  
        method: 'POST',
        data: data
    })
}