<template>
  <div class="waterStatement">
    <div class="title">
      <p>
        <span>用水报表</span>
      </p>
    </div>
    <div class="content">
      <div class="contain">
        <!--头部-->
        <div class="headBox">
          <p class="title">用水量</p>
          <div class="tabBox">
            <p :class="activeIdx===1?'active':''" @click="activeClick(1)">日报表</p>
            <p :class="activeIdx===2?'active':''" @click="activeClick(2)">月报表</p>
            <p :class="activeIdx===3?'active':''" @click="activeClick(3)">年报表</p>
          </div>
        </div>
        <!--搜索-->
        <div class="searchBox">
          <div class="liPox">
            <div class="liBox" style="display: flex">
              <el-date-picker v-if="activeIdx===1" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                              range-separator="→"
                              start-placeholder="开始时间"
                              type="daterange"
                              value-format="YYYY-MM-DD">
              </el-date-picker>
              <el-date-picker v-if="activeIdx===2" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                              range-separator="→"
                              start-placeholder="开始时间"
                              type="monthrange"
                              value-format="YYYY-MM-DD">
              </el-date-picker>
              <el-date-picker v-if="activeIdx===3"
                              v-model="dateTime[0]"
                              :disabled-date="disabledDate"
                              placeholder="开始时间"
                              type="year" value-format="YYYY-MM-DD"
              />
              <div v-if="activeIdx===3" class="segmentation" style="margin: 0 8px;transform: translateY(5px)">至</div>
              <el-date-picker v-if="activeIdx===3"
                              v-model="dateTime[1]"
                              :disabled-date="disabledDate2"
                              placeholder="结束时间"
                              type="year"
                              value-format="YYYY-MM-DD"
              />
            </div>
            <div class="liBox">
              <el-tree-select
                  ref="treeRef1"
                  v-model="ammeter"
                  :data="ammeterList"
                  :props="props"
                  :render-after-expand="false"
                  class="treeSelect"
                  filterable
                  multiple
                  node-key="id"
                  placeholder="请选择电表"
                  style="height: auto!important"
              />
            </div>
            <div class="liBox">
              <el-select v-model="chartType" placeholder="请选择图表类型" @change="planChange">
                <el-option :value="1" label="折线"></el-option>
                <el-option :value="2" label="柱状"></el-option>
              </el-select>
            </div>
          </div>
          <div class="liBox">
            <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
            <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
            <el-button class="searchButton resetButton" @click="exportFun">导出</el-button>
          </div>
        </div>
        <!--内容-->
        <div class="containBox">
          <el-empty v-if="echartsDataX.length===0"
                    class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
          <!--图表-->
          <div v-show="echartsDataX.length>0" class="chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref, watch} from "vue"
import './waterStatement.less'
import * as echarts from "echarts";
import {ElLoading, ElMessage} from "element-plus";
import moment from "moment";
import {exportComDataApi} from "@/api/modules/compoundQuery";
import {getReportDataApi, getAllTreeApi} from "@/api/modules/bigScreen";
import {http2} from "@/api";
import qs from "qs";


// 查询字段*********************************************************************************
let dateTime = ref([moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]) //开始结束时间
let ammeter = ref([]) //电表
let chartType = ref(1) //图表类型
const planChange = function () {
  nextTick(() => {
    chartLoad()
  })
}

watch(() => dateTime.value, (val) => {
  if (activeIdx == 3) {
    disabledDate(new Date(val[0]))
    disabledDate2(new Date(val[1]))
  }
})
const disabledDate = (time) => {
  if (dateTime.value[1]) {
    return time.getTime() >= moment(dateTime.value[1]).valueOf()
  }
  return false
}
const disabledDate2 = (time) => {
  if (dateTime.value[0]) {
    return time.getTime() < moment(dateTime.value[0]).valueOf()
  }
  return false
}


// 电表列表
let ammeterList = ref()


// 日月年切换
let activeIdx = ref(1)
const activeClick = function (idx) {
  activeIdx.value = idx
  if (activeIdx.value === 1) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  }
}

// 获取图表数据****************************************************************************************
let echartsDataY = ref([])
let echartsDataX = ref([])
const getEchartsDataFun = async function () {
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && ammeter.value && chartType.value) {
    echartsDataX.value = []
    echartsDataY.value = []
    openFullScreen()
    await getReportDataApi(qs.stringify({
      type: 2,
      timeType: activeIdx.value,
      startTime: dateTime.value[0] ? dateTime.value[0] : '',
      endTime: dateTime.value[1] ? dateTime.value[1] : '',
      equipmentIdList: ammeter.value.join(',')
    })).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        echartsDataX.value = res.data.result.timeList
        for (let i = 0; i < res.data.result.list.length; i++) {
          echartsDataY.value.push({
            name: res.data.result.list[i].key,
            type: chartType.value == 1 ? 'line' : 'bar',
            symbol: 'circle',
            symbolSize: 8,    //小圆点的大小
            itemStyle: {
              // color: '#427EFF',    //小圆点和线的颜色
              borderColor: '#fff', // 设置边框透明
              borderWidth: 1, // 设置边框宽度为0
              borderType: 'solid', // 设置边框类型为实线
            },
            data: res.data.result.list[i].value
          })
        }
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择查询条件'
    })
  }
}

// 查询
const searchFun = async function () {
  await getEchartsDataFun()
}
// 重置
const resetFun = function () {
  ammeter.value = []
  if (activeIdx.value === 1) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  }
  chartType.value = 1

  echartsDataY.value = []
  echartsDataX.value = []
  nextTick(() => {
    chartLoad()
  })
}

// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    series: echartsDataY.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 点击导出************************************************************************************
const exportFun = function () {
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && ammeter.value) {
    openFullScreen()
    window.location.href = http2 + 'zouk/rest/localScreenData/exportReportData?type=2&timeType=' + activeIdx.value + '&startTime=' + (dateTime.value[0] ? dateTime.value[0] : '') + '&endTime=' + (dateTime.value[0] ? dateTime.value[1] : '') + '&equipmentIdList=' + ammeter.value.join(',')
    setTimeout(() => {
      loadingAdd.value.close();
      ElMessage({
        type: 'success',
        message: '导出成功'
      });
    }, 1000);
    loadingAdd.value.close()
  } else {
    ElMessage({
      type: 'info',
      message: '请选择导出条件'
    });
  }
}

// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
onMounted(() => {
  chartLoad()


  // 获取所有设备树
  getAllTreeApi(qs.stringify({
    type: 2
  })).then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      ammeterList.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})


</script>

<style lang="less" scoped>
</style>
