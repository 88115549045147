import {api} from "@/api";
// 设备-------------------------------------------------------------
// 获取设备列表
export const getListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/equipmentControl/page',
        method: 'POST',
        data: data
    })
}

// 获取树级列表
export const getTreeListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/equipment/listTree',
        method: 'POST',
        data: data
    })
}
// 获取已选树级列表
export const getCheckTreeListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/equipment/getCheckTree',
        method: 'POST',
        data: data
    })
}

// 导入设备
export const importEquipmentApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/equipmentControl/import',
        method: 'POST',
        data: data
    })
}

// 删除设备列表
export const delEquipmentApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/equipmentControl/delete',
        method: 'POST',
        data: data
    })
}


// 参数-------------------------------------------------------------
// 获取参数列表
export const getParamListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControlParam/page',
        method: 'POST',
        data: data
    })
}

// 获取参数的key选项
export const getParamKeyApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/controlStrategy/listControlParametersKeySelectList',
        method: 'POST',
        data: data
    })
}

// 新建参数列表
export const addParamListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControlParam/save',
        method: 'POST',
        data: data
    })
}
// 编辑参数列表
export const editParamListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControlParam/modify',
        method: 'POST',
        data: data
    })
}
// 删除参数列表
export const delParamListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/net/deviceControlParam/delete/${id}`,
        method: 'POST',
    })
}


// 关联项-----------------------------------------------------------------------
// 获取设备基础信息
export const getDeviceInfoApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/equipmentControl/getHead',
        method: 'POST',
        data: data
    })
}
// 获取设备控制关联项列表
export const getDeviceControlListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControlRelated/list',
        method: 'POST',
        data: data
    })
}
// 控制按钮点击
export const controlClickApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/productControl/operate',
        method: 'POST',
        data: data
    })
}
// 获取设备树形列表
export const getDeviceTreeListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControl/project/tree',
        method: 'POST',
        data: data
    })
}
// 关联项导入
export const deviceImportApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControlRelated/import',
        method: 'POST',
        data: data
    })
}
// 关联项删除
export const delItemApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/net/deviceControlRelated/delete/${id}`,
        method: 'POST'
    })
}