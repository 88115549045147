import {createStore} from 'vuex'
// import {getList} from "@/api";

export default createStore({
    state: {
        allList: null,
        sourceData: {
            sourceEditor: null
        },
    },
    mutations: {
        updateList(state, allList) {
            state.allList = allList
        }
    },
    actions: {
        // getList: function (context) {
        //     getList().then(res => {
        //         if (res.data.code === 200) {
        //             context.commit('updateList', res.data.result)
        //         }
        //     })
        // }
    },
    modules: {}
})