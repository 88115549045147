import {api} from "@/api";

// 获取设备控制顶部信息
export const getHead = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentControl/getHead',
        method: 'POST',
        data: data
    })
}
// 获取产品控制选项
export const getControlList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/listProductControlSelect',
        method: 'POST',
        data: data
    })
}
// 产品控制操作
export const productControl = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productControl/operate',
        method: 'POST',
        data: data
    })
}
// 产品控制操作2
export const tProductControl = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/protocol3761/command/enableSwitch',
        method: 'POST',
        data: data
    })
}
//获取设备详情
export const getDeviceDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getDetail',
        method: 'POST',
        data: data
    })
}




