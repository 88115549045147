import {api3} from "@/api";

// 规则列表----------------------------------------------------------------------
// 获取规则列表
export const getListApi = (data) => {
    return api3({
        url: '/api/ekuiper/rules',
        method: 'GET',
        params: data
    })
}

// 获取规则列表中的数据
export const getListDataApi = (name) => {
    return api3({
        url: `/api/ekuiper/rules/${name}/status`,
        method: 'GET',
    })
}

// 启动或停止规则
export const isOpenRuleApi = (id, flag) => {
    return api3({
        url: `/api/ekuiper/rules/${id}/${flag}`,
        method: 'POST',
    })
}
// 规则重启
export const restRuleApi = (id) => {
    return api3({
        url: `/api/ekuiper/rules/${id}/restart`,
        method: 'POST',
    })
}

// 获取规则详情
export const getRuleDetailApi = (id) => {
    return api3({
        url: `/api/ekuiper/rules/${id}`,
        method: 'GET',
    })
}
// 新增规则
export const saveRuleApi = (data) => {
    return api3({
        url: "/api/ekuiper/rules",
        method: 'POST',
        data: data
    })
}
// 删除规则
export const delRuleApi = (id) => {
    return api3({
        url: `/api/ekuiper/rules/${id}`,
        method: 'DELETE',
    })
}

// 导出规则
export const exportRuleApi = (params) => {
    return api3({
        url: '/api/template',
        method: 'GET',
        params: params,
    })
}
// 导入规则
export const importRuleApi = (params, data) => {
    return api3({
        url: '/api/template',
        method: 'POST',
        params: params,
        data: data
    })
}


// 新建编辑规则----------------------------------------------------------------------------------
// 获取规则详情
export const ruleDetailApi = (id) => {
    return api3({
        url: `/api/ekuiper/rules/${id}`,
        method: 'GET',
    })
}

// 新建规则
export const addRuleDetailApi = (data) => {
    return api3({
        url: '/api/ekuiper/rules',
        method: 'POST',
        data: data
    })
}
// 编辑规则
export const editRuleDetailApi = (id, data) => {
    return api3({
        url: `/api/ekuiper/rules/${id}`,
        method: 'PUT',
        data: data
    })
}
// 获取数据源示例列表
export const getDataSourceApi = () => {
    return api3({
        url: '/api/ekuiper/streams',
        method: 'GET',
    })
}
// 获取数据源示例列表详情
export const getDataSourceDetailApi = (name) => {
    return api3({
        url: `/api/ekuiper/streams/${name}`,
        method: 'GET',
    })
}
// 选择sql模拟数据源
export const getDataSourceSelectApi = (data) => {
    return api3({
        url: '/api/ekuiper/rules/validate',
        method: 'POST',
        data: data
    })
}

// 运行测试
export const ruletestApi = (data) => {
    return api3({
        url: '/api/ekuiper/ruletest',
        method: 'POST',
        data: data
    })
}
// 开始运行测试
export const ruletestStartApi = (id) => {
    return api3({
        url: `/api/ekuiper/ruletest/${id}/start`,
        method: 'POST',
    })
}

// 停止运行测试
export const ruletestStopApi = (id) => {
    return api3({
        url: `/api/ekuiper/ruletest/${id}`,
        method: 'DELETE',
    })
}


