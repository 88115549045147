<template>
  <!--惠生用气监控大屏-->
  <div class="hsyqScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="head"></div>
        <div class="timeBox">
          <p class="time">{{ timeVal }}</p>
          <p class="date">{{ dateVal }}</p>
        </div>
        <div class="logo"></div>
        <!--统计图+table表格-->
        <div v-for="(body,id) in getDataValue" :key="id" :style="{left: `${body.left}px`}"
             class="moduleBox">
          <!--统计图-->
          <div :style="{'background':`url(${url}${body.fig}.png)no-repeat 0 0`}" class="topBox">
            <div class="dashboard">
              <div class="circleBox">
                <!--创建echarts仪表盘-->
                <div :class="['circleChart'+id+'_'+1, 'chart', 'circleChart']"></div>
                <p class="name">实时流量</p>
              </div>
            </div>
            <!--当日用气量-->
            <div class="gas">
              <div class="gasBox">
                <p>
                  <!--{{ body.total.amountToday }}-->
                  <count-up :end-val="body.total.amountToday"
                            :start-val="body.oldTotal.amountToday" class="value" decimalPlaces="2"
                            style="display: inline-block"></count-up>
                </p>
                <p>当日用气量（m³）</p>
              </div>
              <div class="gasBox">
                <p>
                  <count-up :end-val="body.total.amountMonth"
                            :start-val="body.oldTotal.amountMonth" class="value" decimalPlaces="2"
                            style="display: inline-block"></count-up>
                </p>
                <p>当月用气量（m³）</p>
              </div>
              <div class="gasBox">
                <p>
                  <count-up :end-val="body.total.amountYear"
                            :start-val="body.oldTotal.amountYear" class="value" decimalPlaces="2"
                            style="display: inline-block"></count-up>
                </p>
                <p>当年用气量（m³）</p>
              </div>
            </div>
            <div class="equipment">
              <div class="equipmentBox">
                <p>总设备数</p>
                <p>
                  <count-up :end-val="body.total.totalEquipmentNumber" :start-val="body.oldTotal.onlineEquipmentNumber"
                            class="numericValue"
                            style="display: inline-block"></count-up>
                  <span>台</span></p>
              </div>
              <div class="equipmentBox">
                <p>在线设备</p>
                <p>
                  <count-up :end-val="body.total.onlineEquipmentNumber" :start-val="body.oldTotal.onlineEquipmentNumber"
                            class="numericValue color-green"
                            style="display: inline-block"></count-up>
                  <span>台</span></p>
              </div>
              <div class="equipmentBox">
                <p>离线设备</p>
                <p>
                  <count-up :end-val="body.total.offlineEquipmentNumber" :start-val="body.oldTotal.onlineEquipmentNumber"
                            class="numericValue color-red"
                            style="display: inline-block"></count-up>
                  <span>台</span></p>
              </div>
            </div>
          </div>
          <!--table表格-->
          <div :style="{'background':`url(${url}${body.table}.png)no-repeat 0 0`}" class="bottomBox">
            <div class="bottom-table">
              <div class="table-th">
                <p>序号</p>
                <p>站点名称</p>
                <p>实时流量</p>
                <p>当天用量</p>
                <p>当月用量</p>
              </div>
              <el-scrollbar height="590px">
                <div v-if="body.list.length>0" :ref="`scroll_${id}`"
                     :style="body.list.length>0?styleObj:{}">
                  <!--                <div v-if="body.list.length>0" :class="[animateUp&&body.list.length>0?'animate-up':'']">-->
                  <div v-for="(item, index) in body.list" :key="index" class="table-tr">
                    <p>{{ item.index }}</p>
                    <p class="over-font">
                      <el-tooltip :content="item.name" placement="top">
                        <span>{{ item.name }}</span>
                      </el-tooltip>
                    </p>
                    <p class="over-font">
                      <el-tooltip :content="item.flowRate" placement="top">
                        <span>{{ item.flowRate }}</span>
                      </el-tooltip>
                      <span>m³/s</span></p>
                    <p class="over-font">
                      <el-tooltip :content="item.amountDay" placement="top">
                        <span>{{ item.amountDay }}</span>
                      </el-tooltip>
                      <span>m³</span></p>
                    <p class="over-font">
                      <el-tooltip :content="item.amountMonth" placement="top">{{ item.amountMonth }}</el-tooltip>
                      <span>m³</span></p>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </v-scale-screen>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, ref, watch} from "vue"
import VScaleScreen from "v-scale-screen";
import * as echarts from "echarts";
import {useCurrentTime} from "@/hooks/useCurrentTime";
import {getHsyqScreenApi} from "@/api/modules/bigScreen";
import CountUp from "vue-countup-v3";

const url = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/hsScreen/'
const {timeVal, dateVal} = useCurrentTime(1000) //当前时间
const getDataValue = ref({
  oxygen: {
    "list": [],
    "total": {
      "amountMonth": "0.00",
      "amountToday": "0.00",
      "amountYear": "0.00",
      "flowRate": "",
      "offlineEquipmentNumber": 0,
      "onlineEquipmentNumber": 0,
      "totalEquipmentNumber": 0
    },
    fig: 'hsYqBgc',
    table: 'hsYqlBgc',
    left: 12.5,
    ScrollHeight: ref(0),
    oldTotal: {}
  },
  co2: {
    "list": [],
    "total": {
      "amountMonth": "0.00",
      "amountToday": "0.00",
      "amountYear": "0.00",
      "flowRate": "",
      "offlineEquipmentNumber": 0,
      "onlineEquipmentNumber": 0,
      "totalEquipmentNumber": 0
    },
    fig: 'hsEyhtBgc',
    table: 'hsEyhtlBgc',
    left: 387.5,
    ScrollHeight: ref(0),
    oldTotal: {}
  },
  gas: {
    "list": [],
    "total": {
      "amountMonth": "0.00",
      "amountToday": "0.00",
      "amountYear": "0.00",
      "flowRate": "",
      "offlineEquipmentNumber": 0,
      "onlineEquipmentNumber": 0,
      "totalEquipmentNumber": 0
    },
    fig: 'hsRqBgc',
    table: 'hsRqlBgc',
    left: 762.5,
    ScrollHeight: ref(0),
    oldTotal: {}
  },
  air: {
    "list": [],
    "total": {
      "amountMonth": "0.00",
      "amountToday": "0.00",
      "amountYear": "0.00",
      "flowRate": "",
      "offlineEquipmentNumber": 0,
      "onlineEquipmentNumber": 0,
      "totalEquipmentNumber": 0
    },
    fig: 'hsYskqBgc',
    table: 'hsYskqlBgc',
    left: 1137.5,
    ScrollHeight: ref(0),
    oldTotal: {}

  },
  argon: {
    "list": [],
    "total": {
      "amountMonth": "0.00",
      "amountToday": "0.00",
      "amountYear": "0.00",
      "flowRate": "",
      "offlineEquipmentNumber": 0,
      "onlineEquipmentNumber": 0,
      "totalEquipmentNumber": 0
    },
    fig: 'hsYaqBgc',
    table: 'hsYaqlBgc',
    left: 1517.5,
    ScrollHeight: ref(0),
    oldTotal: {}
  },
})
const styleObj = ref({
  transform: "translate(0px, 0px)",
});
// const scroll = ref()
//创建echarts仪表盘
const circleChartLoad = (id, index, val) => {
  var chartDom = document.querySelector(`.circleChart${id}_${index}`);
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    series: [{
      type: 'gauge', // 仪表盘
      startAngle: 245, // 起始角度
      endAngle: -62, // 结束角度
      max: 3821.42, // 仪表盘最大值
      radius: "80%", //仪表盘的半径
      pointer: { // 仪表盘指针
        show: true, // 是否显示指针
        offsetCenter: ['0%', '-50%'], // 指针偏移
        length: '50%', // 指针长度
        icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg', // 指针图标
        width: 3, //指针的宽度
        itemStyle: { // 指针样式
          borderCap: 'round', // 指针端点
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ // 指针渐变色
            {
              offset: 0,
              color: '#66FFFF'
            },
            {
              offset: 0.7,
              color: 'rgba(102,255,255,.6)'
            },
            {
              offset: 1,
              color: 'rgba(102,255,255,.1)'
            },
          ]),
        }
      },
      progress: { // 仪表盘进度条
        show: true, // 是否显示进度条
        width: 10,//进度条宽度
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ // 进度条渐变色
            {
              offset: 0,
              color: '#59F8AC'
            },
            {
              offset: 1,
              color: '#50C0FF'
            },
          ]),
        }
      },
      axisLine: { // 仪表盘轴线
        lineStyle: { // 轴线样式
          width: 20, // 轴线宽度
          // color: '#01071D',
          color: [ // 轴线渐变色
            [0.1, '#2c4c64'], // 0~10% 红轴
            [1, '#2c4c64'], // 10~20% 绿轴
          ],
          opacity: 0.4, // 轴线透明度
        }
      },
      splitLine: { // 仪表盘分隔线
        show: false, // 是否显示分隔线
        distance: 0, // 分隔线距离
        length: 10 // 分隔线长度
      },
      axisTick: { // 仪表盘刻度
        show: false // 是否显示刻度
      },
      axisLabel: { // 仪表盘刻度文字
        show: false, // 是否显示刻度文字
        distance: 50 // 文字距离
      },
      data: [{ // 仪表盘数据
        // value: 1643.21,
        value: val ? val : 0.00,
        name: 'm³/s',
        // name: '实时流量', // 数据名称 仪表盘数据名称
        title: { // 数据名称样式
          offsetCenter: ['0%', '32%'] // 文字位置
        },
        detail: { // 数据值样式
          valueAnimation: true,
          offsetCenter: ['0%', '0'],
          textShadowBlur: 12,
          textShadowColor: '#06B8FB'
        }
      }],
      title: { // 仪表盘标题样式
        fontSize: 10,
        color: '#fff'
      },
      detail: { // 仪表盘数据值
        fontSize: 13,
        color: '#fff',
        // formatter: '{value}%',
        // formatter: `{value}{a|%}`,
        rich: { // 富文本样式
          a: {
            fontSize: 16,
            color: '#fff',
            verticalAlign: 'bottom',
            width: 20,
            align: 'center',
          }
        }
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
const gases = ['co2', 'air', 'argon', 'gas', 'oxygen'];
const scrollRefs = new Map();
//echarts 标识class
gases.forEach(item => {
  scrollRefs.set(`scroll_${item}`, ref())
})
// 获取数据并赋值
const geHstData = async () => {
  await getHsyqScreenApi().then(res => {
    gases.forEach(item => {
      getDataValue.value[item].list = mergeAndUpdate(getDataValue.value[item].list, res.data.result[item].list);
      getDataValue.value[item].total = res.data.result[item].total
    })
  })
}

// 监听数据变化 更新数组对象属性值mergeAndUpdate(旧数组, 新数组, identifierKey = '唯一属性,id或index')
function mergeAndUpdate(a, b, identifierKey = 'index') {
  if (b.length === 0) {
    return a
  }
  if (a.length === 0) {
    return b
  }
  const identifierMap = new Map(b.map(item => [item[identifierKey], item]));
  for (const itemA of a) {
    const itemB = identifierMap.get(itemA[identifierKey]);
    if (itemB) {
      // 对比并更新不同属性
      for (const key in itemB) {
        if (key !== identifierKey && itemA[key] !== itemB[key]) {
          itemA[key] = itemB[key];
        }
      }
    }
  }
  return a;
}

const nonFlowRateProps = [
  "amountMonth",
  "amountToday",
  "amountYear",
  "offlineEquipmentNumber",
  "onlineEquipmentNumber",
  "totalEquipmentNumber",
];
// 监听流量变化更新仪表盘
const watchFlowRate = () => {
  gases.forEach(item => {
    /* watch(
         () => getDataValue.value[item].total.flowRate,
         (newFlowRate, oldFlowRate) => {
           // 当 flowRate 值发生变化时，调用 circleChartLoad 函数
           if (newFlowRate !== oldFlowRate) {
             circleChartLoad(item, 1, newFlowRate);
           }
         },
         {deep: true} // 由于 flowRate 属性嵌套在对象内，需要开启深度监听
     );
     watch(
         () => getDataValue.value[item].total,
         (newValue, oldValue) => {
           // console.log('newValue', newValue)
           // console.log('oldValue', oldValue)
           for (const prop of nonFlowRateProps) {
             if (hasPropertyChanged(prop, newValue, oldValue)) {
               // console.log(oldValue);
               getDataValue.value[item].oldTotal = oldValue;
               // console.log(getDataValue.value[item].oldTotal)
               // 在此处执行监听到变化后的操作
             }
           }
         },
         {deep: true}
     );*/
    // 深度监听 flowRate 流量变化 ,total 设备状态变化
    watch(
        () => ({
          flowRate: getDataValue.value[item].total.flowRate,
          total: getDataValue.value[item].total
        }),
        ({flowRate: newFlowRate, total: newValue}, {flowRate: oldFlowRate, total: oldValue}) => {
          if (newFlowRate !== oldFlowRate) {
            circleChartLoad(item, 1, newFlowRate);
          }

          for (const prop of nonFlowRateProps) {
            if (hasPropertyChanged(prop, newValue, oldValue)) {
              getDataValue.value[item].oldTotal = oldValue;
            }
          }
        },
        {deep: true}
    );
  })
}

//对比属性是否变化
function hasPropertyChanged(prop, newValue, oldValue) {
  return newValue[prop] !== oldValue[prop];
}

// 子列表滚动轮播
const scrollAnimate = () => {
  gases.forEach((item) => {
    if (getDataValue.value[item].list && getDataValue.value[item].list.length > 15) {
      // if (getDataValue.value[item].list && getDataValue.value[item].list.length > 0) {
      if (getDataValue.value[item].ScrollHeight === 147) {
        // if (getDataValue.value[item].ScrollHeight === 30) {
        getDataValue.value[item].list.push(getDataValue.value[item].list[0]);
        getDataValue.value[item].list.shift();
        getDataValue.value[item].ScrollHeight = 0;
      }
      getDataValue.value[item].ScrollHeight++
      // ScrollHeight.value++
      styleObj.value.transform = `translate(0px, -${getDataValue.value[item].ScrollHeight}px)`;
    }
  })
}
//是否滚动
// 加载仪表盘
onMounted(() => {
  document.title = "惠生用气监控大屏"
  geHstData()
  nextTick(() => {
    watchFlowRate()
  })
})
// 销毁定时器
const carousel = setInterval(scrollAnimate, 100);
const Interval = setInterval(geHstData, 60000)
onUnmounted(() => {
  clearInterval(Interval)
  clearInterval(carousel)
})

</script>

<style lang="less" scoped>
// 引入样式文件
@import "./hsyqScreen.less";
</style>

