<template>
  <div class="strategy">
    <div class="title">
      <p>
        <span>策略管理</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <el-select v-model="deviceCategory" clearable placeholder="请选择设备大类" @change="change1">
            <el-option v-for="(item,index) in equipmentList" :key="index" :label="item.name"
                       :value="item.id"/>
          </el-select>
        </div>
        <div class="liBox">
          <el-select v-model="deviceModel" clearable placeholder="请选择设备型号" @change="change2">
            <el-option v-for="(item,index) in equipmentModelList" :key="index" :label="item.productModel"
                       :value="item.productId"/>
          </el-select>
        </div>
        <div class="liBox">
          <!--        <el-input v-model="controlId" placeholder="请输入控制类型"></el-input>-->
          <el-select v-model="controlId" clearable placeholder="请选择控制类型">
            <el-option v-for="(item,index) in controlList" :key="index" :label="item.controlName"
                       :value="item.controlId"/>
          </el-select>
        </div>
        <div class="liBox">
          <el-input v-model="deviceName" placeholder="请输入设备"></el-input>
        </div>
        <br>
        <div class="liBox">
          <!--        <el-input v-model="deviceStatus" placeholder="请输入设备状态"></el-input>-->
          <el-select v-model="deviceStatus" clearable placeholder="请选择设备状态">
            <el-option label="离线" value="0"/>
            <el-option label="在线" value="1"/>
          </el-select>
        </div>
        <div class="liBox">
          <el-date-picker v-model="timeSearch"
                          end-placeholder="结束日期"
                          range-separator="→"
                          start-placeholder="开始日期"
                          style="transform: translateY(-4px);"
                          type="daterange"
                          value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--参数列表-->
        <el-table ref="table" v-loading="loading" :data="tableData" :expand-row-keys="[expandId]"
                  row-key="controlStrategyId"
                  style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column type="expand">
            <template #default="props">
              <el-table v-loading="loading" :data="props.row.strategy" :header-cell-style="tableRowClassName2">
                <template #empty>
                  <el-empty class="emptyClass" description="暂无数据信息"
                            image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                  </el-empty>
                </template>
                <el-table-column label="执行时间" prop="child1" width="130px">
                  <template #default="scope">
                    {{ scope.row.durationBegin }} - <br>
                    {{ scope.row.durationEnd }}
                  </template>
                </el-table-column>
                <el-table-column label="执行频率" prop="frequency" width="90px"></el-table-column>
                <el-table-column label="操作参数及触发条件" prop="executionCondition">
                  <template #default="scope">
                    <div v-for="(item,index) in scope.row.executionCondition" :key="index" class="confBox confBoxCopy">
                      <p v-if="item.paramName">{{ item.paramName }} =</p>
                      <div v-for="(item_child,index_child) in item.data" :key="index_child" class="contBox">
                        <p v-if="index_child!==0" style="font-size: 20px">
                          {{ item.formula.slice(2 * index_child - 1, 2 * index_child) }}</p>
                        <div class="contItem">
                          <p v-if="item_child.type!=3" :title='item_child.deviceName' class="name">{{
                              item_child.deviceName
                            }}</p>
                          <p v-if="item_child.type!=3" :title='item_child.typeName' class="name">{{
                              item_child.typeName
                            }}</p>
                          <p v-if="item_child.type==2" class="time" style="color: #908f8f">时效{{
                              item_child.expirationTime
                            }}秒</p>
                          <p v-if="item_child.type==3" class="val" style="text-align: center">{{ item_child.val }}</p>
                          <p v-if="item_child.type==1" class="valUnit" style="text-align: right">{{
                              item_child.unit
                            }}</p>
                        </div>
                      </div>
                      <p v-if="item.paramUnit">{{ item.paramUnit }}(单位)</p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="备注" prop="memo" width="110px"></el-table-column>
                <el-table-column label="操作" width="90px">
                  <template #default="scope">
                    <p class="btn"
                       @click.prevent="editChildClick(scope.row.id,scope.row,props.row.deviceId,props.row.controlId,props.row.controlName,props.row.deviceControlId,props.row.controlStrategyId)">
                      编辑</p>
                    <p class="btn delClass"
                       @click.prevent="delChildClick(scope.row.id,scope.row)">删除</p>
                    <!--                    <img alt="" src="../../../assets/img/161@2x.png" style="width: 24px"-->
                    <!--                         @click.prevent="editChildClick(scope.row.id,scope.row,props.row.deviceId,props.row.controlId,props.row.controlName,props.row.deviceControlId,props.row.controlStrategyId)">-->
                    <!--                    <img alt="" src="../../../assets/img/163@2x.png" style="width: 24px"-->
                    <!--                         @click.prevent="delChildClick(scope.row.id,scope.row)">-->
                    <p class="btn" @click.prevent="useChildClick(scope.row.id,scope.row)">{{
                        scope.row.status == 1 ? '启用' : '禁用'
                      }}</p>
                    <p class="btn"
                       @click.prevent="logChildClick(scope.row.id,scope.row,props.row.controlId)">运行日志</p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="设备大类" prop="deviceCategoryName" width="110px">
          </el-table-column>
          <el-table-column label="设备型号" prop="deviceModel" width="110px">
          </el-table-column>
          <el-table-column label="设备名称" prop="deviceName" width="110px">
          </el-table-column>
          <el-table-column label="状态" prop="deviceStatus" width="90px">
            <template #default="scope">
              <p>{{ scope.row.deviceStatus == 0 ? '离线' : '在线' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="控制" prop="controlName" width="90px">
          </el-table-column>
          <el-table-column label="操作参数" min-width="5%" prop="strategy">
            <template #default="scope">
              <div v-for="(item,index) in scope.row.controlParameters" :key="index" class="confBox">
                <p>{{ item }}</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,prev, pager, next,sizes" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref, watch} from "vue"
import './strategy.less'
import {CirclePlus, CircleClose, Plus, Search} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {onBeforeRouteLeave, onBeforeRouteUpdate, useRouter} from 'vue-router';
import {
  delStrategyListApi, disableStrategyApi, enableStrategyApi,
  getControlListApi,
  getEquipmentModelListApi,
  getStrategyListApi,
  getEquipmentTypeListApi
} from "@/api/modules/strategy";
import qs from "qs";

let router = useRouter()


// 参数配置列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 参数表格数据
let tableData = ref([])
// 获取参数列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getStrategyListApi({
    Page: {
      pageSize: pageSize.value,
      pageNo: pageNumber.value,
    },
    deviceName: deviceName.value ? deviceName.value : '',
    deviceModel: deviceModel.value ? [Number(deviceModel.value)] : [],
    deviceStatus: deviceStatus.value ? [deviceStatus.value] : [],
    deviceCategoryId: deviceCategory.value ? [Number(deviceCategory.value)] : [],
    durationBegin: timeSearch.value[0],
    durationEnd: timeSearch.value[1],
    controlId: controlId.value
  }).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = JSON.parse(JSON.stringify(res.data.result.data))
      for (let i = 0; i < tableData.value.length; i++) {
        tableData.value[i].controlParameters = []
        for (let j = 0; j < tableData.value[i].strategy.length; j++) {
          tableData.value[i].strategy[j].executionCondition = JSON.parse(tableData.value[i].strategy[j].executionCondition)
          tableData.value[i].strategy[j].executionCondition.push(...JSON.parse(tableData.value[i].strategy[j].controlParameters))
        }
        let arr = JSON.parse(tableData.value[i].strategy[0].controlParameters)
        for (let j = 0; j < arr.length; j++) {
          tableData.value[i].controlParameters.push(arr[j].paramName)
        }
      }
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击编辑
const editChildClick = function (id, row, deviceId, control, controlName, deviceControlId, controlStrategyId) {
  router.push({
    path: '/admin/saveStrategy',
    query: {
      controlStrategyId: row.controlStrategyId,
      deviceId: deviceId,
      controlId: control,
      cname: controlName,
      dControlId: deviceControlId,
      csId: controlStrategyId,
    }
  })
}
// 点击删除
const delChildClick = function (id, row) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除策略',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此策略，删除后数据不可恢复'),
    ])
  }).then(() => {
    delStrategyListApi({
      controlStrategyId: row.controlStrategyId
    }).then(res => {
      if (res.data.code == 0) {
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 点击启用或禁用
const useChildClick = function (id, row) {
  ElMessageBox.confirm(`确认要${row.status == 1 ? '禁用' : '启用'}吗？`, 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    row.status == 1 ? disableStrategyApi({
      controlStrategyId: row.controlStrategyId,
    }).then(res => {
      if (res.data.code == 0) {
        ElMessage({
          type: 'success',
          message: '禁用成功'
        });
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    }) : enableStrategyApi({
      controlStrategyId: row.controlStrategyId,
    }).then(res => {
      if (res.data.code == 0) {
        ElMessage({
          type: 'success',
          message: '启用成功'
        });
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: `已取消${row.status == 1 ? '禁用' : '启用'}`
    });
  });
}
// 点击运行日志
const logChildClick = function (id, row, controlId) {
  router.push({
    path: '/admin/strategyLog',
    query: {
      controlStrategyId: row.controlStrategyId,
      controlId: controlId
    }
  })
}


// 编辑展开时返回的id
let expandId = ref()


// 搜索-----------------------------------------------------------------
let deviceCategory = ref()
let deviceModel = ref()
let controlId = ref(router.currentRoute.value.query.controlId)
let deviceName = ref()
let deviceStatus = ref()
let timeSearch = ref([])
let equipmentList = ref([])  //设备大类列表
let equipmentModelList = ref([])  //设备型号列表
let controlList = ref([])  //设备控制列表
// 获取设备大类列表
const getEquipmentTypeListFun = function () {
  getEquipmentTypeListApi().then(res => {
    if (res.data.code == 0) {
      equipmentList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 获取产品型号列表
const getEquipmentModelListFun = function () {
  getEquipmentModelListApi(qs.stringify({
    categoryId: deviceCategory.value
  })).then(res => {
    if (res.data.code == 0) {
      equipmentModelList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 获取控制列表
const getControlListFun = function () {
  getControlListApi(qs.stringify({
    productId: deviceModel.value
  })).then(res => {
    if (res.data.code == 0) {
      controlList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 设备大类选择
const change1 = function () {
  getEquipmentModelListFun()
}
// 产品型号选择
const change2 = function () {
  getControlListFun()
}
// 搜索
const searchFun = function () {
  getListFun()
}
// 重置
const resetFun = function () {
  pageSize.value = 20
  pageNumber.value = 1
  deviceName.value = ''
  deviceModel.value = null
  deviceStatus.value = null
  deviceCategory.value = null
  timeSearch.value = []
  controlId.value = ''
  getListFun()
}


onMounted(() => {
  getListFun()
  getEquipmentTypeListFun()
  expandId.value = router.currentRoute.value.query.csId
})

</script>

<style lang="less" scoped>
</style>
