<template>
  <!--控制页面-->
  <div class="control">
    <div class="title">控制管理</div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <el-input v-model="deviceName" :prefix-icon="Search" autocomplete="off" clearable
                    placeholder='请输入设备名名称' type="text"></el-input>
        </div>
        <div class="liBox">
          <el-select v-model="deviceStatus" clearable placeholder="请选择设备状态" style="width:100%">
            <el-option :value="1" label="在线"></el-option>
            <el-option :value="0" label="离线"></el-option>
          </el-select>
        </div>
        <div class="liBox">
          <el-input v-model="controlName" :prefix-icon="Search" autocomplete="off" clearable
                    placeholder='请输入控制名称' type="text"></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <div class="tableBox">
        <div class="operationBox">
          <el-button class="searchButton importButton" type="primary" @click="importClick">导入</el-button>
        </div>
        <el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面导入设备</p>
            </el-empty>
          </template>
          <el-table-column label="设备型号" property="equipmentModel">
          </el-table-column>
          <el-table-column label="设备名称" property="equipmentName">
          </el-table-column>
          <el-table-column label="状态" property="equipmentStatus">
            <template #default="scope">
              <span v-if="scope.row.equipmentStatus==1">在线</span>
              <span v-if="scope.row.equipmentStatus==0">离线</span>
            </template>
          </el-table-column>
          <el-table-column label="导入时间" property="importTime">
          </el-table-column>
          <el-table-column label="删除" property="name">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass delClass" @click="deleteClick(scope.row.id)">删除</li>
              </ul>
              <!--              <div class="opacity">-->
              <!--                <img alt="" src="../../../assets/img/163@2x.png" @click="deleteClick(scope.row.id)">-->
              <!--              </div>-->
            </template>
          </el-table-column>
          <el-table-column label="控制" property="name">
            <template #default="scope">
              <div v-for='item in scope.row.controlItemList' class="controlClass">
                          <span style="cursor: pointer;color: #165DFF;"
                                @click="controlList(item.controlId)">{{ item.controlName }}</span>
              </div>
            </template>
          </el-table-column>
          <!--          <el-table-column label="控制名称" property="equipmentModel"/>-->
          <!--          <el-table-column label="控制设备" property="equipmentModel"/>-->
          <!--          <el-table-column label="设备的控制" property="equipmentModel"/>-->
          <!--          <el-table-column label="备注" property="equipmentModel"/>-->
          <el-table-column label="操作">
            <template #default="scope">
              <div v-for='item in scope.row.controlItemList' class="controlClass">
                <a class="editClass" @click.prevent="parameterClick(scope.row.id,item.controlId)">参数</a>
                <a class="editClass"
                   @click.prevent="relatedItemsClick(item.controlId,scope.row.id,scope.row.equipmentId,item.controlName)">关联项</a>
                <a class="editClass"
                   @click.prevent="addControlStrategy(item.controlId,scope.row.id,scope.row.equipmentId,item.controlName)">新增策略</a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--参数列表弹窗-->
    <el-dialog v-model="parameterDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div style="position: relative;width:100%;height: 40px;">
        <el-button class="searchButton addButton" type="primary" @click="addClick()">新增参数</el-button>
      </div>
      <div class="tableBox">
        <el-table v-loading="controlParamsLoading" :data="controlParamsData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增参数</p>
            </el-empty>
          </template>
          <el-table-column label="参数名称" property="paramName">
          </el-table-column>
          <el-table-column label="参数单位" property="paramUnit">
          </el-table-column>
          <el-table-column label="参数key" property="controlParamValue">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <!--              <ul class="operationUl">-->
              <!--                <li class="editClass" @click.prevent="editClick(scope.row.deviceControlParamId,scope.row)">编辑</li>-->
              <!--                <li class="editClass" @click.prevent="deleteParamClick(scope.row.deviceControlParamId)">删除</li>-->
              <!--              </ul>-->
              <div class="opacity">
                <span class="btn" @click.prevent="editClick(scope.row.deviceControlParamId,scope.row)">编辑</span>
                <span class="btn delClass" @click.prevent="deleteParamClick(scope.row.deviceControlParamId)">删除</span>
                <!--                <img alt="" src="../../../assets/img/161@2x.png"-->
                <!--                     @click.prevent="editClick(scope.row.deviceControlParamId,scope.row)">-->
                <!--                <img alt="" src="../../../assets/img/163@2x.png"-->
                <!--                     @click.prevent="deleteParamClick(scope.row.deviceControlParamId)">-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize2" :page-sizes="[20,100, 200, 300, 400]"
                         :total="total2"
                         background layout="total, prev, pager, next, sizes"
                         small @size-change="handleSizeChange2" @current-change="handleCurrentChange2"/>
        </div>
      </div>
      <div style="position: relative;width:100%;height: 40px;">
        <el-button class="searchButton addButton" type="primary" @click="parameterDialogVisible=false">关闭</el-button>
      </div>
    </el-dialog>
    <!--新增、编辑参数弹窗-->
    <el-dialog v-model="addDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth2">
      <div>
        <el-form ref="paramFormRef" :model="paramForm" :rules="paramRules" class="demo-ruleForm" status-icon>
          <el-form-item label="参数名称" prop="paramName">
            <el-input v-model="paramForm.paramName" placeholder="请输入参数名称"/>
          </el-form-item>
          <el-form-item label="参数单位" prop="paramUnit">
            <el-input v-model="paramForm.paramUnit" placeholder="请输入参数单位"/>
          </el-form-item>
          <el-form-item label="参数key" prop="controlParamKey">
            <el-select v-model="paramForm.controlParamKey" filterable placeholder="请选择参数key" style="width: 100%">
              <el-option v-for="item in keySelectList" :key="item.id" :label="item.value" :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="paramSave">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--导入弹窗-->
    <el-dialog v-model="importDialogVisible" :close-on-click-modal="false" title="导入" width="70%">
      <div class="transferBox" style="padding: 10px;">
        <div class="leftTransfer">
          <div class="title">待选分组</div>
          <el-tree
              ref="unCheckTree"
              :load="leftTree"
              :props="props"
              lazy
              show-checkbox
              @check-change="handleCheckChange1"
          />
        </div>
        <div class="centerTransfer">
          <el-button style="margin: 10px 40px" type="primary" @click="addTransfer">添加</el-button>
          <el-button type="primary" @click="removeTransfer">删除</el-button>
        </div>
        <div class="rightTransfer">
          <div class="title">已选分组</div>
          <el-tree
              ref="checkTree"
              :data="rightTree"
              :default-checked-keys="defaultCheck"
              :default-expand-all="true"
              :props="props"
              node-key="idx"
              show-checkbox
          />
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="importDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="importSave">保存</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="importDialogVisible2" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div class="transferBox" style="padding: 10px;">
        <el-form ref="paramFormRef" :model="paramForm" :rules="paramRules" class="demo-ruleForm" status-icon
                 style="width: 100%">
          <el-form-item label="控制名称" prop="controlParamKey">
            <el-select v-model="paramForm.controlParamKey" filterable placeholder="请选择控制名称" style="width: 100%">
              <el-option value="储能开机"/>
              <el-option value="储能停机"/>
              <el-option value="储能充电"/>
              <el-option value="储能放电"/>
              <el-option value="光伏开机"/>
              <el-option value="光伏关机"/>
              <el-option value="光伏放电功率"/>
            </el-select>
          </el-form-item>
          <el-form-item label="设备控制" prop="controlParamKey">
            <el-tree-select
                ref="treeRef"
                v-model="equipmentControl"
                :data="allTree"
                :props="props"
                :render-after-expand="false"
                filterable
                multiple
                node-key="id"
                show-checkbox
                style="width: 100%;height: auto!important;"
            />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="importDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="importSave">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, nextTick, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {Search} from '@element-plus/icons-vue'

import {
  addParamListApi,
  delEquipmentApi, delParamListApi, editParamListApi, getCheckTreeListApi,
  getListApi, getParamKeyApi,
  getParamListApi,
  getTreeListApi,
  importEquipmentApi
} from '@/api/modules/control'
import TreeTransfer from '../../../components/treeTransfer/treeTransfer'
import './control.less'
import qs from 'qs';
import {getAllTreeApi} from "@/api/modules/strategy";

let router = useRouter()

// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
let allTree = ref()
/*生命周期*/
onMounted(() => {
  getListFun()
  // 获取所有设备树
  getAllTreeApi().then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      allTree.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})

let equipmentControl = ref()


let controlName = ref('')
let deviceStatus = ref('')
let deviceList = ref([])
let deviceName = ref('')
let tableData = ref([])

let loadingVal = ref(false) //表格加载
//获取列表
const getListFun = function () {
  loadingVal.value = true
  getListApi(qs.stringify({
    pageSize: 20,
    pageNumber: 1,
    equipmentName: deviceName.value,
    equipmentStatus: deviceStatus.value,
    controlName: controlName.value,
  })).then(res => {
    //console.log(res);
    loadingVal.value = false
    if (res.data.ok) {
      tableData.value = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 重置
const resetFun = function () {
  deviceName.value = ''
  deviceStatus.value = ''
  controlName.value = ''
  getListFun()
}

//控制按钮的跳转
const controlList = function (controlId) {
  router.push({
    path: '/admin/strategy',
    query: {
      controlId: controlId
    }
  })
}
//关联项的点击
const relatedItemsClick = function (controlId, id, equipmentId, controlName) {
  console.log(controlId, id, equipmentId, controlName)
  router.push({
    path: '/admin/relatedItems',
    query: {
      coId: controlId,
      dcoId: id,
      dId: equipmentId,
      cname: controlName
    }
  })
}
//新增策略的点击
const addControlStrategy = function (controlId, id, equipmentId, controlName) {
  router.push({
    path: '/admin/saveStrategy',
    query: {
      controlId: controlId,
      dControlId: id,
      deviceId: equipmentId,
      cname: controlName
    }
  })
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除设备',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    delEquipmentApi(qs.stringify({
      equipmentIdList: id
    })).then(res => {
      if (res.data.code === 0) {
        getListFun()
        ElMessage({
          message: '删除成功',
          type: 'success'
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
//参数相关****************************************************************************************
let parameterDialogVisible = ref(false) //参数表格弹窗开关
let dialogWidth = ref('40%') //参数表格弹窗宽度
let controlParamsLoading = ref(false) //参数加载
let controlParamsData = ref([]) //参数列表
let deviceControlId = ref('') //设备控制id
let controlId = ref('') //控制id
let title = ref() //弹窗标题
let dialogWidth2 = ref('40%') //新增参数弹窗宽度
let addDialogVisible = ref(false) //新增参数弹窗开关
let pageSize2 = ref(20)  //每页条数
let pageIndex2 = ref(1)  //当前页
let total2 = ref(0)  //总条数
//每页多少
const handleSizeChange2 = function (val) {
  pageSize2.value = val;
  getControlParamList();
}
//更改页数
const handleCurrentChange2 = function (val) {
  pageIndex2.value = val;
  getControlParamList();
}
let loading2 = ref(false)
// 获取参数列表
const getControlParamList = function () {
  loading2.value = true
  getParamListApi(qs.stringify({
    pageSize: pageSize2.value,
    pageNum: pageIndex2.value,
    deviceControlId: deviceControlId.value,
    controlId: controlId.value
  })).then(res => {
    loading2.value = false
    if (res.data.code === 0) {
      controlParamsData.value = res.data.result.records
      for (let i = 0; i < controlParamsData.value.length; i++) {
        for (let j = 0; j < keySelectList.value.length; j++) {
          if (controlParamsData.value[i].controlParamKey == keySelectList.value[j].id) {
            controlParamsData.value[i].controlParamValue = keySelectList.value[j].value
          }
        }
      }
      total2.value = Number(res.data.result.total)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//获取控制参数key选项
const getKeySelectList = function () {
  getParamKeyApi().then(res => {
    //console.log(res)
    if (res.data.code === 0) {
      keySelectList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err)
  })
}
//参数的点击
const parameterClick = function (did, cid) {
  deviceControlId.value = did
  controlId.value = cid
  getControlParamList()
  title.value = "设置参数"
  parameterDialogVisible.value = true
  getKeySelectList();
}
//新增参数的点击
const addClick = function () {
  addDialogVisible.value = true
  title.value = '新增参数'
  editId.value = false
  paramForm.value = {
    paramName: '',
    paramUnit: '',
    controlParamKey: '',
    deviceControlParamId: 0,
    controlId: controlId.value,
    deviceControlId: deviceControlId.value
  }
}
//参数编辑的点击
const editClick = function (id, row) {
  addDialogVisible.value = true
  title.value = '编辑参数'
  editId.value = true
  paramForm.value = JSON.parse(JSON.stringify(row))
}
// 参数表单元素
let paramFormRef = ref()
//参数表单
let paramForm = ref({
  paramName: '',
  paramUnit: '',
  controlParamKey: '',
  deviceControlParamId: 0,
  controlId: controlId.value,
  deviceControlId: deviceControlId.value
})
let keySelectList = ref([]) //参数key列表
// 参数表单验证规则
let paramRules = ref({
  paramName: [{
    required: true,
    message: '请输入参数名称',
    trigger: 'blur'
  },],
  paramUnit: [{
    required: true,
    message: '请输入参数单位',
    trigger: 'blur'
  },],
  controlParamKey: [{
    required: true,
    message: '请选择参数key',
    trigger: 'blur'
  },],
})
//参数的保存
let editId = ref(false)
const paramSave = function () {
  if (paramFormRef) {
    paramFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        //console.log(params)
        if (editId.value) { //编辑
          editParamListApi(paramForm.value).then(res => {
            if (res.data.code === 0) {
              addDialogVisible.value = false
              getControlParamList()
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          addParamListApi(paramForm.value).then(res => {
            if (res.data.code === 0) {
              addDialogVisible.value = false
              getControlParamList()
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err)
          })
        }
      }
    })
  }
}
// 参数删除
const deleteParamClick = function (id) {
  ElMessageBox.confirm("", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    delParamListApi(id).then(res => {
      if (res.data.code === 0) {
        getControlParamList();
        ElMessage({
          type: 'success',
          message: '删除成功'
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    })
  });
}
/*导入******************************************************************************************/
let importDialogVisible = ref(false)
//导入设备的点击
const importClick = async function () {
  importDialogVisible.value = true
}
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({parentId: node.data.id ? node.data.id : 0, type: 1})).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 给右面树形结构加唯一值
const giveOneValue = async function (list) {
  for (let i = 0; i < list.length; i++) {
    list[i].idx = list[i].type + '-' + list[i].id
    if (list[i].subList.length > 0) {
      await giveOneValue(list[i].subList)
    }
  }
}
const dataSource = ref([
  {
    idx: 1,
    name: 'Level one 1',
    subList: [
      {
        idx: 4,
        name: 'Level two 1-1',
        subList: [
          {
            idx: 9,
            name: 'Level three 1-1-1',
          },
          {
            idx: 10,
            name: 'Level three 1-1-2',
          },
        ],
      },
    ],
  },
  {
    idx: 2,
    name: 'Level one 2',
    subList: [
      {
        idx: '5/1',
        name: 'Level two 2-1',
      },
      {
        idx: 6,
        name: 'Level two 2-2',
      },
    ],
  }
])
// 给右面树形结构加默认选中
const defaultCheckFun = async function (list) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].type === 2) {
      await nextTick(() => {
        defaultCheck.value.push(list[i].idx)
        checkTree.value.setCheckedKeys(defaultCheck.value)
      })
    }
    if (list[i].subList.length > 0) {
      await defaultCheckFun(list[i].subList)
    }
  }
}
//获取右侧项目树
let defaultCheck = ref([])
const getRightProjectFun = async function () {
  await getCheckTreeListApi(checkList.value.length > 0 ? qs.stringify({equipmentIdList: checkList.value.toString()}) : null).then(async res => {
    if (res.data.code === 0) {
      let json = JSON.parse(JSON.stringify(res.data.result))
      await giveOneValue(json)
      rightTree.value = json
      defaultCheck.value = []
      await defaultCheckFun(rightTree.value)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 左侧项目树
const leftTree = (node, resolve) => {
  getProjectFun(node, resolve)
}
// 右侧项目树
const rightTree = ref([])
// 已选中的设备
let checkList = ref([])
// 左侧项目树元素
let unCheckTree = ref()
// 右侧项目树元素
let checkTree = ref()
// 穿梭框点击添加
const addTransfer = function () {
  let arr = unCheckTree.value.getCheckedNodes(true)
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].type === 2) {
      checkList.value.push(arr[i].id)
    }
  }
  getRightProjectFun()
}
// 穿梭框点击移除
const removeTransfer = function () {
  let arr = checkTree.value.getCheckedNodes(true)
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].type === 2) {
      // checkList.value.push(arr[i].id)
      checkList.value = checkList.value.filter(item => item !== arr[i].id)
    }
  }
  getRightProjectFun()
}
//导入的保存
const importSave = function () {
  var importData = ''
  if (checkList.value.length > 0) {
    importData = checkList.value.join(",")
    importEquipmentApi(qs.stringify({
      equipmentIdList: importData,
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '导入成功！'
        })
        importDialogVisible.value = false
        getListFun();
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      message: '请先选择设备',
      type: 'info',
    })
  }
}
</script>