<template>
  <div id="app" @click="clicked()">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script setup>
//element表格宽度问题
import {onMounted} from "vue";
import {useRouter} from 'vue-router';

let router = useRouter()

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
const clicked = function () {
  // if (!window.localStorage.getItem('memberId')) {
  //   router.push('/login')
  // }
}
onMounted(() => {
  // //设置超时退出
  // var lastTime = new Date().getTime();
  // var currentTime = new Date().getTime();
  // var timeOut = 240 * 60 * 1000; //设置超时时间： 4个小时
  // /* 鼠标移动事件 */
  // document.addEventListener('mouseover', function () {
  //   lastTime = new Date().getTime(); //更新操作时间
  //   window.localStorage.setItem('lastTime', lastTime)
  // });
  //
  //
  // /* 定时器  间隔1秒检测是否长时间未操作页面  */
  // window.setInterval(() => {
  //   currentTime = new Date().getTime(); //更新当前时间
  //   let lastTime = window.localStorage.getItem('lastTime')
  //   if (currentTime - lastTime > timeOut) { //判断是否超时
  //     //this.$message({type: 'info',message: '登陆超时，请重新登陆！'})
  //     window.localStorage.clear();
  //     console.log('登录超时')
  //   }
  // }, 1000);
})
</script>

<style lang="less">
html,
body,
h1,
h2,
h3,
h4,
p,
div {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85)
}

.cell {
  color: inherit;
}

ul li,
ol li {
  list-style-type: none;
}

.icon,
.iconfont {
  //color: $navColor;
  //font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

//输入框
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input {
  border: none
}

//按钮
.el-button--primary {
  background-color: #165DFF !important;
}

:root {
  --el-color-primary: #165DFF;
}

//表格
.el-table tr th {
  background-color: #F2F3F5 !important;
  color: #1D2129;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #E5E6EB !important;
}

.el-table tr {
  height: 50px;
  line-height: 50px;
}

.el-table--border .el-table__inner-wrapper::after,
.el-table--border::after,
.el-table--border::before,
.el-table__inner-wrapper::before {
  background-color: transparent !important;
}

.el-input__inner {
  //height: 32px!important;
}

/*弹窗*/

.el-dialog__body {
  border-top: 1px solid #E5E6EB;
  border-bottom: 1px solid #E5E6EB;
}

.el-dialog__title {
  font-size: 16px !important;
  font-weight: bold;
}

.resetButton {
  background: #F2F3F5 !important;
  border-radius: 3px !important;
  border: 1px solid #F2F3F5 !important;
}

//分页
:root {
  .el-pagination {
    --el-color-primary: #165DFF;
  }
}

.el-loading-mask {
  background-color: rgba(255, 255, 255, .9) !important;
}

/*空状态*/
.emptyClass {
  .el-empty__description {
    margin-top: 0 !important;

    p {
      height: 40px;
      font-size: 20px;
      color: #000;
    }
  }

  .el-empty__bottom {
    margin-top: 0 !important;

    p {
      font-size: 14px;
      color: #999 !important;
    }
  }
}

//删除取消
.el-message-box__btns {
  .el-button:first-child {
    background: #F2F3F5 !important;
    border-radius: 3px !important;
    border: 1px solid #F2F3F5 !important;
  }
}
</style>