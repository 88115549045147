<!--流程图旁边的数值-->
<template>
  <div v-if="type" class="main" :style="{ '--font-color': props.data.color,'--font-size': props.data.font}">
    <p :style="margin[index]" v-for="(item, index) in props.data.list" :key="index">
      <!-- 使用动画值数组中对应的值 -->
<!--      {{ animatedValues !== 0.00 ? animatedValues[index].toFixed(2) + props.data.unit : `${item.value}${props.data.unit}` }}-->
      {{ animatedValues[index]!==0 ? animatedValues[index].toFixed(2) + props.data.unit : '' }}
    </p>
  </div>
</template>

<script setup>
import {defineProps,defineAsyncComponent, ref, watch} from 'vue';

const props = defineProps({
  data: Object,
  type: Boolean,
});

const margin = [
  `margin-top:${props.data.onLineTop}px;margin-left:56px;`,
  `margin-top:${props.data.offlineTop}px;margin-left:10px;`,
  `margin-top:${props.data.onLineTop}px;margin-left:-8px;`,
  `margin-top:${props.data.offlineTop}px;margin-left:44px;`,
  `margin-top:${props.data.onLineTop}px;margin-left:-4px;`,
  `margin-top:${props.data.offlineTop}px;margin-left:30px;`,
  `margin-top:${props.data.onLineTop}px;margin-left:5px;`,
  `margin-top:188px;margin-left:-10px;`,
];

// 用于动画显示的响应式数据数组，初始化为8个0.0
const animatedValues = ref(new Array(8).fill(0.0));

// 动画函数，逐渐改变animatedValues中指定索引的值，直到它等于newValue
function animateValueChange(index, newValue) {
  const startValue = animatedValues.value[index];
  const change = newValue - startValue;
  const duration = 2000; // 动画持续时间，单位为毫秒
  let startTime;

  function step(timestamp) {
    if (!startTime) startTime = timestamp;
    const progress = Math.min((timestamp - startTime) / duration, 1);
    animatedValues.value[index] = startValue + change * progress;

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
}

// 监听props.data.list中每个元素的变化，当它变化时，启动动画
watch(() => props.data.list.map(item => item.item.value), (newValues, oldValues) => {
  newValues.forEach((newValue, index) => {
    if (newValue !== undefined && newValue !== oldValues[index]) {
      // console.log(parseFloat(newValue))
      animateValueChange(index, Math.abs(parseFloat(newValue)));
    }
  });
}, {immediate: true, deep: true});
</script>

<style scoped lang="less">
.main {
  position: absolute;
  //color: red;
  display: flex;

  p {
    width: 78px;
    font: var(--font-size);
    color: var(--font-color);
  }
}
</style>
