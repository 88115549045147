<template>
  <div class="formDialog">
    <el-form label-position="top" ref="userForm" :rules="rules" :inline="true" :model="deployGroup"
             label-width="auto">
      <el-form-item v-if="temp.name" label="名称" prop="name">
        <el-input v-model="deployGroup.name" :disabled="store.state.sourceData['prohibit']"></el-input>
      </el-form-item>
      <el-form-item prop="server">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">服务器地址</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">MQTT 消息代理的服务器</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.server"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">客户端 ID</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                          <span
                              style="color: #ffffff;margin-right: 8px">MQTT 连接的客户端 ID。 如果未指定，将使用一个 uuid</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.clientID"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">用户名</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">MQTT 连接用户名</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.username"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">密码</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">MQTT 连接密码</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input type="password" v-model="deployGroup.password"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">MQTT 协议版本</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">MQTT 协议版本。3.1 (也被称为 MQTT 3) 或者 3.1.1 (也被称为 MQTT 4)</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup.protocolVersion"
            placeholder="请输入"
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in mqttVersion"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">客户端证书
            </label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">客户端证书文件</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-upload
            ref="uploadRef"
            class="upload-demo"
            :auto-upload="false"
            :show-file-list="false"
            @change="handleFile($event, 'certificationRaw')"
        >
          <template #trigger>
            <div class="upload-container">
              <div class="el-upload el-upload--text">
                <div class="btn-text">
                  <div :class="['upload-btn']">
                    <div v-if="!deployGroup.certificationRaw">
                      <img
                          src="http://192.168.3.253:8085/web/common/img/upload-file.28dfa07c.svg" alt=""
                          class="image">
                      <span class="text">点击上传</span>
                    </div>
                    <div v-else class="file-md5">
                      <label
                          for="">MD5: {{ encryptText(deployGroup.certificationRaw) }}</label>
                      <div class="text reupload-btn">重新上传</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">客户端私钥</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">客户端私钥文件</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-upload
            ref="uploadRef"
            class="upload-demo"
            :auto-upload="false"
            :show-file-list="false"
            @change="handleFile($event, 'privateKeyRaw')"
        >
          <template #trigger>
            <div class="upload-container">
              <div class="el-upload el-upload--text">
                <div class="btn-text">
                  <div :class="['upload-btn']">
                    <div v-if="!deployGroup.privateKeyRaw">
                      <img
                          src="http://192.168.3.253:8085/web/common/img/upload-file.28dfa07c.svg" alt=""
                          class="image">
                      <span class="text">点击上传</span>
                    </div>
                    <div v-else class="file-md5">
                      <label
                          for="">MD5: {{ encryptText(deployGroup.privateKeyRaw) }}</label>
                      <div class="text reupload-btn">重新上传</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">CA 证书</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">CA 证书文件</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-upload
            ref="uploadRef"
            class="upload-demo"
            :auto-upload="false"
            :show-file-list="false"
            @change="handleFile($event, 'rootCARaw')"
        >
          <template #trigger>
            <div class="upload-container">
              <div class="el-upload el-upload--text">
                <div class="btn-text">
                  <div :class="['upload-btn']">
                    <div v-if="!deployGroup.rootCARaw">
                      <img
                          src="http://192.168.3.253:8085/web/common/img/upload-file.28dfa07c.svg" alt=""
                          class="image">
                      <span class="text">点击上传</span>
                    </div>
                    <div v-else class="file-md5">
                      <label
                          for="">MD5: {{ encryptText(deployGroup.rootCARaw) }}</label>
                      <div class="text reupload-btn">重新上传</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">跳过证书验证</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">如果设置为 true，TLS 接受服务器提供的任何证书以及该证书中的任何主机名。在这种模式下，TLS 容易受到中间人攻击。默认值为 false，配置项只能用于 TLS 连接</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-radio v-model="deployGroup.insecureSkipVerify" :label="true" border>True</el-radio>
        <el-radio v-model="deployGroup.insecureSkipVerify" :label="false" border>False</el-radio>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">QoS 级别</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">默认订阅 QoS 级别</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup.qos"
            placeholder="请输入"
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in qosGrade"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">解压缩</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                              <span
                                  style="color: #ffffff;margin-right: 8px">使用指定的压缩方法解压缩 MQTT payload，留空表示不解压缩</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup.decompression"
            placeholder="请输入"
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in compressionFormat"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import {defineExpose, defineProps, onMounted, reactive, ref} from "vue";
import userSourcePageEditorData from "@/pages/dataProcessing/sourcePage/utils/data";
import {useStore} from "vuex";
import {md5} from "js-md5";

const store = useStore();
const userForm = ref(null);
const temp = defineProps({
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }, name: {
    type: Boolean,
    default: () => {
      return true
    }
  }
})
let {
  mqttVersion,
  qosGrade,
  compressionFormat
} = userSourcePageEditorData()
let deployGroup = ref({
  name: '',
  server: '',
  clientID: '',
  username: '',
  password: '',
  protocolVersion: '',
  clientCertificate: '',
  certificationRaw: '',//证书证书文件 客户端
  privateKeyRaw: '',//私钥文件 客户端客户端私钥
  rootCARaw: '',//ca证书 客户端
  clientKey: '',
  caCertificate: '',
  insecureSkipVerify: false,
  qos: '',
  decompression: ''
})
const rules = reactive({
  name: [{required: true, message: '请填写名称', trigger: 'blur'}],
  server: [{required: true, trigger: 'blur', message: ''}],
})
//上传文件
const handleFile = (rawFile, name) => {
  const reader = new FileReader();
  reader.readAsDataURL(rawFile.raw);
  reader.onload = (event) => {
    const base64String = event.target.result; // 这里就是获取到的Base64字符串
    // 移除前缀,并赋值
    deployGroup.value[name] = base64String.replace(/^data:image\/svg\+xml;base64,/, '')
  };
}
const getFormData = async () => {
  const isValid = await userForm.value.validate();
  if (isValid) {
    return deployGroup.value
  }
}
//md5加密url;
const encryptText = (url) => {
  // 使用md5进行加密并转换为32位小写
  return md5(url).toLowerCase();
}
defineExpose({getFormData});
onMounted(() => {
  deployGroup.value = {...deployGroup.value, ...temp.data}
})
</script>


<style scoped lang="less">
//提示文字最大宽度
.max-tooltip {
  max-width: 280px !important;
}

.label {
  position: relative;
  margin-left: 10px;

  &:before {
    position: absolute;
    content: '*';
    color: var(--el-color-danger);
    top: 0;
    left: -10px;
  }
}

.formDialog {
  @import "public";

  .upload-container {
    width: 500px !important;
    height: 32px;
    border: 1px dashed #189bfe;
    border-radius: 8px;
    background-color: rgba(24, 155, 254, .05);
    cursor: pointer;

    .btn-text {
      width: 500px;
      box-sizing: border-box;
      padding: 0 16px;
      color: #189bfe;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      .upload-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;

        .image {
          width: 18px;
          height: 22px;
          vertical-align: text-bottom;
          margin-right: 10px;
        }

        .file-md5 {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          display: flex;
          justify-content: space-between;

          label {
            color: #96999c;
            margin-right: 10px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .text {
          color: #189bfe;
        }

        .reupload-btn {
          width: 56px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>