import {api} from "@/api";

// 获取抄读详情
export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/getDetail',
        method: 'POST',
        data: data
    })
}
// 获取所有抄读项列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/list',
        method: 'POST',
        data: data
    })
}
// 获取单个抄读项
export const getParserObj = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/get',
        method: 'POST',
        data: data
    })
}
// 获取抄读项选项
export const getParserItemSelect = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/listSelect',
        method: 'POST',
        data: data
    })
}
// 获取配置、数据类型列表
export const getDataType = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/getDataType',
        method: 'POST',
        data: data
    })
}
// 获取字典分类选项
export const getDictCategorySelect = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dictCategory/listSelect',
        method: 'POST',
        data: data
    })
}
// 获取字典选项
export const getDictSelect = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dict/listSelect',
        method: 'POST',
        data: data
    })
}
//获取抄读项使用的字典id列表
export const getDictIdList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/listDictId',
        method: 'POST',
        data: data
    })
}
//获取抄读项使用的字典id列表
export const getParserItemDictList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/listDictId',
        method: 'POST',
        data: data
    })
}
//新增、编辑的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/save',
        method: 'POST',
        data: data
    })
}
//删除抄读项
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/delete',
        method: 'POST',
        data: data
    })
}




