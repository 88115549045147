import {api} from "@/api";


// 获取参数列表
export const getConfigListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/paramConfigV2/page',
        method: 'POST',
        data: data
    })
}


// 保存参数列表
export const saveConfigListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/paramConfigV2/save',
        method: 'POST',
        data: data
    })
}


// 删除参数列表
export const delConfigListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/net/paramConfigV2/delete/${id}`,
        method: 'POST',
    })
}


// 获取设备列表
export const getEquipmentListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/virtualDevice/list',
        method: 'POST',
        data: data
    })
}
// 删除设备列表
export const delEquipmentListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/net/virtualDevice/delete/${id}`,
        method: 'POST'
    })
}
// 保存设备列表
export const saveEquipmentListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/virtualDevice/save',
        method: 'POST',
        data: data
    })
}

// 获取参数类型列表
export const getParamTypeListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/paramConfigV2/listType',
        method: 'POST',
        data: data
    })
}
// 获取树形结构列表
export const getTreeDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControl/project/tree',
        method: 'POST',
        data: data
    })
}
// 获取子项目对应设备的数据项
export const getTitleListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/net/deviceControl/title/list',
        method: 'POST',
        data: data
    })
}

