import {api} from "@/api";

// 获取网关设备表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/pageEquipment',
        method: 'POST',
        data: data
    })
}
//获取所有select
export const getSelectList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getSelect',
        method: 'POST',
        data: data
    })
}
//获取所有分类列表
export const getAllCategory = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/category/listAll',
        method: 'POST',
        data: data
    })
}
//获取产品列表
export const getProductList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/v3/listSelect',
        method: 'POST',
        data: data
    })
}
//设备的绑定
export const bindList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/bind',
        method: 'POST',
        data: data
    })
}
//设备编辑的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/save',
        method: 'POST',
        data: data
    })
}

// 批量设备编辑保存
export const batchUpdate = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/batchUpdate',
        method: 'POST',
        data: data
    })
}
//获取设备详情
export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getDetail',
        method: 'POST',
        data: data
    })
}

//删除设备（解绑）
/*export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/unbind',
        method: 'POST',
        data: data
    })
}*/
//删除设备
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/delete',
        method: 'POST',
        data: data
    })
}
//下发档案
export const downList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/downArchives',
        method: 'POST',
        data: data
    })
}
//清空档案
export const cleanList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/cleanArchives',
        method: 'POST',
        data: data
    })
}
//读取档案
export const readList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/readArchives',
        method: 'POST',
        data: data
    })
}
//读取档案结果
export const readResultList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/getReadArchivesResult',
        method: 'POST',
        data: data
    })
}
//读取解析
export const readParseList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/readParse',
        method: 'POST',
        data: data
    })
}
// 获取未绑定项目设备
export const getAllDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/page',
        method: 'POST',
        data: data
    })
}
//获取实时抄读选项-单个设置使用
export const single = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/listParserSelect/single',
        method: 'POST',
        data: data
    })
}
//更新实时抄读
export const updateParser = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/updateParser',
        method: 'POST',
        data: data
    })
}
//获取产品抄读列表
export const listProductParser = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/listProductParser',
        method: 'POST',
        data: data
    })
}
//获取实时抄读选项-批量设置使用
export const batch = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/listParserSelect/batch',
        method: 'POST',
        data: data
    })
}
//批量更新实时抄读-批量设置使用
export const batchUpdateParser = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/batchUpdateParser',
        method: 'POST',
        data: data
    })
}


//设置档案
export const setGatewayApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/protocol3761/command/setArchives',
        method: 'POST',
        data: data
    })
}

// 立即抄表
export const meterReadingApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message3761/rest/protocol3761/command/setReadTask',
        method: 'POST',
        data: data
    })
}