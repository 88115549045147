<template>
  <div id="divId">
    <!--上方图片-->
    <div style="display: flex;justify-content: space-between;">
      <img class="img1" src="@/assets/img/cn.png" alt="cn">
      <img class="img2" src="@/assets/img/gf.png" alt="gf">
    </div>
    <!--下方图片-->
    <div style="display: flex;justify-content: space-between">
      <img class="img3" src="@/assets/img/fz.png" alt="fz">
      <img class="img4" src="@/assets/img/dw.png" alt="dw">
    </div>
    <!--中心图片-->
    <img class="img-center" src="@/assets/img/kernel.png" alt="center">
    <!--流动动画-->
    <div v-for="(item,index) in 4" :key="index" :class="`arrow${index+1}`" :style="getArrowStyle(tips[index],index)">
      <div v-for="(item,index) in 4" :key="index">
        <div :class="['reuse-forward1']"></div>
        <div :class="['reuse-forward2']"></div>
        <div :class="['reuse-forward3']"></div>
      </div>
    </div>
    <!--动画上方显示-->
    <p v-for="(item,index) in 4" :key="index" :class="[`text${index+1}`,'text']">
      <span>{{
          tips[index].value[0] ? Math.abs(animatedValues[index]).toFixed(2) : '-- '
        }}</span><span>Kwh</span>
    </p>
    <!--轮播功率-->
    <div v-for="(item,index) in tips" :key="index" :class="[item.css,'tips']">
      <div class="title">
        <p>{{ item.title }}</p>
        <!--        <p>{{ item.text === '储能数据' ? item.totalPower > 0 ? '充电' : '放电' : ''}}</p>-->
        <p class="color-green">{{
            item.title === '储能数据' ? item.totalPower > 0 ? '放电' : item.totalPower < 0 ? '充电' : '待机中' : ''
          }}</p>
      </div>
      <el-carousel height="58px" :interval="5000" motion-blur="false" indicator-position="none">
        <el-carousel-item v-for="(value,index) in item.value" :key="index">
          <p class="value-text">{{
              `${item.text}${item.text === '负载' || item.text === '电表' ? '' : index + 1}`
            }}<span>{{ Math.abs(value).toFixed(2) }}</span> Kwh</p>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script setup>
import {defineProps, ref, watch} from 'vue';
//导入父组件传递的数据
const props = defineProps({
  asyncData: {
    type: Object,
    required: true,
    default: () => {
    }
  },
  List: {
    type: Object,
    required: true,
    default: () => {
    }
  }
});
//图片旁边提示
let tips = ref([
  {
    css: 'tips1',
    title: '储能数据',
    value: [],
    text: '储能',
    totalPower: ''
  },
  {
    css: 'tips2',
    title: '光伏数据',
    value: [],
    // text:'光伏功率'
    text: '光伏',
    totalPower: 0
  },
  {
    css: 'tips3',
    title: '负载数据',
    value: [],
    text: '负载',
    totalPower: ''
  },
  {
    css: 'tips4',
    title: '电网数据',
    value: [],
    text: '电表',
    totalPower: ''
  }
])
const addValue = (arrayValue) => {
  let temp = {
    cn: [],
    fz: [],
    dw: []
  }
  arrayValue.forEach((item) => {
    if (item.name.includes('负载')) {
      if (item.value === void 0) {
        return
      }
      tips.value[2].totalPower = item.value
      temp.fz.push(item.value)
    }
    if (item.name.includes('电网')) {
      if (item.value === void 0) {
        return
      }
      tips.value[3].totalPower = item.value
      temp.dw.push(item.value)
    }
  })
  tips.value[2].value = temp.fz
  tips.value[3].value = temp.dw
  temp = {
    fz: [],
    dw: []
  }
}
addValue(props.asyncData.map(item => item.item))
watch(() => props.asyncData.map(item => item.item), (newValues) => {
  addValue(newValues)
}, {immediate: true, deep: true});
/*-------------------------------------------------*/
// 用于动画显示的响应式数据数组，初始化为4个0.0
const animatedValues = ref(new Array(4).fill(0.0));

// 动画函数，逐渐改变animatedValues中指定索引的值，直到它等于newValue
function animateValueChange(index, newValue) {
  const startValue = animatedValues.value[index];
  const change = newValue - startValue;
  const duration = 3000; // 动画持续时间，单位为毫秒
  let startTime;

  function step(timestamp) {
    if (!startTime) startTime = timestamp;
    const progress = Math.min((timestamp - startTime) / duration, 1);
    animatedValues.value[index] = startValue + change * progress;

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
}

//储能
watch(() => props.List.cn, (newValues) => {
  let temp = []
  newValues.forEach((newValue) => {
    if (newValue !== void 0) {
      if (newValue.key.includes('储能')) {
        if (newValue.value === void 0) {
          return
        }
        temp.push(newValue.value)
      }
    }
  });
  tips.value[0].value = temp
  temp = []
}, {immediate: true, deep: true});
// 储能总功率
watch(() => props.List.totalStoreRate, (newValues) => {
  tips.value[0].totalPower = newValues
  animateValueChange(0, parseFloat(newValues));
})
//光伏
watch(() => props.List.gf, (newValues) => {
  let temp = []
  let totalPower = 0
  newValues.forEach((newValue) => {
    if (newValue !== void 0) {
      if (newValue.key.includes('光伏')) {
        if (newValue.value === void 0) {
          return
        }
        totalPower += parseFloat(newValue.value)
        tips.value[1].totalPower = totalPower
        temp.push(newValue.value)
      }
    }
  });
  animateValueChange(1, parseFloat(totalPower));
  tips.value[1].value = temp
}, {immediate: true, deep: true});


// 监听props.data.list中每个元素的变化，当它变化时，启动动画
watch(() => tips.value.map(item => item.value[0]), (newValues) => {
  newValues.forEach((newValue, index) => {
    if (newValue !== void 0 && index !== 0 && index !== 1) {
      animateValueChange(index, parseFloat(newValue));
    }
  });
}, {immediate: true, deep: true});
const getArrowStyle = (tip, index) => {
  if (index === 0) {
    return tip.value[0] < 0 ? {transform: "rotate(212deg)"} : {}
  }
  if (index === 3) {
    return tip.value[0] < 0 ? {transform: "rotate(32deg)"} : {}
  }
}
</script>
<style scoped lang="less">
// 定义 Arrow Triangle Mixin
.arrow-triangle(@bg-color-start: rgba(0, 168, 138),@bg-color-center: rgba(94, 214, 255, 0), @bg-color-end: rgba(94, 214, 255, 0)) {
  content: "";
  position: absolute;
  top: -50%;
  width: 90px;
  height: 20px;
  border-radius: 0 43% 43% 0;
  background: linear-gradient(to left, @bg-color-start, @bg-color-end);
  transform: translate(-100px, 0);
  animation: move 6s infinite;
}

.arrow() {
  width: 200px;
  height: 10px;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}

:deep(.el-carousel__arrow) {
  font-size: 26px;
  top: 52%;
}

:deep(.el-carousel__arrow--right) {
  right: 0;
}

:deep(.el-carousel__arrow--left) {
  left: 0;
}

#divId {
  width: 100%;
  height: 100%;
  position: relative;
  //pointer-events: none; //禁止鼠标选中
  //user-select: none; //禁止拖拽
  //border: #4AB8FF 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img1 {
  width: 217px;
  height: 181px;
  margin: 20px 0 0 21px;
}

.img2 {
  width: 262px;
  height: 243px;
  margin-right: 60px;
}

.img3 {
  width: 229px;
  height: 224px;
}

.img4 {
  width: 233px;
  height: 228px;
}

.img-center {
  position: absolute;
  width: 200px;
  height: 146px;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//正向
.reuse-forward1 {
  &:after {
    .arrow-triangle();
  }

  &:before {
    .arrow-triangle();
    //transform: skewY(-30deg);
    animation-delay: 1s;
  }
}

.reuse-forward2 {
  &:after {
    .arrow-triangle();
    animation-delay: 2s;
  }

  &:before {
    .arrow-triangle();
    //transform: skewY(-30deg);
    animation-delay: 3s;
  }
}

.reuse-forward3 {
  &:after {
    .arrow-triangle();
    animation-delay: 4s;
  }

  &:before {
    .arrow-triangle();
    //transform: skewY(-30deg);
    animation-delay: 5s;
  }
}

//流动定位
.arrow1 {
  .arrow();
  top: 37%;
  left: 20%;
  transform: rotate(32deg);
}

.arrow2 {
  .arrow();
  top: 38%;
  right: 22%;
  transform: rotate(150deg);
}

.arrow3 {
  bottom: 31%;
  left: 20%;
  .arrow();
  transform: rotate(-30deg+180deg);
}

.arrow4 {
  .arrow();
  bottom: 29%;
  right: 22%;
  transform: rotate(212deg);
}

//轮播功率
.tips {
  width: 175px;
  height: 108px;
  position: absolute;
  background: url("@/assets/img/tipsBg.png") no-repeat;
  background-size: 100% 100%;
  padding: 10px 0 0 0;
  box-sizing: border-box;

  .title {
    margin-top: 8px;
    padding-left: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    p {
      color: #C9D9FF;
    }

    p:last-child {
      margin-right: 22px;
    }
  }

  .value-text {
    padding: 0 10px 0 24px;
    margin-top: 12px;
    font-size: 12px;
    color: #C9D9FF;
    box-sizing: border-box;
    /*display: flex;
    justify-content: center;*/

    span {
      margin: 0 2px;
      font-weight: bold;
      font-size: 18px;
      //color: #F1F5FF;
      text-shadow: 0 0 9px #009BFF;
      font-style: normal;
    }
  }
}

.tips1 {
  top: 2%;
  left: 30%;
}

.tips2 {
  top: 2%;
  right: -8%;
}

.tips3 {
  bottom: 8%;
  left: 28%;
}

.tips4 {
  bottom: 1%;
  right: 25%;
}

//流动动画上方功率
.text {
  width: 73px;
  height: 50px;
  font-weight: bold;
  white-space: nowrap;
  font-size: 18px;
  color: #fffd00;
  text-shadow: 0 0 9px #fffd00;
}

.text1 {
  position: absolute;
  top: 31%;
  left: 28%;
  transform: rotate(33deg);
}

.text2 {
  position: absolute;
  top: 33%;
  right: 31.5%;
  transform: rotate(-32deg);
}

.text3 {
  position: absolute;
  bottom: 28.5%;
  left: 27%;
  transform: rotate(-30deg);
}

.text4 {
  position: absolute;
  bottom: 28%;
  right: 30%;
  transform: rotate(32deg);
}

@keyframes move {
  0% {
    transform: translate(-100px, 0px);

  }
  100% {
    transform: translate(280px, 0px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.color-green {
  color: rgba(0, 168, 138) !important;
}

</style>
