import { api } from '@/api'

// 查询房东列表
export function listLandlord(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/page',
    method: 'get',
    params: query,
  })
}

// 查询房东详细
export function getLandlord(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/detail/' + id,
    method: 'get',
  })
}

// 新增房东
export function addLandlord(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/save',
    method: 'post',
    data: data,
  })
}

// 修改房东
export function updateLandlord(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/update',
    method: 'post',
    data: data,
  })
}

// 删除房东
export function delLandlord(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/remove/' + id,
    method: 'post',
  })
}

// 查询房东项目列表
export function listLandlordProject(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/project',
    method: 'get',
    params: query,
  })
}

// 查询房东设备列表
export function listLandlordEquipment(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/equipment',
    method: 'get',
    params: query,
  })
}

// 绑定设备
export function bindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/bind',
    method: 'post',
    data: data,
  })
}

// 解绑设备
export function unbindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/unbind',
    method: 'post',
    data: data,
  })
}

// 查询房东列表
export function getLandlordList(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/list',
    method: 'get',
    params: query,
  })
}

// 解绑租户
export function unbindTenant(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/landlord/unbindTenant',
    method: 'post',
    data: data,
  })
}
