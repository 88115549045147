<template>
  <div class="eleQuery">
    <div class="title">
      <p>
        <span>电量查询</span>
      </p>
    </div>
    <div class="content">
      <div class="contain">
        <!--头部-->
        <div class="headBox">
          <p class="title">电量查询</p>
          <div class="tabBox">
            <p :class="activeIdx===1?'active':''" @click="activeClick(1)">日报表</p>
            <p :class="activeIdx===2?'active':''" @click="activeClick(2)">月报表</p>
            <p :class="activeIdx===3?'active':''" @click="activeClick(3)">年报表</p>
          </div>
        </div>
        <!--搜索-->
        <div class="searchBox">
          <div class="liPox">
            <div class="liBox" style="display: flex">
              <el-date-picker v-if="activeIdx===1" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                              range-separator="→"
                              start-placeholder="开始时间"
                              type="daterange"
                              value-format="YYYY-MM-DD">
              </el-date-picker>
              <el-date-picker v-if="activeIdx===2" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                              range-separator="→"
                              start-placeholder="开始时间"
                              type="monthrange"
                              value-format="YYYY-MM-DD">
              </el-date-picker>
              <el-date-picker v-if="activeIdx===3"
                              v-model="dateTime[0]"
                              :disabled-date="disabledDate"
                              placeholder="开始时间"
                              type="year" value-format="YYYY-MM-DD"
              />
              <div v-if="activeIdx===3" class="segmentation" style="margin: 0 8px;transform: translateY(5px)">至</div>
              <el-date-picker v-if="activeIdx===3"
                              v-model="dateTime[1]"
                              :disabled-date="disabledDate2"
                              placeholder="结束时间"
                              type="year"
                              value-format="YYYY-MM-DD"
              />
            </div>
            <div class="liBox">
              <el-tree-select
                  ref="treeRef1"
                  v-model="ammeter"
                  :data="ammeterList"
                  :props="props"
                  :render-after-expand="false"
                  class="treeSelect"
                  filterable
                  node-key="id"
                  placeholder="请选择电表"
                  style="height: auto!important"
              />
            </div>
          </div>
          <div class="liBox">
            <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
            <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
            <el-button class="searchButton resetButton" @click="exportFun">导出</el-button>
          </div>
        </div>
        <!--内容-->
        <div class="containBox">
          <div class="head">
            <div class="tab">
              <p :class="showFlag?'active':''" @click="showClick(1)">图表</p>
              <p :class="!showFlag?'active':''" @click="showClick(2)">表格</p>
            </div>
          </div>
          <el-empty v-if="echartsDataX.length===0&&showFlag"
                    class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
          <!--图表-->
          <div v-show="echartsDataX.length>0&&showFlag" class="chart"></div>
          <div v-show="echartsDataX.length>0&&showFlag" class="chartCircle"></div>

          <!--表格-->
          <el-table v-show="!showFlag" v-loading="loading" :data="tableData" style="width: 100%;margin-top: 20px">
            <template #empty>
              <el-empty class="emptyClass" description="暂无数据信息"
                        image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              </el-empty>
            </template>
            <el-table-column label="日期" prop="time"></el-table-column>
            <el-table-column label="总" prop="total"></el-table-column>
            <el-table-column label="尖" prop="sharp"></el-table-column>
            <el-table-column label="峰" prop="peak"></el-table-column>
            <el-table-column label="平" prop="flat"></el-table-column>
            <el-table-column label="谷" prop="valley"></el-table-column>
          </el-table>
          <div v-show="!showFlag" class="pag">
            <el-pagination v-model:page-size="pageSize" :current-page="pageNumber" :page-sizes="[10, 20, 50, 100]"
                           :total="total" background
                           layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                           @current-change="pageChange"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref, watch} from "vue"
import './eleQuery.less'


import * as echarts from "echarts";
import {ElLoading, ElMessage} from "element-plus";
import moment from "moment";
import {getAllTreeApi} from "@/api/modules/bigScreen";
import qs from "qs";
import {getEleChartApi, getEleTableApi} from "@/api/modules/eleChargeQuery";
import {http} from "@/api";


// 控制展示图表还是表格
let showFlag = ref(true)
const showClick = async function (flag) {
  if (flag === 1) {
    showFlag.value = true
    await getEchartsDataFun()
  } else {
    pageNumber.value = 1
    pageSize.value = 20
    await getTableDataFun()
    showFlag.value = false
  }
}

// 查询字段*********************************************************************************
let dateTime = ref([moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]) //开始结束时间
let ammeter = ref('') //电表

watch(() => dateTime.value, (val) => {
  if (activeIdx == 3) {
    disabledDate(new Date(val[0]))
    disabledDate2(new Date(val[1]))
  }
})
const disabledDate = (time) => {
  if (dateTime.value[1]) {
    return time.getTime() >= moment(dateTime.value[1]).valueOf()
  }
  return false
}
const disabledDate2 = (time) => {
  if (dateTime.value[0]) {
    return time.getTime() < moment(dateTime.value[0]).valueOf()
  }
  return false
}

// 电表列表
let ammeterList = ref()

// 日月年切换
let activeIdx = ref(1)
const activeClick = function (idx) {
  activeIdx.value = idx
  if (activeIdx.value === 1) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  }
}


// 获取图表数据****************************************************************************************
let echartsDataY = ref([])
let echartsDataX = ref([])
let pieData = ref([])
const getEchartsDataFun = async function () {
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && ammeter.value) {
    echartsDataX.value = []
    echartsDataY.value = []
    pieData.value = []
    openFullScreen()
    await getEleChartApi(qs.stringify({
      timeType: activeIdx.value,
      startTime: dateTime.value[0] ? dateTime.value[0] : '',
      endTime: dateTime.value[1] ? dateTime.value[1] : '',
      equipmentId: ammeter.value
    })).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        for (let i = 0; i < res.data.result.totalData.length; i++) {
          pieData.value.push({
            name: res.data.result.totalData[i].key,
            value: res.data.result.totalData[i].value,
          })
        }
        echartsDataX.value = res.data.result.chartData.timeList
        for (let i = 0; i < res.data.result.chartData.list.length; i++) {
          echartsDataY.value.push({
            name: res.data.result.chartData.list[i].key,
            type: 'bar',
            stack: 'Ad',
            data: res.data.result.chartData.list[i].value
          })
        }
        nextTick(() => {
          chartLoad()
          chartCircleLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择查询条件'
    })
  }
}

// 查询
const searchFun = async function () {
  if (showFlag.value) {
    await getEchartsDataFun()
  } else {
    await getTableDataFun()
  }
}
// 重置
const resetFun = function () {
  ammeter.value = ''
  dateTime.value = []
  if (activeIdx.value === 1) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  }

  echartsDataY.value = []
  echartsDataX.value = []
  pieData.value = []

  pageNumber.value = 1
  pageSize.value = 20
  total.value = 0
  tableData.value = []
  nextTick(() => {
    chartLoad()
    chartCircleLoad()
  })
}

// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    series: echartsDataY.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载饼图
const chartCircleLoad = function () {
  var chartDom = document.querySelector('.chartCircle');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: '电量分析',
        type: 'pie',
        radius: ['20%', '70%'],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            formatter: '{b}: {d}%'
          }
        },
        data: pieData.value
      }
    ]
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}


// 获取表格数据****************************************************************************************
let tableData = ref()
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref(0)
let loading = ref(false)
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getTableDataFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getTableDataFun()
}
const getTableDataFun = function () {
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && ammeter.value) {
    loading.value = true
    getEleTableApi(qs.stringify({
      pageNumber: pageNumber.value,
      pageSize: pageSize.value,
      timeType: activeIdx.value,
      startTime: dateTime.value[0] ? dateTime.value[0] : '',
      endTime: dateTime.value[1] ? dateTime.value[1] : '',
      equipmentId: ammeter.value
    })).then(res => {
      loading.value = false
      if (res.data.code === 0) {
        tableData.value = res.data.result.list
        total.value = Number(res.data.result.total)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择查询条件'
    })
  }
}


// 点击导出************************************************************************************
const exportFun = function () {
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && ammeter.value) {
    openFullScreen()
    window.location.href = http + 'zouk/rest/powerFeeRecord/power/exportTableData?timeType=' + activeIdx.value + '&startTime=' + (dateTime.value[0] ? dateTime.value[0] : '') + '&endTime=' + (dateTime.value[0] ? dateTime.value[1] : '') + '&equipmentId=' + ammeter.value
    setTimeout(() => {
      loadingAdd.value.close();
      ElMessage({
        type: 'success',
        message: '导出成功'
      });
    }, 1000);
    loadingAdd.value.close()
  } else {
    ElMessage({
      type: 'info',
      message: '请选择导出条件'
    });
  }
}


// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
onMounted(() => {
  // 获取所有设备树
  getAllTreeApi(qs.stringify({
    type: 1
  })).then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      ammeterList.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})

</script>

<style lang="less" scoped>
</style>
