<template>
  <div class="point">
    <div class="title">
      <p>
        <span @click="goBack">设备管理</span>
        <span> / </span>
        <span @click="goBack2">组列表</span>
        <span> / </span>
        <span>点位列表</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack2">
          <Back/>
        </el-icon>
        点位列表
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px;">名称</label>
          <el-input v-model="name" :prefix-icon="Search" clearable placeholder="请输入关键字搜索"></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="getListFun()">查询</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--内容-->
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">
            添加点位
          </el-button>
          <el-button type="primary" @click="batchDelClick()">
            批量删除
          </el-button>
        </div>
        <!--列表-->
        <el-table v-loading="loadingVal" :data="tableData" :row-key="getRowKeys" style="width: 100%"
                  @selection-change="handleSelectionChange">
          <el-table-column :reserve-selection="true" label="选取" style="height:20px" type="selection" width="55">
          </el-table-column>
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面添加点位</p>
            </el-empty>
          </template>
          <el-table-column label="名称" property="name"></el-table-column>
          <el-table-column label="地址" property="address"></el-table-column>
          <el-table-column label="类型" property="typeStr"></el-table-column>
          <el-table-column label="属性" property="attributeStr"></el-table-column>
          <el-table-column label="乘系数" property="decimalStr"></el-table-column>
          <el-table-column label="精度" property="precisionStr"></el-table-column>
          <el-table-column label="描述" property="description"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="editClick(scope.row)">
                  编辑
                </li>
                <li class="deleteClass" @click="delClick(scope.row)">
                  删除
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--添加点位弹窗-->
    <el-dialog v-model="addDialogFormVisible" :close-on-click-modal="false" class="addDialogFormVisible"
               style="min-width: 1200px" title="添加点位"
               width="90%">
      <el-table :data="tags" style="width: 100%">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            <p>您可以在此页面添加点位</p>
          </el-empty>
        </template>
        <el-table-column label="名称" min-width="1%" property="name">
          <template #header>
            <span class="mustClass">*</span>名称
          </template>
          <template #default="scope">
            <!--<el-input v-model="scope.row.name" placeholder="请输入名称"></el-input>-->
            <el-select v-model="scope.row.name" class="item" filterable placeholder="请选择字典"
                       @change="dataSelectChange($event,scope.$index)">
              <el-option v-for="(item_d,index_d) in dataList" :key="index_d" :label="item_d.value"
                         :value="item_d.key"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="类型" min-width="1%" property="type">
          <template #header>
            <span class="mustClass">*</span>类型
          </template>
          <template #default="scope">
            <el-select v-model="scope.row.type" clearable filterable placeholder="请选择类型">
              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" style="width:100%">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="地址" min-width="1%" property="address">
          <template #header>
            <span class="mustClass">*</span>地址
          </template>
          <template #default="scope">
            <el-input v-model="scope.row.address" placeholder="请输入地址"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="属性" min-width="1%" property="attribute">
          <template #header>
            <span class="mustClass">*</span>属性
          </template>
          <template #default="scope">
            <el-select v-model="scope.row.attribute" :max-collapse-tags="1" clearable collapse-tags filterable
                       multiple placeholder="请选择属性">
              <el-option v-for="item in attributeList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="乘系数" min-width="1%" property="decimal">
          <template #default="scope">
            <el-input-number v-model="scope.row.decimal" :step="0.1" style="width: 115px;"/>
          </template>
        </el-table-column>
        <el-table-column label="精度" min-width="1%" property="precision">
          <template #default="scope">
            <el-input-number v-model="scope.row.precision" style="width: 115px;"/>
          </template>
        </el-table-column>
        <el-table-column label="描述" min-width="2%" property="description">
          <template #default="scope">
            <el-input v-model="scope.row.description" :rows="2" placeholder="请输入描述" type="textarea"/>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="1%">
          <template #default="scope">
            <ul class="operationUl">
              <li class="deleteClass" @click="delClick2(scope.$index)">
                删除
              </li>
            </ul>
          </template>
        </el-table-column>
      </el-table>
      <div class="addButtonBox">
        <el-button :icon="Plus" circle class="addButtonClass" type="primary" @click="addCreate()"></el-button>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="addDialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="addSave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--编辑点位弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="编辑点位" width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <div style="display: flex;">
          <div style="flex: 1;">
            <el-form-item label="名称" prop="name">
              <el-input v-model="formData.name" disabled placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="类型" prop="type">
              <el-select v-model="formData.type" clearable filterable placeholder="请选择类型">
                <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"
                           style="width:100%">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="精度" prop="precision">
              <el-input-number v-model="formData.precision" style="width: 115px;"/>
            </el-form-item>
            <el-form-item label="乘系数" prop="decimal">
              <el-input-number v-model="formData.decimal" :step="0.1" style="width: 115px;" @change=""/>
            </el-form-item>
          </div>
          <div style="flex: 1;">
            <el-form-item label="属性" prop="attribute">
              <el-select v-model="formData.attribute" :max-collapse-tags="3" clearable collapse-tags filterable
                         multiple placeholder="请选择属性">
                <el-option v-for="item in attributeList" :key="item.id" :label="item.name" :value="item.id"
                           style="width:100%">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址" prop="address">
              <el-input v-model="formData.address" placeholder="请输入地址"></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="描述" prop="description">
          <el-input v-model="formData.description" :rows="2" placeholder="请输入描述" type="textarea"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="editSave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h, onUnmounted} from 'vue';
import './point.less'
import {
  Plus,
  Search,
  CollectionTag,
  EditPen,
  DataAnalysis,
  Setting,
  Memo,
  Download,
  Back,
  Delete
} from '@element-plus/icons-vue'
import {
  getTags,
  tagsDelete, tagsAddSave,
  tagsEditSave,
  getDictSelectApi,
  addTagsApi,
  newTagsDelete
} from "@/api/modules/southDriver";
import {ElMessage, ElMessageBox} from "element-plus";
import {useRouter} from "vue-router/dist/vue-router";
import qs from "qs";

let router = useRouter()
/*生命周期*/
onMounted(async () => {
  await getDataListFun()
  getListFun()
})
let loadingVal = ref(false) //加载
let tableData = ref([]) // 点位列表数据

// 获取点位列表
let loading = ref(false)
//获取点位列表
let name = ref()
// 重置
const resetFun = function () {
  name.value = ''
  getListFun()
}
const getListFun = function () {
  loadingVal.value = true
  let params = {
    node: router.currentRoute.value.query.node,
    group: router.currentRoute.value.query.group,
    name: name.value
  }
  getTags(params).then(res => {
    loadingVal.value = false
    if (res.status == 200) {
      tableData.value = res.data.tags
      tableData.value.forEach(item => {
        if (item.precision == 0) {
          item.precisionStr = '-'
        } else {
          item.precisionStr = item.precision
        }
        if (item.decimal == 0) {
          item.decimalStr = '-'
        } else {
          item.decimalStr = item.decimal
        }
        if (item.attribute == 1) {
          item.attributeStr = 'Read'
        } else if (item.attribute == 2) {
          item.attributeStr = 'Write'
        } else if (item.attribute == 3) {
          item.attributeStr = 'Read,Write'
        } else if (item.attribute == 4) {
          item.attributeStr = 'Subscribe'
        } else if (item.attribute == 5) {
          item.attributeStr = 'Read,Subscribe'
        } else if (item.attribute == 6) {
          item.attributeStr = 'Write,Subscribe'
        } else if (item.attribute == 7) {
          item.attributeStr = 'Read,Write,Subscribe'
        }
        if (item.type == 3) {
          item.typeStr = 'INT16'
        } else if (item.type == 4) {
          item.typeStr = 'UINT16'
        } else if (item.type == 5) {
          item.typeStr = 'INT32'
        } else if (item.type == 6) {
          item.typeStr = 'UINT32'
        } else if (item.type == 7) {
          item.typeStr = 'INT64'
        } else if (item.type == 8) {
          item.typeStr = 'UINT64'
        } else if (item.type == 9) {
          item.typeStr = 'FLOAT'
        } else if (item.type == 10) {
          item.typeStr = 'DOUBLE'
        } else if (item.type == 11) {
          item.typeStr = 'BIT'
        } else if (item.type == 13) {
          item.typeStr = 'STRING'
        } else if (item.type == 14) {
          item.typeStr = 'BYTES'
        }
      })
    } else {
      tableData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点位表单数据
let formData = ref({
  name: '', //名称
  attribute: [], //属性
  type: '', //类型
  address: '', //地址
  precision: '', //精度
  decimal: '', //乘系数
  description: '' //描述
})
let formRules = ref({
  name: [{
    required: true,
    message: '请输入点位名称',
    trigger: 'change',
  }],
  type: [{
    required: true,
    message: '请选择类型',
    trigger: 'change'
  }],
  attribute: [{
    required: true,
    message: '请选择属性',
    trigger: 'change'
  }],
  address: [{
    required: true,
    message: '请输入地址',
    trigger: 'change'
  }]
})
// 新增编辑表单弹窗
let dialogFormVisible = ref(false)
let addDialogFormVisible = ref(false)
let typeList = ref([
  {
    name: 'INT16',
    id: 3
  }, {
    name: 'UINT16',
    id: 4
  }, {
    name: 'INT32',
    id: 5
  }, {
    name: 'UINT32',
    id: 6
  }, {
    name: 'INT64',
    id: 7
  }, {
    name: 'UINT64',
    id: 8
  }, {
    name: 'FLOAT',
    id: 9
  }, {
    name: 'DOUBLE',
    id: 10
  }, {
    name: 'BIT',
    id: 11
  }, {
    name: 'STRING',
    id: 13
  }, {
    name: 'BYTES',
    id: 14
  }])
let attributeList = ref([{
  name: 'Read',
  id: 1
},
  {
    name: 'Write',
    id: 2
  },
  {
    name: 'Subscribe',
    id: 4
  },
])
let title = ref('') //弹窗标题
// 名称选择事件
const dataSelectChange = function (e, index) {
  tags.value[index].description = dataList.value.find(item => item.key === e).value
}
// 新增点位点击
const addClick = function () {
  tags.value = []
  addDialogFormVisible.value = true
  title.value = "添加点位"
}
// 编辑点击
const editClick = function (row) {
  formData.value = {}
  dialogFormVisible.value = true
  title.value = "编辑点位"
  formData.value.name = row.name
  formData.value.type = row.type
  formData.value.address = row.address
  formData.value.precision = row.precision
  formData.value.decimal = row.decimal
  formData.value.description = row.description
  formData.value.attribute = [row.attribute]
  if (row.attribute == 3) {
    formData.value.attribute = [1, 2]
  } else if (row.attribute == 5) {
    formData.value.attribute = [1, 4]
  } else if (row.attribute == 6) {
    formData.value.attribute = [2, 4]
  } else if (row.attribute == 7) {
    formData.value.attribute = [1, 2, 4]
  }
}
//添加点位弹窗
const tags = ref([]) //添加点位弹窗列表
//添加弹窗列表点位
const addCreate = function () {
  tags.value.push({})
}
//删除弹窗列表点位
const delClick2 = function (index) {
  tags.value.forEach((item, i) => {
    if (index == i) {
      tags.value.splice(index, 1)
    }
  })
}
//添加的保存
const addSave = function () {
  var tagsArr = []
  tags.value.forEach(item => {
    item.attribute2 = item.attribute
  })
  tagsArr = JSON.parse(JSON.stringify(tags.value))
  tagsArr.forEach(item => {
    item.attribute = null
    item.attribute2.forEach(items => {
      item.attribute += items
    })
    delete item['attribute2']
  })

  var params = {
    node: router.currentRoute.value.query.node,
    group: router.currentRoute.value.query.group,
    tags: JSON.stringify(tagsArr),
    authorization: 'Bearer ' + window.localStorage.getItem('token')
  }
  addTagsApi(qs.stringify(params)).then(res => {
    if (res.data.code == 0) {
      tagsAddSave({
        node: router.currentRoute.value.query.node,
        group: router.currentRoute.value.query.group,
        tags: tagsArr,
      }).then(res => {
        if (res.status === 200) {
          getListFun()
          addDialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 提交编辑点位表单
let userForm = ref()
//编辑的保存
const editSave = function () {
  var tags = []
  var attribute1 = 0;
  formData.value.attribute.forEach(item => {
    attribute1 += item
  })
  tags.push(JSON.parse(JSON.stringify(formData.value)))
  tags[0].attribute = attribute1
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      var params = {
        node: router.currentRoute.value.query.node,
        group: router.currentRoute.value.query.group,
        tags: tags
      }
      tagsEditSave(params).then(res => {
        if (res.status == 200) {
          getListFun()
          dialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}
// 删除点位
const delClick = function (row) {
  var arr = []
  arr.push(row.name)
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除点位',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此点位，删除后数据不可恢复'),
    ])
  }).then(() => {
    var params = {
      group: router.currentRoute.value.query.group,
      node: router.currentRoute.value.query.node,
      tags: JSON.stringify(arr)
    }
    newTagsDelete(qs.stringify(params)).then(res => {
      if (res.data.code == 0) {
        tagsDelete({
          group: router.currentRoute.value.query.group,
          node: router.currentRoute.value.query.node,
          tags: arr
        }).then(res => {
          if (res.status === 200) {
            ElMessage({
              type: 'success',
              message: '删除成功',
            })
            getListFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}
//复选框触发
let multipleSelection = ref([])
const handleSelectionChange = function (val) {
  multipleSelection.value = val;
}
//保留分页记忆
const getRowKeys = function (row) {
  return row.name
}

//批量删除
const batchDelClick = function () {
  let idList = []
  multipleSelection.value.forEach(item => {
    idList.push(item.name)
  })
  if (multipleSelection.value.length > 0) {
    ElMessageBox.confirm('', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      title: '删除点位',
      message: h('p', null, [
        h('img', {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
        }, ''),
        h('span', {
          style: 'color:#595959;'
        }, '请确认是否要删除此点位，删除后数据不可恢复'),
      ])
    }).then(() => {
      var params = {
        group: router.currentRoute.value.query.group,
        node: router.currentRoute.value.query.node,
        tags: JSON.stringify(idList)
      }
      newTagsDelete(qs.stringify(params)).then(res => {
        if (res.data.code == 0) {
          tagsDelete({
            group: router.currentRoute.value.query.group,
            node: router.currentRoute.value.query.node,
            tags: idList
          }).then(res => {
            if (res.status === 200) {
              ElMessage({
                type: 'success',
                message: '删除成功',
              })
              getListFun()
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message,
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message,
          })
        }
      }).catch(err => {
        console.log(err);
      })
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消删除',
      })
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条删除的数据!'
    })
  }
}
//返回
const goBack = function () {
  router.push('/admin/deviceLocal')
}
const goBack2 = function () {
  router.push({
    path: '/admin/groupLocal',
    query: {
      node: router.currentRoute.value.query.node
    }
  })
}
// 字典列表
let dataList = ref([])
// 获取字典列表
const getDataListFun = async function () {
  await getDictSelectApi().then(res => {
    if (res.data.code === 0) {
      dataList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
</script>

<style lang="less" scoped>

</style>