<template>
  <div class="relatedItems">
    <div class="title">
      <p>
        <span @click="goBack">控制管理</span>
        <span> / </span>
        <span>关联项</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        关联项
      </p>
    </div>
    <div class="content">
      <div class="topBox2">
        <div class="leftTopBox2">
          <div class="leftTopContent">
            <div class="modelClass">
              设备型号：{{ baseObj.productModel }}
            </div>
            <div class="namelClass">
              <span class="codeClass">{{ baseObj.projectName }}</span>
            </div>
          </div>
        </div>
        <div class="leftTopBox2">
          <div class="leftTopContent2">
            <div class="modelClass">
              设备状态
            </div>
            <div class="namelClass">
              <span>{{ baseObj.statusStr }}</span>
            </div>
          </div>
        </div>
        <div class="relatedItemsBox">
          <div class="leftTopContent2">
            <div class="modelClass">
              控制
            </div>
            <el-button class="conorlButton" size="small" type="primary" @click="contrlClick()">{{
                router.currentRoute.value.query.cname
              }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="contBox">
        <div class="contenteBox3">
          <div class="searchBox">
            <div class="lastTimeLeft">控制的关联指标</div>
            <el-button :icon="Plus" class="currentValueRight" type="primary" @click='addclick()'>新增</el-button>
          </div>
          <div class="echartsTabBox">
            <div v-for="(item,index) in monitoringList" class="parentClass">
              <div class="echartsBox111" style="position:relative;">
                <div class="leftDetail">
                  <div class="bigClass">
                    <el-tooltip :content="item.deviceName" class="item" effect="dark" placement="top-start">
                      <span>{{ item.deviceName }}</span>
                    </el-tooltip>
                  </div>
                  <el-icon class="el-icon-error" @click="deleteClick(item.deviceControlRelatedId)">
                    <CircleCloseFilled/>
                  </el-icon>
                  <div class="signClass2">
                    <!--空格处理-->
                    <!--item.dataValue-->
                    <el-tooltip :content="item.titleName" class="item" effect="dark" placement="bottom-start">
                      <span class="numberValueClass">{{ item.titleName }}</span>
                    </el-tooltip>
                    <br/>
                    <span style="position: absolute;bottom: 5px;right: 10px">{{ item.unitName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--新增指标弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title='新增指标' width='40%'>
      <div class="borderClass">
        <!--        <div style="padding: 10px ">-->
        <!--          <el-input v-model="searchName" class="w-50 m-2" clearable placeholder='请输入设备名名称' @clear="clearClick"-->
        <!--                    @input="searchChange(searchName,fromData2)">-->
        <!--            <template #prefix>-->
        <!--              <el-icon class="el-input__icon">-->
        <!--                <search/>-->
        <!--              </el-icon>-->
        <!--            </template>-->
        <!--          </el-input>-->
        <!--        </div>-->
        <div style="padding: 10px;">
          <el-tree
              ref="treeRef"
              :load="treeList"
              :props="props"
              lazy
              show-checkbox
          />
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="importSave">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {CircleCloseFilled, Search, Plus, Back} from '@element-plus/icons-vue'
import './relatedItems.less'
import qs from 'qs';
import {
  controlClickApi, delItemApi,
  deviceImportApi,
  getDeviceControlListApi,
  getDeviceInfoApi, getTreeListApi
} from "@/api/modules/control";

let router = useRouter()

// 返回
const goBack = function () {
  router.push('/admin/control')
}

/*生命周期*/
onMounted(() => {
  getListFun()
  getBaseFun()
})
// 获取设备基础信息-----------------------------------------------------------------------
let baseObj = ref({})
// 获取设备基础信息
const getBaseFun = function () {
  getDeviceInfoApi(qs.stringify({
    equipmentId: router.currentRoute.value.query.dId
  })).then(res => {
    if (res.data.code === 0) {
      baseObj.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err)
  })
}
// 控制点击
const contrlClick = function () {
  var params = {
    equipmentId: router.currentRoute.value.query.dId,
    controlId: router.currentRoute.value.query.coId
  }
  if (router.currentRoute.value.query.dId) {
    ElMessageBox.confirm("确认要执行该操作吗，请谨慎操作", '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      if (router.currentRoute.value.query.dId) {
        openFullScreen();
      }
      controlClickApi(qs.stringify(params)).then(res => {
        loadingAdd.value.close();
        if (res.data.code === 0) {
          ElMessage({
            type: 'success',
            message: '操作成功'
          })
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        loadingAdd.value.close();
        console.log(err)
      })
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消操作'
      })
    });
  } else {
    ElMessage({
      type: 'info',
      message: '请先确定设备是否关联!'
    })
  }
}


// 关联项-----------------------------------------------------------------------
let dialogFormVisible = ref(false)
// 新增指标点击
const addclick = function () {
  dialogFormVisible.value = true
}
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}
// 关联项列表
let monitoringList = ref([])
//获取设备控制关联项列表
const getListFun = function () {
  openFullScreen();
  getDeviceControlListApi(qs.stringify({
    deviceControlId: router.currentRoute.value.query.dcoId,
    controlId: router.currentRoute.value.query.coId
  })).then(res => {
    loadingAdd.value.close();
    if (res.data.code === 0) {
      monitoringList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    loadingAdd.value.close();
    console.log(err)
  })
}
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0)'
  })
}

// 设备导入
let importSave = function () {
  let arr = treeRef.value.getCheckedNodes(true)
  let keyArr = []
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].type === 3) {
      // keyArr.push({arr[i].id})
      keyArr.push({
        // controlId: router.currentRoute.value.query.coId,
        // deviceControlId: router.currentRoute.value.query.dcoId,
        // titleDeviceId: arr[i].titleDeviceId,
        // titleDeviceName: arr[i].titleDeviceName,
        // titleId: arr[i].titleId,
        // titleName: arr[i].titleName,
        // titleParserItemId: arr[i].titleParserItemId,
        // titleParserItemName: arr[i].titleParserItemName,
        // titleSubprojectId: arr[i].titleSubprojectId,
        // titleSubprojectName: arr[i].titleSubprojectName,
        // unitName: arr[i].unitName


        controlId: router.currentRoute.value.query.coId,
        deviceControlId: router.currentRoute.value.query.dcoId,
        titleDeviceId: arr[i].equipmentId,
        titleDeviceName: arr[i].equipmentName,
        titleId: arr[i].id,
        titleName: arr[i].name,
        // titleParserItemId: arr[i].titleParserItemId,
        // titleParserItemName: arr[i].titleParserItemName,
        // titleSubprojectId: arr[i].titleSubprojectId,
        // titleSubprojectName: arr[i].titleSubprojectName,
        unitName: arr[i].unitName
      })
    }
  }
  if (keyArr.length > 0) {
    deviceImportApi(keyArr).then(res => {
      if (res.data.code === 0) {
        dialogFormVisible.value = false
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err)
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请先选择指标！'
    })
  }
}
// 删除指标
const deleteClick = function (id) {
  ElMessageBox.confirm("", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除指标',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除指标，删除后数据不可恢复'),
    ])
  }).then(() => {
    delItemApi(id).then(res => {
      if (res.data.code === 0) {
        getListFun();
        ElMessage({
          type: 'success',
          message: '删除成功'
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    })
  });
}
</script>

<style lang="less" scoped>

</style>