<template>
	<!--产品分类管理页面-->
	<div class="productCategory">
		<div class="title">产品分类</div>
		<div class="content">
			<div class="tableBox">
				<div class="operationBox">
					<el-button :icon="Plus" type="primary" @click="addClick()">新建分类</el-button>
				</div>
				<el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
					<template #empty>
						<el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
							<p>您可以在此页面新建分类信息</p>
						</el-empty>
					</template>
					<el-table-column label="名称" min-width="2%" property="name">
					</el-table-column>
					<el-table-column label="类型" min-width="2%" property="showTypeStr">
					</el-table-column>
					<el-table-column label="操作" min-width="1%">
						<template #default="scope">
							<ul class="operationUl">
								<li class="editClass" @click="addClick(scope.row.id,scope.row)">编辑</li>
								<li class="deleteClass" @click="deleteClick(scope.row.id)">删除
								</li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
				<!--<div class="footerBox">
          <el-pagination small :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small" :disabled="disabled" background layout="total, prev, pager, next, sizes" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>-->
			</div>
		</div>
		<!--新增、编辑分类弹窗-->
		<el-dialog v-model="categoryDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
			<div>
				<el-form ref="categoryFormRef" :model="categoryForm" :rules="categoryRules" class="demo-ruleForm" style="width:98%" label-width="auto">
					<el-form-item label="分类名称" prop="name">
						<el-input v-model="categoryForm.name" placeholder="请输入名称" />
					</el-form-item>
					<el-form-item label="类型" prop="showType">
						<el-select v-model="categoryForm.showType" placeholder="请选择类型" style="width:100%">
							<el-option v-for="item in showTypeList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button @click="categoryDialogVisible = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="categorySave">保存</el-button>
      			</span>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import { reactive, onMounted, computed, watch, ref, defineComponent, h } from 'vue';
	import { useRouter } from 'vue-router';
	import { ElTable, ElMessage, ElMessageBox } from 'element-plus'
	import { ArrowUpBold, ArrowDownBold, Plus } from '@element-plus/icons-vue'
	import { getList, saveList, deleteList } from '../../api/modules/productCategory'
	import './productCategory.less'
	import qs from 'qs';

	let router = useRouter()
	/*生命周期*/
	onMounted(() => {
		getListFun()
	})

	let pageNumber = ref(1) //页码
	let pageSize = ref(20) //页数
	let total = ref(0) //总页数
	let tableData = ref([]) //列表数据
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageNumber.value = val;
		getListFun();
	}
	let loadingVal = ref(false) //表格加载
	//获取分类列表
	const getListFun = function() {
		loadingVal.value = true
		getList(null).then(res => {
			//console.log(res);
			loadingVal.value = false
			if(res.data.ok) {
				tableData.value = res.data.result
				//total.value = Number(res.data.result.total)
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let title = ref() //弹窗标题
	let dialogWidth = ref('40%') //新增分类弹窗宽度
	let categoryDialogVisible = ref(false) //新增分类弹窗开关
	let editId = ref() //编辑id
	let showTypeList = ref([{
			id: 1,
			name: '电表'
		}, {
			id: 2,
			name: '水表'
		}, {
			id: 3,
			name: '变压器'
		}, {
			id: 4,
			name: '燃气'
		}, {
			id: 5,
			name: '储能'
		},
		{
			id: 6,
			name: '光伏'
		}, {
			id: 7,
			name: '氧气'
		}, {
			id: 8,
			name: '二氧化碳'
		}, {
			id: 9,
			name: '压缩空气'
		},
		{
			id: 10,
			name: '氩气'
		}
	]) //类型列表
	//新增、编辑分类的点击
	const addClick = function(id, row) {
		categoryForm.value.name = ''
		categoryForm.value.showType = ''
		editId.value = id
		if(id) {
			title.value = '编辑分类'
			categoryForm.value.name = row.name
			categoryForm.value.showType = row.showType
		} else {
			title.value = '新增分类'
		}
		categoryDialogVisible.value = true
	}
	let categoryFormRef = ref() //分类
	//分类表单
	let categoryForm = ref({
		name: '',
		showType: ''
	})
	// 分类表单验证规则
	let categoryRules = ref({
		name: [{
			required: true,
			message: '请输入名称',
			trigger: 'blur'
		}],
		showType: [{
			required: true,
			message: '请选择类型',
			trigger: 'blur'
		}]
	})
	//分类的保存
	const categorySave = function() {
		let params = {
			name: categoryForm.value.name,
			showType: categoryForm.value.showType
		}
		if(categoryFormRef) {
			categoryFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					if(title.value == '新增分类') {
						params['id'] = 0
					} else if(title.value == '编辑分类') {
						params['id'] = editId.value
					}
					saveList(
						qs.stringify(params)
					).then(async res => {
						//console.log(res);
						if(res.data.ok) {
							categoryDialogVisible.value = false
							getListFun()
						} else {
							ElMessage({
								message: res.data.message,
								type: 'error'
							})
						}
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}
	}
	//删除
	const deleteClick = function(id) {
		ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			title: '删除分类',
			message: h('p', null, [
				h('img', {
					src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
					style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
				}, ''),
				h('span', {
					style: 'color:#595959;'
				}, '请确认是否要删除此分类，删除后数据不可恢复'),
			])
		}).then(() => {
			deleteList(
				qs.stringify({
					id: id
				})
			).then(res => {
				//console.log(res);
				if(res.data.ok) {
					getListFun()
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消删除',
				type: 'info',
			})
		});
	}
</script>