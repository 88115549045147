import { api } from "@/api";

// 获取上网电价列表
export const getList = (data) => {
	return api({
		headers: {
			memberId: window.localStorage.getItem('memberId'),
			Authorization: window.localStorage.getItem('Authorization'),
		},
		url: '/zouk/rest/upPrice/page',
		method: 'POST',
		data: data
	})
}
//新增/编辑的保存
export const saveList = (data) => {
	return api({
		headers: {
			memberId: window.localStorage.getItem('memberId'),
			Authorization: window.localStorage.getItem('Authorization'),
		},
		url: '/zouk/rest/upPrice/save',
		method: 'POST',
		data: data
	})
}

//删除上网电价
export const deleteList = (data) => {
	return api({
		headers: {
			memberId: window.localStorage.getItem('memberId'),
			Authorization: window.localStorage.getItem('Authorization'),
		},
		url: '/zouk/rest/upPrice/delete',
		method: 'POST',
		data: data
	})
}