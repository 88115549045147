import { api } from '@/api'

// 查询电费价格配置列表
export function listPrice(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/page',
    method: 'get',
    params: query,
  })
}

// 查询电费价格配置详细
export function getPrice(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/detail/' + id,
    method: 'get',
  })
}

// 新增电费价格配置
export function addPrice(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/save',
    method: 'post',
    data: data,
  })
}

// 修改电费价格配置
export function updatePrice(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/update',
    method: 'post',
    data: data,
  })
}

// 删除电费价格配置
export function delPrice(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/remove/' + id,
    method: 'post',
  })
}

// 查询电费价格配置项目列表
export function listPriceProject(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/project',
    method: 'get',
    params: query,
  })
}

// 查询电费价格配置设备列表
export function listPriceEquipment(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/equipment',
    method: 'get',
    params: query,
  })
}

// 绑定设备
export function bindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/bind',
    method: 'post',
    data: data,
  })
}

// 解绑设备
export function unbindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/unbind',
    method: 'post',
    data: data,
  })
}

// 查询电费价格配置列表
export function getPriceList(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/price/list',
    method: 'get',
    params: query,
  })
}
