<template>
  <div class="rulesPage">
    <div class="title">
      <p>
        <span>规则</span>
      </p>
    </div>
    <div class="content">
      <div class="contain">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addRuleClick">新建规则</el-button>
          <el-button type="primary" @click="exportClick()">导出规则</el-button>
          <el-button type="primary" @click="importClick()">导入规则</el-button>
          <el-button @click="delWarnClick()">清除警告</el-button>
        </div>
        <!--列表-->
        <el-table v-loading="loading" :data="tableData" class="tableBox" row-key="id" style="width: 100%"
                  table-layout="auto" @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面添加规则</p>
            </el-empty>
          </template>
          <el-table-column type="selection" width="55"/>
          <el-table-column label="ID" property="id">
            <template #default="scope">
              <span style="cursor: pointer; color:rgb(22, 93, 255);"
                    @click="editRuleClick(scope.row.id)">{{
                  scope.row.id
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="名称" property="name"></el-table-column>
          <el-table-column label="状态" property="status">
            <template #default="scope">
              <span v-if="scope.row.status==='running'" class="runBtn btn">运行中</span>
              <span v-else-if="scope.row.status==='stopped: canceled manually.'" class="stop1 btn">已停止</span>
              <span v-else class="stop2 btn">异常停止</span>
            </template>
          </el-table-column>
          <el-table-column label="告警次数" property="warnTotal">
            <template #default="scope">{{ scope.row.warnTotal ? scope.row.warnTotal : 0 }}</template>
          </el-table-column>
          <el-table-column label="最近一次告警" property="warnInfo"/>
          <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <ul class="operationUl">
                <li style="color: #165DFF" @click="editRuleClick(scope.row.id)">编辑</li>
                <li>
                  <el-tooltip
                      :content="scope.row.status==='running'?'停止':'启动'"
                      class="box-item"
                      effect="dark"
                  >
                    <el-icon v-if="scope.row.status==='running'" class="more" @click="isOpenRuleFun(false,scope.row)">
                      <VideoPause/>
                    </el-icon>
                    <el-icon v-if="scope.row.status!=='running'" class="more" @click="isOpenRuleFun(true,scope.row)">
                      <VideoPlay/>
                    </el-icon>
                  </el-tooltip>
                </li>
                <li>
                  <el-popover popper-class="moreOpera" trigger="click">
                    <template #reference>
                      <p class="more"> ... </p>
                    </template>
                    <div class="listPox">
                      <p @click="restClick(scope.row)">
                        <el-icon>
                          <Refresh/>
                        </el-icon>
                        <span>重启</span>
                      </p>
                      <p @click="router.push(`/admin/dataStatistics?id=${scope.row.id}`)">
                        <el-icon>
                          <DataLine/>
                        </el-icon>
                        <span>数据统计</span>
                      </p>
                      <p>
                        <el-icon>
                          <SetUp/>
                        </el-icon>
                        <span>拓扑图</span>
                      </p>
                      <p @click="copyFun(scope.row)">
                        <el-icon>
                          <CopyDocument/>
                        </el-icon>
                        <span>复制</span>
                      </p>
                      <p @click="delClick(scope.row)">
                        <el-icon>
                          <Delete/>
                        </el-icon>
                        <span>删除</span>
                      </p>
                    </div>
                  </el-popover>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="添加应用"
               width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="Rule ID" prop="id" style="margin-bottom: 20px">
          <el-input v-model="formData.id" placeholder="请输入规则ID"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name" style="margin-bottom: 20px">
          <el-input v-model="formData.name" placeholder="请输入规则名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible2" :close-on-click-modal="false" title="导入规则"
               width="40%">
      <div class="dialogBox">
        <div class="head">
          <div class="leftBox">
            <i style="color: red;">*</i>
            <p style="margin-left: 5px">文件内容</p>
          </div>
          <el-upload
              v-model:file-list="fileList" :auto-upload="false" :show-file-list="false" accept=".json"
              action="#" class="upload-demo" limit="1" @change="uploadFile($event)">
            <p class="importBtn">上传文件</p>
          </el-upload>
        </div>
        <div class="monacoEditorContainer"></div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible2=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save2()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, onUnmounted, reactive, ref} from "vue"
import './rulesPage.less'
import {Plus, Refresh, VideoPause, VideoPlay, DataLine, CopyDocument, Delete, SetUp} from '@element-plus/icons-vue'
import {
  getListApi,
  getListDataApi,
  isOpenRuleApi,
  restRuleApi,
  getRuleDetailApi,
  saveRuleApi,
  delRuleApi,
  exportRuleApi, importRuleApi,
} from "@/api/modules/rulesPage";
import {ElMessage, ElMessageBox} from "element-plus";
import * as monaco from 'monaco-editor'
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()
const addRuleClick = function () {
  router.push({
    name: 'AddRules',
    params: {
      actionObj: JSON.stringify({})
    }
  })
}
const editRuleClick = function (id) {
  router.push({
    name: 'AddRules',
    query: {
      name: id
    },
    params: {
      actionObj: JSON.stringify({})
    }
  })
}


// 表格数据
let tableData = ref([])

// 表格多选框选择事件
let checkIdArr = ref([])
const handleSelectionChange = function (e) {
  checkIdArr.value = []
  for (let i = 0; i < e.length; i++) {
    checkIdArr.value.push(e[i].id)
  }
}

// 获取列表*****************************************************************************
let loading = ref(false)
let timer
const getListFun = function () {
  loading.value = true
  getListApi().then(res => {
    loading.value = false
    if (res.status === 200) {
      tableData.value = res.data
      for (let i = 0; i < tableData.value.length; i++) {
        tableData.value[i].status = tableData.value[i].status.toLowerCase()
        if (tableData.value[i].status === 'running') {
          getListDataFun(tableData.value[i].id)
        }
      }
      timer = setInterval(() => {
        for (let i = 0; i < tableData.value.length; i++) {
          tableData.value[i].status = tableData.value[i].status.toLowerCase()
          if (tableData.value[i].status === 'running') {
            getListDataFun(tableData.value[i].id)
          }
        }
      }, 30000)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
const getListDataFun = function (id) {
  getListDataApi(id).then(res => {
    if (res.status === 200) {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value[i].status = res.data.status
          tableData.value[i].warnInfo = res.data.sink_sql_2_0_last_exception
          tableData.value[i].warnTotal = res.data.sink_sql_2_0_exceptions_total
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}


// 添加编辑应用*****************************************************************************
// 点击添加应用
// 应用表单数据
let formData = ref({
  id: '',
  name: ''
})
let formRules = ref({
  id: [{
    required: true,
    message: '请输入规则id',
    trigger: 'change',
  }]
})
// 新增编辑表单弹窗
let dialogFormVisible = ref(false)
// 复制点击
const copyFun = async function (scope) {
  await getRuleDetailApi(scope.id).then(res => {
    loading.value = false
    if (res.status === 200) {
      formData.value = res.data
      formData.value.id = formData.value.id + '_copy'
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
  dialogFormVisible.value = true
}
// 提交应用表单
let userForm = ref()
const save = function () {
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      saveRuleApi(formData.value).then(res => {
        if (res.status === 200 || res.status === 201) {
          getListFun()
          dialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}


// 删除规则*****************************************************************************
const delClick = function (scope) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除规则',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此规则，删除后数据不可恢复'),
    ])
  }).then(() => {
    delRuleApi(scope.id).then(res => {
      if (res.status === 200) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
      ElMessage({
        type: 'error',
        message: err.response.data.message,
      })
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}


// 开启或关闭规则
const isOpenRuleFun = function (flag, scope) {
  isOpenRuleApi(scope.id, flag ? 'start' : 'stop').then(res => {
    loading.value = false
    if (res.status === 200) {
      getListFun()
      ElMessage({
        message: !flag ? '停止成功' : '启动成功',
        type: 'success'
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}

// 点击重启
const restClick = function (scope) {
  restRuleApi(scope.id).then(res => {
    loading.value = false
    if (res.status === 200) {
      getListFun()
      ElMessage({
        message: '重启成功',
        type: 'success'
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}

// 点击导出
const exportClick = function () {
  if (checkIdArr.value.length > 0) {
    exportRuleApi({
      category: 1,
      rules: checkIdArr.value.toString()
    }).then(res => {
      if (res.status === 200) {
        function downLoad() {
          const stringData = JSON.stringify(res.data)
          const blob = new Blob([stringData], {
            type: 'application/json'
          })
          const objectURL = URL.createObjectURL(blob)
          const aTag = document.createElement('a')
          aTag.href = objectURL
          aTag.download = "rules.json";
          aTag.click()
          URL.revokeObjectURL(objectURL)
        }

        downLoad()
        ElMessage({
          type: 'success',
          message: '导出成功',
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
      ElMessage({
        type: 'error',
        message: err.response.data.message,
      })
    })
  } else {
    ElMessage({
      type: 'warning',
      message: '至少选择一条规则进行导出',
    })
  }
}

// 导入规则*****************************************************************************
// 导入弹窗
let dialogFormVisible2 = ref(false)
// 编辑器配置
let monacoEditor = null
// 点击导入
const importClick = function () {
  dialogFormVisible2.value = true
  setTimeout(() => {
    const container = document.querySelector('.monacoEditorContainer')
    monacoEditor = monaco.editor.create(container, {
      value: '', // 编辑器的值
      language: 'mql', //语言
      theme: 'vs', // 编辑器主题：vs, hc-black, or vs-dark
      autoIndent: true, // 自动缩进
      readOnly: false, // 是否只读
      minimap: {   //是否显示缩略图
        enabled: false
      },
      scrollbar: { // 滚动条设置
        verticalScrollbarSize: 2, // 竖滚动条
        horizontalScrollbarSize: 2, // 横滚动条
      }
    })
  }, 500)
}
// 确认导入
const save2 = function () {
  importRuleApi({
    category: 1
  }, {
    ruleData: monacoEditor.getValue()
  }).then(res => {
    if (res.status === 200) {
      dialogFormVisible2.value = false
      getListFun()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
// 文件上传
let fileList = ref([])
const uploadFile = function (file) {
  const reader = new FileReader();
  reader.readAsText(file.raw);
  reader.onload = (e) => {
    try {
      const jsonContent = JSON.parse(e.target.result);
      // 处理json内容
      monacoEditor.setValue(JSON.stringify(jsonContent, null, 2))
    } catch (error) {
      ElMessage({
        type: 'warning',
        message: '文件解析错误，请上传有效的 JSON 文件！',
      })
    }
  };
}


// 清除告警
const delWarnClick = function () {
  for (let i = 0; i < tableData.value.length; i++) {
    tableData.value[i].warnInfo = ''
    tableData.value[i].warnTotal = 0
  }
}


onMounted(() => {
  getListFun()
})
onUnmounted(() => {
  clearInterval(timer)
})

</script>

<style lang="less" scoped>
</style>
