<template>
	<!--大屏配置页面-->
	<div class="bigScreenConfigure">
		<div class="title">大屏配置</div>
		<div class="content">
			<el-form>
				<div class="searchBox">
					<div class="liBox">
						<el-form-item label="大屏名称">
							<el-input v-model="name" autocomplete="off" clearable placeholder='请输入大屏名称' type="text"></el-input>
						</el-form-item>
					</div>
					<div class="liBox">
						<el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
						<el-button class="searchButton resetButton" @click="resetClick()">重置</el-button>
					</div>
				</div>
			</el-form>
			<div class="tableBox">
				<div class="operationBox">
					<el-button :icon="Plus" type="primary" @click="addClick()">
						新建大屏
					</el-button>
				</div>
				<el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
					<template #empty>
						<el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
							<p>您可以在此页面新建大屏信息</p>
						</el-empty>
					</template>
					<el-table-column label="名称" min-width="2%" property="name">
					</el-table-column>
					<el-table-column label="图片" min-width="2%" property="imageUrl">
						<template #default="scope">

							<img :src="scope.row.imageUrl" alt="" style="width:30px;height: 30px;" />
						</template>
					</el-table-column>
					<el-table-column label="请求地址" min-width="2%" property="requestUrl">
					</el-table-column>
					<el-table-column label="操作" min-width="1%">
						<template #default="scope">
							<ul class="operationUl">
								<li class="editClass" @click="addClick(scope.row)">编辑</li>
								<li class="deleteClass" @click="deleteClick(scope.row.id)">删除
								</li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
				<div class="footerBox">
					<el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small" :total="total" background layout="total, sizes, prev, pager, next" small @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</div>
		</div>
		<!--新增/编辑大屏弹窗-->
		<el-dialog v-model="bigScreenDialogVisible" :close-on-click-modal="false" :width="dialogWidth" :title="title">
			<el-form ref="bigScreenFormRef" :model="bigScreenForm" :rules="bigScreenRules" class="demo-ruleForm" style="width:98%" label-width="auto">
				<el-form-item label="名称" prop="name">
					<el-input v-model="bigScreenForm.name" placeholder="请输入名称" />
				</el-form-item>
				<el-form-item label="图片" prop="imageUrl">
					<el-upload :auto-upload="false" :class="{hideUpload:hideUploadEdit}" :file-list='bigScreenForm.imageUrl' :limit="1" accept=".png,.jpg" action="#" list-type="picture-card" @change="uploadFile">
						<el-icon>
							<Plus/>
						</el-icon>
						<p>上传图片</p>
						<template #file="{ file }">
							<div>
								<img :src="file.url" alt="" class="el-upload-list__item-thumbnail" />
								<span class="el-upload-list__item-actions">
										  <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                      >
										    <el-icon><ZoomIn/></el-icon>
										  </span>
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            							  <el-icon><Delete/></el-icon>
          								</span>
								</span>
							</div>
						</template>
					</el-upload>
					<span style="margin-left: 20px;">（ 支持jpg、png格式 ）</span>
				</el-form-item>
				<el-form-item label="请求地址" prop="requestUrl">
					<el-input v-model="bigScreenForm.requestUrl" placeholder="请输入请求地址" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button @click="bigScreenDialogVisible = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="bigScreenSave">保存</el-button>
      			</span>
			</template>
		</el-dialog>

		<el-dialog v-model="dialogVisible" title="查看大图">
			<img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
		</el-dialog>
	</div>
</template>

<script setup>
	import { reactive, onMounted, computed, watch, ref, defineComponent, h } from 'vue';
	import { useRouter } from 'vue-router';
	import { ElTable, ElMessage, ElMessageBox } from 'element-plus'
	import {
		Delete,
		ZoomIn,
		Plus
	} from '@element-plus/icons-vue'
	import {
		getList,
		saveList,
		deleteList,
		uploadImg
	} from '../../../api/modules/bigScreenConfigure'
	import './bigScreenConfigure.less'
	import qs from 'qs';

	let router = useRouter()
	/*生命周期*/
	onMounted(() => {
		getListFun()
	})

	let pageNumber = ref(1) //页码
	let pageSize = ref(20) //页数
	let total = ref(0) //总页数
	let tableData = ref([]) //列表数据
	//重置
	const resetClick = function() {
		name.value = ''
	}
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageNumber.value = val;
		getListFun();
	}
	let loadingVal = ref(false) //表格加载
	let name = ref() //大屏名称
	//获取大屏列表
	const getListFun = function() {
		loadingVal.value = true
		let params = {
			pageNumber: pageNumber.value,
			pageSize: pageSize.value,
			name: name.value
		}
		getList(qs.stringify(params)).then(res => {
			//console.log(res);
			loadingVal.value = false
			if(res.data.ok) {
				tableData.value = res.data.result.list
				total.value = Number(res.data.result.total)
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let dialogWidth = ref('40%') //新增大屏弹窗宽度
	let bigScreenDialogVisible = ref(false) //新增大屏弹窗开关
	let title = ref('') //弹窗标题
	let editId = ref()
	//新增/编辑大屏的点击
	let addClick = function(val) {
		bigScreenForm.value = {}
		if(val) {
			bigScreenForm.value.imageUrl = []
			title.value = '编辑大屏'
			bigScreenForm.value.name = val.name
			bigScreenForm.value.requestUrl = val.requestUrl
			if(val.imageUrl) {
				hideUploadEdit.value = true
				bigScreenForm.value.imageUrl.push({
					name: 'aaa',
					url: val.imageUrl
				})
			} else {
				hideUploadEdit.value = false
			}
			editId.value = val.id
		} else {
			hideUploadEdit.value = false
			title.value = '新建大屏'
			bigScreenForm.value.name = ''
			bigScreenForm.value.imageUrl = []
			bigScreenForm.value.requestUrl = ''
		}
		bigScreenDialogVisible.value = true
	}
	let bigScreenFormRef = ref() //大屏
	//大屏表单
	let bigScreenForm = ref({
		name: '',
		imageUrl: [],
		requestUrl: ''
	})
	// 大屏表单验证规则
	let bigScreenRules = ref({
		name: [{
			required: true,
			message: '请输入名称',
			trigger: 'blur'
		}],
		imageUrl: [{
			required: true,
			message: '请上传图片',
			trigger: 'blur'
		}],
		requestUrl: [{
			required: true,
			message: '请输入请求地址',
			trigger: 'blur'
		}]
	})
	/*
	 * 上传图片
	 * */
	let dialogVisible = ref(false) //大图弹窗显示开关
	let dialogImageUrl = ref() //大图列表
	let hideUploadEdit = ref(false) //图片上传显示开关
	//上传文件
	const uploadFile = function(file) {
		if((file.size / 1024 / 1024) > 5) {
			ElMessage({
				message: '图片大小不能超过5M!',
				type: 'info'
			})
			bigScreenForm.value.imageUrl = []
		} else {
			let fd = new FormData();
			//注意是文件流file.raw
			fd.append('file', file.raw); //传文件
			uploadImg(
				fd
			).then(res => {
				//console.log(res);
				if(res.data.ok) {
					hideUploadEdit.value = true
					bigScreenForm.value.imageUrl = []
					bigScreenForm.value.imageUrl.push({
						name: 'aaa',
						url: res.data.result.fullPath
					})
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}
	}
	//删除图片
	const handleRemove = (file) => {
		bigScreenForm.value.imageUrl = []
		hideUploadEdit.value = false
	}
	//查看大图
	const handlePictureCardPreview = (file) => {
		dialogImageUrl.value = file.url
		dialogVisible.value = true
	}
	//大屏的保存
	const bigScreenSave = function() {
		let imageUrl;
		if(bigScreenForm.value.imageUrl && bigScreenForm.value.imageUrl.length > 0) {
			imageUrl = bigScreenForm.value.imageUrl[0].url
		} else {
			imageUrl = ''
		}
		var params = {
			name: bigScreenForm.value.name,
			imageUrl: imageUrl,
			requestUrl: bigScreenForm.value.requestUrl,
		}
		if(bigScreenFormRef) {
			bigScreenFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					if(title.value == '新建大屏') {
						params['id'] = 0
					} else if(title.value == '编辑大屏') {
						params['id'] = editId.value
					}
					saveList(
						qs.stringify(params)
					).then(async res => {
						//console.log(res);
						if(res.data.ok) {
							bigScreenDialogVisible.value = false
							getListFun()
						} else {
							ElMessage({
								message: res.data.message,
								type: 'error'
							})
						}
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}
	}
	//删除
	const deleteClick = function(id) {
		ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			title: '删除大屏',
			message: h('p', null, [
				h('img', {
					src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
					style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
				}, ''),
				h('span', {
					style: 'color:#595959;'
				}, '请确认是否要删除此大屏，删除后数据不可恢复'),
			])
		}).then(() => {
			deleteList(
				qs.stringify({
					id: id
				})
			).then(res => {
				//console.log(res);
				if(res.data.ok) {
					getListFun()
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消删除',
				type: 'info',
			})
		});
	}
</script>