<template>
  <div class="warnDetail">
    <div class="title">
      <p>
        <span>告警管理</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        <span>散热器温度过高（所属：ofstar离网逆变器 状态：告警中）</span>
        <br>
        <span>设备sn: 30393039设备ID: 6cf80a290cdcebc73bpjjt设备品牌: 设备型号:</span>
      </p>
    </div>
    <div class="content">
      <div class=" cont">
        <p>告警发生时间-告警结束时间</p>
        <p>2023-10-20 14:42:27-2023-10-30 14:42:27</p>
      </div>
      <div class="cont">
        <p>告警原因</p>
        <p>机身温度过高，或者传感器故障</p>
      </div>
      <div class="cont">
        <p>解决措施</p>
        <p>若机身不发热，更换主板</p>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {reactive, ref} from "vue"
import {Back} from '@element-plus/icons-vue'
import {useRouter} from 'vue-router';

let router = useRouter()

const goBack = function () {
  router.push('/admin/warn')
}
</script>

<style lang="less" scoped>
.warnDetail {
  width: 100%;
  height: 100%;
  //标题
  .title {
    width: calc(100% - 16px);
    height: 100px;
    padding-left: 16px;
    background-color: #EDF0F6;
    font-size: 14px;
    color: rgba(0, 0, 0, .85);

    p:nth-child(1) {
      line-height: 48px;
    }

    p:nth-child(2) {
      height: 24px;
      line-height: 24px;
      font-size: 16px;

      .el-icon {
        font-size: 18px;
        font-weight: bold;
        transform: translateY(3px);
        cursor: pointer;
      }

      .el-icon:hover {
        color: #307FE2;
      }

      span:nth-of-type(2) {
        margin-left: 18px;
      }
    }
  }

  //内容
  .content {
    width: calc(100% - 32px);
    height: calc(100% - 64px);
    padding: 0 16px 16px;
    overflow: auto;

    .cont {
      background-color: rgb(255, 255, 255);
      display: flex;
      justify-content: space-between;
      padding: 16px;
      margin-bottom: 12px;

      p {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>
