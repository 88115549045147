<template>
	<div class="rest">
		<div>
			<el-form ref="actionData" :inline="true" :model="formData" :rules="formRules">
				<el-collapse v-model="activeNames2" @change="handleChange">
					<el-collapse-item title="Connection" name="1">
						<div>
							<div style="display: flex;">
								<div style="flex:1">
									<el-form-item label="" prop="url" style="margin-bottom: 20px">
										<span slot="label">
												<span class="mustClass">*</span> 地址
										<el-tooltip content="RESTful API 终端地址，例如 https://www.example.com/api/dummy" effect="dark" placement="top" popper-class="el_tooltip_item">
											<el-icon>
												<QuestionFilled/>
											</el-icon>
										</el-tooltip>
										</span>
										<el-input v-model="formData.url" placeholder="请输入地址"></el-input>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												消息类型
												<el-tooltip content="消息体的类型。对于 get，不需要正文，因此选择 none。对于其他 HTTP 方法，默认值为 json。对于 html、xml、javascript，必须仔细设置 dataTemplate 以确保格式正确" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-select v-model="formData.bodyType" placeholder="请选择">
											<el-option label="none" value="none" />
											<el-option label="json" value="json" />
											<el-option label="text" value="text" />
											<el-option label="html" value="html" />
											<el-option label="xml" value="xml" />
											<el-option label="javascript" value="javascript" />
											<el-option label="form" value="form" />
										</el-select>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												响应类型
												<el-tooltip content="响应类型，可以是 code 或者 body，如果是 code，会检查 HTTP 响应码来判断响应状态。如果是 body，会检查 HTTP 响应正文，要求其为 JSON 格式，并且检查 code 字段的值。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-select v-model="formData.responseType" placeholder="请选择">
											<el-option label="code" value="code" />
											<el-option label="body" value="body" />
										</el-select>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												客户端证书
												<el-tooltip content="客户端证书文件" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<!--certificationRaw-->
										<el-upload v-model:file-list="certificationList" :auto-upload="false" action="#" class="upload-demo" limit="1" @change="uploadFile($event,'certificationRaw')">
											<div class="uploadBox">
												<div :class="certificationList.length>0?'none':''" class="upBtnBox">
													<el-icon>
														<Document/>
													</el-icon>
													<span>点击上传</span>
												</div>
											</div>
										</el-upload>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												CA 证书
												<el-tooltip content="CA 证书文件" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<!--rootCARaw-->
										<el-upload v-model:file-list="rootCAList" :auto-upload="false" action="#" class="upload-demo" limit="1" @change="uploadFile($event,'rootCARaw')">
											<div class="uploadBox">
												<div :class="rootCAList.length>0?'none':''" class="upBtnBox">
													<el-icon>
														<Document/>
													</el-icon>
													<span>点击上传</span>
												</div>
											</div>
										</el-upload>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												HTTP 方法
												<el-tooltip content="RESTful API 的 HTTP 方法" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-select v-model="formData.method" placeholder="请选择">
											<el-option label="GET" value="GET" />
											<el-option label="POST" value="POST" />
											<el-option label="PUT" value="PUT" />
											<el-option label="DELETE" value="DELETE" />
										</el-select>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												连接超时（ms）
												<el-tooltip content='HTTP 请求超时的时间（毫秒），默认为 5000 毫秒' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-input-number v-model="formData.timeout" />
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												打印 HTTP 响应
												<el-tooltip content="控制是否将响应信息打印到控制台中。如果将其设置为 true，则打印响应；如果设置为 false，则跳过打印日志" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.debugResp" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												客户端私钥
												<el-tooltip content="客户端私钥文件" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<!--privateKeyRaw-->
										<el-upload v-model:file-list="privateKeyList" :auto-upload="false" action="#" class="upload-demo" limit="1" @change="uploadFile($event,'privateKeyRaw')">
											<div class="uploadBox">
												<div :class="privateKeyList.length>0?'none':''" class="upBtnBox">
													<el-icon>
														<Document/>
													</el-icon>
													<span>点击上传</span>
												</div>
											</div>
										</el-upload>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												跳过证书验证
												<el-tooltip content="如果设置为 true，TLS 接受服务器提供的任何证书以及该证书中的任何主机名。在这种模式下，TLS 容易受到中间人攻击。默认值为 false，配置项只能用于 TLS 连接" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.insecureSkipVerify" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
							</div>
						</div>
					</el-collapse-item>
					<el-collapse-item title="Headers And OAuth" name="2">
						<div>
							<div class="labelBox" style="margin-bottom: 6px;">
								<span style="margin-right: 6px;">HTTP 头</span>
								<el-tooltip content="要为 HTTP 请求设置的其他标头" effect="dark" placement="top" popper-class="el_tooltip_item">
									<el-icon>
										<QuestionFilled/>
									</el-icon>
								</el-tooltip>
							</div>
							<el-table :data="httpData" style="width: 100%;margin-bottom: 20px;">
								<template #empty>
									<el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
									</el-empty>
								</template>
								<el-table-column label="键" property="key" min-width="10%">
									<template #default="scope">
										<el-input v-model="scope.row.name" placeholder="请输入键"></el-input>
									</template>
								</el-table-column>
								<el-table-column label="值" property="val" min-width="10%">
									<template #default="scope">
										<el-input v-model="scope.row.val" placeholder="请输入值"></el-input>
									</template>
								</el-table-column>
								<el-table-column fixed="right" label="操作" min-width="1%">
									<template #default="scope">
										<ul class="operationUl">
											<li class="deleteClass" @click="delClick(scope.$index)">
												删除
											</li>
										</ul>
									</template>
								</el-table-column>
							</el-table>
							<div class="addButtonBox">
								<el-button :icon="Plus" circle class="addButtonClass" type="primary" @click="addCreate()"></el-button>
							</div>
							<div class="labelBox" style="margin-bottom: 6px;">
								<span style="margin-right: 6px;">OAuth</span>
								<el-tooltip content="配置 OAuth 验证流程" effect="dark" placement="top" popper-class="el_tooltip_item">
									<el-icon>
										<QuestionFilled/>
									</el-icon>
								</el-tooltip>
							</div>
							<el-table :data="oAuthData" style="width: 100%">
								<el-table-column type="expand">
									<template #default="props">
										<div class="oAuth-child">
											<el-table :span-method="arraySpanMethod" :show-header="false" :data="props.row.family">
												<el-table-column prop="name">
													<template #default="scope">
														<label v-if="scope.row.type!==null" for="">{{ scope.row.name }}</label>
														<div v-if="scope.row.type===null&&scope.row.name===null" style="width: 100%;" class="httpHeader">
															<el-table :data="header" style="min-height: 100px;">
																<el-table-column prop="date" label="键" min-width="169">
																	<template #default="scope">
																		<el-input v-model="scope.row.key"></el-input>
																	</template>
																</el-table-column>
																<el-table-column prop="name" label="值" min-width="169">
																	<template #default="scope">
																		<el-input v-model="scope.row.val"></el-input>
																	</template>
																</el-table-column>
																<el-table-column label="操作" min-width="100">
																	<template #default="scope">
																		<div class="deleteClass" @click="deleteTable(header,scope.row,scope.row.$index)">删除
																			<!--<el-button :icon="Delete" circle @click="deleteTable(header,scope.row)" />-->
																		</div>
																	</template>
																</el-table-column>
															</el-table>
															<div class="add-button" style="display: flex;justify-content:flex-end;">
																<el-button :icon="Plus" circle class="addButtonClass" type="primary" @click="addTable('oauth')"></el-button>
															</div>
														</div>
													</template>
												</el-table-column>
												<el-table-column prop="type">
													<template #default="scope">
														<div v-if="deployGroup.oauth[props.row.name]">
															<el-input v-model="deployGroup.oauth[props.row.name][scope.row.name]" v-if="scope.row.type!==null" :rows="1" :type="scope.row.type"></el-input>
														</div>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</template>
								</el-table-column>
								<el-table-column label="键" prop="name"></el-table-column>
								<el-table-column label="值" prop="value" />
							</el-table>
						</div>
					</el-collapse-item>
					<el-collapse-item title="Sink 配置" name="3">
						<div>
							<div style="display: flex">
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												是否忽略输出
												<el-tooltip content="如果选择结果为空，则忽略输出。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.omitIfEmpty" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												将结果数据按条发送
												<el-tooltip content='输出消息以数组形式接收，该属性意味着是否将结果一一发送。 如果为 false，则输出消息将为 {"result":"${the string of received message}"}。 例如，{"result":"[{"count":30},""count":20}]"}。否则，结果消息将与实际字段名称一一对应发送。 对于与上述相同的示例，它将发送 {"count":30}，然后发送 {"count":20} 到 RESTful 端点。默认为 false。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.sendSingle" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="流格式" style="margin-bottom: 20px">
										<el-select v-model="formData.format" placeholder="请选择">
											<el-option label="json" value="json" />
											<el-option label="binary" value="binary" />
											<el-option label="protobuf" value="protobuf" />
											<el-option label="delimited" value="delimited" />
											<el-option label="custom" value="custom" />
										</el-select>
									</el-form-item>
								</div>
							</div>
							<el-form-item label="" style="margin-bottom: 20px">
								<span slot="label">
												数据模版
												<el-tooltip content='Golang 模板格式字符串，用于指定输出数据格式。 模板的输入是目标消息，该消息始终是 map 数组。 如果未指定数据模板，则将数据作为原始输入。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
								<el-input v-model="formData.dataTemplate" placeholder="请输入数据模版" type="textarea"></el-input>
							</el-form-item>
							<!------------------------------->
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												线程数
												<el-tooltip content='设置运行的线程数。该参数值大于1时，消息发出的顺序可能无法保证。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.concurrency" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												缓存大小
												<el-tooltip content='设置可缓存消息数目。若缓存消息数超过此限制，sink将阻塞消息接收，直到缓存消息被消费使得缓存消息数目小于限制为止。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.bufferLength" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												批量发送大小
												<el-tooltip content='设置批量发送的消息数目。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.batchSize" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												批量发送间隔
												<el-tooltip content='设置批量发送的间隔时间，单位为毫秒。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.lingerInterval" />
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												启用缓存
												<el-tooltip content="控制是否启用缓存。如果它设置为 true，那么缓存将被启用，否则，它将被禁用。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.enableCache" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												重发间隔
												<el-tooltip content='重新发送缓存消息的时间间隔（毫秒）。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.resendInterval" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												内存缓存阈值
												<el-tooltip content='内存中缓存的最大消息数。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.memoryCacheThreshold" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												最大磁盘缓存
												<el-tooltip content='缓存在磁盘中的最大消息数。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.maxDiskCache" />
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												启用备用队列发送缓存数据
												<el-tooltip content="是否在重新发送缓存时使用备用队列。如果设置为true，缓存将被发送到备用队列，而不是原始队列。这将导致实时消息和重发消息使用不同的队列发送，消息的顺序发生变化，但是可以防止消息风暴。只有设置为 true 时，以下 resend 相关配置才能生效。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.resendAlterQueue" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列发送优先级
												<el-tooltip content='重新发送缓存的优先级，int 类型，默认为 0。-1 表示优先发送实时数据；0 表示同等优先级；1 表示优先发送缓存数据。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.resendPriority" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列字段名
												<el-tooltip content='重新发送缓存的字段名。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input v-model="formData.resendIndicatorField" placeholder="请输入备用队列字段名"></el-input>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列发送目标
												<el-tooltip content='MQTT sink: 该属性表示重传的主题。若未设置，则仍传到原主题。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input v-model="formData.resendDestination" placeholder="请输入备用队列发送目标"></el-input>
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												停止时清理缓存
												<el-tooltip content="规则停止时是否清理缓存" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.cleanCacheAtStop" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</el-form>
		</div>

	</div>
</template>

<script setup>
	import { onMounted, onUnmounted, reactive, ref, watch, defineEmits } from "vue"
	import './rest.less'
	import { useRouter } from "vue-router/dist/vue-router";
	import { v4 as uuidv4 } from "uuid"
	import { Back, QuestionFilled, Document, Plus, Delete } from '@element-plus/icons-vue'
	let router = useRouter()

	const emit = defineEmits(["returnResults"])
	// 定义传值类型
	const props = defineProps({
		actionObj: String //这里就接收到父组件传递的value
	})
	/*生命周期*/
	onMounted(() => {
		if(JSON.parse(props.actionObj).rest && JSON.parse(props.actionObj).rest.url) {
			formData.value = JSON.parse(props.actionObj).rest
			formData.value.type = "rest"
			//TODO处理对象转数组(确认编辑的时候对不对)
			Object.keys(formData.value.headers).forEach(key => {
				httpData.value.push({
					name: key,
					val: formData.value.headers[key]
				})
			})
			deployGroup.value.oauth = formData.value.oauth
			Object.keys(formData.value.oauth.refresh.headers).forEach(key => {
				header.value.push({
					key: key,
					val: formData.value.oauth.refresh.headers[key]
				})
			})
			if(formData.value.certificationRaw) {
				certificationList.value.push({
					name:'MD5: '+uuidv4(),
					url:formData.value.certificationRaw
				})
			}
			if(formData.value.rootCARaw) {
				rootCAList.value.push({
					name:'MD5: '+uuidv4(),
					url:formData.value.rootCARaw
				})
			}
			if(formData.value.privateKeyRaw) {
				privateKeyList.value.push({
					name:'MD5: '+uuidv4(),
					url:formData.value.privateKeyRaw
				})
			}
		}
		// 禁止折叠面板按钮点击跳转
		let ele = document.querySelectorAll('.el-collapse-item button')
		for(let i = 0; i < ele.length; i++) {
			ele[i].addEventListener('click', function(event) {
				event.preventDefault();
			});
		}
	});
	const activeNames2 = ref(['1', '2', '3']) //折叠面板默认值
	const handleChange = (val) => {
		//console.log(val)
	}
	let active = ref(2);
	//表单
	let certificationList = ref([]);
	let privateKeyList = ref([]);
	let rootCAList = ref([]);
	let formData = ref({
		//Connection
		'url': "",
		'method': "POST",
		'bodyType': "json",
		'timeout': 5000,
		'responseType': "code",
		'debugResp': true,
		'certificationRaw': "",
		'privateKeyRaw': "",
		'rootCARaw': "",
		'insecureSkipVerify': false,
		//Headers And OAuth
		'headers': {},
		"oauth": {},
		//sink配置
		'omitIfEmpty': false,
		'sendSingle': true,
		'format': "json",
		'dataTemplate': "",
		'concurrency': 1,
		'bufferLength': 1024,
		'batchSize': 0,
		'lingerInterval': 0,
		'enableCache': false,
		'resendInterval': 0,
		'memoryCacheThreshold': 1024,
		'maxDiskCache': 1024000,
		'resendAlterQueue': false,
		'resendPriority': 0,
		'resendIndicatorField': "",
		'resendDestination': "",
		'cleanCacheAtStop': false,
		//---------
		'runAsync': false,
		'type': "rest",
		'bufferPageSize': 256
	})
	//表单规则
	let formRules = ref({
		url: [{
			required: true,
			message: '请输入地址',
			trigger: 'change',
		}]
	})
	/*HTTP头*/
	const httpData = ref([]) //HTTP头表格
	//HTTP头表格添加
	const addCreate = function() {
		httpData.value.push({})
	}
	//HTTP头表格添加删除
	const delClick = function(index) {
		httpData.value.forEach((item, i) => {
			if(index == i) {
				httpData.value.splice(index, 1)
			}
		})
	}
	/*OAuth*/
	let header = ref([]) //OAuth的header表格
	let deployGroup = ref({
		"oauth": {
			"access": {
				"url": "",
				"body": "",
				"expire": ""
			},
			"refresh": {
				"url": "",
				"headers": {},
				"body": ""
			}
		}
	}) //
	const oAuthData = [{
			name: 'access',
			family: [{
					name: 'url',
					type: 'input'
				},
				{
					name: 'body',
					type: 'textarea'
				},
				{
					name: 'expire',
					type: 'input'
				},
			],
		},
		{
			name: 'refresh',
			family: [{
					name: 'url',
					type: 'input'
				},
				{
					name: 'headers',
					type: null
				},
				{
					name: null,
					type: null
				},
				{
					name: 'body',
					type: 'textarea'
				},
			],
		}
	] //OAuth表格
	//新增OAuth的headers表格，头部
	const addTable = (name) => {
		header.value.push({
			key: '',
			val: ''
		})
	}
	//删除OAuth的headers表格，头部
	const deleteTable = (array, e, index) => {
		array.forEach((item, index) => {
			if(item == e) {
				array.splice(index, 1)
			}
		})
	}
	//OAuth表格的样式
	const arraySpanMethod = ({
		row,
		column,
		rowIndex,
		columnIndex,
	}) => {
		if(row.name === null && row.type === null) {
			if(rowIndex % 2 === 0) {
				if(columnIndex === 0) {
					return [1, 2]
				}
			}
		}
	}
	let actionData = ref() // 提交表单
	//测试连接的点击
	const testConnectRules = function() {
		actionData.value.validate((valid) => {
			//提交成功
			if(valid) {
				formData.value.headers = httpData.value.reduce((res, item) => (res[item.name] = item.val, res), {}) //指定键名(一维数组转obj [{},{}] => {})
				deployGroup.value.oauth.refresh.headers = header.value.reduce((res, item) => (res[item.key] = item.val, res), {}) //指定键名(一维数组转obj [{},{}] => {})
				formData.value.oauth = deployGroup.value.oauth
				//console.log(formData.value.oauth)
				emit("returnResults", formData.value)
			}
		})
	}
	//提交的点击
	const submitFun = function() {
		emit("submitResults", formData.value)
	}
	// 文件上传
	const uploadFile = function(file, key) {
		const reader = new FileReader();
		reader.readAsDataURL(file.raw);
		reader.onload = (e) => {
			formData.value[key] = reader.result.split(",")[1]
		};
	}
	// 暴露方法
	defineExpose({
		testConnectRules,
		submitFun
	});
</script>

<style>

</style>