<template>
  <div class="sourcePageEditor">
    <div @click="() => $router.push('/admin/sourcePage')" style="margin-bottom: 8px;cursor: pointer"> {{
        '< 返回'
      }}
    </div>
    <div class="stream-details" v-loading="showLoading">
      <div class="stream-steps">
        <div class="title">{{ `${route.query.oper === 'create' ? '创建' : '编辑'}${streamTitle}` }}</div>
        <div class="steps-container">
          <div style="display: flex;align-items: center;">
            <img src="http://192.168.3.253:8085/web/common/img/done-blue.fca2daac.svg" alt="">
            <span class="active-text">{{ `1.选择${streamTitle}类型` }}</span>
          </div>
          <div class="line"></div>
          <div style="display: flex;align-items: center;">
            <img
                :src="active === 2?'http://192.168.3.253:8085/web/common/img/done-blue.fca2daac.svg':'http://192.168.3.253:8085/web/common/img/done-gray.33058c88.svg'"
                alt="">
            <span :class="active === 2 ? 'active active-text' :''">{{ `2.选择${streamTitle}类型` }}</span>
          </div>
        </div>
      </div>
      <div v-if="active === 1">
        <div class="steps-title">选择文件类型</div>
        <div class="stream-content">
          <div class="flex-container" v-if="loading">
            <div v-for="(item, index) in cellType" :key=" index"
                 class="stream-details-dome">
              <div
                  @click="selectCell(item.name)"
                  :class="['template-card',activeCell===item.name?'active-cell':'']">
                <div class="left-content">
                  <img :src="item.url" alt="">
                </div>
                <div class="card-content">
                  <div class="template-label">{{ item.initial }}</div>
                  <div class="template-content">
                    {{ item.about ? item.about.description.zh : '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--配置页-->
      <div v-else class="editor">
        <el-form
            ref="ruleFormRef"
            style="max-width: 600px"
            :model="formData"
            status-icon
            :rules="rules"
            label-width="auto"
            class="demo-ruleForm"
            label-position="top"
        >
          <el-form-item :label="`${streamTitle}名称`" prop="name">
            <el-input type="text" v-model="formData.name" :disabled="route.query.oper === 'editor'"/>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span style="margin-right: 8px">{{ `是否为带结构的${streamTitle}` }}</span>
              <el-switch style="--el-switch-on-color: #ccefe3;" v-model="dataSource"/>
            </template>
          </el-form-item>
          <div class="form-field">
            <el-form-item style="min-width: 810px;" prop="streamFieldTable" v-if="dataSource">
              <template #label>
                <div class="numericField">
                  <div style="display: flex;align-items: center;">
                    <label for="">{{ `${streamTitle}字段` }}</label>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        placement="top"
                    >
                      <img style="width: 16px;height: 16px;margin-left:5px"
                           src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                      <template #content>
                        <div class="max-tooltip">
                          <span style="color: #ffffff;margin-right: 8px;">自定义流字段结构， 例如对应 SQL 语句中的： ( USERID BIGINT, FIRST_NAME STRING, LAST_NAME STRING, NICKNAMES ARRAY(STRING), Gender BOOLEAN, ADDRESS STRUCT( STREET_NAME STRING, NUMBER BIGINT ), )</span>
                        </div>
                      </template>
                    </el-tooltip>
                  </div>
                  <el-button :icon="Plus" @click="streamFieldStatus=true">添加</el-button>
                </div>
              </template>
              <div>
                <el-table :data="formData.streamFieldTable" style="min-width:810px;border-radius: 8px;">
                  <el-table-column prop="Name" label="名称" min-width="344"/>
                  <el-table-column prop="FieldType" label="类型" min-width="344"/>
                  <el-table-column label="操作" min-width="120">
                    <template #default="scope">
                      <div class="del-button" @click="deleteStreamField(scope.row)">删除</div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form-item>
          </div>
          <el-form-item v-if="shouldDisplay">
            <template #label>
              <div style="display: flex;align-items: center;">
                <label for="">数据源</label>
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    placement="top"
                >
                  <img style="width: 16px;height: 16px;margin-left:5px"
                       src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                  <template #content>
                    <div class="max-tooltip">
                      <span style="color: #ffffff;margin-right: 8px">{{ dataSourceTips }}</span>
                    </div>
                  </template>
                </el-tooltip>
              </div>
            </template>
            <el-input v-model="formData.dataSource"></el-input>
          </el-form-item>
          <div v-if="componentType!=='memory'">
            <el-form-item prop="checkPass">
              <template #label>
                <div style="display: flex;justify-content: space-between;">
                  <div>配置组</div>
                  <div class="button-group">
                    <div class="add-button" @click="openDialog(false)">添加配置组</div>
                    <div class="add-button" style="margin-left: 16px" v-if="formData.configurationGroup"
                         @click="openDialog(true)">编辑配置组
                    </div>
                  </div>
                </div>
              </template>
              <el-select
                  v-model="formData.configurationGroup"
                  clearable
                  placeholder="请选择"
                  size="large"
                  style="width: 440px"
              >
                <el-option
                    v-for="(value,key) in configGroup"
                    :key="key"
                    :label="key"
                    :value="key"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="modify.activeName === 'flowManage'?'流格式':'表格式'" prop="age">
              <el-select
                  v-model="formData.streamingFormat"
                  placeholder="请选择"
                  size="large"
                  style="width: 440px"
              >
                <el-option
                    v-for="item in formatGroup"
                    :key="item"
                    :label="item"
                    :value="item"
                />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item v-if="modify.activeName===null||modify.activeName === 'flowManage'">
            <template #label>
              <div style="display: flex;align-items: center;">
                <label for="">Shared</label>
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    placement="top"
                >
                  <img style="width: 16px;height: 16px;margin-left:5px"
                       src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                  <template #content>
                    <div class="max-tooltip">
                      <span style="color: #ffffff;margin-right: 8px">是否在使用该流的规则中共享源的实例</span>
                    </div>
                  </template>
                </el-tooltip>
              </div>
            </template>
            <el-radio v-model="formData.shared" :label="true" border>true</el-radio>
            <el-radio v-model="formData.shared" :label="false" border>false</el-radio>
          </el-form-item>
          <el-form-item v-if="modify.activeName === 'scanTable'">
            <template #label>
              <div style="display: flex;align-items: center;">
                <label for="">保留大小</label>
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    placement="top"
                >
                  <img style="width: 16px;height: 16px;margin-left:5px"
                       src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                  <template #content>
                    <div class="max-tooltip">
                      <span style="color: #ffffff;margin-right: 8px">是否在使用该流的规则中共享源的实例</span>
                    </div>
                  </template>
                </el-tooltip>
              </div>
            </template>
            <el-input-number v-model="formData.reserveSize" text-aling="left" controls-position="right"/>
          </el-form-item>
          <el-form-item v-if="modify.activeName === 'lookupTable'">
            <template #label>
              <div style="display: flex;align-items: center;">
                <label for="">主键</label>
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    placement="top"
                >
                  <img style="width: 16px;height: 16px;margin-left:5px"
                       src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
                  <template #content>
                    <div class="max-tooltip">
                      <span style="color: #ffffff;margin-right: 8px">是否在使用该流的规则中共享源的实例</span>
                    </div>
                  </template>
                </el-tooltip>
              </div>
            </template>
            <el-input v-model="formData.primaryKey"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--最底部按钮-->
    <div v-loading="showLoading" class="footer-fix-container">
      <div v-if="active === 1">
        <el-button type="primary" @click="next(2)">下一步</el-button>
        <el-button @click="() => $router.push('/admin/sourcePage')">取消</el-button>
      </div>
      <div v-else>
        <el-button @click="submit" type="primary">提交</el-button>
        <el-button @click="next(1)">上一步</el-button>
        <el-button @click="() => $router.push('/admin/sourcePage')">取消</el-button>
      </div>
    </div>
    <!--添加流字段弹窗-->
    <div class="add-dialog">
      <el-dialog v-model="streamFieldStatus" :close-on-click-modal="false" :title="`添加${streamTitle}字段`"
                 width="40%">
        <el-form label-position="top" :rules="numericFieldRules" ref="numericFieldForm" :inline="true"
                 :model="streamField" label-width="auto">
          <el-form-item label="名称" prop="Name">
            <el-input v-model="streamField.Name" placeholder="请输入组名称"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="FieldType">
            <el-select
                v-model="streamField.FieldType"
                placeholder="请选择"
                size="large"
                style="width: 440px"
            >
              <el-option
                  v-for="item in streamFieldType"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="streamFieldStatus=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="addStreamField">保存</el-button>
      </span>
        </template>
      </el-dialog>
    </div>
    <!--配置弹窗-->
    <div class="deploy-dialog">
      <el-dialog v-model="deployStatus" :close-on-click-modal="false"
                 :title="`${store.state.sourceData['prohibit']?'编辑':'添加'}源配置组`" width="40%">
        <currentComponent :componentType="modify.activeName" :key="deployKey" :data="deployGroup"
                          ref="formChild"></currentComponent>
        <template #footer>
          <div class="deploy-dialog-footer">
            <div>
              <el-button type="primary" @click="configuration">提交</el-button>
              <el-button @click="deployStatus=false">取消</el-button>
            </div>
            <el-button @click="testConnection">测试连接</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {computed, onMounted, reactive, ref, watch} from "vue"
import {useRoute} from "vue-router";
import {modifyTable, streamConfig, streamType, testConnections} from "@/api/modules/sourcePageEditor";
import {Plus} from "@element-plus/icons-vue";
import userSourcePageEditorData from "@/pages/dataProcessing/sourcePage/utils/data";
import {useStore} from "vuex";
import mqtt from "@/pages/dataProcessing/sourcePage/formDialog/mqtt.vue";
import file from "@/pages/dataProcessing/sourcePage/formDialog/file.vue";
import httppull from "@/pages/dataProcessing/sourcePage/formDialog/httppull.vue";
import httppush from "@/pages/dataProcessing/sourcePage/formDialog/httppush.vue";
import neuron from "@/pages/dataProcessing/sourcePage/formDialog/neuron.vue";
import simulator from "@/pages/dataProcessing/sourcePage/formDialog/simulator.vue";
import sql from "@/pages/dataProcessing/sourcePage/formDialog/sql.vue";
import video from "@/pages/dataProcessing/sourcePage/formDialog/video.vue";
import redis from "@/pages/dataProcessing/sourcePage/formDialog/redis.vue";
import router from "@/router";
import {addConfigGroup} from "@/api/modules/sourcePage";
import {addStream, addTable} from "@/api/modules/sourcePageEditor";
import {ElMessage} from 'element-plus'

let active = ref(1);
const {
  formData,
  configGroup,
  formatGroup,
  streamFieldType,
  streamField,
  dataSource, streamFieldStatus,
  deployStatus,
  activeCell,
  arrayList,
  removeEmptyValues
} = userSourcePageEditorData()
//修改
const formChild = ref(null);
const ruleFormRef = ref(null);
const numericFieldForm = ref(null);
const streamTitle = ref(null);
const deployKey = ref(0)
const store = useStore();
const modify = ref(null)
let deployGroup = ref(null)
const popUpPromptStatus = ref(null)
const cellData = ref(null)
const loading = ref(false)
//弹窗类型
const componentType = ref('mqtt');
const rules = reactive({
  name: [{required: true, message: '请填写内容', trigger: 'blur'}],
  streamFieldTable: [{required: true, message: '', trigger: 'blur'}],
})
const numericFieldRules = reactive({
  Name: [{required: true, message: '请填写内容', trigger: 'blur'}],
  FieldType: [{required: true, message: '请填写内容', trigger: 'blur'}],
})
let route = useRoute();
const next = (n) => {
  active.value = n;
};
/*--------方法区域-----------*/
//测试连接
const testConnection = async () => {
  try {
    streamFieldStatus.value = false
    const formData = await formChild.value.getFormData();
    testConnections(activeCell.value, formData).then(res => {
      if (res.status === 200) {
        ElMessage({
          message: "连接成功",
          type: 'success',
        })
      }
    }).catch(error => {
      // console.log(error)
      ElMessage({
        message: error.message,
        type: 'error',
      })
    })
  } catch (error) {
    ElMessage({
      message: "请填写内容",
      type: 'error',
    })
    // console.log(error)
  }
}
// 打开/编辑弹窗
const openDialog = (status) => {
  popUpPromptStatus.value = status
  store.state.sourceData['prohibit'] = status
  const key = formData.value.configurationGroup
  deployGroup.value = !status ? {name: key, ...deployGroup.value, ...configGroup.value[key]} : {name: key, ...configGroup.value[key]};
  if (formData.value.configurationGroup === '') {
    deployGroup.value = {}
  }
  if (!status) {
    if (activeCell.value === 'file') {
      deployGroup.value.path = ''
      deployGroup.value.interval = ''
    }
    if (activeCell.value === 'simulator') {
      deployGroup.value.data = ''
    }
    if (activeCell.value === 'sql') {
      deployGroup.value.url = ''
    }
  } else {
    streamConfigs()
  }
  deployStatus.value = true
  deployKey.value++
};
// 提交弹窗
const configuration = async () => {
  try {
    const formData = await formChild.value.getFormData();
    const data = {...formData}
    delete data.name
    const path = formData.name
    for (let key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        // 检查属性值是否为空字符串
        if (data[key] === '') {
          // 删除空字符串属性
          delete data[key];
        }
      }
    }
    // 添加配置组 path , name ,data
    addConfigGroup(activeCell.value, path, data).then(res => {
      // console.log(res.status)
      if (res.status === 200) {
        let temp = activeCell.value
        /*if (temp === 'redisSub') {
          temp = 'redis'
        }*/
        // 更新配置组
        streamConfig(temp.replace(/\s/g, '').toLowerCase()).then(res => {
          deployStatus.value = false
          ElMessage({
            message: popUpPromptStatus.value ? '编辑成功' : '添加成功',
            type: 'success',
          })
        })
      }
    })
  } catch (error) {
    ElMessage({
      message: "请填写内容",
      type: 'error',
    })
    // console.log("error", error)
  }
}
//提交主文件
const submit = async () => {
  try {
    const isValid = await ruleFormRef.value.validate();
    const data = removeEmptyValues(formData.value)
    // 字段
    const columns = formData.value.streamFieldTable
    const formattedString = columns.map(column => `${column.Name} ${column.FieldType}`).join(', ');
    //
    const sql = {sql: ''}
    if (modify.value.activeName === 'flowManage') {
      sql.sql = "CREATE STREAM " + data.name + " (" + formattedString + ") WITH ( datasource = \"" + data.dataSource + "\", FORMAT = \"" + data.streamingFormat + "\",CONF_KEY=\"" + data.configurationGroup + "\",TYPE=\"" + activeCell.value + "\", SHARED=\"" + data.shared + "\")"
      addData(addStream, '', data.name, sql)

    } else if (modify.value.activeName === 'scanTable') {
      sql.sql = "CREATE TABLE " + data.name + " (" + formattedString + ") WITH ( datasource = \"" + data.dataSource + "\", FORMAT = \"" + data.streamingFormat + "\",CONF_KEY=\"" + data.configurationGroup + "\",TYPE=\"" + activeCell.value + "\", KIND=\"scan\", RETAIN_SIZE=\"" + data.reserveSize + "\")"
      if (data.streamingFormat === void 0) {
        sql.sql = "CREATE TABLE " + data.name + " (" + formattedString + ") WITH ( datasource = \"" + data.dataSource + "\",CONF_KEY=\"" + data.configurationGroup + "\",TYPE=\"" + activeCell.value + "\", KIND=\"scan\", RETAIN_SIZE=\"" + data.reserveSize + "\")"
      }
      addData(route.query.oper === 'create' ? addTable : modifyTable, 'scan', data.name, sql)

    } else {
      sql.sql = "CREATE TABLE " + data.name + " (" + formattedString + ") WITH ( datasource = \"" + data.dataSource + "\", FORMAT = \"" + data.streamingFormat + "\",CONF_KEY=\"" + data.configurationGroup + "\",TYPE=\"" + activeCell.value + "\", KIND=\"lookup\", KEY=\"" + data.primaryKey + "\")"
      if (data.streamingFormat === void 0) {
        sql.sql = "CREATE TABLE " + data.name + " (" + formattedString + ") WITH ( datasource = \"" + data.dataSource + "\",CONF_KEY=\"" + data.configurationGroup + "\",TYPE=\"" + activeCell.value + "\",KIND=\"lookup\", KEY=\"" + data.primaryKey + "\")"
      }
      addData(route.query.oper === 'create' ? addTable : modifyTable, 'lookup', data.name, sql)
    }
  } catch (error) {
    ElMessage({
      message: "请填写内容",
      type: 'error',
    })
    // console.log("error", error)
  }
}
//报错
const errors = (e) => {
  ElMessage({
    message: e.message,
    type: 'error',
  })
}
//新增通用方法
const addData = (fn, name, path, sql) => {
  if (modify.value.activeName === 'flowManage') {
    fn(path, sql).then(res => {
      if (res.status === 200 || res.status === 201) {
        router.push(`/admin/sourcePage?activeName=${modify.value.activeName}`);
        ElMessage({
          message: route.query.oper === 'create' ? '创建成功' : '编辑成功',
          type: 'success',
        })
      }
    }).catch(error => {
      errors(error)
    })
  } else if (route.query.oper === 'create') {
    fn(name, sql).then(res => {
      if (res.status === 200 || res.status === 201) {
        router.push(`/admin/sourcePage?activeName=${modify.value.activeName}`);
        ElMessage({
          message: route.query.oper === 'create' ? '创建成功' : '编辑成功',
          type: 'success',
        })
      }
    }).catch(error => {
      errors(error)
    })
  } else {
    fn(name, path, sql).then(res => {
      if (res.status === 200 || res.status === 201) {
        router.push(`/admin/sourcePage?activeName=${modify.value.activeName}`);
        ElMessage({
          message: route.query.oper === 'create' ? '创建成功' : '编辑成功',
          type: 'success',
        })
      }
    }).catch(error => {
      errors(error)
    })
  }
}
// 删除配置组添加流字段
const addStreamField = async () => {
  try {
    const data = await numericFieldForm.value.validate();
    if (data) {
      // 查找name是否已存在
      const existingItemIndex = formData.value.streamFieldTable.findIndex(
          item => item.Name === streamField.value.Name
      );
      if (existingItemIndex !== -1) {
        // 如果name已存在，替换其type
        formData.value.streamFieldTable[existingItemIndex].type = streamField.value.type;
      } else {
        // 如果name不存在，直接添加新元素
        formData.value.streamFieldTable.push(streamField.value);
      }
      // 清空或重置streamField.value
      streamField.value = {
        Name: '',
        FieldType: ''
      };
      streamFieldStatus.value = false
    }
  } catch (error) {
    // console.error("error", error)
  }
}
// 删除流字段
const deleteStreamField = (e) => {
  formData.value.streamFieldTable.splice(e, 1)
}
//选择卡片
const selectCell = (item) => {
  activeCell.value = item;
  formData.value.configurationGroup = '';
  formData.value.dataSource = activeCell.value.replace(/\s/g, '').toLowerCase()
}
// 获取/更新配置组
const streamConfigs = () => {
  let temp = activeCell.value
  // if (temp === 'redis') {
  //   temp = 'redis'
  // }
  streamConfig(temp.replace(/\s/g, '').toLowerCase()).then(res => {
    deployGroup.value = res.data[1]
    configGroup.value = res.data;
  })
}
/*--------------计算属性---------------------*/
//动态加载组件
const currentComponent = computed(() => {
  switch (componentType.value) {
    case 'mqtt':
      return mqtt;
    case 'file':
      return file;
    case 'httppull':
      return httppull;
    case 'httppush':
      return httppush;
    case 'memory':
      return;
    case 'neuron':
      return neuron;
    case 'simulator':
      return simulator;
    case 'sql':
      return sql;
    case 'video':
      return video;
    case 'redis':
      return redis;
    default:
      return mqtt;
  }
});
//卡片数组
const cellType = computed(() => {
  const ordinary = arrayList.value.filter(item => item.name !== 'redis');
  const allowedNames = ['httppull', "memory", 'redis', 'sql'];
  const inquiry = arrayList.value.filter(item => allowedNames.includes(item.name));
  if (modify.value === null) {
    activeCell.value = 'mqtt';
    return ordinary;
  }
  // 根据条件决定是否需要额外的输出
  if (route.query.oper === 'editor') {
    formData.value.dataSource = modify.value.Options.datasource;
    formData.value.name = modify.value.Name;
    formData.value.streamingFormat = modify.value.Options.format;
    formData.value.configurationGroup = modify.value.Options.confKey;
    formData.value.shared = modify.value.Options.shared ? modify.value.Options.shared : false;
    formData.value.streamFieldTable = modify.value.StreamFields ? modify.value.StreamFields : [];
    formData.value.primaryKey = modify.value.Options.key ? modify.value.Options.key : "id";
    formData.value.reserveSize = modify.value.Options.retainSize ? modify.value.Options.retainSize : 0;
    activeCell.value = modify.value.Options.type;
    switch (modify.value.activeName) {
      case 'flowManage':
        return ordinary
      case 'scanTable':
        return ordinary
      case 'lookupTable':
        return inquiry
    }
  } else {
    switch (modify.value.activeName) {
      case 'flowManage':
        activeCell.value = 'mqtt';
        return ordinary
      case 'scanTable':
        activeCell.value = 'file';
        return ordinary
      case 'lookupTable':
        activeCell.value = 'redis';
        return inquiry
    }
  }
});
// 是否显示配置组
const shouldDisplay = computed(() => {
  if (modify.value.activeName === 'lookupTable') {
    return componentType.value !== 'neuron' && componentType.value !== 'simulator' && componentType.value !== 'video';
  }
  return componentType.value !== 'neuron' && componentType.value !== 'simulator' && componentType.value !== 'sql' && componentType.value !== 'video';
});
//数据源 tips
const dataSourceTips = computed(() => {
  switch (componentType.value) {
    case 'mqtt':
      return '将要订阅的 MQTT 主题， 例如 topic1';
    case 'file':
      return '不含路径的文件名，例如 test.json';
    case 'httppull':
      return 'URL 的路径部分，与 URL 属性拼接成最终 URL， 例如 /api/data';
    case 'httppush':
      return 'URL 的路径部分，与配置的 HTTP 服务器地址拼接成最终 URL，HTTP 服务器地址默认为http://localhost:10081。例如输入 /api/data，则监听将监听 http://localhost:10081/api/data';
    case 'memory':
      return '将要订阅的内存主题，类似 MQTT 主题，例如 topic1';
    case 'sql':
      return '将要连接的关系数据库表，例如 table1';
    case 'video':
      return video;
    case 'redissub':
      return '将要连接的 Redis 数据库号，例如 0';
  }
})
// 加载动画
const showLoading = computed(() => {
  // if (route.query.oper === 'create') {
  //   return !loading.value
  // }
  return !loading.value
})
/*---------------监听------------------------*/
// 监听开启的弹窗
watch(activeCell, (newValue) => {
  componentType.value = newValue.replace(/\s/g, '').toLowerCase()
}, {deep: true})
// 监听步骤
watch(active, (newValue) => {
  if (newValue !== 1) {
    if (route.query.oper !== 'editor') {
      if (modify.value.activeName === 'flowManage') {
        formData.value.dataSource = activeCell.value.replace(/\s/g, '').toLowerCase()
        formData.value.streamingFormat = 'json'
      } else if (modify.value.activeName === 'scanTable') {
        formData.value.reserveSize = 1
      } else {
        formData.value.primaryKey = 'id'
      }
    }
    if (route.query.oper === 'create') {
      switch (componentType.value) {
        case 'mqtt':
          if (modify.value.activeName === 'scanTable') {
            formData.value.dataSource = 'topic1'
          }
          break;
        case 'file':
          if (modify.value.activeName === 'scanTable') {
            formData.value.dataSource = 'test.json'
          }
          break;
        case 'httppush':
          if (modify.value.activeName === 'scanTable') {
            formData.value.dataSource = '/api/data'
          }
          break;
        case 'memory':
          if (modify.value.activeName !== 'flowManage') {
            formData.value.dataSource = 'topic1'
          }
          break;
        case 'sql':
          if (modify.value.activeName === 'lookupTable') {
            formData.value.dataSource = 'table1'
          }
          break;
        case 'redissub':
          if (modify.value.activeName === 'lookupTable') {
            formData.value.dataSource = '0'
          }
          break;
      }
    }
    streamConfigs()
    deployKey.value++
  }
});
onMounted(() => {
  modify.value = JSON.parse(sessionStorage.getItem('sourceEditor'));
  streamTitle.value = modify.value.activeName === 'lookupTable' ? '查询表' : modify.value.activeName === 'flowManage' ? '流' : '表'
  streamType(modify.value.activeName === 'lookupTable' ? {kind: 'lookup'} : {}).then(res => {
    cellData.value = res.data
    arrayList.value.forEach(itemB => {
      // 查找arrayA中name与itemB.name相同的对象
      const matchedItemInA = cellData.value.find(itemA => itemA.name === itemB.name);
      // 如果找到匹配项，则更新其initial和url
      if (matchedItemInA) {
        itemB.about = matchedItemInA.about
        itemB.about.description.zh = itemB.description.zh
      }
    });
    arrayList.value = arrayList.value.filter(itemA =>
        cellData.value.some(itemB => itemB.name === itemA.name)
    );
    loading.value = true
  })
});
</script>


<style scoped lang="less">
.max-tooltip {
  max-width: 280px !important;
}

@import "sourcePageEditor";
</style>