<template>
  <div class="configuration">
    <div class="title">
      <p>
        <span>参数配置</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <el-select v-model="effectType" clearable placeholder="请选择参数类型">
            <el-option label="源" value="1"/>
            <el-option label="网" value="2"/>
            <el-option label="荷" value="3"/>
            <el-option label="储" value="4"/>
          </el-select>
        </div>
        <div class="liBox">
          <el-input v-model="deviceName" :prefix-icon="Search" placeholder="请输入设备名称"></el-input>
        </div>
        <div class="liBox">
          <el-input v-model="paramName" :prefix-icon="Search" placeholder="请输入参数名称"></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--新增参数-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增参数
        </el-button>
        <!--参数列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增参数</p>
            </el-empty>
          </template>
          <el-table-column label="类型" prop="effectType" width="70px">
            <template #default="scope">
              <span v-if="scope.row.effectType==1">源</span>
              <span v-if="scope.row.effectType==2">网</span>
              <span v-if="scope.row.effectType==3">荷</span>
              <span v-if="scope.row.effectType==4">储</span>
            </template>
          </el-table-column>
          <el-table-column label="设备名称" prop="deviceName" width="90px"></el-table-column>
          <el-table-column label="参数名称" prop="paramName" width="90px"></el-table-column>
          <el-table-column label="单位" prop="valueUnit" width="70px"></el-table-column>
          <el-table-column label="创建时间" prop="createdAt" sortable width="120px"></el-table-column>
          <el-table-column label="值" min-width="4%">
            <template #default="scope">
              <div class="confBox">
                <div v-for="(item_child,index_child) in scope.row.paramValue.data" :key="index_child" class="contBox">
                  <p v-if="index_child!==0" style="font-size: 20px">
                    {{ scope.row.paramValue.formula.slice(2 * index_child - 1, 2 * index_child) }}</p>
                  <div class="contItem">
                    <p v-if="item_child.type==1" :title="item_child.deviceName" class="name">{{
                        item_child.deviceName
                      }}</p>
                    <p v-if="item_child.type==1" :title="item_child.typeName" class="name">{{ item_child.typeName }}</p>
                    <p v-if="item_child.type==1" class="time" style="color:#908f8f;">时效{{ item_child.expirationTime }}秒
                    </p>
                    <p v-if="item_child.type==2" class="val" style="text-align: center">{{ item_child.val }}</p>
                    <p v-if="item_child.type==3" class="valcopy" style="text-align: center">{{ item_child.val }}</p>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>


          <!--          <el-table-column label="参数名称" prop="createdAt"></el-table-column>-->
          <!--          <el-table-column label="值的设置的源">-->
          <!--            <template #default="scope">-->
          <!--              <div class="confBox">-->
          <!--                <div v-for="(item_child,index_child) in scope.row.paramValue.data" :key="index_child" class="contBox">-->
          <!--                  <p v-if="index_child!==0" style="font-size: 20px">-->
          <!--                    {{ scope.row.paramValue.formula.slice(2 * index_child - 1, 2 * index_child) }}</p>-->
          <!--                  <div class="contItem">-->
          <!--                    <p v-if="item_child.type==1" :title="item_child.deviceName" class="name">{{-->
          <!--                        item_child.deviceName-->
          <!--                      }}</p>-->
          <!--                    <p v-if="item_child.type==1" :title="item_child.typeName" class="name">{{ item_child.typeName }}</p>-->
          <!--                    <p v-if="item_child.type==1" class="time" style="color:#908f8f;">时效{{ item_child.expirationTime }}秒-->
          <!--                    </p>-->
          <!--                    <p v-if="item_child.type==2" class="val" style="text-align: center">{{ item_child.val }}</p>-->
          <!--                    <p v-if="item_child.type==3" class="valcopy" style="text-align: center">{{ item_child.val }}</p>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--          <el-table-column label="备注" prop="createdAt"></el-table-column>-->
          <el-table-column label="操作" width="120px">
            <template #default="scope">
              <!--              <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>-->
              <!--              <span v-if="!scope.row.used" class="editClass btn"-->
              <!--                    @click.prevent="delClick(scope.row.id,scope.row)">删除</span>-->
              <!--              <span v-if="scope.row.used" class="editClass btn" style="color: #767676">删除</span>-->
              <div class="opacity">
                <span class="btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span :style="{cursor:!scope.row.used?'pointer':'no-drop'}" class="btn delClass"
                      @click.prevent="!scope.row.used?delClick(scope.row.id,scope.row):null">删除</span>
                <!--                <img alt="" src="../../../assets/img/161@2x.png" @click.prevent="editClick(scope.row.id,scope.row)">-->
                <!--                <img :style="{cursor:!scope.row.used?'pointer':'no-drop'}" alt="" src="../../../assets/img/163@2x.png"-->
                <!--                     @click.prevent="!scope.row.used?delClick(scope.row.id,scope.row):null">-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,prev, pager, next,sizes" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.paramId?'编辑参数':'新建参数'"
               width="80%">
      <el-form v-model="formData" :inline="true">
        <el-form-item label="设备类型" name="effectType">
          <el-select v-model="formData.effectType" placeholder="请选择设备类型" @change="typeSelect">
            <el-option :value="1" label="源"/>
            <el-option :value="2" label="网"/>
            <el-option :value="3" label="荷"/>
            <el-option :value="4" label="储"/>
          </el-select>
        </el-form-item>
        <el-form-item label="选择设备" name="virtualDeviceId">
          <el-select v-model="formData.virtualDeviceId" allow-create filterable placeholder="请选择设备"
                     @change="deviceChange">
            <el-option v-for="(item,index) in equipmentList" :key="index" :label="item.deviceName"
                       :value="item.deviceId">
              <span style="float: left">{{ item.deviceName }}</span>
              <el-icon style="float: right; color: #FF734F;margin-top: 10px;"
                       @click="delDevice($event,item.deviceId)">
                <Delete/>
              </el-icon>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参数名称" name="paramName">
          <el-input v-model="formData.paramName" type="text"></el-input>
        </el-form-item>
        <el-form-item label="是否展示" name="isShow">
          <el-select v-model="formData.isShow" allow-create placeholder="请选择是否展示">
            <el-option :value="1" label="是"/>
            <el-option :value="0" label="否"/>
          </el-select>
        </el-form-item>
        <el-form-item label="展示顺序" name="showSort">
          <el-input v-model="formData.showSort" type="number"></el-input>
        </el-form-item>
        <el-form-item label="橱窗展示" name="isShowcase">
          <el-select v-model="formData.isShowcase" allow-create placeholder="请选择是否展示">
            <el-option :value="1" label="是"/>
            <el-option :value="0" label="否"/>
          </el-select>
        </el-form-item>
        <el-form-item label="橱窗展示顺序" name="showcaseSort">
          <el-input v-model="formData.showcaseSort" type="number"></el-input>
        </el-form-item>
        <el-form-item label="参数类型" name="paramType">
          <el-select v-model="formData.paramType" clearable>
            <el-option v-for="(item,index) in paramTypeList" :key="index" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称" name="valueUnit">
          <el-input v-model="formData.valueUnit" type="text"></el-input>
        </el-form-item>
      </el-form>
      <!--参数值的设置-->
      <div class="content">
        <div class="contentItem">
          <p>值</p>
          <!--          <el-select clearable placeholder="请选择">-->
          <!--            <el-option :value="1" label="总表的总有功功率"/>-->
          <!--            <el-option :value="2" label="储能的SOC"/>-->
          <!--            <el-option :value="3" label="储能的保留SOC"/>-->
          <!--            <el-option :value="3" label="储能的总有功功率"/>-->
          <!--            <el-option :value="3" label="储能的状态"/>-->
          <!--            <el-option :value="3" label="储能的充放电最大功率"/>-->
          <!--            <el-option :value="3" label="申请容量"/>-->
          <!--            <el-option :value="3" label="电费及充放电设置"/>-->
          <!--          </el-select>-->
          <p>=</p>
          <div v-for="(item_child,index_child) in formData.paramValue.data" :key="index_child"
               :style="{alignItems:item_child.type===''?'center':'start'}" class="contItemBox">
            <div v-if="index_child!==0" class="symbol">
              <p
                  :class="formData.paramValue.formula.substring(2*index_child-1,2*index_child)==='+'?'activeSymbol':''"
                  @click="symbolClick(index_child,'+')">+</p>
              <p
                  :class="formData.paramValue.formula.substring(2*index_child-1,2*index_child)==='-'?'activeSymbol':''"
                  @click="symbolClick(index_child,'-')">-</p>
              <p
                  :class="formData.paramValue.formula.substring(2*index_child-1,2*index_child)==='×'?'activeSymbol':''"
                  @click="symbolClick(index_child,'×')">×</p>
              <p
                  :class="formData.paramValue.formula.substring(2*index_child-1,2*index_child)==='÷'?'activeSymbol':''"
                  @click="symbolClick(index_child,'÷')">÷</p>
            </div>
            <div class="contItem">
              <el-select v-model="item_child.type" clearable placeholder="请选择参数类型">
                <el-option :value="1" label="指标"/>
                <el-option :value="2" label="数值"/>
                <el-option :value="3" label="代码"/>
              </el-select>
              <div v-if="item_child.type!==''" class="contBox">
                <el-icon v-if="formData.paramValue.data.length!==1" class="delIcon" @click="delControl(index_child)">
                  <CircleClose/>
                </el-icon>
                <el-select v-show="item_child.type==1" ref="elSelectTable" v-model="item_child.typeName"
                           :filter-method="filterMethod" :popper-append-to-body="false"
                           class="select-unitName select-option-father" clearable filterable
                           popper-class="select_report_and_type">
                  <el-option :value="dataItemList">
                    <!--                    <el-tree ref="tableDom" :expand-on-click-node='false' :load="load"-->
                    <!--                             class="unit-tree"-->
                    <!--                             highlight-current lazy @node-click="clickUnitTreeNodeChild($event,index_child)">-->
                    <!--                    </el-tree>-->
                    <el-tree
                        ref="treeRef"
                        :expand-on-click-node=true
                        :filter-node-method="filterNode"
                        :load="treeList"
                        :props="props"
                        highlight-current
                        lazy
                        @node-click="clickUnitTreeNodeChild($event,index_child)"
                    />
                  </el-option>
                </el-select>
                <div class="center">
                  <el-input v-if="item_child.type==2" v-model="item_child.val" class="val" placeholder="请输入"
                            type="number"
                  />
                  <el-input v-if="item_child.type==3" v-model="item_child.val" class="val" placeholder="请输入"/>
                  <p v-if="item_child.type==1">{{ item_child.deviceName }}</p>
                  <p v-if="item_child.type==1">{{ item_child.typeName }}</p>
                </div>
                <p v-if="item_child.type==1" class="unit">{{ item_child.unit }}</p>
              </div>
              <div v-if="item_child.type==1" class="contTime">
                <span>时效</span>
                <el-input v-model="item_child.expirationTime" placeholder="请输入"></el-input>
                <span>秒</span>
              </div>
            </div>
          </div>
          <div>
            <el-icon class="addIcon" @click="addControl">
              <CirclePlus/>
            </el-icon>
          </div>
        </div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './configuration.less'
import {CirclePlus, CircleClose, Plus, Search, Delete} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {
  getConfigListApi,
  saveConfigListApi,
  delConfigListApi,
  getEquipmentListApi,
  getParamTypeListApi, getTreeDataApi, saveEquipmentListApi, delEquipmentListApi, getTitleListApi
} from '@/api/modules/configuration'
import qs from "qs";
import letterList from "@/assets/js/letterList";
import {getTreeListApi} from "@/api/modules/control";

let router = useRouter()


// 参数配置列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 参数表格数据
let tableData = ref([])
// 获取参数列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getConfigListApi(qs.stringify({
    pageNum: pageNumber.value,
    pageSize: pageSize.value,
    effectType: effectType.value,
    deviceName: deviceName.value,
    paramName: paramName.value,
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
      for (let i = 0; i < tableData.value.length; i++) {
        tableData.value[i].paramValue = JSON.parse(tableData.value[i].paramValue)
        tableData.value[i].paramType = String(tableData.value[i].paramType)
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取设备列表
let equipmentList = ref([])
const getEquipmentListFun = async function () {
  await getEquipmentListApi(qs.stringify({
    effectType: formData.value.effectType,
  })).then(res => {
    if (res.data.code === 0) {
      equipmentList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 获取参数类型列表
let paramTypeList = ref([])
const getParamTypeListFun = async function () {
  await getParamTypeListApi().then(res => {
    if (res.data.code === 0) {
      paramTypeList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}


// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}


// 参数类型
let effectType = ref()
// 设备名称
let deviceName = ref()
// 参数名称
let paramName = ref()


// 查询
const searchFun = function () {
  pageNumber.value = 1
  getListFun()
}
// 重置
const resetFun = function () {
  pageNumber.value = 1
  pageSize.value = 20
  effectType.value = ''
  deviceName.value = ''
  paramName.value = ''
  getListFun()
}
// 选择设备大类
const typeSelect = function () {
  getEquipmentListFun()
  formData.value.virtualDeviceId = ''
}
// 选择设备
const deviceChange = function (e) {
  let flag = true
  for (let i = 0; i < equipmentList.value.length; i++) {
    if (e === equipmentList.value[i].deviceId) {
      formData.value.deviceName = equipmentList.value[i].deviceName
      flag = false
    }
  }
  if (flag) {
    saveEquipmentListApi({
      deviceId: 0,
      deviceName: e,
      effectType: formData.value.effectType,
    }).then(async res => {
      if (res.data.code == 0) {
        await getEquipmentListFun()
        formData.value.deviceName = e
        formData.value.virtualDeviceId = res.data.result
        // this.$forceUpdate()
      } else {
        formData.value.deviceName = ''
        formData.value.virtualDeviceId = ''
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }
}
// 删除设备
const delDevice = function (event, id) {
  event.stopPropagation()
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除设备',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    delEquipmentListApi(id).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
        getEquipmentListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 新增参数表单-----------------------------------------------------------------
// 参数表单数据
let formData = ref({
  effectType: '',
  virtualDeviceId: '',
  deviceName: '',
  paramName: '',
  paramType: '',
  valueUnit: '',
  isShow: '',
  showSort: null,
  isShowcase: '',
  showcaseSort: null,
  paramValue: {
    formula: 'A',
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      expirationTime: '',
      deviceId: '',
    }]
  },
})
// 新增编辑表单弹窗
let dialogFormVisible = ref(false)
// 树形结构数据
let dataItemList = ref([])

// 新增参数点击
const addClick = function () {
  formData.value = {
    effectType: '',
    virtualDeviceId: '',
    deviceName: '',
    paramName: '',
    paramType: '',
    valueUnit: '',
    isShow: '',
    showSort: '',
    isShowcase: '',
    showcaseSort: '',
    paramValue: {
      formula: 'A',
      data: [{
        val: "",
        type: '',
        unit: "",
        typeId: '',
        typeName: "",
        replaceStr: "A",
        expirationTime: '',
        deviceId: '',
      }]
    },
  }
  equipmentList.value = []
  dialogFormVisible.value = true
}
// 编辑点击
const editClick = function (id, row) {
  dialogFormVisible.value = true
  formData.value = JSON.parse(JSON.stringify(row))
  getEquipmentListFun()
}
// 提交参数表单
const save = function () {
  let data = JSON.parse(JSON.stringify(formData.value))

  // 判断填写是否完整
  let flag = true
  // 判断其他
  if (data.effectType === '' || data.deviceName === '' || data.paramName === '' || data.valueUnit === '' || data.isShow === '' || data.isShowcase === '') {
    flag = false
  }
  // 判断参数
  for (let i = 0; i < data.paramValue.data.length; i++) {
    if (data.paramValue.data[i].type) {
      if (data.paramValue.data[i].type == 1) {
        if (data.paramValue.data[i].typeId === '' || data.paramValue.data[i].typeName === '' || data.paramValue.data[i].expirationTime === '') {
          flag = false
        }
      } else if (data.paramValue.data[i].type == 2 || data.paramValue.data[i].type == 3) {
        if (data.paramValue.data[i].val === '') {
          flag = false
        }
      }
    } else {
      flag = false
    }
  }

  // 填写完整才进行处理调接口
  if (flag) {
    // 处理参数符号
    for (let j = 0; j < data.paramValue.data.length; j++) {
      data.paramValue.data[j].replaceStr = letterList()[j].name
    }
    data.paramValue.formula = letterList()[0].name + data.paramValue.formula.substr(1);
    for (let j = 0; j < data.paramValue.formula.length; j++) {
      if (j % 2 == 0 && j !== 0) {
        data.paramValue.formula = data.paramValue.formula.substr(0, j) + letterList()[j / 2].name + data.paramValue.formula.substr(j + 1);
      }
    }
    data.showSort = Number(data.showSort)
    data.showcaseSort = Number(data.showcaseSort)
    data.paramValue = JSON.stringify(data.paramValue)

    if (!data.paramId) {
      data.paramId = null
    }
    saveConfigListApi(data).then(res => {
      if (res.data.code == 0) {
        getListFun()
        dialogFormVisible.value = false
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  } else {
    ElMessage({
      type: 'info',
      message: '缺少必填项'
    });
  }

}

// 删除参数
const delClick = function (id, row) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    delConfigListApi(row.paramId).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}


// 弹窗的参数操作------------------------------------
// 符号点击
const symbolClick = function (index_child, e) {
  let str = formData.value.paramValue.formula
  formData.value.paramValue.formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
}
// 添加参数值
const addControl = function () {
  formData.value.paramValue.formula = formData.value.paramValue.formula.concat('+D')
  formData.value.paramValue.data.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    expirationTime: '',
    deviceId: '',
  })
}
// 删除参数值
const delControl = function (index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    let str = formData.value.paramValue.formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    formData.value.paramValue.formula = strA.concat(strB)
    formData.value.paramValue.data.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data, index) {
  if (data.type == 3) {
    // formData.value.paramValue.data[index].typeName = data.label
    // formData.value.paramValue.data[index].typeId = data.id
    // formData.value.paramValue.data[index].deviceName = data.titleDeviceName
    // formData.value.paramValue.data[index].unit = data.unitName
    // formData.value.paramValue.data[index].deviceId = data.titleDeviceId
    formData.value.paramValue.data[index].typeName = data.name
    formData.value.paramValue.data[index].typeId = data.id
    formData.value.paramValue.data[index].deviceName = data.equipmentName
    formData.value.paramValue.data[index].unit = data.unitName
    formData.value.paramValue.data[index].deviceId = data.equipmentId
    elSelectTable.value[index].blur();
  } else {
    // ElMessage({
    //   type: 'info',
    //   message: '当前选择不是指标选项'
    // });
  }
}


// 下拉搜索
const filterMethod = function (val) {
  treeRef.value[0].filter(val)
}
const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}


onMounted(() => {
  // 获取参数列表
  getListFun()
  // 获取参数类型列表
  getParamTypeListFun()
})
</script>

<style lang="less" scoped>
</style>
