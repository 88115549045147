import {api} from "@/api";

export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/getDetail',
        method: 'POST',
        data: data
    })
}