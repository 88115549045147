<template>
  <div class="businessScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="headBox">
          <div class="headItemBox">
            <div class="valueBox">
              <count-up :end-val="data.equipmentTotal" :options="{useGrouping:false}" :start-val="countStart1"
                        class="val"></count-up>
              <div class="bgcList">
                <p v-for="(item,index) in (String(data.equipmentTotal).length)"
                   :key="index"></p>
              </div>
            </div>
          </div>
          <div class="headItemBox">
            <div class="valueBox">
              <count-up :end-val="data.equipmentTotal2" :options="{useGrouping:false}" :start-val="countStart2"
                        class="val"></count-up>
              <div class="bgcList">
                <p v-for="(item,index) in String(data.equipmentTotal).length"
                   :key="index"></p>
              </div>
            </div>
          </div>
          <div class="headItemBox">
            <div class="valueBox">
              <count-up :end-val="data.equipmentTotal3" :options="{useGrouping:false}" :start-val="countStart3"
                        class="val"></count-up>
              <div class="bgcList">
                <p v-for="(item,index) in String(data.equipmentTotal).length"
                   :key="index"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="leftBox">
          <div class="leftItemBox">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/gfTitle.png">
            <div class="cont">
              <p>总发电量(kwh)</p>
              <count-up :end-val="data.equipmentTotal" :start-val="countStart1" class="val"></count-up>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/gfItem1.png">
                  <p>逆变器台数</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">20.57</p>
                  <p class="unit">万台</p>
                </div>
              </div>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/gfItem2.png">
                  <p>总装机容量</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">20.57</p>
                  <p class="unit">kw</p>
                </div>
              </div>
            </div>
          </div>
          <div class="leftItemBox">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/cnTitle.png">
            <div class="cont">
              <p>总放电量(kwh)</p>
              <count-up :end-val="data.equipmentTotal" :start-val="countStart1" class="val"></count-up>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/cnItem1.png">
                  <p>储能台数</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">20.57</p>
                  <p class="unit">万台</p>
                </div>
              </div>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/cnItem2.png">
                  <p>总容量</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">20.57</p>
                  <p class="unit">kw</p>
                </div>
              </div>
            </div>
          </div>
          <div class="leftItemBox">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/dnTitle.png">
            <div class="cont">
              <p>总放电量(kwh)</p>
              <count-up :end-val="data.equipmentTotal" :start-val="countStart1" class="val"></count-up>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/dnItem1.png">
                  <p>设备台数</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">20.57</p>
                  <p class="unit">万台</p>
                </div>
              </div>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/dnItem2.png">
                  <p>总用电量</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">20.57</p>
                  <p class="unit">kw</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightItemBox">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/yqTitle.png">
            <div class="cont cont1">
              <div class="list">
                <div class="listItem">
                  <p>氧气用量</p>
                  <p>150t</p>
                </div>
                <div class="listItem">
                  <p>氮气</p>
                  <p>150t</p>
                </div>
                <div class="listItem">
                  <p>二氧化碳用量</p>
                  <p>150t</p>
                </div>
                <div class="listItem">
                  <p>燃气</p>
                  <p>150t</p>
                </div>
              </div>
            </div>
          </div>
          <div class="rightItemBox">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/ysTitle.png">
            <div class="cont cont2">
              <div class="chart1"></div>
            </div>
          </div>
          <div class="rightItemBox">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/skTitle.png">
            <div class="cont cont3">
              <div class="chart2"></div>
              <div class="list">
                <div class="listItem">
                  <div class="itemL">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/skItem1.png">
                    <p>微信收款</p>
                  </div>
                  <div class="itemR">
                    <div class="val">20.57</div>
                    <div class="unit">亿元</div>
                  </div>
                </div>
                <div class="listItem">
                  <div class="itemL">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/skItem2.png">
                    <p>支付宝收款</p>
                  </div>
                  <div class="itemR">
                    <div class="val">20.57</div>
                    <div class="unit">亿元</div>
                  </div>
                </div>
                <div class="listItem">
                  <div class="itemL">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/skItem3.png">
                    <p>银行卡收款</p>
                  </div>
                  <div class="itemR">
                    <div class="val">20.57</div>
                    <div class="unit">亿元</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="centerBox">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/ywTitle.png">
        </div>
      </div>
    </v-scale-screen>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './businessScreen.less'
import VScaleScreen from 'v-scale-screen'
import CountUp from 'vue-countup-v3'
import * as echarts from "echarts";

let countStart1 = ref(0)
let countStart2 = ref(0)
let countStart3 = ref(0)
let data = ref({
  equipmentTotal: 28087654928,
  equipmentTotal2: 28088888888,
  equipmentTotal3: 28088888888,
})


// 加载柱状图
const chartFun1 = function () {
  var chartDom = document.querySelector('.chart1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: '用电量(t)',
      textStyle: {
        fontSize: 12,
        fontWeight: 300
      }
    },
    grid: {
      left: 40,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      // boundaryGap:false,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        }
      },
      data: ['2024.01', '2024.02', '2024.03', '2024.04', '2024.05', '2024.06']
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#405873'
        }
      },
    },
    series: [
      {
        type: "pictorialBar", //设置类型为象形柱状图
        symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/chart1.png", //图形类型，带圆角的矩形
        barWidth: 26,
        symbolRepeat: false,
        z: 1,
        label: {
          show: true,
          position: 'top',
          color: '#2A6EF3',
          formatter: '{c}' + 't',
        },
        data: [322, 433, 134, 632, 532, 222],
      },
      {
        type: 'line',
        smooth: true,
        data: [462, 573, 274, 772, 672, 362],
        itemStyle: {
          color: '#4E9CFF',
          opacity: 0
        },
        lineStyle: {
          width: 3
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(78,156,255,0.2)'
          },
            {
              offset: 1,
              color: 'rgba(78,156,255,0)'
            },
          ])
        }
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

// 加载饼图
const chartFun2 = function () {
  var chartDom = document.querySelector('.chart2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: -70,
      top: 10,
      bottom: 10,
      right: 10,
    },
    tooltip: {
      // trigger: 'axis', // axis   item   none三个值
      show: true
    },
    series: [
      {
        name: '收款数据',
        type: 'pie',
        radius: ['85%', '100%'],
        padAngle: 2,
        labelLine: {
          show: false
        },
        hoverAnimation: false,
        data: [
          {
            value: 1048, name: '微信收款',
            itemStyle: {
              color: 'rgba(40, 196, 69, 1)',
            },
          },
          {
            value: 735, name: '支付宝收款',
            itemStyle: {
              color: 'rgba(52, 109, 249,1)',
            },
          },
          {
            value: 580, name: '银行卡收款',
            itemStyle: {
              color: 'rgba(6, 145, 253,1)',
            },
          }
        ]
      },
      {
        name: '收款数据',
        type: 'pie',
        radius: ['55%', '85%'],
        padAngle: 2,
        labelLine: {
          show: false
        },
        hoverAnimation: false,
        data: [
          {
            value: 1048, name: '微信收款',
            itemStyle: {
              // color: 'rgba(40, 196, 69, 0.3)',
              color: '#dbf6e7',
            },
          },
          {
            value: 735, name: '支付宝收款',
            itemStyle: {
              // color: 'rgba(52, 109, 249,0.3)',
              color: '#dff1ff',
            },
          },
          {
            value: 580, name: '银行卡收款',
            itemStyle: {
              // color: 'rgba(6, 145, 253,0.3)',
              color: '#e0e8ff',
            },
          }
        ]
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}


onMounted(() => {
  chartFun1()
  chartFun2()
})

</script>

<style lang="less" scoped>
</style>
