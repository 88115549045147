<template>
  <div class="northDetail">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="goBack">北向应用</span>
        <span> / </span>
        <span>应用详情</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        {{ router.currentRoute.value.query.name }} 应用组列表
      </p>
    </div>
    <div class="content">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="数据统计" name=1>
          <div class="cont">
            <div class="detailBox">
              <div class="topBox">
                <p>
                  <span>节点类型: </span>
                  <span>{{ metricsData.node_type === '2' ? '应用' : '驱动' }}</span>
                </p>
                <p>
                  <span>连接状态: </span>
                  <span>{{ metricsData.link_state === '1' ? '连接' : '断开' }}</span>
                </p>
                <p>
                  <span>工作状态: </span>
                  <span :class="metricsData.running_state==='3'  ?'start':'stop'">{{
                      metricsData.running_state === '3' ? '运行中' : '停止'
                    }}</span>
                </p>
              </div>
              <div class="bottomBox">
                <div class="item">
                  <p>{{ metricsData.send_msgs_total }}</p>
                  <p>发送消息数总计</p>
                </div>
                <div class="item">
                  <p>{{ metricsData.send_msg_errors_total }}</p>
                  <p>发送消息失败数总计</p>
                </div>
                <div class="item">
                  <p>{{ metricsData.recv_msgs_total }}</p>
                  <p>下行消息指令数</p>
                </div>
              </div>
            </div>
            <div class="listBox">
              <div v-for="(item,index) in metricsData.list" :key="index" class="listItem">
                <p class="name">{{ item.name }}</p>
                <ul>
                  <li v-for="(item_c,index_c) in item.arr" :key="index_c">
                    <p>{{ item_c.name }}: &nbsp; </p>
                    <p> {{ item_c.val }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="应用配置" name=2>
          <div class="cont2">
            <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
              <el-form-item label="应用名称" prop="node" style="margin-bottom: 20px">
                <el-input v-model="formData.node" disabled placeholder="请输入应用名称"></el-input>
              </el-form-item>
              <!--              <el-form-item label="插件" prop="plugin">-->
              <!--                <el-select v-model="formData.plugin" clearable filterable placeholder="请选择插件" @change="selectChange">-->
              <!--                  <el-option v-for="(item,index) in selectList" :key="index"-->
              <!--                             :label="item.name==='eKuiper'?'ZKData':item.name"-->
              <!--                             :value="item.name"></el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
            </el-form>
            <el-form :inline="true" :model="formData.params" label-width="auto" style="transform: translateY(-16px)">
              <!--接口请求循环出来的表单数据-->
              <el-form-item v-for="(value, key, index) in formObj" :key="index" :prop="key"
                            :rules="[{
                        required: value.attribute==='required'&&(!value.condition||formData.params[value.condition.field]===value.condition.value),
                        message: `${value.type==='string'||value.type==='int'?'请输入':'请选择'}${value.name_zh}`,
                        trigger: 'blur',
                      }]"
                            class="formItem">
                <div v-if="!value.condition||formData.params[value.condition.field]===value.condition.value"
                     slot="label"
                     class="labelBox"
                     style="margin-top: 20px">
                  <i v-if="value.attribute==='required'" style="color: red;">*</i>
                  <p style="margin-left: 5px">{{ value.name_zh }}</p>
                  <el-tooltip
                      :content="value.description_zh"
                      effect="dark"
                      placement="top"
                      popper-class="el_tooltip_item"
                  >
                    <el-icon>
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="widthBox">
                  <!--输入框-->
                  <el-input
                      v-if="value.type==='string'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]" :maxlength="value.valid.length"></el-input>
                  <!--下拉框-->
                  <el-select
                      v-if="value.type==='map'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]" clearable>
                    <el-option v-for="(item,index) in value.valid.map" :key="index" :label="item.key"
                               :value="item.value"></el-option>
                  </el-select>
                  <!--单选框-->
                  <el-radio-group
                      v-if="value.type==='bool'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]">
                    <el-radio :label="true" border>True</el-radio>
                    <el-radio :label="false" border>False</el-radio>
                  </el-radio-group>
                  <!--数字输入框-->
                  <el-input-number
                      v-if="value.type==='int'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]"
                      :controls="false" :max="value.valid.max" :min="value.valid.min"/>
                  <!--上传文件-->
                  <el-upload
                      v-if="value.type==='file'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model:file-list="formData.params[key+'-fileList']" :auto-upload="false"
                      :on-remove="handleRemove($event,key)"
                      action="#" class="upload-demo" limit="1" @change="uploadFile($event,key)">
                    <div class="uploadBox">
                      <div
                          :class="formData.params[key+'-fileList']&&formData.params[key+'-fileList'].length>0?'none':''"
                          class="upBtnBox">
                        <el-icon>
                          <Document/>
                        </el-icon>
                        <span>点击上传</span>
                      </div>
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
            </el-form>
            <div class="footer">
              <el-button class="cancle resetButton" @click="goBack">取消</el-button>
              <el-button class="confrim" type="primary" @click="save()">保存</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, onUnmounted, reactive, ref} from "vue"
import './northDetail.less'
import {useRouter} from "vue-router/dist/vue-router";
import {Back, QuestionFilled} from '@element-plus/icons-vue'
import {
  getFormDataApi,
  getMetricsApi, getSelectListApi,
  getSettingApi, saveSettingApi
} from "@/api/modules/northDriver";
import {ElLoading, ElMessage} from "element-plus";

let router = useRouter()
// 返回
const goBack = function () {
  router.push('/admin/northDriver')
}

// tab栏选中
let activeName = ref(router.currentRoute.value.query.type)

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

// 获取数据统计
let metricsData = ref({})
const getMetricsFun = function () {
  // openFullScreen()
  getMetricsApi({
    category: 'app',
    node: router.currentRoute.value.query.name
  }).then(res => {
    // loadingAdd.value.close()
    if (res.status === 200) {
      const regex = /\r\n|\n|\r/g;
      let arr = res.data.split(regex)
      let newArr = {}
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][0] !== '#') {
          let valIndex = arr[i].indexOf('}')
          let keyIndex = arr[i].indexOf('{')
          newArr[arr[i].slice(0, keyIndex)] = arr[i].slice(valIndex + 2)
        }
      }
      let newArr2 = {
        list: []
      }
      for (const newArrKey in newArr) {
        if (newArrKey.slice(0, 4) === 'last') {
          let key = newArrKey.slice(0, newArrKey.slice(5).indexOf('_') + 5)
          let flag = false
          for (let i = 0; i < newArr2.list.length; i++) {
            if (key === newArr2.list[i].name) {
              newArr2.list[i].arr.push({
                name: newArrKey.slice(newArrKey.slice(5).indexOf('_') + 6),
                val: newArr[newArrKey]
              })
              flag = true
            }
          }
          if (!flag) {
            newArr2.list.push({
              name: key,
              arr: [{
                name: newArrKey.slice(newArrKey.slice(5).indexOf('_') + 6),
                val: newArr[newArrKey]
              }]
            })
          }
        } else {
          newArr2[newArrKey] = newArr[newArrKey]
        }
      }
      metricsData.value = newArr2
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    // loadingAdd.value.close()
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}


// 添加编辑应用*****************************************************************************
// 获取应用配置
const getSettingFun = function () {
  getSettingApi({
    node: router.currentRoute.value.query.name
  }).then(res => {
    if (res.status === 200) {
      formData.value.params = res.data.params
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
// 应用表单数据
let formData = ref({
  node: router.currentRoute.value.query.name,
  params: {}
})
let formRules = ref({
  node: [{
    required: true,
    message: '请输入应用名称',
    trigger: 'change',
  }]
})
// 插件选择事件
let formObj = ref({})
const selectChange = async function () {
  await getFormDataApi({
    schema_name: router.currentRoute.value.query.plugin === 'ZKData' ? 'ekuiper' : router.currentRoute.value.query.plugin.split(" ")[0].toLowerCase()
  }).then(res => {
    if (res.status === 200) {
      formData.value = {
        node: formData.value.node,
        params: {}
      }
      formObj.value = res.data
      let num = 0
      for (const key in formObj.value) {
        formData.value.params[key] = formObj.value[key].default
        if (formObj.value[key].type === 'file') {
          let fileList = key + '-fileList'
          formData.value.params[fileList] = []
          num++
        }
      }
      console.log(formData.value)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
// 文件上传
const uploadFile = function (file, key) {
  const reader = new FileReader();
  reader.readAsDataURL(file.raw);
  reader.onload = (e) => {
    formData.value.params[key] = reader.result.split(",")[1]
  };
}
// 文件删除
const handleRemove = function (file, key) {
  formData.value.params[key] = ''
  console.log(file, key, formData.value.params[key])
}

// 提交应用表单
let userForm = ref()
const save = function () {
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      saveSettingApi(formData.value).then(res => {
        if (res.status === 200) {
          router.push('/admin/northDriver')
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}

// 下拉列表数据
let selectList = ref([])

onMounted(async () => {
  getMetricsFun()
  // 获取下拉列表数据
  getSelectListApi().then(res => {
    if (res.status === 200) {
      for (let i = 0; i < res.data.plugins.length; i++) {
        if (res.data.plugins[i].node_type === 2) {
          selectList.value.push(res.data.plugins[i])
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
  await selectChange()
  getSettingFun()
})
// 定时器
const intervalId = setInterval(getMetricsFun, 5000)
onUnmounted(() => {
  clearInterval(intervalId)
})

</script>

<style lang="less" scoped>
</style>
