<template>
  <div class="executionLog">
    <div class="title">
      <p>
        <span>日志管理</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <el-select v-model="deviceId" clearable placeholder="请选择设备" style="width:100%" @change="equipmentSelect">
            <el-option v-for="item in equipmentList" :key="item.id" :label="item.name" :value="item.deviceId"
                       style="width:100%">
            </el-option>
          </el-select>
        </div>
        <div class="liBox">
          <el-select v-model="controlId" clearable placeholder="请选择控制" style="width:100%">
            <el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id"
                       style="width:100%">
            </el-option>
          </el-select>
        </div>
        <div class="liBox">
          <el-date-picker v-model="timeList" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→" start-placeholder="开始时间" type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss"
                          @change="timeChange">
          </el-date-picker>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--参数列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="设备名称" prop="deviceName" width="110">
          </el-table-column>
          <el-table-column label="控制名称" prop="controlName" width="110">
          </el-table-column>
          <el-table-column label="执行时间" prop="executeTime" width="170">
          </el-table-column>
          <el-table-column label="备注" prop="memo" width="200">
          </el-table-column>
          <el-table-column label="控制参数" min-width="10%" prop="controlParamList">
            <template #default="scope">
              <div v-for='(item,index) in scope.row.controlParamList' :key="index">
                <p>{{ item }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="相关参考指标值" min-width="10%" prop="targetList">
            <template #default="scope">
              <div v-for='(item,index) in scope.row.targetList' :key="index">
                <p>{{ item }}</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,prev, pager, next,sizes" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './executionLog.less'

import {CirclePlus, CircleClose, Plus, Search} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {
  getEquipmentSelectApi, getProductControlApi,
  getRunResultListApi,
} from "@/api/modules/strategy";
import qs from "qs";

let router = useRouter()
// 运行结果列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 运行结果表格数据
let tableData = ref([])
// 获取运行结果列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getRunResultListApi(qs.stringify({
    pageSize: pageSize.value,
    pageNum: pageNumber.value,
    deviceId: deviceId.value,
    controlId: controlId.value,
    startTime: startTime.value,
    endTime: endTime.value,
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 搜索
let equipmentList = ref([]) // 设备选项列表
let productList = ref([])   //产品选项列表
let deviceId = ref('')
let controlId = ref('')
let timeList = ref([])
let startTime = ref('')
let endTime = ref('')
const searchFun = function () {
  getListFun()
}
const resetFun = function () {
  pageSize.value = 20
  pageNumber.value = 1
  deviceId.value = ''
  controlId.value = ''
  timeList.value = []
  timeChange()
  getListFun()
}


// 获取设备选项
const getEquipmentList = function () {
  getEquipmentSelectApi().then(res => {
    if (res.data.ok) {
      equipmentList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err)
  })
}
// 获取产品控制选项
const getProductList = function (productId) {
  getProductControlApi(qs.stringify({
    productId: productId,
  })).then(res => {
    if (res.data.ok) {
      productList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err)
  })
}
// 设备选择事件
const equipmentSelect = function (e) {
  for (let i = 0; i < equipmentList.value.length; i++) {
    if (equipmentList.value[i].deviceId === e) {
      getProductList(equipmentList.value[i].productId)
    }
  }
}
// 时间选择事件
const timeChange = function () {
  if (timeList.value && timeList.value.length) {
    startTime.value = timeList.value[0]
    endTime.value = timeList.value[1]
  } else {
    startTime.value = ''
    endTime.value = ''
  }
}

onMounted(() => {
  getListFun()
  getEquipmentList()
})
</script>

<style lang="less" scoped>
</style>
