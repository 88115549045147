<template>
  <div class="onceImgPage">
    <div class="bgcImg" @click="clickFun">
      <img alt="" class="bgc" src="./img.png">
      <img :class="flag?'active item':'item'" alt="" src="./img_1.png">
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref} from "vue"
import VScaleScreen from 'v-scale-screen'

let flag = ref(false)
const clickFun = function () {
  flag.value = !flag.value
}

onMounted(() => {
  nextTick(() => {
    let ele = document.querySelector('.onceImgPage')
    let scale = (ele.clientWidth) / 1321
    document.querySelector('.bgcImg').style.transform = `scale(${scale})`
  })
})
</script>

<style lang="less" scoped>
.onceImgPage {
  width: 100%;
  height: 100%;

  .bgcImg {
    width: 1321px;
    height: 822px;
    transform-origin: 0% 0%;
    position: relative;

    .bgc {
      width: 100%;
      height: 100%;
      transform: scale();
    }

    .item {
      position: absolute;
      width: 41px;
      height: 32px;
      left: 376px;
      top: 258px;
    }

    .active.item {
      transform: rotate(-35deg);
      left: 369px;
      top: 262px;
    }
  }
}
</style>
