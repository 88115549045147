<template>
  <div class="task">
    <div class="content">
      <div class="tableBox">
        <div class="operationBox">
          <el-button v-if="props.type!=1" :icon="Plus" type="primary" @click="organizationTaskFun()">新增任务</el-button>
          <el-button v-if="props.type!=1" type="primary" @click="readTaskFun">读取任务</el-button>
          <el-button v-if="props.type!=1" type="primary" @click="emptyTaskFun()">清空任务</el-button>
          <el-button v-if="props.type!=1" type="primary" @click="issueTaskFun()">下发任务</el-button>
          <el-button v-if="props.type==1" type="primary" @click="setTaskFun()">设置任务</el-button>
        </div>
        <el-table v-loading="taskLoading" :data="taskData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建任务信息</p>
            </el-empty>
          </template>
          <el-table-column label="任务ID" min-width="3%" prop="taskId"/>
          <el-table-column label="时间间隔" min-width="3%" prop="step"/>
          <el-table-column label="状态" min-width="3%" prop="statusStr"/>
          <el-table-column label="开始时间" min-width="4%" prop="startTime" sortable/>
          <el-table-column label="结束时间" min-width="4%" prop="endTime" sortable/>
          <el-table-column label="方案编号" min-width="3%" prop="planCaseNo"/>
          <el-table-column label="延时" min-width="3%" prop="delay"/>
          <el-table-column label="操作" min-width="3%" prop="">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="editTaskFun(scope.row.id)"><img alt=""
                                                                              src="../../../assets/img/161@2x.png"/>
                </li>
                <li class="editClass" @click="delTaskFun(scope.row.id)"><img alt=""
                                                                             src="../../../assets/img/163@2x.png"/></li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small"
                         :total="total" background layout="total, sizes, prev, pager, next" small
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!--读取任务-->
    <el-dialog v-model="readTaskAlert" :close-on-click-modal="false" class="readTaskAlert" title="读取任务" width="80%">
      <div class="tableBox" style="padding: 0;">
        <el-table v-loading="readLoading" :data="readData" style="width: 100%" table-layout="auto">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="上报时间" min-width="1%" prop="createTime">
          </el-table-column>
          <el-table-column label="上报内容" min-width="3%" prop="content">
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" min-width="1%" width="100px">
            <template #default="scope">
              <ul>
                <li class="editClass" @click="analysisClick(scope.row.id)">解析</li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="confrim" type="primary" @click="readTaskAlert=false">确认</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--解析弹窗-->
    <el-dialog v-model="analysisAlert" :close-on-click-modal="false" class="analysisAlert" title="任务解析" width="80%">
      <div class="tableBox" style="padding: 0;">
        <!--任务解析-->
        <el-table v-loading="analysisDataLoading" :data="analysisList" style="width: 100%" table-layout="auto">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="任务id" prop="taskId" width="130">
          </el-table-column>
          <el-table-column label="方案编号" prop="caseNo" width="130">
          </el-table-column>
          <el-table-column label="执行间隔" prop="step" width="130">
          </el-table-column>
          <el-table-column label="状态" prop="statusStr" width="130">
          </el-table-column>
          <el-table-column label="方案类型" prop="caseTypeStr" width="130">
          </el-table-column>
          <el-table-column label="开始时间" prop="startTime" width="130">
          </el-table-column>
          <el-table-column label="结束时间" prop="endTime" width="130">
          </el-table-column>
          <el-table-column label="延时" prop="delay" width="130">
          </el-table-column>
          <el-table-column label="任务运行时段" prop="timeType" width="130">
          </el-table-column>
          <el-table-column label="开始脚本ID" prop="preId" width="130">
          </el-table-column>
          <el-table-column label="完成脚本ID" prop="sufId" width="130">
          </el-table-column>
          <el-table-column label="起始小时" prop="timeStartHour" width="130">
          </el-table-column>
          <el-table-column label="起始分钟" prop="timeStartMinute" width="130">
          </el-table-column>
          <el-table-column label="结束小时" prop="timeEndHour" width="130">
          </el-table-column>
          <el-table-column label="结束分钟" prop="timeEndMinute" width="130">
          </el-table-column>
          <el-table-column label="执行优先等级" prop="priority" width="130">
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="confrim" type="primary" @click="analysisAlert=false">确认</el-button>
      			</span>
      </template>
      <div slot="footer" class="dialog-footer">

      </div>
    </el-dialog>
    <!--新增任务弹窗-->
    <el-dialog v-model="organizationTaskAlert" :close-on-click-modal="false" :title="title"
               class="organizationTaskAlert" width="80%">
      <el-form ref="organizationTaskRef" :model="organizationTask" :rules="organizationTaskRules" label-width="auto">
        <div style="display: flex; justify-content: space-between;">
          <div style="width:47%">
            <el-form-item label="执行间隔" prop="step">
              <div style="display: inline-block;width:49%;">
                <el-input v-model="organizationTask.step" autocomplete="off" placeholder="时间" type="number"></el-input>
              </div>
              <div style="display: inline-block;width:49%;">
                <el-select v-model="organizationTask.stepType" placeholder="单位" style="width:100%">
                  <el-option v-for="item in timeUnitTypeList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="任务ID" prop="taskId">
              <el-input v-model="organizationTask.taskId" autocomplete="off" placeholder="单行输入"
                        type="number"></el-input>
            </el-form-item>
            <el-form-item label="方案编号" prop="planId">
              <el-select v-model="organizationTask.planId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in palnData" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="采集方案" prop="caseType">
              <el-select v-model="organizationTask.caseType" placeholder="请选择" style="width:100%">
                <el-option v-for="item in caseTypeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker v-model="organizationTask.startTime" placeholder="YYYY-MM-DD hh:mm:ss" style="width:100%"
                              suffix-icon="close" type="date" value-format="YYYY-MM-DD">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker v-model="organizationTask.endTime" placeholder="YYYY-MM-DD hh:mm:ss" style="width:100%"
                              suffix-icon="close" type="date" value-format="YYYY-MM-DD">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="延时" prop="delay">
              <div style="display: inline-block;width:49%;">
                <el-input v-model="organizationTask.delay" autocomplete="off" placeholder="时间" type="number"></el-input>
              </div>
              <div style="display: inline-block;width:49%;">
                <el-select v-model="organizationTask.delayType" placeholder="单位" style="width:100%">
                  <el-option v-for="item in timeUnitTypeList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="任务运行时间段"></el-form-item>
            <el-form-item label="类型" prop="timeType">
              <el-select v-model="organizationTask.timeType" placeholder="请选择" style="width:100%">
                <el-option v-for="item in timeTypeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="width:47%">
            <el-form-item label="执行优先等级" prop="priority">
              <el-input v-model="organizationTask.priority" autocomplete="off" placeholder="单行输入"
                        type="number"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-radio v-model="organizationTask.status" label="正常">正常（1）</el-radio>
              <el-radio v-model="organizationTask.status" label="停用">停用（2）</el-radio>
            </el-form-item>
            <el-form-item label="任务开始前脚本id" prop="preId">
              <el-input v-model="organizationTask.preId" autocomplete="off" placeholder="单行输入" type="number"></el-input>
            </el-form-item>
            <el-form-item label="任务开始后脚本id" prop="sufId">
              <el-input v-model="organizationTask.sufId" autocomplete="off" placeholder="单行输入" type="number"></el-input>
            </el-form-item>
            <el-form-item label="">

            </el-form-item>
            <el-form-item label="">

            </el-form-item>
            <el-form-item label="">

            </el-form-item>
            <el-form-item label="">

            </el-form-item>
            <el-form-item label="时段">

            </el-form-item>
            <el-form-item label="起始小时" prop="timeStartHour">
              <el-input v-model="organizationTask.timeStartHour" autocomplete="off" placeholder="单行输入"
                        type="number"></el-input>
            </el-form-item>
            <el-form-item label="起始分钟" prop="timeStartMinute">
              <el-input v-model="organizationTask.timeStartMinute" autocomplete="off" placeholder="单行输入"
                        type="number"></el-input>
            </el-form-item>
            <el-form-item label="结束小时" prop="timeEndHour">
              <el-input v-model="organizationTask.timeEndHour" autocomplete="off" placeholder="单行输入"
                        type="number"></el-input>
            </el-form-item>
            <el-form-item label="结束分钟" prop="timeEndMinute">
              <el-input v-model="organizationTask.timeEndMinute" autocomplete="off" placeholder="单行输入"
                        type="number"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="organizationTaskAlert=false">取消</el-button>
		    		<el-button type="primary" @click="taskSave">确认</el-button>
      			</span>
      </template>
    </el-dialog>
    <div v-if="progressShow" class="progressPop">
      <Progress></Progress>
    </div>
    <!--设置任务弹窗-->
    <el-dialog v-model="setTaskShow" :close-on-click-modal="false" class="organizationTaskAlert" title="设置任务"
               width="40%">
      <el-form ref="setTaskRef" :model="setTaskData" :rules="setTaskRules" label-width="auto">
        <el-form-item label="定时上报周期" prop="intervalValue">
          <el-input v-model="setTaskData.intervalValue" placeholder="请输入定时上报周期" type="number"></el-input>
        </el-form-item>
        <el-form-item label="上报基准时间" prop="baseTime">
          <el-input v-model="setTaskData.baseTime" placeholder="请输入上报基准时间"></el-input>
        </el-form-item>
        <el-form-item label="数据抽取倍率" prop="extractionRate">
          <el-input v-model="setTaskData.extractionRate" placeholder="请输入数据抽取倍率" type="number"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="setTaskShow=false">取消</el-button>
		    		<el-button type="primary" @click="setTaskSubmit">确认</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus, Minus} from '@element-plus/icons-vue'
import {
  getList,
  getPlanList,
  getSelectList,
  saveList,
  getDetail,
  deleteList,
  downList,
  cleanList,
  readList,
  readResultList,
  readParseList,
  setTaskApi
} from '../../../api/modules/task'
import './task.less'
import Progress from '../../../components/progress.vue'
import qs from 'qs';
import router from "@/router";
// 定义传值类型
const props = defineProps({
  value: String, //这里就接收到父组件传递的value
  type: String, //这里就接收到父组件传递的value
})

// 分页 **********************************************************
// 当前页数
let pageIndex = ref(1)
// 每页条数
let pageSize = ref(20)
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageIndex.value = val;
  getListFun();
}
// 获取总条数
const total = ref(0)
// 获取列表
let taskLoading = ref(false)
let taskData = ref([])
const getListFun = function () {
  taskLoading.value = true
  getList(qs.stringify({
    pageSize: pageSize.value,
    pageNumber: pageIndex.value,
    gatewayId: props.value
  })).then(res => {
    //console.log(res);
    taskLoading.value = false
    if (res.data.ok) {
      taskData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//获取所有select
const getSelectListFun = function () {
  getSelectList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      caseTypeList.value = res.data.result.caseTypeList
      //statusList.value = res.data.result.statusList
      timeTypeList.value = res.data.result.timeTypeList
      timeUnitTypeList.value = res.data.result.timeUnitTypeList
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//全部方案列表
let palnData = ref([])
const getPlanListFun = function () {
  getPlanList(qs.stringify({
    gatewayId: props.value
  })).then(res => {
    //console.log(res)
    if (res.data.ok) {
      palnData.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {

  })
}

// 读取任务***********************************************************
// 读取任务弹窗----------------------------------------------
let readTaskAlert = ref(false)
let readLoading = ref(false)
// 读取任务列表
// 点击读取任务
const readTaskFun = function () {
  analysisList.value = []
  readData.value = []
  progressShow.value = true
  readTaskAlert.value = true
  readList(qs.stringify({
    gatewayId: props.value
  })).then(async res => {
    //console.log(res);
    if (res.data.ok) {
      if (res.data.result) {
        await readResultListFun(res.data.result);
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message + '!'
      });
      progressShow.value = false
    }
  }).catch(err => {
    console.log(err);
  })
}
//任务读取结果
const readResultListFun = function (id) {
  let num = 0
  let timer = setInterval(() => {
    num++
    readResultList(qs.stringify({
      commandLogId: id
    })).then(res => {
      //console.log(num)
      //console.log(res);
      if (res.data.ok) {
        if (res.data.result.length > 0) {
          progressShow.value = false
          readData.value = res.data.result
          ElMessage({
            type: 'success',
            message: '读取任务成功!'
          });
        }
        if (num > 9 || res.data.result.length > 0) {
          clearInterval(timer)
        }
        if (num > 9) {
          progressShow.value = false
          ElMessage({
            type: 'error',
            message: '读取任务失败!'
          });
        }
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }, 1000)
}

let readData = ref([])
const readParseListFun = function (id) {
  analysisDataLoading.value = true
  readParseList(qs.stringify({
    commandLogId: id
  })).then(res => {
    //console.log(res);
    analysisDataLoading.value = false
    if (res.data.ok) {
      analysisList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
/*加载loading*/
const loadingVal = ref()
const progressShow = ref(false)
const openFullScreen = () => {
  loadingVal.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}

// 解析弹窗----------------------------------------------
let analysisAlert = ref(false)
// 任务解析列表
let analysisDataLoading = ref(false)
let analysisList = ref([])
// 点击查看解析
const analysisClick = function (id) {
  analysisAlert.value = true
  readParseListFun(id)
}

// 清空任务***********************************************************
const emptyTaskFun = function () {
  ElMessageBox.confirm("确认清空此网关的任务吗？", '清空任务', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'success',
  }).then(() => {
    //openFullScreen()
    progressShow.value = true
    cleanList(qs.stringify({
      gatewayId: props.value
    })).then(res => {
      //console.log(res);
      //loadingVal.value.close()
      progressShow.value = false
      if (res.data.ok) {
        ElMessage({
          message: '任务清空成功！',
          type: 'success',
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message + '!'
        });
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消清空任务!'
    });
  });
}

// 下发任务***********************************************************
const issueTaskFun = function () {
  ElMessageBox.confirm("确认下发此网关的任务吗？", '下发任务', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'success'
  }).then(() => {
    //openFullScreen()
    progressShow.value = true
    downList(qs.stringify({
      gatewayId: props.value
    })).then(res => {
      //console.log(res);
      //loadingVal.value.close()
      progressShow.value = false
      if (res.data.ok) {
        ElMessage({
          message: '任务下发成功！',
          type: 'success',
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message + '!'
        });
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消下装任务!'
    });
  });
}

// 设置任务----------------------------------------------------------------------------------------
let setTaskShow = ref(false)
let setTaskRef = ref()
// 设置任务表单数据
let setTaskData = ref({
  gatewayId: router.currentRoute.value.query.id,
  baseTime: '',
  intervalValue: '',
  extractionRate: '',
})
let setTaskRules = ref({
  baseTime: [{
    required: true,
    message: '请输入上报基准时间',
    trigger: 'blur'
  }],
  intervalValue: [{
    required: true,
    message: '请输入定时上报周期',
    trigger: 'blur'
  }],
  extractionRate: [{
    required: true,
    message: '请输入数据抽取倍率',
    trigger: 'blur'
  }],
})
// 设置任务
const setTaskFun = function () {
  setTaskShow.value = true
}
// 设置任务提交
const setTaskSubmit = function () {
  setTaskApi(setTaskData.value).then(res => {
    setTaskShow.value = false
    if (res.data.code === 0) {
      ElMessage({
        message: '设置任务成功！',
        type: 'success',
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

// 组织任务***********************************************************
// 组织任务弹窗
let organizationTaskAlert = ref(false)
// 时间选择列表
let timeUnitTypeList = ref([])
// 采集方案列表
let caseTypeList = ref([])
// 任务运行时间段
let timeTypeList = ref([])
// 组织任务表单
let organizationTask = ref({
  step: 30,
  stepType: '1',
  taskId: 1,
  planId: '',
  caseType: '1',
  startTime: '2001-01-01 12:00:00',
  endTime: '2099-01-01 12:00:00',
  delay: 0,
  delayType: '0',
  timeType: '0',
  priority: 0,
  status: '正常',
  preId: 0,
  sufId: 0,
  timeStartHour: 0,
  timeStartMinute: 0,
  timeEndHour: 0,
  timeEndMinute: 0,
})
let title = ref() //弹窗标题
let organizationTaskRef = ref() //任务
//任务验证规则
let organizationTaskRules = ref({
  step: [{
    required: true,
    message: '请输入时间间隔数值',
    trigger: 'blur'
  }],
  stepType: [{
    required: true,
    message: '请输入时间间隔单位',
    trigger: 'blur'
  }],
  taskId: [{
    required: true,
    message: '请输入任务ID',
    trigger: 'blur'
  }],
  planId: [{
    required: true,
    message: '请选择方案编号',
    trigger: 'blur'
  }],
  caseType: [{
    required: true,
    message: '请选择方案类型',
    trigger: 'blur'
  }],
  startTime: [{
    required: true,
    message: '请选择开始时间',
    trigger: 'blur'
  }],
  endTime: [{
    required: true,
    message: '请选择结束时间',
    trigger: 'blur'
  }],
  delay: [{
    required: true,
    message: '请输入延时间隔数值',
    trigger: 'blur'
  }],
  delayType: [{
    required: true,
    message: '请输入延时间隔单位',
    trigger: 'blur'
  }],
  timeType: [{
    required: true,
    message: '请选择任务运行时段类型',
    trigger: 'blur'
  }],
  priority: [{
    required: true,
    message: '请输入执行优先级',
    trigger: 'blur'
  }],
  status: [{
    required: true,
    message: '请选择任务状态',
    trigger: 'blur'
  }],
  preId: [{
    required: true,
    message: '请输入任务开始前脚本ID',
    trigger: 'blur'
  }],
  sufId: [{
    required: true,
    message: '请输入任务完成后脚本ID',
    trigger: 'blur'
  }],
  timeStartHour: [{
    required: true,
    message: '请输入起始小时',
    trigger: 'blur'
  }],
  timeStartMinute: [{
    required: true,
    message: '请输入起始分钟',
    trigger: 'blur'
  }],
  timeEndHour: [{
    required: true,
    message: '请输入结束小时',
    trigger: 'blur'
  }],
  timeEndMinute: [{
    required: true,
    message: '请输入结束分钟',
    trigger: 'blur'
  }],
})
// 点击组织任务
const organizationTaskFun = function () {
  title.value = "新增任务"
  organizationTask.value = {
    step: 30,
    stepType: '1',
    taskId: 1,
    planId: '',
    caseType: '1',
    startTime: '2001-01-01 12:00:00',
    endTime: '2099-01-01 12:00:00',
    delay: 0,
    delayType: '0',
    timeType: '0',
    priority: 0,
    status: '正常',
    preId: 0,
    sufId: 0,
    timeStartHour: 0,
    timeStartMinute: 0,
    timeEndHour: 0,
    timeEndMinute: 0,
  }
  edit_Id.value = ''
  organizationTaskAlert.value = true
}
// 点击编辑组织任务
let edit_Id = ref()
const editTaskFun = function (id) {
  title.value = "组织任务"
  edit_Id.value = id
  organizationTaskAlert.value = true
  //todo时间返回格式不对
  getDetail(qs.stringify({
    id: id
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      organizationTask.value.planId = res.data.result.planId
      organizationTask.value.taskId = res.data.result.taskId
      organizationTask.value.stepType = String(res.data.result.stepType)
      organizationTask.value.step = res.data.result.step
      organizationTask.value.delay = res.data.result.delay
      organizationTask.value.startTime = res.data.result.startTime
      organizationTask.value.endTime = res.data.result.endTime
      organizationTask.value.delayType = String(res.data.result.delayType)
      organizationTask.value.delay = res.data.result.delay
      if (res.data.result.status == 1) {
        organizationTask.value.status = '正常'
      } else if (res.data.result.status == 2) {
        organizationTask.value.status = '停用'
      }
      organizationTask.value.preId = res.data.result.preId
      organizationTask.value.sufId = res.data.result.sufId
      organizationTask.value.timeType = String(res.data.result.timeType)
      organizationTask.value.timeStartMinute = res.data.result.timeStartMinute
      organizationTask.value.timeStartHour = res.data.result.timeStartHour
      organizationTask.value.timeEndHour = res.data.result.timeEndHour
      organizationTask.value.timeEndMinute = res.data.result.timeEndMinute
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击建立任务
const taskSave = function () {
  var status;
  if (organizationTask.value.status == '停用') {
    status = 2
  } else if (organizationTask.value.status == '正常') {
    status = 1
  }
  if (edit_Id.value) { //编辑
    var params = {
      id: edit_Id.value,
      gatewayId: props.value,
      planId: organizationTask.value.planId, //所属方案
      taskId: organizationTask.value.taskId, //任务ID
      stepType: organizationTask.value.stepType, //时间间隔单位
      step: organizationTask.value.step, //时间间隔数值
      caseType: organizationTask.value.caseType, //方案类型
      startTime: organizationTask.value.startTime, //开始时间
      endTime: organizationTask.value.endTime, //结束时间
      delayType: Number(organizationTask.value.delayType), //延时间隔单位
      delay: organizationTask.value.delay, //延时间隔数值
      priority: organizationTask.value.priority, //执行优先级
      status: status, //任务状态
      preId: organizationTask.value.preId, //任务开始前脚本ID
      sufId: organizationTask.value.sufId, //任务完成后脚本ID
      timeType: organizationTask.value.timeType, //任务运行时段类型
      timeStartHour: organizationTask.value.timeStartHour, //起始小时
      timeStartMinute: organizationTask.value.timeStartMinute, //起始分钟
      timeEndHour: organizationTask.value.timeEndHour, //结束小时
      timeEndMinute: organizationTask.value.timeEndMinute, //结束分钟
    }
  } else { //添加
    var params = {
      id: 0,
      gatewayId: props.value,
      planId: organizationTask.value.planId, //所属方案
      taskId: organizationTask.value.taskId, //任务ID
      stepType: organizationTask.value.stepType, //时间间隔单位
      step: organizationTask.value.step, //时间间隔数值
      caseType: organizationTask.value.caseType, //方案类型
      startTime: organizationTask.value.startTime, //开始时间
      endTime: organizationTask.value.endTime, //结束时间
      delayType: Number(organizationTask.value.delayType), //延时间隔单位
      delay: organizationTask.value.delay, //延时间隔数值
      priority: organizationTask.value.priority, //执行优先级
      status: status, //任务状态
      preId: organizationTask.value.preId, //任务开始前脚本ID
      sufId: organizationTask.value.sufId, //任务完成后脚本ID
      timeType: organizationTask.value.timeType, //任务运行时段类型
      timeStartHour: organizationTask.value.timeStartHour, //起始小时
      timeStartMinute: organizationTask.value.timeStartMinute, //起始分钟
      timeEndHour: organizationTask.value.timeEndHour, //结束小时
      timeEndMinute: organizationTask.value.timeEndMinute, //结束分钟
    }
  }
  //console.log(params)
  if (organizationTaskRef) {
    organizationTaskRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveList(qs.stringify(params)).then(res => {
          //console.log(res);
          if (res.data.ok) {
            getListFun()
            organizationTaskAlert.value = false
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}

const delTaskFun = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除任务',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此任务，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
watch(() => props.value, (newValue, oldValue) => {
  getListFun()
  getPlanListFun()
  getSelectListFun()
}, {
  immediate: true
})
</script>

<style>

</style>