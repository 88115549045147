import {api3} from "@/api";
//获取配置资源列表
export const configureResources = () => {
    return api3({
        url: '/api/ekuiper/metadata/resources',
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })
}
//获取文件管理列表
export const fileManage = () => {
    return api3({
        url: '/api/ekuiper/config/uploads',
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })
}
//获取protobuf模式列表
export const getProtobufModesApi = () => {
    return api3({
        url: '/api/ekuiper/schemas/protobuf',
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })
}
//获取custom模式列表
export const getCustomModesApi = () => {
    return api3({
        url: '/api/ekuiper/schemas/custom',
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })
}
//获取单个protobuf模式
export const getProtobufOneApi = (name) => {
    return api3({
        url: `/api/ekuiper/schemas/protobuf/${name}`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })
}
//获取单个custom模式
export const getCustomOneApi = (name) => {
    return api3({
        url: `/api/ekuiper/schemas/custom/${name}`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })
}
//新建protobuf模式列表
export const addProtobufModesApi = (data) => {
    return api3({
        url: '/api/ekuiper/schemas/protobuf',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: data
    })
}
//新建custom模式列表
export const addCustomModesApi = (data) => {
    return api3({
        url: '/api/ekuiper/schemas/custom',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: data
    })
}
//编辑protobuf模式列表
export const editProtobufModesApi = (name, data) => {
    return api3({
        url: `/api/ekuiper/schemas/protobuf/${name}`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: data
    })
}
//编辑custom模式列表
export const editCustomModesApi = (name, data) => {
    return api3({
        url: `/api/ekuiper/schemas/custom/${name}`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: data
    })
}
// 删除
export const delProtobufModesApi = (name) => {
    return api3({
        url: `/api/ekuiper/schemas/protobuf/${name}`,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
    })
}
export const delCustomModesApi = (name) => {
    return api3({
        url: `/api/ekuiper/schemas/custom/${name}`,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
    })
}
// 上传模式文件
export const uploadModeFileApi = (data) => {
    return api3({
        url: '/api/ekuiper/config/uploads',
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: data
    })
}

//删除配置资源
export const deleteConfigure = (type, name) => {
    return api3({
        url: `/api/ekuiper/metadata/sources/${type}/confKeys/${name}`,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'}
    })
}


// 文件管理------------------------------------------------------------------------
// 获取文件管理列表
export const getFileConfigApi = () => {
    return api3({
        url: '/api/ekuiper/config/uploads',
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })
}
// 新增文件
export const addFileConfigApi = (data) => {
    return api3({
        url: '/api/ekuiper/config/uploads',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: data
    })
}

// 删除文件
export const delFileConfigApi = (name) => {
    return api3({
        url: `/api/ekuiper/config/uploads/${name}`,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'}
    })
}
