<template>
  <div class="subscription">
    <div class="title">
      <p>
        <span>订阅管理</span>
      </p>
    </div>
    <!--列表-->
    <div class="content">
      <div class="contain">
        <!--新增订阅-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增订阅
        </el-button>
        <!--订阅列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="订阅名称" prop="topicName"></el-table-column>
          <el-table-column label="开始时间" prop="startTime"></el-table-column>
          <el-table-column label="结束时间" prop="endTime"></el-table-column>
          <!--          <el-table-column label="频率" prop="frequency"></el-table-column>-->
          <el-table-column label="备注" prop="remark"></el-table-column>
          <el-table-column fixed="right" label="操作" width="120px">
            <template #default="scope">
              <div class="opacity">
                <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span class="editClass delBtn" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>
                <!--                <img alt="" src="../../assets/img/161@2x.png" @click.prevent="editClick(scope.row.id,scope.row)">-->
                <!--                <img alt="" src="../../assets/img/163@2x.png" @click.prevent="delClick(scope.row.id,scope.row)">-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑订阅':'新建订阅'"
               width="50%">
      <el-form ref="fromRef" :inline="true" :model="formData" :rules="formRules">
        <el-form-item label="订阅名称:" prop="topicName">
          <el-input v-model="formData.topicName" placeholder="请输入查询名称"></el-input>
        </el-form-item>
        <el-form-item label="开始时间:" prop="startTime">
          <el-date-picker
              v-model="formData.startTime"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="结束时间:" prop="endTime">
          <el-date-picker
              v-model="formData.endTime"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
        <!--        <el-form-item label="频率:" prop="frequency">-->
        <!--          <el-input v-model="formData.frequency" placeholder="请输入频率" type="number"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item style="margin-left: 38px" label="备注:" prop="remark">
          <el-input style="width: 82.5%" v-model="formData.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <div v-for="(item,index) in formData.params" :key="index" class="rowItem">
          <el-form-item label="设备" name="item.deviceId">
            <!--            <el-select v-model="item.deviceId" placeholder="请选择设备" @change="equipmentChange(index)">-->
            <!--              <el-option v-for="(item_d,index_d) in equipmentList" :key="index_d" :label="item_d.name"-->
            <!--                         :value="item_d.id"></el-option>-->
            <!--            </el-select>-->
            <el-tree-select
                ref="treeRef1"
                v-model="item.deviceId"
                :data="allTree"
                :props="props"
                :render-after-expand="false"
                filterable
                node-key="id"
                placeholder="请选择设备"
                style="height: auto!important;"
                @change="equipmentChange(index)"
            />
          </el-form-item>
          <el-form-item class="data" name="item.data">
            <el-select v-model="item.dictIds" class="item" multiple placeholder="请选择指标"
                       @change="dataChange(index)">
              <el-option v-for="(item_d,index_d) in dataList[index]" :key="index_d" :label="item_d.name"
                         :value="item_d.id"></el-option>
            </el-select>
          </el-form-item>

          <el-icon v-if="formData.params.length>1" class="delIcon" @click="delItemClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===formData.params.length-1" class="addIcon" @click="addItemClick">
            <CirclePlus/>
          </el-icon>
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>

  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './subscription.less'

import {CloseBold, ZoomIn, Plus, Back, CircleClose, CirclePlus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  getListApi, editListApi, saveListApi, delListApi, getDataListApi, getEquipmentListApi, getDetailApi
} from "@/api/modules/subscription"
import {getAllTreeApi} from "@/api/modules/strategy";

let router = useRouter()


// 订阅列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 表格数据
let tableData = ref([])
// 获取表格数据
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  let data = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value
  }
  getListApi(data).then(res => {
    loading.value = false
    if (res.data.code === 200) {
      tableData.value = res.data.data.list
      tableData.value.sort((a, b) => {
        const startTimeA = new Date(a.startTime);
        const startTimeB = new Date(b.startTime);
        return startTimeB - startTimeA;
      });
      total.value = Number(res.data.data.total)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 新增编辑-----------------------------------------------------------------
// 新增编辑弹窗
let dialogFormVisible = ref(false)
// 设备列表
let equipmentList = ref([])
// 指标列表
let dataList = ref([])
// 获取设备列表
const getEquipmentListFun = function () {
  getEquipmentListApi().then(res => {
    if (res.data.code === 200) {
      equipmentList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取指标列表
const getDataListFun = function (index, id) {
  if (id) {
    getDataListApi(id).then(res => {
      if (res.data.code === 200) {
        dataList.value[index] = res.data.data
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 选择设备事件
let treeRef1 = ref()
const equipmentChange = function (index) {
  formData.value.params[index].dictIds = []
  // getDataListFun(index)
  getDataListFun(index, treeRef1.value[index].getCurrentNode().id)
  formData.value.params[index].deviceId = treeRef1.value[index].getCurrentNode().id
}
// 选择指标事件
const dataChange = function (index) {
  if (formData.value.params[index].dictIds.length > 10) {
    ElMessage({
      type: 'info',
      message: '最多选择10个指标'
    })
    formData.value.params[index].dictIds.splice(-1)
  }
}
// 新增编辑表单数据
let formData = ref({
  topicName: '',
  startTime: '',
  endTime: '',
  remark: '',
  params: [
    {
      deviceId: '',
      dictIds: [],
    }
  ]
})
// 表单规则
let formRules = ref({
  topicName: [
    {required: true, message: '请输入订阅名称', trigger: 'blur'},
    {pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/, message: '订阅名称只能由字母开头的数字、字母或下划线组成', trigger: 'blur'}
  ],
  startTime: [
    {required: true, message: '请选择开始时间', trigger: 'blur'},
  ],
  endTime: [
    {required: true, message: '请选择结束时间', trigger: 'blur'},
  ]
})
// 点击新增
const addClick = function () {
  dialogFormVisible.value = true
  formData.value = {
    topicName: '',
    startTime: '',
    endTime: '',
    remark: '',
    params: [
      {
        deviceId: '',
        dictIds: [],
      }
    ]
  }
}
// 点击编辑
const editClick = function (id, row) {
  dialogFormVisible.value = true
  getDetailApi(id).then(res => {
    if (res.data.code === 200) {
      formData.value = {
        id: id,
        topicName: row.topicName,
        startTime: row.startTime,
        endTime: row.endTime,
        remark: row.remark,
        params: res.data.data.conditionList
      }
      for (let i = 0; i < formData.value.params.length; i++) {
        getDataListFun(i, formData.value.params[i].deviceId)
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击增加设备
const addItemClick = function () {
  formData.value.params.push({
    deviceId: '',
    dictIds: [],
  })
}
// 点击删除设备
const delItemClick = function (index) {
  formData.value.params.splice(index, 1)
  dataList.value.splice(index, 1)
}
// 提交
let fromRef = ref()
const save = function () {
  // 提交表单
  if (fromRef) {
    fromRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (formData.value.id) {
          editListApi(formData.value).then(res => {
            if (res.data.code === 200) {
              getListFun()
              dialogFormVisible.value = false
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          saveListApi(formData.value).then(res => {
            if (res.data.code === 200) {
              getListFun()
              dialogFormVisible.value = false
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err);
          })
        }
      }
    })
  }
}

// 点击删除
const delClick = function (id, row) {
  ElMessageBox.confirm('请确认是否要删除此查询方案，删除后数据不可恢复', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除订阅',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此查询方案，删除后数据不可恢复'),
    ])
  }).then(() => {
    delListApi(id).then(res => {
      if (res.data.code === 200) {
        getListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 树形结构数据
let allTree = ref()
onMounted(() => {
  getListFun()
  // getEquipmentListFun()

  // 获取所有设备树
  getAllTreeApi().then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      allTree.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})

</script>

<style lang="less" scoped>
:deep(.el-dialog) {
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
}

/*:deep(.is-first) {
  margin-right: 19px !important;
}*/
</style>
