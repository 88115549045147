// useCircuit.js 提取复用函数
import {ref} from 'vue';

export default function useCircuit(getDeviceListApi) {
    //jtopo数据存储
    const asyncData = ref([]);
    //预览设备数据:
    const equipmentLists = ref({
        "photovoltaicEquipmentList": [{
            "key": "光伏设备1",
        },
            {
                "key": "光伏设备2",
            },
            {
                "key": "光伏设备3",
            },
            {
                "key": "光伏设备4",
            },
            {
                "key": "光伏设备5",
            }
        ],
        "storeEquipment": {
            "key": "储能设备",
        },
    })

    // 提取构建ports数组的逻辑到一个单独的函数
    function buildPorts(EquipmentList, storeEquipment, loadRate, totalRate) {
        let ports = [{
            name: '负载',
            img: 'fz2.svg',
            value: loadRate
        }];
        EquipmentList.forEach(item => {
            ports.push({
                name: item.key,
                value: item.value,
                // img: 'cn2.svg'
                img: item.key.includes('储能') ? 'cn2.svg' : 'gf2.svg'//是否包含储能，包含储能则使用储能图片
            });
        });
        ports.splice(2, 0, {
            name: storeEquipment.key,
            value: storeEquipment.value,
            img: 'cn2.svg'
        });
        ports.push({
            name: '电网',
            img: 'dw2.svg',
            value: totalRate
        });
        ports.forEach(item => {
            /*if (item.value === undefined) {
                return;
            }*/
            circuitValue.value.list.push({
                // value: Math.abs(item.value)
                item
            });
        })
        return ports;
    }

    //流向判断对照数据
    const tempData = ref([
        {
            name: '负载',
            img: 'fz2.svg',
            value: '1'
        },
        {
            name: '光伏逆变器地址11',
            value: '1',
            img: 'gf2.svg'
        },
        {
            name: '储能',
            value: '1',
            img: 'cn2.svg'
        },
        {
            name: '光伏逆变器地址12',
            value: '1',
            img: 'gf2.svg'
        },
        {
            name: '光伏逆变器地址13',
            value: '1',
            img: 'gf2.svg'
        },
        {
            name: '光伏逆变器地址14',
            value: '1',
            img: 'gf2.svg'
        },
        {
            name: '光伏逆变器地址15',
            value: '1',
            img: 'gf2.svg'
        },
        {
            name: '电网',
            img: '1',
            value: '133.20'
        },
    ])

    const equipmentList = async () => {
        await getDeviceListApi().then(res => {
            if (res.data.code === 0) {
                if (res.data.result.photovoltaicEquipmentList.length === 0) {
                    //丰轩乐大屏
                    let {
                        loadRate,
                        totalRate,
                        storeEquipmentList
                    } = res.data.result;
                    //储能设备列表及数据
                    const fxlEquipmentLis = []
                    storeEquipmentList.forEach((item, index) => {
                        if (index > 0) {
                            fxlEquipmentLis.push({
                                "key": storeEquipmentList[index].key,
                                "value": storeEquipmentList[index].value,
                            })
                        }
                    })
                    // 从第二项开始，将光伏替换为储能
                    if (fxlEquipmentLis.length !== 0) {
                        fxlEquipmentLis.forEach((item, index) => {
                            equipmentLists.value.photovoltaicEquipmentList[index] = item
                        })
                    }
                    storeEquipmentList = {
                        "key": storeEquipmentList[0].key,
                        "value": storeEquipmentList[0].value,
                    }
                    //清空数据显示数组
                    circuitValue.value.list = []
                    // asyncData.value = buildPorts(fxlEquipmentLis.length === 0 ? equipmentLists.value.photovoltaicEquipmentList : fxlEquipmentLis, storeEquipmentList, loadRate, totalRate);
                    asyncData.value = buildPorts(equipmentLists.value.photovoltaicEquipmentList, storeEquipmentList, loadRate, totalRate);
                    //开启流动动画
                    arrow.value.flow = true;
                    // 开启数字滚动显示
                    transitionName.value = true
                    //判断是否异号，异号重新赋值key
                    asyncData.value.forEach((item, index) => {
                        if (item.value === void 0) {
                            return
                        }
                        if (item.value * tempData.value[index].value < 0) {
                            arrow.value.key++
                        }
                    })
                    //比较完后赋值
                    tempData.value = asyncData.value
                } else {
                    //洪恩大屏
                    const {
                        photovoltaicEquipmentList,
                        storeEquipment,
                        loadRate,
                        totalRate,
                        storeEquipmentList
                    } = res.data.result;
                    circuitValue.value.list = []
                    asyncData.value = buildPorts(photovoltaicEquipmentList, storeEquipment, loadRate, totalRate, storeEquipmentList);
                    arrow.value.flow = true;
                    transitionName.value = true
                    asyncData.value.forEach((item, index) => {
                        if (item.value * tempData.value[index].value < 0) {
                            arrow.value.key++
                        }
                    })
                    tempData.value = asyncData.value
                }
            } else {
                // 处理API返回的错误情况
                console.error('Failed to fetch device list:', res.data.message);//默认是光伏背景颜色
            }
        })
    }
    const circuitValue = ref({
        color: '#D5A915',
        list: [],
        unit: 'kW',
        font: '400 17px arial',
        onLineTop: 138,
        offlineTop: 194,
    });
    const transitionName = ref(false);
    //初始化渲染
    asyncData.value = buildPorts(equipmentLists.value.photovoltaicEquipmentList, equipmentLists.value.storeEquipment);
    const [sideX, sideY] = [-530, -90];
    const basicData = {
        bgColor: 'rgba(255, 255, 255, 0)', //背景色
        // rgb: 'rgba(255, 255, 255, 0)',//图片背景颜色，默认为透明
        fontColor: '#fff', //图片名称字体颜色
        font: '400 18px arial', //字体样式
        after: {
            color: 'linear-gradient(to right, #001530, #000d28)',
            width: 134,
            height: 28.5
        }, //左下角水印遮盖
        lineWidth: 4, //线条宽度，
        textOffsetY: 28, //名称字体垂直偏移量
        upDown: true, //是否开启水平线上字体据顶，下距底显示
        imageTextUp: -15, //图片名称上方偏移量“需开启 upDown”
        imageTextDown: 7, //图片名称下方偏移量“需开启 upDown”
        mainLineColor: '#2668CF', //主线的颜色
        mainLineText: false, //主线文字是否展示
        mainLineOffset: [420, -25], //电网箭头文字偏移量
        sideX, //默认初始节点坐标
        sideY,
        //图片节点坐标及宽高，x,y,w,h
        IMAGES_OFFSETS: [
            [140, sideY - 7, 76.57, 86.24],
            [230, sideY + 180, 76.57, 86.24],
            [300, sideY - 7, 89.74, 86.24],
            [420, sideY + 180, 89.74, 86.24],
            [490, sideY - 7, 89.74, 86.24],
            [680, sideY - 7, 89.74, 86.24],
            [600, sideY + 180, 89.74, 86.24],
            [900, sideY + 80, 87.34, 81.46],
        ],
        //拼接url路径
        url: 'url(https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/'
    }
    const arrow = ref({
        size: [22, 21], //箭头大小
        img: 'jiantou.svg', //箭头图片
        color: '#D5A915', //箭头旁边字体颜色
        font: '400 22px arial', //箭头旁边字体大小
        textOffset: [-30, -70], //箭头文字偏移量
        arrowOffset: [0, 70], //箭头偏移量
        flowColor: '#2668CF', //侧边线的颜色或流动箭头颜色
        flow: false, //是否开启流动箭头
        lineDash: [6, 4], //流动箭头虚线
        position: 0.7, //箭头在线的哪个位置，%
        time: 180, //流动速度,
        unit: 'kW', //单位
        key: 0,
    });
    // 返回需要暴露给组件的数据和方法
    return {
        asyncData,
        basicData,
        arrow,
        circuitValue,
        transitionName,
        // 如果需要的话，也可以暴露fetchAndProcessData方法供外部调用
        equipmentList
    };
}