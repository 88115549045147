<template>
  <div class="hsHomeScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="head"></div>
        <div class="timeBox">
          <p class="time">{{ timeVal }}</p>
          <p class="date">{{ dateVal }}</p>
          <!--          <p class="week">{{ weekVal }}</p>-->
        </div>
        <div class="logo">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/fsScreen/hsHomeLogo.png">
        </div>
        <div class="leftBox">
          <div class="leftBoxItem" @click="toScreen('/hsHomeScreen/hsyqScreen')">
            <el-carousel :interval="5000">
              <el-carousel-item v-for="(item,index) in data.airTotalList" :key="index">
                <div class="leftBoxItem">
                  <div class="circleBoxItem">
                    <div class="circleBox">
                      <div :class="'hsHomeCircleChart1_'+index" class="chart"></div>
                      <p class="name">{{ item.name }}</p>
                    </div>
                  </div>
                  <div class="dataBox1">
                    <div class="dataBoxItem">
                      <count-up ref="countUp1" :decimalPlaces="2" :end-val="item.amountToday"
                                :start-val="countStart1[index]" class="value" style="display: inline-block"></count-up>
                      <p class="name">当日用气量(m³)</p>
                    </div>
                    <div class="dataBoxItem">
                      <count-up ref="countUp2" :decimalPlaces="2" :end-val="item.amountMonth"
                                :start-val="countStart2[index]" class="value" style="display: inline-block"></count-up>
                      <p class="name">当月用气量(m³)</p>
                    </div>
                    <div class="dataBoxItem">
                      <count-up ref="countUp3" :decimalPlaces="2" :end-val="item.amountYear"
                                :start-val="countStart3[index]" class="value" style="display: inline-block"></count-up>
                      <p class="name">当年用气量(m³)</p>
                    </div>
                  </div>
                  <div class="dataBox2">
                    <div class="dataBoxItem">
                      <p class="name">总设备数</p>
                      <count-up ref="countUp13" :end-val="item.totalEquipmentNumber" :start-val="countStart13[index]"
                                class="value" style="display: inline-block"></count-up>
                      <span class="unit">台</span>
                    </div>
                    <div class="dataBoxItem">
                      <p class="name">在线设备数</p>
                      <count-up ref="countUp14" :end-val="item.onlineEquipmentNumber" :start-val="countStart14[index]"
                                class="value" style="display: inline-block"></count-up>
                      <span class="unit">台</span>
                    </div>
                    <div class="dataBoxItem">
                      <p class="name">离线设备数</p>
                      <count-up ref="countUp15" :end-val="item.offlineEquipmentNumber" :start-val="countStart15[index]"
                                class="value" style="display: inline-block"></count-up>
                      <span class="unit">台</span>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="leftBoxItem" @click="toScreen('/hsHomeScreen/hsydScreen')">
            <div class="circleBoxItem">
              <div class="circleBox">
                <div class="chart  hsHomeCircleChart2"></div>
                <p class="name">实时功率</p>
              </div>
            </div>
            <div class="dataBox1">
              <div class="dataBoxItem">
                <count-up ref="countUp4" :decimalPlaces="2" :end-val="data.electric.total.amountToday"
                          :start-val="countStart4" class="value" style="display: inline-block"></count-up>
                <p class="name">当日用电量(kwh)</p>
              </div>
              <div class="dataBoxItem">
                <count-up ref="countUp5" :decimalPlaces="2" :end-val="data.electric.total.amountMonth"
                          :start-val="countStart5" class="value" style="display: inline-block"></count-up>
                <p class="name">当月用电量(kwh)</p>
              </div>
              <div class="dataBoxItem">
                <count-up ref="countUp6" :decimalPlaces="2" :end-val="data.electric.total.amountYear"
                          :start-val="countStart6" class="value" style="display: inline-block"></count-up>
                <p class="name">当年用电量(kwh)</p>
              </div>
            </div>
            <div class="dataBox2">
              <div class="dataBoxItem">
                <p class="name">总设备数</p>
                <count-up ref="countUp16" :end-val="data.electric.total.totalEquipmentNumber" :start-val="countStart16"
                          class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
              <div class="dataBoxItem">
                <p class="name">在线设备数</p>
                <count-up ref="countUp17" :end-val="data.electric.total.onlineEquipmentNumber" :start-val="countStart17"
                          class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
              <div class="dataBoxItem">
                <p class="name">离线设备数</p>
                <count-up ref="countUp18" :end-val="data.electric.total.offlineEquipmentNumber"
                          :start-val="countStart18" class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
            </div>
          </div>
          <div class="leftBoxItem" @click="toScreen('/hsHomeScreen/hsysScreen')">
            <div class="circleBoxItem">
              <div class="circleBox">
                <div class="chart  hsHomeCircleChart3"></div>
                <p class="name">实时总读数</p>
              </div>
            </div>
            <div class="dataBox1">
              <div class="dataBoxItem">
                <count-up ref="countUp7" :decimalPlaces="2" :end-val="data.water.total.amountToday"
                          :start-val="countStart7" class="value" style="display: inline-block"></count-up>
                <p class="name">当日用水量(吨)</p>
              </div>
              <div class="dataBoxItem">
                <count-up ref="countUp8" :decimalPlaces="2" :end-val="data.water.total.amountMonth"
                          :start-val="countStart8" class="value" style="display: inline-block"></count-up>
                <p class="name">当月用水量(吨)</p>
              </div>
              <div class="dataBoxItem">
                <count-up ref="countUp9" :decimalPlaces="2" :end-val="data.water.total.amountYear"
                          :start-val="countStart9" class="value" style="display: inline-block"></count-up>
                <p class="name">当年用水量(吨)</p>
              </div>
            </div>
            <div class="dataBox2">
              <div class="dataBoxItem">
                <p class="name">总设备数</p>
                <count-up ref="countUp19" :end-val="data.water.total.totalEquipmentNumber" :start-val="countStart19"
                          class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
              <div class="dataBoxItem">
                <p class="name">在线设备数</p>
                <count-up ref="countUp20" :end-val="data.water.total.onlineEquipmentNumber" :start-val="countStart20"
                          class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
              <div class="dataBoxItem">
                <p class="name">离线设备数</p>
                <count-up ref="countUp21" :end-val="data.water.total.offlineEquipmentNumber" :start-val="countStart21"
                          class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
            </div>
          </div>
          <div class="leftBoxItem">
            <div class="circleBoxItem">
              <div class="circleBox">
                <div class="chart  hsHomeCircleChart4"></div>
                <p class="name">实时功率</p>
              </div>
            </div>
            <div class="dataBox1">
              <div class="dataBoxItem">
                <count-up ref="countUp10" :decimalPlaces="2" :end-val="data.photovoltaic.total.amountToday"
                          :start-val="countStart10" class="value" style="display: inline-block"></count-up>
                <p class="name">当日发电量(kwh)</p>
              </div>
              <div class="dataBoxItem">
                <count-up ref="countUp11" :decimalPlaces="2" :end-val="data.photovoltaic.total.amountMonth"
                          :start-val="countStart11" class="value" style="display: inline-block"></count-up>
                <p class="name">当月发电量(kwh)</p>
              </div>
              <div class="dataBoxItem">
                <count-up ref="countUp12" :decimalPlaces="2" :end-val="data.photovoltaic.total.amountYear"
                          :start-val="countStart12" class="value" style="display: inline-block"></count-up>
                <p class="name">当年发电量(kwh)</p>
              </div>
            </div>
            <div class="dataBox2">
              <div class="dataBoxItem">
                <p class="name">总设备数</p>
                <count-up ref="countUp22" :end-val="data.photovoltaic.total.totalEquipmentNumber"
                          :start-val="countStart22" class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
              <div class="dataBoxItem">
                <p class="name">在线设备数</p>
                <count-up ref="countUp23" :end-val="data.photovoltaic.total.onlineEquipmentNumber"
                          :start-val="countStart23" class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
              <div class="dataBoxItem">
                <p class="name">离线设备数</p>
                <count-up ref="countUp24" :end-val="data.photovoltaic.total.offlineEquipmentNumber"
                          :start-val="countStart24" class="value" style="display: inline-block"></count-up>
                <span class="unit">台</span>
              </div>
            </div>
          </div>
        </div>
        <div class="centerBox">
          <div class="centerBoxItem">
            <div class="chartBox">
              <div class="legend">
                <p>单位：m³</p>
                <div class="right">
                  <p>实时气流量</p>
                  <p class="rect"></p>
                </div>
              </div>
              <div class="chart hsHomeChart1_1"></div>
            </div>
          </div>
          <div class="centerBoxItem">
            <div class="chartBox">
              <div class="legend">
                <p>单位：kw</p>
                <div class="right">
                  <p>实时功率</p>
                  <p class="rect"></p>
                </div>
              </div>
              <div class="hsHomeChart2_1 chart"></div>
            </div>
          </div>
          <div class="centerBoxItem">
            <div class="chartBox">
              <div class="legend">
                <p>单位：吨</p>
                <div class="right">
                  <p>实时水流量</p>
                  <p class="rect"></p>
                </div>
              </div>
              <div class="hsHomeChart3_1 chart"></div>
            </div>
          </div>
          <div class="centerBoxItem">
            <div class="chartBox">
              <div class="legend">
                <p>单位：kwh</p>
                <div class="right">
                  <p>光伏实时发电功率</p>
                  <p class="rect"></p>
                </div>
              </div>
              <div class="hsHomeChart4_1 chart"></div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightBoxItem">
            <div class="chartBox">
              <div class="legend">
                <p>单位：m³</p>
                <div class="rightCopy">
                  <p :class="active1===1?'active':''" @click="tabClick(1,1)">日</p>
                  <p :class="active1===2?'active':''" @click="tabClick(1,2)">月</p>
                  <p :class="active1===3?'active':''" @click="tabClick(1,3)">年</p>
                </div>
              </div>
              <div class="hsHomeChart5 chart"></div>
            </div>
          </div>
          <div class="rightBoxItem">
            <div class="chartBox">
              <div class="legend">
                <p>单位：kwh</p>
                <div class="rightCopy">
                  <p :class="active2===1?'active':''" @click="tabClick(2,1)">日</p>
                  <p :class="active2===2?'active':''" @click="tabClick(2,2)">月</p>
                  <p :class="active2===3?'active':''" @click="tabClick(2,3)">年</p>
                </div>
              </div>
              <div class="hsHomeChart6 chart"></div>
            </div>
          </div>
          <div class="rightBoxItem">
            <div class="chartBox">
              <div class="legend">
                <p>单位：吨</p>
                <div class="rightCopy">
                  <p :class="active3===1?'active':''" @click="tabClick(3,1)">日</p>
                  <p :class="active3===2?'active':''" @click="tabClick(3,2)">月</p>
                  <p :class="active3===3?'active':''" @click="tabClick(3,3)">年</p>
                </div>
              </div>
              <div class="hsHomeChart7 chart"></div>
            </div>
          </div>
          <div class="rightBoxItem">
            <div class="chartBox">
              <div class="legend">
                <p>单位：kwh</p>
                <div class="rightCopy">
                  <p :class="active4===1?'active':''" @click="tabClick(4,1)">日</p>
                  <p :class="active4===2?'active':''" @click="tabClick(4,2)">月</p>
                  <p :class="active4===3?'active':''" @click="tabClick(4,3)">年</p>
                </div>
              </div>
              <div class="hsHomeChart8 chart"></div>
            </div>
          </div>
        </div>
      </div>
    </v-scale-screen>
  </div>
</template>

<script setup>
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import VScaleScreen from 'v-scale-screen'
import './hsHomeScreen.less'
import * as echarts from "echarts";
import {getHsHomeScreenApi} from '@/api/modules/bigScreen'
import {ElMessage} from "element-plus";
import CountUp from 'vue-countup-v3'
import {useRouter, useRoute} from 'vue-router';

let router = useRouter()

// 大屏跳转
const toScreen = function (val) {
  window.open(`#${val}`, '_blank');
}

// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.hngfScreen'); // 获取需要全屏的元素;
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};
const check = function () {
  enterFullScreen()
}
// 当前时间
let timeVal = ref()
let dateVal = ref()
let weekVal = ref()
const updateTime = function () {
  const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
  const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
  const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
  const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
  const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
  const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
  timeVal.value = hours + ':' + minutes + ':' + seconds
  dateVal.value = year + '.' + month + '.' + day
  weekVal.value = new Intl.DateTimeFormat('zh-CN', {
    weekday: 'long'
  }).format(new Date());
}

// 左边----------------------------------
// 加载左边第一个
const circleChartLoad = function (i, value) {
  var chartDom = document.querySelector(`.hsHomeCircleChart1_${i}`);
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    series: [{
      type: 'gauge',
      startAngle: 245,
      endAngle: -62,
      pointer: {
        show: true,
        offsetCenter: ['0%', '-50%'],
        length: '50%',
        icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
        itemStyle: {
          borderCap: 'round',
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#66FFFF'
          },
            {
              offset: 0.7,
              color: 'rgba(102,255,255,.6)'
            },
            {
              offset: 1,
              color: 'rgba(102,255,255,.1)'
            },
          ]),
        }
      },
      progress: {
        show: true,
        width: 10,
        // overlap: false,
        // roundCap: true,
        // clip: false,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#59F8AC'
          },
            {
              offset: 1,
              color: '#50C0FF'
            },
          ]),
        }
      },
      axisLine: {
        lineStyle: {
          width: 20,
          // color: '#01071D',
          color: [
            [0.1, '#2c4c64'], // 0~10% 红轴
            [1, '#2c4c64'], // 10~20% 绿轴
          ],
          opacity: 0.4, //See here
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      data: [{
        value: value,
        // name: data.value.statusStr,
        name: 'm³/s',
        title: {
          offsetCenter: ['0%', '36%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '0'],
          textShadowBlur: 12,
          textShadowColor: '#06B8FB',
        }
      }],
      title: {
        fontSize: 10,
        color: '#fff'
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        // formatter: '{value}',
        // formatter: `{value}{a|%}`,
        // rich: {
        //   a: {
        //     fontSize: 12,
        //     color: '#fff',
        //     verticalAlign: 'bottom',
        //     width: 20,
        //     align: 'center',
        //     display: 'block'
        //   }
        // }
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载左边第二个
const circleChartLoad2 = function () {
  var chartDom = document.querySelector('.hsHomeCircleChart2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    series: [{
      type: 'gauge',
      startAngle: 245,
      endAngle: -62,
      pointer: {
        show: true,
        offsetCenter: ['0%', '-50%'],
        length: '50%',
        icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
        itemStyle: {
          borderCap: 'round',
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#66FFFF'
          },
            {
              offset: 0.7,
              color: 'rgba(102,255,255,.6)'
            },
            {
              offset: 1,
              color: 'rgba(102,255,255,.1)'
            },
          ]),
        }
      },
      progress: {
        show: true,
        width: 10,
        // overlap: false,
        // roundCap: true,
        // clip: false,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#59F8AC'
          },
            {
              offset: 1,
              color: '#50C0FF'
            },
          ]),
        }
      },
      axisLine: {
        lineStyle: {
          width: 20,
          // color: '#01071D',
          color: [
            [0.1, '#2c4c64'], // 0~10% 红轴
            [1, '#2c4c64'], // 10~20% 绿轴
          ],
          opacity: 0.4, //See here
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      data: [{
        value: data.value.electric.total.flowRate,
        // name: data.value.statusStr,
        name: 'kw',
        title: {
          offsetCenter: ['0%', '36%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '0'],
          textShadowBlur: 12,
          textShadowColor: '#06B8FB',
        }
      }],
      title: {
        fontSize: 10,
        color: '#fff'
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        // formatter: '{value}',
        // formatter: `{value}{a|%}`,
        // rich: {
        //   a: {
        //     fontSize: 12,
        //     color: '#fff',
        //     verticalAlign: 'bottom',
        //     width: 20,
        //     align: 'center',
        //     display: 'block'
        //   }
        // }
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载左边第三个
const circleChartLoad3 = function () {
  var chartDom = document.querySelector('.hsHomeCircleChart3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    series: [{
      type: 'gauge',
      startAngle: 245,
      endAngle: -62,
      pointer: {
        show: true,
        offsetCenter: ['0%', '-50%'],
        length: '50%',
        icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
        itemStyle: {
          borderCap: 'round',
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#66FFFF'
          },
            {
              offset: 0.7,
              color: 'rgba(102,255,255,.6)'
            },
            {
              offset: 1,
              color: 'rgba(102,255,255,.1)'
            },
          ]),
        }
      },
      progress: {
        show: true,
        width: 10,
        // overlap: false,
        // roundCap: true,
        // clip: false,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#59F8AC'
          },
            {
              offset: 1,
              color: '#50C0FF'
            },
          ]),
        }
      },
      axisLine: {
        lineStyle: {
          width: 20,
          // color: '#01071D',
          color: [
            [0.1, '#2c4c64'], // 0~10% 红轴
            [1, '#2c4c64'], // 10~20% 绿轴
          ],
          opacity: 0.4, //See here
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      data: [{
        value: data.value.water.total.flowRate,
        // name: data.value.statusStr,
        name: 'm³',
        title: {
          offsetCenter: ['0%', '36%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '0'],
          textShadowBlur: 12,
          textShadowColor: '#06B8FB',
        }
      }],
      title: {
        fontSize: 10,
        color: '#fff'
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        // formatter: '{value}',
        // formatter: `{value}{a|%}`,
        // rich: {
        //   a: {
        //     fontSize: 12,
        //     color: '#fff',
        //     verticalAlign: 'bottom',
        //     width: 20,
        //     align: 'center',
        //     display: 'block'
        //   }
        // }
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载左边第四个
const circleChartLoad4 = function () {
  var chartDom = document.querySelector('.hsHomeCircleChart4');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    series: [{
      type: 'gauge',
      startAngle: 245,
      endAngle: -62,
      pointer: {
        show: true,
        offsetCenter: ['0%', '-50%'],
        length: '50%',
        icon: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/zhizhen.svg',
        itemStyle: {
          borderCap: 'round',
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#66FFFF'
          },
            {
              offset: 0.7,
              color: 'rgba(102,255,255,.6)'
            },
            {
              offset: 1,
              color: 'rgba(102,255,255,.1)'
            },
          ]),
        }
      },
      progress: {
        show: true,
        width: 10,
        // overlap: false,
        // roundCap: true,
        // clip: false,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#59F8AC'
          },
            {
              offset: 1,
              color: '#50C0FF'
            },
          ]),
        }
      },
      axisLine: {
        lineStyle: {
          width: 20,
          // color: '#01071D',
          color: [
            [0.1, '#2c4c64'], // 0~10% 红轴
            [1, '#2c4c64'], // 10~20% 绿轴
          ],
          opacity: 0.4, //See here
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      data: [{
        value: data.value.photovoltaic.total.flowRate,
        // name: data.value.statusStr,
        name: 'kw',
        title: {
          offsetCenter: ['0%', '36%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '0'],
          textShadowBlur: 12,
          textShadowColor: '#06B8FB',
        }
      }],
      title: {
        fontSize: 10,
        color: '#fff'
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        // formatter: '{value}',
        // formatter: `{value}{a|%}`,
        // rich: {
        //   a: {
        //     fontSize: 12,
        //     color: '#fff',
        //     verticalAlign: 'bottom',
        //     width: 20,
        //     align: 'center',
        //     display: 'block'
        //   }
        // }
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

// 中间-----------------------------------
// 加载中间第一个
const chartLoad1 = function () {
  var chartDom = document.querySelector('.hsHomeChart1_1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    legend: {
      textStyle: {
        color: '#6FA7FF',
        fontSize: 10
      },
      itemWidth: 13,
      //				itemHeight: 7, //修改icon图形大小
      //				icon: 'circle',
      left: 'right'
    },
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 15,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.airLine.timeList
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: []
  };
  let colorList = [
    ['#1B63CC', '#03DFFC'],
    ['#099D4E', '#6FE3BE'],
    ['#05A998', '#63FBFC'],
    ['#4827BC', '#7199EF'],
    ['#2D5CBD', '#40A0FA'],
  ]

  for (let i = 0; i < data.value.airLine.list.length; i++) {
    option.series.push({
      data: data.value.airLine.list[i].value,
      name: data.value.airLine.list[i].key,
      type: 'line',
      itemStyle: {
        color: colorList[i][0],
        opacity: 0
      },
      lineStyle: {
        width: 2.5
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: colorList[i][0],
          opacity: 0.6
        },
          {
            offset: 1,
            color: colorList[i][1],
            opacity: 0
          },
        ])
      }
    })
  }
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载中间第二个
const chartLoad2 = function () {
  var chartDom = document.querySelector('.hsHomeChart2_1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 10,
      bottom: 30,
      right: 15,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.electric.lineListX,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [{
      type: 'line',
      data: data.value.electric.lineListY,
      itemStyle: {
        color: '#51FFDF',
        opacity: 0
      },
      lineStyle: {
        width: 2.5
      },
      // markPoint: {
      //   data: [{
      //     type: 'max',
      //     name: 'Max',
      //     symbolSize: 40
      //   }]
      // },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(30, 239, 196, 0.65)'
        },
          {
            offset: 1,
            color: 'rgba(6, 169, 137, 0)'
          },
        ])
      }
    }],
  };
  myChart.clear()
  option && myChart.setOption(option);
  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载中间第三个
const chartLoad3 = function () {
  var chartDom = document.querySelector('.hsHomeChart3_1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 10,
      bottom: 30,
      right: 15,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.water.lineListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [{
      data: data.value.water.lineListY,
      type: 'line',
      itemStyle: {
        color: '#55B5FF',
        opacity: 0
      },
      lineStyle: {
        width: 2.5
      },
      // markPoint: {
      //   data: [{
      //     type: 'max',
      //     name: 'Max',
      //     symbolSize: 40,
      //   }]
      // },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(54, 161, 255, 0.60)'
        },
          {
            offset: 1,
            color: 'rgba(25, 104, 255, 0)'
          },
        ])
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载中间第四个
const chartLoad4 = function () {
  var chartDom = document.querySelector('.hsHomeChart4_1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 10,
      bottom: 30,
      right: 15,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.photovoltaic.lineListX,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [{
      type: 'line',
      data: data.value.photovoltaic.lineListY,
      itemStyle: {
        color: '#51FFDF',
        opacity: 0
      },
      lineStyle: {
        width: 2.5
      },
      // markPoint: {
      //   data: [{
      //     type: 'max',
      //     name: 'Max',
      //     symbolSize: 40
      //   }]
      // },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(30, 239, 196, 0.65)'
        },
          {
            offset: 1,
            color: 'rgba(6, 169, 137, 0)'
          },
        ])
      }
    }],
  };
  myChart.clear()
  option && myChart.setOption(option);
  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

// 右边-----------------------------------
// 加载右边第一个
const chartLoad5 = function () {
  let axiosX = []
  let axiosY = []
  if (active1.value === 1) {
    axiosX = data.value.airDay.timeList
    axiosY = data.value.airDay.list
  } else if (active1.value === 2) {
    axiosX = data.value.airMonth.timeList
    axiosY = data.value.airMonth.list
  } else if (active1.value === 3) {
    axiosX = data.value.airYear.timeList
    axiosY = data.value.airYear.list
  }
  var chartDom = document.querySelector('.hsHomeChart5');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    legend: {
      textStyle: {
        color: '#6FA7FF',
        fontSize: 10
      },
      icon: 'rect',
      itemWidth: 13,
      itemHeight: 6,
      left: 'right'
    },
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 15,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: axiosX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: []
  };

  let colorList = [
    ['#1B63CC', '#03DFFC'],
    ['#099D4E', '#6FE3BE'],
    ['#05A998', '#63FBFC'],
    ['#4827BC', '#7199EF'],
    ['#2D5CBD', '#40A0FA'],
  ]
  for (let i = 0; i < axiosY.length; i++) {
    option.series.push({
      name: axiosY[i].key,
      type: 'bar',
      stack: '用气量统计',
      barWidth: 16,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(
            0, 1, 0, 0, [{ //代表渐变色从正上方开始
              offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
              color: colorList[i][0]
            }, //柱图渐变色
              {
                offset: 1, //指100%处的颜色
                color: colorList[i][1]
              }
            ]
        )
      },
      data: axiosY[i].value
    })
  }
  myChart.clear()
  option && myChart.setOption(option);
  nextTick(() => {
    let max = myChart.getModel().getComponent('yAxis', 0).axis.scale.getExtent()[1]
    for (let i = 0; i < option.series[0].data.length; i++) {
      option.series[0].data[i] = max
    }
    myChart.setOption(option);
  })
  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第二个
const chartLoad6 = function () {
  var chartDom = document.querySelector('.hsHomeChart6');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 10,
      bottom: 30,
      right: 15,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.electric.dayListX ? data.value.electric.dayListX : []
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [{
      data: data.value.electric.dayListY ? JSON.parse(JSON.stringify(data.value.electric.dayListY)) : [],
      type: 'bar',
      barWidth: 18,
      itemStyle: {
        color: 'transparent',
        borderWidth: 1,
        borderColor: 'rgba(255,255,255,.5)'
      },
      tooltip: {
        show: false
      },
    },
      {
        type: "pictorialBar", //设置类型为象形柱状图
        border: 12,
        symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#B0FFEB",
            shadowColor: '#0078FF',
            shadowBlur: 12,
            shadowOffsetX: 1,
          }
        },
        symbolRepeat: "true",
        symbolSize: [14, 5],
        z: 1,
        data: data.value.electric.dayListY ? data.value.electric.dayListY : [],
      },
      {
        type: "pictorialBar", //设置类型为象形柱状图
        symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#094E66",
            barBorderColor: "#FFFFFF", // 边框色
            barBorderWidth: 1, // 边框宽度
          }
        },
        tooltip: {
          show: false
        },
        label: {
          show: false,
          position: 'top', // 位置
          color: '#f0f0f0',
          fontSize: 14,
        },
        symbolRepeat: "fixed",
        symbolSize: [14, 5],
        z: 0,
        data: data.value.electric.dayListY ? data.value.electric.dayListY : [],
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);
  nextTick(() => {
    let max = myChart.getModel().getComponent('yAxis', 0).axis.scale.getExtent()[1]
    for (let i = 0; i < option.series[0].data.length; i++) {
      option.series[0].data[i] = max
    }
    myChart.setOption(option);
  })

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第三个
const chartLoad7 = function () {
  var chartDom = document.querySelector('.hsHomeChart7');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 10,
      bottom: 30,
      right: 15,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.water.dayListX ? data.value.water.dayListX : []
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [{
      data: data.value.water.dayListY ? JSON.parse(JSON.stringify(data.value.water.dayListY)) : [],
      type: 'bar',
      barWidth: 18,
      itemStyle: {
        color: 'transparent',
        borderWidth: 1,
        borderColor: 'rgba(255,255,255,.5)'
      },
      tooltip: {
        show: false
      },
    },
      {
        type: "pictorialBar", //设置类型为象形柱状图
        border: 12,
        symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#B6D5FF",
            shadowColor: '#0078FF',
            shadowBlur: 12,
            shadowOffsetX: 1,
          },

        },
        symbolRepeat: "true",
        symbolSize: [14, 5],
        z: 1,
        data: data.value.water.dayListY ? data.value.water.dayListY : [],
      },
      {
        type: "pictorialBar", //设置类型为象形柱状图
        symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#0B3174",
            barBorderColor: "#FFFFFF", // 边框色
            barBorderWidth: 1, // 边框宽度
          }
        },
        label: {
          show: false,
          position: 'top', // 位置
          color: '#f0f0f0',
          fontSize: 14,
        },
        tooltip: {
          show: false
        },
        symbolRepeat: "fixed",
        symbolSize: [14, 5],
        z: 0,
        data: data.value.water.dayListY ? data.value.water.dayListY : [],
      }
    ]
  };
  myChart.clear()
  option && myChart.setOption(option);
  nextTick(() => {
    let max = myChart.getModel().getComponent('yAxis', 0).axis.scale.getExtent()[1]
    for (let i = 0; i < option.series[0].data.length; i++) {
      option.series[0].data[i] = max
    }
    myChart.setOption(option);
  })
  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第四个
const chartLoad8 = function () {
  var chartDom = document.querySelector('.hsHomeChart8');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 10,
      bottom: 30,
      right: 15,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          color: '#6FA7FF'
        }
      },
      data: data.value.photovoltaic.dayListX ? data.value.photovoltaic.dayListX : []
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [{
      data: data.value.photovoltaic.dayListY ? JSON.parse(JSON.stringify(data.value.photovoltaic.dayListY)) : [],
      type: 'bar',
      barWidth: 18,
      itemStyle: {
        color: 'transparent',
        borderWidth: 1,
        borderColor: 'rgba(255,255,255,.5)'
      },
      tooltip: {
        show: false
      },
    },
      {
        type: "pictorialBar", //设置类型为象形柱状图
        border: 12,
        symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#B0FFEB",
            shadowColor: '#0078FF',
            shadowBlur: 12,
            shadowOffsetX: 1,
          }
        },
        symbolRepeat: "true",
        symbolSize: [14, 5],
        z: 1,
        data: data.value.photovoltaic.dayListY ? data.value.photovoltaic.dayListY : [],
      },
      {
        type: "pictorialBar", //设置类型为象形柱状图
        symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#094E66",
            barBorderColor: "#FFFFFF", // 边框色
            barBorderWidth: 1, // 边框宽度
          }
        },
        tooltip: {
          show: false
        },
        label: {
          show: false,
          position: 'top', // 位置
          color: '#f0f0f0',
          fontSize: 14,
        },
        symbolRepeat: "fixed",
        symbolSize: [14, 5],
        z: 0,
        data: data.value.photovoltaic.dayListY ? data.value.photovoltaic.dayListY : [],
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);
  nextTick(() => {
    let max = myChart.getModel().getComponent('yAxis', 0).axis.scale.getExtent()[1]
    for (let i = 0; i < option.series[0].data.length; i++) {
      option.series[0].data[i] = max
    }
    myChart.setOption(option);
  })

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

// 右边年月日切换
let active1 = ref(2)
let active2 = ref(2)
let active3 = ref(2)
let active4 = ref(2)
const tabClick = function (id1, id2) {
  if (id1 === 1) {
    active1.value = id2
    chartLoad5()
  } else if (id1 === 2) {
    active2.value = id2
    data.value.electric.dayListX = []
    data.value.electric.dayListY = []
    if (id2 === 1) {
      for (let i = 0; i < data.value.electric.dayList.length; i++) {
        data.value.electric.dayListX.push(data.value.electric.dayList[i].key)
        data.value.electric.dayListY.push(Number(data.value.electric.dayList[i].value))
      }
    } else if (id2 === 2) {
      for (let i = 0; i < data.value.electric.monthList.length; i++) {
        data.value.electric.dayListX.push(data.value.electric.monthList[i].key)
        data.value.electric.dayListY.push(Number(data.value.electric.monthList[i].value))
      }
    } else if (id2 === 3) {
      for (let i = 0; i < data.value.electric.yearList.length; i++) {
        data.value.electric.dayListX.push(data.value.electric.yearList[i].key)
        data.value.electric.dayListY.push(Number(data.value.electric.yearList[i].value))
      }
    }
    chartLoad6()
  } else if (id1 === 3) {
    active3.value = id2
    data.value.water.dayListX = []
    data.value.water.dayListY = []
    if (id2 === 1) {
      for (let i = 0; i < data.value.water.dayList.length; i++) {
        data.value.water.dayListX.push(data.value.water.dayList[i].key)
        data.value.water.dayListY.push(Number(data.value.water.dayList[i].value))
      }
    } else if (id2 === 2) {
      for (let i = 0; i < data.value.water.monthList.length; i++) {
        data.value.water.dayListX.push(data.value.water.monthList[i].key)
        data.value.water.dayListY.push(Number(data.value.water.monthList[i].value))
      }
    } else if (id2 === 3) {
      for (let i = 0; i < data.value.water.yearList.length; i++) {
        data.value.water.dayListX.push(data.value.water.yearList[i].key)
        data.value.water.dayListY.push(Number(data.value.water.yearList[i].value))
      }
    }
    chartLoad7()
  } else if (id1 === 4) {
    active4.value = id2
    data.value.photovoltaic.dayListX = []
    data.value.photovoltaic.dayListY = []
    if (id2 === 1) {
      for (let i = 0; i < data.value.photovoltaic.dayList.length; i++) {
        data.value.photovoltaic.dayListX.push(data.value.photovoltaic.dayList[i].key)
        data.value.photovoltaic.dayListY.push(Number(data.value.photovoltaic.dayList[i].value))
      }
    } else if (id2 === 2) {
      for (let i = 0; i < data.value.photovoltaic.monthList.length; i++) {
        data.value.photovoltaic.dayListX.push(data.value.photovoltaic.monthList[i].key)
        data.value.photovoltaic.dayListY.push(Number(data.value.photovoltaic.monthList[i].value))
      }
    } else if (id2 === 3) {
      for (let i = 0; i < data.value.photovoltaic.yearList.length; i++) {
        data.value.photovoltaic.dayListX.push(data.value.photovoltaic.yearList[i].key)
        data.value.photovoltaic.dayListY.push(Number(data.value.photovoltaic.yearList[i].value))
      }
    }
    chartLoad8()
  }
}

let data = ref({
  "airDay": {
    "list": [],
    "timeList": []
  },
  "airLine": {
    "list": [],
    "timeList": []
  },
  "airMonth": {
    "list": [],
    "timeList": []
  },
  "airTotalList": [],
  "airYear": {
    "list": [],
    "timeList": []
  },
  "electric": {
    "dayList": [],
    "lineList": [],
    "monthList": [],
    "total": {},
    "yearList": []
  },
  "photovoltaic": {
    "dayList": [],
    "lineList": [],
    "monthList": [],
    "total": {},
    "yearList": []
  },
  "water": {
    "dayList": [],
    "lineList": [],
    "monthList": [],
    "total": {},
    "yearList": []
  }
})

// 数字轮播数据
let countUp1 = ref()
let countUp2 = ref()
let countUp3 = ref()
let countUp4 = ref()
let countUp5 = ref()
let countUp6 = ref()
let countUp7 = ref()
let countUp8 = ref()
let countUp9 = ref()
let countUp10 = ref()
let countUp11 = ref()
let countUp12 = ref()
let countUp13 = ref()
let countUp14 = ref()
let countUp15 = ref()
let countUp16 = ref()
let countUp17 = ref()
let countUp18 = ref()
let countUp19 = ref()
let countUp20 = ref()
let countUp21 = ref()
let countUp22 = ref()
let countUp23 = ref()
let countUp24 = ref()
//数字轮播初始值
let countStart1 = ref([])
let countStart2 = ref([])
let countStart3 = ref([])
let countStart4 = ref(0)
let countStart5 = ref(0)
let countStart6 = ref(0)
let countStart7 = ref(0)
let countStart8 = ref(0)
let countStart9 = ref(0)
let countStart10 = ref(0)
let countStart11 = ref(0)
let countStart12 = ref(0)
let countStart13 = ref([])
let countStart14 = ref([])
let countStart15 = ref([])
let countStart16 = ref(0)
let countStart17 = ref(0)
let countStart18 = ref(0)
let countStart19 = ref(0)
let countStart20 = ref(0)
let countStart21 = ref(0)
let countStart22 = ref(0)
let countStart23 = ref(0)
let countStart24 = ref(0)

// 获取大屏数据
const getDataFun = async function () {
  await getHsHomeScreenApi().then(res => {
    if (res.data.code === 0) {
      for (let i = 0; i < data.value.airTotalList.length; i++) {
        if (data.value.airTotalList[i] && data.value.airTotalList[i].amountToday != res.data.result.airTotalList[i].amountToday) {
          countStart1.value[i] = res.data.result.airTotalList[i].amountToday
        }
        if (data.value.airTotalList[i] && data.value.airTotalList[i].amountMonth != res.data.result.airTotalList[i].amountMonth) {
          countStart2.value[i] = res.data.result.airTotalList[i].amountMonth
        }
        if (data.value.airTotalList[i] && data.value.airTotalList[i].amountYear != res.data.result.airTotalList[i].amountYear) {
          countStart3.value[i] = res.data.result.airTotalList[i].amountYear
        }
        if (data.value.airTotalList[i].totalEquipmentNumber != res.data.result.airTotalList[i].totalEquipmentNumber) {
          countStart13.value[i] = res.data.result.airTotalList[i].totalEquipmentNumber
        }
        if (data.value.airTotalList[i].onlineEquipmentNumber != res.data.result.airTotalList[i].onlineEquipmentNumber) {
          countStart14.value[i] = res.data.result.airTotalList[i].onlineEquipmentNumber
        }
        if (data.value.airTotalList[i].offlineEquipmentNumber != res.data.result.airTotalList[i].offlineEquipmentNumber) {
          countStart15.value[i] = res.data.result.airTotalList[i].offlineEquipmentNumber
        }
        if (data.value.airTotalList[i] && (data.value.airTotalList[i].flowRate != res.data.result.airTotalList[i].flowRate)) {
          nextTick(() => {
            circleChartLoad(i, res.data.result.airTotalList[i].flowRate)
          })
        }
      }
      if (data.value.electric.total.amountToday != res.data.result.electric.total.amountToday) {
        countStart4.value = res.data.result.electric.total.amountToday
      }
      if (data.value.electric.total.amountMonth != res.data.result.electric.total.amountMonth) {
        countStart5.value = res.data.result.electric.total.amountMonth
      }
      if (data.value.electric.total.amountYear != res.data.result.electric.total.amountYear) {
        countStart6.value = res.data.result.electric.total.amountYear
      }
      if (data.value.water.total.amountToday != res.data.result.water.total.amountToday) {
        countStart7.value = res.data.result.water.total.amountToday
      }
      if (data.value.water.total.amountMonth != res.data.result.water.total.amountMonth) {
        countStart8.value = res.data.result.water.total.amountMonth
      }
      if (data.value.water.total.amountYear != res.data.result.water.total.amountYear) {
        countStart9.value = res.data.result.water.total.amountYear
      }
      if (data.value.photovoltaic.total.amountToday != res.data.result.photovoltaic.total.amountToday) {
        countStart10.value = res.data.result.photovoltaic.total.amountToday
      }
      if (data.value.photovoltaic.total.amountMonth != res.data.result.photovoltaic.total.amountMonth) {
        countStart11.value = res.data.result.photovoltaic.total.amountMonth
      }
      if (data.value.photovoltaic.total.amountYear != res.data.result.photovoltaic.total.amountYear) {
        countStart12.value = res.data.result.photovoltaic.total.amountYear
      }
      if (data.value.electric.total.totalEquipmentNumber != res.data.result.electric.total.totalEquipmentNumber) {
        countStart16.value = res.data.result.electric.total.totalEquipmentNumber
      }
      if (data.value.electric.total.onlineEquipmentNumber != res.data.result.electric.total.onlineEquipmentNumber) {
        countStart17.value = res.data.result.electric.total.onlineEquipmentNumber
      }
      if (data.value.electric.total.offlineEquipmentNumber != res.data.result.electric.total.offlineEquipmentNumber) {
        countStart18.value = res.data.result.electric.total.offlineEquipmentNumber
      }
      if (data.value.water.total.totalEquipmentNumber != res.data.result.water.total.totalEquipmentNumber) {
        countStart19.value = res.data.result.water.total.totalEquipmentNumber
      }
      if (data.value.water.total.onlineEquipmentNumber != res.data.result.water.total.onlineEquipmentNumber) {
        countStart20.value = res.data.result.water.total.onlineEquipmentNumber
      }
      if (data.value.water.total.offlineEquipmentNumber != res.data.result.water.total.offlineEquipmentNumber) {
        countStart21.value = res.data.result.water.total.offlineEquipmentNumber
      }
      if (data.value.photovoltaic.total.totalEquipmentNumber != res.data.result.photovoltaic.total.totalEquipmentNumber) {
        countStart22.value = res.data.result.photovoltaic.total.totalEquipmentNumber
      }
      if (data.value.photovoltaic.total.onlineEquipmentNumber != res.data.result.photovoltaic.total.onlineEquipmentNumber) {
        countStart23.value = res.data.result.photovoltaic.total.onlineEquipmentNumber
      }
      if (data.value.photovoltaic.total.offlineEquipmentNumber != res.data.result.photovoltaic.total.offlineEquipmentNumber) {
        countStart24.value = res.data.result.photovoltaic.total.offlineEquipmentNumber
      }

      if (data.value.airLine && data.value.airLine.length > 0) {
        if (JSON.stringify(data.value.airLine) != JSON.stringify(res.data.result.airLine)) {
          nextTick(() => {
            chartLoad1()
          })
        }
      }
      if (data.value.electric.lineList && data.value.electric.lineList.length > 0) {
        if (JSON.stringify(data.value.electric.lineList) != JSON.stringify(res.data.result.electric.lineList)) {
          nextTick(() => {
            chartLoad2()
          })
        }
      }
      if (data.value.water.lineList && data.value.water.lineList.length > 0) {
        if (JSON.stringify(data.value.water.lineList) != JSON.stringify(res.data.result.water.lineList)) {
          nextTick(() => {
            chartLoad3()
          })
        }
      }
      if (data.value.photovoltaic.lineList && data.value.photovoltaic.lineList.length > 0) {
        if (JSON.stringify(data.value.photovoltaic.lineList) != JSON.stringify(res.data.result.photovoltaic.lineList)) {
          nextTick(() => {
            chartLoad4()
          })
        }
      }

      if (data.value.electric.total.flowRate != data.value.electric.total.flowRate) {
        nextTick(() => {
          circleChartLoad2()
        })
      }
      if (data.value.water.total.flowRate != data.value.water.total.flowRate) {
        nextTick(() => {
          circleChartLoad3()
        })
      }
      if (data.value.photovoltaic.total.flowRate != data.value.photovoltaic.total.flowRate) {
        nextTick(() => {
          circleChartLoad4()
        })
      }

      if ((active1.value === 1 && data.value.airDay && data.value.airDay.length > 0 && JSON.stringify(data.value.airDay) != JSON.stringify(res.data.result.airDay)) || (active1.value === 2 && data.value.airMonth && data.value.airMonth.length > 0 && JSON.stringify(data.value.airMonth) != JSON.stringify(res.data.result.airMonth)) || (active1.value === 3 && data.value.airYear && data.value.airYear.length > 0 && JSON.stringify(data.value.airYear) != JSON.stringify(res.data.result.airYear))) {
        nextTick(() => {
          chartLoad5()
        })
      }
      if ((active2.value === 1 && data.value.electric.dayList && data.value.electric.dayList.length > 0 && JSON.stringify(data.value.electric.dayList) != JSON.stringify(res.data.result.electric.dayList)) || (active2.value === 2 && data.value.electric.monthList && data.value.electric.monthList.length > 0 && JSON.stringify(data.value.electric.monthList) != JSON.stringify(res.data.result.electric.monthList)) || (active2.value === 3 && data.value.electric.yearList && data.value.electric.yearList.length > 0 && JSON.stringify(data.value.electric.yearList) != JSON.stringify(res.data.result.electric.yearList))) {
        nextTick(() => {
          chartLoad6()
        })
      }
      if ((active3.value === 1 && data.value.water.dayList && data.value.water.dayList.length > 0 && JSON.stringify(data.value.water.dayList) != JSON.stringify(res.data.result.water.dayList)) || (active3.value === 2 && data.value.water.monthList && data.value.water.monthList.length > 0 && JSON.stringify(data.value.water.monthList) != JSON.stringify(res.data.result.water.monthList)) || (active3.value === 3 && data.value.water.yearList && data.value.water.yearList.length > 0 && JSON.stringify(data.value.water.yearList) != JSON.stringify(res.data.result.water.yearList))) {
        nextTick(() => {
          chartLoad7()
        })
      }
      if ((active4.value === 1 && data.value.photovoltaic.dayList && data.value.photovoltaic.dayList.length > 0 && JSON.stringify(data.value.photovoltaic.dayList) != JSON.stringify(res.data.result.photovoltaic.dayList)) || (active4.value === 2 && data.value.photovoltaic.monthList && data.value.photovoltaic.monthList.length > 0 && JSON.stringify(data.value.photovoltaic.monthList) != JSON.stringify(res.data.result.photovoltaic.monthList)) || (active4.value === 3 && data.value.photovoltaic.yearList && data.value.photovoltaic.yearList.length > 0 && JSON.stringify(data.value.photovoltaic.yearList) != JSON.stringify(res.data.result.photovoltaic.yearList))) {
        nextTick(() => {
          chartLoad8()
        })
      }

      data.value = res.data.result
      // 当日实时用气流量趋势
      // 当日实时用电功率
      data.value.electric.lineListX = []
      data.value.electric.lineListY = []
      for (let i = 0; i < data.value.electric.lineList.length; i++) {
        data.value.electric.lineListX.push(data.value.electric.lineList[i].key)
        data.value.electric.lineListY.push(Number(data.value.electric.lineList[i].value))
      }

      // 当日实时用水流量趋势
      data.value.water.lineListX = []
      data.value.water.lineListY = []
      for (let i = 0; i < data.value.water.lineList.length; i++) {
        data.value.water.lineListX.push(data.value.water.lineList[i].key)
        data.value.water.lineListY.push(Number(data.value.water.lineList[i].value))
      }
      // 当日实时光伏用量趋势
      data.value.photovoltaic.lineListX = []
      data.value.photovoltaic.lineListY = []
      for (let i = 0; i < data.value.photovoltaic.lineList.length; i++) {
        data.value.photovoltaic.lineListX.push(data.value.photovoltaic.lineList[i].key)
        data.value.photovoltaic.lineListY.push(Number(data.value.photovoltaic.lineList[i].value))
      }
      // 用气量统计
      data.value.air = {
        dayListX: [],
        dayListY: []
      }

      // 用电量统计
      data.value.electric.dayListX = []
      data.value.electric.dayListY = []
      // 当日用电量统计
      if (active2.value === 1) {
        for (let i = 0; i < data.value.electric.dayList.length; i++) {
          data.value.electric.dayListX.push(data.value.electric.dayList[i].key)
          data.value.electric.dayListY.push(Number(data.value.electric.dayList[i].value))
        }
      } else if (active2.value === 2) { // 当月用电量统计
        for (let i = 0; i < data.value.electric.monthList.length; i++) {
          data.value.electric.dayListX.push(data.value.electric.monthList[i].key)
          data.value.electric.dayListY.push(Number(data.value.electric.monthList[i].value))
        }
      } else if (active2.value === 3) { // 当年用电量统计
        for (let i = 0; i < data.value.electric.yearList.length; i++) {
          data.value.electric.dayListX.push(data.value.electric.yearList[i].key)
          data.value.electric.dayListY.push(Number(data.value.electric.yearList[i].value))
        }
      }

      // 用水量统计
      data.value.water.dayListX = []
      data.value.water.dayListY = []
      // 当日用水量统计
      if (active3.value === 1) {
        for (let i = 0; i < data.value.water.dayList.length; i++) {
          data.value.water.dayListX.push(data.value.water.dayList[i].key)
          data.value.water.dayListY.push(Number(data.value.water.dayList[i].value))
        }
      } else if (active3.value === 2) { // 当月用水量统计
        for (let i = 0; i < data.value.water.monthList.length; i++) {
          data.value.water.dayListX.push(data.value.water.monthList[i].key)
          data.value.water.dayListY.push(Number(data.value.water.monthList[i].value))
        }
      } else if (active3.value === 3) { // 当年用水量统计
        for (let i = 0; i < data.value.water.yearList.length; i++) {
          data.value.water.dayListX.push(data.value.water.yearList[i].key)
          data.value.water.dayListY.push(Number(data.value.water.yearList[i].value))
        }
      }

      // 光伏发电量统计
      data.value.photovoltaic.dayListX = []
      data.value.photovoltaic.dayListY = []
      // 当日光伏发电量统计
      if (active4.value === 1) {
        for (let i = 0; i < data.value.photovoltaic.dayList.length; i++) {
          data.value.photovoltaic.dayListX.push(data.value.photovoltaic.dayList[i].key)
          data.value.photovoltaic.dayListY.push(Number(data.value.photovoltaic.dayList[i].value))
        }
      } else if (active4.value === 2) { // 当月光伏发电量统计
        for (let i = 0; i < data.value.photovoltaic.monthList.length; i++) {
          data.value.photovoltaic.dayListX.push(data.value.photovoltaic.monthList[i].key)
          data.value.photovoltaic.dayListY.push(Number(data.value.photovoltaic.monthList[i].value))
        }
      } else if (active4.value === 3) { // 当年光伏发电量统计
        for (let i = 0; i < data.value.photovoltaic.yearList.length; i++) {
          data.value.photovoltaic.dayListX.push(data.value.photovoltaic.yearList[i].key)
          data.value.photovoltaic.dayListY.push(Number(data.value.photovoltaic.yearList[i].value))
        }
      }

      if (countStart1.value.length === 0 && countStart2.value.length === 0 && countStart3.value.length === 0 && countStart13.value.length === 0 && countStart14.value.length === 0 && countStart15.value.length === 0) {
        for (let i = 0; i < data.value.airTotalList.length; i++) {
          countStart1.value.push(data.value.airTotalList[i].amountToday)
          countStart2.value.push(data.value.airTotalList[i].amountMonth)
          countStart3.value.push(data.value.airTotalList[i].amountYear)
          countStart13.value.push(data.value.airTotalList[i].totalEquipmentNumber)
          countStart14.value.push(data.value.airTotalList[i].onlineEquipmentNumber)
          countStart15.value.push(data.value.airTotalList[i].offlineEquipmentNumber)
          nextTick(() => {
            circleChartLoad(i, data.value.airTotalList[i].flowRate)
          })
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

onMounted(async () => {
  document.title = "惠生能源监控大屏"
  await getDataFun()
  updateTime()
  nextTick(() => {
    // circleChartLoad()
    circleChartLoad2()
    circleChartLoad3()
    circleChartLoad4()
    chartLoad1()
    chartLoad2()
    chartLoad3()
    chartLoad4()
    chartLoad5()
    chartLoad6()
    chartLoad7()
    chartLoad8()
  })
})

// 定时器
const intervalId2 = setInterval(getDataFun, 30000)
const intervalId = setInterval(updateTime, 1000)
onUnmounted(() => {
  clearInterval(intervalId)
  clearInterval(intervalId2)
})
</script>

<style lang="less" scoped>

</style>