import {api} from "@/api";

//获取详情列表
export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/getDetail',
        method: 'POST',
        data: data
    })
}
//获取所有抄读项
export const getParserItemList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parserItem/listSelect',
        method: 'POST',
        data: data
    })
}
// 获取产品分类列表
export const getAllCategoryList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/category/listAll',
        method: 'POST',
        data: data
    })
}
//获取所有抄读方式
export const getAllParserList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/v3/listSelect',
        method: 'POST',
        data: data
    })
}
//产品新增的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/save',
        method: 'POST',
        data: data
    })
}
//获取控制类型列表
export const getListTypeSelect = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productControl/listTypeSelect',
        method: 'POST',
        data: data
    })
}
//控制新增、编辑的保存
export const controlSaveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productControl/save',
        method: 'POST',
        data: data
    })
}
//获取控制详情
export const getControlDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productControl/getDetail',
        method: 'POST',
        data: data
    })
}
//控制的删除
export const controlDelete = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productControl/delete',
        method: 'POST',
        data: data
    })
}
//更新选中
export const updateCheck = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/updateCheck',
        method: 'POST',
        data: data
    })
}
//获取数据项选项
export const getDataItemCheck = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/getDataItemCheck',
        method: 'POST',
        data: data
    })
}
//更新数据项
export const updateDataItemSave = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/updateDataItem',
        method: 'POST',
        data: data
    })
}
/*事件*/
//获取事件详情
export const getEventDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productCategory/getDetail',
        method: 'POST',
        data: data
    })
}
//事件的保存
export const eventSave = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productCategory/save',
        method: 'POST',
        data: data
    })
}
//事件的删除
export const eventDeleteFun = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productCategory/delete',
        method: 'POST',
        data: data
    })
}

//图片上传
export const uploadImg = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/upload/uploadSingle',
        method: 'POST',
        data: data
    })
}
//图片的保存
export const saveImg = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/saveImage',
        method: 'POST',
        data: data
    })
}
