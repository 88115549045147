import {api} from "@/api";

// 获取菜单列表
export const getMenuListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/menu/list',
        method: 'POST',
        data: data
    })
}

// 保存菜单列表
export const saveMenuListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/menu/save',
        method: 'POST',
        data: data
    })
}
// 删除菜单列表
export const delMenuListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/menu/delete',
        method: 'POST',
        data: data
    })
}
// 获取菜单最大排序
export const getMenuMaxSortListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/menu/getMaxSortNumber',
        method: 'POST',
        data: data
    })
}


// 上传
export const uploadImg = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/upload/uploadSingle',
        method: 'POST',
        data: data
    })
}
