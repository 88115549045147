import {api, api3} from "@/api";

import axios from "axios";

// 南向设备列表
export const getNode = (data) => {
    return api3({
        url: '/api/neuron/node',
        method: 'GET',
        params: data
    })
}
// 插件类型列表
export const getPlugin = (data) => {
    return api3({
        url: '/api/neuron/plugin',
        method: 'GET',
        params: data
    })
}
//详情
export const getState = (data) => {
    return api3({
        url: '/api/neuron/node/state',
        method: 'GET',
        params: data
    })
}
//控制
export const ctl = (data) => {
    return api3({
        url: '/api/neuron/node/ctl',
        method: 'POST',
        data: data
    })
}
//复制的获取
export const driversList = (data) => {
    return api3({
        url: '/api/neuron/global/drivers',
        method: 'GET',
        params: data
    })
}
// 删除南向设备列表
export const nodeDelete = (data) => {
    return api3({
        url: '/api/neuron/node',
        method: 'DELETE',
        data: data
    })
}
//复制操作
export const driversControl = (data) => {
    return api3({
        url: '/api/neuron/global/drivers',
        method: 'PUT',
        data: data
    })
}
//开启/关闭debug日志
export const levelControl = (data) => {
    return api3({
        url: '/api/neuron/log/level',
        method: 'PUT',
        data: data
    })
}
//设备的编辑
export const nodeEditSave = (data) => {
    return api3({
        url: '/api/neuron/node',
        method: 'PUT',
        data: data
    })
}
//设备详细信息
export const metricsList = (data) => {
    return api3({
        url: '/api/neuron/metrics',
        method: 'GET',
        params: data
    })
}
//设备的导出
export const template = (data) => {
    return api3({
        url: '/api/template',
        method: 'GET',
        params: data
    })
}
//设备的导入
export const templateImport = (data) => {
    return api3({
        url: '/api/template?' + 'category=2',
        method: 'POST',
        data: data
    })
}
/*组**************************************************/
//组列表
export const getGroups = (data) => {
    return api3({
        url: '/api/neuron/group',
        method: 'GET',
        params: data
    })
}
//组的添加
export const groupAddSave = (data) => {
    return api3({
        url: '/api/neuron/group',
        method: 'POST',
        data: data
    })
}
//新组的添加
export const newGroupAddSave = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentGroupTags/addGroup',
        method: 'POST',
        data: data
    })
}
//组的编辑
export const groupEditSave = (data) => {
    return api3({
        url: '/api/neuron/group',
        method: 'PUT',
        data: data
    })
}
//组的删除
export const groupDelete = (data) => {
    return api3({
        url: '/api/neuron/group',
        method: 'DELETE',
        data: data
    })
}
//组的导出
export const tags = (data) => {
    return api3({
        url: '/api/neuron/tags',
        method: 'GET',
        params: data
    })
}
//组的导入
export const importGtags = (data) => {
    return api3({
        url: '/api/neuron/gtags',
        method: 'POST',
        data: data
    })
}
/*点位*******************************************/
//点位列表
export const getTags = (data) => {
    return api3({
        url: '/api/neuron/tags',
        method: 'GET',
        params: data
    })
}
//点位的删除
export const tagsDelete = (data) => {
    return api3({
        url: '/api/neuron/tags',
        method: 'DELETE',
        data: data
    })
}
//新点位的删除
export const newTagsDelete = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentGroupTags/delete',
        method: 'POST',
        data: data
    })
}

//点位的添加
export const tagsAddSave = (data) => {
    return api3({
        url: '/api/neuron/tags',
        method: 'POST',
        data: data
    })
}
//点位的编辑
export const tagsEditSave = (data) => {
    return api3({
        url: '/api/neuron/tags',
        method: 'PUT',
        data: data
    })
}


// 南向设备新增到设备
export const saveSouthEquipmentApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentLocal/saveDirect',
        method: 'POST',
        data: data
    })
}

// 字典列表
export const getDictSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dict/listKeySelect',
        method: 'POST',
        data: data
    })
}

// 添加点位
export const addTagsApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentGroupTags/add',
        method: 'POST',
        data: data
    })
}