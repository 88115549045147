<template>
  <div class="northDriver">
    <div class="title">
      <p>
        <span>北向应用</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px;">插件类型</label>
          <el-select v-model="plugType" clearable placeholder="请选择插件类型">
            <el-option v-for="(item,index) in selectList" :key="index" :label="item.name==='eKuiper'?'ZKData':item.name"
                       :value="item.name"></el-option>
          </el-select>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px;">名称</label>
          <el-input v-model="name" :prefix-icon="Search" clearable placeholder="请输入关键字搜索"></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--内容-->
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">
            添加应用
          </el-button>
        </div>
        <!--列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面添加应用</p>
            </el-empty>
          </template>
          <el-table-column label="名称" property="name">
            <template #default="scope">
              <span style="cursor: pointer; color:rgb(22, 93, 255);"
                    @click="router.push(`/admin/northGroup/?name=${scope.row.name}`)">{{
                  scope.row.name
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="工作状态" property="status">
            <template #default="scope">
              <span :class="scope.row.running===3 ?'start':'stop'">{{ scope.row.running === 3 ? '运行中' : '停止' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="连接状态" property="linkStatus">
            <template #default="scope">
              <p :style="{color:scope.row.link===1 ?'rgba(0, 0, 0, 0.85)':'#bac1cd'}">
                {{ scope.row.link === 1 ? '连接' : '断开' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="订阅组总数" property="sub_group_count"/>
          <el-table-column label="插件" property="plugin"/>
          <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <ul class="operationUl">
                <li>
                  <el-tooltip
                      :content="scope.row.switch?'停止':'启动'"
                      class="box-item"
                      effect="dark"
                  >
                    <el-switch :value="scope.row.switch" style="--el-switch-on-color: #ccefe3;"
                               @change="isOpenChange($event,scope.row)"/>
                  </el-tooltip>
                </li>
                <li @click="router.push(`/admin/northGroup/?name=${scope.row.name}`)">
                  <el-tooltip
                      class="box-item"
                      content="添加订阅"
                      effect="dark"
                  >
                    <el-icon color="rgba(0, 0, 0, 0.85)" size="20">
                      <CollectionTag/>
                    </el-icon>
                  </el-tooltip>
                </li>
                <li>
                  <el-popover
                      popper-class="moreOpera" trigger="click">
                    <template #reference>
                      <p class="more"> ... </p>
                    </template>
                    <div class="listPox">
                      <p @click="editClick(scope.row)">
                        <el-icon>
                          <EditPen/>
                        </el-icon>
                        <span>编辑应用</span>
                      </p>
                      <p @click="router.push(`/admin/northDetail?name=${scope.row.name}&plugin=${scope.row.plugin}&type=1`)">
                        <el-icon>
                          <DataAnalysis/>
                        </el-icon>
                        <span>数据统计</span>
                      </p>
                      <p @click="router.push(`/admin/northDetail?name=${scope.row.name}&plugin=${scope.row.plugin}&type=2`)">
                        <el-icon>
                          <Setting/>
                        </el-icon>
                        <span>应用配置</span>
                      </p>
                      <p @click="controlLogFun(scope.row)">
                        <el-icon>
                          <Memo/>
                        </el-icon>
                        <span>{{ scope.row.log_level === 'notice' ? '开启' : '关闭' }}DEBUG日志</span>
                      </p>
                      <p @click="downloadLogFun(scope.row)">
                        <el-icon>
                          <Download/>
                        </el-icon>
                        <span>下载驱动日志</span>
                      </p>
                      <p @click="delClick(scope.row.name)">
                        <el-icon>
                          <Delete/>
                        </el-icon>
                        <span>删除</span>
                      </p>
                    </div>
                  </el-popover>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <!--        <div class="footerBox">-->
        <!--          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background-->
        <!--                         layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"-->
        <!--                         @current-change="pageChange"/>-->
        <!--        </div>-->
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="添加应用"
               width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="名称" prop="name" style="margin-bottom: 20px">
          <el-input v-model="formData.name" placeholder="请输入应用名称"></el-input>
        </el-form-item>
        <el-form-item label="插件" prop="plugin">
          <el-select v-model="formData.plugin" clearable filterable placeholder="请选择插件" @change="selectChange">
            <el-option v-for="(item,index) in selectList" :key="index" :label="item.name==='eKuiper'?'ZKData':item.name"
                       :value="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="formData.params" label-width="auto">
        <!--接口请求循环出来的表单数据-->
        <el-form-item v-for="(value, key, index) in formObj" :key="index" :prop="key"
                      :rules="[{
                        required: value.attribute==='required'&&(!value.condition||formData.params[value.condition.field]===value.condition.value),
                        message: `${value.type==='string'||value.type==='int'?'请输入':'请选择'}${value.name_zh}`,
                        trigger: 'blur',
                      }]"
                      class="formItem">
          <div v-if="!value.condition||formData.params[value.condition.field]===value.condition.value" slot="label"
               class="labelBox"
               style="margin-top: 20px">
            <i v-if="value.attribute==='required'" style="color: red;">*</i>
            <p style="margin-left: 5px">{{ value.name_zh }}</p>
            <el-tooltip
                :content="value.description_zh"
                effect="dark"
                placement="top"
                popper-class="el_tooltip_item"
            >
              <el-icon>
                <QuestionFilled/>
              </el-icon>
            </el-tooltip>
          </div>
          <div class="widthBox">
            <!--输入框-->
            <el-input
                v-if="value.type==='string'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" :maxlength="value.valid.length"></el-input>
            <!--下拉框-->
            <el-select
                v-if="value.type==='map'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" clearable>
              <el-option v-for="(item,index) in value.valid.map" :key="index" :label="item.key"
                         :value="item.value"></el-option>
            </el-select>
            <!--单选框-->
            <el-radio-group
                v-if="value.type==='bool'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]">
              <el-radio :label="true" border>True</el-radio>
              <el-radio :label="false" border>False</el-radio>
            </el-radio-group>
            <!--数字输入框-->
            <el-input-number
                v-if="value.type==='int'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]"
                :controls="false" :max="value.valid.max" :min="value.valid.min"/>
            <!--上传文件-->
            <el-upload
                v-if="value.type==='file'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model:file-list="formData.params[key+'-fileList']" :auto-upload="false"
                action="#" class="upload-demo" limit="1" @change="uploadFile($event,key)">
              <div class="uploadBox">
                <div :class="formData.params[key+'-fileList']&&formData.params[key+'-fileList'].length>0?'none':''"
                     class="upBtnBox">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <span>点击上传</span>
                </div>
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible2" :close-on-click-modal="false" title="编辑应用"
               width="40%">
      <el-form ref="userForm2" :inline="true" :model="formData2" :rules="formRules2" label-width="auto">
        <el-form-item label="名称" prop="name" style="margin-bottom: 20px">
          <el-input v-model="formData2.name" placeholder="请输入应用名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible2=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save2()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, onUnmounted, reactive, ref} from "vue"
import './northDriver.less'
import {
  Plus,
  Search,
  CollectionTag,
  EditPen,
  DataAnalysis,
  Setting,
  Memo,
  Download,
  Delete,
  QuestionFilled,
  Document
} from '@element-plus/icons-vue'
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {useRouter} from "vue-router/dist/vue-router";
import {
  getSelectListApi,
  getListApi,
  getListDataApi,
  controlRunApi,
  delDataApi,
  controlLogApi,
  downloadLogApi,
  addDataApi,
  editDataApi,
  getFormDataApi
} from "@/api/modules/northDriver";


let router = useRouter()


// 获取列表数据*****************************************************************************
// 搜索
let plugType = ref('')
let name = ref('')

/*// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}*/

// 表格数据
let tableData = ref([])

// 获取列表*****************************************************************************
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getListApi({
    type: 2,
    node: name.value,
    plugin: plugType.value
  }).then(res => {
    loading.value = false
    if (res.status === 200) {
      tableData.value = res.data.nodes
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].plugin === 'eKuiper') {
          tableData.value[i].plugin = 'ZKData'
        }
      }
      getListDataFun()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
const getListDataFun = function () {
  getListDataApi().then(res => {
    if (res.status === 200) {
      for (let i = 0; i < tableData.value.length; i++) {
        for (let j = 0; j < res.data.states.length; j++) {
          res.data.states[j].switch = res.data.states[j].running === 3
          if (tableData.value[i].name === res.data.states[j].node) {
            tableData.value[i] = {...tableData.value[i], ...res.data.states[j]}
          }
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}

// 查询
const searchFun = function () {
  // pageNumber.value = 1
  getListFun()
}
// 重置
const resetFun = function () {
  plugType.value = ''
  name.value = ''
  // pageNumber.value = 1
  getListFun()
}


// 应用连接断开
const isOpenChange = function (value, scope) {
  controlRunApi({
    cmd: scope.running === 3 ? 1 : 0,
    node: scope.name,
  }).then(res => {
    loading.value = false
    if (res.status === 200) {
      getListFun()
      ElMessage({
        message: scope.running === 3 ? '停止成功' : '运行成功',
        type: 'success'
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}


// 添加编辑应用*****************************************************************************
// 点击添加应用
// 应用表单数据
let formData = ref({
  name: '',
  plugin: '',
  params: {}
})
let formRules = ref({
  name: [{
    required: true,
    message: '请输入应用名称',
    trigger: 'change',
  }],
  plugin: [{
    required: true,
    message: '请选择插件',
    trigger: 'blur'
  }]
})
// 新增编辑表单弹窗
let dialogFormVisible = ref(false)
// 新增应用点击
const addClick = function () {
  formData.value = {
    name: '',
    plugin: '',
    params: {}
  }
  dialogFormVisible.value = true
}
// 插件选择事件
let formObj = ref({})
const selectChange = function () {
  getFormDataApi({
    schema_name: formData.value.plugin.split(" ")[0].toLowerCase()
  }).then(res => {
    if (res.status === 200) {
      formData.value = {
        name: formData.value.name,
        plugin: formData.value.plugin,
        params: {}
      }
      formObj.value = res.data
      let num = 0
      for (const key in formObj.value) {
        formData.value.params[key] = formObj.value[key].default
        if (formObj.value[key].type === 'file') {
          let fileList = key + '-fileList'
          formData.value.params[fileList] = []
          num++
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}
// 文件上传
const uploadFile = function (file, key) {
  const reader = new FileReader();
  reader.readAsDataURL(file.raw);
  reader.onload = (e) => {
    formData.value.params[key] = reader.result.split(",")[1]
  };
}
// 提交应用表单
let userForm = ref()
const save = function () {
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      addDataApi(formData.value).then(res => {
        if (res.status === 200) {
          getListFun()
          dialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}


// 编辑编辑应用*****************************************************************************
// 应用表单数据
let formData2 = ref({
  name: '',
})
let formRules2 = ref({
  name: [{
    required: true,
    message: '请输入应用名称',
    trigger: 'change',
  }],
})
// 编辑表单弹窗
let dialogFormVisible2 = ref(false)
// 编辑点击
let oldName = ref()
const editClick = function (row) {
  dialogFormVisible2.value = true
  oldName.value = row.name
  formData2.value = {
    name: row.name
  }
}
// 提交应用表单
let userForm2 = ref()
const save2 = function () {
  userForm2.value.validate((valid) => {
    //提交成功
    if (valid) {
      editDataApi({
        name: oldName.value,
        new_name: formData2.value.name
      }).then(res => {
        if (res.status === 200) {
          getListFun()
          dialogFormVisible2.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}

// 删除应用*****************************************************************************
const delClick = function (name) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除应用',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此应用，删除后数据不可恢复'),
    ])
  }).then(() => {
    delDataApi({
      name: name
    }).then(res => {
      if (res.status === 200) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
      ElMessage({
        type: 'error',
        message: err.response.data.message,
      })
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

// 开启或关闭DEBUG日志*****************************************************************************
const controlLogFun = function (scope) {
  openFullScreen()
  controlLogApi({
    node: scope.name,    //应用名称
    level: scope.log_level === 'notice' ? 'debug' : 'notice',  //关闭notice    开启debug
    core: false
  }).then(res => {
    loadingAdd.value.close()
    if (res.status === 200) {
      ElMessage({
        type: 'success',
        message: scope.log_level === 'notice' ? '已开启DEBUG日志' : '已关闭DEBUG日志',
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}

// 下载驱动日志*****************************************************************************
const downloadLogFun = function (scope) {
  const downloadZip = (data, fileName = '驱动日志') => {
    var blob = new Blob([data], {type: 'application/x-tar'})
    if ('download' in document.createElement('a')) {
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) //创建下载的链接
      downloadElement.href = href
      downloadElement.download = fileName //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    }
  }
  openFullScreen()
  downloadLogApi(scope.name).then(res => {
    loadingAdd.value.close()
    if (res.status === 200) {
      downloadZip(res.data)
      ElMessage({
        type: 'success',
        message: '下载成功',
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })
}


// 下拉列表数据
let selectList = ref([])

onMounted(async () => {
  // 获取下拉列表数据
  getSelectListApi().then(res => {
    if (res.status === 200) {
      for (let i = 0; i < res.data.plugins.length; i++) {
        if (res.data.plugins[i].node_type === 2) {
          selectList.value.push(res.data.plugins[i])
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
    ElMessage({
      type: 'error',
      message: err.response.data.message,
    })
  })

  await getListFun()

})


// 定时器
const intervalId = setInterval(getListDataFun, 10000)
onUnmounted(() => {
  clearInterval(intervalId)
})

</script>

<style lang="less" scoped>
</style>
