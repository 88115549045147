<template>
  <div class="userSetting">
    <!--标题-->
    <div class="title">
      <el-icon @click="goBack">
        <Back/>
      </el-icon>
      <span>设置</span>
    </div>
    <!--表格-->
    <div class="tabBox">
      <div class="nameBox row">
        <p class="label">名称</p>
        <p class="val">{{ userInfo.name }}</p>
      </div>
      <div class="accountBox row">
        <p class="label">用户账号（登陆账号）</p>
        <p class="val">{{ userInfo.account }}</p>
      </div>
      <div class="telBox row">
        <p class="label">手机号</p>
        <p class="val">{{ userInfo.mobile }}</p>
        <!--        <p @click="()=>{dialogTel=true;telForm.mobile=userInfo.mobile}">修改</p>-->
      </div>
      <div class="passBox row">
        <p class="label">密码</p>
        <p class="val">******</p>
        <p @click="dialogPass=true">修改</p>
      </div>
    </div>
    <!--修改手机号弹窗-->
    <el-dialog v-model="dialogTel" title="修改手机号" width="520px">
      <div class="cont">
        <el-form ref="telFormRef" :model="telForm" :rules="telRules">
          <el-form-item label="手机号:" prop="tel" request>
            <el-input v-model="telForm.mobile" class="input-with-select" type="number"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="dialogTel = false">取消</el-button>
        <el-button type="primary" @click="editTelSubmit">确定</el-button>
      </span>
      </template>
    </el-dialog>
    <!--修改密码弹窗-->
    <el-dialog v-model="dialogPass" title="修改密码" width="520px">
      <div class="cont">
        <el-form ref="passFormRef" :model="passForm" :rules="passRules" class="demo-ruleForm" status-icon>
          <el-form-item label="旧密码" prop="oldPassword" request>
            <el-input v-model="passForm.oldPassword" show-password type="password"/>
          </el-form-item>
          <el-form-item label="新密码" prop="pass2" request>
            <el-input v-model="passForm.pass2" show-password type="password"/>
          </el-form-item>
          <el-form-item label="确认新密码" prop="newPassword" request>
            <el-input v-model="passForm.newPassword" show-password type="password"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="dialogPass = false">取消</el-button>
        <el-button type="primary" @click="editPassSubmit">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {Back} from '@element-plus/icons-vue'
import {useRouter} from 'vue-router';
import {ElMessage, ElMessageBox} from "element-plus";
import {getLoginDetailApi} from "@/api/modules/login";
import {updatePassApi} from "@/api/modules/tissue";
import qs from "qs";

let router = useRouter()
// 返回
const goBack = function () {
  router.go(-1)
}

// 用户信息*******************************************************
let userInfo = ref({})
onMounted(() => {
  getLoginDetailApi().then(res => {
    if (res.data.code === 0) {
      userInfo.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
})

// 手机号修改*******************************************************
let mobileValue = ref(1)
let telPass = ref(false)
let dialogTel = ref(false)
let telForm = ref({
  mobile: ''
})
// 正确的手机号正则
let telVal = /^[1][3,4,5,7,8][0-9]{9}$/;
let telValidator = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号码'))
  } else if (!telVal.test(value)) {
    callback(new Error('请输入有效的电话号码'))
  } else {
    callback()
  }
}
let telRules = ref({
  mobile: [{
    required: true,
    trigger: 'blur',
    validator: telValidator
  }]
})
let telFormRef = ref()
const editTelSubmit = function () {
  if (telFormRef) {
    telFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {

      }
    })
  }
}
// 密码修改*******************************************************
let dialogPass = ref(false)
let passForm = ref({
  oldPassword: '',
  pass2: '',
  newPassword: '',
})

// 编辑新建管理员的表单规则
let againPass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== passForm.value.pass2) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}

//密码验证规则
// 密码校验 长度不能小于8位且不能大于20位字符,必须包含大写字母、小写字母、数字和特殊符号
let ISPWD = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,\._\+(){}])[0-9a-zA-Z!@#$%^&*,\\._\+(){}]{8,20}$/;
let passwordValidator = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入新密码'))
  } else if (!ISPWD.test(value)) {
    callback(new Error('密码长度 8-20 位，至少包含以下四种字符类型（数字、大写字母、小写字母、英文符号）'))
  } else {
    callback()
  }
}
let passRules = ref({
  oldPassword: [{
    required: true,
    message: '请输入旧密码',
    trigger: 'blur'
  },],
  pass2: [{
    required: true,
    trigger: 'blur',
    validator: passwordValidator
  },],
  newPassword: [{
    required: true,
    trigger: "blur",
    validator: againPass
  }]
})
let passFormRef = ref()
const editPassSubmit = function () {
  if (passFormRef) {
    passFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        updatePassApi(qs.stringify(passForm.value)).then(res => {
          if (res.data.code === 0) {
            dialogPass.value = false
            ElMessage({
              message: '修改密码成功',
              type: 'success'
            })
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
</script>

<style lang="less">
.userSetting {
  margin: 32px auto;
  padding: 24px 32px;
  width: 946px;
  background-color: #fff;
  height: calc(100% - 112px);
  overflow: auto;
  //标题
  .title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    font-weight: 600;

    .el-icon {
      font-size: 20px;
      font-weight: bold;
      margin-right: 8px;
      transform: translateY(4px);
    }
  }

  //表格
  .tabBox {
    width: 100%;
    margin-top: 10px;
    color: rgba(0, 0, 0, .85);

    .row {
      width: 100%;
      height: 48px;
      border-bottom: 1px solid #efefef;
      line-height: 48px;

      p {
        display: inline-block;
      }

      p:nth-child(1) {
        width: 360px;
        margin-left: 10px;
      }

      p:nth-child(2) {
        width: 360px;
      }

      p:nth-child(3) {
        color: rgb(24, 144, 255);
        cursor: pointer;
      }
    }
  }

  .el-dialog {
    .cont {
      margin-bottom: 20px;

      > .el-input {
        width: calc(100% - 56px);
      }
    }

    .el-dialog__header {
      border-bottom: 1px solid #efefef;
    }

    .el-dialog__footer {
      border-top: 1px solid #efefef;
    }

    .el-form-item {
      display: block;
    }
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background-color: transparent !important;
  }
}
</style>