<template>
  <div class="energyFlow">
    <div class="sankeyChart"></div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onActivated, onMounted, reactive, ref} from "vue"
import * as echarts from "echarts";

let data = ref([{
  name: 'a',
  children: [
    {
      name: 'b1',
      children: [
        {
          name: 'c1'
        },
        {
          name: 'c2'
        }
      ]
    },
    {
      name: 'b2',
      children: [
        {
          name: 'c3'
        },
        {
          name: 'c4'
        },
        {
          name: 'c5'
        }
      ]
    },
    {
      name: 'b3',
      children: [
        {
          name: 'c6'
        }
      ]
    }
  ]
}])

const fun = function () {

}


// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.sankeyChart');
  var myChart = echarts.init(chartDom);
  var option;
  let a = '44%'
  option = {
    // grid: {
    //   left: 50,
    //   right: 20
    // },
    // legend: {
    //   show: true,
    //   type: 'scroll'
    // },
    // tooltip: {
    //   trigger: 'axis', // axis   item   none三个值
    //   show: true
    // },
    // xAxis: {
    //   type: 'category',
    //   boundaryGap: true,
    //   data: echartsDataX.value
    // },
    // yAxis: {
    //   type: 'value',
    // },
    series: {
      type: 'sankey',
      layout: 'none',
      layoutIterations: 0,  //是否根据data顺序进行展示，默认32
      emphasis: {
        focus: 'adjacency'
      },
      data: [
        {
          name: 'a'
        },
        {
          name: 'b1'
        },
        {
          name: 'b2'
        },
        {
          name: 'b3'
        },
        {
          name: 'c1'
        },
        {
          name: 'c2'
        },
        {
          name: 'c3'
        },
        {
          name: 'c4'
        },
        {
          name: 'c5'
        },
        {
          name: 'c6'
        },

      ],
      links: [
        {
          source: 'a',
          target: 'b1',
          value: 5
        },
        {
          source: 'a',
          target: 'b2',
          value: 3
        },
        {
          source: 'a',
          target: 'b3',
          value: 8
        },
        {
          source: 'b1',
          target: 'c1',
          value: 3
        },
        {
          source: 'b1',
          target: 'c2',
          value: 1
        },
        {
          source: 'b1',
          target: 'c3',
          value: 1
        },
        {
          source: 'b2',
          target: 'c4',
          value: 3
        },
        {
          source: 'b3',
          target: 'c5',
          value: 6
        },
        {
          source: 'b3',
          target: 'c6',
          value: 2
        }
      ],
      levels: [
        {
          depth: 0,
          itemStyle: {
            color: '#fbb4ae'
          },
          lineStyle: {
            color: 'source',
            opacity: 0.6
          }
        },
        {
          depth: 1,
          itemStyle: {
            color: '#b3cde3'
          },
          lineStyle: {
            color: 'source',
            opacity: 0.6
          }
        },
        {
          depth: 2,
          itemStyle: {
            color: '#ccebc5'
          },
          lineStyle: {
            color: 'source',
            opacity: 0.6
          }
        },
        {
          depth: 3,
          itemStyle: {
            color: '#decbe4'
          },
          lineStyle: {
            color: 'source',
            opacity: 0.6
          }
        }
      ],
      label: {
        formatter: '{b}\n{c}' + ' ' + a,
        fontSize: 18
      }
    }
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
onMounted(() => {
  nextTick(() => {
    chartLoad()
  })
})
</script>

<style lang="less" scoped>
.energyFlow {
  width: 100%;
  height: calc(100% - 1px);
  background-color: #fff;
  border-top: 1px solid #efefef;
  overflow: auto;

  .sankeyChart {
    width: 100%;
    height: 500px;
  }
}
</style>
