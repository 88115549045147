<template>
  <div class="log">
    <div class="title">
      <p>
        <span>操作日志</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div style="display: flex; width: calc(100% - 136px);">
          <div class="liBox">
            <span>日志标题</span>
            <el-input v-model="title" clearable placeholder="请输入日志标题" style="width: 74%;"></el-input>
          </div>
          <div class="liBox">
            <span>日志备注</span>
            <el-input v-model="remark" clearable placeholder="请输入日志备注" style="width: 74%;"></el-input>
          </div>
          <div class="liBox" style="margin-right: 0">
            <span>时间</span>
            <el-date-picker v-model="time" end-placeholder="结束时间" popper-class="date-style"
                            range-separator="→"
                            start-placeholder="开始时间" style="max-width: 200px;" type="datetimerange"
                            value-format="YYYY-MM-DD HH:mm:ss">
            </el-date-picker>
          </div>
        </div>
        <div class="liBox" style="width: 136px;margin-right: 24px">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--日志列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%;" table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建设备信息</p>
            </el-empty>
          </template>
          <el-table-column fixed label="标题" min-width="140px" prop="title"></el-table-column>
          <el-table-column label="用户名称" min-width="120px" prop="memberName"></el-table-column>
          <el-table-column label="组织名称" min-width="150px" prop="customerName"></el-table-column>
          <el-table-column label="创建时间" min-width="200px" prop="createTime"></el-table-column>
          <el-table-column label="修改前内容" min-width="150px" prop="beforeContent">
            <template #default="scope">
              <!--              <el-tooltip effect="light" :content="scope.row.beforeContent" placement="top">-->
              <p class="cont">{{ scope.row.beforeContent }}</p>
              <!--              </el-tooltip>-->
            </template>
          </el-table-column>
          <!--          <el-table-column label="修改后内容" width="600" prop="currentContent">-->
          <el-table-column label="修改后内容" min-width="150px" prop="currentContent">
            <template #default="scope">
              <!--              <el-tooltip effect="light" :content="scope.row.currentContent" placement="top">-->
              <p class="cont">{{ scope.row.currentContent }}</p>
              <!--                <span>{{ scope.row.roleStr }}</span>-->
              <!--              </el-tooltip>-->
            </template>
          </el-table-column>
          <el-table-column label="备注" min-width="80px" prop="remark">
            <template #default="scope"><p class="cont">{{ scope.row.remark }}</p></template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="140px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="lookDetail(scope.row)">查看详情</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-show="tableData.length > 0" v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]"
                         :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="查看日志"
               width="40%">
      <div class="logCont">
        <div class="liBox">
          <p>标题：</p>
          <p>{{ logDetail.title }}</p>
        </div>
        <div class="liBox">
          <p>用户名称：</p>
          <p>{{ logDetail.memberName }}</p>
        </div>
        <div class="liBox">
          <p>组织名称：</p>
          <p>{{ logDetail.customerName }}</p>
        </div>
        <div class="liBox">
          <p>创建时间：</p>
          <p>{{ logDetail.createTime }}</p>
        </div>
        <div class="liBox">
          <p>修改前内容：</p>
          <p>{{ logDetail.beforeContent }}</p>
        </div>
        <div class="liBox">
          <p>修改后内容：</p>
          <p>{{ logDetail.currentContent }}</p>
        </div>
        <div class="liBox">
          <p>备注：</p>
          <p>{{ logDetail.remark }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {CloseBold, ZoomIn, Plus, Delete} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {getLogApi} from "@/api/modules/log";

let router = useRouter()

// 告警列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 搜索条件
let title = ref()
let remark = ref()
let deviceAddress = ref()
let time = ref()
let warnLevel = ref()
const filterHandler = (value, row, column) => {
  const property = column['property']
  return row[property] === value
}
// 参数表格数据
let tableData = ref([])
// 获取告警列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getLogApi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    title: title.value,
    remark: remark.value,
    startTime: time.value ? time.value[0] : '',
    endTime: time.value ? time.value[1] : '',
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询
const searchFun = function () {
  pageNumber.value = 1
  getListFun()
}

// 查看详情弹窗
let dialogFormVisible = ref(false)
let logDetail = ref()

// 点击查看详情
const lookDetail = function (row) {
  dialogFormVisible.value = true
  logDetail.value = row
}


onMounted(() => {
  getListFun()
})


</script>

<style lang="less" scoped>
.log {
  width: 100%;
  height: 100%;

  //标题
  .title {
    width: calc(100% - 36px);
    height: 48px;
    padding-left: 36px;
    line-height: 48px;
    //background-color: #fff;
    /* span {
       color: rgba(0, 0, 0, 0.45);
     }*/
  }

  //内容
  .content {
    width: calc(100% - 56px);
    height: calc(100% - 80px);
    padding: 0 28px 16px 28px;
    overflow: auto;

    //搜索
    .searchBox {
      margin-bottom: 16px;
      padding: 24px;
      width: calc(100% - 48px);
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      border-radius: 6px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);

      .liBox {
        //margin-bottom: 16px;
        margin-right: 24px;
        display: flex;
        align-items: center;

        > p {
          margin-bottom: 8px;
        }

        > span {
          margin-right: 8px;
        }

        .el-select, .el-input {
          width: 220px;
        }
      }
    }

    //列表内容
    .contain {
      width: calc(100% - 48px);
      padding: 24px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
      //新增
      .export {
        font-weight: 400;
        margin-bottom: 16px;
        display: flex;
        justify-content: end;
      }

      //表格
      .el-table {
        color: rgba(3, 10, 26, 0.85);

        .el-table__body-header, .el-table__header {
          th {
            background-color: rgb(250, 250, 250);
            font-weight: 500;
          }

          th:nth-child(2)::before, th:nth-child(3)::before, th:nth-child(4)::before, th:nth-child(5)::before, th:nth-child(6)::before, th:nth-child(7)::before {
            content: '';
            width: 1px;
            height: 1.6em;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(0, 0, 0, 0.06);
          }
        }

        th, td {
          padding: 0;
        }

        .cell {
          padding: 14px 16px;

          .btn {
            display: inline-block;
            color: #165DFF;
            margin-right: 14px;
            cursor: pointer;
          }


          .cont {
            display: -webkit-box; //对象作为弹性伸缩盒子模型显示
            overflow: hidden; //溢出隐藏
            -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
            -webkit-line-clamp: 1; //设置 块元素包含的文本行数
            cursor: pointer;
          }
        }
      }

      //分页
      .pag {
        width: 100%;
        height: 32px;
        padding: 16px 0 0 0;

        .el-pagination {
          float: right;
          margin-right: 12px;
        }
      }
    }
  }

  //弹窗
  .el-dialog {

    .logCont {
      .liBox {
        margin-bottom: 30px;

        p:first-child {
          //color: rgba(3, 10, 26, 0.5);
        }

        p:last-child {
          width: calc(100% - 15px);
          margin-left: 15px;
          margin-top: 5px;
          word-wrap: break-word;
        }
      }
    }
  }
}

//搜索
:deep(.searchBox) {
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
}

//主体
:deep(.contain) {
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
}

//对话弹窗
:deep(.el-dialog) {
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
}

/*:deep(.is-first) {
  margin-right: 19px !important;
}*/
</style>