<template>
  <div class="menuPage">
    <div class="title">
      <p>
        <span>菜单管理</span>
      </p>
    </div>
    <div class="content">
      <!--数据列表内容-->
      <div class="contain">
        <!--新增菜单-->
        <el-button class="addBtn" type="primary" @click="addClick()">
          <el-icon>
            <Plus/>
          </el-icon>
          新增菜单
        </el-button>
        <!--菜单列表-->
        <el-table v-loading="loading" :data="tableData" :tree-props="{children: 'subList', hasChildren: 'hasChildren'}"
                  row-key="id"
                  style="width: 100%" table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <!--              <p>您可以在此页面新建菜单</p>-->
            </el-empty>
          </template>
          <el-table-column label="菜单名称" min-width="160px" prop="name"/>
          <el-table-column label="菜单key" min-width="150px" prop="menuKey"/>
          <el-table-column label="排序" prop="sortNumber" sortable/>
          <el-table-column label="是否是菜单" prop="menuFlag">
            <template #default="scope">{{ scope.row.menuFlag ? '是' : '否' }}</template>
          </el-table-column>
          <el-table-column label="菜单logo" prop="logo">
            <template #default="scope">
              <img :src="scope.row.logo" alt="" width="30">
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="120px">
            <template #default="scope">
              <!--              <span v-if="scope.row.menuFlag" class="btn" @click="addClick(scope.row)">新增</span>-->
              <!--              <span v-if="!scope.row.menuFlag" class="btn" style="width: 28px;"></span>-->
              <!--              <span class="btn" @click="editClick(scope.row)">编辑</span>-->
              <!--              <span class="btn" @click="delClick(scope.row.id)">删除</span>-->
              <div class="opacity">
                <span v-if="scope.row.menuFlag" class="btn" @click="addClick(scope.row)">新增</span>
                <span v-if="!scope.row.menuFlag" class="btn" style="width: 28px;"></span>
                <span class="btn" @click="editClick(scope.row)">修改</span>
                <span class="delBtn" @click="delClick(scope.row.id)">删除</span>

                <!--                <img alt="" src="../../assets/img/161@2x.png" @click.prevent="editClick(scope.row)">
                                <img alt="" src="../../assets/img/163@2x.png" @click.prevent="delClick(scope.row.id)">-->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--新建编辑菜单弹窗-->
    <el-dialog
        v-model="menuDialog"
        :title="menuForm.id==0?'新增菜单':'编辑菜单'"
        width="522"
    >
      <el-form
          ref="menuFormRef"
          :model="menuForm"
          :rules="menuRules"
          class="menuFormRef"
          status-icon
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="menuForm.name" placeholder="请输入名称"/>
        </el-form-item>
        <el-form-item label="排序" prop="sortNumber">
          <el-input v-model="menuForm.sortNumber" placeholder="请输入排序" type="number"/>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="menuForm.type" disabled placeholder="请选择类型">
            <el-option :value="1" label="桌面端"/>
            <el-option :value="2" label="移动端"/>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单key" prop="menuKey">
          <el-input v-model="menuForm.menuKey" placeholder="请输入菜单key"/>
        </el-form-item>
        <el-form-item label="是否为菜单" prop="menuFlag">
          <el-radio-group v-model="menuForm.menuFlag">
            <el-radio :label="1" size="large">是</el-radio>
            <el-radio :label="0" size="large">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单未选中logo" prop="logo2">
          <el-upload :auto-upload="false" :file-list="menuForm.logo2" :on-preview="handlePictureCardPreview"
                     :on-remove="onRemove" accept=".png,.jpg,.jpeg,.svg"
                     action="#" class="logo1" limit="1" list-type="picture-card"
                     @change="uploadFile">
            <el-icon>
              <Plus/>
            </el-icon>
            <p>上传图片</p>
          </el-upload>
        </el-form-item>
        <el-form-item label="菜单已选中logo" prop="logo4">
          <el-upload :auto-upload="false" :file-list="menuForm.logo4" :on-preview="handlePictureCardPreview"
                     :on-remove="onRemove2" accept=".png,.jpg,.jpeg"
                     action="#" class="logo2" limit="1" list-type="picture-card"
                     @change="uploadFile2">
            <el-icon>
              <Plus/>
            </el-icon>
            <p>上传图片</p>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="menuDialog = false">取消</el-button>
        <el-button type="primary" @click="menuSubmit">确定</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible" title="查看大图">
      <img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref} from "vue"
import './menu.less'
import {Delete, Plus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {getMenuListApi, saveMenuListApi, delMenuListApi, getMenuMaxSortListApi, uploadImg} from '@/api/modules/menu'
import qs from "qs";

let router = useRouter()


// 菜单表格数据
let tableData = ref([])
// 获取菜单列表
let loading = ref(false)
const getMenuListFun = function () {
  loading.value = true
  getMenuListApi(qs.stringify({type: 1})).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 菜单表单数据
let menuForm = ref({
  id: 0,
  parentId: 0,
  sortNumber: '',
  type: 1,
  name: '',
  menuKey: '',
  menuFlag: '',
  logo: '',
  logo2: [],
})
//菜单表单规则
let menuRules = ref({
  name: [
    {required: true, message: '请输入名称', trigger: 'blur'},
  ],
  sortNumber: [
    {required: true, message: '请输入排序', trigger: 'blur'},
  ],
  type: [
    {required: true, message: '请选择类型', trigger: 'blur'},
  ],
  menuKey: [
    {required: true, message: '请输入菜单key', trigger: 'blur'},
  ],
  menuFlag: [
    {required: true, message: '请选择是否为菜单', trigger: 'blur'},
  ]
})
// 新建菜单弹窗
let menuDialog = ref(false)
// 编辑的id
let editId = ref('0')

// 新增菜单
const addClick = function (row) {
  menuDialog.value = true
  menuForm.value = {
    id: 0,
    parentId: 0,
    sortNumber: '',
    type: 1,
    name: '',
    menuKey: '',
    menuFlag: '',
    logo: '',
    logo2: [],
  }
  nextTick(() => {
    document.querySelector('.logo1 .el-upload--picture-card').style.display = 'flex'
    document.querySelector('.logo2 .el-upload--picture-card').style.display = 'flex'
  })

  if (row) {
    menuForm.value.parentId = row.id
  } else {
    menuForm.value.parentId = 0
  }
  getMenuMaxSortListApi(qs.stringify({
    parentId: row ? row.id : 0
  })).then(res => {
    if (res.data.code === 0) {
      menuForm.value.sortNumber = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 编辑菜单
const editClick = function (row) {
  menuDialog.value = true
  menuForm.value = JSON.parse(JSON.stringify(row))

  if (menuForm.value.logo) {
    menuForm.value.logo2 = [{
      url: menuForm.value.logo,
      name: 'aaa'
    }]
    nextTick(() => {
      if (menuForm.value.logo2.length > 0) {
        document.querySelector('.logo1 .el-upload--picture-card').style.display = 'none'
      } else {
        document.querySelector('.logo1 .el-upload--picture-card').style.display = 'flex'
      }
    })
  }
  if (menuForm.value.logo3) {
    menuForm.value.logo4 = [{
      url: menuForm.value.logo,
      name: 'aaa'
    }]
    nextTick(() => {
      if (menuForm.value.logo4.length > 0) {
        document.querySelector('.logo2 .el-upload--picture-card').style.display = 'none'
      } else {
        document.querySelector('.logo2 .el-upload--picture-card').style.display = 'flex'
      }
    })
  }
}


let dialogVisible = ref(false) //大图弹窗显示开关
let dialogImageUrl = ref() //大图列表
//上传文件
const uploadFile = function (file) {
  let fd = new FormData();
  //注意是文件流file.raw
  fd.append('file', file.raw); //传文件
  uploadImg(fd).then(res => {
    //console.log(res);
    if (res.data.ok) {
      menuForm.value.logo2 = [{
        url: res.data.result.fullPath,
        name: 'aaa'
      }]
      ElMessage({
        message: '上传成功',
        type: 'success'
      })
      if (menuForm.value.logo2.length > 0) {
        document.querySelector('.logo1 .el-upload--picture-card').style.display = 'none'
      } else {
        document.querySelector('.logo1 .el-upload--picture-card').style.display = 'flex'
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 图片移除操作
const onRemove = function () {
  if (menuForm.value.logo2.length > 0) {
    document.querySelector('.logo1 .el-upload--picture-card').style.display = 'none'
  } else {
    document.querySelector('.logo1 .el-upload--picture-card').style.display = 'flex'
  }
}
//上传文件2
const uploadFile2 = function (file) {
  let fd = new FormData();
  //注意是文件流file.raw
  fd.append('file', file.raw); //传文件
  uploadImg(fd).then(res => {
    //console.log(res);
    if (res.data.ok) {
      menuForm.value.logo4 = [{
        url: res.data.result.fullPath,
        name: 'aaa'
      }]
      ElMessage({
        message: '上传成功',
        type: 'success'
      })
      if (menuForm.value.logo4.length > 0) {
        document.querySelector('.logo2 .el-upload--picture-card').style.display = 'none'
      } else {
        document.querySelector('.logo2 .el-upload--picture-card').style.display = 'flex'
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 图片移除操作2
const onRemove2 = function () {
  if (menuForm.value.logo4.length > 0) {
    document.querySelector('.logo2 .el-upload--picture-card').style.display = 'none'
  } else {
    document.querySelector('.logo2 .el-upload--picture-card').style.display = 'flex'
  }
}

//查看大图
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}


let menuFormRef = ref()
// 提交菜单
const menuSubmit = function () {
  menuFormRef.value.validate((valid) => {
    //提交成功
    if (valid) {
      if (menuForm.value.menuFlag) {
        if (menuForm.value.logo2 && menuForm.value.logo2.length > 0) {
          menuForm.value.logo = menuForm.value.logo2[0].url
        } else {
          menuForm.value.logo = ''
        }
      } else {
        menuForm.value.logo = ''
      }
      delete menuForm.value.logo2
      saveMenuListApi(qs.stringify(menuForm.value)).then(res => {
        if (res.data.code === 0) {
          menuDialog.value = false
          getMenuListFun()
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
      })
    }
  })
}

// 删除菜单
const delClick = function (id) {
  ElMessageBox.confirm('请确认是否要删除此菜单，删除后数据不可恢复', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除菜单',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此菜单，删除后数据不可恢复'),
    ])
  }).then(() => {
    delMenuListApi(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getMenuListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}

onMounted(() => {
  getMenuListFun()
})


</script>

<style lang="less" scoped>
:deep(.el-dialog) {
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
}
</style>
