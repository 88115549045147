<template>
  <div class="saveStrategy">
    <div class="title">
      <p>
        <span>{{ router.currentRoute.value.query.controlStrategyId ? '编辑策略' : '新增策略' }}</span>
      </p>
    </div>
    <div class="content">
      <!--上部分信息-->
      <div class="topBox2">
        <div class="leftTopBox2">
          <div class="leftTopContent">
            <div class="modelClass">
              设备型号：{{ infoData.productModel }}
            </div>
            <!--            <div class="namelClass2">-->
            <!--              <span class="codeClass">设备编号：{{ infoData.deviceCode }}</span>-->
            <!--            </div>-->
            <!--            <div class="namelClass2">-->
            <!--              {{ String(infoData.deviceName).replace(/\s/g, "&ensp;") }}-->
            <!--            </div>-->
            <div class="namelClass2">
              <span class="codeClass">{{ infoData.projectName }}</span>
            </div>
          </div>
          <!--          <div class="rightTopContent">-->
          <!--          					<textarea class="urlClass" disabled>-->
          <!--          						{{infoData.projectName}}-->
          <!--          					</textarea>-->
          <!--          </div>-->
        </div>
        <div class="leftTopBox2">
          <div class="leftTopContent2">
            <div class="modelClass">
              设备状态
            </div>
            <div class="namelClass">
              <span>{{ infoData.statusStr }}</span>
            </div>
          </div>
        </div>
        <div class="relatedItemsBox">
          <div class="leftTopContent2">
            <div class="modelClass">
              控制
            </div>
            <!--          <button v-for="item in infoData.controlItemList" class="conorlButton" @click="contrlClick(item.controlId)">-->
            <!--            {{ item.controlName }}-->
            <!--          </button>-->
            <el-button class="conorlButton" size="small" type="primary"
                       @click="contrlClick(router.currentRoute.value.query.controlId)">
              {{ router.currentRoute.value.query.cname }}
            </el-button>
            <!--<button class="conorlButton">校时</button>-->
          </div>
        </div>
      </div>
      <!--执行条件-->
      <div class="filter">
        <div class="filterDate">
          <p>执行日期</p>
          <div class="pickerBox">
            <el-date-picker
                v-model="tableData.executeDate"
                end-placeholder="结束日期"
                range-separator="→"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="YYYY-MM-DD HH:mm:ss"
                @input="forceUpdate">
            </el-date-picker>
          </div>
        </div>
        <div class="filterFrequency">
          <p>执行频率</p>
          <el-input v-model="tableData.frequency" placeholder="请填写执行频率" type="number"></el-input>
          <p>秒</p>
        </div>
        <div class="filterTime">
          <p>执行时间</p>
          <div>
            <div v-for="(item,index) in tableData.executeTime" :key="index" class="pickerBox">
              <el-time-picker
                  v-model="item.time"
                  end-placeholder="结束时间"
                  format="HH:mm"
                  is-range
                  placeholder="选择时间范围"
                  range-separator="→"
                  start-placeholder="开始时间"
                  value-format="HH:mm"
                  @input="forceUpdate"
              >
              </el-time-picker>
              <el-icon v-if="tableData.executeTime.length!==1" class="delIcon"
                       @click="delTime(index)">
                <CircleClose/>
              </el-icon>
              <el-icon v-if="index===tableData.executeTime.length-1" class="addIcon"
                       @click="addTime">
                <CirclePlus/>
              </el-icon>
            </div>
          </div>
        </div>
        <div class="filterFrequency">
          <p>执行备注</p>
          <el-input v-model="tableData.memo" type="text"/>
        </div>
      </div>
      <!--控制参数-->
      <div class="content1 content">
        <div class="head">控制参数</div>
        <div v-for="(item,index) in paramsData" :key="index" class="contentItem">
          <!--        <i v-if="paramsData.length!==1" class="el-icon-delete delFilter"-->
          <!--           style="color: #FE4761;display: flex;align-items: center;margin-right: 10px;font-size: 16px;cursor: pointer"-->
          <!--           @click="delParams(index)"></i>-->
          <div class="rang">
            <el-input v-model="item.minValue" placeholder="请输入最小值"></el-input>
            <el-input v-model="item.maxValue" placeholder="请输入最大值"></el-input>
          </div>
          <p>{{ item.paramName }}</p>
          <p>=</p>
          <div v-for="(item_child,index_child) in item.data" :key="index_child"
               :style="{alignItems:item_child.type===''?'center':'start'}"
               class="contItemBox">
            <div v-if="index_child!==0" class="symbol">
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='+'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'+',1)">+</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='-'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'-',1)">-</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='×'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'×',1)">×</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='÷'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'÷',1)">÷</p>
            </div>
            <div class="contItem">
              <el-select v-model="item_child.type" clearable placeholder="请选择参数类型">
                <el-option :value="1" label="参数"/>
                <el-option :value="2" label="指标"/>
                <el-option :value="3" label="数值"/>
              </el-select>
              <div v-if="item_child.type!==''" class="contBox">
                <el-icon v-if="item_child.length!==1" class="delIcon"
                         @click="delControl(index,index_child)">
                  <CircleClose/>
                </el-icon>
                <el-select v-if="item_child.type==1" v-model="item_child.typeId" filterable
                           @change="dataItemChange($event,index,index_child,1,1)">
                  <el-option v-for="(item_s,index_s) in paramList" :key="index_s"
                             :label="item_s.paramName+'('+item_s.deviceName+')'"
                             :value="item_s.paramId"/>
                </el-select>
                <el-select v-if="item_child.type==2" v-model="item_child.deviceControlRelatedId" filterable
                           @change="dataItemChange($event,index,index_child,1,2)">
                  <el-option v-for="(item_s,index_s) in dataItemList" :key="index_s"
                             :label="item_s.titleName+'('+item_s.deviceName+')'"
                             :value="item_s.deviceControlRelatedId"/>
                </el-select>
                <div class="center">
                  <el-input v-if="item_child.type==3" v-model="item_child.val" class="val" placeholder="请输入"
                            type="number"/>
                  <!--                <img v-if="$store.state.themeVal" alt="" src="../../../assets/image/lineChat2.png">-->
                  <!--                <img v-if="!$store.state.themeVal" alt="" src="../../../assets/image/lineChat.png">-->
                  <p v-if="item_child.type!=3">{{ item_child.deviceName }}</p>
                  <p v-if="item_child.type!=3">{{ item_child.typeName }}</p>
                </div>
                <p v-if="item_child.type!=3" class="unit">{{ item_child.unit }}</p>
              </div>
              <div v-if="item_child.type==2" class="contTime">
                <span>时效</span>
                <el-input v-model="item_child.expirationTime" placeholder="请输入" type="number"></el-input>
                <span>秒</span>
              </div>
            </div>
          </div>
          <div>
            <el-icon class="addIcon" @click="addControl(index)">
              <CirclePlus/>
            </el-icon>
          </div>
          <p>{{ item.paramUnit }}(单位)</p>
        </div>
        <!--      <div class="addFilter" @click="addParams"><span>+</span> 添加控制参数</div>-->
      </div>
      <!--执行条件-->
      <div class="content2 content">
        <div class="head">执行条件</div>
        <div v-for="(item,index) in filterData" :key="index" class="contentItem">
          <div v-if="filterData.length!==1" style="display: flex;align-items: center">
            <el-icon class="el-icon-delete delFilter"
                     style="color: #FE4761;font-size: 16px;cursor: pointer;"
                     @click="delFilter(index)">
              <Delete/>
            </el-icon>
          </div>
          <p>执行条件{{ index + 1 }}</p>
          <div v-for="(item_child,index_child) in item.data" :key="index_child"
               :style="{alignItems:item_child.type===''?'center':'start'}"
               class="contItemBox">
            <div
                v-if="item.formula.substring(2*index_child-1,2*index_child)==='+'||item.formula.substring(2*index_child-1,2*index_child)==='-'||item.formula.substring(2*index_child-1,2*index_child)==='×'||item.formula.substring(2*index_child-1,2*index_child)==='÷'"
                class="symbol">
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='+'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'+',2)">+</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='-'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'-',2)">-</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='×'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'×',2)">×</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='÷'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'÷',2)">÷</p>
            </div>
            <div
                v-if="item.formula.substring(2*index_child-1,2*index_child)==='>'||item.formula.substring(2*index_child-1,2*index_child)==='='||item.formula.substring(2*index_child-1,2*index_child)==='<'||item.formula.substring(2*index_child-1,2*index_child)==='≠'"
                class="symbol symbol2">
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='>'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'>',2)">></p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='='?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'=',2)">=</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='<'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'<',2)">&lt;</p>
              <p :class="item.formula.substring(2*index_child-1,2*index_child)==='≠'?'activeSymbol':''"
                 @click="symbolClick(index,index_child,'≠',2)">≠</p>
            </div>
            <div class="contItem">
              <el-select v-model="item_child.type" clearable placeholder="请选择参数类型">
                <el-option :value="1" label="参数"/>
                <el-option :value="2" label="指标"/>
                <el-option :value="3" label="数值"/>
              </el-select>
              <div v-if="item_child.type!==''" class="contBox">
                <el-icon
                    v-if="index_child!==0&&(item.formula.substring(2*index_child-1,2*index_child)!=='>'&&item.formula.substring(2*index_child-1,2*index_child)!=='='&&item.formula.substring(2*index_child-1,2*index_child)!=='<')&&item.formula.substring(2*index_child-1,2*index_child)!=='≠'"
                    class="el-icon-circle-close delIcon" @click="delFilterItem(index,index_child)">
                  <CircleClose/>
                </el-icon>
                <el-select v-if="item_child.type==1" v-model="item_child.typeId" filterable
                           @change="dataItemChange($event,index,index_child,2,1)">
                  <el-option v-for="(item_s,index_s) in paramList" :key="index_s"
                             :label="item_s.paramName+'('+item_s.deviceName+')'"
                             :value="item_s.paramId"/>
                </el-select>
                <el-select v-if="item_child.type==2" v-model="item_child.deviceControlRelatedId" filterable
                           @change="dataItemChange($event,index,index_child,2,2)">
                  <el-option v-for="(item_s,index_s) in dataItemList" :key="index_s"
                             :label="item_s.titleName+'('+item_s.deviceName+')'"
                             :value="item_s.deviceControlRelatedId"/>
                </el-select>
                <div class="center">
                  <el-input v-if="item_child.type==3" v-model="item_child.val" class="val" placeholder="请输入"
                            type="number"/>
                  <!--                <img v-if="$store.state.themeVal" alt="" src="../../../assets/image/lineChat2.png">-->
                  <!--                <img v-if="!$store.state.themeVal" alt="" src="../../../assets/image/lineChat.png">-->
                  <p v-if="item_child.type!=3">{{ item_child.deviceName }}</p>
                  <p v-if="item_child.type!=3">{{ item_child.typeName }}</p>
                </div>
                <p v-if="item_child.type!=3" class="unit">{{ item_child.unit }}</p>
              </div>
              <div v-if="item_child.type==2" class="contTime">
                <span>时效</span>
                <el-input v-model="item_child.expirationTime" placeholder="请输入" type="number"></el-input>
                <span>秒</span>
              </div>
            </div>
            <div
                v-if="item.formula.substring(2*index_child+1,2*index_child+2)==='>'||item.formula.substring(2*index_child+1,2*index_child+2)==='='||item.formula.substring(2*index_child+1,2*index_child+2)==='<'||item.formula.substring(2*index_child+1,2*index_child+2)==='≠'"
                class="addBox"
                @click="addFilterBefore(index,index_child)">+
            </div>
            <div v-if="item.formula.length===2*index_child+1" class="addBox" @click="addFilterAfter(index,index_child)">
              +
            </div>
          </div>
        </div>
        <div class="addFilter" @click="addFilter"><span>+</span> 添加执行条件</div>
      </div>
      <!--取消保存-->
      <div class="operate">
        <el-button class="cancel" @click="cancel">取消</el-button>
        <el-button class="save" type="primary" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './saveStrategy.less'
import {CirclePlus, CircleClose, Delete, Search} from '@element-plus/icons-vue'
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  getDataItemApi,
  getHeadInfoApi, getParamListApi,
  getStrategyConfigListApi,
  getStrategyDetailApi,
  operateDeviceApi, saveStrategyApi
} from "@/api/modules/strategy";
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import letterList from '@/assets/js/letterList.js'
import {controlClickApi} from "@/api/modules/control";

let router = useRouter()

// 头部信息
let infoData = ref({})

let dataItemList = ref([])  //数据项列表
let paramList = ref([]) //参数列表
// 整体数据
let tableData = ref({
  controlId: router.currentRoute.value.query.controlId,  //设备控制id
  deviceId: router.currentRoute.value.query.deviceId,  //设备id
  controlStrategyId: null,  //控制策略id

  controlParameters: [], //参数列表
  executionCondition: [],  //条件列表
  executeTime: [{
    time: null
  }],  //执行时间
  dayRunTime: [
    {
      durationBegin: "",
      durationEnd: ""
    }
  ],  //执行时间
  frequency: '',  //执行频率
  executeDate: [],  //执行日期
  runEndTime: "",  //执行结束日期
  runStartTime: "",    //执行开始日期
  memo: '',  //备注
})
// 控制参数数据
let paramsData = ref([])
// 执行条件数据
let filterData = ref([{
  formula: 'A=B',
  data: [{
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    expirationTime: '',
    deviceId: '',
    deviceName: '',
  }, {
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "B",
    expirationTime: '',
    deviceId: '',
    deviceName: '',
  }]
}])


// 获取头部信息
const getHeadInfoFun = function () {
  getHeadInfoApi(qs.stringify({
    equipmentId: router.currentRoute.value.query.deviceId,
  })).then(res => {
    if (res.data.code === 0) {
      infoData.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
//控制按钮的点击
const contrlClick = function (relatedItems_id) {
  // if (router.currentRoute.value.query.deviceId) {
  //   openFullScreen();
  // }
  // var params = {
  //   src: 2,
  //   id: router.currentRoute.value.query.deviceId,
  //   control_id: relatedItems_id
  // }
  // if (router.currentRoute.value.query.deviceId) {
  //   operateDeviceApi(qs.stringify(params)).then(res => {
  //     loadingAdd.value.close();
  //     if (res.data.res == 1) {
  //       ElMessage({
  //         type: 'success',
  //         message: res.data.msg + '!'
  //       });
  //     } else {
  //       ElMessage({
  //         type: 'error',
  //         message: res.data.msg + '!'
  //       });
  //     }
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // } else {
  //   ElMessage({
  //     type: 'info',
  //     message: '请先确定设备是否关联!'
  //   });
  // }

  var params = {
    equipmentId: router.currentRoute.value.query.deviceId,
    controlId: router.currentRoute.value.query.controlId
  }
  if (router.currentRoute.value.query.deviceId) {
    ElMessageBox.confirm("确认要执行该操作吗，请谨慎操作", '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      if (router.currentRoute.value.query.deviceId) {
        openFullScreen();
      }
      controlClickApi(qs.stringify(params)).then(res => {
        loadingAdd.value.close();
        if (res.data.code === 0) {
          ElMessage({
            type: 'success',
            message: '操作成功'
          })
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        loadingAdd.value.close();
        console.log(err)
      })
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消操作'
      })
    });
  } else {
    ElMessage({
      type: 'info',
      message: '请先确定设备是否关联!'
    })
  }

}
//加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 获取详情
const getDetail = function () {
  getStrategyDetailApi({
    controlStrategyId: router.currentRoute.value.query.controlStrategyId,
  }).then(res => {
    if (res.data.code == 0) {
      tableData.value = res.data.result
      // 参数列表
      paramsData.value = JSON.parse(tableData.value.controlParameters)
      for (let i = 0; i < paramsData.value.length; i++) {
        for (let j = 0; j < paramsData.value[i].data.length; j++) {
          for (let k = 0; k < dataItemList.value.length; k++) {
            if (paramsData.value[i].data[j].deviceControlRelatedId === dataItemList.value[k].deviceControlRelatedId) {
              paramsData.value[i].data[j].deviceName = dataItemList.value[k].deviceName
              paramsData.value[i].data[j].deviceId = dataItemList.value[k].deviceId
            }
          }
        }
      }
      // 条件列表
      filterData.value = JSON.parse(tableData.value.executionCondition)
      for (let i = 0; i < filterData.value.length; i++) {
        for (let j = 0; j < filterData.value[i].data.length; j++) {
          for (let k = 0; k < dataItemList.value.length; k++) {
            if (filterData.value[i].data[j].deviceControlRelatedId === dataItemList.value[k].deviceControlRelatedId) {
              filterData.value[i].data[j].deviceName = dataItemList.value[k].deviceName
              filterData.value[i].data[j].deviceId = dataItemList.value[k].deviceId
            }
          }
        }
      }
      // 执行日期处理
      tableData.value.executeDate = [tableData.value.runStartTime, tableData.value.runEndTime]
      // 执行时间处理
      tableData.value.executeTime = []
      for (let i = 0; i < tableData.value.dayRunTime.length; i++) {
        tableData.value.executeTime.push({
          time: [tableData.value.dayRunTime[i].durationBegin, tableData.value.dayRunTime[i].durationEnd]
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 获取参数列表
const getParamListFun = async function () {
  await getStrategyConfigListApi(qs.stringify({
    pageNum: 1,
    pageSize: 10000,
    deviceControlId: router.currentRoute.value.query.dControlId,
    controlId: router.currentRoute.value.query.controlId,
  })).then(res => {
    if (res.data.code == 0) {
      for (let i = 0; i < res.data.result.records.length; i++) {
        paramsData.value.push({
          formula: 'A',
          paramName: res.data.result.records[i].paramName,
          paramUnit: res.data.result.records[i].paramUnit,
          controlParameterKey: res.data.result.records[i].controlParamKey,
          maxValue: '',
          minValue: '',
          data: [{
            val: "",
            type: '',
            unit: "",
            typeId: '',
            typeName: "",
            replaceStr: "A",
            expirationTime: '',
          }]
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 获取数据项列表
const getDataItem = async function () {
  await getDataItemApi(qs.stringify({
    controlId: router.currentRoute.value.query.controlId,
    deviceControlId: router.currentRoute.value.query.dControlId
  })).then(res => {
    if (res.data.code === 0) {
      dataItemList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 获取参数列表
const getParamList = async function () {
  await getParamListApi(qs.stringify({
    pageNum: 1,
    pageSize: 10000,
  })).then(res => {
    if (res.data.code == 0) {
      paramList.value = res.data.result.list
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      });
    }
  }).catch(err => {
    console.log(err)
  })
}
// 数据项选择change事件
const dataItemChange = function (e, index, index_child, num, num2) {
  if (num2 === 1) {  //参数
    for (let i = 0; i < paramList.value.length; i++) {
      if (paramList.value[i].paramId === e) {
        if (num === 1) {  //控制参数
          paramsData.value[index].data[index_child].unit = paramList.value[i].valueUnit
          paramsData.value[index].data[index_child].typeName = paramList.value[i].paramName
          paramsData.value[index].data[index_child].deviceName = paramList.value[i].deviceName
          paramsData.value[index].data[index_child].deviceId = paramList.value[i].deviceId
        } else {  //执行条件
          filterData.value[index].data[index_child].unit = paramList.value[i].valueUnit
          filterData.value[index].data[index_child].typeName = paramList.value[i].paramName
          filterData.value[index].data[index_child].deviceName = paramList.value[i].deviceName
          filterData.value[index].data[index_child].deviceId = paramList.value[i].deviceId
        }
      }
    }
  } else {  //指标
    for (let i = 0; i < dataItemList.value.length; i++) {
      if (dataItemList.value[i].deviceControlRelatedId === e) {
        if (num === 1) {  //控制参数
          paramsData.value[index].data[index_child].unit = dataItemList.value[i].dataUnit
          paramsData.value[index].data[index_child].typeName = dataItemList.value[i].titleName
          paramsData.value[index].data[index_child].deviceName = dataItemList.value[i].deviceName
          paramsData.value[index].data[index_child].deviceId = dataItemList.value[i].deviceId
          paramsData.value[index].data[index_child].typeId = dataItemList.value[i].titleCode
        } else {  //执行条件
          filterData.value[index].data[index_child].unit = dataItemList.value[i].dataUnit
          filterData.value[index].data[index_child].typeName = dataItemList.value[i].titleName
          filterData.value[index].data[index_child].deviceName = dataItemList.value[i].deviceName
          filterData.value[index].data[index_child].deviceId = dataItemList.value[i].deviceId
          filterData.value[index].data[index_child].typeId = dataItemList.value[i].titleCode
        }
      }
    }
  }
}

// 总体条件--------------------------------------------------------------
// 添加执行时间
const addTime = function () {
  tableData.value.executeTime.push({
    time: null
  })
  // this.$forceUpdate()
}
// 删除执行时间
const delTime = function (index) {
  tableData.value.executeTime.splice(index, 1)
  // this.forceUpdate()
}
const forceUpdate = function () {
  this.$forceUpdate()
}


// 控制参数--------------------------------------------------------------
// 添加参数
const addParams = function (index) {
  paramsData.value.push({
    formula: 'A',
    maxValue: '',
    minValue: '',
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      expirationTime: '',
    }]
  })
}
// 删除参数
const delParams = function (index) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    paramsData.value.splice(index, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 添加控制参数
const addControl = function (index) {
  paramsData.value[index].formula = paramsData.value[index].formula.concat('+D')
  paramsData.value[index].data.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    expirationTime: '',
  })
}
// 删除控制参数
const delControl = function (index, index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    let str = paramsData.value[index].formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    paramsData.value[index].formula = strA.concat(strB)
    paramsData.value[index].data.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 执行条件--------------------------------------------------------------
// 添加执行条件
const addFilter = function () {
  filterData.value.push({
    formula: "A=B",
    data: [
      {
        val: "",
        type: '',
        unit: "",
        typeId: '',
        typeName: "",
        replaceStr: "A",
        expirationTime: ''
      }, {
        val: "",
        type: '',
        unit: "",
        typeId: '',
        typeName: "",
        replaceStr: "B",
        expirationTime: ''
      }
    ],
    conditionStatus: 0
  })
}
// 删除执行条件
const delFilter = function (index) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除条件',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此条件，删除后数据不可恢复'),
    ])
  }).then(() => {
    filterData.value.splice(index, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 添加执行条件前
const addFilterBefore = function (index, index_child) {
  filterData.value[index].formula = filterData.value[index].formula.slice(0, 2 * index_child + 1) + '+D' + filterData.value[index].formula.slice(2 * index_child + 1)
  filterData.value[index].data.splice(index_child + 1, 0, {
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    expirationTime: ''
  })
}
// 添加执行条件后
const addFilterAfter = function (index, index_child) {
  filterData.value[index].formula = filterData.value[index].formula.concat('+D')
  filterData.value[index].data.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    expirationTime: ''
  })
}
// 删除执行条件单个框
const delFilterItem = function (index, index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除条件',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此条件，删除后数据不可恢复'),
    ])
  }).then(() => {
    let str = filterData.value[index].formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    filterData.value[index].formula = strA.concat(strB)
    filterData.value[index].data.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}

// 符号点击
const symbolClick = function (index, index_child, e, num) {
  if (num === 1) {
    let str = paramsData.value[index].formula
    paramsData.value[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
  } else {
    let str = filterData.value[index].formula
    filterData.value[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
  }
}


// 取消
const cancel = function () {
  router.push({
    path: '/admin/strategy',
    query: {csId: router.currentRoute.value.query.csId}
  })
}
// 保存
const save = function () {
  let data = JSON.parse(JSON.stringify(tableData.value))
  let flag = true
  // 判断时间段是否完善
  for (let i = 0; i < data.executeTime.length; i++) {
    if (data.executeTime[i].time == null) {
      flag = false
    }
  }
  // 判断参数是否填写完整
  for (let i = 0; i < paramsData.value.length; i++) {
    for (let j = 0; j < paramsData.value[i].data.length; j++) {
      if (paramsData.value[i].data[j].type) {
        if (paramsData.value[i].data[j].type == 1) {
          if (paramsData.value[i].data[j].typeId === '' || paramsData.value[i].data[j].typeName === '') {
            flag = false
          }
        } else if (paramsData.value[i].data[j].type == 2) {
          if (paramsData.value[i].data[j].typeId === '' || paramsData.value[i].data[j].typeName === '' || paramsData.value[i].data[j].expirationTime === '') {
            flag = false
          }
        } else if (paramsData.value[i].data[j].type == 3) {
          if (paramsData.value[i].data[j].val === '') {
            flag = false
          }
        }
      } else {
        flag = false
      }
    }
  }
  // 判断条件是否填写完整
  for (let i = 0; i < filterData.value.length; i++) {
    for (let j = 0; j < filterData.value[i].data.length; j++) {
      if (filterData.value[i].data[j].type) {
        if (filterData.value[i].data[j].type == 1) {
          if (filterData.value[i].data[j].typeId === '' || filterData.value[i].data[j].typeName === '') {
            flag = false
          }
        } else if (filterData.value[i].data[j].type == 2) {
          if (filterData.value[i].data[j].typeId === '' || filterData.value[i].data[j].typeName === '' || filterData.value[i].data[j].expirationTime === '') {
            flag = false
          }
        } else if (filterData.value[i].data[j].type == 3) {
          if (filterData.value[i].data[j].val === '') {
            flag = false
          }
        }
      } else {
        flag = false
      }
    }
  }

  if (flag && data.executeDate !== null && data.executeDate.length !== 0 && data.frequency !== '') {
    // 处理参数列表字母
    for (let i = 0; i < paramsData.value.length; i++) {
      for (let j = 0; j < paramsData.value[i].data.length; j++) {
        paramsData.value[i].data[j].replaceStr = letterList()[j].name
      }
      paramsData.value[i].formula = letterList()[0].name + paramsData.value[i].formula.substr(1);
      for (let j = 0; j < paramsData.value[i].formula.length; j++) {
        if (j % 2 == 0 && j !== 0) {
          paramsData.value[i].formula = paramsData.value[i].formula.substr(0, j) + letterList()[j / 2].name + paramsData.value[i].formula.substr(j + 1);
        }
      }
    }
    // 处理条件列表字母
    for (let i = 0; i < filterData.value.length; i++) {
      for (let j = 0; j < filterData.value[i].data.length; j++) {
        filterData.value[i].data[j].replaceStr = letterList()[j].name
      }
      filterData.value[i].formula = letterList()[0].name + filterData.value[i].formula.substr(1);
      for (let j = 0; j < filterData.value[i].formula.length; j++) {
        if (j % 2 == 0 && j !== 0) {
          filterData.value[i].formula = filterData.value[i].formula.substr(0, j) + letterList()[j / 2].name + filterData.value[i].formula.substr(j + 1);
        }
      }
    }
    // 频率
    data.frequency = Number(data.frequency)
    // 参数列表
    data.controlParameters = JSON.stringify(paramsData.value)
    // 条件列表
    data.executionCondition = JSON.stringify(filterData.value)
    // 执行日期处理
    data.runStartTime = data.executeDate[0]
    data.runEndTime = data.executeDate[1]
    delete data.executeDate
    // 执行时间处理
    data.dayRunTime = []
    for (let i = 0; i < data.executeTime.length; i++) {
      data.dayRunTime.push({
        durationBegin: data.executeTime[i].time[0],
        durationEnd: data.executeTime[i].time[1]
      })
    }
    delete data.executeTime

    saveStrategyApi(data).then(res => {
      if (res.data.code == 0) {
        ElMessage({
          type: 'success',
          message: '保存成功'
        });
        router.push({
          path: '/admin/strategy',
          query: {csId: router.currentRoute.value.query.csId}
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  } else {
    ElMessage({
      type: 'info',
      message: '缺少必填项'
    });
  }
}


onMounted(async () => {
  await getHeadInfoFun()
  await getDataItem()
  await getParamList()

  if (router.currentRoute.value.query.controlStrategyId) {
    await getDetail()
  } else {
    await getParamListFun()
  }
})

</script>

<style lang="less" scoped>
</style>
