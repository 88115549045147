<template>
  <div id="divId"
       :style="{ '--bg-color': props.basicData.bgColor,'--after-color': props.basicData.after.color,'--after-width':props.basicData.after.width ,'--after-height':props.basicData.after.height}">
  </div>
</template>
<!--光伏发电流程图-->
<script setup>
import {onMounted, defineProps} from 'vue';
import {Stage, Layer, Node, Link, TextNode} from '@jtopo/core';
//导入父组件传递的数据
const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  basicData: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  arrow: {
    type: Object,
    required: true,
    default: () => ({}),
  }
});
onMounted(() => {
  const stage = new Stage('divId');
  stage.hideToolbar();//隐藏工具栏
  stage.mode = 'view' //设置只读模式
  const layer = new Layer('default');
  const nodes = [];
  stage.addChild(layer);
  //画布样式设置
  layer.css({
    background: props.basicData.bgColor,
    width: '100%',
    height: '100%',
  })
  //初始化起始点位置
  const [sideX, sideY] = [props.basicData.sideX, props.basicData.sideY]
  const rgb = props.basicData.rgb ? props.basicData.bgColor : 'rgba(255, 255, 255, 0)';

  //创建节点方法，可设置默认样式
  function addNode(text, x, y, w, h, bgc) {
    const node = new Node(text, x, y, w, h);
    const css = {
      background: bgc || props.basicData.bgColor,
      textPosition: 'cb',
      textAlign: 'center',
      textBaseline: 'top',
      color: props.basicData.fontColor,
      font: props.basicData.font,
      textOffsetY: props.basicData.textOffsetY,
      backgroundSize: '100% 100%'
    }
    node.css(css);
    nodes.push(node);
    return node;
  }

  let previousNode;
  const nodesArray = [];
  //拼接url地址：
  const https = props.basicData.url
  const noRepeat = 'no-repeat'
  //创建图片节点实例，
  for (let i = 0; i < props.data.length; i++) {
    const [offsetX, offsetY, width, height] = props.basicData.IMAGES_OFFSETS[i];
    const y = offsetY === null ? (previousNode ? previousNode.y : sideY) : offsetY;
    const imageUrl = `${rgb} ${https}${props.data[i].img.toLowerCase()}) ${noRepeat}`;
    const currentNode = addNode(props.data[i].name, sideX + offsetX, y, width, height, imageUrl);
    if (i > 0) {
      previousNode = currentNode;
    } else {
      previousNode = {y: sideY}; // 对于第一个节点，设置初始y值
    }
    nodesArray.push(currentNode); // 将每个节点添加到数组中
    // 判断是否存在value属性，添加箭头 和功率
    if (props.data[i].value !== void 0) {
      //添加功率
      /*const textNode = new TextNode(`${Math.abs(props.data[i].value)}${props.arrow.unit}`)
      textNode.css({
        textPosition: 'rm',
        textAlign: 'left',
        textBaseline: "middle",
        font: props.arrow.font,
        color: props.arrow.color,
      })
      textNode.x = props.arrow.textOffset[0];
      textNode.y = props.arrow.textOffset[1];*/
      //添加箭头
      const arrowNode = new Node('', 0, 0, props.arrow.size[0], props.arrow.size[1])
      arrowNode.css({
        background: `${rgb} ${https}${props.arrow.img.toLowerCase()}) no-repeat`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
      })
      //判断光伏流动方向
      if (props.data[i].value < 0) {
        arrowNode.rotate(Math.PI / 180)
      } else {
        arrowNode.rotate(Math.PI)
      }
      arrowNode.y = -props.arrow.arrowOffset[1];
      //调整箭头与文字的位置
      if (nodesArray[i].y < 0) {
        // textNode.y = -props.arrow.textOffset[1] - 10;
        arrowNode.rotate(Math.PI / 180)
        arrowNode.y = props.arrow.arrowOffset[1] - 10;
      }
      //判断箭头反向
      if (nodesArray[i].text === '储能' || nodesArray[i].text === '丰轩乐储能') {
        if (props.data[i].value < 0) {
          arrowNode.rotate(Math.PI / 180)
          // textNode.text = `${-props.data[i].value}${props.arrow.unit}`
        } else {
          arrowNode.rotate(Math.PI)
        }
      }
      if (nodesArray[i].text === '负载') {
        arrowNode.rotate(Math.PI)
        // textNode.x = props.arrow.textOffset[0] - 20;
        if (props.data[i].value < 0) {
          // textNode.text = `${-props.data[i].value}${props.arrow.unit}`
        }
      }
      if (nodesArray[i].text === '电网') {
        arrowNode.y = 0;
        arrowNode.x = -110;
        // textNode.x = -185;
        // textNode.y = 20;
        if (props.data[i].value < 0) {
          arrowNode.rotate(-Math.PI / 2)
          // textNode.text = `${-props.data[i].value}${props.arrow.unit}`
        } else {
          arrowNode.rotate(Math.PI / 2)
        }
      }
      nodesArray[i].addChild(arrowNode);
      // nodesArray[i].addChild(textNode);
    }
  }
  // 如果 props.basicData.upDown 为真，则根据节点的 y 坐标调整文本位置
  if (props.basicData.upDown) {
    nodesArray.forEach(node => {
      // 根据节点的 y 坐标决定文本是显示在节点的上方还是下方
      const cssConfig = node.y < -20 ? {
        textPosition: 'ct', // 文本位置居中顶部
        textOffsetY: props.basicData.imageTextUp,   // 文本偏移量
      } : {
        textPosition: 'cb', // 文本位置居中底部
        textOffsetY: props.basicData.imageTextDown,    // 文本偏移量
      };
      // 应用 CSS 配置到节点
      node.css(cssConfig);
    });
  }
  //实线定位点
  const slidY = sideY + 80;
  const solidRows = [];
  // 假设 nodesArray 的长度至少为 7，确保下面的循环有意义
  // 如果 nodesArray 的长度是动态的，你可能需要调整这段代码
  for (let i = 0; i < 7; i++) {
    // 使用 i % nodesArray.length 来确保索引不会超出 nodesArray 的范围
    // 这对于动态长度的 nodesArray 尤其有用
    const nodeIndex = i % nodesArray.length;
    const solidRow = addNode(null, nodesArray[nodeIndex].x, slidY, 3, 3, props.basicData.mainLineColor);
    solidRows.push(solidRow);
  }
  //实线连接
  const dashedLinks3 = [
    new Link('', solidRows[0], nodesArray[0], "center", "cb"),
    new Link('', solidRows[2], nodesArray[2], "center", "cb"),
  ]
  //添加水平部分实线的样式，颜色可由父组件传递
  dashedLinks3.forEach(link => {
    link.css({
      strokeStyle: props.basicData.mainLineColor,
      lineWidth: props.basicData.lineWidth,
    })
  })

  //需要添加动画箭头的
  // const linkIndices = [1, 3, 4, 5, 6];
  const linkIndices = [
    {id: 1, start: 'ct', end: 'center'},
    {id: 3, start: 'ct', end: 'center'},
    {id: 4, start: 'cb', end: 'center'},
    {id: 5, start: 'cb', end: 'center'},
    {id: 6, start: 'ct', end: 'center'},
  ];
  const dashedLinks = linkIndices.map(index => new Link('', nodesArray[index.id], solidRows[index.id], index.start, index.end));
  //电网区域箭头绑定的线条
  let returnArrow = [new Link('', solidRows[0], nodesArray[7], "center", "lm")];
  returnArrow[0].css({
    strokeStyle: props.basicData.mainLineColor,
    lineWidth: props.basicData.lineWidth,
  })
  //设置光伏区域箭头反向位置大小等等
  dashedLinks.forEach((link, index) => {
    link.css({
      strokeStyle: props.arrow.flowColor,
      strokeWidth: 3,
      lineWidth: props.basicData.lineWidth,
    })
    if (props.data[linkIndices[index].id].value < 0) {
      swapLinkBeginAndEnd(link)
    }
  })

  function swapLinkBeginAndEnd(link) {
    // 保存原始的起始位置
    const temp = link.begin;
    // 交换起始和结束位置
    link.begin = link.end;
    link.end = temp;
    // 更新图层以反映更改
    layer.update();
  }

  props.data.forEach(item => {
    // 检查 text 属性是否为 "电网" 或 "储能"
    if (item.name === '电网') {
      if (item.value > 0) {
        swapLinkBeginAndEnd(returnArrow[0])
      }
    }
    if (item.name === '储能' || item.name === '丰轩乐储能') {
      if (item.value < 0) {
        swapLinkBeginAndEnd(dashedLinks3[1])
      }
    }
  })

  //线路流动动画
  function flow(objects, n) {
    if (n === objects.length) {
      return;
    }
    const obj = objects[n];
    obj.css({
      lineDash: props.arrow.lineDash,
    });
    let offset = 0;
    setInterval(function () {
      if (++offset > 16) offset = 0;
      obj.css({
        lineDashOffset: -offset
      });
      layer.update();
    }, props.arrow.time);

    setTimeout(function () {
      flow(objects, n + 1);
    }, 0);
  }

  //添加流动动画
  if (props.arrow.flow) {
    flow(dashedLinks, 0)
    flow(dashedLinks3, 0)
    flow(returnArrow, 0)
  }
  //注册节点
  layer.addChilds([...dashedLinks3, ...dashedLinks, ...returnArrow, ...nodes])
  stage.show();
})
</script>

<style scoped lang="less">
#divId {
  width: 100%;
  height: 100%;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: calc(var(--after-width) * 1px);
    height: calc(var(--after-height) * 1px);
    background: var(--after-color);
  }
}
</style>
