<template>

  <div class="hecnScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="head">
          <img alt="" src="../../../assets/img/head2.png">
        </div>
        <div class="timeBox">
          <p class="time">{{ timeVal }}</p>
          <p class="date">{{ dateVal }}</p>
          <p class="week">{{ weekVal }}</p>
        </div>
        <div class="weatherBox">
          <span>{{ currentWeatherObj.city }}</span>
          <span><span>{{
              allWeatherData[0] ? allWeatherData[0].nighttemp : ''
            }}~{{ allWeatherData[0] ? allWeatherData[0].daytemp : '' }}C°</span></span>
          <span>{{ currentWeatherObj.weather }}</span>
        </div>
        <div class="leftBox">
          <div class="leftBoxItem">
            <div class="titleBox">
              <p class="name">储能放电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">今日</p>
                <count-up ref="countUp1" :end-val="data.generateElectricityToday" :start-val="countStart1"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">昨日</p>
                <count-up ref="countUp2" :end-val="data.generateElectricityYesterday" :start-val="countStart2"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当月累计</p>
                <count-up ref="countUp3" :end-val="data.generateElectricityMonth" :start-val="countStart3"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">历史累计</p>
                <count-up ref="countUp4" :end-val="data.generateElectricityTotal" :start-val="countStart4"
                          class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">近七日发电趋势</p>
              <div class="chart1_2 chart"></div>
            </div>
          </div>
          <div class="leftBoxItem">
            <div class="titleBox">
              <p class="name">储能充电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">今日</p>
                <count-up ref="countUp5" :end-val="data.rechargeToday" :start-val="countStart5" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">昨日</p>
                <count-up ref="countUp6" :end-val="data.rechargeYesterday" :start-val="countStart6"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当月累计</p>
                <count-up ref="countUp7" :end-val="data.rechargeMonth" :start-val="countStart7" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">历史累计</p>
                <count-up ref="countUp8" :end-val="data.rechargeTotal" :start-val="countStart8" class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">近七日充电量趋势</p>
              <div class="chart2_2 chart"></div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightBoxItem circleBoxItem">
            <div class="titleBox">
              <p class="name">储能状态</p>
            </div>
            <div class="circleBox">
              <div class="chart circleChart"></div>
            </div>
          </div>
          <div class="rightBoxItem">
            <div class="titleBox">
              <p class="name">储能功率</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">最高 <span>{{ data.generateRateMaxTime }}</span></p>
                <count-up ref="countUp9" :end-val="data.generateRateMaxValue" :start-val="countStart9"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">最低 <span>{{ data.generateRateMinTime }}</span></p>
                <count-up ref="countUp10" :end-val="data.generateRateMinValue" :start-val="countStart10"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当前 <span>{{ data.generateRateNowTime }}</span></p>
                <count-up ref="countUp11" :end-val="data.generateRateNowValue" :start-val="countStart11"
                          class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">当日储能发电功率</p>
              <div class="chart3_2 chart"></div>
            </div>
          </div>
        </div>
        <div class="topBox">
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp12" :end-val="data.monthMaxRate" :start-val="countStart12" class="val"
                          style="display: inline-block"></count-up>
                <span> kw</span>
              </p>
              <p class="name">本月最高功率</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon2.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp13" :end-val="data.thatDayIncome" :start-val="countStart13" class="val"
                          style="display: inline-block"></count-up>
                <span> 元</span>
              </p>
              <p class="name">当天收益</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon3.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp14" :end-val="data.monthAddNumber" :start-val="countStart14" class="val"
                          style="display: inline-block"></count-up>
                <span></span>
              </p>
              <p class="name">本月增容次数</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon4.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp15" :end-val="data.totalIncome" :start-val="countStart15" class="val"
                          style="display: inline-block"></count-up>
                <span> 元</span>
              </p>
              <p class="name">总收益</p>
            </div>
          </div>
        </div>
        <div class="bottomBox">
          <div class="bottomBoxItem">
            <div class="titleBox">
              <p class="name">当天放电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="chart chart4_2"></div>
          </div>
          <div class="bottomBoxItem">
            <div class="titleBox">
              <p class="name">当月放电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="chart chart5_2"></div>
          </div>
        </div>
        <div class="mainCircuit">
          <change-circuit :type="transitionName" :data="circuitValue"/>
          <transition mode="out-in" appear name="el-fade-in-linear">
            <circuit :key="arrow.key" :arrow='arrow' :basicData='basicData' :data="asyncData"/>
          </transition>
          <!--          <transition appear mode="out-in" name="el-fade-in-linear">
                      <circuit :key="arrow.key" :arrow='arrow' :asyncData="circuitValue.list" :basicData='basicData'
                               :data="asyncData"/>
                    </transition>-->
        </div>
      </div>
      <!--      <img alt="" src="http://zkep2.1942it.com/storage/upload/202309/a8f1ad30-4d4f-11ee-93be-a50bae242d53.png"-->
      <!--           style="width: 100%">-->
    </v-scale-screen>
  </div>

</template>

<script setup>
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import VScaleScreen from 'v-scale-screen'
import './hecnScreen.less'
import * as echarts from "echarts";
import {hncnScreenApi, getWeatherApi, getDeviceListApi} from '@/api/modules/bigScreen'
import {ElMessage} from "element-plus";
import Circuit from "@/components/circuit.vue";
import CountUp from 'vue-countup-v3'
import ChangeCircuit from "@/components/changeCircuit.vue";
import useCircuit from "@/hooks/useOldCircuit";
/*天气************************************************/
let currentWeatherObj = ref({}) //实时天气
//实时天气
const currentWeatherFun = function () {
  getWeatherApi('base').then(res => {
    if (res.data.status === '1') {
      currentWeatherObj.value = res.data.lives[0]
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let allWeatherData = ref([]) //所有天气
//所有天气
const allWeatherFun = function () {
  getWeatherApi('all').then(res => {
    if (res.data.status === '1') {
      allWeatherData.value = res.data.forecasts[0].casts
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.hngfScreen'); // 获取需要全屏的元素;
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};
const check = function () {
  enterFullScreen()
}
// 当前时间
let timeVal = ref()
let dateVal = ref()
let weekVal = ref()
const updateTime = function () {
  const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
  const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
  const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
  const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
  const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
  const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
  timeVal.value = hours + ':' + minutes + ':' + seconds
  dateVal.value = year + '-' + month + '-' + day
  weekVal.value = new Intl.DateTimeFormat('zh-CN', {
    weekday: 'long'
  }).format(new Date());
}

// 加载左边第一个
const chartLoad1 = function () {
  var chartDom = document.querySelector('.chart1_2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.generateElectricityListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.generateElectricityListY,
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: '#1F2D3E',
        borderRadius: [10, 10, 10, 10]
      },
      barWidth: 16,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: '#FF639A'
        },
          {
            offset: 1,
            color: '#F5335C'
          },
        ]),
        borderRadius: [10, 10, 10, 10]
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载左边第二个
const chartLoad2 = function () {
  var chartDom = document.querySelector('.chart2_2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.rechargeListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.rechargeListY,
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: '#1F2D3E',
        borderRadius: [10, 10, 10, 10]
      },
      barWidth: 16,
      itemStyle: {
        color: '#43F2AE',
        borderRadius: [10, 10, 10, 10]
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第一个
const circleChartLoad = function () {
  var chartDom = document.querySelector('.circleChart');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    series: [{
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#5ED2FF'
          },
            {
              offset: 0.3,
              color: '#1986EA'
            },
            {
              offset: 0.7,
              color: '#1986EA'
            },
            {
              offset: 1,
              color: '#5ED2FF'
            },
          ]),
        }
      },
      axisLine: {
        lineStyle: {
          width: 20,
          opacity: 0, //See here
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      data: [{
        value: data.value.rechargeRate,
        name: data.value.statusStr,
        title: {
          offsetCenter: ['0%', '-20%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '10%']
        }
      }],
      title: {
        fontSize: 14,
        color: '#fff'
      },
      detail: {
        fontSize: 24,
        color: '#66FFFF',
        formatter: '{value}%'
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第二个
const chartLoad3 = function () {
  var chartDom = document.querySelector('.chart3_2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.generateRateListX,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.generateRateListY,
      type: 'line',
      itemStyle: {
        color: '#43F2AE',
        opacity: 0
      },
      lineStyle: {
        width: 4
      },
    }],
  };
  myChart.clear()
  option && myChart.setOption(option);
  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载下面第一个
const chartLoad4 = function () {
  var chartDom = document.querySelector('.chart4_2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.todayGenerateElectricityListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.todayGenerateElectricityListY,
      type: 'line',
      itemStyle: {
        color: '#18BEF3',
        opacity: 0
      },
      lineStyle: {
        width: 4
      },
      markPoint: {
        data: [{
          type: 'max',
          name: 'Max',
          symbolSize: 40
        }]
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载下边第二个
const chartLoad5 = function () {
  var chartDom = document.querySelector('.chart5_2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.monthGenerateElectricityListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.monthGenerateElectricityListY,
      type: 'line',
      itemStyle: {
        color: '#DA5C1F',
        opacity: 0
      },
      lineStyle: {
        width: 4
      },
      markPoint: {
        data: [{
          type: 'max',
          name: 'Max',
          symbolSize: 40
        }]
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

let data = ref({
  "generateElectricityMonth": "",
  "generateElectricityMonthList": [],
  "generateElectricityToday": "",
  "generateElectricityTotal": "",
  "generateElectricityYesterday": "",
  "generateRateList": [],
  "generateRateMaxTime": "",
  "generateRateMaxValue": "",
  "generateRateMinTime": "",
  "generateRateMinValue": "",
  "generateRateNowTime": "",
  "generateRateNowValue": "",
  "monthMaxRate": "",
  "monthGenerateElectricityList": [],
  "photovoltaicEquipmentList": [],
  "monthAddNumber": "",
  "storeEquipment": {
    "key": "",
    "value": ""
  },
  "thatDayIncome": "",
  "todayGenerateElectricityList": [],
  "totalIncome": "",
  "upMonth": "",
  "upMonthList": [],
  "upToday": "",
  "upTotal": "",
  "upYesterday": ""
})

// 数字轮播数据
let countUp1 = ref()
let countUp2 = ref()
let countUp3 = ref()
let countUp4 = ref()
let countUp5 = ref()
let countUp6 = ref()
let countUp7 = ref()
let countUp8 = ref()
let countUp9 = ref()
let countUp10 = ref()
let countUp11 = ref()
let countUp12 = ref()
let countUp13 = ref()
let countUp14 = ref()
let countUp15 = ref()
//数字轮播初始值
let countStart1 = ref(0)
let countStart2 = ref(0)
let countStart3 = ref(0)
let countStart4 = ref(0)
let countStart5 = ref(0)
let countStart6 = ref(0)
let countStart7 = ref(0)
let countStart8 = ref(0)
let countStart9 = ref(0)
let countStart10 = ref(0)
let countStart11 = ref(0)
let countStart12 = ref(0)
let countStart13 = ref(0)
let countStart14 = ref(0)
let countStart15 = ref(0)

// 获取大屏数据
const getDataFun = async function () {
  await hncnScreenApi().then(res => {
    if (data.value.generateElectricityToday != res.data.result.generateElectricityToday) {
      countStart1.value = data.value.generateElectricityToday
    }
    if (data.value.generateElectricityYesterday != res.data.result.generateElectricityYesterday) {
      countStart2.value = data.value.generateElectricityYesterday
    }
    if (data.value.generateElectricityMonth != res.data.result.generateElectricityMonth) {
      countStart3.value = data.value.generateElectricityMonth
    }
    if (data.value.generateElectricityTotal != res.data.result.generateElectricityTotal) {
      countStart4.value = data.value.generateElectricityTotal
    }
    if (data.value.rechargeToday != res.data.result.rechargeToday) {
      countStart5.value = data.value.rechargeToday
    }
    if (data.value.rechargeYesterday != res.data.result.rechargeYesterday) {
      countStart6.value = data.value.rechargeYesterday
    }
    if (data.value.rechargeMonth != res.data.result.rechargeMonth) {
      countStart7.value = data.value.rechargeMonth
    }
    if (data.value.rechargeTotal != res.data.result.rechargeTotal) {
      countStart8.value = data.value.rechargeTotal
    }
    if (data.value.generateRateMaxValue != res.data.result.generateRateMaxValue) {
      countStart9.value = data.value.generateRateMaxValue
    }
    if (data.value.generateRateMinValue != res.data.result.generateRateMinValue) {
      countStart10.value = data.value.generateRateMinValue
    }
    if (data.value.generateRateNowTime != res.data.result.generateRateNowTime) {
      countStart11.value = data.value.generateRateNowTime
    }
    if (data.value.monthMaxRate != res.data.result.monthMaxRate) {
      countStart12.value = data.value.monthMaxRate
    }
    if (data.value.thatDayIncome != res.data.result.thatDayIncome) {
      countStart13.value = data.value.thatDayIncome
    }
    if (data.value.monthAddNumber != res.data.result.monthAddNumber) {
      countStart14.value = data.value.monthAddNumber
    }
    if (data.value.totalIncome != res.data.result.totalIncome) {
      countStart15.value = data.value.totalIncome
    }
    if (data.value.generateElectricityList && data.value.generateElectricityList.length > 0) {
      if (JSON.stringify(data.value.generateElectricityList) != JSON.stringify(res.data.result.generateElectricityList)) {
        chartLoad1()
      }
    }
    if (data.value.rechargeList && data.value.rechargeList.length > 0) {
      if (JSON.stringify(data.value.rechargeList) != JSON.stringify(res.data.result.rechargeList)) {
        chartLoad2()
      }
    }
    if (data.value.generateRateList && data.value.generateRateList.length > 0) {
      if (JSON.stringify(data.value.generateRateList) != JSON.stringify(res.data.result.generateRateList)) {
        chartLoad3()
      }
    }
    if (data.value.todayGenerateElectricityList && data.value.todayGenerateElectricityList.length > 0) {
      if (JSON.stringify(data.value.todayGenerateElectricityList) != JSON.stringify(res.data.result.todayGenerateElectricityList)) {
        chartLoad4()
      }
    }
    if (data.value.monthGenerateElectricityList && data.value.monthGenerateElectricityList.length > 0) {
      if (JSON.stringify(data.value.monthGenerateElectricityList) != JSON.stringify(res.data.result.monthGenerateElectricityList)) {
        chartLoad5()
      }
    }
    if (data.value.rechargeRate != res.data.result.rechargeRate) {
      circleChartLoad()
    }
    if (res.data.code === 0) {
      data.value = res.data.result
      // 储能发电量
      data.value.generateElectricityListX = []
      data.value.generateElectricityListY = []
      for (let i = 0; i < data.value.generateElectricityList.length; i++) {
        data.value.generateElectricityListX.push(data.value.generateElectricityList[i].key)
        data.value.generateElectricityListY.push(Number(data.value.generateElectricityList[i].value))
      }
      // 储能充电量
      data.value.rechargeListX = []
      data.value.rechargeListY = []
      for (let i = 0; i < data.value.rechargeList.length; i++) {
        data.value.rechargeListX.push(data.value.rechargeList[i].key)
        data.value.rechargeListY.push(Number(data.value.rechargeList[i].value))
      }
      // 储能功率
      data.value.generateRateListX = []
      data.value.generateRateListY = []
      for (let i = 0; i < data.value.generateRateList.length; i++) {
        data.value.generateRateListX.push(data.value.generateRateList[i].key)
        data.value.generateRateListY.push(Number(data.value.generateRateList[i].value))
      }
      // 当天发电量
      data.value.todayGenerateElectricityListX = []
      data.value.todayGenerateElectricityListY = []
      for (let i = 0; i < data.value.todayGenerateElectricityList.length; i++) {
        data.value.todayGenerateElectricityListX.push(data.value.todayGenerateElectricityList[i].key)
        data.value.todayGenerateElectricityListY.push(Number(data.value.todayGenerateElectricityList[i].value))
      }
      // 当月发电量
      data.value.monthGenerateElectricityListX = []
      data.value.monthGenerateElectricityListY = []
      for (let i = 0; i < data.value.monthGenerateElectricityList.length; i++) {
        data.value.monthGenerateElectricityListX.push(data.value.monthGenerateElectricityList[i].key)
        data.value.monthGenerateElectricityListY.push(Number(data.value.monthGenerateElectricityList[i].value))
      }
      // 储能状态
      if (data.value.status == 0) {
        data.value.statusStr = '停机中'
      } else if (data.value.status == 1) {
        data.value.statusStr = '待机中'
      } else if (data.value.status == 2) {
        data.value.statusStr = '放电中'
      } else if (data.value.status == 3) {
        data.value.statusStr = '充电中'
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
onMounted(async () => {
  await getDataFun()
  await equipmentList();
  arrow.value.key = 1
  updateTime()
  //天气
  currentWeatherFun()
  allWeatherFun()

  nextTick(() => {
    chartLoad1()
    chartLoad2()
    chartLoad3()
    chartLoad4()
    chartLoad5()
    circleChartLoad()
  })

  // window.onresize = () => {
  //   let timer = setTimeout(() => {
  //     if (timer) {
  //       clearTimeout(timer)
  //     }
  //     let dom = document.querySelector('.hngfScreen')
  //     console.log(dom.clientWidth / 1920, dom.clientHeight / 1080)
  //     let dom2 = document.querySelector('.screen-wrapper')
  //     dom2.style.transform = `scale(${dom.clientWidth / 1920},${dom.clientHeight / 1080})`
  //     console.log(dom2.style)
  //   }, 500)
  // }
})
//大屏设备数据
const {asyncData, basicData, arrow, circuitValue, transitionName, equipmentList} = useCircuit(getDeviceListApi)
basicData.after.color = 'linear-gradient(to right, #001530, #000d28)'


// 定时器
const intervalId2 = setInterval(getDataFun, 10000)
const intervalId5 = setInterval(equipmentList, 60000)
const intervalId = setInterval(updateTime, 1000)
const intervalId3 = setInterval(currentWeatherFun, 1800000)
const intervalId4 = setInterval(allWeatherFun, 1800000)
onUnmounted(() => {
  clearInterval(intervalId)
  clearInterval(intervalId2)
  clearInterval(intervalId3)
  clearInterval(intervalId4)
  clearInterval(intervalId5)
})
</script>

<style lang="less" scoped>

</style>