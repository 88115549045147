<template>
  <div class="monitoring">
    <div class="title">数据监控</div>
    <!--搜索-->
    <div class="searchBox">
      <div class="left-search">
        <div class="timeBox">
          <span v-show="tags.length>0" style="margin-right: 8px">更新时间</span>
          <span v-show="tags.length>0">{{ formattedDateTime }}</span>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px;">设备名称</label>
          <!--一级查询-->
          <el-select ref="elSelectTable" v-model="firstLevelQuery.name" :popper-append-to-body="false"
                     class="select-unitName select-option-father"
                     clearable
                     placeholder="请选择南向设备" popper-class="select_report_and_type" style="margin-right: 16px"
                     @clear="clearFun(firstLevelQuery)">
            <el-option
                v-for="item in nodes"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                @click="firstInquiry(item.name)">
            </el-option>
          </el-select>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px;">组名称</label>
          <!--二级查询-->
          <el-select ref="elSelectTable" v-model="secondaryQuery.name" :popper-append-to-body="false"
                     class="select-unitName select-option-father"
                     clearable
                     placeholder="请选择组" popper-class="select_report_and_type" style="margin-right: 16px"
                     @clear="clearFun(secondaryQuery)">
            <el-option v-for="item in firstLevelQuery.name?groups:[]"
                       :key="item.name"
                       :label="item.name"
                       :value="item.name">
            </el-option>
          </el-select>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px;">关键字</label>
          <el-input v-model="searchValue" placeholder="请输入关键字搜索" @input="search"/>
        </div>
      </div>
      <div class="liBox" style="min-width: 150px;">
        <el-button class="searchButton" type="primary" @click="secondaryInquiry">查询</el-button>
        <el-button class="resetButton" @click="resetFun">重置</el-button>
      </div>
    </div>
    <div class="contain">
      <!--数据监控列表-->
      <!--      <el-table v-loading="loading" :data="secondaryQuery.name?tags:[]" style="width: 100%" table-layout="fixed">-->
      <el-table v-loading="loading" :data="tags" style="width: 100%" table-layout="fixed">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column fixed label="名称" min-width="229px" prop="name"/>
        <el-table-column label="地址" min-width="226px" prop="address"/>
        <el-table-column label="类型" min-width="90px" prop="type">
          <template #default="scope">
            <span v-if="scope.row.type === 3">INT16</span>
            <span v-if="scope.row.type === 4">UINT16</span>
            <span v-if="scope.row.type === 5">INT32</span>
            <span v-if="scope.row.type === 6">UINT32</span>
            <span v-if="scope.row.type === 7">INT64</span>
            <span v-if="scope.row.type === 8">UINT64</span>
            <span v-if="scope.row.type === 9">FLOAT</span>
            <span v-if="scope.row.type === 10">DOUBLE</span>
            <span v-if="scope.row.type === 11">BIT</span>
            <span v-if="scope.row.type === 13">STRING</span>
            <span v-if="scope.row.type === 14">BYTES</span>
          </template>
        </el-table-column>
        <el-table-column label="乘系数" min-width="180px" prop="upAddress">
          <template #default="scope">
            <span>-</span>
          </template>
        </el-table-column>
        <el-table-column min-width="226px">
          <template #header>
            <div style="display: flex;align-items: center">
              <div>值</div>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  placement="top-start"
              >
                <svg class="icon"
                     height="200" p-id="5136"
                     style="display: flex;align-items: center;width: 18px;height: 20px;margin-left: 4px"
                     t="1718681471146" version="1.1" viewBox="0 0 1024 1024" width="200"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M464 784.352c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"
                        fill="#00b173" p-id="5137"></path>
                  <path
                      d="M512 960C264.96 960 64 759.04 64 512S264.96 64 512 64s448 200.96 448 448-200.96 448-448 448z m0-831.713c-211.584 0-383.713 172.129-383.713 383.713 0 211.552 172.129 383.713 383.713 383.713 211.552 0 383.713-172.16 383.713-383.713 0-211.584-172.161-383.713-383.713-383.713z"
                      fill="#00b173" p-id="5138"></path>
                  <path
                      d="M512 673.695c-17.665 0-32-14.336-32-31.999v-54.112c0-52.353 39.999-92.352 75.327-127.648 25.887-25.92 52.672-52.672 52.672-74.016 0-53.344-43.072-96.736-95.999-96.736-53.823 0-96 41.536-96 94.56 0 17.664-14.335 31.999-32 31.999s-32-14.336-32-32c0-87.423 71.774-158.559 160-158.559S672 297.28 672 385.92c0 47.904-36.32 84.191-71.424 119.296-27.84 27.776-56.575 56.512-56.575 82.335v54.112c0 17.665-14.336 32.032-32.001 32.032z"
                      fill="#00b173" p-id="5139"></path>
                </svg>
                <img alt=""
                     src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg"
                     style="width: 16px;height: 16px;margin-left:5px">
                <template #content>
                  <div>
                    <span style="color: #ffffff;margin-right: 8px">使用16进制输入</span>
                    <el-switch v-model="sixteenTable" style="--el-switch-on-color: #ccefe3;"/>
                  </div>
                </template>
              </el-tooltip>
            </div>
          </template>
          <template #default="scope">
            <div>
              <span :class="[errorTip(scope.row.value) ? 'red' : '']">{{
                  sixteenTable ? decimalToHexadecimal(scope.row.value) : scope.row.value
                }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="描述" min-width="226px" prop="description">
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="100px">
          <template #default="scope">
            <span v-if="scope.row.attribute===3" class="btn" @click="lookClick(scope.row.name)">Write</span>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="tags.length > 10" class="pag">
        <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                       layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                       @current-change="pageChange"/>
      </div>
    </div>
    <!--Write弹窗-->
    <el-dialog v-model="alarmDialog" :close-on-click-modal="false" :title="dTitle" width="640px">
      <div class="tableBox">
        <el-form
            ref="ruleFormRef"
            class="demo-ruleForm"
            label-width="auto"
        >
          <el-form-item prop="delivery">
            <template #default>
              <div style="width: 100%; display: flex;align-items: center;justify-content: space-between">
                <div>值</div>
                <div>
                  <span style="margin-right: 10px">使用16进制输入</span>
                  <el-switch v-model="sixteenDialog" style="--el-switch-on-color: #ccefe3;"/>
                </div>
              </div>
            </template>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dialogInput"/>
          </el-form-item>
          <el-form-item>
            <template #default>
              <view style="display: flex;justify-content: end;width: 100%;">
                <el-button @click="alarmDialog = false">取消</el-button>
                <el-button type="primary" @click="writeClick">确认</el-button>
              </view>
            </template>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {computed, nextTick, onMounted, onUnmounted, ref, watch} from "vue"
import {ElMessage} from 'element-plus'
import {useRouter} from 'vue-router';
import {dataMonitoring, dataMonitoring2, dataTableData, dataTableData2, dataWrite} from "@/api/modules/monitoring";
import {getList} from "@/api/modules/device";
import qs from "qs";
import router from "@/router";

const searchValue = ref('')
//table 数据存储位置
const tags = ref([])
const nodes = ref([])
const groups = ref([])
const tagsName = ref('')
let sixteenTable = ref(false)
let sixteenDialog = ref(false)
/*-------方法---------*/
//错误提示
const errorTip = (msg) => {
  if (msg === void 0) {
    return false;
  }
  if (msg.toString().includes('Error')) {
    // console.log('----')
    return true
  }
  return false
}
//转16进制
const decimalToHexadecimal = (decimalNumber) => {
  if (decimalNumber && String(decimalNumber).includes('Error')) {
    return decimalNumber
  }
  // 使用toString(16)方法将十进制数转换为十六进制字符串
  // padStart(2, '0')确保结果至少有两位，不足则前补0
  return '0x' + decimalNumber.toString(16)
}
let pageSize = ref(20)
let total = ref()
// 更改每页多页
const handleSizeChange = function (e) {
  pageSize.value = e
}
// 获取数据监控列表
let loading = ref(false)

// 查看详细-----------------------------------------------------------------------
// 详细弹窗
let alarmDialog = ref(false)
// 详情数据
let dLoading = ref(false)
let dTitle = ref('')
// 点击Write
const lookClick = function (name) {
  alarmDialog.value = true
  dLoading.value = true
  // console.log(name)
  dTitle.value = name

}
// dialog 输入框
let dialogInput = ref('')
let tempInput = ref('')
watch(sixteenDialog, (newValue, oldValue) => {
  if (newValue) {
    tempInput.value = dialogInput.value
    dialogInput.value = decimalToHexadecimal(dialogInput.value)
  } else {
    dialogInput.value = tempInput.value
  }
});
// 搜索
const search = () => {
  dataTableData({
    node: firstLevelQuery.value.name,
    group: secondaryQuery.value.name,
    name: searchValue.value,
    desc: searchValue.value
  }).then(res => {
    tags.value = res.data.tags
  })
}
// 写入
const writeClick = () => {
  dataWrite({
    group: secondaryQuery.value.name,
    node: firstLevelQuery.value.name,
    tag: dTitle.value,
    value: dialogInput.value
  }).then(res => {
    if (res.data.error === 0) {
    }
    ElMessage({
      message: '提交成功',
      type: 'success'
    })
    alarmDialog.value = false
  })
}
// 一级查询
let firstLevelQuery = ref({
  id: '',
  name: router.currentRoute.value.query.node ? router.currentRoute.value.query.node : '',
  type: router.currentRoute.value.query.group ? router.currentRoute.value.query.group : ''
})
// 二级查询
let secondaryQuery = ref({
  id: '',
  name: '',
  type: ''
})
watch(secondaryQuery, (newVal, oldVal) => {
  // 检查name属性是否为空
  if (newVal.name === '') {
    tags.value = []
  }
}, {
  deep: true // 添加deep: true以深度监听对象内部属性的变化
});
// 下拉框清空
const clearFun = (array) => {
  array.id = ''
  array.name = ''
  array.type = ''
  tags.value = []
}
//重置
const resetFun = () => {
  clearFun(firstLevelQuery.value)
  clearFun(secondaryQuery.value)
}
const firstInquiry = async () => {
  groups.value = []
  secondaryQuery.value.name = ''
  await dataMonitoring2({node: firstLevelQuery.value.name}).then(res => {
    groups.value = res.data.groups
  })
}
const secondaryInquiry = () => {
  loading.value = true
  if (firstLevelQuery.value.name === '' || secondaryQuery.value.name === '') {
    loading.value = false
    return;
  }
  dataTableData({node: firstLevelQuery.value.name, group: secondaryQuery.value.name}).then(res => {
    tags.value = res.data.tags
    dataTableData2({node: firstLevelQuery.value.name, group: secondaryQuery.value.name}).then(res => {
      tags.value = mergeObjectsBySameName(tags.value, res.data.tags)
    }).finally(() => {
      loading.value = false
    })
  })
  setInterval(() => {
    loading.value = false
  }, 60000)
}
const mergeObjectsBySameName = (arr1, arr2) => {
  // 创建arr1的副本作为结果数组，以保持原数组不变
  let result = JSON.parse(JSON.stringify(arr1));

  // 遍历arr2，寻找需要更新的name
  arr2.forEach(obj2 => {
    // 在result数组中查找name属性相同的对象
    const index = result.findIndex(obj1 => obj1.name === obj2.name);
    // 如果找到匹配的对象，则更新或添加value属性
    if (index !== -1) {
      // 如果result中的对象还没有value属性，直接添加
      // eslint-disable-next-line no-prototype-builtins
      if (!result[index].hasOwnProperty('value')) {
        // console.log(obj2.value ? obj2.value : `Error(${obj2.error}):插件未运行`)
        // console.log(obj2,'1')
        result[index].value = obj2.value !== void 0 ? obj2.value : `Error(${obj2.error}):插件未运行`;
      } else {
        // 如果已有value属性，则用arr2中的value进行更新
        result[index].value = obj2.value !== void 0 ? obj2.value : `Error(${obj2.error}):插件未运行`;
      }
    } else {
      // result[index].value = obj2.value!== void 0 ? obj2.value : `Error(${obj2.error}):插件未运行`;
      // 如果没有找到匹配的对象，根据需求决定是否需要添加新对象到result
      // 此处未包含添加新对象到result的逻辑，按需添加
    }
  });

  return result; // 返回更新后的数组副本
};
// 初始化当前时间
let currentTime = ref(new Date());

// 格式化日期时间的函数
const formatDate = (date) => {
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
// 计算属性，用于格式化显示的时间
const formattedDateTime = computed(() => formatDate(currentTime.value));
// 在组件挂载后启动定时器
onMounted(async () => {
  getList(qs.stringify({
    pageNumber: 1,
    pageSize: 100000,
    equipmentType: 1,
  })).then(res => {
    if (res.data.code === 0) {
      nodes.value = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })

  if (router.currentRoute.value.query.group) {
    await firstInquiry()
    secondaryQuery.value.name = router.currentRoute.value.query.group
    search()
  }

  const timer = setInterval(() => {
    currentTime.value = new Date(); // 更新当前时间
    if (tags.value.length > 0) {
      dataTableData2({node: firstLevelQuery.value.name, group: secondaryQuery.value.name}).then(res => {
        tags.value = mergeObjectsBySameName(tags.value, res.data.tags)
      })
    }
  }, 3000); // 每3秒执行一次
  // 在组件卸载时清除定时器，防止内存泄漏
  onUnmounted(() => clearInterval(timer));
});
</script>

<style lang="less" scoped>
@import "monitoring";

:deep(.is-checked) {
  .el-switch__core {
    border: 1px solid #00b173 !important;
  }

  .el-switch__action {
    background-color: #00b173 !important;
  }
}
</style>
