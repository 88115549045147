<template>
	<!--网关管理页面-->
	<div class="gateway">
		<div class="title">网关管理</div>
		<div class="content">
			<div class="searchBox">
				<el-form>
					<div class="liBox">
						<el-form-item label="通讯地址">
							<el-input v-model="address" autocomplete="off" clearable placeholder='请输入通讯地址' type="text"></el-input>
						</el-form-item>
					</div>
					<div class="liBox">
						<el-form-item label="创建时间">
							<el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style" range-separator="→" start-placeholder="开始时间" type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss">
							</el-date-picker>
						</el-form-item>
					</div>
					<div class="liBox">
						<el-form-item label="网关状态">
							<el-select v-model="useStatus" clearable placeholder="请选择" style="width:100%">
								<el-option label="全部" value=""></el-option>
								<el-option label="在线" value="1"></el-option>
								<el-option label="离线" value="0"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="liBox">
						<el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
						<el-button class="searchButton resetButton" @click="resetClick()">重置</el-button>
					</div>
				</el-form>
			</div>
			<div class="tableBox">
				<div class="operationBox">
					<el-button :icon="Plus" type="primary" @click="addClick()">
						新建网关
					</el-button>
				</div>
				<el-table ref="multipleTableRef" v-loading="gatewayLoading" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
					<template #empty>
						<el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
							<p>您可以在此页面新建网关信息</p>
						</el-empty>
					</template>
					<el-table-column label="通讯地址" min-width="1%" property="address">
						<template #default="scope">
							<span>{{ scope.row.address}}</span>
							<img class="adressClass" src="../../assets/img/编组@2x.png" alt="" />
						</template>
					</el-table-column>
					<el-table-column label="状态" min-width="1%" property="useStatusStr">
						<template #default="scope">
							<div class='statusOnClass' v-show="scope.row.useStatus==1">
								<div class="dotOnClass"></div>{{ scope.row.useStatusStr }}</div>
							<div class='statusOutClass' v-show="scope.row.useStatus==0">
								<div class="dotOutClass"></div>{{ scope.row.useStatusStr }}</div>
						</template>
					</el-table-column>
					<el-table-column label="创建人" min-width="1%" property="creatorName">
						<template #default="scope">
							<img class="userClass" src="../../assets/img/user.png" alt="" />
							<sapn>{{ scope.row.creatorName}}</sapn>
						</template>
					</el-table-column>
					<el-table-column label="创建时间" min-width="1%" property="createTime" sortable>
					</el-table-column>
					<el-table-column label="最后通讯时间" min-width="1%" property="lastTime" sortable>
					</el-table-column>
					<el-table-column label="协议类型" min-width="1%" property="protocolTypeStr">
						<template #default="scope">
							<p>{{ scope.row.protocolTypeStr ? scope.row.protocolTypeStr : '698' }}</p>
						</template>
					</el-table-column>
					<el-table-column label="备注" min-width="2%" show-overflow-tooltip property="remark">
					</el-table-column>
					<el-table-column label="操作" min-width="2%">
						<template #default="scope">
							<ul class="operationUl">
								<li class="editClass" @click="addClick(scope.row.id,scope.row)">编辑
								</li>
								<li class="editClass" @click="transmissionFun(scope.row.id,scope.row.protocolType)">透传</li>
								<li class="editClass" @click="checkTimeClick(scope.row.id,scope.row.protocolType)">校时</li>
								<li class="editClass" @click="managerClick(scope.row.id)">管理</li>
								<li class="deleteClass" @click="deleteClick(scope.row.id)">删除
								</li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
				<div class="footerBox">
					<el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :current-page="pageNumber" :small="small" :total="total" background layout="total, sizes, prev, pager, next" small @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</div>
		</div>
		<!--新增网关弹窗-->
		<el-dialog v-model="gatewayDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
			<div>
				<el-form ref="gatewayFormRef" :model="gatewayForm" :rules="gatewayRules" class="demo-ruleForm" style="width:98%" label-width="auto">
					<el-form-item label="通讯地址" prop="address">
						<el-input v-model="gatewayForm.address" placeholder="请输入通讯地址" />
					</el-form-item>
					<el-form-item label="协议类型" prop="protocolType">
						<el-select v-model="gatewayForm.protocolType" placeholder="请选择" style="width:100%">
							<el-option v-for="item in protocolTypeList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注" prop="">
						<el-input v-model="gatewayForm.remark" placeholder="请输入备注" />
					</el-form-item>
					<!--          <el-form-item label="是否为376.1类型" prop="">-->
					<!--            <el-input v-model="gatewayForm.remark" placeholder="请输入备注"/>-->
					<!--          </el-form-item>-->
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
		    		<el-button @click="gatewayDialogVisible = false" class="resetButton">取消</el-button>
		    		<el-button type="primary" @click="gatewaySave">确定</el-button>
      			</span>
			</template>
		</el-dialog>
		<!--透传网关弹窗-->
		<el-dialog v-model="gatewayTrans" :close-on-click-modal="false" class="gatewayTrans" title="透传" width="50%">
			<div class="textarea1">
				<el-table v-if="readData instanceof Array&&readData.length>0" v-loading="readLoading" :data="readData" border style="width: 100%">
					<el-table-column label="上报时间" min-width="1%" prop="applyTime">
					</el-table-column>
					<el-table-column label="上报内容" min-width="2%" prop="apply">
					</el-table-column>
				</el-table>
				<p v-if="typeof readData == 'string'">{{ readData }}</p>
			</div>
			<div slot="footer" class="dialog-footer">
				<div style="display: flex;justify-content: space-between;position: relative;">
					<el-input v-model="msg" autosize class="textarea2" type="textarea">
					</el-input>
					<el-button class="confrim1" style=";position: absolute;bottom:0;right:0" type="primary" @click="sendSave()">
						发送
					</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script setup>
	import { reactive, onMounted, computed, watch, ref, defineComponent, h } from 'vue';
	import { useRouter } from 'vue-router';
	import { ElTable, ElMessage, ElMessageBox, ElLoading } from 'element-plus'
	import { ArrowUpBold, ArrowDownBold, Plus } from '@element-plus/icons-vue'
	import {
		getList,
		getAllCategory,
		getProductList,
		getDetail,
		saveList,
		deleteList,
		checkTimeFun,
		sendCommandList,
		readResultList,
		// 376.1
		tSendCommandListApi,
		tCheckTimeApi,
		getTypeListApi
	} from '../../api/modules/gateway'
	import './gateway.less'
	import qs from 'qs';

	let router = useRouter()
	/*生命周期*/
	onMounted(() => {
		if(router.currentRoute.value.query.pn) {
			pageNumber.value = Number(router.currentRoute.value.query.pn)
		}
		getListFun()
		getTypeListFun()
	})

	let pageNumber = ref(1) //页码
	let pageSize = ref(20) //页数
	let total = ref(0) //总页数
	let tableData = ref([]) //列表数据
	//重置
	const resetClick = function() {
		address.value = ''
		dateTime.value = []
		useStatus.value = ''
	}
	//每页多少
	const handleSizeChange = function(val) {
		pageSize.value = val;
		getListFun();
	}
	//更改页数
	const handleCurrentChange = function(val) {
		pageNumber.value = val;
		getListFun();
	}
	let gatewayLoading = ref(false) //表格加载
	let address = ref() //通讯地址
	let dateTime = ref() //创建时间
	let useStatus = ref('') //状态
	//获取网关列表
	const getListFun = function() {
		gatewayLoading.value = true
		let params = {
			pageNumber: pageNumber.value,
			pageSize: pageSize.value,
			address: address.value,
			useStatus: useStatus.value,
			startTime: dateTime.value ? dateTime.value[0] : '',
			endTime: dateTime.value ? dateTime.value[1] : '',
		}
		getList(qs.stringify(params)).then(res => {
			gatewayLoading.value = false
			//console.log(res);
			if(res.data.ok) {
				tableData.value = res.data.result.list
				total.value = Number(res.data.result.total)
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	let dialogWidth = ref('40%') //新增网关弹窗宽度
	let gatewayDialogVisible = ref(false) //新增网关弹窗开关
	let title = ref() //弹窗标题
	let editId = ref() //编辑id
	let protocolTypeList = ref()
	const getTypeListFun = function() {
		getTypeListApi().then(res => {
			if(res.data.ok) {
				protocolTypeList.value = res.data.result
				for(let i = 0; i < protocolTypeList.value.length; i++) {
					protocolTypeList.value[i].id = Number(protocolTypeList.value[i].id)
				}
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	//新增网关的点击
	const addClick = function(id, row) {
		if(id) {
			editId.value = id
			title.value = '编辑网关'
			gatewayForm.value.address = row.address
			gatewayForm.value.protocolType = row.protocolType
			gatewayForm.value.remark = row.remark
		} else {
			editId.value = ''
			title.value = '新建网关'
			gatewayForm.value = {
				address: '',
				protocolType: 2,
				remark: ''
			}
		}
		gatewayDialogVisible.value = true
	}
	let gatewayFormRef = ref() //网关
	//网关表单
	let gatewayForm = ref({
		address: '',
		protocolType: 2,
		remark: ''
	})
	// 网关表单验证规则
	let gatewayRules = ref({
		address: [{
			required: true,
			message: '请输入通讯地址',
			trigger: 'blur'
		}],
		protocolType: [{
			required: true,
			message: '请选择协议类型',
			trigger: 'blur'
		}],
	})
	//网关的保存
	const gatewaySave = function() {
		let params = {
			id: 0,
			address: gatewayForm.value.address,
			protocolType: gatewayForm.value.protocolType,
			remark: gatewayForm.value.remark
		}
		if(title.value == '新建网关') {
			params['id'] = 0
		} else if(title.value == '编辑网关') {
			params['id'] = editId.value
		}
		if(gatewayFormRef) {
			gatewayFormRef.value.validate((valid) => {
				//提交成功
				if(valid) {
					saveList(
						qs.stringify(params)
					).then(async res => {
						//console.log(res);
						if(res.data.ok) {
							gatewayDialogVisible.value = false
							getListFun()
						} else {
							ElMessage({
								message: res.data.message,
								type: 'error'
							})
						}
					}).catch(err => {
						console.log(err);
					})
				}
			})
		}
	}
	//删除
	const deleteClick = function(id) {
		//		ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
		//			confirmButtonText: '确定',
		//			cancelButtonText: '取消',
		//			type: 'warning',
		//		}).then(() => {
		ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			title: '删除网关',
			message: h('p', null, [
				h('img', {
					src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
					style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
				}, ''),
				h('span', {
					style: 'color:#595959;'
				}, '请确认是否要删除此网关，删除后数据不可恢复'),
			])
		}).then(() => {
			deleteList(
				qs.stringify({
					id: id
				})
			).then(res => {
				//console.log(res);
				if(res.data.ok) {
					getListFun()
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				message: '已取消删除',
				type: 'info',
			})
		});
	}
	//管理
	const managerClick = function(id) {
		router.push({
			path: '/admin/gatewayDetail',
			query: {
				id: id,
				pn: pageNumber.value,
				flag: false
			}
		})
	}
	// 透传相关事件********************************************************
	let gatewayTrans = ref(false)
	let gateWayId = ref()
	let gateType = ref()

	const transmissionFun = function(id, type) {
		gateWayId.value = id
		gateType.value = type
		gatewayTrans.value = true
		// readData.value = []
		msg.value = ''
	}
	let readLoading = ref(false)
	let readData = ref()
	let msg = ref()
	// 透传提交
	const sendSave = function() {
		if(msg.value) {
			//msg.value = msg.value.replace(/\s*/g, ""); //去除空格
			openFullScreen()
			gateType.value == 1 ? tSendCommandListApi({
				// sendCommandList(qs.stringify({
				gatewayId: gateWayId.value,
				msg: msg.value
			}).then(async res => {
				if(res.data.ok) {
					if(res.data.result) {
						await readResultListFun(res.data.result.commandLogId);
					}
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
					loadingVal.value.close()
				}
			}).catch(err => {
				console.log(err);
			}) : sendCommandList(qs.stringify({
				gatewayId: gateWayId.value,
				msg: msg.value.replace(/\s*/g, "") //去除空格
			})).then(async res => {
				if(res.data.ok) {
					if(res.data.result) {
						await readResultListFun(res.data.result);
					}
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
					loadingVal.value.close()
				}
			}).catch(err => {
				console.log(err);
			})
		} else {
			ElMessage({
				type: 'info',
				message: '请先输入报文!'
			});
		}
	}
	//透传结果
	const readResultListFun = function(id) {
		let num = 0
		let timer = setInterval(() => {
			num++
			readResultList(qs.stringify({
				commandLogId: id
			})).then(res => {
				//console.log(num)
				//console.log(res);
				if(res.data.ok) {
					if(res.data.result.length > 0) {
						loadingVal.value.close()
						readData.value = res.data.result
						ElMessage({
							type: 'success',
							message: '透传发送成功!'
						});
					}
					if(num > 9 || res.data.result.length > 0) {
						clearInterval(timer)
					}
					if(num > 9) {
						loadingVal.value.close()
						ElMessage({
							type: 'error',
							message: '透传发送失败!'
						});
					}
				} else {
					ElMessage({
						message: res.data.message,
						type: 'error'
					})
				}
			}).catch(err => {
				console.log(err);
			})
		}, 1000)
	}
	//透传读取列表
	const command_applyListFun = function(id) {
		var params = {
			pageSize: -1,
			pageNum: 1,
			commandLogId: id
		}
		command_applyList(params).then(res => {
			//readLoading.value = false
			loadingVal.value.close()
			readData.value = []
			if(res.data.ok) {
				readData.value = res.data.result
			} else {
				ElMessage({
					message: res.data.message,
					type: 'error'
				})
			}
		}).catch(err => {
			console.log(err);
		})
	}
	/*加载loading*/
	const loadingVal = ref()
	const openFullScreen = () => {
		loadingVal.value = ElLoading.service({
			lock: true,
			text: '',
			background: 'rgba(0, 0, 0, 0.7)',
		})
	}
	//校时
	const checkTimeClick = function(id, type) {
		ElMessageBox.confirm("确认校时吗？", '确认要校时吗？', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'success',
		}).then(async() => {
			openFullScreen()
			type == 1 ? tCheckTimeApi(qs.stringify({
				gatewayId: id
			})).then(res => {
				getListFun()
				loadingVal.value.close()
				//console.log(res);
				if(res.data.ok) {
					ElMessage({
						message: '校时成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			}) : checkTimeFun(qs.stringify({
				gatewayId: id
			})).then(res => {
				getListFun()
				loadingVal.value.close()
				//console.log(res);
				if(res.data.ok) {
					ElMessage({
						message: '校时成功！',
						type: 'success',
					})
				} else {
					ElMessage({
						type: 'error',
						message: res.data.message + '!'
					});
				}
			}).catch(err => {
				console.log(err);
			})
		}).catch(() => {
			ElMessage({
				type: 'info',
				message: '已取消校时！',
			})
		})
	}
</script>