import {api} from "@/api";

// 获取工单列表
export const getWorkOrderListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/ticket/page',
        method: 'POST',
        data: data
    })
}

// 保存工单列表
export const saveWorkOrderListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/ticket/save',
        method: 'POST',
        data: data
    })
}
// 删除工单列表
export const delWorkOrderListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/ticket/delete',
        method: 'POST',
        data: data
    })
}
// 查看工单详情
export const lookWorkOrderDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/ticket/getDetail',
        method: 'POST',
        data: data
    })
}
// 处理工单
export const handleWorkOrderApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/ticket/handle',
        method: 'POST',
        data: data
    })
}