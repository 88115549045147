<template>
  <div class="redis">
    <el-form label-position="top" ref="userForm" :rules="rules" :inline="true" :model="deployGroup"
             label-width="auto">
      <el-form-item v-if="type.name" label="名称" prop="name">
        <el-input v-model="deployGroup.name" :disabled="store.state.sourceData['prohibit']"></el-input>
      </el-form-item>
      <el-form-item prop="addr">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">地址</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">Redis 的地址, 例如：10.122.48.17:6379</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.addr"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">用户名</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">Redis 用户名</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.username"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">密码</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span style="color: #ffffff;margin-right: 8px">Redis 登陆密码</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.password"></el-input>
      </el-form-item>
      <el-form-item prop="dataType">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">数据类型</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">Redis 数据的类型，可以为 string 或者 list， 默认是 string</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-select
            v-model="deployGroup.dataType"
            placeholder="请选择"
            clearable
            size="large"
            style="width: 440px"
        >
          <el-option
              v-for="item in dataTypeList"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineExpose, defineProps, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
const userForm = ref(null);
const type = defineProps({
  componentType: {
    type: Object,
    default: () => {
      return {}
    }
  },
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }, name: {
    type: Boolean,
    default: () => {
      return true
    }
  }
})
const dataTypeList = ref(['string', 'list'])
const store = useStore();
let deployGroup = ref({
  name: '',
  username: '',
  password: '',
  addr: "",
  dataType: "string"
})
const rules = reactive({
  name: [{required: true, message: '请填写名称', trigger: 'blur'}],
  addr: [{required: true, trigger: 'blur', message: ''}],
  dataType: [{required: true, trigger: 'blur', message: ''}],
})
const getFormData = async () => {
  const isValid = await userForm.value.validate();
  if (isValid) {
    return deployGroup.value
  }
}
defineExpose({getFormData});
onMounted(() => {
  deployGroup.value = {...deployGroup.value, ...type.data}
})
</script>


<style scoped lang="less">
.max-tooltip {
  max-width: 280px !important;
}

.redis {
  @import "public";
}
</style>