<template>
  <div class="history">
    <div class="content">
      <div class="tableBox">
        <el-table v-loading="historyLoading" :data="historyData" style="width: 100%">
          <template #empty>
            <el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png" description="暂无数据信息" class="emptyClass">
						</el-empty>
          </template>
          <el-table-column label="时间" prop="time" width="180px" sortable/>
          <el-table-column label="上报内容" min-width="1%" prop="upContent">
          </el-table-column>
          <el-table-column label="回复" min-width="1%" prop="">
            <template #default="scope">
              <div v-for="item in scope.row.applyContentList">
                {{ item }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small"
                         :total="total" background layout="total, sizes, prev, pager, next" small
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent,onUnmounted,onDeactivated} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus, Minus} from '@element-plus/icons-vue'
import {getList} from '../../../api/modules/history'
import './history.less'
import qs from 'qs';
// 定义传值类型
const props = defineProps({
  value: String //这里就接收到父组件传递的value
})
let pageNumber = ref(1) // 当前页数
let pageSize = ref(20) // 每页条数
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
const total = ref(0) //总条数
// 获取列表
let historyLoading = ref(false)
let historyData = ref([])
const getListFun = function () {
  historyLoading.value = true
  getList(qs.stringify({
    pageSize: pageSize.value,
    pageNumber: pageNumber.value,
    gatewayId: props.value
  })).then(res => {
    //console.log(res);
    historyLoading.value = false
    if (res.data.ok) {
      historyData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const intervalId = setInterval(getListFun, 5000)
watch(() => props.value, (newValue, oldValue) => {
  getListFun()
}, {
  immediate: true
})
onUnmounted(() => {
clearInterval(intervalId)
})
onDeactivated(()=>{
	clearInterval(intervalId)
})
</script>

<style>

</style>