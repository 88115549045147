<template>
	<div class="neuron">
		<div>
			<el-form ref="actionData" :inline="true" :model="formData" :rules="formRules">
				<el-collapse v-model="activeNames2" @change="handleChange">
					<el-collapse-item title="Base" name="1">
						<div>
							<div style="display: flex;">
								<div style="flex:1">
									<el-form-item label="" prop="url" style="margin-bottom: 20px">
										<span slot="label">
												<span class="mustClass">*</span> 路径
										<el-tooltip content="连接数采引擎的 URL" effect="dark" placement="top" popper-class="el_tooltip_item">
											<el-icon>
												<QuestionFilled/>
											</el-icon>
										</el-tooltip>
										</span>
										<el-input v-model="formData.url" placeholder="请输入路径"></el-input>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
											分组名称
										<el-tooltip content="数采引擎节点分组名称" effect="dark" placement="top" popper-class="el_tooltip_item">
											<el-icon>
												<QuestionFilled/>
											</el-icon>
										</el-tooltip>
										</span>
										<el-input v-model="formData.groupName" placeholder="请输入分组名称"></el-input>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												原始字符串
												<el-tooltip content="是否发送原始字符串。选择是，则通过数据模板发送原始数据给数采引擎，同时忽略节点名称、分组名称、标签字段的配置" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.raw" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label"> 节点名称
										<el-tooltip content="数采引擎节点名称" effect="dark" placement="top" popper-class="el_tooltip_item">
											<el-icon>
												<QuestionFilled/>
											</el-icon>
										</el-tooltip>
										</span>
										<el-input v-model="formData.nodeName" placeholder="请输入节点名称"></el-input>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												标签字段
												<el-tooltip content="要写入的数据标签，规则中的字段名称和数采引擎中的数据标签名称应相同">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<!--远程下拉-->
										<el-select v-model="formData.tags" placeholder="输入新条目后，按下回车键即可创建新条目" filterable allow-create :reserve-keyword="false" multiple>
											<el-option v-for="item in formData.tags" :key="item" :label="item" :value="item">
											</el-option>
										</el-select>
									</el-form-item>
								</div>
							</div>
						</div>
					</el-collapse-item>
					<el-collapse-item title="Sink 配置" name="2">
						<div>
							<div style="display: flex">
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												是否忽略输出
												<el-tooltip content="如果选择结果为空，则忽略输出。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.omitIfEmpty" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												将结果数据按条发送
												<el-tooltip content='输出消息以数组形式接收，该属性意味着是否将结果一一发送。 如果为 false，则输出消息将为 {"result":"${the string of received message}"}。 例如，{"result":"[{"count":30},""count":20}]"}。否则，结果消息将与实际字段名称一一对应发送。 对于与上述相同的示例，它将发送 {"count":30}，然后发送 {"count":20} 到 RESTful 端点。默认为 false。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.sendSingle" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="流格式" style="margin-bottom: 20px">
										<el-select v-model="formData.format" placeholder="请选择">
											<el-option label="json" value="json" />
											<el-option label="binary" value="binary" />
											<el-option label="protobuf" value="protobuf" />
											<el-option label="delimited" value="delimited" />
											<el-option label="custom" value="custom" />
										</el-select>
									</el-form-item>
								</div>
							</div>
							<el-form-item label="" style="margin-bottom: 20px">
								<span slot="label">
												数据模版
												<el-tooltip content='Golang 模板格式字符串，用于指定输出数据格式。 模板的输入是目标消息，该消息始终是 map 数组。 如果未指定数据模板，则将数据作为原始输入。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
								<el-input v-model="formData.dataTemplate" placeholder="请输入数据模版" type="textarea"></el-input>
							</el-form-item>
							<!------------------------------->
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												线程数
												<el-tooltip content='设置运行的线程数。该参数值大于1时，消息发出的顺序可能无法保证。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.concurrency" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												缓存大小
												<el-tooltip content='设置可缓存消息数目。若缓存消息数超过此限制，sink将阻塞消息接收，直到缓存消息被消费使得缓存消息数目小于限制为止。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.bufferLength" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												批量发送大小
												<el-tooltip content='设置批量发送的消息数目。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.batchSize" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												批量发送间隔
												<el-tooltip content='设置批量发送的间隔时间，单位为毫秒。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.lingerInterval" />
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												启用缓存
												<el-tooltip content="控制是否启用缓存。如果它设置为 true，那么缓存将被启用，否则，它将被禁用。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.enableCache" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												重发间隔
												<el-tooltip content='重新发送缓存消息的时间间隔（毫秒）。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.resendInterval" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												内存缓存阈值
												<el-tooltip content='内存中缓存的最大消息数。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.memoryCacheThreshold" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												最大磁盘缓存
												<el-tooltip content='缓存在磁盘中的最大消息数。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.maxDiskCache" />
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												启用备用队列发送缓存数据
												<el-tooltip content="是否在重新发送缓存时使用备用队列。如果设置为true，缓存将被发送到备用队列，而不是原始队列。这将导致实时消息和重发消息使用不同的队列发送，消息的顺序发生变化，但是可以防止消息风暴。只有设置为 true 时，以下 resend 相关配置才能生效。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.resendAlterQueue" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列发送优先级
												<el-tooltip content='重新发送缓存的优先级，int 类型，默认为 0。-1 表示优先发送实时数据；0 表示同等优先级；1 表示优先发送缓存数据。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.resendPriority" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列字段名
												<el-tooltip content='重新发送缓存的字段名。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input v-model="formData.resendIndicatorField" placeholder="请输入备用队列字段名"></el-input>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列发送目标
												<el-tooltip content='MQTT sink: 该属性表示重传的主题。若未设置，则仍传到原主题。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input v-model="formData.resendDestination" placeholder="请输入备用队列发送目标"></el-input>
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												停止时清理缓存
												<el-tooltip content="规则停止时是否清理缓存" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.cleanCacheAtStop" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</el-form>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, onUnmounted, reactive, ref, watch, defineEmits } from "vue"
	import './neuron.less'
	import { useRouter } from "vue-router/dist/vue-router";
	import { Back, QuestionFilled, Document, Plus } from '@element-plus/icons-vue'
	let router = useRouter()

	const emit = defineEmits(["returnResults"])
	// 定义传值类型
	const props = defineProps({
		actionObj: String //这里就接收到父组件传递的value
	})
	/*生命周期*/
	onMounted(() => {
		if(JSON.parse(props.actionObj).neuron && JSON.parse(props.actionObj).neuron.url) {
			formData.value = JSON.parse(props.actionObj).neuron
			formData.value.type = "neuron"
			console.log(formData.value)
		}
		// 禁止折叠面板按钮点击跳转
		let ele = document.querySelectorAll('.el-collapse-item button')
		for(let i = 0; i < ele.length; i++) {
			ele[i].addEventListener('click', function(event) {
				event.preventDefault();
			});
		}
	});
	const activeNames2 = ref(['1', '2']) //折叠面板默认值
	const handleChange = (val) => {
		//console.log(val)
	}
	let active = ref(2);
	//表单
	let formData = ref({
		//Base
		'url': "tcp://127.0.0.1:7081",
		'nodeName': "",
		'groupName': "",
		'tags': [],
		'raw': false,
		//sink配置
		'omitIfEmpty': false,
		'sendSingle': true,
		'format': "json",
		'dataTemplate': "",
		'concurrency': 1,
		'bufferLength': 1024,
		'batchSize': 0,
		'lingerInterval': 0,
		'enableCache': false,
		'resendInterval': 0,
		'memoryCacheThreshold': 1024,
		'maxDiskCache': 1024000,
		'resendAlterQueue': false,
		'resendPriority': 0,
		'resendIndicatorField': "",
		'resendDestination': "",
		'cleanCacheAtStop': false,
		//-----------
		'runAsync': false,
		'type': "neuron",
		'bufferPageSize': 256,
		'omitIfEmpty': false,
		'sendSingle': true
	})
	//表单规则
	let formRules = ref({
		url: [{
			required: true,
			message: '请输入路径',
			trigger: 'change',
		}],
	})
	let actionData = ref() // 提交表单
	//测试连接的点击
	const testConnectRules = function() {
		actionData.value.validate((valid) => {
			//提交成功
			if(valid) {
				emit("returnResults", formData.value)
			}
		})
	} //提交的点击
	const submitFun = function() {
		emit("submitResults", formData.value)
	}
	// 暴露方法
	defineExpose({
		testConnectRules,
		submitFun
	});
</script>

<style>

</style>