<template>
  <div class="configurationPage">
    <div class="title">配置</div>
    <div class="navigation">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="资源" name="resource"></el-tab-pane>
        <el-tab-pane label="模式" name="mode"></el-tab-pane>
        <el-tab-pane label="文件管理" name="manage"></el-tab-pane>
      </el-tabs>
      <div class="addButton">
        <el-button type="primary" @click="lookClick('','add')">
          {{
            activeName === 'resource' ? '添加配置组' : activeName === 'mode' ? '创建模式' : '创建文件'
          }}
          <template #icon>
            <el-icon>
              <Plus/>
            </el-icon>
          </template>
        </el-button>
      </div>
      <div class="sourcePage-table">
        <!--资源管理表-->
        <!--        v-loading="loading.flowManage"-->
        <el-table v-if="activeName==='resource'" v-loading="loading.resource" :data="tags1" style="width: 100%"
                  table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column fixed label="名称" min-width="350px">
            <template #default="scope">
              <div
                  class="table-Name"
                  @click="lookClick(scope.row,'edit')">
                {{ scope.row.key }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型" min-width="350px" prop="value"/>
          <el-table-column fixed="right" label="操作" min-width="100px">
            <template #default="scope">
              <el-tooltip
                  class="box-item"
                  content="修改"
                  effect="dark"
                  placement="top-start"
              >
                <span class="btn color-blue" style="margin-right: 12px;"
                      @click="lookClick(scope.row,'edit')">修改</span>
              </el-tooltip>
              <el-tooltip
                  class="box-item"
                  content="删除"
                  effect="dark"
                  placement="top-start"
              >
                <span>
                  <el-popover :visible="visible===scope.row" placement="left" width="200px">
                    <div style="width: 200px;height: 86px;padding: 12px;box-sizing: border-box;">
                      <div style="display:flex;align-items: center;">
                        <el-icon color="rgb(255, 153, 0)" style="margin-right: 5px">
                          <QuestionFilled/>
                        </el-icon>
                        <div class="text">
                          您确定要删除吗？
                        </div>
                      </div>
                      <div style="display:flex;justify-content: end;height: 32px;">
                        <el-button text @click="visible = false">No</el-button>
                        <el-button type="primary" @click="deleteClick(scope.row)">
                          Yes
                        </el-button>
                      </div>
                    </div>
                    <template #reference>
                      <span class="btn color-red" @click="visible=scope.row">删除</span>
                    </template>
                  </el-popover>
                </span>
                <!--<span class="btn color-red" @click="visible=true">删除</span>-->
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--模式-->
        <el-table v-if="activeName==='mode'" :data="modelList" style="width: 100%"
                  table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column fixed label="名称" min-width="350px">
            <template #default="scope">
              <div class="table-Name" @click="editModeClick(scope.row)">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="类型" min-width="350px" prop="type"/>
          <el-table-column fixed="right" label="操作" min-width="100px">
            <template #default="scope">
              <el-tooltip
                  class="box-item"
                  content="修改"
                  effect="dark"
                  placement="top-start"
              >
                <span class="btn color-blue" style="margin-right: 12px"
                      @click="editModeClick(scope.row)">修改</span>
              </el-tooltip>
              <el-tooltip
                  class="box-item"
                  content="删除"
                  effect="dark"
                  placement="top-start"
              >
                <span>
                  <el-popover :visible="visible===scope.row" placement="left" width="300px">
                    <div style="width: 300px;height: 85px;padding: 12px;box-sizing: border-box;">
                      <div style="display:flex;align-items: center;">
                        <el-icon color="rgb(255, 153, 0)" style="margin-right: 5px">
                          <QuestionFilled/>
                        </el-icon>
                        <div class="text">
                          您确定要删除吗？
                        </div>
                      </div>
                      <div style="display:flex;justify-content: end;height: 32px;">
                        <el-button text @click="visible = false">No</el-button>
                        <el-button type="primary" @click="deleteClick2(scope.row)">
                          Yes
                        </el-button>
                      </div>
                    </div>
                    <template #reference>
                      <span class="btn color-red" @click="visible=scope.row">删除</span>
                    </template>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--文件管理-->
        <el-table v-if="activeName==='manage'" :data="fileList" style="width: 100%"
                  table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column fixed label="名称" min-width="350px">
            <template #default="scope">
              <div>{{ scope.row }}</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="100px">
            <template #default="scope">
              <el-tooltip
                  class="box-item"
                  content="删除"
                  effect="dark"
                  placement="top-start"
              >
                <span>
                  <el-popover :visible="visible===scope.row" placement="left" width="300px">
                    <div style="width: 300px;height: 85px;padding: 12px;box-sizing: border-box;">
                      <div style="display:flex;align-items: center;">
                        <el-icon color="rgb(255, 153, 0)" style="margin-right: 5px">
                          <QuestionFilled/>
                        </el-icon>
                        <div class="text">
                          您确定要删除吗？
                        </div>
                      </div>
                      <div style="display:flex;justify-content: end;height: 32px;">
                        <el-button text @click="visible = false">No</el-button>
                        <el-button type="primary" @click="deleteClick3(scope.row)">
                          Yes
                        </el-button>
                      </div>
                    </div>
                    <template #reference>
                      <span class="btn color-red" @click="visible=scope.row">删除</span>
                    </template>
                  </el-popover>
                </span>
              </el-tooltip>

            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--模式弹窗-->
    <div class="deploy-dialog">
      <el-dialog v-model="deployStatus" :close-on-click-modal="false"
                 :title="opera==='add'?'创建模式':'编辑模式'">
        <el-form
            ref="ruleFormRef"
            :model="formData"
            :rules="rules"
            label-position="top"
            label-width="auto"
            status-icon
            style="max-width: 600px"
        >
          <el-form-item label="模式名称" prop="name">
            <el-input v-model="formData.name" :disabled="opera==='add'?false:true"></el-input>
          </el-form-item>
          <el-form-item label="模式类型" prop="type">
            <el-select
                v-model="formData.type"
                placeholder="请选择"
                size="large"
            >
              <el-option
                  v-for="item in ['protobuf','custom']"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </el-form-item>
          <div v-if="formData.type==='protobuf'" class="porto">
            <el-form-item :required="formData.type==='protobuf'" prop="file">
              <template #label>
                <div style="position: relative;width: 100%;">
                  <span>模式内容（*.proto）</span>
                  <div class="file-path">
                    <label :class="[protoType==='file'?'active-proto':'unselected-proto']"
                           @click="protoType='file'">文件</label>
                    <label :class="[protoType==='text'?'active-proto':'unselected-proto']"
                           @click="protoType='text'">文本</label>
                  </div>
                </div>
              </template>
              <el-upload v-if="protoType==='file'" v-model:file-list="formData.file" :auto-upload="false"
                         accept=".proto" action="#"
                         class="upload-demo" limit="1" @change="uploadFile($event)">
                <div class="uploadBox">
                  <div :class="formData.file.length>0?'none':''" class="upBtnBox">
                    <el-icon>
                      <Document/>
                    </el-icon>
                    <span>点击上传</span>
                  </div>
                </div>
              </el-upload>
              <el-input v-if="protoType==='text'" v-model="formData.content" type="textarea"></el-input>
            </el-form-item>
          </div>
          <div class="porto">
            <el-form-item :required="formData.type==='custom'" prop="soFile">
              <template #label>
                <div style="position: relative;width: 100%;">
                  <span>模式二进制文件（*.so）</span>
                </div>
              </template>
              <el-upload v-model:file-list="formData.soFile" :auto-upload="false"
                         accept=".so" action="#"
                         class="upload-demo" limit="1" @change="uploadFile2($event)">
                <div class="uploadBox">
                  <div :class="formData.soFile.length>0?'none':''" class="upBtnBox">
                    <el-icon>
                      <Document/>
                    </el-icon>
                    <span>点击上传</span>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <div class="deploy-dialog-footer">
            <div>
              <el-button @click="deployStatus=false">取消</el-button>
              <el-button type="primary" @click="addModeFun">提交</el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
    <!--文件弹窗-->
    <div class="deploy-dialog">
      <el-dialog v-model="fileDialog" :close-on-click-modal="false"
                 title="创建文件">
        <el-form
            ref="ruleFormRef2"
            :model="formData2"
            label-position="top"
            label-width="auto"
            status-icon
            style="max-width: 600px"
        >
          <div class="file-path2">
            <label :class="[fileType==='uploadFile'?'active-proto':'unselected-proto']"
                   @click="fileType='uploadFile'">上传文件</label>
            <label :class="[fileType==='customFile'?'active-proto':'unselected-proto']"
                   @click="fileType='customFile'">自定义文件</label>
          </div>
          <el-form-item v-if="fileType==='customFile'" :required="fileType==='customFile'" label="文件名称" prop="name">
            <el-input v-model="formData2.name"></el-input>
          </el-form-item>
          <el-form-item v-if="fileType==='customFile'" :required="fileType==='customFile'" label="文件内容" prop="content">
            <div class="head">
              <el-upload
                  :auto-upload="false" :show-file-list="false"
                  action="#" class="upload-demo" limit="1" @change="uploadFile3($event)">
                <p class="importBtn">上传文件</p>
              </el-upload>
            </div>
            <div class="monacoEditorContainer"></div>
          </el-form-item>
          <div v-if="fileType==='uploadFile'" class="porto">
            <el-form-item :required="fileType==='uploadFile'" prop="soFile">
              <el-upload v-model:file-list="formData2.soFile" :auto-upload="false"
                         accept=".so" action="#"
                         class="upload-demo" limit="1" @change="uploadFile4($event)">
                <div class="uploadBox">
                  <div :class="formData2.soFile.length>0?'none':''" class="upBtnBox">
                    <el-icon>
                      <Document/>
                    </el-icon>
                    <span>点击上传</span>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <div class="deploy-dialog-footer">
            <div>
              <el-button @click="fileDialog=false">取消</el-button>
              <el-button type="primary" @click="addFileConfigFun">提交</el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref, watch} from "vue"
import {Plus, QuestionFilled, Document} from "@element-plus/icons-vue";
import {
  configureResources,
  deleteConfigure,
  getProtobufModesApi,
  getCustomModesApi,
  addProtobufModesApi,
  addCustomModesApi,
  uploadModeFileApi,
  delProtobufModesApi,
  delCustomModesApi,
  getProtobufOneApi,
  getCustomOneApi,
  editProtobufModesApi,
  editCustomModesApi,
  getFileConfigApi,
  delFileConfigApi,
  addFileConfigApi
} from "@/api/modules/configurationPage";
import router from "@/router";
import {ElMessage} from "element-plus";
import './configurationPage.less'
import qs from "qs";
import * as monaco from "monaco-editor";
/*数据*/
const activeName = ref('resource')
const visible = ref(null)
const tags1 = ref([])
const tags3 = ref([])
const deployStatus = ref(false)
const fileDialog = ref(false)
const formData = ref({
  name: '',
  type: 'protobuf',
  content: '',
  file: [],
  soFile: []
})
const protoType = ref('file')
const rules = reactive({
  name: [{required: true, message: '请填写内容', trigger: 'blur'}],
  type: [{required: true, message: '', trigger: 'blur'}],
})
const formData2 = ref({
  name: '',
  content: '',
  soFile: []
})
let fileType = ref('uploadFile')
let loading = ref({
  resource: true,
  mode: true,
  manage: true
});
/*方法*/
//获取资源数据----------------------------------------------------------------------------
const getFlowData = async (list, loding, tags) => {
  try {
    const response = await list();
    tags.value = response.data.sources.flatMap(obj =>
        Object.entries(obj).map(([key, value]) => ({key, value}))
    );
  } catch (error) {
    console.error('Error fetching or processing flow data:', error);
  } finally {
    loading.value[loding] = false
  }
};
//跳转
let opera = ref('add')
const lookClick = (name, oper) => {
  // console.log(name, oper)
  if (activeName.value === 'resource') {
    if (oper === 'edit') {
      router.push(`/admin/dataConfigurationPage/resourcesPage?oper=${oper}&type=${name.value}&confKeyName=${name.key}`)
      return;
    }
    router.push(`/admin/dataConfigurationPage/resourcesPage?oper=${oper}`);
  } else if (activeName.value === 'mode') {
    deployStatus.value = true
    opera.value = 'add'
    formData.value = {
      name: '',
      type: 'protobuf',
      content: '',
      file: [],
      soFile: []
    }
  } else {
    fileDialog.value = true
    formData2.value = {
      name: '',
      content: '',
      soFile: []
    }
  }
}
//删除
const deleteClick = (row) => {
  const {key, value} = row
  deleteConfigure(value, key).then(res => {
    // console.log(res)
    if (res.status === 200 || res.status === 201) {
      ElMessage({
        message: "删除成功",
        type: 'success',
      })
      getFlowData(configureResources, 'resource', tags1)
    }
  }).catch(err => {
    ElMessage({
      message: err.message,
      type: 'error',
    })
  }).finally(() => {
    visible.value = false
  })
}
/*监听*/
watch(activeName, (newValue) => {
  if (newValue === 'resource') {
    loading.value.resource = true
    tags1.value = []
    getFlowData(configureResources, 'resource', tags1)
  } else if (newValue === 'mode') {
    loading.value.mode = true
    modelList.value = []
    getProtobufModesFun()
    getCustomModesFun()
  } else if (newValue === 'manage') {
    loading.value.manage = true
    getFileConfigFun()
    // tags3.value = []
    // getFlowData(getScanTable, getTables, 'lookup', 'manage', tags3)
  }
}, {deep: true})
let monacoEditor = null
watch(fileType, (newVal) => {
  if (newVal === 'customFile') {
    setTimeout(() => {
      const container = document.querySelector('.monacoEditorContainer')
      monacoEditor = monaco.editor.create(container, {
        value: '', // 编辑器的值
        language: 'mql', //语言
        theme: 'vs', // 编辑器主题：vs, hc-black, or vs-dark
        autoIndent: true, // 自动缩进
        readOnly: false, // 是否只读
        minimap: {   //是否显示缩略图
          enabled: false
        },
        scrollbar: { // 滚动条设置
          verticalScrollbarSize: 2, // 竖滚动条
          horizontalScrollbarSize: 2, // 横滚动条
        }
      })
    }, 500)
  }
}, {deep: true})


// 获取模式数据----------------------------------------------------------------------
let modelList = ref([])
const getProtobufModesFun = function () {
  getProtobufModesApi().then(res => {
    if (res.status === 200 || res.status === 201) {
      for (let i = 0; i < res.data.length; i++) {
        modelList.value.push({
          name: res.data[i],
          type: 'protobuf'
        })
      }
    }
  }).catch(err => {
    ElMessage({
      message: err.message,
      type: 'error',
    })
  })
}
const getCustomModesFun = function () {
  getCustomModesApi().then(res => {
    if (res.status === 200 || res.status === 201) {
      for (let i = 0; i < res.data.length; i++) {
        modelList.value.push({
          name: res.data[i],
          type: 'custom'
        })
      }
    }
  }).catch(err => {
    ElMessage({
      message: err.message,
      type: 'error',
    })
  })
}
// 新建模式
const addModeFun = function () {
  if (formData.value.type === 'protobuf') {
    opera.value === 'edit' ? editProtobufModesApi(formData.value.name, {
      name: formData.value.name,
      content: formData.value.file[0].url ? '' : formData.value.content,
      file: formData.value.file[0].url,
      soFile: formData.value.soFile[0].url,
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        deployStatus.value = false
        modelList.value = []
        getProtobufModesFun()
        getCustomModesFun()
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    }) : addProtobufModesApi({
      name: formData.value.name,
      content: formData.value.content,
      file: formData.value.file[0].url,
      soFile: formData.value.soFile[0].url,
      type: 'protobuf'
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        deployStatus.value = false
        modelList.value = []
        getProtobufModesFun()
        getCustomModesFun()
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    })
  } else {
    opera.value === 'edit' ? editCustomModesApi(formData.value.name, {
      name: formData.value.name,
      soFile: formData.value.soFile[0].url,
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        deployStatus.value = false
        modelList.value = []
        getProtobufModesFun()
        getCustomModesFun()
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    }) : addCustomModesApi({
      name: formData.value.name,
      soFile: formData.value.soFile[0].url,
      type: 'custom'
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        deployStatus.value = false
        modelList.value = []
        getProtobufModesFun()
        getCustomModesFun()
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    })
  }
}
// 编辑模式
const editModeClick = async function (scope) {
  deployStatus.value = true
  opera.value = 'edit'
  if (scope.type === 'protobuf') {
    await getProtobufOneApi(scope.name).then(res => {
      if (res.status === 200 || res.status === 201) {
        formData.value = res.data
        if (formData.value.file !== '') {
          formData.value.file = [{
            name: 'file://' + res.data.file,
            url: 'file://' + res.data.file
          }]
          protoType.value = 'file'
        } else {
          formData.value.file = []
          protoType.value = 'text'
        }
        if (formData.value.soFile !== '') {
          formData.value.soFile = [{
            name: 'file://' + res.data.soFile,
            url: 'file://' + res.data.soFile
          }]
        } else {
          formData.value.soFile = []
        }
        console.log(formData.value)
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    })

  } else {
    await getCustomOneApi(scope.name).then(res => {
      if (res.status === 200 || res.status === 201) {
        formData.value = res.data
        if (formData.value.soFile !== '') {
          formData.value.soFile = [{
            name: 'file://' + res.data.soFile,
            url: 'file://' + res.data.soFile
          }]
        } else {
          formData.value.soFile = []
        }
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    })
  }

}
// 删除模式
const deleteClick2 = function (scope) {
  if (scope.type === 'protobuf') {
    delProtobufModesApi(scope.name).then(res => {
      if (res.status === 200 || res.status === 201) {
        modelList.value = []
        getProtobufModesFun()
        getCustomModesFun()
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    }).finally(() => {
      deployStatus.value = false
    })
  } else {
    delCustomModesApi(scope.name).then(res => {
      if (res.status === 200 || res.status === 201) {
        modelList.value = []
        getProtobufModesFun()
        getCustomModesFun()
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    }).finally(() => {
      deployStatus.value = false
    })
  }
}


// 获取文件管理数据----------------------------------------------------------------------
let fileList = ref([])
const getFileConfigFun = function () {
  getFileConfigApi().then(res => {
    if (res.status === 200 || res.status === 201) {
      fileList.value = res.data
    }
  }).catch(err => {
    ElMessage({
      message: err.message,
      type: 'error',
    })
  })
}
// 新增文件
let fileData = ref()
const addFileConfigFun = function () {
  if (fileType.value === 'customFile') {
    addFileConfigApi({
      name: formData2.value.name,
      content: monacoEditor.getValue()
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        fileDialog.value = false
        getFileConfigFun()
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    })
  } else {
    let fd = new FormData();
    //注意是文件流file.raw
    fd.append('uploadFile', fileData.value.raw); //传文件
    uploadModeFileApi(fd).then(res => {
      if (res.status === 200 || res.status === 201) {
        fileDialog.value = false
        getFileConfigFun()
      }
    }).catch(err => {
      ElMessage({
        message: err.message,
        type: 'error',
      })
    })
  }
}


// 删除文件
const deleteClick3 = function (scope) {
  delFileConfigApi(scope.substring(scope.lastIndexOf("/") + 1)).then(res => {
    if (res.status === 200 || res.status === 201) {
      getFileConfigFun()
    }
  }).catch(err => {
    ElMessage({
      message: err.message,
      type: 'error',
    })
  })
}


// 文件上传--------------------------------------------------------------------------
// 文件上传
const uploadFile = function (file) {
  let fd = new FormData();
  //注意是文件流file.raw
  fd.append('uploadFile', file.raw); //传文件
  uploadModeFileApi(fd).then(res => {
    if (res.status === 200 || res.status === 201) {
      formData.value.file = [{
        name: file.raw.name,
        url: 'file://' + res.data
      }]
    }
  }).catch(err => {
    ElMessage({
      message: err.message,
      type: 'error',
    })
  })
}
// 文件上传
const uploadFile2 = function (file) {
  let fd = new FormData();
  //注意是文件流file.raw
  fd.append('uploadFile', file.raw); //传文件
  uploadModeFileApi(fd).then(res => {
    if (res.status === 200 || res.status === 201) {
      formData.value.soFile = [
        {
          name: file.raw.name,
          url: 'file://' + res.data
        }
      ]
    }
  }).catch(err => {
    ElMessage({
      message: err.message,
      type: 'error',
    })
  })
}
// 文件上传
const uploadFile3 = function (file) {
  const reader = new FileReader();
  reader.readAsText(file.raw);
  reader.onload = (e) => {
    if (e.target.result) {
      const jsonContent = JSON.parse(JSON.stringify(e.target.result));
      // 处理json内容
      monacoEditor.setValue(JSON.stringify(jsonContent, null, 2))
    } else {
      ElMessage({
        type: 'warning',
        message: '文件解析错误，请上传有效的文件！',
      })
    }
  };
}
const uploadFile4 = function (file) {
  fileData.value = file
}


onMounted(() => {
  getFlowData(configureResources, 'resource', tags1)
})
</script>

<style lang="less" scoped>
.configurationPage {
  padding: 28px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .title {
    width: 100%;
    height: 48px;
  }

  .btn {
    cursor: pointer;
  }

  .navigation {
    width: 100%;
    height: calc(100% - 48px);
    overflow: auto;
    margin-bottom: 16px;
    padding: 24px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);

    .el-tabs {
      width: 100%;
    }

    .addButton {
      display: flex;
      justify-content: end;
      width: 100%;
      margin-bottom: 10px;
    }

    .table-Name {
      color: #165DFF;
      cursor: pointer;
    }
  }

  .color-blue {
    color: #165DFF;
    //cursor: pointer;
  }

  .color-red {
    color: #EF2121;
    //cursor: pointer;
  }

  .deploy-dialog {
    :deep(.el-dialog) {
      width: 600px !important;
      box-sizing: border-box;
      border-radius: 8px !important;

      .el-dialog__body, .el-dialog__header, .el-dialog__footer {
        border: none;
      }

      .el-form {
        .el-input__wrapper, .el-input-number, .el-input, .el-form-item__label {
          //width: 500px !important;
          height: 32px !important;
          border-radius: 8px;
          box-sizing: border-box;
        }

        .el-form-item {
          display: block !important;
        }

        .porto {
          .el-form-item__label {
            display: flex;
          }
        }
      }
    }

    .file-path, .file-path2 {
      width: 95px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      //border: 1px solid #343741;
      box-sizing: border-box;
      border-radius: 4px;

      label {
        padding: 5px 11px;
        font-size: 12px;
        color: #000000;
        //border: 1px solid #dcdfe6;
        border-right: none;
        border-radius: 4px 0 0 4px;
      }

      label:last-child {
        //background-color: #ffffff;
        border-left: none;
        border-radius: 0 4px 4px 0;
        border-right: 1px solid #dcdfe6;
      }
    }

    .file-path2 {
      width: 190px;
      height: 32px;
      position: relative;
      margin-bottom: 20px;
      //left: 0;
      //top: 30px;
      label {
        padding: 8px 15px;
      }
    }

    .active-proto {
      border: 1px solid #189bfe;
      color: #ffffff !important;
      background-color: #189bfe;
    }

    .unselected-proto {
      border: 1px solid #dcdfe6;
    }

    .upload-container {
      width: 500px !important;
      height: 32px;
      border: 1px dashed #189bfe;
      border-radius: 8px;
      background-color: rgba(24, 155, 254, .05);
      cursor: pointer;
    }

    .deploy-dialog-footer {
      display: flex;
      justify-content: end;
    }
  }
}
</style>
