<template>
  <div class="workOrder">
    <div class="title">
      <p>
        <span>工单管理</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div style="display: flex;">
          <div class="liBox" style="margin-left: 8px">
            <p>运维工单号</p>
            <el-input v-model="orderNo" placeholder="请输入运维工单号" style="width:70%; height: 32px;"></el-input>
          </div>
          <div class="liBox">
            <p>处理时间</p>
            <el-date-picker v-model="handleTime" end-placeholder="- 结束时间" prefix-icon=""
                            range-separator="→"
                            start-placeholder="开始时间" style="width: 226px; height: 32px;" type="datetimerange"
                            value-format="YYYY-MM-DD HH:mm:ss">
            </el-date-picker>
            <!--            <el-date-picker style="width: 226px; height: 32px;" v-model="handleTime" end-placeholder="结束时间"
                                        popper-class="date-style"
                                        range-separator="→" start-placeholder="开始时间" type="datetimerange"
                                        value-format="YYYY-MM-DD HH:mm:ss">
                        </el-date-picker>-->
          </div>
        </div>
        <div style="display: flex">
          <div class="liBox" style="margin-right: 8px">
            <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          </div>
          <div class="liBox" style="margin-right: 0px">
            <el-button class="searchButton" @click="resetFun">重置</el-button>
          </div>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--新增参数-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          创建工单
        </el-button>
        <!--工单列表-->
        <!--        <el-table v-loading="loading" :data="tableData" stripe style="width: 100%;height: 622px;" table-layout="auto">-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%;" table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <!--              <p>您可以在此页面新建设备信息</p>-->
            </el-empty>
          </template>
          <!--                    <template #empty>
                                            <el-empty image="
                                https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/noData.png">
                                            </el-empty>
                              </template>-->
          <el-table-column fixed label="运维工单号" min-width="140px" prop="orderNo"></el-table-column>
          <el-table-column label="处理人" min-width="120px" prop="handleMemberName">
            <template #default="scope">
              <img v-show="scope.row.handleMemberName" alt="" class="userClass" src="../../assets/img/user.png"/>
              <span>{{ scope.row.handleMemberName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="处理时间" min-width="180px" prop="handleTime"></el-table-column>
          <el-table-column label="故障描述" min-width="180px" prop="faultContent"></el-table-column>
          <el-table-column :filter-method="filterHandler" :filters="[
        { text: '待处理', value: '待处理' },
        { text: '已完结', value: '已完结' },
      ]" label="状态" min-width="80px" prop="statusStr">
            <template #default="scope">
              <span :class="[scope.row.statusStr==='已完结'?'color-green':'color-blue']">{{
                  scope.row.statusStr
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" min-width="180px" prop="createTime"></el-table-column>
          <el-table-column label="创建人" min-width="120px" prop="createName">
            <template #default="scope">
              <img alt="" class="userClass" src="../../assets/img/user.png"/>
              <span>{{ scope.row.createName }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="200px">
            <template #default="scope">
              <!--              <span class="editClass btn" @click.prevent="detailClick(scope.row.id,scope.row)">详情</span>-->
              <!--              <span v-show="scope.row.handleFlag" class="editClass btn"-->
              <!--                    @click.prevent="handleClick(scope.row.id,scope.row)">处理</span>-->
              <!--              <span v-show="scope.row.editFlag" class="editClass btn"-->
              <!--                    @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>-->
              <!--              <span class="editClass btn" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>-->
              <div class="opacity">
                <span class="editClass btn" @click.prevent="detailClick(scope.row.id,scope.row)">详情</span>
                <span :class="['editClass' ,'btn',scope.row.handleFlag?'':'disabled']"
                      @click.prevent="scope.row.handleFlag?handleClick(scope.row.id,scope.row):''">处理</span>
                <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span class="editClass delBtn" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>
                <!--                <span v-show="scope.row.handleFlag" class="editClass btn"
                                      @click.prevent="handleClick(scope.row.id,scope.row)">处理</span>-->
                <!--                <img alt="" src="../../assets/img/161@2x.png" @click.prevent="editClick(scope.row.id,scope.row)">-->
                <!--                <img alt="" src="../../assets/img/163@2x.png" @click.prevent="delClick(scope.row.id,scope.row)">-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-show="tableData.length!==0" v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]"
                         :total="total" background
                         layout="total, sizes, prev, pager, next," @size-change="handleSizeChange"
                         @current-change="pageChange"/>
          <!--          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,prev, pager, next,sizes" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>-->
        </div>
      </div>
    </div>
    <!--新建、编辑工单弹窗-->
    <el-dialog
        v-model="workDrawer"
        title="新建工单"
        width="522"
    >
      <el-form ref="saveFormDataRef" :model="saveFormData" :rules="saveFormDataRules" class="saveFormData"
               status-icon>
        <el-form-item label="故障描述" prop="faultContent">
          <el-input v-model="saveFormData.faultContent" type="textarea"/>
        </el-form-item>
        <el-form-item label="故障图片" prop="faultImage2">
          <el-upload v-model:file-list='saveFormData.faultImage2'
                     :auto-upload="false" :on-preview="handlePictureCardPreview"
                     accept=".png,.jpg,.jpeg" action="#" list-type="picture-card"
                     @change="uploadFile">
            <el-icon>
              <Plus/>
            </el-icon>
            <p>上传图片</p>
          </el-upload>
        </el-form-item>
        <p style="margin-bottom: 20px;">支持jpg、jpeg、png格式</p>
      </el-form>
      <template #footer>
        <div class="operBox">
          <el-button @click="workDrawer=false">取消</el-button>
          <el-button type="primary" @click="saveWorkOrderFun">确认</el-button>
        </div>
      </template>
    </el-dialog>
    <!--    <el-dialog v-model="workDrawer" :show-close="false" title="新建工单" direction="rtl" size="30%">
          <template #header>
            <span class="closeClass">
              <el-icon :size="20" style="cursor: pointer;" @click="workDrawer=false">
                <CloseBold/>
              </el-icon>
            </span>
            <span class="tltleClass">创建工单</span>
            <div class="operBox">
              <el-button @click="workDrawer=false">取消</el-button>
              <el-button type="primary" @click="saveWorkOrderFun">确认</el-button>
            </div>
          </template>
          <template #default>
            <div style="padding: 20px;">
              <el-form ref="saveFormDataRef" :model="saveFormData" :rulesPage="saveFormDataRules" class="saveFormData"
                       status-icon>
                <el-form-item label="故障描述" prop="faultContent">
                  <el-input v-model="saveFormData.faultContent" type="textarea"/>
                </el-form-item>
                <el-form-item label="故障图片" prop="faultImage2">
                  <el-upload v-model:file-list='saveFormData.faultImage2'
                             :auto-upload="false" :on-preview="handlePictureCardPreview"
                             accept=".png,.jpg,.jpeg" action="#" list-type="picture-card"
                             @change="uploadFile">
                    <el-icon>
                      <Plus/>
                    </el-icon>
                    <p>上传图片</p>
                  </el-upload>
                </el-form-item>
                <p style="margin-bottom: 20px;">支持jpg、jpeg、png格式</p>
              </el-form>
            </div>
          </template>
        </el-dialog>-->
    <el-dialog v-model="dialogVisible" title="查看大图">
      <img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
    </el-dialog>
    <!--处理工单弹窗-->
    <el-drawer v-model="handleDrawer" :show-close="false" direction="rtl" size="30%">
      <template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="handleDrawer=false">
						<CloseBold/>
					</el-icon>
				</span>
        <span class="tltleClass">处理工单</span>
        <div class="operBox">
          <el-button @click="handleDrawer=false">取消</el-button>
          <el-button type="primary" @click="handleWorkOrderFun">确认</el-button>
        </div>
      </template>
      <template #default>
        <div style="padding: 20px;">
          <el-form ref="handleFormRef" :model="handleFormData" :rules="handleFormDataRule" class="handleFormData"
                   status-icon>
            <el-form-item label="处理反馈" prop="handleContent">
              <el-input v-model="handleFormData.handleContent" type="textarea"/>
            </el-form-item>
            <el-form-item label="处理图片" prop="imageList2">
              <el-upload v-model:file-list='handleFormData.imageList2'
                         :auto-upload="false" :on-preview="handlePictureCardPreview"
                         accept=".png,.jpg,.jpeg" action="#" list-type="picture-card"
                         @change="uploadFile2">
                <el-icon>
                  <Plus/>
                </el-icon>
                <p>上传图片</p>
              </el-upload>
            </el-form-item>
            <p style="margin-bottom: 20px;">支持jpg、jpeg、png格式</p>
          </el-form>
        </div>
      </template>
    </el-drawer>
    <!--工单详情弹窗-->
    <el-drawer v-model="detailDrawer" :show-close="false" direction="rtl" size="30%">
      <template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="detailDrawer=false">
						<CloseBold/>
					</el-icon>
				</span>
        <span class="tltleClass2">处理工单 (工单号：{{ workOrderDetail.orderNo }})</span>
        <el-button class="status" size="small">{{ workOrderDetail.status }}</el-button>
      </template>
      <template #default>
        <div style="padding: 20px;">
          <div class="tit">工单基本信息</div>
          <div class="pox">
            <p>故障描述</p>
            <p>{{ workOrderDetail.faultContent ? workOrderDetail.faultContent : '-' }}</p>
          </div>
          <div class="pox">
            <p>故障图片</p>
            <p v-if="!workOrderDetail.faultImage2||workOrderDetail.faultImage2.length===0||workOrderDetail.faultImage2[0]===''">
              -</p>
            <div v-for="(item,index) in workOrderDetail.faultImage2" v-else :key="index" class="image">
              <img :src="item.url"
                   alt="" style="width: 90px;height: 90px;">
            </div>
          </div>
          <div class="pox">
            <p>创建人</p>
            <p>{{ workOrderDetail.createName ? workOrderDetail.createName : '-' }}</p>
          </div>
          <div class="pox">
            <p>创建时间</p>
            <p>{{ workOrderDetail.createTime ? workOrderDetail.createTime : '-' }}</p>
          </div>
          <div class="handleContent pox">
            <p class="tit">处理信息</p>
            <p class="info">{{ workOrderDetail.handleContent ? workOrderDetail.handleContent : '暂无处理信息' }}</p>
          </div>
          <div class="pox">
            <p>处理图片</p>
            <p v-if="!workOrderDetail.handleImage2||workOrderDetail.handleImage2.length===0||workOrderDetail.faultImage2[0]===''">
              -</p>
            <div v-for="(item,index) in workOrderDetail.handleImage2" v-else :key="index" class="image">
              <img :src="item.url"
                   alt="" style="width: 90px;height: 90px;">
            </div>
          </div>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref, watch} from "vue"
import './workOrder.less'
import {
  delWorkOrderListApi,
  getWorkOrderListApi,
  saveWorkOrderListApi,
  handleWorkOrderApi,
  lookWorkOrderDetailApi
} from '@/api/modules/workOrder'
import {CloseBold, ZoomIn, Plus, Delete} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {uploadImg} from "@/api/modules/tissue";

let router = useRouter()
let route = useRouter()

// 工单列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 搜索条件
let orderNo = ref()
let handleTime = ref([])
const filterHandler = (value, row, column) => {
  const property = column['property']
  return row[property] === value
}
// 参数表格数据
let tableData = ref([])
// 获取工单列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getWorkOrderListApi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    orderNo: orderNo.value,
    startHandleTime: handleTime.value[0],
    endHandleTime: handleTime.value[1]
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询
const searchFun = function () {
  getListFun()
}
// 重置
const resetFun = function () {
  orderNo.value = ''
  handleTime.value = []
  getListFun()
}


// 图片处理-----------------------------------------------------------------
// 上传图片
let dialogVisible = ref(false) //大图弹窗显示开关
let dialogImageUrl = ref() //大图列表
//新建编辑工单上传文件
const uploadFile = async function (file) {
  let fd = new FormData();
  //注意是文件流file.raw
  fd.append('file', file.raw); //传文件
  await uploadImg(
      fd
  ).then(res => {
    if (res.data.ok) {
      saveFormData.value.faultImage2[saveFormData.value.faultImage2.length - 1].url = res.data.result.fullPath
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
  console.log(saveFormData.value.faultImage2)
}
//处理工单上传文件
const uploadFile2 = async function (file) {
  let fd = new FormData();
  //注意是文件流file.raw
  fd.append('file', file.raw); //传文件
  await uploadImg(
      fd
  ).then(res => {
    if (res.data.ok) {
      handleFormData.value.imageList2[handleFormData.value.imageList2.length - 1].url = res.data.result.fullPath
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
  console.log(handleFormData.value.handleImage)
}
//查看大图
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}


// 新建编辑工单-----------------------------------------------------------------
let workDrawer = ref(false)  //新建编辑弹窗
// 新建编辑表单数据
let saveFormData = ref({
  id: 0,
  faultContent: '',
  faultImage2: [],
})
// 新建编辑表单规则
let saveFormDataRules = ref({
  faultContent: [
    {required: true, message: '请输入故障描述', trigger: 'blur'},
  ],
})
// 点击新建工单
const addClick = function () {
  workDrawer.value = true
  saveFormData.value = {
    id: 0,
    faultContent: '',
    faultImage2: [],
  }
}
// 点击编辑工单
const editClick = function (id, row) {
  workDrawer.value = true
  saveFormData.value = {
    id: id,
    faultContent: row.faultContent
  }
  lookWorkOrderDetailApi(qs.stringify({id: id})).then(res => {
    if (res.data.code === 0) {
      saveFormData.value.faultImage2 = []
      for (let i = 0; i < res.data.result.faultImageList.length; i++) {
        saveFormData.value.faultImage2.push({
          url: res.data.result.faultImageList[i],
          name: i
        })
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 新建编辑工单提交
let saveFormDataRef = ref()
const saveWorkOrderFun = function () {
  if (saveFormDataRef) {
    saveFormDataRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveFormData.value.imageList = []
        for (let i = 0; i < saveFormData.value.faultImage2.length; i++) {
          saveFormData.value.imageList.push(saveFormData.value.faultImage2[i].url)
        }
        saveFormData.value.imageList = saveFormData.value.imageList.join(',')
        delete saveFormData.value.faultImage2
        saveWorkOrderListApi(qs.stringify(saveFormData.value)).then(res => {
          if (res.data.code === 0) {
            workDrawer.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 处理工单-----------------------------------------------------------------
let handleDrawer = ref(false)// 处理工单弹窗
// 处理工单表单数据
let handleFormData = ref({
  id: '',
  handleContent: '',
  imageList2: []
})
// 处理工单表单规则
let handleFormDataRule = ref({
  handleContent: [
    {required: true, message: '请输入处理反馈', trigger: 'blur'},
  ],
})
// 点击处理工单
const handleClick = function (id, row) {
  handleDrawer.value = true
  handleFormData.value = {
    id: id,
    handleContent: '',
    imageList2: []
  }
}
// 处理工单表单提交
let handleFormRef = ref()
const handleWorkOrderFun = function () {
  console.log(handleFormData.value)
  if (handleFormRef) {
    handleFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        handleFormData.value.imageList = []
        for (let i = 0; i < handleFormData.value.imageList2.length; i++) {
          handleFormData.value.imageList.push(handleFormData.value.imageList2[i].url)
        }
        handleFormData.value.imageList = handleFormData.value.imageList.join(',')
        delete handleFormData.value.imageList2
        console.log(handleFormData.value)
        handleWorkOrderApi(qs.stringify(handleFormData.value)).then(res => {
          if (res.data.code === 0) {
            handleDrawer.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 工单详情-----------------------------------------------------------------
let detailDrawer = ref(false)  //工单详情弹窗
// 工单详情数据
let workOrderDetail = ref({})
const detailClick = function (id, row) {
  detailDrawer.value = true
  lookWorkOrderDetailApi(qs.stringify({id: id})).then(res => {
    if (res.data.code === 0) {
      workOrderDetail.value = {
        orderNo: row.orderNo,
        faultContent: res.data.result.faultContent,
        createName: res.data.result.createName,
        createTime: res.data.result.createTime,
        handleContent: res.data.result.handleContent,
        status: res.data.result.status == 1 ? '待处理' : '已完结'
      }
      workOrderDetail.value.faultImage2 = []
      for (let i = 0; i < res.data.result.faultImageList.length; i++) {
        workOrderDetail.value.faultImage2.push({
          url: res.data.result.faultImageList[i],
          name: 'name' + i
        })
      }
      workOrderDetail.value.handleImage2 = []
      for (let i = 0; i < res.data.result.handleImageList.length; i++) {
        workOrderDetail.value.handleImage2.push({
          url: res.data.result.handleImageList[i],
          name: 'name' + i
        })
      }
      console.log(workOrderDetail.value)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 删除工单-----------------------------------------------------------------
const delClick = function (id, row) {
  ElMessageBox.confirm('请确认是否要删除此工单，删除后数据不可恢复', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    // type: 'warning',
    title: '删除工单',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    delWorkOrderListApi(qs.stringify({id: id})).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


onMounted(() => {
  getListFun()
  if (router.currentRoute.value.query.equipmentName) {
    let data = router.currentRoute.value.query
    workDrawer.value = true
    saveFormData.value.faultContent = `告警设备: ${data.equipmentName}\n告警级别: ${data.levelStr}\n告警规则: ${data.triggerRule}\n触发值: ${data.nowValue}`
  }
  const query = {...route.query};
  // 使用新的查询参数替换当前路由
  router.replace({query});
})
</script>

<style lang="less" scoped>
:deep(.cell) {
  min-height: 50px;
  padding: 0;
  height: auto;
}

</style>
