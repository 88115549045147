<template>
  <div class="group">
    <div class="title">
      <p>
        <span @click="goBack">设备管理</span>
        <span> / </span>
        <span>组列表</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        组列表
      </p>
    </div>
    <div class="content">
      <!--内容-->
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">
            添加组
          </el-button>
          <el-button type="primary" @click="exportClick()">
            导出
          </el-button>
          <el-upload v-model:file-list="nodeData" :auto-upload="false" :show-file-list="false" action="#"
                     class="upload-demo"
                     limit="1" @change="uploadFile">
            <el-button style="margin: 0 12px;" type="primary" @click="">
              导入
            </el-button>
          </el-upload>
          <el-button type="primary">
            <a href="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/upload-tag-template.xlsx"
               style="color: #fff;">下载模板</a>
          </el-button>
          <el-button type="primary" @click="batchDelClick()">
            批量删除
          </el-button>
        </div>
        <!--列表-->
        <el-table v-loading="loadingVal" :data="tableData" :row-key="getRowKeys" style="width: 100%"
                  @selection-change="handleSelectionChange">
          <el-table-column :reserve-selection="true" label="选取" style="height:20px" type="selection" width="55">
          </el-table-column>
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面添加组</p>
            </el-empty>
          </template>
          <el-table-column label="组名称" property="name">
            <template #default="scope">
              <span style="cursor: pointer; color:rgb(22, 93, 255);"
                    @click="pointClick(scope.row.name)">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="点位数量" property="tag_count">
            <template #default="scope">
              <p @click="pointClick(scope.row.name)">
                {{ scope.row.tag_count }}</p>
            </template>
          </el-table-column>
          <el-table-column label="间隔(ms)" property="interval">
            <template #default="scope">
              <p @click="pointClick(scope.row.name)">
                {{ scope.row.interval }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <ul class="operationUl">
                <li>
                  <el-tooltip class="box-item" content="点位列表" effect="dark">
                    <img alt="" class="groupClass" src="../../../../assets/img/point.svg"
                         @click="pointClick(scope.row.name)"/>
                  </el-tooltip>
                </li>
                <li>
                  <el-tooltip class="box-item" content="监控" effect="dark">
                    <img alt="" class="groupClass" src="../../../../assets/img/minor.svg"
                         @click="monitoringClick(scope.row.name)"/>
                  </el-tooltip>
                </li>
                <li>
                  <el-popover popper-class="moreOpera" trigger="click">
                    <template #reference>
                      <p class="more">
                        <el-tooltip class="box-item" content="更多" effect="dark">
                          <el-icon class="more">
                            <MoreFilled/>
                          </el-icon>
                        </el-tooltip>
                      </p>
                    </template>
                    <div class="listPox">
                      <p @click="editClick(scope.row)">
                        <el-icon>
                          <EditPen/>
                        </el-icon>
                        <span>编辑</span>
                      </p>
                      <p @click="delClick(scope.row)">
                        <el-icon>
                          <Delete/>
                        </el-icon>
                        <span>删除</span>
                      </p>
                    </div>
                  </el-popover>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="title" width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="组名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入组名称"></el-input>
        </el-form-item>
        <el-form-item label="间隔" prop="interval">
          <el-input v-model="formData.interval" placeholder="请输入间隔">
            <template #suffix>
              ms
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h, onUnmounted} from 'vue';
import './group.less'
import {
  Plus,
  Search,
  CollectionTag,
  EditPen,
  DataAnalysis,
  Setting,
  Memo,
  Download,
  Back,
  MoreFilled,
  Delete
} from '@element-plus/icons-vue'
import {
  getGroups,
  groupAddSave,
  groupEditSave,
  groupDelete,
  tags,
  importGtags,
  newGroupAddSave
} from "@/api/modules/southDriver";
import {ElMessage, ElMessageBox} from "element-plus";
import {useRouter} from "vue-router/dist/vue-router";
import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx'
import qs from "qs";

let router = useRouter()

/*生命周期*/
onMounted(() => {
  getListFun()
})
let loadingVal = ref(false) //加载
let tableData = ref([]) // 组列表数据

// 获取组列表
let loading = ref(false)
//获取组列表
const getListFun = function () {
  loadingVal.value = true
  let params = {
    node: router.currentRoute.value.query.node
  }
  getGroups(params).then(res => {
    loadingVal.value = false
    if (res.status == 200) {
      tableData.value = res.data.groups
    } else {
      tableData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 组表单数据
let formData = ref({
  name: '',
  interval: '1000',
  group: ''
})
let formRules = ref({
  name: [{
    required: true,
    message: '请输入组名称',
    trigger: 'change',
  }],
  interval: [{
    required: true,
    message: '请输入间隔',
    trigger: 'blur'
  }]
})
// 新增编辑表单弹窗
let dialogFormVisible = ref(false)
let title = ref('') //弹窗标题
// 新增组点击
const addClick = function () {
  formData.value = {
    name: '',
    interval: '1000',
    group: ''
  }
  dialogFormVisible.value = true
  title.value = "添加组"
}
// 编辑点击
const editClick = function (row) {
  dialogFormVisible.value = true
  title.value = "编辑组"
  formData.value.name = row.name
  formData.value.interval = row.interval
  formData.value.group = row.name
}
// 提交组表单
let userForm = ref()
const save = function () {
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      var params = {
        interval: Number(formData.value.interval),
        node: router.currentRoute.value.query.node
      }
      if (title.value == '添加组') {
        // params['group'] = formData.value.name
        // groupAddSave(params).then(res => {
        //   if (res.status == 200) {
        //     getListFun()
        //     dialogFormVisible.value = false
        //   } else {
        //     ElMessage({
        //       type: 'error',
        //       message: res.data.message
        //     });
        //   }
        // }).catch(err => {
        //   console.log(err)
        // })
        newGroupAddSave(qs.stringify({
          interval: Number(formData.value.interval),
          node: router.currentRoute.value.query.node,
          group: formData.value.name,
          authorization: 'Bearer ' + window.localStorage.getItem('token')
        })).then(res => {
          if (res.data.code == 0) {
            getListFun()
            dialogFormVisible.value = false
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            });
          }
        }).catch(err => {
          console.log(err)
        })
      } else if (title.value == '编辑组') {
        params['group'] = formData.value.group
        params['new_name'] = formData.value.name
        groupEditSave(params).then(res => {
          if (res.status == 200) {
            getListFun()
            dialogFormVisible.value = false
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            });
          }
        }).catch(err => {
          console.log(err)
        })
      }

    }
  })
}
// 删除组
const delClick = function (row) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除组',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此组，删除后数据不可恢复'),
    ])
  }).then(() => {
    var params = {
      group: row.name,
      node: router.currentRoute.value.query.node
    }
    groupDelete(params).then(res => {
      if (res.status == 200) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}
//复选框触发
let multipleSelection = ref([])
const handleSelectionChange = function (val) {
  multipleSelection.value = val;
}
//保留分页记忆
const getRowKeys = function (row) {
  return row.name
}
/*导出*/
const s2ab = function (s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}
const exportClick = async function () {
  //多个组数据处理
  let idList = []
  multipleSelection.value.forEach(item => {
    idList.push({
      group: item.name,
      interval: item.interval
    })
  })
  if (multipleSelection.value.length > 0) {
    var k = 0
    var jsonArr = []
    for (var g = 0; g < idList.length; g++) {
      k++
      await tags({
        group: idList[g].group,
        node: router.currentRoute.value.query.node
      }).then(res => {
        if (res.status == 200) {
          for (var i = 0; i < res.data.tags.length; i++) {
            res.data.tags[i].group = idList[g].group
            res.data.tags[i].interval = idList[g].interval
            if (res.data.tags[i].attribute == 1) {
              res.data.tags[i].attribute = 'Read'
            } else if (res.data.tags[i].attribute == 2) {
              res.data.tags[i].attribute = 'Write'
            } else if (res.data.tags[i].attribute == 3) {
              res.data.tags[i].attribute = 'Read Write'
            } else if (res.data.tags[i].attribute == 4) {
              res.data.tags[i].attribute = 'Subscribe'
            } else if (res.data.tags[i].attribute == 5) {
              res.data.tags[i].attribute = 'Read Subscribe'
            } else if (res.data.tags[i].attribute == 6) {
              res.data.tags[i].attribute = 'Write Subscribe'
            } else if (res.data.tags[i].attribute == 7) {
              res.data.tags[i].attribute = 'Read Write Subscribe'
            }
            if (res.data.tags[i].type == 3) {
              res.data.tags[i].type = 'INT16'
            } else if (res.data.tags[i].type == 4) {
              res.data.tags[i].type = 'UINT16'
            } else if (res.data.tags[i].type == 5) {
              res.data.tags[i].type = 'INT32'
            } else if (res.data.tags[i].type == 6) {
              res.data.tags[i].type = 'UINT32'
            } else if (res.data.tags[i].type == 7) {
              res.data.tags[i].type = 'INT64'
            } else if (res.data.tags[i].type == 8) {
              res.data.tags[i].type = 'UINT64'
            } else if (res.data.tags[i].type == 9) {
              res.data.tags[i].type = 'FLOAT'
            } else if (res.data.tags[i].type == 10) {
              res.data.tags[i].type = 'DOUBLE'
            } else if (res.data.tags[i].type == 11) {
              res.data.tags[i].type = 'BIT'
            } else if (res.data.tags[i].type == 13) {
              res.data.tags[i].type = 'STRING'
            } else if (res.data.tags[i].type == 14) {
              res.data.tags[i].type = 'BYTES'
            }
            jsonArr.push(res.data.tags[i])
          }
          if (k == idList.length) {
            const data = jsonArr.map(obj => [obj.group, obj.interval, obj.name, obj.address, obj.attribute, obj.type, obj.description, obj.decimal, obj.precision]);
            data.unshift(['group', 'interval', 'name', 'address', 'attribute', 'type', 'description', 'decimal', 'precision',])
            /*二维数组下载为excel*/
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const element = document.createElement('a');
            const blob = new Blob([s2ab(XLSX.write(workbook, {
              bookType: 'xlsx',
              type: 'binary'
            }))], {
              type: "application/octet-stream"
            });
            element.href = URL.createObjectURL(blob);
            element.download = 'tags.xlsx';
            element.click(); // 模拟点击下载
            ElMessage({
              type: 'success',
              message: '导出成功',
            })
            getListFun()
          }

        } else {
          ElMessage({
            type: 'error',
            message: res.data.message,
          })
        }
      }).catch(err => {
        console.log(err);
      })
    }

  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条导出的数据!'
    })
  }
}
/*导入*/
let nodeData = ref()
// 文件上传
const uploadFile = function (file) {
  var groups = []
  // excel文件 —— array
  const reader = new FileReader();
  reader.readAsArrayBuffer(file.raw);
  reader.onload = function (e) {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, {
      type: 'array'
    });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    //去重
    let result = jsonData.filter((item, index, self) => {
      return self.findIndex(t => t.group === item.group) === index;
    });
    result.forEach(item => {
      groups.push({
        group: item.group,
        interval: item.interval,
        tags: []
      })
    })
    groups.forEach(item => {
      jsonData.forEach(items => {
        if (items.attribute == 'Read') {
          items.attribute = 1
        } else if (items.attribute == 'Write') {
          items.attribute = 2
        } else if (items.attribute == 'Read Write') {
          items.attribute = 3
        } else if (items.attribute == 'Subscribe') {
          items.attribute = 4
        } else if (items.attribute == 'Read Subscribe') {
          items.attribute = 5
        } else if (items.attribute == 'Write Subscribe') {
          items.attribute = 6
        } else if (items.attribute == 'Read Write Subscribe') {
          items.attribute = 7
        }
        if (items.type == 'INT16') {
          items.type = 3
        } else if (items.type == 'UINT16') {
          items.type = 4
        } else if (items.type == 'INT32') {
          items.type = 5
        } else if (items.type == 'UINT32') {
          items.type = 6
        } else if (items.type == 'INT64') {
          items.type = 7
        } else if (items.type == 'UINT64') {
          items.type = 8
        } else if (items.type == 'FLOAT') {
          items.type = 9
        } else if (items.type == 'DOUBLE') {
          items.type = 10
        } else if (items.type == 'BIT') {
          items.type = 11
        } else if (items.type == 'STRING') {
          items.type = 13
        } else if (items.type == 'BYTES') {
          items.type = 14
        }
        if (item.group == items.group) {
          item.tags.push(items)
        }
      })
    })
    importClick(groups)
  };
}
const importClick = function (groups) {
  importGtags({
    groups: groups,
    node: router.currentRoute.value.query.node,
  }).then(res => {
    if (res.status == 200) {
      ElMessage({
        type: 'success',
        message: '导入成功',
      })
      getListFun()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//批量删除
const batchDelClick = function () {
  let idList = []
  if (multipleSelection.value.length > 0) {
    ElMessageBox.confirm('', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      title: '删除组',
      message: h('p', null, [
        h('img', {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
        }, ''),
        h('span', {
          style: 'color:#595959;'
        }, '请确认是否要删除此组，删除后数据不可恢复'),
      ])
    }).then(() => {
      for (var i = 0; i < multipleSelection.value.length; i++) {
        groupDelete({
          group: multipleSelection.value[i].name,
          node: router.currentRoute.value.query.node
        }).then(res => {
          getListFun()
        }).catch(err => {
          console.log(err);
          ElMessage({
            type: 'error',
            message: '删除失败',
          })
          return
        })
      }
      ElMessage({
        type: 'success',
        message: '删除成功',
      })
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消删除',
      })
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条删除的数据!'
    })
  }
}
//点位列表点击
const pointClick = function (node) {
  router.push({
    path: '/admin/point',
    query: {
      node: router.currentRoute.value.query.node,
      group: node
    }
  })
}
//返回
const goBack = function () {
  router.push({
    path: '/admin/device',
    query: {
      type: 3
    }
  })
}
//监控的点击
const monitoringClick = function (node) {
  router.push({
    path: '/admin/monitoring',
    query: {
      type: 1,
      node: router.currentRoute.value.query.node,
      group: node
    }
  })
}
const loadingAdd = ref()
//加载的动画
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0)'
  });
}
</script>

<style lang="less">
.moreOpera {
  width: 180px !important;
}

//.moreOpera .listPox {
//  position: fixed;
//  z-index: 99999;
//  width: 148px;
//  padding: 8px 16px;
//  background-color: #fff;
//  border-radius: 4px;
//  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
//
//  > p {
//    padding: 8px;
//    cursor: pointer;
//
//    .el-icon {
//      margin-right: 8px;
//    }
//  }
//}
</style>