<template>
  <div class="energyFlowChart">
    <div class="title">
      <p>
        <span>能流图</span>
      </p>
    </div>
    <div class="content">
      <!--左侧内容-->
      <div class="leftBox">
        <div class="searchBox">
          <el-input v-model="searchVal" class="input-with-select" clearable placeholder="请输入">
            <template #append>
              <el-button :icon="Search" @click="searchClick(customerList,searchVal)"/>
            </template>
          </el-input>
        </div>
        <div class="treeBox">
          <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                   :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                   :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
            <template #default="{ node, data }">
							<span class="custom-tree-node" style="width:100%;">
	              <span>{{ node.label }}</span>
							</span>
            </template>
          </el-tree>
        </div>
      </div>
      <!--右侧内容-->
      <div class="rightBox">
        <!--选择水电气-->
        <div class="headSelect">
          <p :class="activeIdx===1?'activeP':''" @click="activeClick(1)">水</p>
          <p :class="activeIdx===2?'activeP':''" @click="activeClick(2)">电</p>
          <p :class="activeIdx===3?'activeP':''" @click="activeClick(3)">气</p>
        </div>
        <!--搜索-->
        <div v-if="isShowPage" class="searchBox">
          <div class="liBox">
            <el-select v-model="timeType" clearable placeholder="请选择" @change="timeTypeClick">
              <el-option :value="1" label="日"></el-option>
              <el-option :value="2" label="月"></el-option>
              <el-option :value="3" label="年"></el-option>
            </el-select>
          </div>
          <div class="liBox" style="display: flex">
            <el-date-picker v-if="timeType===1" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                            range-separator="→"
                            start-placeholder="开始时间"
                            type="daterange"
                            value-format="YYYY-MM-DD">
            </el-date-picker>
            <el-date-picker v-if="timeType===2" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                            range-separator="→"
                            start-placeholder="开始时间"
                            type="monthrange"
                            value-format="YYYY-MM-DD">
            </el-date-picker>
            <el-date-picker v-if="timeType===3"
                            v-model="dateTime[0]"
                            :disabled-date="disabledDate"
                            placeholder="开始时间"
                            type="year" value-format="YYYY-MM-DD"
            />
            <div v-if="timeType===3" class="segmentation" style="margin: 0 8px;transform: translateY(5px)">至</div>
            <el-date-picker v-if="timeType===3"
                            v-model="dateTime[1]"
                            :disabled-date="disabledDate2"
                            placeholder="结束时间"
                            type="year"
                            value-format="YYYY-MM-DD"
            />
          </div>
          <div class="operation">
            <el-popover
                content=""
                placement="bottom"
                popper-class="energyPopver"
                title="生成报表"
                trigger="hover"
                width="72"
            >
              <template #reference>
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon1.png">
              </template>
            </el-popover>
            <el-popover
                content=""
                placement="bottom"
                popper-class="energyPopver"
                title="全屏展示"
                trigger="hover"
                width="72"
            >
              <template #reference>
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon2.png"
                     @click="enterFullScreen">
              </template>
            </el-popover>
            <el-popover
                content=""
                placement="bottom"
                popper-class="energyPopver"
                title="编辑模式"
                trigger="hover"
                width="72"
            >
              <template #reference>
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon3.png"
                     @click="isShowPage=false">
              </template>
            </el-popover>
          </div>
        </div>
        <!--能流图-->
        <div v-if="isShowPage" class="energyFlow">
          <div class="sankeyChart"></div>
        </div>
        <EditEnergy v-if="!isShowPage" @isShowFun="isShowFun"/>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref, watch} from "vue"
import './energyFlowChart.less'
import * as echarts from "echarts";
import {useRouter} from "vue-router/dist/vue-router";
import {Search, Plus} from '@element-plus/icons-vue'
import {getDeviceListApi, getProjectListApi} from "@/api/modules/project";
import {ElMessage} from "element-plus";
import qs from "qs";
import moment from "moment";
import EditEnergy from './editEnergy'


let router = useRouter()


// 选择水电气
let activeIdx = ref(1)
const activeClick = function (idx) {
  activeIdx.value = idx
}

// 日月年切换搜索-----------------------------------------------------------------------------
let dateTime = ref([moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]) //开始结束时间
let timeType = ref(1)
const timeTypeClick = function (idx) {
  timeType.value = idx
  if (timeType.value === 1) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]
  } else if (timeType.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (timeType.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  }
}
watch(() => dateTime.value, (val) => {
  if (timeType.value == 3) {
    disabledDate(new Date(val[0]))
    disabledDate2(new Date(val[1]))
  }
})
const disabledDate = (time) => {
  if (dateTime.value[1]) {
    return time.getTime() >= moment(dateTime.value[1]).valueOf()
  }
  return false
}
const disabledDate2 = (time) => {
  if (dateTime.value[0]) {
    return time.getTime() < moment(dateTime.value[0]).valueOf()
  }
  return false
}


// 编辑或展示-----------------------------------------------------------------------------------
let isShowPage = ref(true)
const isShowFun = function (val) {
  isShowPage.value = val
  nextTick(() => {
    chartLoad()
  })

}


// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
let searchVal = ref() //树级列表字段
let defaultExpandKeys = ref([])
//树级列表搜索
const searchClick = function (data, name) {
  defaultExpandKeys.value = []
  let res = tree(data, name)
  if (res) {
    treeRef.value.setCurrentKey(res.id)
    defaultExpandKeys.value.push(res.parentId)
    custerObj.value = res

  }
}
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let treeRef = ref(null)
//获取项目树级列表
const getProjectListFun = async function () {
  await getProjectListApi().then(res => {
    if (res.data.ok) {
      customerList.value = []
      customerList.value.push(res.data.result)
      //有无路由参数
      if (router.currentRoute.value.query.id) {
        defaultExpandKeys.value = [router.currentRoute.value.query.id]
      } else {
        if (window.localStorage.getItem('currentNodeKey2')) {
          defaultExpandKeys.value = [window.localStorage.getItem('currentNodeKey2')]
        } else {
          defaultExpandKeys.value = [customerList.value[0].id]
        }
      }
      if (currentNodeKey.value === undefined) { //第一次加默认选中
        //有无路由参数
        if (router.currentRoute.value.query.id) {
          currentNodeKey.value = router.currentRoute.value.query.id
          custerObj.value = JSON.parse(window.localStorage.getItem('project'))
        } else {
          if (window.localStorage.getItem('currentNodeKey2')) {
            currentNodeKey.value = window.localStorage.getItem('currentNodeKey2')
            custerObj.value = JSON.parse(window.localStorage.getItem('custerObj2'))
          } else {
            currentNodeKey.value = customerList.value[0].id
            custerObj.value = customerList.value[0]
          }
        }
        nextTick(() => {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        })
      } else {
        nextTick(() => {
          treeRef.value.setCurrentKey(custerObj.value.id)
        })
      }
      nextTick(() => {
        treeRef.value.setCurrentKey(currentNodeKey.value)
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  window.localStorage.setItem('currentNodeKey2', currentNodeKey.value)
  window.localStorage.setItem('custerObj2', JSON.stringify(e))
  defaultExpandKeys.value.push(e.parentId)
}


// 加载图表----------------------------------------------------------------------------------------
const chartLoad = function () {
  var chartDom = document.querySelector('.sankeyChart');
  var myChart = echarts.init(chartDom);
  var option;
  let a = '44%'
  option = {
    series: {
      type: 'sankey',
      layout: 'none',
      nodeGap: 60,
      nodeAlign: 'left',
      // nodeWidth: 60,
      layoutIterations: 0,  //是否根据data顺序进行展示，默认32
      emphasis: {
        focus: 'adjacency'
      },
      data: [
        {
          name: 'a'
        },
        {
          name: 'b1',
          itemStyle: {
            color: '#F44826'
          }
        },
        {
          name: 'b2'
        },
        {
          name: 'b3'
        },
        {
          name: 'c1'
        },
        {
          name: 'c2'
        },
        {
          name: 'c3'
        },
        {
          name: 'c4'
        },
        {
          name: 'c5'
        },
        {
          name: 'c6'
        },
        {
          name: 'd2'
        },
        {
          name: 'd3'
        },
        {
          name: 'e3'
        },
        {
          name: 'f3'
        },
      ],
      links: [
        {
          source: 'a',
          target: 'b1',
          value: 5
        },
        {
          source: 'a',
          target: 'b2',
          value: 3
        },
        {
          source: 'a',
          target: 'b3',
          value: 8
        },
        {
          source: 'b1',
          target: 'c1',
          value: 3,
          lineStyle: {
            color: '#F44826'
          }
        },
        {
          source: 'b1',
          target: 'c2',
          value: 1
        },
        {
          source: 'b1',
          target: 'c3',
          value: 1
        },
        {
          source: 'b2',
          target: 'c4',
          value: 3
        },
        {
          source: 'b3',
          target: 'c5',
          value: 6
        },
        {
          source: 'b3',
          target: 'c6',
          value: 2
        },
        {
          source: 'c2',
          target: 'd2',
          value: 1
        },
        {
          source: 'c3',
          target: 'd3',
          value: 1
        },
        {
          source: 'd3',
          target: 'e3',
          value: 1
        },
        {
          source: 'e3',
          target: 'f3',
          value: 1
        }
      ],
      levels: [
        {
          depth: 0,
          itemStyle: {
            // color: '#165DFF',
            color: '#8C9CF8',
            opacity: 0.8
          },
          lineStyle: {
            // color: '#165DFF',
            color: '#8C9CF8',
            opacity: 0.2
          }
        },
        {
          depth: 1,
          itemStyle: {
            color: '#6AADFF',
            opacity: 0.8
          },
          lineStyle: {
            color: '#6AADFF',
            opacity: 0.2
          }
        },
        {
          depth: 2,
          itemStyle: {
            color: '#3ADEC1',
            opacity: 0.8
          },
          lineStyle: {
            color: '#3ADEC1',
            opacity: 0.2
          }
        },
        {
          depth: 3,
          itemStyle: {
            color: '#35D6DB',
            opacity: 0.8
          },
          lineStyle: {
            color: '#35D6DB',
            opacity: 0.2
          }
        },
        {
          depth: 4,
          itemStyle: {
            color: '#97D06C',
            opacity: 0.8
          },
          lineStyle: {
            color: '#97D06C',
            opacity: 0.2
          }
        }
      ],
      label: {
        formatter: '{b}\n{c}\n' + a,
        fontSize: 18
      }
    }
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}


// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.energyFlow'); // 获取需要全屏的元素;
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};

onMounted(() => {
  getProjectListFun()
  nextTick(() => {
    chartLoad()
  })
})
</script>

<style lang="less" scoped>
</style>
