// 数据处理页面hooks
import {ref} from 'vue';

export default function userSourcePageEditorData() {
    let formData = ref({
        name: '',
        dataSource: '',
        configurationGroup: '',
        streamingFormat: '',
        streamFieldType: '',
        streamFieldTable: [],
        shared: false,
        primaryKey: null,
        reserveSize: 0
    })
    // 配置组
    const configGroup = ref(null)
//格式
    const formatGroup = ref([
        'json',
        'binary',
        'protobuf',
        'delimited',
        'custom'
    ])
//流字段类型
    const streamFieldType = ref([
        "bigint",
        "float",
        "string",
        "datetiem",
        "boolean",
        "array",
        "struct",
        "bytea"
    ])
    const streamField = ref({
        Name: '',
        FieldType: ''
    })
    let streamFieldStatus = ref(false)
    const deployStatus = ref(false)

    let activeCell = ref(null)
    /*let arrayList = ref([
        {
            "initial": "File",
            "name": "file",
            "url": 'http://192.168.3.253:8085/web/common/img/file.0accde53.svg',
            "about": {
                "trial": true,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "https://ekuiper.org/docs/en/latest/guide/sources/builtin/file.html",
                    "zh": "http://192.168.3.253:8085/web/common/img/file.0accde53.svg"
                },
                "description": {
                    "en": "Monitor file system and load the content into the eKuiper processing pipeline.",
                    "zh": "监控文件系统，读取文件内容，并且将数据放入数据处理流水线中。"
                }
            }
        },
        {
            "initial": "HttpPull",
            "name": "httppull",
            "url": 'http://192.168.3.253:8085/web/common/img/httppull.41a963a7.svg',
            "about": {
                "trial": false,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "https://ekuiper.org/docs/en/latest/guide/sources/builtin/http_pull.html",
                    "zh": "https://ekuiper.org/docs/zh/latest/guide/sources/builtin/http_pull.html"
                },
                "description": {
                    "en": "eKuiper provides built-in support for pulling HTTP source stream, which can pull the message from HTTP server broker and feed into the eKuiper processing pipeline.",
                    "zh": "支持可从 HTTP 服务器代理提取消息并输入数据处理管道。"
                }
            }
        },
        {
            "initial": "HttpPush",
            "name": "httppush",
            "url": 'http://192.168.3.253:8085/web/common/img/httppush.2f9dc5f4.svg',
            "about": {
                "trial": false,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "https://ekuiper.org/docs/en/latest/guide/sources/builtin/http_push.html",
                    "zh": "https://ekuiper.org/docs/zh/latest/guide/sources/builtin/http_push.html"
                },
                "description": {
                    "en": "eKuiper provides built-in support for HTTP server to receive the stream utils pushed by HTTP protocol.",
                    "zh": "内置的 HTTP 服务支持，用于接收 HTTP 协议推送的流数据。"
                }
            }
        },
        {
            "initial": "Memory",
            "name": "memory",
            "url": 'http://192.168.3.253:8085/web/common/img/memory.55d85a26.svg',
            "about": {
                "trial": true,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "https://ekuiper.org/docs/en/latest/guide/sources/builtin/memory.html",
                    "zh": "https://ekuiper.org/docs/zh/latest/guide/sources/builtin/memory.html"
                },
                "description": {
                    "en": "The source subscribes to topics to consume events from memory sink to form a rule pipeline.",
                    "zh": "内存源可通过订阅主题来消费内存sink里的消息，从而实现规则流水线。"
                }
            }
        },
        {
            "initial": "MQTT",
            "name": "mqtt",
            "url": 'http://192.168.3.253:8085/web/common/img/mqtt.aa375789.svg',
            "about": {
                "trial": false,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "https://ekuiper.org/docs/en/latest/guide/sources/builtin/mqtt.html",
                    "zh": "https://ekuiper.org/docs/zh/latest/guide/sources/builtin/mqtt.html"
                },
                "description": {
                    "en": "subscribe the message from MQTT broker and feed into the processing pipeline.",
                    "zh": "订阅来自 MQTT 代理的消息并输入处理管道。"
                }
            }
        },
        {
            "initial": "neuron",
            "name": "neuron",
            "url": 'http://192.168.3.253:8085/web/common/img/neuron.db27b457.svg',
            "about": {
                "trial": true,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "https://ekuiper.org/docs/en/latest/guide/sources/builtin/neuron.html",
                    "zh": "https://ekuiper.org/docs/zh/latest/guide/sources/builtin/neuron.html"
                },
                "description": {
                    "en": "Consume utils from the utils collection engine.",
                    "zh": "从数采引擎中消费数据。"
                }
            }
        },
        {
            "initial": "Simulator",
            "name": "simulator",
            "url": 'http://192.168.3.253:8085/web/common/img/simulator.6d75bf6d.svg',
            "about": {
                "trial": true,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "",
                    "zh": ""
                },
                "description": {
                    "en": "Generate simulated utils for consumption.",
                    "zh": "生成模拟数据供消费。"
                }
            }
        },
        {
            "initial": "Redis",
            "name": "redisSub",
            "url": 'http://192.168.3.253:8085/web/common/img/redis.9d87938a.svg',
            "about": {
                "trial": false,
                "installed": true,
                "author": {
                    "name": "XinTong Zhou",
                    "email": "emy120115@gmail.com",
                    "company": "persional",
                    "website": ""
                },
                "helpUrl": null,
                "description": {
                    "en": "This is a source plugin for Redis. It can be used to subscribe to data from Redis message channels.",
                    "zh": "从redis中读消息。"
                }
            }
        },
        {
            "initial": "SQL",
            "name": "sql",
            "url": 'http://192.168.3.253:8085/web/common/img/sql.2b56f7af.svg',
            "about": {
                "trial": true,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "https://ekuiper.org/docs/en/latest/guide/sources/plugin/sql.html",
                    "zh": "https://ekuiper.org/docs/zh/latest/guide/sources/plugin/sql.html"
                },
                "description": {
                    "en": "Read message from sql database",
                    "zh": "从数据库中读取消息"
                }
            }
        },
        {
            "initial": "Video",
            "name": "video",
            "url": 'http://192.168.3.253:8085/web/common/img/video.769e4c72.svg',
            "about": {
                "trial": false,
                "installed": true,
                "author": {
                    "name": "EMQ",
                    "email": "contact@emqx.io",
                    "company": "EMQ Technologies Co., Ltd",
                    "website": "https://www.emqx.io"
                },
                "helpUrl": {
                    "en": "https://ekuiper.org/docs/en/latest/guide/sources/plugin/video.html",
                    "zh": "https://ekuiper.org/docs/zh/latest/guide/sources/plugin/video.html"
                },
                "description": {
                    "en": "Pull images from a video stream",
                    "zh": "可从视频流提取图片消息并输入到处理管道。"
                }
            }
        }
    ])*/
    let arrayList = ref([
        {
            "initial": "File",
            "name": "file",
            "url": 'http://192.168.3.253:8085/web/common/img/file.0accde53.svg',
            "description": {
                "zh": "监控文件系统，读取文件内容，并且将数据放入数据处理流水线中。"
            }
        },
        {
            "initial": "HttpPull",
            "name": "httppull",
            "url": 'http://192.168.3.253:8085/web/common/img/httppull.41a963a7.svg',
            "description": {
                "zh": "支持可从 HTTP 服务器代理提取消息并输入数据处理管道。"
            }
        },
        {
            "initial": "HttpPush",
            "name": "httppush",
            "url": 'http://192.168.3.253:8085/web/common/img/httppush.2f9dc5f4.svg',
            "description": {
                "zh": "内置的 HTTP 服务支持，用于接收 HTTP 协议推送的流数据。"
            }
        },
        {
            "initial": "Memory",
            "name": "memory",
            "url": 'http://192.168.3.253:8085/web/common/img/memory.55d85a26.svg',
            "description": {
                "zh": "内存源可通过订阅主题来消费内存sink里的消息，从而实现规则流水线。"
            }
        },
        {
            "initial": "MQTT",
            "name": "mqtt",
            "url": 'http://192.168.3.253:8085/web/common/img/mqtt.aa375789.svg',
            "description": {
                "zh": "订阅来自 MQTT 代理的消息并输入处理管道。"
            }
        },
        {
            "initial": "neuron",
            "name": "neuron",
            "url": 'http://192.168.3.253:8085/web/common/img/neuron.db27b457.svg',
            "description": {
                "zh": "从数采引擎中消费数据。"
            }
        },
        {
            "initial": "Simulator",
            "name": "simulator",
            "url": 'http://192.168.3.253:8085/web/common/img/simulator.6d75bf6d.svg',
            "description": {
                "zh": "生成模拟数据供消费。"
            }
        },
        {
            "initial": "Redis",
            "name": "redis",
            "url": 'http://192.168.3.253:8085/web/common/img/redis.9d87938a.svg',
            "description": {
                "zh": "从redis中读消息。"
            }
        },
        {
            "initial": "SQL",
            "name": "sql",
            "url": 'http://192.168.3.253:8085/web/common/img/sql.2b56f7af.svg',
            "description": {
                "zh": "从数据库中读取消息"
            }
        },
        {
            "initial": "Video",
            "name": "video",
            "url": 'http://192.168.3.253:8085/web/common/img/video.769e4c72.svg',
            "description": {
                "zh": "可从视频流提取图片消息并输入到处理管道。"
            }
        }
    ])
    let dataSource = ref(false)
    let mqttVersion = ref(['3.1', '3.1.1'])
    let qosGrade = ref([0, 1, 2])
    let compressionFormat = ref(['zlib', 'gzip', 'flate', 'zstd'])
    const removeEmptyValues = (obj) => {
        // 检查是否为对象或数组，如果不是则直接返回
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        // 处理数组
        if (Array.isArray(obj)) {
            return obj
                .filter(item => item !== null && item !== undefined && item !== '' && (typeof item !== 'object' || Object.keys(item).length > 0))
                .map(item => removeEmptyValues(item));
        }

        // 处理对象
        return Object.entries(obj)
            .filter(([key, value]) => value !== null && value !== undefined && value !== '' && (typeof value !== 'object' || Object.keys(value).length > 0))
            .reduce((acc, [key, value]) => ({...acc, [key]: removeEmptyValues(value)}), {});
    }
    return {
        formData,
        configGroup,
        formatGroup,
        streamFieldType,
        streamField,
        dataSource, streamFieldStatus,
        deployStatus,
        activeCell,
        arrayList,
        mqttVersion,
        qosGrade,
        compressionFormat,
        removeEmptyValues
    }
}