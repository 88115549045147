<template>
	<div class="mqtt">
		<div>
			<el-form ref="actionData" :inline="true" :model="formData" :rules="formRules">
				<el-collapse v-model="activeNames2" @change="handleChange">
					<el-collapse-item title="Connection" name="1">
						<div>

							<div style="display: flex;">
								<div style="flex:1">
									<el-form-item label="" prop="server" style="margin-bottom: 20px">
										<span slot="label">
												<span class="mustClass">*</span> MQTT服务器地址
										<el-tooltip content="MQTT 服务器地址，例如 tcp://127.0.0.1:1883" effect="dark" placement="top" popper-class="el_tooltip_item">
											<el-icon>
												<QuestionFilled/>
											</el-icon>
										</el-tooltip>
										</span>
										<el-input v-model="formData.server" placeholder="请输入MQTT服务器地址"></el-input>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												用户名
												<el-tooltip content="连接用户名" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-input v-model="formData.username" placeholder="请输入用户名"></el-input>

									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												MQTT 协议版本
												<el-tooltip content="MQTT 协议版本。3.1 (也被称为 MQTT 3) 或者 3.1.1 (也被称为 MQTT 4)" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-select v-model="formData.protocolVersion" placeholder="请选择">
											<el-option label="3.1" value="3.1" />
											<el-option label="3.1.1" value="3.1.1" />
										</el-select>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												客户端私钥
												<el-tooltip content="客户端私钥文件" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
										</span>
										<!--privateKeyRaw-->
										<el-upload v-model:file-list="privateKeyList" :auto-upload="false" action="#" class="upload-demo" limit="1" @change="uploadFile($event,'privateKeyRaw')">
											<div class="uploadBox">
												<div :class="privateKeyList.length>0?'none':''" class="upBtnBox">
													<el-icon>
														<Document/>
													</el-icon>
													<span>点击上传</span>
												</div>
											</div>
										</el-upload>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												跳过证书验证
												<el-tooltip content="如果设置为 true，TLS 接受服务器提供的任何证书以及该证书中的任何主机名。在这种模式下，TLS 容易受到中间人攻击。默认值为 false，配置项只能用于 TLS 连接" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.insecureSkipVerify" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												MQTT 客户端标识符
												<el-tooltip content="MQTT 连接的客户端 ID。如果未指定，将使用一个 uuid" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-input v-model="formData.clientId" placeholder="请输入MQTT 客户端标识符"></el-input>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												密码
												<el-tooltip content="连接密码。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-input v-model="formData.password" placeholder="请输入密码"></el-input>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												客户端证书
												<el-tooltip content="客户端证书文件" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<!--certificationRaw-->
										<el-upload v-model:file-list="certificationList" :auto-upload="false" action="#" class="upload-demo" limit="1" @change="uploadFile($event,'certificationRaw')">
											<div class="uploadBox">
												<div :class="certificationList.length>0?'none':''" class="upBtnBox">
													<el-icon>
														<Document/>
													</el-icon>
													<span>点击上传</span>
												</div>
											</div>
										</el-upload>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												CA 证书
												<el-tooltip content="CA 证书文件" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<!--rootCARaw-->
										<el-upload v-model:file-list="rootCAList" :auto-upload="false" action="#" class="upload-demo" limit="1" @change="uploadFile($event,'rootCARaw')">
											<div class="uploadBox">
												<div :class="rootCAList.length>0?'none':''" class="upBtnBox">
													<el-icon>
														<Document/>
													</el-icon>
													<span>点击上传</span>
												</div>
											</div>
										</el-upload>
									</el-form-item>
								</div>
							</div>
						</div>
					</el-collapse-item>
					<el-collapse-item title="Payload" name="2">
						<div>
							<div style="display: flex;">
								<div style="flex:1">
									<el-form-item label="" prop="topic" style="margin-bottom: 20px">
										<span slot="label">
												<span class="mustClass">*</span> MQTT 主题
										<el-tooltip content="MQTT 主题，例如 analysis/result" effect="dark" placement="top" popper-class="el_tooltip_item">
											<el-icon>
												<QuestionFilled/>
											</el-icon>
										</el-tooltip>
										</span>
										<el-input v-model="formData.topic" placeholder="请输入MQTT 主题"></el-input>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												Retained
												<el-tooltip content="转发后的消息是否保留" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.retained" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												QoS
												<el-tooltip content="消息转发的服务质量" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-select v-model="formData.qos" placeholder="请选择">
											<el-option label="0" value="0" />
											<el-option label="1" value="1" />
											<el-option label="2" value="2" />
										</el-select>
									</el-form-item>
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												压缩
												<el-tooltip content="使用指定的压缩方法压缩 payload，留空表示不压缩" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-select v-model="formData.compression" placeholder="请选择">
											<el-option label="zlib" value="zlib" />
											<el-option label="gzip" value="gzip" />
											<el-option label="flate" value="flate" />
											<el-option label="zstd" value="zstd1" />
										</el-select>
									</el-form-item>
								</div>
							</div>
						</div>
					</el-collapse-item>
					<el-collapse-item title="Sink 配置" name="3">
						<div>
							<div style="display: flex">
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												是否忽略输出
												<el-tooltip content="如果选择结果为空，则忽略输出。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.omitIfEmpty" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="" style="margin-bottom: 20px">
										<span slot="label">
												将结果数据按条发送
												<el-tooltip content='输出消息以数组形式接收，该属性意味着是否将结果一一发送。 如果为 false，则输出消息将为 {"result":"${the string of received message}"}。 例如，{"result":"[{"count":30},""count":20}]"}。否则，结果消息将与实际字段名称一一对应发送。 对于与上述相同的示例，它将发送 {"count":30}，然后发送 {"count":20} 到 RESTful 端点。默认为 false。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
										<el-radio-group v-model="formData.sendSingle" placeholder="请选择">
											<el-radio :label="true" border>True</el-radio>
											<el-radio :label="false" border>False</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
								<div style="flex:1">
									<el-form-item label="流格式" style="margin-bottom: 20px">
										<el-select v-model="formData.format" placeholder="请选择">
											<el-option label="json" value="json" />
											<el-option label="binary" value="binary" />
											<el-option label="protobuf" value="protobuf" />
											<el-option label="delimited" value="delimited" />
											<el-option label="custom" value="custom" />
										</el-select>
									</el-form-item>
								</div>
							</div>
							<el-form-item label="" style="margin-bottom: 20px">
								<span slot="label">
												数据模版
												<el-tooltip content='Golang 模板格式字符串，用于指定输出数据格式。 模板的输入是目标消息，该消息始终是 map 数组。 如果未指定数据模板，则将数据作为原始输入。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
								<el-input v-model="formData.dataTemplate" placeholder="请输入数据模版" type="textarea"></el-input>
							</el-form-item>
							<!------------------------------->
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												线程数
												<el-tooltip content='设置运行的线程数。该参数值大于1时，消息发出的顺序可能无法保证。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.concurrency" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												缓存大小
												<el-tooltip content='设置可缓存消息数目。若缓存消息数超过此限制，sink将阻塞消息接收，直到缓存消息被消费使得缓存消息数目小于限制为止。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.bufferLength" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												批量发送大小
												<el-tooltip content='设置批量发送的消息数目。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.batchSize" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												批量发送间隔
												<el-tooltip content='设置批量发送的间隔时间，单位为毫秒。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.lingerInterval" />
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												启用缓存
												<el-tooltip content="控制是否启用缓存。如果它设置为 true，那么缓存将被启用，否则，它将被禁用。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.enableCache" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												重发间隔
												<el-tooltip content='重新发送缓存消息的时间间隔（毫秒）。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.resendInterval" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												内存缓存阈值
												<el-tooltip content='内存中缓存的最大消息数。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.memoryCacheThreshold" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												最大磁盘缓存
												<el-tooltip content='缓存在磁盘中的最大消息数。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.maxDiskCache" />
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												启用备用队列发送缓存数据
												<el-tooltip content="是否在重新发送缓存时使用备用队列。如果设置为true，缓存将被发送到备用队列，而不是原始队列。这将导致实时消息和重发消息使用不同的队列发送，消息的顺序发生变化，但是可以防止消息风暴。只有设置为 true 时，以下 resend 相关配置才能生效。" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.resendAlterQueue" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列发送优先级
												<el-tooltip content='重新发送缓存的优先级，int 类型，默认为 0。-1 表示优先发送实时数据；0 表示同等优先级；1 表示优先发送缓存数据。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input-number v-model="formData.resendPriority" />
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列字段名
												<el-tooltip content='重新发送缓存的字段名。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input v-model="formData.resendIndicatorField" placeholder="请输入备用队列字段名"></el-input>
								</el-form-item>
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												备用队列发送目标
												<el-tooltip content='MQTT sink: 该属性表示重传的主题。若未设置，则仍传到原主题。' effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-input v-model="formData.resendDestination" placeholder="请输入备用队列发送目标"></el-input>
								</el-form-item>
							</div>
							<div class="itemBox">
								<el-form-item label="" style="margin-bottom: 20px">
									<span slot="label">
												停止时清理缓存
												<el-tooltip content="规则停止时是否清理缓存" effect="dark" placement="top" popper-class="el_tooltip_item">
													<el-icon>
														<QuestionFilled/>
													</el-icon>
												</el-tooltip>
											</span>
									<el-radio-group v-model="formData.cleanCacheAtStop" placeholder="请选择">
										<el-radio :label="true" border>True</el-radio>
										<el-radio :label="false" border>False</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</el-form>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, onUnmounted, reactive, ref, watch, defineEmits } from "vue"
	import './mqtt.less'
	import { useRouter } from "vue-router/dist/vue-router";
	import { v4 as uuidv4 } from "uuid"
	import { Back, QuestionFilled, Document, Plus } from '@element-plus/icons-vue'
	let router = useRouter()

	const emit = defineEmits(["returnResults"])
	// 定义传值类型
	const props = defineProps({
		actionObj: String //这里就接收到父组件传递的value
	})
	/*生命周期*/
	onMounted(() => {
		if(JSON.parse(props.actionObj).mqtt && JSON.parse(props.actionObj).mqtt.server) {
			formData.value = JSON.parse(props.actionObj).mqtt
			formData.value.type = "mqtt"
			if(formData.value.certificationRaw) {
				certificationList.value.push({
					name:'MD5: '+uuidv4(),
					url:formData.value.certificationRaw
				})
			}
			if(formData.value.rootCARaw) {
				rootCAList.value.push({
					name:'MD5: '+uuidv4(),
					url:formData.value.rootCARaw
				})
			}
			if(formData.value.privateKeyRaw) {
				privateKeyList.value.push({
					name:'MD5: '+uuidv4(),
					url:formData.value.privateKeyRaw
				})
			}
		}
		// 禁止折叠面板按钮点击跳转
		let ele = document.querySelectorAll('.el-collapse-item button')
		for(let i = 0; i < ele.length; i++) {
			ele[i].addEventListener('click', function(event) {
				event.preventDefault();
			});
		}
	});
	const activeNames2 = ref(['1', '2', '3']) //折叠面板默认值
	const handleChange = (val) => {
		//console.log(val)
	}
	let active = ref(2);
	//表单
	let certificationList = ref([]);
	let privateKeyList = ref([]);
	let rootCAList = ref([]);
	let formData = ref({
		//Connection
		'server': "tcp://127.0.0.1:1883",
		'clientId': "",
		'username': "",
		'password': "",
		'protocolVersion': "3.1.1",
		'certificationRaw': "",
		'privateKeyRaw': "",
		'rootCARaw': "",
		'insecureSkipVerify': false,
		'topic': "",
		'qos': 0,
		'retained': false,
		'compression': "",
		//sink配置
		'omitIfEmpty': false,
		'sendSingle': true,
		'format': "json",
		'dataTemplate': "",
		'concurrency': 1,
		'bufferLength': 1024,
		'batchSize': 0,
		'lingerInterval': 0,
		'enableCache': false,
		'resendInterval': 0,
		'memoryCacheThreshold': 1024,
		'maxDiskCache': 1024000,
		'resendAlterQueue': false,
		'resendPriority': 0,
		'resendIndicatorField': "",
		'resendDestination': "",
		'cleanCacheAtStop': false,
		//----------------
		'type': "mqtt", //类型
		'runAsync': false,
		'bufferPageSize': 256,
	})
	//表单规则
	let formRules = ref({
		server: [{
			required: true,
			message: '请输入MQTT服务器地址',
			trigger: 'change',
		}],
		topic: [{
			required: true,
			message: '请输入MQTT 主题',
			trigger: 'change',
		}]
	})
	let actionData = ref() // 提交表单
	//测试连接的点击
	const testConnectRules = function() {
		actionData.value.validate((valid) => {
			//提交成功
			if(valid) {
				emit("returnResults", formData.value)
			}
		})
	}
	//提交的点击
	const submitFun = function() {
		emit("submitResults", formData.value)
	}
	// 文件上传
	const uploadFile = function(file, key) {
		const reader = new FileReader();
		reader.readAsDataURL(file.raw);
		reader.onload = (e) => {
			formData.value[key] = reader.result.split(",")[1]
		};
	}
	// 暴露方法
	defineExpose({
		testConnectRules,
		submitFun
	});
</script>

<style>

</style>