<template>
	<div class="progress">
		<el-progress type="circle" :percentage="percentageVal" />
	</div>
</template>

<script setup>
	import { onMounted, reactive, ref, watch } from "vue"
	onMounted(() => {
		percentageVal.value = 0
		progress()
	})
	const percentageVal = ref(0)
	const progress = function() {
		// 创建一个定时器，让里面的函数每隔20毫秒自动执行一次
		let timer = setInterval(function() {
			// 使range部分的宽度每次增加2px
			percentageVal.value = percentageVal.value + 1
			// 当宽度达到和外部盒子宽度一致时，清除定时器
			if(percentageVal.value >= 99) {
				clearInterval(timer)
			}
		}, 50)
	}
</script>

<style lang="less">
	.progress {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.el-progress__text {
			color: rgba(32, 160, 255);
		}
		.el-progress-circle {
			width: 80px!important;
			height: 80px!important;
		}
	}
</style>