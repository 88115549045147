<template>
  <div class="warn">
    <div class="title">
      <p>
        <span>告警管理</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <p>设备大类</p>
          <el-input v-model="deviceType" placeholder="请输入设备大类"></el-input>
        </div>
        <div class="liBox">
          <p>设备名称</p>
          <el-input v-model="deviceName" placeholder="请输入设备名称"></el-input>
        </div>
        <div class="liBox">
          <p>设备地址</p>
          <el-input v-model="deviceAddress" placeholder="请输入设备地址"/>
        </div>
        <div class="liBox">
          <p>发生时间</p>
          <el-date-picker v-model="happenTime" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→" start-placeholder="开始时间" type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
        </div>
        <div class="liBox">
          <p>告警等级</p>
          <el-input v-model="warnLevel" placeholder="请输入设备名称"></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
        </div>
        <div class="liBox">
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--告警列表-->
        <el-table v-loading="loading" :data="tableData" stripe style="width: 100%" table-layout="auto">
          <template #empty>
            <el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/noData.png">
            </el-empty>
          </template>
          <el-table-column :filter-method="filterHandler" :filters="[
        { text: '重要', value: '重要' },
        { text: '次要', value: '次要' },
        { text: '提示', value: '提示' },
      ]" label="告警等级" prop="warnLevel">
            <template #default="scope">
              <p v-if="scope.row.warnLevel==='重要'" class="level level1">{{ scope.row.warnLevel }}</p>
              <p v-if="scope.row.warnLevel==='次要'" class="level level2">{{ scope.row.warnLevel }}</p>
              <p v-if="scope.row.warnLevel==='提示'" class="level level3">{{ scope.row.warnLevel }}</p>
            </template>
          </el-table-column>
          <el-table-column label="设备大类" prop="deviceType"></el-table-column>
          <el-table-column label="设备名称" prop="deviceName"></el-table-column>
          <el-table-column label="所属项目" prop="project"></el-table-column>
          <el-table-column label="告警内容" prop="warnContent"></el-table-column>
          <el-table-column label="发生时间" prop="happenTime"></el-table-column>
          <el-table-column fixed="right" label="操作" width="220px">
            <template #default="scope">
              <span class="editClass btn" @click.prevent="closeWarnClick(scope.row.id,scope.row)">关闭警告</span>
              <span class="editClass btn" @click.prevent="workOrderClick(scope.row.id,scope.row)">运维工单</span>
              <span class="editClass btn" @click.prevent="detailClick(scope.row.id,scope.row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,prev, pager, next,sizes" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--运维工单弹窗-->
    <el-drawer v-model="workDrawer" :show-close="false" direction="rtl" size="30%">
      <template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="workDrawer=false">
						<CloseBold/>
					</el-icon>
				</span>
        <span class="tltleClass">创建工单</span>
        <div class="operBox">
          <el-button @click="workDrawer=false">取消</el-button>
          <el-button type="primary" @click="saveWorkOrderFun">确认</el-button>
        </div>
      </template>
      <template #default>
        <div style="padding: 20px;">
          <el-form ref="saveFormDataRef" :model="saveFormData" :rules="saveFormDataRules" class="saveFormData"
                   status-icon>
            <el-form-item label="项目名称" prop="projectName">
              <el-select v-model="saveFormData.projectName" placeholder="请选择项目名称">
                <el-option label="1" value="1"/>
                <el-option label="2" value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item label="设备名称" prop="deviceName">
              <el-select v-model="saveFormData.deviceName" placeholder="请选择设备名称">
                <el-option label="1" value="1"/>
                <el-option label="2" value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item label="故障描述" prop="describe">
              <el-input v-model="saveFormData.describe" type="textarea"/>
            </el-form-item>
            <el-form-item label="负责人" prop="responsiblePeople">
              <el-select v-model="saveFormData.responsiblePeople" placeholder="请选择负责人">
                <el-option label="1" value="1"/>
                <el-option label="2" value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item label="故障图片" prop="faultImage">
              <el-upload v-model:file-list='saveFormData.faultImage'
                         :auto-upload="false" :on-preview="handlePictureCardPreview"
                         accept=".png,.jpg,.jpeg" action="#" list-type="picture-card"
                         @change="uploadFile">
                <el-icon>
                  <Plus/>
                </el-icon>
                <p>上传图片</p>
              </el-upload>
            </el-form-item>
            <p style="margin-bottom: 20px;">支持jpg、jpeg、png格式</p>
          </el-form>
        </div>
      </template>
    </el-drawer>
    <el-dialog v-model="dialogVisible" title="查看大图">
      <img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {reactive, ref} from "vue"
import './warn.less'
import {
  delWarnListApi,
  getWarnListApi,
  saveWarnListApi,
  detailWarnListApi
} from '@/api/modules/warn'
import {CloseBold, ZoomIn, Plus, Delete} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {uploadImg} from "@/api/modules/tissue";
import {delWorkOrderListApi, getWorkOrderListApi} from "@/api/modules/workOrder";

let router = useRouter()

// 告警列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 搜索条件
let deviceType = ref()
let deviceName = ref()
let deviceAddress = ref()
let happenTime = ref()
let warnLevel = ref()
const filterHandler = (value, row, column) => {
  const property = column['property']
  return row[property] === value
}
// 参数表格数据
let tableData = ref([
  {
    warnLevel: '重要',
    deviceType: '123',
    deviceName: '123',
    project: '123',
    warnContent: '123',
    happenTime: '2023-10-22 13:00:00',
  }, {
    warnLevel: '次要',
    deviceType: '123',
    deviceName: '123',
    project: '123',
    warnContent: '123',
    happenTime: '2023-10-22 13:00:00',
  },
])
// 获取告警列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getWorkOrderListApi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询
const searchFun = function () {
  getListFun()
}
// 重置
const resetFun = function () {
  deviceType.value = ''
  deviceName.value = ''
  deviceAddress.value = ''
  happenTime.value = ''
  warnLevel.value = ''
  getListFun()
}


// 图片处理-----------------------------------------------------------------
// 上传图片
let dialogVisible = ref(false) //大图弹窗显示开关
let dialogImageUrl = ref() //大图列表
//新建编辑工单上传文件
const uploadFile = async function (file) {
  let fd = new FormData();
  //注意是文件流file.raw
  fd.append('file', file.raw); //传文件
  await uploadImg(
      fd
  ).then(res => {
    if (res.data.ok) {
      saveFormData.value.faultImage[saveFormData.value.faultImage.length - 1].fullPath = res.data.result.fullPath
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
  console.log(saveFormData.value.faultImage)
}
//查看大图
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}


// 运维工单-----------------------------------------------------------------
let workDrawer = ref(false)  //新建编辑弹窗
// 新建编辑表单数据
let saveFormData = ref({
  id: 0,
  projectName: '',
  deviceName: '',
  describe: '',
  faultImage: [],
  responsiblePeople: '',
})
// 新建编辑表单规则
let saveFormDataRules = ref()
// 点击运维工单
const workOrderClick = function () {
  workDrawer.value = true
  saveFormData.value = {
    id: 0,
    projectName: '',
    deviceName: '',
    describe: '',
    faultImage: [],
    responsiblePeople: '',
  }
}
// 新建编辑工单提交
const saveWorkOrderFun = function () {
  console.log(saveFormData.value)
  // saveWorkOrderListApi()
}


// 查看详情-----------------------------------------------------------------
const detailClick = function () {
  router.push({path: "/admin/warnDetail"})
}

// 关闭警告-----------------------------------------------------------------
const closeWarnClick = function (id, row) {
  ElMessageBox.confirm('请确认是否要关闭此告警', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    delWarnListApi().then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '关闭告警成功'
        });
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消关闭告警'
    });
  });
}


</script>

<style lang="less" scoped>
</style>
