<template>
  <div class="custom">
    <div class="title">
      <p>
        <span>计费项管理</span>
      </p>
    </div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">计费项</label>
          <el-input
            v-model="queryParams.chargeItemName"
            prefix-icon="Search"
            clearable
            placeholder="请输入计费项"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建计费项
        </el-button>
        <el-table v-loading="loading" :data="customList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建计费项</p>
            </el-empty>
          </template>
          <el-table-column
            label="计费项名称"
            align="center"
            prop="chargeItemName"
          />
          <el-table-column label="表格简称" align="center" prop="remark" />
          <el-table-column
            label="是否在表格展示"
            align="center"
            prop="tableIsShow"
          >
            <template #default="{ row }">
              <el-switch
                v-model="row.tableIsShow"
                active-text="是"
                inactive-text="否"
                :active-value="1"
                :inactive-value="0"
                @change="handleTableIsShow(row)"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="meteredElectricityTable">
            <template #header>
              <p>表格展示抄见电量</p>
              <p>（本月止度、上月示度）</p>
            </template>
            <template #default="{ row }">
              <el-switch
                v-model="row.meteredElectricityTable"
                active-text="是"
                inactive-text="否"
                :active-value="1"
                :inactive-value="0"
                @change="handleMeteredElectricityTable(row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="表格展示倍率"
            align="center"
            prop="multiplierTable"
          >
            <template #default="{ row }">
              <el-switch
                v-model="row.multiplierTable"
                active-text="是"
                inactive-text="否"
                :active-value="1"
                :inactive-value="0"
                @change="handleMultiplierTable(row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="表格展示电量"
            align="center"
            prop="electricityUsageTable"
          >
            <template #default="{ row }">
              <el-switch
                v-model="row.electricityUsageTable"
                active-text="是"
                inactive-text="否"
                :active-value="1"
                :inactive-value="0"
                @change="handleElectricityUsageTable(row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="表格展示电价"
            align="center"
            prop="electricityPriceTable"
          >
            <template #default="{ row }">
              <el-switch
                v-model="row.electricityPriceTable"
                active-text="是"
                inactive-text="否"
                :active-value="1"
                :inactive-value="0"
                @change="handleElectricityPriceTable(row)"
              />
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="1000px"
      append-to-body
    >
      <el-form ref="customRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item v-if="isShow" label="项目" prop="projectId">
          <el-select
            v-model="form.projectId"
            placeholder="请选择项目"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in formLists.projectList"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="计费项名称" prop="chargeItemName">
          <el-input
            v-model="form.chargeItemName"
            placeholder="请输入计费项名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="表格简称" prop="remark">
          <el-input
            v-model="form.remark"
            placeholder="请输入表格简称"
            clearable
          />
        </el-form-item>
        <el-card
          style="margin-bottom: 8px"
          header="表格展示区域"
          shadow="never"
        >
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="是否在表格展示" prop="tableIsShow">
                <el-switch
                  v-model="form.tableIsShow"
                  active-text="是"
                  inactive-text="否"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="表格展示抄见电量"
                prop="meteredElectricityTable"
              >
                <el-switch
                  v-model="form.meteredElectricityTable"
                  active-text="是"
                  inactive-text="否"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="表格展示倍率" prop="multiplierTable">
                <el-switch
                  v-model="form.multiplierTable"
                  active-text="是"
                  inactive-text="否"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="表格展示电量" prop="electricityUsageTable">
                <el-switch
                  v-model="form.electricityUsageTable"
                  active-text="是"
                  inactive-text="否"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="表格展示电价" prop="electricityPriceTable">
                <el-switch
                  v-model="form.electricityPriceTable"
                  active-text="是"
                  inactive-text="否"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card style="margin-bottom: 8px" header="计费项" shadow="never">
          <div>
            <el-button
              style="margin-bottom: 12px"
              type="primary"
              v-for="item in formLists.fixedList"
              :key="item.id"
              @click="handleAddChargeItem(item)"
              >{{ item.chargeItemName }}</el-button
            >
          </div>
        </el-card>
        <el-card
          v-if="formLists.customList.length > 0"
          style="margin-bottom: 8px"
          header="已配置计费项"
          shadow="never"
        >
          <div>
            <el-button
              style="margin-bottom: 12px"
              type="primary"
              v-for="item in formLists.customList"
              :key="item.id"
              @click="handleAddChargeItem(item)"
              >{{ item.chargeItemName }}</el-button
            >
          </div>
        </el-card>
        <el-card style="margin-bottom: 8px" header="公式区域" shadow="never">
          <el-form-item prop="formula">
            <div
              ref="editor"
              contenteditable
              style="border: 1px solid #ddd; width: 100%; padding: 8px"
            ></div>
          </el-form-item>
        </el-card>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, nextTick, h } from 'vue'
import './custom.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listCustom,
  getCustom,
  delCustom,
  addCustom,
  updateCustom,
  getCustomList,
} from '@/api/modules/custom'
import { getFixedList } from '@/api/modules/fixed'
import { listLandlordProject } from '@/api/modules/landlord'

const customList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const customRef = ref(null)
const editor = ref(null)
let observer
const isShow = ref(false)

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
  },
  rules: {
    projectId: [{ required: true, message: '项目不能为空', trigger: 'change' }],
    chargeItemName: [
      { required: true, message: '计费项名称不能为空', trigger: 'blur' },
      {
        pattern: /^[^+\-*/()]+$/,
        message: '计费项名称不能包含+-*/()',
        trigger: 'blur',
      },
    ],
    tableIsShow: [
      { required: true, message: '是否在表格展示不能为空', trigger: 'change' },
    ],
    meteredElectricityTable: [
      {
        required: true,
        message: '表格展示抄见电量不能为空',
        trigger: 'change',
      },
    ],
    multiplierTable: [
      { required: true, message: '表格展示倍率不能为空', trigger: 'change' },
    ],
    electricityUsageTable: [
      {
        required: true,
        message: '表格展示电量不能为空',
        trigger: 'change',
      },
    ],
    electricityPriceTable: [
      {
        required: true,
        message: '表格展示电价不能为空',
        trigger: 'change',
      },
    ],
    remark: [{ required: true, message: '表格简称不能为空', trigger: 'blur' }],
    formula: [{ required: true, message: '公式不能为空', trigger: 'blur' }],
  },
})

const { queryParams, form, rules } = toRefs(data)

const formLists = reactive({
  projectList: [],
  fixedList: [],
  customList: [],
})

/** 查询计费项列表 */
function getList() {
  loading.value = true
  listCustom(queryParams.value).then((response) => {
    customList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    projectId: null,
    chargeItemName: null,
    tableIsShow: 0,
    meteredElectricityTable: 0,
    multiplierTable: 0,
    electricityUsageTable: 0,
    electricityPriceTable: 0,
    formula: null,
    remark: null,
  }
  customRef.value?.resetFields()
  observer?.disconnect()
  if (editor.value) {
    editor.value.innerHTML = ''
  }
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    chargeItemName: null,
    mobile: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  listLandlordProject().then((response) => {
    formLists.projectList = response.data.result
    getFixedList().then((response) => {
      formLists.fixedList = response.data.result
      getCustomList().then((response) => {
        if (formLists.projectList.length === 1) {
          form.value.projectId = formLists.projectList[0].projectId
          isShow.value = false
        } else {
          isShow.value = true
        }

        formLists.customList = response.data.result

        open.value = true
        title.value = '新建计费项'

        nextTick(() => {
          observer = new MutationObserver(() => {
            form.value.formula = editor.value.innerText
          })

          observer.observe(editor.value, {
            childList: true,
            subtree: true,
            characterData: true,
          })
        })
      })
    })
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  listLandlordProject().then((res) => {
    formLists.projectList = res.data.result
    getFixedList().then((response) => {
      formLists.fixedList = response.data.result
      getCustomList().then((response) => {
        formLists.customList = response.data.result
        getCustom(row.id).then((response) => {
          let formula = response.data.result.formula
          const parts = formula.split(/([+\-*/()])/)

          parts.forEach((part, index) => {
            ;[...formLists.fixedList, ...formLists.customList].forEach(
              (item) => {
                if (part === item.chargeItemValue) {
                  parts[index] = /* HTML */ `<span
                    contenteditable="false"
                    class="el-tag el-tag--primary el-tag--light"
                    >${item.chargeItemName}</span
                  >`
                }
              }
            )
          })

          formula = parts.join('')

          form.value = response.data.result

          if (
            formLists.projectList.length === 1 &&
            form.value.projectId === formLists.projectList[0].projectId
          ) {
            isShow.value = false
          } else {
            isShow.value = true
          }

          open.value = true
          title.value = '修改计费项'

          nextTick(() => {
            editor.value.innerHTML = formula

            observer = new MutationObserver((mutations) => {
              mutations.forEach(() => {
                form.value.formula = editor.value.innerText
              })
            })

            observer.observe(editor.value, {
              childList: true,
              subtree: true,
              characterData: true,
            })

            form.value.formula = editor.value.innerText
          })
        })
      })
    })
  })
}

/** 提交按钮 */
function submitForm() {
  customRef.value.validate((valid) => {
    if (valid) {
      let formula = editor.value.innerText
      const parts = formula.split(/([+\-*/()])/)

      parts.forEach((part, index) => {
        ;[...formLists.fixedList, ...formLists.customList].forEach((item) => {
          if (part === item.chargeItemName) {
            parts[index] = item.chargeItemValue
          }
        })
      })

      formula = parts.join('')

      if (form.value.id != null) {
        updateCustom({ ...form.value, formula }).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addCustom({ ...form.value, formula }).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除计费项',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此计费项，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delCustom(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}

function handleAddChargeItem(item) {
  const selection = window.getSelection()
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0)

    // Check if the current selection is within the editor and not inside a span
    if (editor.value && editor.value.contains(range.commonAncestorContainer)) {
      let currentNode = range.commonAncestorContainer
      let insideSpan = false

      // Traverse up the DOM tree to check if any parent is a span
      while (currentNode && currentNode !== editor.value) {
        if (currentNode.nodeName === 'SPAN') {
          insideSpan = true
          break
        }
        currentNode = currentNode.parentNode
      }

      // Only insert the span if the cursor is not inside another span
      if (!insideSpan) {
        const button = document.createElement('span')
        button.className = 'el-tag el-tag--primary el-tag--light'
        button.contentEditable = 'false'
        button.innerText = item.chargeItemName

        range.deleteContents()
        range.insertNode(button)

        // Set the cursor after the inserted button
        range.setStartAfter(button)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }
  }
}

function handleTableIsShow(row) {
  getCustom(row.id).then((response) => {
    updateCustom({
      ...response.data.result,
      tableIsShow: row.tableIsShow,
    })
  })
}

function handleMeteredElectricityTable(row) {
  getCustom(row.id).then((response) => {
    updateCustom({
      ...response.data.result,
      meteredElectricityTable: row.meteredElectricityTable,
    })
  })
}

function handleMultiplierTable(row) {
  getCustom(row.id).then((response) => {
    updateCustom({
      ...response.data.result,
      multiplierTable: row.multiplierTable,
    })
  })
}

function handleElectricityUsageTable(row) {
  getCustom(row.id).then((response) => {
    updateCustom({
      ...response.data.result,
      electricityUsageTable: row.electricityUsageTable,
    })
  })
}

function handleElectricityPriceTable(row) {
  getCustom(row.id).then((response) => {
    updateCustom({
      ...response.data.result,
      electricityPriceTable: row.electricityPriceTable,
    })
  })
}
</script>
