<template>
  <div class="onceImgPage">
    <div class="title">
      <p>
        <span>一次图</span>
      </p>
    </div>
    <div class="content">
      <!--左侧内容-->
      <div class="leftBox">
        <div class="searchBox">
          <el-input v-model="searchVal" class="input-with-select" clearable placeholder="请输入">
            <template #append>
              <el-button :icon="Search" @click="searchClick(customerList,searchVal)"/>
            </template>
          </el-input>
        </div>
        <div class="treeBox">
          <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                   :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                   :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
            <template #default="{ node, data }">
							<span class="custom-tree-node" style="width:100%;">
	              <span>{{ node.label }}</span>
							</span>
            </template>
          </el-tree>
        </div>
      </div>
      <!--右侧内容-->
      <div class="rightBox">
        <!--搜索-->
        <!--        <div class="searchBox">-->
        <!--          <div class="liBox">-->
        <!--            <el-select v-model="type" placeholder="请选择">-->
        <!--              <el-option :value="1" label="电"></el-option>-->
        <!--              <el-option :value="2" label="水"></el-option>-->
        <!--              <el-option :value="3" label="气"></el-option>-->
        <!--            </el-select>-->
        <!--          </div>-->
        <!--          <div class="liBox">-->
        <!--            <el-select v-model="timeType" placeholder="请选择">-->
        <!--              <el-option :value="1" label="秒"></el-option>-->
        <!--              <el-option :value="2" label="分"></el-option>-->
        <!--              <el-option :value="3" label="时"></el-option>-->
        <!--              <el-option :value="4" label="天"></el-option>-->
        <!--            </el-select>-->
        <!--          </div>-->
        <!--          <div class="liBox">-->
        <!--            <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"-->
        <!--                            range-separator="→"-->
        <!--                            start-placeholder="开始时间"-->
        <!--                            type="daterange"-->
        <!--                            value-format="YYYY-MM-DD">-->
        <!--            </el-date-picker>-->
        <!--          </div>-->
        <!--        </div>-->
        <div style="height: 100% ;overflow: auto">
          <div class="onceImgBgc">
            <div class="bgcImg" @click="clickFun">
              <img alt="" class="bgc" src="../project/onceImg/img.png">
              <img :class="flag?'active item':'item'" alt="" src="../project/onceImg/img_1.png">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, ref} from "vue";
import './onceImg.less'
import * as echarts from "echarts";
import {useRouter} from "vue-router/dist/vue-router";
import {Search, Plus} from '@element-plus/icons-vue'
import {getDeviceListApi, getProjectListApi} from "@/api/modules/project";
import {ElMessage} from "element-plus";
import qs from "qs";

let router = useRouter()


// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
let searchVal = ref() //树级列表字段
let defaultExpandKeys = ref([])
//树级列表搜索
const searchClick = function (data, name) {
  defaultExpandKeys.value = []
  let res = tree(data, name)
  if (res) {
    treeRef.value.setCurrentKey(res.id)
    defaultExpandKeys.value.push(res.parentId)
    custerObj.value = res

  }
}
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let treeRef = ref(null)
//获取项目树级列表
const getProjectListFun = async function () {
  await getProjectListApi().then(res => {
    if (res.data.ok) {
      customerList.value = []
      customerList.value.push(res.data.result)
      //有无路由参数
      if (router.currentRoute.value.query.id) {
        defaultExpandKeys.value = [router.currentRoute.value.query.id]
      } else {
        if (window.localStorage.getItem('currentNodeKey2')) {
          defaultExpandKeys.value = [window.localStorage.getItem('currentNodeKey2')]
        } else {
          defaultExpandKeys.value = [customerList.value[0].id]
        }
      }
      if (currentNodeKey.value === undefined) { //第一次加默认选中
        //有无路由参数
        if (router.currentRoute.value.query.id) {
          currentNodeKey.value = router.currentRoute.value.query.id
          custerObj.value = JSON.parse(window.localStorage.getItem('project'))
        } else {
          if (window.localStorage.getItem('currentNodeKey2')) {
            currentNodeKey.value = window.localStorage.getItem('currentNodeKey2')
            custerObj.value = JSON.parse(window.localStorage.getItem('custerObj2'))
          } else {
            currentNodeKey.value = customerList.value[0].id
            custerObj.value = customerList.value[0]
          }
        }
        nextTick(() => {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        })
      } else {
        nextTick(() => {
          treeRef.value.setCurrentKey(custerObj.value.id)
        })
      }
      nextTick(() => {
        treeRef.value.setCurrentKey(currentNodeKey.value)
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  window.localStorage.setItem('currentNodeKey2', currentNodeKey.value)
  window.localStorage.setItem('custerObj2', JSON.stringify(e))
  defaultExpandKeys.value.push(e.parentId)
}


// 一次图
let flag = ref(false)
const clickFun = function () {
  flag.value = !flag.value
}


onMounted(() => {
  getProjectListFun()

  nextTick(() => {
    let ele = document.querySelector('.onceImgBgc')
    let scale = (ele.clientWidth) / 1321
    document.querySelector('.bgcImg').style.transform = `scale(${scale})`
  })

})


onMounted(() => {

})

</script>

<style lang="less" scoped>
</style>
