<template>
  <div class="video">
    <el-form label-position="top" ref="userForm" :rules="rules" :inline="true" :model="deployGroup"
             label-width="auto">
      <el-form-item v-if="type.name" label="名称" prop="name">
        <el-input v-model="deployGroup.name" :disabled="store.state.sourceData['prohibit']"></el-input>
      </el-form-item>
      <el-form-item prop="url">
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">路径</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">获取结果的 URL</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input v-model="deployGroup.url"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex;align-items: center;">
            <label for="">时间间隔（ms）</label>
            <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
            >
              <img style="width: 16px;height: 16px;margin-left:5px"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/questionMark_icon.svg" alt="">
              <template #content>
                <div class="max-tooltip">
                  <span
                      style="color: #ffffff;margin-right: 8px">发出消息的间隔，单位为 ms</span>
                </div>
              </template>
            </el-tooltip>
          </div>
        </template>
        <el-input-number v-model="deployGroup.interval"/>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineExpose, defineProps, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import {Delete, Plus} from "@element-plus/icons-vue";

const userForm = ref(null);
const type = defineProps({
  componentType: {
    type: Object,
    default: () => {
      return {}
    }
  },
  data: {
    type: Object,
    default: () => {
      return {}
    }
  }, name: {
    type: Boolean,
    default: () => {
      return true
    }
  }
})
const store = useStore();
let deployGroup = ref({
  name: '',
  interval: 0,
  url: '',
})
const rules = reactive({
  name: [{required: true, message: '请填写名称', trigger: 'blur'}],
  url: [{required: true, trigger: 'blur', message: ''}],
})
const getFormData = async () => {
  const isValid = await userForm.value.validate();
  if (isValid) {
    return deployGroup.value
  }
}
defineExpose({getFormData});
onMounted(() => {
  deployGroup.value = {...deployGroup.value, ...type.data}
})

</script>


<style scoped lang="less">
.max-tooltip {
  max-width: 280px !important;
}

.label {
  position: relative;
  margin-left: 10px;

  &:before {
    position: absolute;
    content: '*';
    color: var(--el-color-danger);
    top: 0;
    left: -10px;
  }
}

.video {
  @import "public";

  .httpHeader {
    width: 500px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #e2e7ea;
  }
}
</style>