<template>
  <div class="programme">
    <div class="title">
      <p>
        <span>方案配置</span>
      </p>
    </div>
    <div class="content">
      <!--数据列表内容-->
      <div class="contain">
        <!--新增方案配置-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增方案配置
        </el-button>
        <!--方案列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增方案</p>
            </el-empty>
          </template>
          <el-table-column label="设备" prop="equipmentList"></el-table-column>
          <el-table-column label="开始时间" prop="startTime"></el-table-column>
          <el-table-column label="结束时间" prop="endTime"></el-table-column>
          <el-table-column label="是否启用" prop="openFlagStr"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="操作" width="160px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="changeStatus(scope.row.openFlag,scope.row.id)">
                  {{ scope.row.openFlag ? '禁用' : '启用' }}</p>
                <p class="btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</p>
                <p :style="{cursor:!scope.row.used?'pointer':'no-drop'}" class="btn delClass"
                   @click.prevent="!scope.row.used?delClick(scope.row.id,scope.row):null">删除</p>
                <!--                <img alt="" src="../../../assets/img/161@2x.png" @click.prevent="editClick(scope.row.id,scope.row)">-->
                <!--                <img :style="{cursor:!scope.row.used?'pointer':'no-drop'}" alt="" src="../../../assets/img/163@2x.png"-->
                <!--                     @click.prevent="!scope.row.used?delClick(scope.row.id,scope.row):null">-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,prev, pager, next,sizes" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--选择方案弹窗-->
    <el-dialog v-model="dialogSelect" :close-on-click-modal="false" class="selectProgramme"
               title="选择方案" width="40%">
      <el-radio-group v-model="formData.type" @change="selectTask">
        <el-radio :label="1" size="large">峰谷套利</el-radio>
        <el-radio :label="2" size="large">需量控制</el-radio>
        <el-radio :label="3" size="large">逆功率保护</el-radio>
        <el-radio :label="4" disabled size="large">光储充协调优化</el-radio>
        <el-radio :label="5" disabled size="large">保护策略</el-radio>
      </el-radio-group>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="dialogSelect=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="firstNext1">下一步</el-button>
      </span>
      </template>
    </el-dialog>
    <!--检查关联参数弹窗-->
    <el-dialog v-model="dialogAssociation1" :close-on-click-modal="false" class="dialogAssociation1"
               title="检查关联参数" width="30%">
      <div class="associationBox">
        <el-form ref="associationFormRef" :model="associationForm" :rules="associationRules" class="associationForm"
                 status-icon
                 style="width: 100%">
          <el-form-item label="总表" prop="total">
            <el-tree-select
                ref="treeRef1"
                v-model="associationForm.total"
                :data="allTree"
                :props="props"
                :render-after-expand="false"
                filterable
                node-key="id"
                style="width: 100%;height: auto!important;"
            />
          </el-form-item>
          <el-form-item label="储能设备" prop="device">
            <el-tree-select
                ref="treeRef2"
                v-model="associationForm.device"
                :data="allTree"
                :props="props"
                :render-after-expand="false"
                filterable
                node-key="id"
                style="width: 100%;height: auto!important;"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="firstBack(2)">上一步</el-button>
        <el-button class="confrim" type="primary" @click="firstNext2">下一步</el-button>
      </span>
      </template>
    </el-dialog>
    <!--电费设置弹窗-->
    <el-dialog v-model="dialogVersion" :close-on-click-modal="false" class="dialogVersion"
               title="电费设置" width="1300px">
      <div class="versionBox">
        <el-select v-model="versionSelect" placeholder="请选择版本名称" @change="versionChange">
          <el-option v-for="(item,index) in versionList" :key="index" :label="item.versionNumber"
                     :value="item.id"></el-option>
        </el-select>
        <div class="listCont">
          <div class="cont">
            <div class="timeBox">
              <p class="tit">分时策略：</p>
              <div>
                <p>尖时段</p>
                <p v-for="(item,index) in strategyForm.flatRate.timeList" :key="index">{{
                    item.startTime
                  }}～{{ item.endTime }}</p>
              </div>
              <div>
                <p>峰时段</p>
                <p v-for="(item,index) in strategyForm.peakRate.timeList" :key="index">{{
                    item.startTime
                  }}～{{ item.endTime }}</p>
              </div>
              <div>
                <p>平时段</p>
                <p v-for="(item,index) in strategyForm.flatRate.timeList" :key="index">{{
                    item.startTime
                  }}～{{ item.endTime }}</p>
              </div>
              <div>
                <p>谷时段</p>
                <p v-for="(item,index) in strategyForm.valleyRate.timeList" :key="index">{{
                    item.startTime
                  }}～{{ item.endTime }}</p>
              </div>
            </div>
            <div class="priceBox1">
              <div class="tit">电网电价
                <el-tooltip
                    class="box-item"
                    content="从电网购电的电价"
                    effect="dark"
                    placement="top"
                >
                  <el-icon>
                    <QuestionFilled/>
                  </el-icon>
                </el-tooltip>
              </div>
              <div>
                <p>尖时段电价</p>
                <p>{{ strategyForm.sharpRate.fee }}元/kwh</p>
              </div>
              <div>
                <p>峰时段电价</p>
                <p>{{ strategyForm.peakRate.fee }}元/kwh</p>
              </div>
              <div>
                <p>平时段电价</p>
                <p>{{ strategyForm.flatRate.fee }}元/kwh</p>
              </div>
              <div>
                <p>谷时段电价</p>
                <p>{{ strategyForm.valleyRate.fee }}元/kwh</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="firstBack(3)">上一步</el-button>
        <el-button class="confrim" type="primary" @click="firstNext4">下一步</el-button>
      </span>
      </template>
    </el-dialog>
    <!--设置生效时间及运行策略间隔弹窗-->
    <el-dialog v-model="dialogSetTime" :close-on-click-modal="false" class="dialogSetTime"
               title="设置生效时间及运行策略间隔" width="30%">
      <div class="associationBox">
        <el-form
            ref="setTimeRef"
            :model="setTimeForm"
            :rules="setTimeRules"
            class="setTimeForm"
            status-icon
        >
          <el-form-item label="运行时间" prop="timeList">
            <el-date-picker v-model="setTimeForm.timeList" end-placeholder="结束时间" popper-class="date-style"
                            range-separator="→" start-placeholder="开始时间" type="datetimerange"
                            value-format="YYYY-MM-DD HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="运行间隔" prop="timer">
            <el-input v-model="setTimeForm.timer"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="setTimeForm.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="firstBack(4)">上一步</el-button>
        <el-button class="confrim" type="primary" @click="firstNext5">生成策略</el-button>
      </span>
      </template>
    </el-dialog>

    <!--检查关联参数弹窗2-->
    <el-dialog v-model="dialogAssociation2" :close-on-click-modal="false" class="dialogAssociation1"
               title="检查关联参数" width="30%">
      <div class="associationBox">
        <el-form ref="associationFormRef2" :model="associationForm2" :rules="associationRules2" class="associationForm"
                 status-icon
                 style="width: 100%">
          <el-form-item label="总表" prop="total">
            <el-tree-select
                ref="treeRef3"
                v-model="associationForm2.total"
                :data="allTree"
                :props="props"
                :render-after-expand="false"
                filterable
                node-key="id"
                style="width: 100%;height: auto!important;"
            />
          </el-form-item>
          <el-form-item label="申请容量" prop="capacity">
            <el-input v-model="associationForm2.capacity" placeholder="请输入申请容量"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="secondBack(2)">上一步</el-button>
        <el-button class="confrim" type="primary" @click="secondNext2">下一步</el-button>
      </span>
      </template>
    </el-dialog>


    <!--检查关联参数弹窗3-->
    <el-dialog v-model="dialogAssociation3" :close-on-click-modal="false" class="dialogAssociation1"
               title="检查关联参数" width="30%">
      <div class="associationBox">
        <el-form ref="associationFormRef3" :model="associationForm3" :rules="associationRules3" class="associationForm"
                 status-icon
                 style="width: 100%">
          <el-form-item label="光伏设备" prop="total">
            <el-tree-select
                ref="treeRef4"
                v-model="associationForm3.total"
                :data="allTree"
                :props="props"
                :render-after-expand="false"
                filterable
                node-key="id"
                style="width: 100%;height: auto!important;"
            />
          </el-form-item>
          <el-form-item label="申请逆网最大容量" prop="capacity">
            <el-input v-model="associationForm3.capacity" placeholder="请输入申请逆网最大容量"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="thirdBack(2)">上一步</el-button>
        <el-button class="confrim" type="primary" @click="thirdNext2">下一步</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './programme.less'
import {CirclePlus, CircleClose, Plus, Search, Delete, QuestionFilled} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {
  getConfigListApi,
  saveConfigListApi,
  delConfigListApi,
  getEquipmentListApi,
  getParamTypeListApi, getTreeDataApi, saveEquipmentListApi, delEquipmentListApi, getTitleListApi
} from '@/api/modules/configuration'
import qs from "qs";
import {getTreeListApi} from "@/api/modules/control";
import {getListDetailApi} from '@/api/modules/electrovalence'
import {
  getProgrammeListApi,
  getProgrammeDetailApi,
  saveProgrammeApi,
  delProgrammeApi,
  changeProgrammeApi,
  getVersionSelectApi,
  getAllTreeApi
} from '@/api/modules/strategy'
import {loginApi2} from "@/api/modules/login";

let router = useRouter()


// 方案配置列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 方案表格数据
let tableData = ref([])
// 获取方案列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getProgrammeListApi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 点击启用或禁用
const changeStatus = function (num, id) {
  ElMessageBox.confirm(`请确认是否${num === 0 ? '启用' : '禁用'}此方案`, 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    changeProgrammeApi(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: `${num === 0 ? '启用' : '禁用'}成功`,
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: `取消${num === 0 ? '启用' : '禁用'}`
    })
  })
}


// 方案类型
let effectType = ref()
// 设备名称
let deviceName = ref()
// 方案名称
let paramName = ref()
// 查询
const searchFun = function () {
  pageNumber.value = 1
  getListFun()
}

let formData = ref({
  id: 0,
  type: null,
  startTime: '',
  endTime: '',
  runTime: '',
  totalId: '',
  storeEquipmentIds: '',
  capacity: '',
  powerGridRateId: '',
  remark: ''
})
// 选择方案-----------------------------------------------------------------
// 新增编辑表单弹窗
let dialogSelect = ref(false)
// 新增方案点击
const addClick = function () {
  dialogSelect.value = true
  formData.value = {
    id: 0,
    type: null,
    startTime: '',
    endTime: '',
    runTime: '',
    totalId: '',
    storeEquipmentIds: '',
    capacity: '',
    powerGridRateId: '',
    remark: ''
  }
  associationForm.value = {
    total: '',
    device: []
  }
  associationForm2.value = {
    total: '',
    capacity: ''
  }
  associationForm3.value = {
    total: '',
    capacity: ''
  }
  versionSelect.value = null
  setTimeForm.value = {
    timeList: [],
    timer: '',
    remark: ''
  }
}
// 编辑点击
const editClick = function (id, row) {
  dialogSelect.value = true
  getProgrammeDetailApi(qs.stringify({
    id: id
  })).then(res => {
    if (res.data.code === 0) {
      formData.value = res.data.result
      associationForm.value = {
        total: res.data.result.totalId,
        device: res.data.result.storeEquipmentList[0] ? res.data.result.storeEquipmentList[0].id : ''
      }
      associationForm2.value = {
        total: res.data.result.totalId,
        capacity: res.data.result.capacity
      }
      associationForm3.value = {
        total: res.data.result.totalId,
        capacity: res.data.result.capacity
      }
      versionSelect.value = res.data.result.powerGridRateId
      versionChange(versionSelect.value)
      setTimeForm.value = {
        timeList: [res.data.result.startTime, res.data.result.endTime],
        timer: res.data.result.runTime,
        remark: res.data.result.remark
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 选择方案事件
const selectTask = function () {
  if (formData.value.id == 0) {
    associationForm.value = {
      total: '',
      device: []
    }
    associationForm2.value = {
      total: '',
      capacity: ''
    }
    associationForm3.value = {
      total: '',
      capacity: ''
    }
    versionSelect.value = null
    setTimeForm.value = {
      timeList: [],
      timer: '',
      remark: ''
    }
  }
}
// 下一步
const firstNext1 = function () {
  if (formData.value.type) {
    dialogSelect.value = false
    if (formData.value.type === 1) {
      dialogAssociation1.value = true
    } else if (formData.value.type === 2) {
      dialogAssociation2.value = true
    } else if (formData.value.type === 3) {
      dialogAssociation3.value = true
    }
  } else {
    ElMessage({
      message: '请选择方案类型',
      type: 'info'
    })
  }
}

// 峰谷套利方案********************************************************************************************
// 检查关联参数-----------------------------------------------------------------
let dialogAssociation1 = ref(false)
// 参数表单数据
let associationForm = ref({
  total: '',
  device: []
})
// 表单规则
let associationRules = ref({
  total: [
    {required: true, message: '请选择总表', trigger: 'blur'},
  ],
  device: [
    {required: true, message: '请选择储能设备', trigger: 'blur'},
  ]
})

// 上一步
const firstBack = function (num) {
  if (num === 2) {
    dialogAssociation1.value = false
    dialogSelect.value = true
  } else if (num === 3) {
    dialogVersion.value = false
    dialogAssociation1.value = true
  } else if (num === 4) {
    if (formData.value.type === 1) {
      dialogSetTime.value = false
      dialogVersion.value = true
    } else if (formData.value.type === 2) {
      dialogSetTime.value = false
      dialogAssociation2.value = true
    } else if (formData.value.type === 3) {
      dialogSetTime.value = false
      dialogAssociation3.value = true
    }
  }
}
// 下一步
let versionList = ref()
let associationFormRef = ref()
let treeRef1 = ref()
let treeRef2 = ref()
const firstNext2 = function () {
  // 提交表单
  if (associationFormRef) {
    associationFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        associationForm.value.total = treeRef1.value.getCurrentNode() ? treeRef1.value.getCurrentNode().id : associationForm.value.total
        associationForm.value.device = treeRef2.value.getCurrentNode() ? treeRef2.value.getCurrentNode().id : associationForm.value.device
        dialogAssociation1.value = false
        dialogVersion.value = true
      }
    })
  }
}

// 电费设置-----------------------------------------------------------------
let dialogVersion = ref(false)
// 版本选择
let versionSelect = ref()
// 选择后的数据
let strategyForm = ref({
  startTime: "",
  endTime: "",
  flatRate: {
    fee: "",
    timeList: [
      {
        endTime: "",
        startTime: "",
        type: 1
      }
    ]
  },
  peakRate: {
    fee: "",
    timeList: [
      {
        endTime: "",
        startTime: "",
        type: 1
      }
    ]
  },
  sharpRate: {
    fee: "",
    timeList: [
      {
        endTime: "",
        startTime: "",
        type: 2
      }
    ]
  },
  valleyRate: {
    fee: "",
    timeList: [
      {
        endTime: "",
        startTime: "",
        type: 1
      }
    ]
  },
  versionNumber: ""
})

const versionChange = function (e) {
  for (let i = 0; i < versionList.value.length; i++) {
    if (versionList.value[i].id === e) {
      strategyForm.value = versionList.value[i]
    }
  }
}
// 下一步
const firstNext4 = function () {
  if (versionSelect.value) {
    dialogVersion.value = false
    dialogSetTime.value = true
    if (formData.value.type === 1) {
      setTimeForm.value.timeList = [strategyForm.value.startTime, strategyForm.value.endTime]
    }
  } else {
    ElMessage({
      type: 'info',
      message: '请选择版本',
    })
  }

}


// 设置生效时间及运行策略间隔-----------------------------------------------------------------
let dialogSetTime = ref(false)
// 表单数据
let setTimeForm = ref({
  timeList: [],
  timer: '',
  remark: ''
})
// 表单验证规则
let setTimeRules = ref({
  timeList: [
    {required: true, message: '请选择运行时间', trigger: 'blur'},
  ],
  timer: [
    {required: true, message: '请输入运行间隔', trigger: 'blur'},
  ]
})
// 生成策略
let setTimeRef = ref()
const firstNext5 = function () {
  // 提交表单
  if (setTimeRef) {
    setTimeRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        dialogSetTime.value = false
        let data = {
          id: formData.value.id,
          type: formData.value.type,
          startTime: setTimeForm.value.timeList[0],
          endTime: setTimeForm.value.timeList[1],
          runTime: setTimeForm.value.timer,
          remark: setTimeForm.value.remark,
          powerGridRateId: versionSelect.value
        }
        if (formData.value.type === 1) {
          data.totalId = associationForm.value.total
          data.storeEquipmentIds = associationForm.value.device
        } else if (formData.value.type === 2) {
          data.totalId = associationForm2.value.total
          data.capacity = associationForm2.value.capacity
        } else if (formData.value.type === 3) {
          data.totalId = associationForm3.value.total
          data.capacity = associationForm3.value.capacity
        }
        saveProgrammeApi(qs.stringify(data)).then(res => {
          if (res.data.code === 0) {
            getListFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 需量控制方案********************************************************************************************
// 检查关联参数-----------------------------------------------------------------
let dialogAssociation2 = ref(false)
// 参数表单数据
let associationForm2 = ref({
  total: '',
  capacity: ''
})
// 表单规则
let associationRules2 = ref({
  total: [
    {required: true, message: '请选择总表', trigger: 'blur'},
  ],
  capacity: [
    {required: true, message: '请输入申请容量', trigger: 'blur'},
  ]
})
// 上一步
const secondBack = function (num) {
  if (num === 2) {
    dialogAssociation2.value = false
    dialogSelect.value = true
  }
}
// 下一步
let associationFormRef2 = ref()
let treeRef3 = ref()
const secondNext2 = function () {
  // 提交表单
  if (associationFormRef2) {
    associationFormRef2.value.validate((valid) => {
      //提交成功
      if (valid) {
        dialogAssociation2.value = false
        dialogSetTime.value = true
        associationForm2.value.total = treeRef3.value.getCurrentNode().id
      }
    })
  }
}


// 逆功率保护方案********************************************************************************************
// 检查关联参数-----------------------------------------------------------------
let dialogAssociation3 = ref(false)
// 参数表单数据
let associationForm3 = ref({
  total: '',
  capacity: ''
})
// 表单规则
let associationRules3 = ref({
  total: [
    {required: true, message: '请选择光伏设备', trigger: 'blur'},
  ],
  capacity: [
    {required: true, message: '请输入申请逆网最大容量', trigger: 'blur'},
  ]
})
// 上一步
const thirdBack = function (num) {
  if (num === 2) {
    dialogAssociation3.value = false
    dialogSelect.value = true
  }
}
// 下一步
let associationFormRef3 = ref()
let treeRef4 = ref()
const thirdNext2 = function () {
  // 提交表单
  if (associationFormRef3) {
    associationFormRef3.value.validate((valid) => {
      //提交成功
      if (valid) {
        dialogAssociation3.value = false
        dialogSetTime.value = true
        associationForm3.value.total = treeRef4.value.getCurrentNode().id
      }
    })
  }
}


// 删除方案
const delClick = function (id, row) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除方案',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此方案，删除后数据不可恢复'),
    ])
  }).then(() => {
    delProgrammeApi(qs.stringify({id: id})).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}

// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}


// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 树形结构数据
let allTree = ref()
onMounted(() => {
  // 获取方案列表
  getListFun()
  // 获取费率
  getVersionSelectApi().then(res => {
    if (res.data.code === 0) {
      versionList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
  // 获取所有设备树
  getAllTreeApi().then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      allTree.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
</script>

<style lang="less" scoped>
</style>
