<template>
  <div class="hewwScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="head">
          <img alt="" src="../../../assets/img/head3.png">
        </div>
        <div class="timeBox">
          <p class="time">{{ timeVal }}</p>
          <p class="date">{{ dateVal }}</p>
          <p class="week">{{ weekVal }}</p>
        </div>
        <div class="weatherBox">
          <span>{{ currentWeatherObj.city }}</span>
          <span><span>{{
              allWeatherData[0] ? allWeatherData[0].nighttemp : ''
            }}~{{ allWeatherData[0] ? allWeatherData[0].daytemp : '' }}C°</span></span>
          <span>{{ currentWeatherObj.weather }}</span>
        </div>
        <div class="leftBox">
          <div class="leftBoxItem">
            <div class="titleBox">
              <p class="name">储能</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">今日</p>
                <count-up ref="countUp1" :end-val="data.storeEnergyToday" :start-val="countStart1"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">昨日</p>
                <count-up ref="countUp2" :end-val="data.storeEnergyYesterday" :start-val="countStart2"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当月累计</p>
                <count-up ref="countUp3" :end-val="data.storeEnergyTotal" :start-val="countStart3"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">储能状态</p>
                <div class="val val2">
                  <count-up ref="countUp4" :end-val="data.rechargeRate" :start-val="countStart4" class="val"></count-up>
                  %
                  <div class="iconBox">
                    <!--<img alt="" src="../../../assets/img/cd.svg" >-->
                    <p v-if="data.status==0">停机</p>
                    <p v-if="data.status==1">待机</p>
                    <p v-if="data.status==2">放电</p>
                    <p v-if="data.status==3">充电</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">近七日放电趋势</p>
              <div class="chart1_3 chart"></div>
            </div>
          </div>
          <div class="leftBoxItem">
            <div class="titleBox">
              <p class="name">光伏发电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">今日</p>
                <count-up ref="countUp5" :end-val="data.photovoltaicGenerateElectricityToday" :start-val="countStart5"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">昨日</p>
                <count-up ref="countUp6" :end-val="data.photovoltaicGenerateElectricityYesterday"
                          :start-val="countStart6" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当月累计</p>
                <count-up ref="countUp7" :end-val="data.photovoltaicGenerateElectricityMonth" :start-val="countStart7"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">历史累计</p>
                <count-up ref="countUp8" :end-val="data.photovoltaicGenerateElectricityTotal" :start-val="countStart8"
                          class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">近七日发电趋势</p>
              <div class="chart2_3 chart"></div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightBoxItem">
            <div class="titleBox">
              <p class="name">总表</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">今日</p>
                <count-up ref="countUp9" :end-val="data.totalUseToday" :start-val="countStart9" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">昨日</p>
                <count-up ref="countUp10" :end-val="data.totalUseYesterday" :start-val="countStart10"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当月累计</p>
                <count-up ref="countUp11" :end-val="data.totalUseMonth" :start-val="countStart11"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">历史累计</p>
                <count-up ref="countUp12" :end-val="data.totalUseTotal" :start-val="countStart12"
                          class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">用电量趋势</p>
              <div class="chart6_3 chart"></div>
            </div>
          </div>
          <div class="rightBoxItem">
            <div class="titleBox">
              <p class="name">光伏上网电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">今日</p>
                <count-up ref="countUp13" :end-val="data.photovoltaicUpToday" :start-val="countStart13"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">昨日</p>
                <count-up ref="countUp14" :end-val="data.photovoltaicUpYesterday" :start-val="countStart14"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当月累计</p>
                <count-up ref="countUp15" :end-val="data.photovoltaicUpMonth" :start-val="countStart15"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">历史累计</p>
                <count-up ref="countUp16" :end-val="data.photovoltaicUpTotal" :start-val="countStart16"
                          class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">近7日上网电量趋势</p>
              <div class="chart3_3 chart"></div>
            </div>
          </div>
        </div>
        <div class="topBox">
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp17" :end-val="data.totalRate" :start-val="countStart17" class="val"
                          style="display: inline-block"></count-up>
                <span> kw</span>
              </p>
              <p class="name">总表功率</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/gfgl.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp18" :end-val="data.photovoltaicRate" :start-val="countStart18" class="val"
                          style="display: inline-block"></count-up>
                <span> kw</span>
              </p>
              <p class="name">光伏功率</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon2.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp19" :end-val="data.photovoltaicThatDayIncome" :start-val="countStart19"
                          class="val" style="display: inline-block"></count-up>
                <span> 元</span>
              </p>
              <p class="name">光伏当天收益</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/fzgl.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp20" :end-val="data.loadRate" :start-val="countStart20" class="val"
                          style="display: inline-block"></count-up>
                <span> kw</span>
              </p>
              <p class="name">负载功率</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/cngl.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp21" :end-val="data.storeEnergyRate" :start-val="countStart21" class="val"
                          style="display: inline-block"></count-up>
                <span> kw</span>
              </p>
              <p class="name">储能功率</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/cndtsy.svg">
            <div class="valCont">
              <p class="val">
                <count-up ref="countUp22" :end-val="data.storeEnergyThatDayIncome" :start-val="countStart22" class="val"
                          style="display: inline-block"></count-up>
                <span> 元</span>
              </p>
              <p class="name">储能当天收益</p>
            </div>
          </div>
        </div>
        <div class="bottomBox">
          <div class=" strategyBox">
            <p>微网策略调整</p>
            <div class="titleBox">
              <div class="trBox">
                <div class="thBox">时间</div>
                <div class="thBox">设备名称</div>
                <div class="thBox">控制名称</div>
                <div class="thBox">控制参数</div>
              </div>
            </div>
            <div class="contentBox">
              <div v-for="item in data.logList" :class="{'animate-up':animateUp}" class="trBox">
                <div class="tdBox">{{ item.executeTime }}</div>
                <div :title="item.deviceName" class="tdBox">{{ item.deviceName }}</div>
                <div :title="item.controlName" class="tdBox">{{ item.controlName }}</div>
                <div class="tdBox">
                  <div v-for='(item,index) in item.controlParamList' :key="index">
                    <p :title="item">{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomBoxItem bottomBoxItem2">
            <div class="titleBox">
              <p class="name">近一小时功率曲线</p>
              <p class="unit">kwh</p>
            </div>
            <div class="chart chart5_3"></div>
          </div>
        </div>
        <div class="mainCircuit">
          <change-circuit :data="circuitValue" :type="transitionName"/>
          <transition appear mode="out-in" name="el-fade-in-linear">
            <circuit :key="arrow.key" :arrow='arrow' :basicData='basicData' :data="asyncData"/>
          </transition>
        </div>
      </div>
    </v-scale-screen>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import './hewwScreen.less'
import VScaleScreen from 'v-scale-screen'
import * as echarts from "echarts";
import {hnwwScreenApi, getWeatherApi, getDeviceListApi} from '../../../api/modules/bigScreen'
import {ElMessage} from "element-plus";
import Circuit from "@/components/circuit.vue";
import CountUp from 'vue-countup-v3'
import ChangeCircuit from "@/components/changeCircuit.vue";
import useCircuit from "@/hooks/useOldCircuit";
/*天气************************************************/
let currentWeatherObj = ref({}) //实时天气
//实时天气
const currentWeatherFun = function () {
  getWeatherApi('base').then(res => {
    if (res.data.status === '1') {
      currentWeatherObj.value = res.data.lives[0]
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let allWeatherData = ref([]) //所有天气
//所有天气
const allWeatherFun = function () {
  getWeatherApi('all').then(res => {
    if (res.data.status === '1') {
      allWeatherData.value = res.data.forecasts[0].casts
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.hngfScreen'); // 获取需要全屏的元素;
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};
const check = function () {
  enterFullScreen()
}
// 当前时间
let timeVal = ref()
let dateVal = ref()
let weekVal = ref()
const updateTime = function () {
  const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
  const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
  const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
  const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
  const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
  const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
  timeVal.value = hours + ':' + minutes + ':' + seconds
  dateVal.value = year + '-' + month + '-' + day
  weekVal.value = new Intl.DateTimeFormat('zh-CN', {
    weekday: 'long'
  }).format(new Date());
}

// 加载左边第一个
const chartLoad1 = function () {
  var chartDom = document.querySelector('.chart1_3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.storeEnergyListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.storeEnergyList,
      type: 'line',
      showBackground: true,
      backgroundStyle: {
        color: '#1F2D3E',
        borderRadius: [10, 10, 10, 10]
      },
      barWidth: 16,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: {
        color: '#FB4D7D',
        borderWidth: 2,
        borderColor: '#040B1D',
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载左边第二个
const chartLoad2 = function () {
  var chartDom = document.querySelector('.chart2_3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.photovoltaicGenerateElectricityListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.photovoltaicGenerateElectricityList,
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: '#1F2D3E',
        borderRadius: [10, 10, 10, 10]
      },
      barWidth: 16,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: '#FF639A'
        },
          {
            offset: 1,
            color: '#F5335C'
          },
        ]),
        borderRadius: [10, 10, 10, 10]
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第一个
const chartLoad6 = function () {
  var chartDom = document.querySelector('.chart6_3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.totalUseListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.totalUseList,
      type: 'line',
      showBackground: true,
      backgroundStyle: {
        color: '#1F2D3E',
        borderRadius: [10, 10, 10, 10]
      },
      barWidth: 16,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: {
        color: '#43F2AE',
        borderWidth: 2,
        borderColor: '#040B1D',
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第二个
const chartLoad3 = function () {
  var chartDom = document.querySelector('.chart3_3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.photovoltaicUpListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.photovoltaicUpList,
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: '#1F2D3E',
        borderRadius: [10, 10, 10, 10]
      },
      barWidth: 16,
      itemStyle: {
        color: '#43F2AE',
        borderRadius: [10, 10, 10, 10]
      },
    }]
  };
  myChart.clear()
  option && myChart.setOption(option);
  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

// 加载下边第一个
const chartLoad5 = function () {
  var chartDom = document.querySelector('.chart5_3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    legend: {
      show: true,
      icon: 'roundRect',
      textStyle: {
        color: '#048CB5'
      }
    },
    grid: {
      left: 50,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.rateTimeList
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: data.value.rateValueListX
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

let data = ref({})

let animateUp = ref(false)
const scrollAnimate = function () {
  animateUp.value = true;
  setTimeout(() => {
    if (data.value.logList && data.value.logList.length > 0) {
      data.value.logList.push(data.value.logList[0]);
      data.value.logList.shift();
      animateUp.value = false;
    }
  }, 500);
}
const lunbo = function () {
  setInterval(scrollAnimate, 4000);
}

// 数字轮播数据
let countUp1 = ref()
let countUp2 = ref()
let countUp3 = ref()
let countUp4 = ref()
let countUp5 = ref()
let countUp6 = ref()
let countUp7 = ref()
let countUp8 = ref()
let countUp9 = ref()
let countUp10 = ref()
let countUp11 = ref()
let countUp12 = ref()
let countUp13 = ref()
let countUp14 = ref()
let countUp15 = ref()
let countUp16 = ref()
let countUp17 = ref()
let countUp18 = ref()
let countUp19 = ref()
let countUp20 = ref()
let countUp21 = ref()
let countUp22 = ref()
// 数字轮播初始值
let countStart1 = ref(0)
let countStart2 = ref(0)
let countStart3 = ref(0)
let countStart4 = ref(0)
let countStart5 = ref(0)
let countStart6 = ref(0)
let countStart7 = ref(0)
let countStart8 = ref(0)
let countStart9 = ref(0)
let countStart10 = ref(0)
let countStart11 = ref(0)
let countStart12 = ref(0)
let countStart13 = ref(0)
let countStart14 = ref(0)
let countStart15 = ref(0)
let countStart16 = ref(0)
let countStart17 = ref(0)
let countStart18 = ref(0)
let countStart19 = ref(0)
let countStart20 = ref(0)
let countStart21 = ref(0)
let countStart22 = ref(0)

// 获取大屏数据
const getDataFun = async function () {
  await hnwwScreenApi().then(res => {
    if (res.data.code === 0) {
      if (data.value.storeEnergyToday != res.data.result.storeEnergyToday) {
        countStart1.value = data.value.storeEnergyToday
      }
      if (data.value.storeEnergyYesterday != res.data.result.storeEnergyYesterday) {
        countStart2.value = data.value.storeEnergyYesterday
      }
      if (data.value.storeEnergyTotal != res.data.result.storeEnergyTotal) {
        countStart3.value = data.value.storeEnergyTotal
      }
      if (data.value.rechargeRate != res.data.result.rechargeRate) {
        countStart4.value = data.value.rechargeRate
      }
      if (data.value.photovoltaicGenerateElectricityToday != res.data.result.photovoltaicGenerateElectricityToday) {
        countStart5.value = data.value.photovoltaicGenerateElectricityToday
      }
      if (data.value.photovoltaicGenerateElectricityYesterday != res.data.result.photovoltaicGenerateElectricityYesterday) {
        countStart6.value = data.value.photovoltaicGenerateElectricityYesterday
      }
      if (data.value.photovoltaicGenerateElectricityMonth != res.data.result.photovoltaicGenerateElectricityMonth) {
        countStart7.value = data.value.photovoltaicGenerateElectricityMonth
      }
      if (data.value.photovoltaicGenerateElectricityTotal != res.data.result.photovoltaicGenerateElectricityTotal) {
        countStart8.value = data.value.photovoltaicGenerateElectricityTotal
      }
      if (data.value.totalUseToday != res.data.result.totalUseToday) {
        countStart9.value = data.value.totalUseToday
      }
      if (data.value.totalUseYesterday != res.data.result.totalUseYesterday) {
        countStart10.value = data.value.totalUseYesterday
      }
      if (data.value.totalUseMonth != res.data.result.totalUseMonth) {
        countStart11.value = data.value.totalUseMonth
      }
      if (data.value.totalUseTotal != res.data.result.totalUseTotal) {
        countStart12.value = data.value.totalUseTotal
      }
      if (data.value.photovoltaicUpToday != res.data.result.photovoltaicUpToday) {
        countStart13.value = data.value.photovoltaicUpToday
      }
      if (data.value.photovoltaicUpYesterday != res.data.result.photovoltaicUpYesterday) {
        countStart14.value = data.value.photovoltaicUpYesterday
      }
      if (data.value.photovoltaicUpMonth != res.data.result.photovoltaicUpMonth) {
        countStart15.value = data.value.photovoltaicUpMonth
      }
      if (data.value.photovoltaicUpTotal != res.data.result.photovoltaicUpTotal) {
        countStart16.value = data.value.photovoltaicUpTotal
      }
      if (data.value.totalRate != res.data.result.totalRate) {
        countStart17.value = data.value.totalRate
      }
      if (data.value.photovoltaicRate != res.data.result.photovoltaicRate) {
        countStart18.value = data.value.photovoltaicRate
      }
      if (data.value.photovoltaicThatDayIncome != res.data.result.photovoltaicThatDayIncome) {
        countStart19.value = data.value.photovoltaicThatDayIncome
      }
      if (data.value.loadRate != res.data.result.loadRate) {
        countStart20.value = data.value.loadRate
      }
      if (data.value.storeEnergyRate != res.data.result.storeEnergyRate) {
        countStart21.value = data.value.storeEnergyToday
      }
      if (data.value.storeEnergyThatDayIncome != res.data.result.storeEnergyThatDayIncome) {
        countStart22.value = data.value.storeEnergyThatDayIncome
      }
      if (data.value.storeEnergyList && data.value.storeEnergyList.length > 0) {
        if (JSON.stringify(data.value.storeEnergyList) != JSON.stringify(res.data.result.storeEnergyList)) {
          chartLoad1()
        }
      }
      if (data.value.photovoltaicGenerateElectricityList && data.value.photovoltaicGenerateElectricityList.length > 0) {
        if (JSON.stringify(data.value.photovoltaicGenerateElectricityList) != JSON.stringify(res.data.result.photovoltaicGenerateElectricityList)) {
          chartLoad2()
        }
      }
      if (data.value.totalUseList && data.value.totalUseList.length > 0) {
        if (JSON.stringify(data.value.totalUseList) != JSON.stringify(res.data.result.totalUseList)) {
          chartLoad6()
        }
      }
      if (data.value.photovoltaicUpList && data.value.photovoltaicUpList.length > 0) {
        if (JSON.stringify(data.value.photovoltaicUpList) != JSON.stringify(res.data.result.photovoltaicUpList)) {
          chartLoad3()
        }
      }
      if (data.value.rateValueList && data.value.rateValueList.length > 0) {
        if (JSON.stringify(data.value.rateValueList) != JSON.stringify(res.data.result.rateValueList)) {
          chartLoad5()
        }
      }

      data.value = res.data.result
      //储能
      data.value.storeEnergyListX = []
      data.value.storeEnergyListY = []
      for (let i = 0; i < data.value.storeEnergyList.length; i++) {
        data.value.storeEnergyListX.push(data.value.photovoltaicGenerateElectricityList[i].key)
        data.value.storeEnergyListY.push(Number(data.value.storeEnergyList[i].value))
      }
      // 光伏发电量
      data.value.photovoltaicGenerateElectricityListX = []
      data.value.photovoltaicGenerateElectricityListY = []
      for (let i = 0; i < data.value.photovoltaicGenerateElectricityList.length; i++) {
        data.value.photovoltaicGenerateElectricityListX.push(data.value.photovoltaicGenerateElectricityList[i].key)
        data.value.photovoltaicGenerateElectricityListY.push(Number(data.value.photovoltaicGenerateElectricityList[i].value))
      }
      //总表
      data.value.totalUseListX = []
      data.value.totalUseListY = []
      for (let i = 0; i < data.value.totalUseList.length; i++) {
        data.value.totalUseListX.push(data.value.totalUseList[i].key)
        data.value.totalUseListY.push(Number(data.value.totalUseList[i].value))
      }
      // 光伏上网电量
      data.value.photovoltaicUpListX = []
      data.value.photovoltaicUpListY = []
      for (let i = 0; i < data.value.photovoltaicUpList.length; i++) {
        data.value.photovoltaicUpListX.push(data.value.photovoltaicUpList[i].key)
        data.value.photovoltaicUpListY.push(Number(data.value.photovoltaicUpList[i].value))
      }
      //近一小时功率曲线
      data.value.rateValueListX = []
      data.value.rateValueList = JSON.parse(JSON.stringify(res.data.result.rateValueList))
      data.value.rateValueListX = JSON.parse(JSON.stringify(res.data.result.rateValueList))
      for (let i = 0; i < data.value.rateValueListX.length; i++) {
        data.value.rateValueListX[i].name = data.value.rateValueListX[i].key
        data.value.rateValueListX[i].data = data.value.rateValueListX[i].value
        data.value.rateValueListX[i].type = 'line'
        data.value.rateValueListX[i].showBackground = true
        data.value.rateValueListX[i].backgroundStyle = {
          borderRadius: [10, 10, 10, 10]
        }
        data.value.rateValueListX[i].barWidth = 16
        data.value.rateValueListX[i].symbol = 'circle'
        data.value.rateValueListX[i].symbolSize = 1
        data.value.rateValueListX[i].itemStyle = {
          borderWidth: 0,
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
onMounted(async () => {
  await getDataFun()
  await equipmentList()
  arrow.value.key = 1
  lunbo()

  updateTime()
  //天气
  currentWeatherFun()
  allWeatherFun()

  nextTick(() => {
    chartLoad1()
    chartLoad2()
    chartLoad3()
    chartLoad5()
    chartLoad6()
  })

  // window.onresize = () => {
  //   let timer = setTimeout(() => {
  //     if (timer) {
  //       clearTimeout(timer)
  //     }
  //     let dom = document.querySelector('.hngfScreen')
  //     console.log(dom.clientWidth / 1920, dom.clientHeight / 1080)
  //     let dom2 = document.querySelector('.screen-wrapper')
  //     dom2.style.transform = `scale(${dom.clientWidth / 1920},${dom.clientHeight / 1080})`
  //     console.log(dom2.style)
  //   }, 500)
  // }
})
//大屏设备数据从hooks中获取
const {asyncData, basicData, arrow, circuitValue, transitionName, equipmentList} = useCircuit(getDeviceListApi)
basicData.after.color = 'linear-gradient(to right, #00293e, #00122c)'


// 定时器
const intervalId2 = setInterval(getDataFun, 10000)
const intervalId5 = setInterval(equipmentList, 60000)
const intervalId = setInterval(updateTime, 1000)
const intervalId3 = setInterval(currentWeatherFun, 1800000)
const intervalId4 = setInterval(allWeatherFun, 1800000)
onUnmounted(() => {
  clearInterval(intervalId)
  clearInterval(intervalId2)
  clearInterval(intervalId3)
  clearInterval(intervalId4)
  clearInterval(intervalId5)
})
</script>

<style lang="less" scoped>

</style>