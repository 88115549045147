<template>
  <div class="sourcePage">
    <div class="title">源管理</div>
    <div class="navigation">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="流管理" name="flowManage"></el-tab-pane>
        <el-tab-pane label="扫描表" name="scanTable"></el-tab-pane>
        <el-tab-pane label="查询表" name="lookupTable"></el-tab-pane>
      </el-tabs>
      <div class="addButton">
        <el-button type="primary" @click="lookClick('','create')">
          {{
            activeName === 'flowManage' ? '创建流' : activeName === 'scanTable' ? '创建表' : '创建查询表'
          }}
          <template #icon>
            <el-icon>
              <Plus/>
            </el-icon>
          </template>
        </el-button>
      </div>
      <div class="sourcePage-table">
        <!--流管理表-->
        <el-table v-if="activeName==='flowManage'" v-loading="loading.flowManage" :data="tags1" style="width: 100%"
                  table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column min-width="350px" fixed label="名称">
            <template #default="scope">
              <div
                  @click="lookClick(scope.row,'editor')"
                  class="table-Name">
                {{ scope.row.Name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="350px" label="流类型" prop="Options.type"/>
          <el-table-column min-width="350px" label="流格式" prop="Options.format"/>
          <el-table-column min-width="100px" fixed="right" label="操作">
            <template #default="scope">
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="修改"
                  placement="top-start"
              >
                <span style="margin-right: 12px;" class="btn color-blue"
                      @click="lookClick(scope.row,'editor')">修改</span>
              </el-tooltip>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="删除"
                  placement="top-start"
              >
                <span>
                  <el-popover width="300px" :visible="visible===scope.row" placement="left">
                    <div style="width: 300px;height: 125px;padding: 12px;box-sizing: border-box;">
                      <div style="display:flex;align-items: center;">
                        <el-icon style="margin-right: 5px" color="rgb(255, 153, 0)">
                          <QuestionFilled/>
                        </el-icon>
                        <div class="text">
                          所有使用该流的规则将继续使用被删除的配置运行，直到重新启动，如果不正确，请手动停止它们。
                        </div>
                      </div>
                      <div style="display:flex;justify-content: end;height: 32px;">
                        <el-button text @click="visible = false">No</el-button>
                        <el-button type="primary" @click="deleteClick('flowManage',scope.row)">
                          Yes
                        </el-button>
                      </div>
                    </div>
                    <template #reference>
                      <span class="btn color-red" @click="visible=scope.row">删除</span>
                    </template>
                  </el-popover>
                </span>
                <!--<span class="btn color-red" @click="visible=true">删除</span>-->
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--扫描表-->
        <el-table v-if="activeName==='scanTable'" v-loading="loading.scanTable" :data="tags2" style="width: 100%"
                  table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column min-width="350px" fixed label="名称">
            <template #default="scope">
              <div class="table-Name" @click="lookClick(scope.row,'editor')">{{ scope.row.Name }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="350px" label="表类型" prop="Options.type"/>
          <el-table-column min-width="350px" label="表格式" prop="Options.format"/>
          <el-table-column min-width="100px" fixed="right" label="操作">
            <template #default="scope">
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="修改"
                  placement="top-start"
              >
                <span style="margin-right: 12px" class="btn color-blue"
                      @click="lookClick(scope.row,'editor')">修改</span>
              </el-tooltip>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="删除"
                  placement="top-start"
              >
                <span>
                  <el-popover width="300px" :visible="visible===scope.row" placement="left">
                    <div style="width: 300px;height: 125px;padding: 12px;box-sizing: border-box;">
                      <div style="display:flex;align-items: center;">
                        <el-icon style="margin-right: 5px" color="rgb(255, 153, 0)">
                          <QuestionFilled/>
                        </el-icon>
                        <div class="text">
                          所有使用该流的规则将继续使用被删除的配置运行，直到重新启动，如果不正确，请手动停止它们。
                        </div>
                      </div>
                      <div style="display:flex;justify-content: end;height: 32px;">
                        <el-button text @click="visible = false">No</el-button>
                        <el-button type="primary" @click="deleteClick('scanTable',scope.row)">
                          Yes
                        </el-button>
                      </div>
                    </div>
                    <template #reference>
                      <span class="btn color-red" @click="visible=scope.row">删除</span>
                    </template>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--查询表-->
        <el-table v-if="activeName==='lookupTable'" v-loading="loading.lookupTable" :data="tags3" style="width: 100%"
                  table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column min-width="350px" fixed label="名称">
            <template #default="scope">
              <div class="table-Name" @click="lookClick(scope.row,'editor')">{{ scope.row.Name }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="350px" label="表类型" prop="Options.type"/>
          <el-table-column min-width="350px" label="表格式" prop="Options.format"/>
          <el-table-column min-width="100px" fixed="right" label="操作">
            <template #default="scope">
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="修改"
                  placement="top-start"
              >
                <span style="margin-right: 12px" class="btn color-blue"
                      @click="lookClick(scope.row,'editor')">修改</span>
              </el-tooltip>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="删除"
                  placement="top-start"
              >
                <span>
                  <el-popover width="300px" :visible="visible===scope.row" placement="left">
                    <div style="width: 300px;height: 125px;padding: 12px;box-sizing: border-box;">
                      <div style="display:flex;align-items: center;">
                        <el-icon style="margin-right: 5px" color="rgb(255, 153, 0)">
                          <QuestionFilled/>
                        </el-icon>
                        <div class="text">
                          所有使用该流的规则将继续使用被删除的配置运行，直到重新启动，如果不正确，请手动停止它们。
                        </div>
                      </div>
                      <div style="display:flex;justify-content: end;height: 32px;">
                        <el-button text @click="visible = false">No</el-button>
                        <el-button type="primary" @click="deleteClick('lookupTable',scope.row)">
                          Yes
                        </el-button>
                      </div>
                    </div>
                    <template #reference>
                      <span class="btn color-red" @click="visible=scope.row">删除</span>
                    </template>
                  </el-popover>
                </span>
              </el-tooltip>

            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, ref, watch} from "vue"
import router from "@/router";
import {Plus, QuestionFilled} from "@element-plus/icons-vue";
import {
  deleteStream, deleteTable, deleteTables,
  getLookupTable,
  getScanTable,
  getStream,
  getTables,
  streamManagement
} from "@/api/modules/sourcePage";
import {ElMessage} from "element-plus";
import {useRoute} from "vue-router";

const activeName = ref('flowManage')
// 获取数据监控列表
let loading = ref({
  flowManage: true,
  scanTable: true,
  lookupTable: true
});
const visible = ref(null)
let tags1 = ref([])
let tags2 = ref([])
let tags3 = ref([])
const route = useRoute();
/*----------------------------方法-----------------------*/
// 获取表数据，通用
const getFlowData = async (list, dome, params, loding, tags) => {
  tags.value = []
  const result = []
  try {
    const response = await list(params);
    for (const item of response.data) {
      const newData = await dome(item);
      const isDuplicate = tags.value.some(existingItem => existingItem.Name === newData.data.Name);
      if (!isDuplicate) {
        result.push(newData.data);
      }
    }
  } catch (error) {
    console.error('Error fetching or processing flow data:', error);
  } finally {
    tags.value.push(...result);
    loading.value[loding] = false
  }
};
//跳转，修改/新增
const lookClick = (name, oper) => {
  router.push(`/admin/sourcePage/sourcePageEditor?oper=${oper}`)
  const data = {...name, activeName: activeName.value}
  sessionStorage.setItem('sourceEditor', JSON.stringify(data))
}
// 删除(流管理表)
const deleteClick = (name, item) => {
  if (name === 'flowManage') {
    deleteData(deleteStream, item.Name, item, tags1)
  } else if (name === 'scanTable') {
    deleteData(deleteTables, item.Name, item, tags2)
  } else {
    deleteData(deleteTables, item.Name, item, tags3)
  }
}
//删除通用方法
const deleteData = (list, params, item, tags) => {
  list(params).then(res => {
    if (res.status === 200) {
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
      tags.value.splice(tags.value.indexOf(item), 1)
    }
  })
}
/*------------------------监听-----------------------------------*/
// 监听tab切换
watch(activeName, (newValue) => {
  if (newValue === 'flowManage') {
    loading.value.flowManage = true
    // tags1.value = []
    getFlowData(streamManagement, getStream, '', "flowManage", tags1)
  } else if (newValue === 'scanTable') {
    loading.value.scanTable = true
    // tags2.value = []
    getFlowData(getScanTable, getTables, 'scan', 'scanTable', tags2)
  } else if (newValue === 'lookupTable') {
    loading.value.lookupTable = true
    // tags3.value = []
    getFlowData(getScanTable, getTables, 'lookup', 'lookupTable', tags3)
  }
}, {deep: true})
onMounted(() => {
  if (route.query.activeName!== void 0) {
    activeName.value = route.query.activeName
  }
  getFlowData(streamManagement, getStream, '', "flowManage", tags1)
})
</script>

<style lang="less" scoped>
.sourcePage {
  padding: 28px;
  width: 100%;
  box-sizing: border-box;

  .title {
    width: 100%;
    height: 48px;
  }

  .btn {
    cursor: pointer;
  }

  .navigation {
    width: 100%;
    margin-bottom: 16px;
    padding: 24px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);

    .el-tabs {
      width: 100%;
    }

    .addButton {
      display: flex;
      justify-content: end;
      width: 100%;
      margin-bottom: 10px;
    }

    .table-Name {
      color: #165DFF;
      cursor: pointer;
    }
  }

  .color-blue {
    color: #165DFF;
    //cursor: pointer;
  }

  .color-red {
    color: #EF2121;
    //cursor: pointer;
  }
}
</style>
