import {api} from "@/api";

// // 获取告警列表
// export const getWarnListApi = (data) => {
//     return api({
//         headers: {
//             memberId: window.localStorage.getItem('memberId'),
//         },
//         url: '',
//         method: 'POST',
//         data: data
//     })
// }
//
// // 保存告警列表
// export const saveWarnListApi = (data) => {
//     return api({
//         headers: {
//             memberId: window.localStorage.getItem('memberId'),
//         },
//         url: '',
//         method: 'POST',
//         data: data
//     })
// }
// // 删除告警列表
// export const delWarnListApi = (data) => {
//     return api({
//         headers: {
//             memberId: window.localStorage.getItem('memberId'),
//         },
//         url: '',
//         method: 'POST',
//         data: data
//     })
// }
// // 查看告警详情
// export const lookWarnDetailApi = (data) => {
//     return api({
//         headers: {
//             memberId: window.localStorage.getItem('memberId'),
//         },
//         url: '',
//         method: 'POST',
//         data: data
//     })
// }
// // 处理告警
// export const handleWarnApi = (data) => {
//     return api({
//         headers: {
//             memberId: window.localStorage.getItem('memberId'),
//         },
//         url: '',
//         method: 'POST',
//         data: data
//     })
// }


// 设备报警---------------------------------------------------------------------------------
// 获取设备报警列表
export const getAlarmListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warn/pageEquipmentWarn',
        method: 'POST',
        data: data
    })
}
// 获取设备报警详细列表
export const getAlarmDetailListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warn/getEquipmentWarnDetail',
        method: 'POST',
        data: data
    })
}
// 获取设备当时值列表
export const getValueListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warn/getEventDetail',
        method: 'POST',
        data: data
    })
}


// 网关离线---------------------------------------------------------------------------------
// 获取网关离线列表
export const getOfflineListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/warn/pageOffline',
        method: 'POST',
        data: data
    })
}